import React, {useState, useMemo} from 'react'
import useTranslation from '../../../i18n/useTranslation'
import {
  ListItem,
  IconLesson,
  CarouselFrame,
  DayFrame,
  // IconArrowDown,
  // IconArrowUp,
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import moment from 'moment-timezone'
import {useSelector} from 'react-redux'

const RenderLabelGroupLessons = (props) => {
  const {lessons, name, days /** , showCollapse = false**/} = props
  const {translate} = useTranslation()
  const [isOpen /** , setOpen */] = useState(false)
  const parseDays = useMemo(() => {
    if (!(days?.length > 0)) return []
    return (days || []).map(({day, hour}) => {
      return moment()
        .isoWeekday(day)
        .hour(hour?.split(':')[0])
        .minutes(hour?.split(':')[1])
        .toISOString()
    })
  }, [days])

  const {timezone: zone} = useSelector((state) => state.user.info)
  return (
    <>
      <CarouselFrame type='animation'>
        <ListItem
          showTopLine={false}
          onClick={() => {
            // setOpen(!isOpen)
          }}
          className='pl-0 py-1 w-100'
          Left={
            <div
              className='d-flex align-items-center text-muted'
              title={translate(`home.onboard.approve.weeklyItemLessonGroup`, {
                lpw: lessons,
                name: name,
              })}
            >
              <IconLesson />
              <span className='ml-4' style={{whiteSpace: 'nowrap'}}>
                {translate(`home.onboard.approve.weeklyItemLessonGroup`, {
                  lpw: lessons,
                  name: name,
                })}
              </span>
            </div>
          }
          Right={
            null
            // !showCollapse ? null : (
            //   <div className='hover-icon'>
            //     {isOpen ? <IconArrowUp /> : <IconArrowDown />}
            //   </div>
            // )
          }
        />
      </CarouselFrame>
      <Collapse isOpen={isOpen}>
        {parseDays.map((date) => {
          return (
            <ListItem
              showTopLine={false}
              className='pl-0 py-1 ml-6'
              Left={
                <div
                  className='d-flex align-items-center text-muted'
                  title={null}
                >
                  <DayFrame
                    className='listItemDay'
                    free={false}
                    selected
                    check
                    span={null}
                    spanColor='#DCE1E7'
                  />
                  <span className='ml-2' style={{whiteSpace: 'nowrap'}}>
                    {moment(date).tz(zone).format('dddd, hh:mma')}
                  </span>
                </div>
              }
            />
          )
        })}
      </Collapse>
    </>
  )
}

export default RenderLabelGroupLessons
