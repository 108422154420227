import React, {useEffect, useState, useMemo} from 'react'
import useGetPdfTemplate from '../../../../Model/useGetPdfTemplate'
import usePdfViewer from '../usePdfViewer'
import HideTimeline from '../Components/HideTimeline'
// Retorna la instancia del documento usando usePDF

const camelCaseToKey = (text) => {
  const result = text.replace(/([A-Z])/g, '-$1').toLowerCase()
  // const finalResult = result.charAt(0).toLowerCase() + result.slice(1);
  return result
}
const GetPdfProposalObject = (props) => {
  const {counselingPlan, setDocumentArray, start} = props
  // obtiene plantilla pdf según proposal version
  const {data} = useGetPdfTemplate({
    templateKey: camelCaseToKey(counselingPlan?.proposalVersion || ''),
  })

  const {generatePdfObject} = usePdfViewer()
  const [timelineUrl, setTimelineUrl] = useState(null)

  const documentArray = useMemo(
    () => {
      if (
        counselingPlan &&
        data?.getPdfTemplate?.json &&
        timelineUrl &&
        start
      ) {
        const documentArray = generatePdfObject(
          counselingPlan,
          data.getPdfTemplate.json,
          timelineUrl,
        )
        return documentArray
      }
      return []
    },
    // eslint-disable-next-line
        [counselingPlan, data, timelineUrl, start]
  )
  useEffect(() => {
    if (documentArray && start) {
      setDocumentArray(documentArray)
    }
    // eslint-disable-next-line
    }, [documentArray])
  return (
    <HideTimeline
      start={start}
      tasks={counselingPlan?.tasks || []}
      setTimelineUrl={setTimelineUrl}
    />
  )
}
export default GetPdfProposalObject
