import React from 'react'
import {IconSignCheck, IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
// import useRegisterScoresModal from '../../../RegisterScoresModal/useRegisterScoresModal'
import useHandlerExamForm, {FORMODEL} from '../../../DocsAndExams/SubmitScore/useHandlerExamForm'
import InfoTaskButton from './infoTaskButton'
// import useShowTask from '../../useShowTask'

const ScoreCompletedButton = (props) => {
  const {task} = props
  // const [taskData, setTaskData] = useState(task)
  const {open: openRegisterScores} = useHandlerExamForm(FORMODEL.task)
  // const { close: closeShowTask } = useShowTask()

  const infoByStatus = {
    pending: {
      text: `${task?.buttonLabel}`,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconArrowRight
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    review: {
      text: `${task?.buttonLabel}`,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    completed: {
      text: `${task?.buttonLabel}`,
      colorText: '#72809D',
      background: '#F0F4F8',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#72809D'
        />
      ),
    },
  }

  return (
    <>
      <InfoTaskButton
        background={infoByStatus[task?.status]?.background}
        colorText={infoByStatus[task?.status]?.colorText}
        task={task}
        onClick={() => {
          debugger
          openRegisterScores(() => {}, task.id).then(() => {debugger}).catch(() => {debugger}).finally(() => {})
        }}
        icon={infoByStatus[task?.status]?.icon}
        textLabel={infoByStatus[task?.status]?.text}
      />
      {task?.status !== 'pending' &&
        task?.resolveTask?.uploadFiles?.length > 0 &&
        task?.resolveTask?.uploadFiles?.map((file) => {
          return (
            <InfoTaskButton
              background={infoByStatus[task?.status].background}
              colorText={infoByStatus[task?.status].colorText}
              onClick={() => {
                window.open(file.url)
              }}
              icon={infoByStatus[task?.status].icon}
              textLabel={file.url.split('/').at(-1)}
            />
          )
        })}
    </>
  )
}

export default ScoreCompletedButton
