import React from 'react'
import Skeleton from 'react-loading-skeleton'
import usePaymentItemsByPlanId from './GetPaymentItemsByMyPlanId'
import useTranslation from '../../../../../../i18n/useTranslation'
import {Action, IconLesson} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
const PaymentInfo = (props) => {
  const {planId} = props
  const {loading, data} = usePaymentItemsByPlanId({planId})
  const paymentsData = loading ? [] : data && data.docs ? data.docs : []
  const {translate} = useTranslation()
  // let approvalFeeStatus = 'paid'
  // const approvalFee = paymentsData.reduce((amount, payment) => {
  //  if (payment.type === 'otherFixed') {
  //    if (payment.status !== 'paid') {
  //      approvalFeeStatus = 'pendingPayment'
  //    }
  //    return amount + payment.amount
  //  }
  //  return amount
  // }, 0)
  const otherFixedLabels = {
    'payment-exam': {
      name: translate('program.plan.infoModal.examregistrationfee'),
    },
    'payment-registration': {
      name: translate('program.plan.infoModal.registrationfee'),
    },
    'payment-pro': {
      name: 'Zinkerz Pro',
    },
  }
  return (
    <>
      <p className='h4 font-weight-bold my-1 mb-2'>
        {translate('program.plan.infoModal.paymentStatus')}
      </p>
      <div className='d-flex flex-column align-items-center'>
        {loading ? (
          <Skeleton height={20} count={3} />
        ) : paymentsData.length ? (
          <>
            {/** approvalFee ? (
              <Action
                status={approvalFeeStatus}
                labelLeft={
                  <>
                    <IconLesson className='actions-icon mr-2' size={16} />
                    {translate('program.plan.infoModal.payment.approvalFee')}
                  </>
                }
                labelRight={`$${approvalFee}`}
              />
              ) : null**/}
            {paymentsData
              .filter((p) => p.type === 'otherFixed')
              .map((element, index) => {
                return (
                  <Action
                    key={index}
                    status={
                      element.status === 'paid'
                        ? 'paid'
                        : element.status === 'pendingPayment'
                        ? 'scheduled'
                        : 'pendingPayment'
                    }
                    labelLeft={
                      <>
                        <IconLesson className='actions-icon mr-2' size={16} />
                        {otherFixedLabels[
                          element?.description
                            ?.toString()
                            ?.toLowerCase()
                            .replace(' ', '-')
                        ]
                          ? otherFixedLabels[
                              element?.description
                                ?.toString()
                                ?.toLowerCase()
                                .replace(' ', '-')
                            ].name
                          : element?.description
                              ?.toString()
                              ?.toLowerCase()
                              .replace(' ', '-')}
                      </>
                    }
                    labelRight={`$${element.amount.toFixed(2)} (${translate(
                      `program.plan.infoModal.payment.${element.status}`,
                    )})`}
                  />
                )
              })}
            {paymentsData
              .filter((p) => p.type !== 'otherFixed')
              .sort(
                (a, b) =>
                  new Date(a.date).getTime() - new Date(b.date).getTime(),
              )
              .map((element, index) => {
                return (
                  <Action
                    key={index}
                    status={
                      element.status === 'paid'
                        ? 'paid'
                        : element.status === 'pendingPayment'
                        ? 'scheduled'
                        : 'pendingPayment'
                    }
                    labelLeft={
                      <>
                        <IconLesson className='actions-icon mr-2' size={16} />
                        {translate(
                          'profile.payments.endOf',
                          moment(element.date).format('MMMM'),
                        )}
                      </>
                    }
                    labelRight={`$${element.amount.toFixed(2)} (${translate(
                      `program.plan.infoModal.payment.${element.status}`,
                    )})`}
                  />
                )
              })}
          </>
        ) : (
          <span className='w-100 text-center text-body'>
            {translate('misc.nothingFound')}
          </span>
        )}
      </div>
    </>
  )
}

export default PaymentInfo
