import React, {useEffect} from 'react'
import useViewPlanModal from '../useViewPlanModal'
import MyCounselingPlan from '../../../../Model/Users/Plans/MyCounselingPlan'
import useCounselingPlanReducer from '../../../../reducers/counseling-plan'

const RedirectFromUrl = () => {
  const {parsePlanFromAPI} = useCounselingPlanReducer()

  const {open} = useViewPlanModal()
  const params = new URL(document.location).searchParams
  const counselingPlanId = params.get('counselingPlanId')
  const tab = params.get('tab')

  const {data: dataCounselingPlan} = MyCounselingPlan({
    planId: counselingPlanId,
  })

  useEffect(() => {
    if (
      Object.keys(dataCounselingPlan)?.length > 0 /** no es un objeto vacio**/
    ) {
      open(() => {}, parsePlanFromAPI(dataCounselingPlan), tab)
    }
    // eslint-disable-next-line
    }, [dataCounselingPlan, tab])

  return <></>
}

export default RedirectFromUrl
