import React from 'react'
import SendEmail from './Email'
import ResetPassword from './ResetPassword'
import {TabContent, TabPane} from 'reactstrap'
import {useLocation} from 'react-router-dom'

const RecoveryPasswordPage = (props) => {
  const {back, defaultEmail} = props
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const page = searchParams.get('page') || 'email'
  return (
    <TabContent activeTab={page}>
      <TabPane tabId='email'>
        <SendEmail defaultEmail={defaultEmail} />
      </TabPane>
      <TabPane tabId='reset'>
        <ResetPassword back={back} />
      </TabPane>
    </TabContent>
  )
}

export default RecoveryPasswordPage
