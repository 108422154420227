export default {
  title: '¡Tu plan de estudios está listo!',
  subtitle:
    'Genial, hemos enviado un mail Integer posuere erat a ante venenatis dapibus posuere velit aliquettiam a sem malesuad.',
  effortTitle: 'Esfuerzo/Asistencia',
  effortText:
    'Se espera que los estudiantes asistan a todas las lecciones y participen activamente en clases.',
  cancelationTitle: 'Cancelación',
  cancelationText:
    'Las clases deben reprogramarse 24 horas antes de la hora de la lección. Es responsabilidad del estudiante hacerlo dentro de su calendario MyZinkerz.',
  homeworkTitle: 'Tareas',
  homeworkText:
    'La tarea debe completarse antes del comienzo de cada lección. Los estudiantes deben revisar las notas de la lección para saber las tareas que deben entregarse.\n',
  supportTitle: 'Soporte',
  supportText:
    'Estamos aquí para ti. Comuníquese con nosotros a través de WhatsApp para cualquier cosa que necesite.\n',
  goto: 'Ir a My Zinkerz',
}
