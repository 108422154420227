export default {
  upcoming: 'POR COMENZAR',
  pendingApproval: 'APROBACIÓN PENDIENTE',
  inProgress: 'EN PROGRESO',
  lessonsPerWeek: (num) => {
    return `${num} clases por semana`
  },
  totalLessons: (num) => {
    return `${num} lecciones totales`
  },
  weeklySchedule: 'Agenda semanal',
  weeklyLPT: ({num, type, topic}) => ` ${num} ${type} ${topic} clases`,
  calendar: 'Calendario',
  plans: 'Planes',
  noFound: 'No se encontraron planes',
  plantoapprove: 'Tienes un plan por aprobar',
}
