import React, {useMemo, useState} from 'react'
import {
  IconAlert,
  IconRight,
  IconTask,
  InvoiceCard,
  ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import {useSelector} from 'react-redux'
import startCase from 'lodash/startCase'
import PaymentModalForm from '../../../Modal/ChangePM'
const Header = (props) => {
  const {
    invoice = {},
    loading,
    modalState,
    className = '',
    offline = false,
  } = props
  const {translate} = useTranslation()
  const {paymentMethod} = useSelector((state) => state.user.info)
  const issuedInvoice = (invoice.issuedInvoices ?? []).find(
    (issInvoice) => issInvoice.status === 'paid',
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggle = () => setIsModalOpen((open) => !open)

    // console.log("invoice *****", invoice)
    // console.log("!!invoice?.amountPaid *****", !!invoice?.amountPaid)
    // console.log("invoice.amountPaid !== 0 *****", invoice.amountPaid !== 0)

  const realAmount = useMemo(
    () => {
      return invoice.status === 'paid' ? 
        invoice.amount : 
          !!invoice?.amountPaid && invoice.amountPaid !== 0 ? 
            invoice.amountPaid :
            invoice.amount
    }, [invoice]
  ) 
  
  return (
    // modal-header px-2 px-sm-4 px-lg-6 px-xl-8
    <div
      className={`listItemBorders  d-flex flex-column align-items-center ${className}`}
    >
      {loading ? (
        <InvoiceCard empty textTop={<Skeleton height={10} />} type='white' />
      ) : (
        <>
          <div
            className='borderTop w-100 mt-4'
            style={{backgroundColor: 'white'}}
          />
          <InvoiceCard
            iconSize={100}
            showHeader={false}
            type={
              modalState // 'pendingPayment'
              // invoice.category === 'livePrep' && false ? 'normal' : modalState
            }
            text={`End of ${moment.utc(invoice.to).format('MMMM')}`}
            typePayment={null /** <IconClose size={20} onClick={toggle} />**/}
            className='mt-1 px-0 pt-0 noHeadBorder'
            amount={realAmount}
            invoice={`INV-${String(invoice.invoiceNumber).padStart(5, 0)}`}
            textTop=''
            of={offline ? ' ' : null}
            iconSpanClassName='font-size-h3-offline-invoice'
            // refund={false}
          />
          <div className='borderTop w-100' style={{backgroundColor: 'white'}} />
          {!['paid', 'canceled', 'void'].includes(invoice.status) &&
          !offline ? (
            <ListItem
              className='w-100'
              showTopLine
              Left={
                <span style={{ pointerEvents: 'none' }}>
                  <PaymentModalForm {...props} isProfileView={false} />
                </span>
              }
              Right={
                <span onClick={toggle} style={{ cursor: 'pointer' }}>
                  {paymentMethod?.__typename === 'CardPaymentMethod'
                    ? `${startCase(paymentMethod?.cardInfo.brand)} ${
                        paymentMethod?.cardInfo.last4
                      }`
                    : paymentMethod?.kind
                    ? translate(
                        `profile.payments.methods.${paymentMethod?.kind}`,
                      )
                    : translate('profile.payments.methods.noPaymentMethod')}
                  <IconRight className='ml-1' />
                </span>
              }
            />
          ) : null}
          {isModalOpen && <PaymentModalForm {...props} action="addmethodpaymentfromprofile" isProfileView={false} handleModalClose={toggle}/>}
          {invoice.status === 'unPaid' && !offline ? (
            <ListItem
              className='w-100'
              classNameLeft='text-truncate'
              showTopLine
              Left={
                <span className='d-flex flex-column text-truncate'>
                  <span className='text-error'>
                    {`${translate('profile.payments.lastAttempt')}: ${moment(
                      invoice.lastProcessedAt,
                    ).format('DD-MM-YYYY HH:mma')}`}
                  </span>
                  <span className='mt-2 text-error'>
                    {translate('profile.payments.errorDesc')}
                  </span>
                </span>
              }
              Right={
                <IconAlert className='ml-1 text-error w-auto' height='auto' />
              }
            />
          ) : invoice.status === 'paid' ? (
            <ListItem
              className='w-100'
              classNameLeft='text-truncate'
              showTopLine
              Left={
                <span className='d-flex flex-column text-truncate'>
                  <span className='text-paid'>
                    {translate('profile.payments.paidOn')}:&nbsp;
                    {moment(invoice.lastProcessedAt).format('DD-MM-YYYY HH:mm')}
                  </span>
                  {issuedInvoice ? (
                    <span className='text-paid'>
                      {translate('profile.payments.paidWith')}:&nbsp;
                      {translate(
                        `profile.payments.methods.${issuedInvoice.paymentMethod}`,
                      )}
                    </span>
                  ) : null}
                </span>
              }
              Right={
                <IconTask className='ml-1' color='#81B023' fill='#ffffff' />
              }
            />
          ) : null}
        </>
      )}
    </div>
  )
}

export default Header
