import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  ApolloProvider as RawApolloProvider,
  ApolloClient,
  InMemoryCache,
  // HttpLink,
} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {createUploadLink} from 'apollo-upload-client'

import url from './client/url'
import AuthContext from '../../../contexts/AuthContext'
import FirebaseUserContext from '../../../contexts/FirebaseUserContext'
import ActionsContext from '../../../contexts/ActionsContext'

const propTypes = {
  children: PropTypes.any,
}
// const httpLink = new HttpLink({uri: `${url}/graphql`})
const httpLink = new createUploadLink({
  uri: `${url}/graphql`,
})

// const uploadLink = new createUploadLink({uri: `${url}/graphql`})

function ApolloProvider({children}) {
  const [client, setClient] = useState(
    new ApolloClient({cache: new InMemoryCache(), link: httpLink}),
  )
  const [firebaseUser, setFirebaseUser] = useState(null)
  const [isLogged, setLogged] = useState(false)
  useEffect(() => {
    if (firebaseUser) {
      const asyncAuthLink = setContext(
        (request) =>
          new Promise((success, fail) => {
            // do some async lookup here
            firebaseUser
              .getIdToken()
              .then((token) => {
                success({
                  headers: {authorization: `Bearer ${token}`},
                })
              })
              .catch(fail)
          }),
      )
      setClient(
        new ApolloClient({
          cache: new InMemoryCache(),
          link: asyncAuthLink.concat(httpLink),
          // .concat(uploadLink),
        }),
      )
      setLogged(true)
    } else {
      setLogged(false)
    }
  }, [firebaseUser])
  const [action, setAction] = useState('')
  const changeAction = (action) => {
    setAction(action)
  }
  return (
    <AuthContext.Provider value={isLogged}>
      <ActionsContext.Provider
        value={{
          action: action,
          changeAction: changeAction,
        }}
      >
        <FirebaseUserContext.Provider value={{setFirebaseUser}}>
          <RawApolloProvider client={client}>{children}</RawApolloProvider>
        </FirebaseUserContext.Provider>
      </ActionsContext.Provider>
    </AuthContext.Provider>
  )
}

ApolloProvider.propTypes = propTypes

export default ApolloProvider
