import {useLazyQuery, gql} from '@apollo/client'

export const useChatNotification = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useLazyQuery(
    gql`
      query(
        $chanel: ChannelRoom!
        $planId: ID
        $phase: PhaseEnum
        $stepId: ID
        $taskId: ID
        $lessonId: ID
        $userIn: Boolean
      ) {
        setNotificationChat(
          chanel: $chanel
          planId: $planId
          phase: $phase
          stepId: $stepId
          taskId: $taskId
          lessonId: $lessonId
          userIn: $userIn
        )
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError,
    },
  )
}
