import {gql, useQuery} from '@apollo/client'

export default (vars) => {
  const {data, loading} = useQuery(
    gql`
      query($limit: Int, $next: String, $previous: String, $search: String) {
        getUniversities(
          limit: $limit
          sortAscending: true
          sortField: "ranking"
          next: $next
          previous: $previous
          search: $search
        ) {
          hasNext
          hasPrevious
          next
          previous
          totalDocs
          docs {
            id
            ranking
            isPrincipal
            isActive
            collegeId
            name
            logo
            collegeUnitId
            deleteAt
          }
        }
      }
    `,
    {
      variables: vars,
    },
  )
  return {loading, data: data ? data.getUniversities : {}}
}
