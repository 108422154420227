import React from 'react'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import SwitchAnimationIcon from './SwitchAnimationIcon'
const AllowPermissions = (props) => {
  const {setBlur = () => {}, onContinue = () => {}} = props
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${
        breakWidth === 'SM' ? 'px-0' : breakWidth === 'MD' ? 'px-2' : 'px-6'
      } mx-6`}
    >
      <label className='h3 text-info text-center font-weight-bold'>
        {translate('modal.infolesson.allowPermissions.title') ||
          "You'll be asked to allow permissions"}
      </label>
      <div className='p-0 mx-0 my-4'>
        <SwitchAnimationIcon />
      </div>
      <label
        className={`h3 text-info text-center ${
          breakWidth === 'XL' ? 'px-4' : 'px-2'
        }`}
      >
        {translate('modal.infolesson.allowPermissions.body') ||
          'When you enter the classroom, make sure to allow both microphone and camera permissions.'}
      </label>
      <div className='d-flex flex-row justify-content-end w-100 mt-4'>
        <span
          className='btn-link'
          onClick={() => {
            setBlur(false)
            if (onContinue) onContinue()
          }}
        >
          {translate('modal.infolesson.allowPermissions.onContinue') ||
            'Ok, understood.'}
        </span>
      </div>
    </div>
  )
}

export default AllowPermissions
