export default function reducer(state, action) {
  switch (action.type) {
    case 'update-data':
      return {
        ...state,
        ...action.data,
      }
    case 'reset-data':
      return {
        ...action.data,
      }
    default:
      throw new Error()
  }
}
