export default function () {
  const {hostname} = window.location

  const isDev = hostname.includes('dev.')
  const isProduction = !isDev && hostname.includes('.com')

  if (isProduction) return 'production'
  if (isDev) return 'beta'

  return 'local'
}
