import validate from 'validate.js'

const constraints = {
  institution: {
    presence: true,
    length: {
      minimum: 1,
      maximum: 30,
    },
  },
  // gpa: {
  //   numericality: {
  //     greaterThan: 0,
  //   },
  // },
  month: {presence: true},
  year: {presence: true},
}
export default (data) => {
  return validate(data, constraints)
}
