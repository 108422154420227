const examTypes = {
  SAT: 'SAT',
  ACT: 'ACT',
  AdvancedPlacement: 'Advanced Placement',
  TOEFL: 'TOEFL',
  IELTS: 'IELTS',
  Duolingo: 'Duolingo',
  SSAT: 'SSAT',
  ISEE: 'ISEE',
  GRE: 'GRE',
  LSAT: 'LSAT',
}

export default examTypes
