import {initialState} from './initialState'
import {SET_SERVICES_ACTION} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SERVICES_ACTION:
      return {
        ...state,
        services: action.payload.reduce((obj, service) => {
          return {
            ...obj,
            [service.id]: {
              ...service,
              topics: service.topics.reduce(
                (obj, topic) => ({...obj, [topic.id]: topic}),
                {},
              ),
            },
          }
        }, {}),
      }
    default:
      return {...state}
  }
}
