import React from 'react'
import {
  IconSuccess,
  ListItem,
  ToolTipInfo,
  IconInfo,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import Skeleton from 'react-loading-skeleton'
import formatNumber from '../../../helpers/formatNumber'

const BreakDown = (props) => {
  const {
    data,
    totalLessons,
    isPerMonth,
    individualPricing,
    hasGroupPricing, // indica si el plan tiene groupId
    groupPricing,
  } = props
  const {translate} = useTranslation()

  const paymentsName = {
    'Payment registration': translate(
      'home.onboard.approve.breakdownRegistration',
    ),
    'Payment exam': translate('home.onboard.approve.exam'),
    'Payment pro': 'Marathon', //'Zinkerz Pro',
  }
  const paymentItemsLesson =
    data && data.filter((pay) => pay.type !== 'others-fixed')

  const totalValueGroupLesson = isPerMonth
    ? (groupPricing?.groupPricingRate || 0) * paymentItemsLesson.length
    : (groupPricing?.groupPricingRate || 0) *
      (groupPricing?.totalGroupLessons || 0)

  let totalAmountPaymentItemsLesson =
    paymentItemsLesson &&
    paymentItemsLesson.reduce((total, pay) => {
      return total + pay.amount
    }, 0)

  if (!isPerMonth && totalAmountPaymentItemsLesson >= totalValueGroupLesson) {
    totalAmountPaymentItemsLesson -= totalValueGroupLesson
  }
  return (
    <div className='my-5 mx-0'>
      <h3>
        <b>{translate('home.onboard.approve.breakdownTitle')}</b>
      </h3>
      <div className='m-0 p-0 my-4'>
        {data ? (
          <>
            {data
              .filter((pay) => pay.type === 'others-fixed')
              .map((pay, indexPay) => {
                return (
                  <ListItem
                    key={indexPay}
                    showTopLine={false}
                    className='pl-0 py-1'
                    Left={
                      <div className='d-flex align-items-center text-muted'>
                        <IconSuccess color='#696CFF' />
                        <span className='ml-4'>
                          {paymentsName[pay.description]}
                        </span>
                        {paymentsName[pay.description] ===
                          'Registration Fee' && (
                          <ToolTipInfo
                            tooltipId={'registration-fee-tooltip-breakdown'}
                            type='dark'
                            place='right'
                            innerElement={
                              <p
                                className='p-0 m-0'
                                style={{maxWidth: '200px', color: 'white'}}
                              >
                                {translate(
                                  'home.onboard.approve.registrationFeeInfo',
                                )}
                              </p>
                            }
                          >
                            <div className='hover-icon'>
                              <IconInfo className='text-gray' size={16} />
                            </div>
                          </ToolTipInfo>
                        )}
                      </div>
                    }
                    Right={<span>{`$${formatNumber(pay.amount)}`}</span>}
                  />
                )
              })}
            {isPerMonth ? (
              <ListItem
                showTopLine={false}
                className='pl-0 py-1'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <IconSuccess color='#696CFF' />
                    <span className='ml-4'>
                      {hasGroupPricing
                        ? translate(
                            'home.onboard.approve.before.individualGroupValue',
                          )
                        : translate(
                            'home.onboard.approve.before.individualValue',
                          )}
                    </span>
                  </div>
                }
                Right={
                  <span>{`$${formatNumber(
                    totalAmountPaymentItemsLesson,
                  )}`}</span>
                }
              />
            ) : (
              <ListItem
                showTopLine={false}
                className='pl-0 py-1'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <IconSuccess color='#696CFF' />
                    <span className='ml-4'>
                      {translate('home.onboard.approve.before.rateLesson', {
                        num: totalLessons,
                        rate: parseFloat(individualPricing).toFixed(2),
                      })}
                    </span>
                  </div>
                }
                Right={
                  <span>{`$${formatNumber(
                    totalAmountPaymentItemsLesson,
                  )}`}</span>
                }
              />
            )}
            {hasGroupPricing &&
              (isPerMonth ? null : (
                <ListItem
                  showTopLine={false}
                  className='pl-0 py-1'
                  Left={
                    <div className='d-flex align-items-center text-muted'>
                      <IconSuccess color='#696CFF' />
                      <span className='ml-4'>
                        {translate(
                          'home.onboard.approve.before.rateLessonGroup',
                          {
                            num: groupPricing?.totalGroupLessons || 0,
                            rate: parseFloat(
                              groupPricing?.groupPricingRate || 0,
                            ).toFixed(2),
                          },
                        )}
                      </span>
                    </div>
                  }
                  Right={
                    <span>{`$${formatNumber(totalValueGroupLesson)}`}</span>
                  }
                />
              ))}
          </>
        ) : (
          <Skeleton count={3} />
        )}
      </div>
    </div>
  )
}

export default BreakDown
