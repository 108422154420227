import {useLazyQuery, gql} from '@apollo/client'

const useGetUserPhone = () => {
  return useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          phone
          studentInfo {
            studentType
            parents {
              parentUserId
              phone
              firstName
              lastName
            }
          }
        }
      }
    `,
  )
}

export default useGetUserPhone
