import React, { useState, useCallback, useEffect, useMemo } from 'react'
import LessonsIcons from '../../components/LessonsIcons'
import {
  IconProgram,
  ListItem,
  ListOption,
  IconCountry,
  IconArrowRight,
  StickyButton,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { gql, useLazyQuery } from '@apollo/client'
import * as moment from 'moment-timezone'
import ChangeTimezone from '../../components/ChangeTimezone'
import { nextPage, setTimeOnTopicPartLesson } from '../../utils'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import { CHANGE_TIMEZONE } from '../../../../store/user/const'
import UseUpdateTimezone from '../../Model/useUpdateTimezone'
import Skeleton from 'react-loading-skeleton'
import { showAlert } from '../../../../common/Alert/util'
export const ScheduledOrientationHeader = (props) => {
  const { scheduledTimes, keyLesson } = useSelector(
    (state) => state.modal.onboarding.data,
  )
  return (
    <div className='m-0 p-0'>
      <div className='d-flex my-2 overflow-hidden'>
        <CarouselFrame>
          <LessonsIcons
            data={
              scheduledTimes
                ? Object.entries(scheduledTimes).reduce(
                  (arrayData, [key, objTopic]) => {
                    if (key?.split('-')[1] === keyLesson?.split('-')[1]) {
                      return arrayData.concat(
                        objTopic.times.map((item, indexLesson) => {
                          return {
                            scheduled: item !== null,
                            lesson: objTopic.topic.name,
                            idTopic: objTopic.topic.id.toString(),
                            onClick: () => { },
                          }
                        }),
                      )
                    }
                    return arrayData
                  },
                  [],
                )
                : []
            }
            idTopic={keyLesson?.split('-')[0]}
            lesson={1}
            checked
            selected={false}
          />
        </CarouselFrame>
      </div>
    </div>
  )
}
export const ScheduledOrientationBody = (props) => {
  const { setPage, isAdmin } = props
  const [pageNum, setPageNum] = useState(1)
  const [dataHour, setDataHour] = useState([])
  const [dataFull, setDataFull] = useState([])
  const { translate } = useTranslation()

  const dispatch = useDispatch()

  const zone = useSelector((state) => state.user.info.timezone)
  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const reduxMedium = useSelector((state) => state.modal.onboarding.mediumModal)

  const dataRedux = useSelector((state) => state.modal.onboarding.data)
  const { scheduledTimes, keyLesson, dataPlan, orientationKey } = dataRedux
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'
  const { ranges } = dataPlan
  const orientationData =
    scheduledTimes && keyLesson ? scheduledTimes[keyLesson] : {}
  const orientator =
    keyLesson === ORIENTATION_KEY ? orientationData?.educator[0] : {}

  const generateBoundaries = (timezone) => {
    // setea el timezone
    moment().tz(timezone)
    let auxFrom = moment().toISOString()
    let auxTo = moment().toISOString()
    const now = moment()
    const from_plan = moment(ranges[0][0]) // reemplazar por from
    // el plan ya comenzó
    const timeToPlanBegin = moment
      .duration(
        from_plan.clone().subtract(1, 'day').diff(now.clone().add(1, 'day')),
      )
      .asHours()
    if (
      now.clone().add(1, 'day') < from_plan.clone().subtract(1, 'day') &&
      ![6, 7].includes(from_plan.clone().subtract(1, 'day').isoWeekday())
      // el plan no comienza en sabado ni domingo, si es así no
      // obtiene ningún horario por rango de hora laboral
    ) {
      // SI FALTAN MAS DE 2 DÍAS PARA COMENZAR EL PLAN
      auxFrom = now.add(1, 'day').toISOString()
      auxTo = from_plan.toISOString() // FROM
    } else {
      auxFrom = now.add(1, 'day').toISOString()
      if (!(now.clone().add(1, 'day') < from_plan.clone().subtract(1, 'day')))
        auxTo = now
          .add(
            [6, 7].includes(from_plan.clone().subtract(1, 'day').isoWeekday())
              ? 4
              : timeToPlanBegin <= 0
                ? 4
                : 2,
            'day',
          )
          .toISOString()
      else
        auxTo = now
          .add(
            // el plan ya comenzó
            timeToPlanBegin <= 0 ? 4 : 2,
            'day',
          )
          .toISOString()
    }
    // EL RANGO DE FECHAS SIEMPRE COMENZARÁ 24 HRS DESPUES DEL LA HORA ACTUAL
    return [auxFrom, auxTo]
  }

  const boundaries = useMemo(() => {
    return generateBoundaries(zone)
    // eslint-disable-next-line
  }, [zone])

  const [from, to] = boundaries

  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO - FIN
  const parseFrames = useCallback(
    (frames) => {
      // timezone del orientador
      const orientatorZone = orientator?.timezone || 'UTC'
      // setea el timezone
      moment().tz(zone)
      const array = []
      // frames.length
      for (let i = 0; i < frames.length; i++) {
        let [from, to] = frames[i]
        from = moment(from)
        to = moment(to)
        if (from.minutes() !== 0) {
          // SI NO PARTE DESDE UNA HORA EN PUNTO (minuto zero) => ajustar from a la siguiente hora
          const minutes = from.minutes()
          let addMin = 0
          if (minutes >= 1 && minutes < 15) {
            addMin = 15 - minutes
          } else if (minutes >= 15 && minutes < 30) {
            addMin = 30 - minutes
          } else if (minutes >= 30 && minutes < 45) {
            addMin = 45 - minutes
          } else if (minutes >= 45) {
            addMin = 60 - minutes
          }

          from = from.add(addMin, 'm')
          const seconds = from.seconds()
          if (seconds !== 0) {
            from = from.subtract(seconds, 's')
          }
        }
        if (moment(to).clone().minutes() !== 0) {
          const minutesTo = moment(to).clone().minutes()
          let subMin = 0
          if (minutesTo >= 1 && minutesTo < 15) {
            subMin = 15 - minutesTo
          } else if (minutesTo >= 15 && minutesTo < 30) {
            subMin = 30 - minutesTo
          } else if (minutesTo >= 30 && minutesTo < 45) {
            subMin = 45 - minutesTo
          } else if (minutesTo >= 45) {
            subMin = 45 - minutesTo
          }
          to = to.subtract(subMin, 'm')
        }
        while (from < to && array.length <= 100) {
          const dayOfWeek = moment(from).clone().tz(orientatorZone).isoWeekday()
          const hourOfDay = moment(from).clone().tz(orientatorZone).hour()
          const fromBool = moment(from) > moment().add(24, 'hours')
          const hourBool = hourOfDay >= 10 && hourOfDay < 17
          const dayBool = dayOfWeek >= 1 && dayOfWeek <= 5
          if (
            // de 10am a 17pm
            hourBool &&
            // lunes a viernes
            dayBool &&
            fromBool
          ) {
            array.push(moment(from).format())
          }
          from = from.add(15, 'm')
        }
      }
      return array
    },
    [zone, orientator],
  )

  // implementar isAdmin
  const [getFreeFrames, { data, loading }] = useLazyQuery(
    gql`
      query(
        $educatorId: ID!
        $studentId: ID
        $from: DateTime!
        $to: DateTime!
      ) {
        getOrientationHours(
          from: $from
          to: $to
          educatorId: $educatorId
          studentId: $studentId
        )
      }
    `,
  )
  useEffect(() => {
    if (
      reduxSmall?.page === 'scheduledOrientation' &&
      orientator?.id &&
      from &&
      to
    ) {
      const variables = {
        educatorId: orientator.id,
        from,
        to,
      }
      if (isAdmin) variables.studentId = dataPlan?.studentUserId
      getFreeFrames({ variables })
    }
  }, [reduxSmall, orientator, from, to, dataPlan, isAdmin, getFreeFrames])

  // const parsedFrames = data ? parseFrames(data.getOrientationHours) : []
  const [selectedTime, setSelectedTime] = useState(null)

  useEffect(() => {
    if (data) {
      const parsedFrames = data ? parseFrames(data.getOrientationHours) : []
      setDataHour(parsedFrames.slice(0, 10))
      setDataFull(parsedFrames)
    }
    // eslint-disable-next-line
  }, [data])
  const more = () => {
    const moreNumber = pageNum + 1
    setDataHour(dataHour.concat(dataFull.slice(pageNum * 10, moreNumber * 10)))
    setPageNum(moreNumber)
  }

  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO
  const [changeTimezone] = UseUpdateTimezone()

  const selectBlock = (timeMoment, selected) => {
    setSelectedTime(selected ? timeMoment : null)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {
        ...dataRedux,
        scheduledTimes: setTimeOnTopicPartLesson(
          scheduledTimes,
          keyLesson,
          selected ? moment(timeMoment) : null,
          0,
        ),
      },
    })
  }
  const setZone = (time) => {
    // ejecutar mutación updateUser
    changeTimezone({
      variables: { newTimezone: time },
    }).then(
      ({ data: { updateTimezone } }) => {
        dispatch({
          type: CHANGE_TIMEZONE,
          data: updateTimezone,
        })
        showAlert(
          {
            text: translate('modal.changeTimezone.successAlert'),
            status: 'success',
          },
          dispatch,
        )
        // deselecciona el horario seleccionado
        selectBlock(null, false)
      },
      (err) => {
        console.error(err)
        showAlert(
          {
            text: translate('modal.changeTimezone.errorAlert'),
            status: 'error',
          },
          dispatch,
        )
      },
    )
    // changeTimezone({
    //  input: {
    //    timezone: time,
    //  },
    // })
  }

  const noBlocks = dataFull?.length === 0
  return (
    <div className='m-0 p-0'>
      <span className='text-title'>
        <b>{translate('home.onboard.approve.scheduled.chooseOr')}</b>
      </span>{' '}
      <br />
      <span className='text-title'>
        {translate('home.onboard.approve.scheduled.orSubtitle')}
      </span>
      <ChangeTimezone setTime={setZone} time={zone}>
        <ListItem
          className='hover-no-border'
          hasHover
          showBottomLine
          showTopLine
          Left={
            <div className='d-flex align-items-center text-muted pl-2'>
              <IconCountry className='mx-3' size={16} />
              <span className='ml-2'>{zone}</span>
            </div>
          }
          Right={
            <div className='hover-icon pr-2'>
              <IconArrowRight />
            </div>
          }
        />
      </ChangeTimezone>
      <div className='m-0 pt-6 pb-0 px-0'>
        {loading ? (
          <Skeleton count={6} height={39} />
        ) : !noBlocks ? (
          <>
            {/* <ListOption
              key={-1}
              classNameFilledIcon='znk-list-option-selected-inner-gold'
              className={`text-title mb-2 ${selectedTime === "2022-08-08T11:00:00-04:00" ? 'selected-secondary' : ''
                }`}
              childClassName='d-flex flex-row'
              selectedInput={selectedTime === "2022-08-08T11:00:00-04:00"}
              id='x-0'
              label={
                <div
                  className='m-0 p-0 ml-4 w-100'
                  style={{ overflowX: 'hidden' }}
                >
                  <span className='h4' style={{ whiteSpace: 'nowrap' }}>
                    another
                  </span>
                </div>
              }
              icon={
                <IconProgram
                  size={16}
                  fill='logo'
                  className='znk-icon-service align-self-center position-absolute'
                />
              }
              onClick={() => {
                selectBlock("2022-08-08T11:00:00-04:00", true)
              }}
            /> */}
            {dataHour.map((time, index) => {
              return (
                <ListOption
                  key={index}
                  classNameFilledIcon='znk-list-option-selected-inner-gold'
                  className={`text-title mb-2 ${selectedTime === time ? 'selected-secondary' : ''
                    }`}
                  childClassName='d-flex flex-row'
                  selectedInput={selectedTime === time}
                  id='x-0'
                  label={
                    <div
                      className='m-0 p-0 ml-4 w-100'
                      style={{ overflowX: 'hidden' }}
                    >
                      <span className='h4' style={{ whiteSpace: 'nowrap' }}>{`
                          ${moment(time).tz(zone).format('ddd D, MMM')} 
                          ${moment(time).tz(zone).format('h:mma')} - ${moment(
                        time,
                      )
                          .tz(zone)
                          .clone()
                          .add(15, 'minutes')
                          .format('h:mma')}
                          `}</span>
                    </div>
                  }
                  icon={
                    <IconProgram
                      size={16}
                      fill='logo'
                      className='znk-icon-service align-self-center position-absolute'
                    />
                  }
                  onClick={() => {
                    selectBlock(time, true)
                    // 2022-08-08T10:45:00-04:00
                  }}
                />
              )
            })}
          </>
        ) : (
          <div className='d-flex flex-column m-0 p-0 my-2'>
            <span className='text-title'>
              {translate('home.onboard.approve.scheduled.noCounselorTime')}
            </span>
            <span
              className='btn-link'
              onClick={() => {
                selectBlock(
                  moment().year(2000).month(0).set('date', 15).toISOString(),
                  true,
                )
              }}
            >
              {translate(
                'home.onboard.approve.scheduled.noCounselorTimeToContinue',
              )}
            </span>
          </div>
        )}
      </div>
      {!loading && dataHour.length < dataFull.length && !noBlocks && (

        <div className='d-flex flex-row justify-content-center cursor-pointer text-primary'>
          <span
            className='btn-link'
            onClick={more}
          >
            {translate('home.onboard.approve.scheduled.orShowMore')}
          </span>
        </div>

      )}
      <StickyButton
        condition={selectedTime !== null}
        onClickButtonFunction={() => {
          // Si no hay horarios disponibles usa la hora actual
          // luego lección será creada en estado pending
          const { page: next, key: nextKey, index } = nextPage(
            scheduledTimes,
            keyLesson,
          )
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.data',
            data: { ...dataRedux, keyLesson: nextKey, indexLesson: index },
          })
          if (next === 'end') {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.smallModal',
              data: { ...reduxSmall, isOpen: false },
            })
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.mediumModal',
              data: { ...reduxMedium, page: 'confirmScheduled' },
            })
          } else setPage(next)
        }}
      />
    </div>
  )
}

export default { ScheduledOrientationHeader, ScheduledOrientationBody }
