import cancel from './cancel'
import infolesson from './infolesson'
import sendnotes from './sendnotes'
import rate from './rate'

export default {
  cancel,
  infolesson,
  rate,
  sendnotes,
}
