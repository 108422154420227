import React from 'react'
import {Button, InputSelect as Select} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import useModalState from '../../../../../../hooks/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'
import escapeRegExp from 'lodash/escapeRegExp'
import {Collapse} from 'reactstrap'

const TopicSelection = (props) => {
  const {modalData, onChange} = useModalState('program.newManualLesson')
  const services = useSelector((state) => state.services.services)
  const {serviceId, topicId} = modalData
  const {translate} = useTranslation()
  return (
    <div className='p-0 m-0'>
      <span className='text-body'>
        {translate('modal.newlesson.serviceSelect')}
      </span>
      <Select
        value={serviceId}
        InputClassName='mt-2 mb-4'
        onChange={(id) =>
          onChange((s) => ({...s, serviceId: id, topicId: null}))
        }
        options={Object.values(services).map((s) => ({
          label: s.name,
          value: s.id,
        }))}
        filterOption={(obj, string) => {
          if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
          return false
        }}
      />

      <Collapse isOpen={serviceId}>
        <span className='text-body'>
          {translate('modal.newlesson.topicSelect')}
        </span>
        <Select
          value={topicId}
          InputClassName='mt-2 mb-4'
          onChange={(id) => onChange((s) => ({...s, topicId: id}))}
          options={
            serviceId
              ? Object.values(services[serviceId].topics).map((t) => ({
                  label: t.name,
                  value: t.id,
                }))
              : []
          }
          filterOption={(obj, string) => {
            if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
              return true
            return false
          }}
        />
      </Collapse>
      <Button
        disabled={!serviceId || !topicId}
        color='primary'
        className='w-100 mt-2'
        check
        onClick={() => onChange((s) => ({...s, stage: 1}))}
        label={translate('modal.newlesson.continue')}
      />
    </div>
  )
}

export default TopicSelection
