import React from 'react'
import {Action} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'

const NoTasksOnList = () => {
  const {translate} = useTranslation()

  return (
    <Action
      color='transparent'
      textColor='#2C3F78'
      border='dashed 1px #2C3F78'
      className='mt-3'
      labelLeft={
        <span className='w-100 d-flex flex-column my-3'>
          <label className='m-0'>{translate('counseling.noTasks')}</label>
        </span>
      }
      labelRight={null}
      action={null}
    />
  )
}
export default NoTasksOnList
