export default {
  pendingTask: 'Pending Tasks',
  reviewTask: 'Tasks under Review',
  completedTask: 'Completed Tasks',
  yourTeam: 'Your Team',
  docsExams: 'Docs & Exams',
  docsExamsText: 'Learn more about college counseling',
  docsExamsMainText:
    'Aquí podrás ver los documentos en los que tengas que trabajar y los examenes para que ingreses tu puntaje.',
  contact: 'Contact your educator',
  maxFilesError: 'Max quantity of file you can choose is: ',
  maxSizeError: 'Max size of files is :',
  title1: 'Uploaded Files on Task',
  title2: 'Upload this files?',
  loginInfoModalTitle: 'New access key',
  loginInfoModalSub:
    'This information is confidential and it will be used for our strict use only',
  account: 'Account',
  user: 'User',
  password: 'Password',
  save: 'Save access key',
  selectDate: 'Select a date',
  sendReview: 'Send to Review',
  inReview: 'In Review',
  completed: 'Completed',
  deleteFiles: 'Delete Selected(s) File(s)',
  noFiles: "There's no files uploaded on your task",
  registerScoresButton: 'Register scores',
  rescheduledMessage: 'Meeting re scheduled succesfully',
  learnMoreCounseling: 'Learn more about college counseling',
  emptyCarrousel:
    'Aquí podrás ágregar las universidades a las que te interese postular para hacer un seguimiento del proceso de admisión.',
  myUniversities: 'My universities',
  explore: 'Explore',
  allUniversities: 'All Universities',
}
