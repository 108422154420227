import React, {useState, useEffect} from 'react'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import {
  useWindowDimensions,
  IconStarFilled,
  IconTask,
  Task,
  Avatar,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {useSelector, useDispatch} from 'react-redux'
import {TabContent, TabPane} from 'reactstrap'
import {UseGetAllUserTasks} from '../../../../../Model/Users/Student/getAllUserTasks/useGetAllUserTasks'
import NoTasksComponent from '../../../Student/NoTasksOnMain'
import useShowTask from '../../ViewTask/useShowTask'
import NoTasksOnList from '../../ViewTask/Components/noTasksOnList'
import showAvatarAssigned from '../../../Utils/showAvatarAssigned'
import {COUNSELING_ALL_TASKS} from '../../../../../store/user/const'
import IsSpecific from '../../ViewTask/Components/IsSpecific'

const TasksSection = (props) => {
  const {planId, dataTeam} = props
  const allTasksReduxData = useSelector(
    (state) => state.user.counseling?.allTasks,
  )
  const [counselor, setCounselor] = useState({})
  const dispatch = useDispatch()
  const universityId = ''
  const taskStatus = ['pending', 'completed', 'review']
  const tasksState = useSelector((state) => state.user.info.studentInfo.tasks)
  const studentInfo = useSelector((state) => state.user.info)
  // Modal Pending Task
  const {open: openTask} = useShowTask()

  //  eslint-disable-next-line
  const [task, setTask] = useState(null)
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const showTask = (task) => {
    setTask(task)
    openTask(() => {}, task)
  }
  const [
    getAllUserTasks,
    {data: dataAllUserTasks, loading},
  ] = UseGetAllUserTasks()
  // Set pending tasks
  const [pendingList, setPendingList] = useState([])
  // Set complete tasks
  const [completeList, setCompleteList] = useState([])
  // Set review tasks
  const [reviewList, setReviewList] = useState([])

  useEffect(() => {
    if (planId && planId !== '') {
      getAllUserTasks({
        variables: {
          universityId,
          status: taskStatus,
          planId,
        },
      })
    }
    //  eslint-disable-next-line
  }, [planId, tasksState])

  useEffect(() => {
    // Cada vez que se terminen de actualizar las tasks (traemos todas), hacemos un filter para cada estado
    if (dataAllUserTasks?.getUserTasks?.length > 0) {
      dispatch({
        type: COUNSELING_ALL_TASKS,
        payload: 'counseling.allTasks',
        data: dataAllUserTasks?.getUserTasks.filter(
          ({deleteAt}) => !deleteAt || deleteAt === null,
        ),
      })
    }
    //  eslint-disable-next-line
  }, [dataAllUserTasks])

  useEffect(() => {
    const pendingArray = allTasksReduxData?.filter(
      (task) => task.status === 'pending',
    )
    setPendingList(pendingArray)
    const completedArray = allTasksReduxData?.filter(
      (task) => task.status === 'completed',
    )
    setCompleteList(completedArray)
    const reviewArray = allTasksReduxData?.filter(
      (task) => task.status === 'review',
    )
    setReviewList(reviewArray)
  }, [allTasksReduxData])

  useEffect(() => {
    if (dataTeam) {
      const staffFilter = dataTeam?.filter(
        (staff) => staff?.role === 'counselor',
      )
      setCounselor(staffFilter[0])
    }
  }, [dataTeam])

  return (
    <TabContent activeTab={loading ? `loading` : `show`}>
      <TabPane tabId='loading'>
        <>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            <table className='w-100 mt-3'>
              <Skeleton count={1} height={20} width={300} />
              <div className='d-flex flex-column'>
                {new Array(3).fill(0).map(() => {
                  return (
                    <Skeleton
                      className='mx-1'
                      count={1}
                      height={20}
                      width={700}
                    />
                  )
                })}
              </div>
            </table>
          </div>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            <table className='w-100 mt-2'>
              <Skeleton count={1} height={20} width={300} />
              <div className='d-flex flex-column'>
                {new Array(3).fill(0).map(() => {
                  return (
                    <Skeleton
                      className='mx-1'
                      count={1}
                      height={20}
                      width={700}
                    />
                  )
                })}
              </div>
            </table>
          </div>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            <table className='w-100 mt-2'>
              <Skeleton count={1} height={20} width={300} />
              <div className='d-flex flex-column'>
                {new Array(3).fill(0).map(() => {
                  return (
                    <Skeleton
                      className='mx-1'
                      count={1}
                      height={20}
                      width={700}
                    />
                  )
                })}
              </div>
            </table>
          </div>
        </>
      </TabPane>
      <TabPane tabId='show'>
        {(!dataAllUserTasks?.getUserTasks ||
          dataAllUserTasks?.getUserTasks?.length === 0) && <NoTasksComponent />}
        <>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            {(pendingList?.length > 0 || reviewList?.length > 0) && (
              <>
                <span>{translate('counseling.pendingTask')}</span>
                {pendingList?.length === 0 && <NoTasksOnList />}
              </>
            )}
            <table className='w-100 mt-3'>
              {!loading && pendingList?.length > 0
                ? pendingList.map((userTask) => {
                    const dueDate = moment(userTask.dueDate).format('MMM Do')
                    return (
                      <Task
                        style={{
                          cursor: 'pointer',
                          color: '#2C3F78',
                        }}
                        noIcon
                        className={`${
                          moment(userTask.dueDate) < moment()
                            ? 'late'
                            : moment(userTask.dueDate) > moment()
                            ? 'text-gray'
                            : 'text-primary'
                        } w-20 d-flex flex-row justify-content-end mr-2`}
                        taskClassName='my-2'
                        labelLeft={
                          <div className='text-truncate w-100'>
                            <IconStarFilled className='mr-2' size={14} />
                            <span
                              style={{
                                lineHeight: '1.6',
                                color: '#2C3F78',
                              }}
                            >
                              {userTask.name}
                            </span>
                          </div>
                        }
                        labelRight={
                          <>
                            {userTask?.isEspecific && (
                              <IsSpecific
                                universitiesList={userTask?.universities || []}
                                findInDB
                                showLabel={false}
                                showTooltip
                                className='mr-2'
                                iconColor='counseling'
                              />
                            )}

                            {userTask.userId === studentInfo.id
                              ? dueDate
                              : showAvatarAssigned(
                                  userTask.userId,
                                  dataTeam,
                                  'named',
                                )}
                          </>
                        }
                        onClick={() => {
                          showTask(userTask)
                        }}
                      />
                    )
                  })
                : null}
            </table>
          </div>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            {(reviewList?.length > 0 || completeList?.length > 0) && (
              <>
                <span>{translate('counseling.reviewTask')}</span>
                {reviewList?.length === 0 && <NoTasksOnList />}
              </>
            )}

            <table className='w-100 mt-2'>
              {!loading && reviewList?.length > 0
                ? reviewList?.map((userTask) => {
                    return (
                      <Task
                        style={{cursor: 'pointer', color: '#2C3F78'}}
                        noIcon
                        taskClassName='my-2'
                        className='w-20 d-flex justify-content-end mr-2'
                        labelLeft={
                          <div className='text-truncate w-100'>
                            <IconStarFilled className='mr-2' size={14} />
                            <span
                              style={{
                                lineHeight: '1.6',
                                color: '#2C3F78',
                              }}
                            >
                              {userTask.name}
                            </span>
                          </div>
                        }
                        labelRight={
                          <>
                            {userTask?.isEspecific && (
                              <IsSpecific
                                universitiesList={userTask?.universities || []}
                                findInDB
                                showLabel={false}
                                showTooltip
                                className='mr-2'
                                iconColor='counseling'
                              />
                            )}
                            <div className='d-flex flex-row align-items-center'>
                              <div className='mr-2'>
                                <span
                                  style={{
                                    color: '#2C3F78',
                                  }}
                                >
                                  {counselor?.firstName +
                                    ' ' +
                                    counselor?.lastName?.charAt(0) +
                                    '.'}
                                </span>
                              </div>
                              <Avatar
                                classNameAvatar='w-100 text-align-center'
                                uri={counselor?.avatar || ''}
                                name={
                                  counselor?.firstName +
                                  ' ' +
                                  counselor?.lastName
                                }
                                size='avatar-small'
                              />
                            </div>
                          </>
                        }
                        onClick={() => {
                          showTask(userTask)
                        }}
                      />
                    )
                  })
                : null}
            </table>
          </div>
          <div
            className={`mt-2 mt-md-5 px-0 ${
              breakWidth === 'SM' ? 'text-truncate' : ''
            }`}
          >
            {completeList?.length > 0 && (
              <span>{translate('counseling.completedTask')}</span>
            )}
            <table className='w-100 mt-2'>
              {!loading && completeList?.length > 0
                ? completeList.map((task) => {
                    const dueDate = moment(task.dueDate).format('MMM Do')
                    return (
                      <Task
                        style={{cursor: 'pointer'}}
                        noIcon
                        className={`d-flex justify-content-end mr-2 ${
                          breakWidth === 'SM' ? 'w-30' : 'w-20'
                        }`}
                        taskClassName='my-2'
                        labelLeft={
                          <div>
                            <IconStarFilled className='mr-2' size={14} />
                            <span
                              style={{
                                textDecorationLine: 'line-through',
                              }}
                              className='text-gray'
                            >
                              {task.name}
                            </span>
                          </div>
                        }
                        labelRight={
                          <>
                            {task?.isEspecific && (
                              <IsSpecific
                                universitiesList={task?.universities || []}
                                findInDB
                                showLabel={false}
                                showTooltip
                                className='mr-2'
                                iconColor='counseling'
                              />
                            )}
                            <div>
                              <span className='text-gray mr-2'>{dueDate}</span>
                              <IconTask
                                color='#9fda1f'
                                fill='#ffffff'
                                size={16}
                              />
                            </div>
                          </>
                        }
                        onClick={() => {
                          showTask(task)
                        }}
                      />
                    )
                  })
                : null}
            </table>
          </div>
        </>
      </TabPane>
    </TabContent>
  )
}

export default TasksSection
