export const showAlert = (
  {
    text,
    status,
    onClick,
    time = 5000,
    permanet = false,
    id = new Date().getTime(),
    unreadMsgs,
  },
  dispatch,
) => {
  dispatch({
    type: 'ADD_ALERT',
    payload: {id, text, status, onClick, unreadMsgs},
  })
  if (!permanet) {
    setTimeout(() => {
      dispatch({
        type: 'REMOVE_ALERT',
        payload: {id},
      })
    }, time)
  }
}

export default {
  showAlert,
}
