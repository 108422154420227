import React, {useEffect, useState} from 'react'
import {getMessages, markAsReader} from '../../helpers/getMessages'
import {ChatBox} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import {Spinner} from 'reactstrap'
import NoHaveMessages from '../NoHaveMessages'

const message = {messages: [], error: null, loading: false}

const MessagesItems = (props) => {
  const {
    id: lessonID,
    __typename: type,
    usersList,
    joinedUser = [],
    isActive = false,
    onNewMessage = () => {},
  } = props
  const {id: userID, timezone} = useSelector((state) => state.user.info)
  const [messages, setMessages] = useState(message)
  const {loading, error} = messages
  const [msgWithRole, setMsgWithRole] = useState()

  useEffect(() => {
    getMessages(lessonID, setMessages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let currentUser = ''
    if (messages.messages.length > 0) {
      setMsgWithRole(
        messages?.messages.map((msg) => {
          const found = usersList.find((user) => user.id === msg.userID)
          if (!found && userID === msg.userID) currentUser = 'You'
          return {...msg, role: found?.role || currentUser}
        }),
      )
    }
    // eslint-disable-next-line
  }, [messages, loading])

  useEffect(() => {
    if (isActive && userID) {
      markAsReader(lessonID, userID)
    }
    // eslint-disable-next-line
  }, [isActive, userID])

  useEffect(
    () => {
      onNewMessage(messages)
    },
    // eslint-disable-next-line
  [messages])

  return (
    <>
      {loading ? (
        <>
          {messages.messages.length === 0 ? (
            <div
              className='d-flex flex-column justify-content-center align-items-center'
              style={{flexGrow: 1}}
            >
              <NoHaveMessages {...props} />
            </div>
          ) : (
            <div style={{flexGrow: 1}}>
              <ChatBox
                joinedUser={joinedUser} // [{id: 'userId', name: 'userName', date: 'joinDate'}]
                messages={msgWithRole}
                className={'znk-scroll-horizontal'}
                myUserId={userID}
                timezone={timezone}
                type={type}
              />
            </div>
          )}
        </>
      ) : (
        <div className='text-center m-9'>
          <Spinner type='grow' color='dark' />
        </div>
      )}
      {error && 'Error to get messages.'}
    </>
  )
}

export default MessagesItems
