import React from 'react'
import {useSelector} from 'react-redux'
import RedirectToLocalUrl from '../index'
import useTranslation from '../../../i18n/useTranslation'

const RedirectToPaymentMethods = () => {
  const paymentMethod = useSelector((state) => state.user.info.paymentMethod)
  const roles = useSelector((state) => state.user.info.roles)
  const {translate} = useTranslation()

  return (
    <RedirectToLocalUrl
      title={translate(`root.redirect.setPaymentMethod.title`)}
      body={translate(`root.redirect.setPaymentMethod.body`)}
      localUrl='/profile/info?action=addpayment'
      actionText={translate(`root.redirect.setPaymentMethod.action`)}
      show={!paymentMethod && roles?.includes('STUDENT') && false}
      dependency={[paymentMethod]}
    />
  )
}
export default RedirectToPaymentMethods
