const scoreCollectionQueryResult = `
      score {
          name
          examDate
          overall
          results { 
              name
              scale
              value
              values {
                  name
                  scale
                  value
              }
          }
      }

`

export default scoreCollectionQueryResult
