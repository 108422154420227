import React, {useState, useEffect, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  Button,
  IconRemove,
  Task,
  // IconArrowDown,
  // IconArrowUp,
  IconArrowRight,
  // IconTask,
  // IconErase,
  IconSignCheck,
} from '@Knowledge-OTP/znk-ui-components'
// import Skeleton from 'react-loading-skeleton'
import useAllUserTasks from '../../../../Task/Model/useAllUserTasks'
import useUploadFileToTask from '../../../../Task/Model/useUploadFileToTask'
import useRemoveFilesFromTask from '../../../../Task/Model/useRemoveFilesFromTasks'
import './index.css'
import MessageModal from './Modals/messageModal'
import {UseGetUserTask} from '../../../../../Model/Users/Student/getUserTask/useGetUserTask'
import ShowButtonByStatus from './showButtonsByStatus'
import useTranslation from '../../../../../i18n/useTranslation'
// import SendToReviewButtonOff from './Buttons/sendToReviewOff'
import useOperationTaskMutation from '../../../../Task/Model/useOperationTask'
import InfoTaskButton from './Buttons/infoTaskButton'
import dragAndDropIcon from '../../../../../assets/drag-and-drop.png'
import {COUNSELING_ALL_TASKS} from '../../../../../store/user/const'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import phaseNames from '../../../Utils/phaseNames'

const UploadFile = (props) => {
  // Si se cambia el máximo de archivos que se pueden subir, cambiar esta constante
  const maxNumOfFiles =
    process.env.MAX_QUANTITY_FILES ||
    process.env.REACT_APP_MAX_QUANTITY_FILES ||
    10 // Queda 10 por defecto, para evitar errores si no están las variables de entorno
  const maxSize = process.env.MAX_SIZE || process.env.REACT_APP_MAX_SIZE || 300 // Poner la cantidad máxima de mb
  const byteInMb = 1048576 // cuantos bytes son 1 mb
  const {translate} = useTranslation()
  const [message, setMessage] = useState('')
  const {task /** lessonId, */} = props
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )
  const reduxData = useSelector((state) => state.modal.counseling.viewTaskModal)
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [taskData, setTaskData] = useState(task)
  const refetchUserData = useSelector((state) => state.user.refetch)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [filesUploadedByUser, setFilesUploadedByUser] = useState([])
  const dropArea = document.querySelector('.file-select')
  const dragImage = document.getElementById('dragImage')
  const dragSpan = document.getElementById('dragSpan')
  const [loadingFiles, setLoadingFiles] = useState(false)
  const filesUploadedOnTask = useMemo(() => {
    return taskData?.resolveTask?.uploadFiles
  }, [taskData])
  // const [showFilesList, setShowFilesList] = useState(false)
  const [showFilesToUpload, setShowFilesToUpload] = useState(true)
  const [render, setRender] = useState(false)

  const checkBox = document.querySelectorAll('.checkbox-input')

  const infoByStatus = {
    pending: {
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconArrowRight
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    review: {
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    completed: {
      colorText: '#72809D',
      background: '#F0F4F8',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#72809D'
        />
      ),
    },
  }

  if (dropArea) {
    // If user Drag File Over DropArea
    dropArea.addEventListener('dragover', (event) => {
      event.preventDefault() // preventing from default behaviour
      dropArea.classList.add('active')
      dragImage.style.visibility = 'visible'
      dragSpan.style.visibility = 'hidden'
    })

    // If user leave dragged File from DropArea
    dropArea.addEventListener('dragleave', () => {
      dropArea.classList.remove('active')
      dragImage.style.visibility = 'hidden'
      dragSpan.style.visibility = 'visible'
    })
  }

  // Query para traer una sola task
  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      const taskToSave = resp?.getUserTask
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.viewTaskModal',
        data: {
          ...reduxData,
          task: taskToSave,
        },
      })
      // setTaskData(resp?.getUserTask)
      setShowFilesToUpload(false)
      // refetchUserData()
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  // Query para traer todas las tasks del Plan.
  // Se usa acá solo para hacer el refetch y actualizar el listado ante cada cambio que se haga
  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  // Mutation para subir archivos a la task
  const {
    submit: submitUpload,
    loading: uploadLoading,
    data: uploadData,
  } = useUploadFileToTask(
    () => {
      sendToReviewFunction()
    },
    () => {},
  )

  // Mutation para borrar archivos subidos a la task
  const [
    removeFileMutation,
    // eslint-disable-next-line
    { errors: removeErrors, loading: removeLoading },
  ] = useRemoveFilesFromTask(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
    },
    (errors) => {},
  )

  // Mutation para enviar a review la task
  const [
    operationMutation,
    // eslint-disable-next-line
    { loading: loadMutation, data: dataMutation },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
      refetchUserData()
      refetchTasks().then((resp) => {
        dispatch({
          type: COUNSELING_ALL_TASKS,
          payload: 'counseling.allTasks',
          data: resp?.data?.getUserTasks,
        })
      })
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )

  const sendToReviewFunction = () => {
    operationMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase:
          task?.phase?.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase()
          }) || task?.phaseName,
        planId,
      },
    })
  }

  // Función que maneja los cambios en el Drag and Drop de los files
  const handleFileChange = (e) => {
    // If user drop File on DropArea
    dropArea.classList.remove('active')
    dragImage.style.visibility = 'hidden'
    dragSpan.style.visibility = 'visible'
    let fullSize = 0
    if (!e.target.files) return
    if (e.target.files?.length > maxNumOfFiles) {
      setMessage(translate('counseling.maxFilesError') + maxNumOfFiles)
      setMessageModalOpen(true)
    }

    if (e?.target?.files) {
      fullSize = Array.from(e?.target?.files).reduce((fullSize, file) => {
        return fullSize + file.size
      }, 0)
    }

    if (fullSize > maxSize * byteInMb) {
      setMessage(translate('counseling.maxSizeError') + `${maxSize} mb`)
      setMessageModalOpen(true)
    }

    setFilesUploadedByUser(
      filesUploadedByUser.concat(Array.from(e?.target?.files)),
    )
    if (showFilesToUpload === false) setShowFilesToUpload(true)

    setLoadingFiles(!loadingFiles)
  }

  // Función que es llamada para subir archivos a una task
  const uploadFileFunction = () => {
    if (filesUploadedByUser?.length === 0) return
    try {
      submitUpload({
        files: filesUploadedByUser,
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId,
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
  }

  // Función que es llamada al intentar borrar archivos de una task
  // eslint-disable-next-line
  const removeFileFunction = (urlsArray = []) => {
    // Busca las urls de los files checkeados
    if (urlsArray?.length === 0) {
      Array.from(checkBox).forEach((x) => {
        if (x.checked) {
          urlsArray.push(filesUploadedOnTask[x.id].url)
        }
      })
    }
    if (urlsArray?.length === 0) return

    removeFileMutation({
      variables: {
        files: urlsArray,
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId,
      },
    }).then((resp) => {
      if (resp?.data?.removeFilesFromTask === true) {
        refetchUserData()
        refetchTasks()
        // refetchOneTask()
        setShowFilesToUpload(false)
      } else {
        setMessage(removeErrors)
        setMessageModalOpen(true)
      }
    })
  }

  // Función para borrar un archivo del array de archivos que se va a subir
  const removeFromListFunction = (itemRemove) => {
    filesUploadedByUser.splice(itemRemove, 1)
    setRender(!render)
  }

  useEffect(() => {}, [render])

  /* eslint-disable */
  useEffect(() => {

    if (uploadData) {
      refetchUserData()
      refetchTasks()
      // refetchOneTask()
      setShowFilesToUpload(false)
      setFilesUploadedByUser([])
    }
  }, [uploadData])
  /* eslint-enable */

  return (
    <>
      {/* {loadingOneTask ? (
        <Skeleton height={20} />
      ) : (
        taskData?.status === 'pending' && (
          <div
            id='uploaded-files'
            className='mb-2 d-flex flex-column align-items-center justify-content-between'
          >
            <div className='w-100 d-flex justify-content-between'>
              <span className='h4 mb-1'> {translate('counseling.title1')}</span>
              {!showFilesList ? (
                <IconArrowDown
                  onClick={() => setShowFilesList(!showFilesList)}
                />
              ) : (
                <IconArrowUp onClick={() => setShowFilesList(!showFilesList)} />
              )}
            </div>
             <div
              className='w-100'
              style={{
                display: showFilesList ? 'block' : 'none',
              }}
            >
              {filesUploadedOnTask?.length > 0 ? (
                filesUploadedOnTask?.map((x, index) => (
                  <Task
                    style={{ cursor: 'pointer' }}
                    noIcon
                    taskClassName='w-100 my-2'
                    labelLeft={
                      // eslint-disable-next-line
                      <a href={x.url} target="_blank">
                        <div>
                          <span style={{ lineHeight: '1.6', color: '#4a6270' }}>
                            {x.url.split('/').at(-1)}
                          </span>
                        </div>
                      </a>
                    }
                    labelRight={
                      <div className='d-flex align-items-center justify-content-between'>
                        <Button
                          activity={removeLoading}
                          label=''
                          style={{ color: '#ff2323' }}
                          className='w-70 ml-1'
                          size='small'
                        // onClick={() => removeFileFunction([x.url])}
                        />
                        <IconErase
                          size={25}
                          color='red'
                          onClick={() => removeFileFunction([x.url])}
                        />
                      </div>
                    }
                    onClick={() => {
                      //   show file?
                    }}
                  />
                ))
              ) : (
                <Task
                  // style={{ cursor: 'pointer' }}
                  noIcon
                  taskClassName='w-100 my-2'
                  labelLeft={
                    <div>
                      <span style={{ lineHeight: '1.6', color: '#4a6270' }}>
                        {translate('counseling.noFiles')}
                      </span>
                    </div>
                  }
                />
              )}
            //    {filesUploadedOnTask?.length > 0 && (
            //   <div className='d-flex justify-content-end'>
            //     <Button
            //       activity={removeLoading}
            //       label={translate('counseling.deleteFiles')}
            //       style={{ backgroundColor: '#ff2323' }}
            //       className='w-70 ml-1'
            //       size='large'
            //       onClick={() => removeFileFunction()}
            //     />
            //   </div>
            // )} 
            </div> 
          </div>*
        )
      )} */}
      {task?.status === 'pending' && (
        <div
          id='drop-zone'
          className='file-select mb-4 d-flex flex-column align-items-center justify-content-center'
        >
          <img
            className='d-flex align-items-center justify-content-center'
            style={{visibility: 'hidden'}}
            id='dragImage'
            src={dragAndDropIcon}
            alt='Early Decision Icon'
            color='#696bff'
            width='100'
            height='100'
          />
          <span
            className='d-flex flex-column align-items-center justify-content-center'
            style={{
              visibility: 'visible',
              textAlign: 'center',
            }}
            id='dragSpan'
            color='#696bff'
            width='100'
            height='100'
          >
            {' '}
            Drag and Drop here or <br />
            <span className='cursor-pointer'>
              {' '}
              <u>{translate('modal.task.uploadfilebuton.browse')} </u>{' '}
            </span>
          </span>
          <input
            className='d-flex justify-content-center py-2 mb-4 input-drop'
            type='file'
            style={{backgroundColor: 'transparent'}}
            id='input'
            name='input'
            onChange={handleFileChange}
            multiple
          />
        </div>
      )}
      {task?.status === 'pending' && (
        <>
          <div
            id='files-info'
            className='d-flex flex-column mb-4'
            style={{
              display: showFilesToUpload ? 'block' : 'none',
            }}
          >
            {filesUploadedByUser?.length > 0 && (
              <span className='h4 mb-1'>
                {' '}
                {translate('counseling.title2')}{' '}
              </span>
            )}
            {filesUploadedByUser?.length > 0 &&
              filesUploadedByUser?.map((x, index) => (
                <div className='d-flex flex-row align-items-center justify-content-between'>
                  <Task
                    noIcon
                    taskClassName='my-1'
                    labelLeft={
                      <div>
                        <span style={{lineHeight: '1.6', color: '#4a6270'}}>
                          {x.name}
                        </span>
                      </div>
                    }
                    labelRight={
                      <IconRemove
                        style={{cursor: 'pointer'}}
                        onClick={() => removeFromListFunction(index)}
                      />
                    }
                  />
                </div>
              ))}
          </div>
          <div>
            <Button
              label={taskData?.buttonLabel}
              activity={uploadLoading}
              style={{backgroundColor: '#696CFF'}}
              className='w-100 mb-4'
              size='large'
              onClick={() => uploadFileFunction()}
              disabled={filesUploadedByUser?.length === 0}
            />
          </div>
        </>
      )}
      {task?.status !== 'pending' &&
        task?.resolveTask?.uploadFiles?.length > 0 &&
        task?.resolveTask?.uploadFiles?.map((file) => {
          return (
            <InfoTaskButton
              background={infoByStatus[task?.status].background}
              colorText={infoByStatus[task?.status].colorText}
              onClick={() => {
                window.open(file.url)
              }}
              icon={infoByStatus[task?.status].icon}
              textLabel={file.url.split('/').at(-1)}
            />
          )
        })}

      {task?.status !== 'pending' && <ShowButtonByStatus taskData={task} />}
      {/* {taskData?.status === 'pending' &&
        taskData?.resolveTask?.uploadFiles?.length === 0 ? (
        <div
          className='d-flex justify-content-center py-2'
          style={{
            width: '100%',
            border: '1px solid',
            borderRadius: '10px',
            borderColor: '#999bf7',
          }}
        >
          <div className='d-flex justify-content-between my-auto w-100'>
            <div className='col-10'>
              <span className='d-flex justify-content-start align-middle text-gray'>
                {translate('counseling.sendReview')}
              </span>
            </div>
            <div className='col-2 d-flex justify-content-end align-items-center'>
              <IconTask
                color='#ffffff'
                fill='#b0b2f9'
                stroke='#b0b2f9'
                strokeWidth='2'
                size={16}
              />
            </div>
          </div>
        </div>
      ) : taskData?.status === 'pending' &&
        (taskData?.resolveTask?.uploadFiles?.length === 0 ||
          taskData?.resolveTask === null) ? (
        <SendToReviewButtonOff />
      ) : (
        <ShowButtonByStatus taskData={taskData} />
      )} */}
      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
    </>
  )
}

export default UploadFile
