import React, {useMemo, useState} from 'react'
import {StudentLessonCalendar} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import CalendarViewStudentForEducator from '../../../../../Model/CalendarEvent/List/CalendarViewStudentForEducator/index'
import {useSelector} from 'react-redux'

const Calendar = (props) => {
  const {userData} = props

  const [now, setNow] = useState(moment())
  const [from, to] = useMemo(() => {
    // let now = moment().add(week, 'week')
    return [now.clone().startOf('isoWeek'), now.clone().endOf('isoWeek')]
  }, [now])
  const info = useSelector((state) => state.user.info)
  // obtener free times del estudiante incluyendo al educador (usuario)
  const {data} = CalendarViewStudentForEducator({
    studentId: userData?.id || '',
    from,
    to,
  })
  return (
    <StudentLessonCalendar
      lessons={data || []}
      setMoment={setNow}
      moment={now}
      idEducator={info?.id || null}
    />
  )
}

export default Calendar
