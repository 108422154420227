import {useLazyQuery, gql} from '@apollo/client'
import queryMyTeam from '../../QueryResult/useMyTeam'

export const UseMyTeam = () => {
  return useLazyQuery(
    gql`query(
           $planId: ID!
          )
          {
             myPlan(
               planId: $planId
           ) {
      ${queryMyTeam}
      status
      }
       }`,
  )
}
