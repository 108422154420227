import React, {useEffect, useMemo, useState} from 'react'
import * as moment from 'moment'
import {
  Avatar,
  IconProgram,
  LessonSelectCalendar,
  ListItem,
  ListOption,
  LoadingSpinner,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import useModalState from '../../../../../../hooks/useModalState'
import {gql, useLazyQuery} from '@apollo/client'
import useTranslation from '../../../../../../i18n/useTranslation'
import {TabContent, TabPane} from 'reactstrap'

const TimeSelection = (props) => {
  const {modalData, onChange} = useModalState('program.newManualLesson')
  const {translate} = useTranslation()
  const {educator, stage, dateTime} = modalData
  const [selectedBlock, setSelectedBlock] = useState(null)
  const baseLimit = useMemo(() => moment().add(24, 'h').endOf('hour'), [])
  const [time, setTime] = useState(() => moment().startOf('isoWeek'))
  const [view, setView] = useState('blocks') // For switching between lists and calendar
  const [from, to] = useMemo(() => {
    if (view === 'blocks') {
      const possibleHours = moment().add(24, 'h')
      const from = possibleHours.toISOString()
      const to = possibleHours.clone().add(7, 'd').toISOString()
      return [from, to]
    } else {
      const from = time.toISOString()
      const to = time.clone().add(7, 'd').toISOString()
      return [from, to]
    }
  }, [time, view])
  const [getFreeTimeFrames, {data, loading}] = useLazyQuery(
    gql`
      query(
        $educatorId: ID
        $minDurationInMinutes: Int
        $to: DateTime!
        $from: DateTime!
        $involveMyself: Boolean!
      ) {
        myFreeTimeFrames(
          educatorUserId: $educatorId
          minDurationInMinutes: $minDurationInMinutes
          to: $to
          from: $from
          involveMyself: $involveMyself
        )
      }
    `,
  )
  useEffect(() => {
    if (educator && from && to && stage === 2) {
      getFreeTimeFrames({
        variables: {
          educatorId: educator.id,
          from,
          to,
          minDurationInMinutes: 50,
          involveMyself: true,
        },
        fetchPolicy: 'network-only',
      })
    }
  }, [educator, from, to, stage, getFreeTimeFrames])
  // const timeFrames = data?.myFreeTimeFrames ?? []
  const possibleBlocks = useMemo(() => {
    return (data?.myFreeTimeFrames || [])
      .reduce((arr, timeFrame) => {
        const [fromISO, toISO] = timeFrame
        const fromMoment = moment(fromISO)
        const toMoment = moment(toISO)
          .subtract(50, 'minute')
          .milliseconds(0)
          .seconds(0)
          .minutes(0)
        // eslint-disable-next-line
          while (fromMoment < toMoment) {
          const lessonArr = toMoment.clone()
          arr.unshift(lessonArr)
          toMoment.subtract(1, 'h')
        }
        return arr
      }, [])
      .sort((a, b) => a.valueOf() - b.valueOf())
  }, [data])

  const [page, setPage] = useState(0)
  const [visibleBlocks, setVisibleBlocks] = useState([])
  useEffect(
    () => {
      const newArrayConcat = (possibleBlocks || []).slice(
        page * 5,
        (page + 1) * 5,
      )
      if (page === 0) setVisibleBlocks(newArrayConcat)
      else if (visibleBlocks.length < (page + 1) * 5)
        setVisibleBlocks((visibleBlocks || []).concat(newArrayConcat))
    },
    // eslint-disable-next-line
    [page, possibleBlocks])

  return (
    <>
      <ListItem
        className='m-0 p-0 pr-3 py-3 px-1 hover-no-border'
        showTopLine
        showBottomLine
        hasHover
        hasColorHover
        onClick={() => onChange((s) => ({...s, stage: s.stage - 1}))}
        Left={
          <div className='d-flex align-items-center text-muted'>
            {educator ? (
              <>
                <Avatar
                  name={`${educator.firstName} ${educator.lastName}`}
                  named={false}
                  uri={educator.avatar}
                  size='avatar-medium text-white'
                  useHover={false}
                />
                <span className='ml-2'>
                  {educator.firstName} {educator.lastName}
                </span>
              </>
            ) : (
              <span className='text-secondary'>
                {translate('lessons.selectAnEducator')}
              </span>
            )}
          </div>
        }
      />
      <TabContent activeTab={view} className='mb-4 mt-4'>
        <TabPane tabId='blocks'>
          <div
            className='w-100'
            // style={{maxHeight: '350px', overflowY: 'auto', overflowX: 'hidden'}}
          >
            {loading ? (
              <div className='w-100 d-flex flex-row align-items-center justify-content-center my-2'>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                {visibleBlocks.map((item) => {
                  const dateLabel = `${item.format(
                    'h:mma',
                  )} to ${item
                    .clone()
                    .add(50, 'm')
                    .format('h:mma')} | ${item.format('MMM D, YYYY')}`
                  return (
                    <ListOption
                      className='mb-2 px-3'
                      label={null}
                      selectedInput={item.toISOString() === dateTime}
                      key={item.valueOf()}
                      icon={
                        <div className='d-flex align-items-center text-muted'>
                          <IconProgram
                            size={16}
                            className='znk-icon-service text-logo'
                          />
                          <span
                            className='ml-2 text-truncate'
                            title={dateLabel}
                          >
                            {dateLabel}
                          </span>
                        </div>
                      }
                      childClassName='d-flex align-items-center'
                      onClick={() =>
                        onChange((s) => ({...s, dateTime: item.toISOString()}))
                      }
                    />
                  )
                })}
                {possibleBlocks.length > visibleBlocks.length && (
                  <div className='d-flex flex-row w-100 justify-content-center py-2'>
                    <span
                      onClick={() => {
                        setPage(page + 1)
                      }}
                      className='btn-link'
                    >
                      Show More
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <span className='btn-link mt-5' onClick={() => setView('calendar')}>
            {translate('modal.newlesson.needmoretimes')}
          </span>
        </TabPane>
        <TabPane tabId='calendar'>
          <LessonSelectCalendar
            moment={time}
            setMoment={setTime}
            availabilityBlocks={data?.myFreeTimeFrames || []}
            loading={loading}
            selectedBlock={selectedBlock}
            setSelected={(block) => {
              setSelectedBlock(block)
              onChange((s) => ({...s, dateTime: block?.date}))
            }}
            limitFrom={baseLimit}
          />
        </TabPane>
      </TabContent>
      <StickyButton
        condition={true || !!dateTime}
        disabled={!dateTime}
        activity={loading}
        color='#696CFF'
        onClickButtonFunction={() => onChange((s) => ({...s, stage: 3}))}
        label={translate('modal.newlesson.continue')}
      />
    </>
  )
}

export default TimeSelection
