import React, {useState} from 'react'
// import {OrientationLessonModal} from '@Knowledge-OTP/znk-ui-components'
import OrientationLessonModal from '../../../Workspace/Student/ViewTask/Components/Modals/OrientationLessonModal'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {hasHttp} from '../../../Calendar/Lessons/Modal/InfoModal/utils'
import CheckInLesson from '../../../../Model/Lessons/CheckInLesson'
import useLinkLesson from '../../../Calendar/Lessons/Modal/InfoModal/useLinkLesson'
import SendLessonAttendance from '../../../../Model/Lessons/SendLessonAttendance'
import {showAlert} from '../../../../common/Alert/util'

const OrientationModal = () => {
  // redux modal para lecciones de orientación
  const reduxOrientation = useSelector(
    (state) => state.modal.calendar.orientationLessonModal,
  )
  const {
    isOpen: isOpenOrientation,
    dataLesson: dataLessonOrientation,
  } = reduxOrientation
  const {translate} = useTranslation()
  const dispatch = useDispatch()

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const [backupLoad, setBackupLoad] = useState(false)

  const setDataLessonToRedux = (newDataLesson) => {
    // setea lección modificada en redux, se usa luego de ejecutar una mutación
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.orientationLessonModal',
      data: {
        isOpen: true,
        dataLesson: newDataLesson,
      },
    })
  }

  const {loading: loadCheckIn, submit: checkIn} = CheckInLesson(
    ({data}) => {
      // actualizar en redux
      setDataLessonToRedux(data?.checkInLesson)
    },
    (error) => {
      console.error(error)
    },
  )

  // set student/educator attendance
  const [
    setAttendanceMutation,
    {loading: loadAttendanceMutation},
  ] = SendLessonAttendance(
    ({data}) => {
      setDataLessonToRedux(data?.sendLessonAttendance)
      showAlert(
        {
          text: translate('modal.infolesson.saveAttendanceSuccess'),
          status: 'success',
        },
        dispatch,
      )
      // refetchCalendarQuery()
    },
    (error) => {
      console.error(error)
      showAlert(
        {
          text: translate('modal.infolesson.saveAttendanceError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  return (
    <OrientationLessonModal
      role='educator'
      isOpen={isOpenOrientation}
      toggle={() => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'calendar.orientationLessonModal',
          data: {
            ...reduxOrientation,
            isOpen: !isOpenOrientation,
          },
        })
      }}
      dataLesson={dataLessonOrientation}
      onRescheduled={() => {}}
      onCheckIn={(dataLesson) => {
        checkIn({lessonId: dataLesson?.id || ''})
      }}
      onCheckInLoad={loadCheckIn}
      onCancel={(dataLesson) => {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'calendar.postponeLesson',
          data: {
            isOpen: true,
            lessonId: dataLesson?.id || '',
            isOrientation: true,
          },
        })
      }}
      cancelText={translate('modal.infolesson.youneedremove')}
      onGoToClass={(dataLesson) => {
        // si location es VCR es launchURL, sino es location.linkStudent!
        setGoToClassLoad(true)
        getvclink(dataLesson?.id, null)
          .then(
            ({launchUrl, location}) => {
              if (location.type === 'virtualClassroom')
                window.open(hasHttp(launchUrl), '_blank')
              else window.open(hasHttp(location.linkStudent), '_blank')
              setGoToClassLoad(false)
            },
            (error) => {
              console.error(error)
              // setVcLinkError(true)
              setGoToClassLoad(false)
            },
          )
          .catch((error) => {
            console.error(error)
            // setVcLinkError(true)
            setGoToClassLoad(false)
          })
      }}
      onGoToClassLoad={goToClassLoad}
      gotoclassText='Go to classroom'
      onBackUp={(dataLesson) => {
        setBackupLoad(true)
        window.open(hasHttp(dataLesson?.backupLink), '_blank')
        setBackupLoad(false)
      }}
      onBackUpLoad={backupLoad}
      backupText='Backup call'
      studentText={translate('modal.infolesson.student')}
      educatorText={translate('modal.infolesson.educator')}
      onSaveAttendance={(dataLesson, studentAtt, educatorAtt) => {
        setAttendanceMutation({
          educatorAttendance: educatorAtt,
          attendance: Object.entries(studentAtt).map(([idStu, stuAtt]) => {
            return {
              studentId: idStu,
              status: stuAtt,
            }
          }),
          lessonId: dataLesson?.id || '',
        })
      }}
      saveAttendanceLoad={loadAttendanceMutation}
      onSendResume={(dataLesson) => {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'calendar.resumeOrientationModal',
          data: {
            isOpen: true,
            dataLesson: dataLesson,
          },
        })
      }}
      sendResumeText={translate('modal.infolesson.sendResume')}
    />
  )
}

export default OrientationModal
