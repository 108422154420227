const queryMyTeam = `
... on CouncelingPlan{
    councelor{
      teamUserId
      firstName
      lastName
    }
    advisor{
      teamUserId
      firstName
      lastName
    }
    head{
      teamUserId
      firstName
      lastName
    }

    aditionalInfo {
      avatarCounselor: avatarCouncelor
      avatarAdvisor
      avatarHead
    }
  }
`

export default queryMyTeam
