import React, {useEffect, useState} from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import {Collapse} from 'reactstrap'
import {
  setStatusOnFirebase,
  getStatus,
} from '../../../../common/FirebaseAlerts/helpers'
const KEY = `educatorWorkspace`
const CollapseMsg = (props) => {
  const {userId} = props
  const {translate} = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const [snapshot, setSnapshot] = useState(null)
  useEffect(() => {
    getStatus(userId, KEY, setSnapshot, setOpen)
  }, [userId])
  return (
    <Collapse isOpen={isOpen}>
      <div className='m-0 w-100 d-flex flex-column px-0 py-4'>
        <span className='h2 text-title font-weight-bold'>
          {translate('workspace.teach.topmsg.title')}
        </span>
        <span className='h4 text-gray mt-2'>
          {translate('workspace.teach.topmsg.body')}
        </span>
        <span
          className='btn-link h4 mt-5'
          onClick={() => {
            setStatusOnFirebase(userId, KEY, snapshot)
            setOpen(false)
          }}
        >
          {translate('workspace.teach.topmsg.link')}
        </span>
      </div>
    </Collapse>
  )
}

export default CollapseMsg
