import React from 'react'
import useTranslation from '../../../i18n/useTranslation'
import {gql, useQuery} from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import PlanSection from './Section'
import {
  useWindowDimensions,
  Notification,
} from '@Knowledge-OTP/znk-ui-components'
import PlansViewScroll from './scroll'
import {useHistory} from 'react-router'
import {parsePlan} from './util'
import queryCounselingPlanResult from '../../../Model/QueryResult/useMyCounselingPlan'
import educatorNotesResult from '../../../Model/QueryResult/useEducatorNotes'
const PlansView = (props) => {
  const {horizontal} = props
  const {translate} = useTranslation()
  const history = useHistory()
  const {breakWidth} = useWindowDimensions()
  const {data, loading} = useQuery(
    gql`
      query {
        myPlans(limit: 0) {
          totalDocs
          docs {
            checkInDates {
              id
              date
              type
            }
            id
            kind
            name
            studentUser {
              studentUserId
              firstName
              lastName
            }
            location {
              type
            }
            serviceId
            createdAt
            lessonIds
            status
            selectedEducators {
              firstName
              lastName
              educatorUserId
              topicId
              lessonLink
            }
            recommendedEducators {
              firstName
              lastName
              educatorUserId
              topicId
              lessonLink
            }
            groupId
            ... on LessonsPerWeekPlan {
              parts {
                lessonsPerWeek
                totalLessons
                from
                to
                lessonPerWeekPerTopic {
                  topicId
                  lessonsPerWeek
                  lockLessons
                  totalLessons
                }
              }
              groupPerWeekInfo {
                id
                PartsSchedule {
                  lessonsPerWeekGroup {
                    topicId
                    educatorId
                    day
                    duration
                    hour
                    totalLessons
                  }
                }
                lessonsGroups {
                  ... on GroupLesson {
                    students {
                      firstName
                      lastName
                      studentUserId
                      rating
                      wasLate
                      attendanceStatus
                      rate
                      planId
                    }
                  }
                  id
                  status
                  title
                  description
                  educator {
                    educatorUserId
                    firstName
                    lastName
                    rate
                    isManualRate
                    ignoreAvailabilty
                    ignorePaymentItem
                  }
                  service {
                    id
                    name
                  }
                  topic {
                    id
                    name
                  }
                  commentForEducator
                  recordingUrl
                  braincertId
                  backupLink
                  recurringId
                  kind
                  startDate
                  endDate
                  busy
                  userIdsInvolved
                  notes {
                    status
                    educatorNotes {
                      ${educatorNotesResult}
                    }
                    sentTime
                  }
                }
              }
            }
            ... on LessonsFixedPlan {
              totalCost
              lessonPerTopic: parts {
                TotalPerTopic {
                  topicId
                  lessonsPerWeek
                  lockLessons
                  totalLessons
                }
                type
                totalLessons
                from
                to
              }
            }
            ... on CouncelingPlan {
              ${queryCounselingPlanResult}
            }
          }
        }
      }
    `,
  )
  const parsedPlans = parsePlan(data?.myPlans?.docs || [])
  const counselingPlans = data
    ? data?.myPlans?.docs.filter(({kind}) => ['counceling'].includes(kind))
    : []
  const sentPlan = parsedPlans.filter((item) => item.status === 'sent')
  return breakWidth !== 'MD' && !horizontal ? (
    <div className='d-flex flex-column w-100 px-3 p-md-0'>
      {loading ? (
        <Skeleton height={40} />
      ) : (
        sentPlan &&
        sentPlan.length > 0 && (
          <Notification
            label={translate('program.plan.plantoapprove')}
            number={1}
            className='mb-3'
            onClick={() => {
              history.push(`/onboarding/${sentPlan[0].id}`)
            }}
          />
        )
      )}
      <PlanSection
        title={translate('program.plan.inProgress')}
        loading={loading}
        plans={parsedPlans
          .filter(
            (item, index) =>
              item.status === 'inprogress' || item.status === 'upcoming',
          )
          .concat(
            counselingPlans.filter(({status}) =>
              ['scheduled'].includes(status),
            ),
          )}
      />
      <PlanSection
        title={translate('program.plan.pendingApproval')}
        loading={loading}
        plans={parsedPlans
          .filter((item, index) => item.status === 'sent')
          .concat(
            counselingPlans.filter(({status}) => ['sent'].includes(status)),
          )}
      />
    </div>
  ) : (
    <PlansViewScroll
      plans={parsedPlans
        .filter((plan) =>
          ['sent', 'inprogress', 'upcoming'].includes(plan.status),
        )
        .concat(
          counselingPlans.filter(({status}) =>
            ['sent', 'scheduled'].includes(status),
          ),
        )}
      sentPlan={sentPlan}
      loading={loading}
    />
  )
}

export default PlansView
