import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '@Knowledge-OTP/znk-ui-components/dist/styles/main.css'
import * as serviceWorker from './serviceWorker'
import Root from './Root'
import './index.css'
import {BrowserRouter as Router} from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Root>
        <App />
      </Root>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
