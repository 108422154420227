import React, {useState, useEffect} from 'react'
import AddSubstitute from '../../../../../Model/Lessons/AddSubstitute'
import {
  IconClose,
  IconArrowRight,
  Avatar,
  ListItem,
  IconProgram,
  IconEdit,
  IconCreditCard,
  IconDuplicate,
  DropDown,
  IconOptions,
  IconArrowDown,
  IconArrowLeft,
  IconArrowUp,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import {useDispatch, useSelector} from 'react-redux'
import {Badge, Collapse} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import WasSubstitutedMsg from '../../../Components/WasSubstitutedMsg'

const RescheduledHeaderModal = (props) => {
  const {dataLesson, toggle} = props
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  // obtengo las lecciones pendiente para volver a setearlas en redux cuando retorne al modal anterior
  const {lessons} = useSelector(
    (state) => state.modal.calendar.rescheduledpending,
  )

  const topic =
    dataLesson.groupTopic ||
    dataLesson.individualTopic ||
    dataLesson.planTopic ||
    {}
  const service = dataLesson.service

  return (
    <>
      <div className='w-100 d-flex flex-row justify-content-between mt-1'>
        <span
          onClick={() => {
            toggle()
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'lesson.rescheduledpending',
              data: {
                isOpen: true,
                lessons,
              },
            })
          }}
          className='btn-link d-flex flex-row h4 align-items-center'
        >
          <IconArrowLeft size={15} className='mr-2' />
          <span>{translate('modal.infolesson.back').toUpperCase()}</span>
        </span>
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <h2 className='my-1'>
            <b>{dataLesson && `${dataLesson.title}`}</b>
          </h2>
          <h4 className='my-1'>
            {dataLesson &&
              dataLesson.startDate &&
              dataLesson.endDate &&
              `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(
                dataLesson.endDate,
              ).format('hh:mm a')} | ${separateCamelCase(
                moment(dataLesson.startDate).format('MMM'),
              )} ${moment(dataLesson.startDate).format('DD, YYYY')}`}
          </h4>
          <h5 className='h45 my-1 pr-2'>
            {dataLesson &&
              dataLesson.__typename &&
              service &&
              `${separateCamelCase(service.name)} | ${separateCamelCase(
                topic?.name,
              )} | ${separateCamelCase(
                dataLesson?.location?.type || 'No defined',
              )} | ${separateCamelCase(
                dataLesson.__typename?.replace('Lesson', ''),
              )}`}
          </h5>
          <div className='d-flex flex-row m-0 p-0'>
            <Badge
              className='mx-0 mt-3 px-3 mr-1 p-0 badge badge-pill badge-input'
              pill
            >
              {translate('modal.infolesson.pending')}
            </Badge>
          </div>
        </div>
      </div>
    </>
  )
}

const RescheduledBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    createdDate,
    updateDate,
    RenderStudents,
    wasSubstituted,
  } = props

  const {translate} = useTranslation()
  const [substituteEducator, setSubstituteEducator] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (dataLesson?.substitute) setSubstituteEducator(dataLesson?.substitute)
    else setSubstituteEducator(null)
  }, [dataLesson])

  const {submit: addSubstituteMutation} = AddSubstitute(
    (res) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.addSustituteEducator',
        data: {
          isOpen: false,
          idTopic: '',
          setEducator: () => {},
          topicName: '',
          educator: {},
          idService: '',
        },
      })
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          isOpen: true,
          dataLesson: {
            ...dataLesson,
            substitute: res?.data?.addSubstitute?.substitute || null,
          },
        },
      })
      // setSubstituteEducator(res?.data?.addSubstitute?.substitute || null)
    },
    () => {
      alert('error')
    },
  )

  // const lessonPast = dataLesson?.endDate ? moment() >= moment(dataLesson?.endDate) : false

  const rateStudent = Array.isArray(student)
    ? `$${student
        .reduce((minRate, stu) => {
          if (minRate > stu?.rate) return stu?.rate
          return minRate
        }, Number.POSITIVE_INFINITY)
        .toFixed(2)} ~ 
          $${student
            .reduce((maxRate, stu) => {
              if (maxRate < stu?.rate) return stu?.rate
              return maxRate
            }, Number.NEGATIVE_INFINITY)
            .toFixed(2)}`
    : student && `$${(student?.rate || 0).toFixed(2)}`
  const ignorePaymentStudent = Array.isArray(student)
    ? false
    : student?.ignorePaymentItem

  const colorOptionsIcon = '#696CFF'
  const colorOptionText = 'text-primary'

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }

  const penalizationInfo = dataLesson?.penalizationInfo
  return (
    <>
      <div className='d-flex flex-row justify-content-between my-2 h-100'>
        <span className='font-weight-bold'>
          {translate('modal.infolesson.price')}
        </span>
        <span
          className={`font-weight-bold ${
            ignorePaymentStudent ? 'text-gray' : ''
          }`}
          style={ignorePaymentStudent ? {textDecoration: 'line-through'} : {}}
        >
          {rateStudent}
        </span>
      </div>
      <div
        className={`d-flex flex-row justify-content-between my-2 ${
          !penalizationInfo ? 'mb-6' : ''
        }`}
      >
        <span className='font-weight-bold'>
          {translate('modal.newlesson.educatorwage')}
        </span>
        <span
          className={`font-weight-bold ${
            educator?.ignorePaymentItem ? 'text-gray' : ''
          }`}
          style={
            educator?.ignorePaymentItem ? {textDecoration: 'line-through'} : {}
          }
        >{`$${educator && educator.rate.toFixed(2)}`}</span>
      </div>
      {penalizationInfo && (
        <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
          <span className='text-error'>
            {translate('modal.penalizelesson.penalty')}
          </span>
          <span className='text-error'>{`-$${
            penalizationInfo?.amount && penalizationInfo.amount.toFixed(2)
          }`}</span>
        </div>
      )}
      <ListItem
        showTopLine
        Left={<RenderStudents student={student} />}
        Right={
          Array.isArray(student) ? (
            <div
              className='hover-icon py-1 px-2'
              onClick={toggleCollapseGroupStudent}
            >
              {collapseGroupStudent ? <IconArrowUp /> : <IconArrowDown />}
            </div>
          ) : (
            <div className='d-flex flex-row align-items-center'>
              <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                <DropDown
                  direction='bottom'
                  fixedWidth
                  hoverEnabled={false}
                  closeMenuWhenClick
                  options={[
                    {
                      component: (
                        <div className='d-flex flex-row align-items-center m-0 p-2'>
                          <div className='text-title h45 mr-2'>Rate: </div>
                          <div className='font-weight-bold mr-2 h45'>{`$${student?.rate.toFixed(
                            2,
                          )}`}</div>
                        </div>
                      ),
                    },
                  ]}
                >
                  <div className='hover-icon py-1 px-2'>
                    <IconOptions style={{transform: 'rotate(90deg)'}} />
                  </div>
                </DropDown>
              </div>
            </div>
          )
        }
      />
      <Collapse isOpen={collapseGroupStudent}>
        {Array.isArray(student) &&
          student.map((stu) => {
            return (
              <div>
                <ListItem
                  className='py-2'
                  Left={
                    <div className='d-flex flex-row my-0 py-0 mr-7'>
                      <Avatar
                        name={stu && `${stu.firstName} ${stu.lastName}`}
                        named={false}
                        uri={null}
                        innerDiv
                        size='avatar-small mr-1'
                      />
                      <div
                        className='h45 align-self-center text-dark'
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {stu && `${stu.firstName} ${stu.lastName} `}
                      </div>
                    </div>
                  }
                  Right={
                    <div className='d-flex flex-row align-items-center'>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick
                          options={[
                            {
                              component: (
                                <div className='d-flex flex-row align-items-center m-0 p-2'>
                                  <div className='text-title h45 mr-2'>
                                    Rate:{' '}
                                  </div>
                                  <div className='font-weight-bold mr-2 h45'>{`$${stu?.rate.toFixed(
                                    2,
                                  )}`}</div>
                                </div>
                              ),
                            },
                          ]}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{transform: 'rotate(90deg)'}} />
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  }
                />
              </div>
            )
          })}
      </Collapse>
      <WasSubstitutedMsg wasSubstituted={wasSubstituted} />
      <ListItem
        showBottomLine
        showTopLine
        Left={
          <div className='d-flex flex-row'>
            <Avatar
              name={
                dataLesson?.educator &&
                `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `
              }
              named={false}
              uri={null}
              size='avatar-medium mr-1'
            />
            <span className='align-self-center'>
              {dataLesson?.educator &&
                `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `}
              <small className='text-muted'>{`(${translate(
                'modal.infolesson.educator',
              )})`}</small>
            </span>
          </div>
        }
        Right={
          <div className='d-flex flex-row align-items-center'>
            {!wasSubstituted && (
              <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                <DropDown
                  direction='down'
                  fixedWidth
                  hoverEnabled={false}
                  options={[
                    {
                      component: (
                        <span
                          onClick={() => {
                            const topic =
                              dataLesson?.individualTopic ||
                              dataLesson?.planTopic ||
                              dataLesson?.groupTopic
                            dispatch({
                              type: 'SET_DATA_MODAL_FORM_LIST',
                              payload: 'plan.addSustituteEducator',
                              data: {
                                isOpen: true,
                                idTopic: topic?.id,
                                setEducator: (edu) => {
                                  addSubstituteMutation({
                                    educatorUserId: edu?.id,
                                    lessonId: dataLesson?.id,
                                  })
                                },
                                topicName: topic?.name,
                                educator: {},
                                idService: dataLesson?.service?.id,
                              },
                            })
                          }}
                        >
                          {substituteEducator
                            ? translate('modal.infolesson.changesubstitute')
                            : translate('modal.infolesson.addsubstitute')}
                        </span>
                      ),
                    },
                  ]}
                >
                  <div className='hover-icon py-1 px-2'>
                    <IconOptions style={{transform: 'rotate(90deg)'}} />
                  </div>
                </DropDown>
              </div>
            )}
          </div>
        }
      />
      {substituteEducator && (
        <ListItem
          showBottomLine
          showTopLine={false}
          Left={
            <div className='d-flex flex-row'>
              <Avatar
                name={`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                named={false}
                uri={null}
                size='avatar-medium mr-1'
              />
              <span className='align-self-center'>
                {`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                <small className='text-muted'>{`(${translate(
                  'modal.infolesson.substitute',
                )})`}</small>
              </span>
            </div>
          }
          Right={null}
        />
      )}
      <div className='d-flex flex-row my-4'>
        <span className='font-weight-bold'>
          {translate('modal.infolesson.rescheduledText')}
        </span>
      </div>
      {!wasSubstituted && (
        <ListItem
          showTopLine
          className='py-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'lesson.rescheduledLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson,
                rescheduledPending: true,
              },
            })
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconProgram
                color={colorOptionsIcon}
                className='align-self-center mr-2'
              />
              <span className={`align-self-center ${colorOptionText}`}>
                {translate('modal.infolesson.rescheduling')}
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      )}
      {!wasSubstituted && (
        <ListItem
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.duplicateLessonModal',
              data: {
                isOpen: true,
                dataLesson: dataLesson,
              },
            })
          }}
          showTopLine
          className='py-4'
          Left={
            <div className='d-flex flex-row'>
              <IconDuplicate
                color={colorOptionsIcon}
                className='align-self-center mr-2'
              />
              <span className={`align-self-center ${colorOptionText}`}>
                {translate('modal.infolesson.duplicatelesson')}
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      )}
      {!wasSubstituted && (
        <ListItem
          showTopLine
          className='py-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'lesson.editLessonModal',
              data: {
                isOpen: true,
                dataLesson: dataLesson,
              },
            })
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconEdit
                color={colorOptionsIcon}
                className='align-self-center mr-2'
              />
              <span className={`align-self-center ${colorOptionText}`}>
                {translate('modal.infolesson.editlesson')}
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      )}
      {!wasSubstituted && (
        <ListItem
          showTopLine
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.editPaymentLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson,
              },
            })
          }}
          className='py-4'
          Left={
            <div className='d-flex flex-row'>
              <IconCreditCard
                color={colorOptionsIcon}
                className='align-self-center mr-2'
              />
              <span className={`align-self-center ${colorOptionText}`}>
                {translate('modal.infolesson.editpayment')}
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      )}
      <ListItem
        showBottomLine={false}
        showTopLine={false}
        className='py-4'
        Left={
          <div className='d-flex flex-column'>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.created')}</b>{' '}
              {translate('modal.infolesson.at')}{' '}
              {`
                                ${
                                  createdDate &&
                                  moment(createdDate).format(
                                    'MMM DD, YYYY hh:mma',
                                  )
                                }
                            `}
            </span>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.update')}</b>{' '}
              {translate('modal.infolesson.at')}{' '}
              {`
                                ${
                                  updateDate &&
                                  moment(updateDate).format(
                                    'MMM DD, YYYY hh:mma',
                                  )
                                }
                            `}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      {!wasSubstituted && (
        <div
          className='pb-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.cancelLesson',
              data: {
                isOpen: true,
                lessonId: dataLesson?.id || '',
              },
            })
          }}
        >
          <span className='h5 text-gray'>
            {translate('modal.infolesson.youneedremove')}
          </span>
        </div>
      )}
    </>
  )
}

export {RescheduledHeaderModal, RescheduledBodyModal}
