import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const useDocsAndExams = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.counseling.docsAndExams)
  const {isOpen, onChange, docsAndExams} = reduxData

  const setData = (docsAndExams) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.docsAndExams',
      data: {
        ...reduxData,
        docsAndExams,
      },
    })
  }
  const setOpen = (open, onChange = () => {}, docsAndExams) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.docsAndExams',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        docsAndExams,
      },
    })
  }

  return {
    isOpen,
    onChange,
    open: (onChange = () => {}, college = {}) => {
      setOpen(true, onChange, college)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    docsAndExams,
    setData,
  }
}
export default useDocsAndExams
