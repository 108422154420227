import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
  InputSelect,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup, Label} from 'reactstrap'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import useActivities from '../../../../../Student/modal/InfoStudent/useQueries/useActivities'

const ApComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    taskStatus,
    activity,
  } = propsToSend
  const [serviceState, setServiceState] = useState('')

  const scale = '1-5'
  const scaleMin = parseInt(scale.split('-')[0])
  const scaleMax = parseInt(scale.split('-')[1])
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const servicesRedux = useSelector((state) => state.services.services)

  const [
    activitiesQuery,
    // eslint-disable-next-line
    { data: dataActivities, loading: loadActivities },
  ] = useActivities()
  const scores = reduxData?.scores
  const [APservices, setAPservices] = useState([])
  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scores?.subject >= scaleMin && scores?.subject <= scaleMax
      // && !!scores?.examDate
    )
  }
  const onChange = (exam) => {
    setServiceState(exam)
    setScoresData({
      ...scores,
      subjectName: exam,
    })
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.subject,
      results: [
        {
          name:
            scores?.subjectName !== 'Subject'
              ? serviceState
              : scores?.subjectName,
          scale: scale,
          values: [
            {
              name:
                scores?.subjectName === 'Subject'
                  ? serviceState
                  : scores?.subjectName,
              scale: scale,
              value: scores?.subject,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  useEffect(() => {
    if (reduxData?.scores?.name === 'Advanced Placement' && planId) {
      activitiesQuery({
        variables: {
          kinds: ['test'],
        },
      })
    }
    // eslint-disable-next-line
  }, [reduxData, activitiesQuery])

  useEffect(() => {
    const arrayAP = []
    let string = []
    for (const property in servicesRedux) {
      string = servicesRedux[property].name.split(' ')
      if (string[0] === 'AP') {
        string.shift()
        arrayAP.push(string.join(' '))
      }
    }
    setAPservices(arrayAP)
  }, [servicesRedux])

  return (
    <div className={`${scoresComponentClass} w-100`}>
      <Form Form value={scores}>
        <FormGroup className='mt-7 mb-0 w-100'>
          <Label style={{color: sectionTitleColor}}>Advanced Placement</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              {(scores?.subjectName !== 'Subject' &&
                scores?.subjectName !== '') ||
              taskStatus !== 'pending' ? (
                <ScoreListItem
                  id='subject'
                  className='m-1'
                  classNameLeft='w-100'
                  label={scores?.subjectName}
                  value={scores?.subject}
                  onChange={(e) => {
                    setScoresData({
                      ...scores,
                      subject: parseInt(e),
                    })
                  }}
                  isEditable={isEditable}
                  min={scaleMin}
                  max={scaleMax}
                />
              ) : (
                <ScoreListItem
                  id='subject'
                  className='m-1'
                  classNameLeft='w-100'
                  label={
                    <InputSelect
                      className='pt-1'
                      style={{
                        color: '#2C3F58',
                        backgroundColor: 'transparent',
                      }}
                      value={serviceState}
                      menuPlacement='top'
                      options={APservices.map((service) => {
                        return {
                          value: service,
                          label: service,
                        }
                      })}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                  }
                  value={scores?.subject}
                  onChange={(e) => {
                    setScoresData({
                      ...scores,
                      subject: parseInt(e),
                    })
                  }}
                  isEditable={isEditable}
                  min={scaleMin}
                  max={scaleMax}
                />
              )}
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default ApComponent
