import React, {useEffect, useMemo, useState} from 'react'
import {ModalLayout} from '@Knowledge-OTP/znk-ui-components'

import useViewPlanModal from './useViewPlanModal'
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
// reducers
import useCounselingPlanReducer from '../../../reducers/counseling-plan'
import useBubblesModalHeader from '../../../bubbles/useBubblesModalHeader'
// handler for tabs and sections by status of plan ["scheduled", "draft", "sent"]
import useTabsByStatus from './Components/useTabsByStatus'
import useTemplateReducer from '../../../reducers/template'
import moment from 'moment'
import useUser from '../../../hooks/auth/useUser'

const ViewPlanModal = () => {
  const {isOpen, toggle, dataPlan, tab} = useViewPlanModal()
  const planReducer = useCounselingPlanReducer()
  const {state: plan, updateData: setData, setPosition} = planReducer
  useEffect(() => {
    // actualiza la información del plan entrante en el reducers
    if (dataPlan) {
      setData(setPosition(dataPlan))
    }

    // eslint-disable-next-line
    }, [dataPlan])
  const {statusPlan, modalStatus, classText} = useMemo(() => {
    return {
      classText: '', // plan?.status ===  'cancel' ? 'text-light' : 'text-white',
      statusPlan: plan?.status || 'draft', // status del plan en API
      modalStatus:
        plan?.status === 'cancel'
          ? 'canceled'
          : ['draft', 'sent'].includes(plan?.status)
          ? 'scheduled'
          : 'scheduled-counseling', // para color del modal (statuses en modal colored)
    }
  }, [plan])

  const bubblesStyle = useBubblesModalHeader(plan?.status || 'scheduled')

  // retorna el listado de tabs según el status del plan
  const {sections: sectionsDefault, defaultTab} = useTabsByStatus(
    statusPlan,
    plan,
  )

  const [activeTab, setActiveTab] = useState(defaultTab)
  useEffect(() => {
    if (tab) {
      setActiveTab(tab)
    } else setActiveTab(defaultTab)
  }, [defaultTab, tab])

  const {filterDelete} = useTemplateReducer()

  // universidades en perfil del usuario (estudiante dueño del plan)
  // eslint-disable-next-line
  const {data, refetch: refetchUserQuery} = useUser({id: dataPlan?.studentUser?.studentUserId || ''})
  const universities = data?.user?.studentInfo?.universities || []

  return (
    <ModalLayout
      style={bubblesStyle}
      className={`modal-colored modal-sidebar modal-desktop-half ${modalStatus}-modal`}
      isOpen={isOpen}
      toggle={toggle}
      // options={parseToDropdown(optionsDefault)}
      topHead={
        <div className='m-0 p-0 mt-4 mb-3 d-flex flex-column'>
          <div className='d-flex flex-row align-items-center mb-7'>
            <div className='shape star-shape medium mr-1' />
            <span className={`h4 ${classText} m-0`}>Counseling</span>
          </div>
          <span
            className={`h2 ${classText} font-weight-bold`}
            style={
              plan?.status === 'cancel' ? {textDecoration: 'line-through'} : {}
            }
          >
            Counseling Plan
          </span>
          <span className={`h4 my-2 ${classText} font-weight-bold`}>
            {`${moment(plan?.from).format(`MMM DD YYYY`)} to ${moment(
              plan?.to,
            ).format(`MMM DD YYYY`)}`}
          </span>
          <span className={`h45 ${classText}`}>
            {`${
              dataPlan?.type
                ? `${dataPlan.type
                    .substring(0, 1)
                    .toUpperCase()}${dataPlan.type.substring(
                    1,
                    dataPlan.type?.length,
                  )}`
                : 'Type'
            } | ${dataPlan?.aditionalInfo?.studentCountryName}`}
          </span>
          {/* <span className={`h45 ${classText}`}>{plan?.id}</span> */}
        </div>
      }
      minHead={
        <span
          className={`h3 ${classText}`}
          style={
            plan?.status === 'cancel' ? {textDecoration: 'line-through'} : {}
          }
        >
          Counseling Plan
        </span>
      }
    >
      <div className='m-0 p-0 d-flex flex-column'>
        <Nav className='znk-scroll-horizontal flex-nowrap'>
          {sectionsDefault.map(({label, key}) => {
            return (
              <NavItem
                className='nav-title mb-2 mr-2'
                active={activeTab === key}
              >
                <NavLink
                  onClick={() => {
                    setActiveTab(key)
                  }}
                >
                  {label}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
        <div className='borderTop w-100 my-0' />
        <TabContent activeTab={activeTab}>
          {sectionsDefault.map(({Inner, key}) => {
            return (
              <TabPane tabId={key}>
                <Inner
                  plan={{
                    ...plan,
                    tasks: filterDelete({phases: plan?.tasks || []}).phases,
                    originalTask: plan?.tasks,
                  }}
                  setData={setData}
                  isEdit={plan?.status !== 'cancel'}
                  statusPlan={statusPlan}
                  universities={universities}
                />
              </TabPane>
            )
          })}
        </TabContent>
      </div>
    </ModalLayout>
  )
}

export default ViewPlanModal
