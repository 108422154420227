import React from 'react'
import {ConfirmModal, Activity} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const ReloadOnboarding = (props) => {
  const {isOpen, toggle, confirmAction, loading} = props
  const {translate} = useTranslation()
  return (
    <ConfirmModal
      backdrop={[false, 'static']}
      isOpen={isOpen}
      toggle={toggle}
      title={translate('home.onboard.reload.title')}
      body={translate('home.onboard.reload.body')}
      confirmAction={!loading && confirmAction}
      cancelAction={() => {}}
      confirmText={
        loading ? <Activity /> : translate('home.onboard.reload.action')
      }
      cancelText={null}
    />
  )
}

ReloadOnboarding.propTypes = {}
export default ReloadOnboarding
