import {gql, useQuery} from '@apollo/client'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: {}}
  }

  const {data, error, loading} = useQuery(
    gql`
      query($limit: Int) {
        paginatedCountries(limit: $limit) {
          hasNext
          hasPrevious
          next
          previous
          totalDocs
          docs {
            id
            name
            code
            dialCode
            defaultStudentRate
            defaultEducatorRate
            createdAt
            updatedAt
            servicesRate {
              serviceId
              name
              defaultRate
            }
          }
        }
      }
    `,
    {
      variables: vars,
    },
  )
  return {loading, data: data ? data.paginatedCountries : {}, error}
}
