import {gql, useMutation} from '@apollo/client'

const useRemoveFilesFromTask = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation(
        $files: [String!]!
        $taskId: ID!
        $stepId: ID!
        $phase: PhaseEnum!
        $planId: ID!
      ) {
        removeFilesFromTask(
          files: $files
          taskId: $taskId
          stepId: $stepId
          phase: $phase
          planId: $planId
        )
      }
    `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useRemoveFilesFromTask
