import {useQuery, gql} from '@apollo/client'

const useGetOwnerPlan = (variables = {}) => {
  return useQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          id
          emails {
            address
            verified
          }
          firstName
          lastName
          studentInfo {
            parents {
              firstName
              lastName
              managePayments
              email
              phone
            }
          }
          roles
        }
      }
    `,
    {variables},
  )
}

export default useGetOwnerPlan
