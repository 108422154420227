import React, {useState} from 'react'
import {IconLoad} from '@Knowledge-OTP/znk-ui-components'
import useActionsFields from './useActionsFields'

const OptionAction = (props) => {
  const {item, refetch} = props
  const {onClickAction, getLabel} = useActionsFields(refetch)
  const [loading, setLoading] = useState(false)
  const {label} = getLabel(item)
  return (
    <span
      className={loading ? `cursor-pointer` : ``}
      onClick={() => {
        setLoading(true)
        onClickAction(item, () => {
          setLoading(false)
        })
      }}
    >
      {loading ? <IconLoad size={12} className='mr-2' /> : null}
      {label}
    </span>
  )
}

export default OptionAction
