import {useDispatch} from 'react-redux'
import UseUpdateTimezone from '../../Model/useUpdateTimezone'
import {showAlert} from '../../../../common/Alert/util'
import {CHANGE_TIMEZONE} from '../../../../store/user/const'
import useTranslation from '../../../../i18n/useTranslation'

const useChangeTimezone = () => {
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const [changeTimezone, {loading}] = UseUpdateTimezone()
  const setZone = (time) => {
    // ejecutar mutación updateUser
    changeTimezone({
      variables: {newTimezone: time},
    }).then(
      ({data: {updateTimezone}}) => {
        dispatch({
          type: CHANGE_TIMEZONE,
          data: updateTimezone,
        })
        // deselecciona bloques
        // setCheckedFrames(getNewChecked())
        showAlert(
          {
            text: translate('modal.changeTimezone.successAlert'),
            status: 'success',
          },
          dispatch,
        )
      },
      (err) => {
        console.error(err)
        showAlert(
          {
            text: translate('modal.changeTimezone.errorAlert'),
            status: 'error',
          },
          dispatch,
        )
      },
    )
  }
  return {
    setZone,
    loading,
  }
}

export default useChangeTimezone
