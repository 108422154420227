import React, {useState, useEffect, useMemo} from 'react'
import {
  IconIllustratedLesson,
  IconClose,
  IconSignMinus,
  IconArrowRight,
  IconProgram,
  ListItem,
  Activity,
  Button,
  IconRepeat,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import CheckInLesson from '../../../../../../Model/Lessons/CheckInLesson'
import CancelLesson from '../../CancelLesson'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../i18n/useTranslation'
import useModalState from '../../../../../../hooks/useModalState'
import {hasHttp} from '../utils'
import useLinkLesson from '../useLinkLesson'
import BackupLinkButton from '../../../../../CalendarLessons/modal/InfoLesson/Components/BackupLinkButton'

const ScheduledHeaderModal = (props) => {
  const {RenderTitleLesson, toggle} = props
  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1 text-white'>
        <IconSignMinus size={20} className='ml-auto' />
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <IconIllustratedLesson size={80} />
        <RenderTitleLesson />
      </div>
    </>
  )
}

const ScheduledBodyModal = (props) => {
  const {
    RenderEducator,
    dataLesson,
    refetchLessons,
    currentEducator,
    currentStudent,
    setDataLessonOnRedux,
  } = props
  const {id, isLocked, startDate, title, service, topic} = dataLesson
  const dispatch = useDispatch()
  const {onChange} = useModalState('program.newManualLesson')
  const openCopyLesson = () => {
    const {educatorUserId, firstName, lastName} = currentEducator
    onChange((s) => ({
      stage: 2,
      serviceId: service.id,
      topic: topic.id,
      educator: {
        id: educatorUserId,
        firstName,
        lastName,
      },
      dateTime: null,
    }))
  }
  const [timeToStart, setTimeToStart] = useState(
    moment.duration(moment(dataLesson.startDate).diff(moment())).asMinutes(),
  )
  const {translate} = useTranslation()
  useEffect(() => {
    // inicia cuenta para habilitar el botón checkIn
    const initTime = moment
      .duration(
        moment(dataLesson?.startDate).subtract(10, 'minutes').diff(moment()),
      )
      .asMilliseconds()
    const timer = setTimeout(() => {
      setTimeToStart(
        moment
          .duration(moment(dataLesson?.startDate).diff(moment()))
          .asMinutes(),
      )
    }, initTime)
    return () => clearTimeout(timer)
  }, [dataLesson])

  const {submit: checkIn, loading: loadCheckIn} = CheckInLesson(
    ({data}) => {
      // marca como attended el attendenStatus del educador
      // alert("success")
      setDataLessonOnRedux(data?.checkInLesson || dataLesson)
    },
    () => {
      alert('error')
    },
  )

  // backup link & go to classroom
  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)

  const isOnNext24 = useMemo(() => {
    return timeToStart / 60 < 24
  }, [timeToStart])
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'
  const isCheckIn = currentStudent?.attendanceStatus === 'attended'
  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-between h-100'>
      <div className='m-0 p-0 d-flex flex-column'>
        <RenderEducator />
        <div className='m-0 p-0 d-flex flex-column'>
          <div className='d-flex flex-row my-6'>
            {isCheckIn ? (
              <div className='d-flex flex-row m-0 w-100'>
                <BackupLinkButton
                  className='w-50 mr-1'
                  educatorId={
                    dataLesson?.substitute?.educatorUserId ||
                    dataLesson?.educator?.educatorUserId
                  }
                />
                <Button
                  style={{backgroundColor: '#696CFF'}}
                  className='w-50 ml-1'
                  size='large'
                  onClick={() => {
                    // si location es VCR es launchURL, sino es location.linkStudent!
                    setGoToClassLoad(true)
                    getvclink(dataLesson?.id, null)
                      .then(
                        ({launchUrl, location}) => {
                          if (
                            location.type === 'virtualClassroom' ||
                            location.type === 'zinkerzPlatform'
                          )
                            window.open(hasHttp(launchUrl), '_blank')
                          else
                            window.open(hasHttp(location.linkStudent), '_blank')
                          setGoToClassLoad(false)
                        },
                        (error) => {
                          console.error(error)
                          // setVcLinkError(true)
                          setGoToClassLoad(false)
                        },
                      )
                      .catch((error) => {
                        console.error(error)
                        // setVcLinkError(true)
                        setGoToClassLoad(false)
                      })
                  }}
                  label={
                    goToClassLoad ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <span className='text-center'>
                        {translate('student.infolesson.options.gotoclassroom')}
                      </span>
                    )
                  }
                />
              </div>
            ) : (
              <Button
                style={{backgroundColor: '#72809d'}}
                className='w-100'
                size='large'
                onClick={() => {
                  if (timeToStart < 10) {
                    checkIn({lessonId: dataLesson?.id || ''})
                  }
                }}
                disabled={timeToStart >= 10}
                label={
                  loadCheckIn ? (
                    <div className='w-100 d-flex flex-row justify-content-center'>
                      <Activity inverse />
                    </div>
                  ) : (
                    <div className='d-flex flex-row justify-content-between'>
                      <span>
                        {translate('student.infolesson.options.checkIn')}
                      </span>
                      <IconArrowRight color='white' />
                    </div>
                  )
                }
              />
            )}
          </div>
        </div>
        <div className='m-0 p-0 d-flex flex-column'>
          <ListItem
            showTopLine
            showBottomLine
            className='py-4'
            onClick={() => {
              isOnNext24 || isLocked || isGroupLesson
                ? dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'program.cantReschedule',
                    data: {
                      isOpen: true,
                      isLocked,
                      isOnNext24,
                      isGroupLesson,
                    },
                  })
                : dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'program.rescheduledModal',
                    data: {
                      isOpen: true,
                      stage: 'timeSelectionBlock',
                      educator: currentEducator,
                      selectedBlock: null,
                      refetchLessons: refetchLessons,
                      lesson: {
                        lessonId: id,
                        name: title,
                        service: service,
                        topic: dataLesson.topic
                      },
                      plan: currentStudent,
                      setOpenInfoModal: (bool) => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'student.infoLessonModal',
                          data: {
                            isOpen: bool,
                            dataLesson,
                          },
                        })
                      },
                    },
                  })
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconProgram
                  color='#696CFF'
                  className='align-self-center mr-2'
                />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.rescheduled')}
                </span>
              </div>
            }
            Right={<IconArrowRight color='#696CFF' />}
          />
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-4'
            onClick={() => {
              openCopyLesson()
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconRepeat className='align-self-center mr-2 text-primary' />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.repeat')}
                </span>
              </div>
            }
            Right={<IconArrowRight className='text-primary' />}
          />
        </div>
      </div>
      <div className='mx-0 my-3 p-0'>
        <CancelLesson
          startDate={startDate}
          lessonId={id}
          refetchLessons={refetchLessons}
          isLocked={isLocked}
          isGroupLesson={isGroupLesson}
        >
          <span className='h5 text-gray m-0 p-0'>
            {translate('student.infolesson.options.needCancel')}
          </span>
        </CancelLesson>
      </div>
    </div>
  )
}

export {ScheduledHeaderModal, ScheduledBodyModal}
