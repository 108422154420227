import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const useShowTask = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.counseling.viewTaskModal)
  const {isOpen, onChange, task} = reduxData

  const setTaskData = (task) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.viewTaskModal',
      data: {
        ...reduxData,
        task,
      },
    })
  }
  const setOpen = (open, onChange = () => {}, task) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.viewTaskModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        task,
      },
    })
  }

  return {
    isOpen,
    onChange,
    open: (onChange = () => {}, task = {}) => {
      setOpen(true, onChange, task)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    task,
    setTaskData,
  }
}
export default useShowTask
