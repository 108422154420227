const taskTypes = {
  completion: 'completion',
  uploadFile: 'uploadFile',
  link: 'link',
  scheduledMeetting: 'scheduledMeetting',
  scoreCollection: 'scoreColletion',
  loginInfo: 'logoinInfo',
  completionArray: ['completion'],
  uploadFileArray: ['uploadFile', 'upload-file'],
  linkArray: ['link'],
  scheduledMeettingArray: ['scheduledMeetting', 'scheduled-meetting'],
  scoreCollectionArray: ['scoreColletion', 'score-collection'],
  loginInfoArray: ['logoinInfo', 'login-info'],
}

export default taskTypes
