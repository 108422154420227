import {useMemo} from 'react'
import ResumeSection from '../../Tabs/Resume'
import TasksSection from '../../Tabs/Tasks'
import TimelineSection from '../../Tabs/Timeline'
import SummarySection from '../../Tabs/Summary'
import UniversitiesSection from '../../Tabs/Universities'
// import MettingsSection from '../../Tabs/Mettings'
import MettingsSection from '../../Tabs/NewMettings'
// import PaymentsSection from '../../Tabs/Payments'
import PaymentsSection from '../../Tabs/NewPayments'
import ActivitySection from '../../Tabs/Activity'
import LivePrepTimelineSection from '../../Tabs/LivePrepTimeline'
import DocumentsTab from '../../Tabs/Docs'

const useTabsByStatus = (status, item) => {
  // status: oneOf [scheduled, draft, sent]
  // actions: edit, sent, cancel, approve

  // timelines:
  //   events: []
  //   plans: []
  //   tests: []
  const noTimeline = useMemo(() => {
    return !(
      item?.timelines?.events?.length > 0 ||
      item?.timelines?.tests?.length > 0 ||
      item?.timelines?.plans?.length > 0
    )
  }, [item])

  const {baseSections: sections, defaultTab} = useMemo(() => {
    let baseSections = []
    let defaultTab = 'resume'
    if (['draft', 'sent', 'cancel'].includes(status)) {
      baseSections = baseSections.concat([
        {
          label: 'Resume',
          key: 'resume',
          Inner: ResumeSection,
        },
      ])
      if (['draft', 'sent'].includes(status) && !noTimeline) {
        baseSections = baseSections.concat([
          {
            label: 'Live Prep Timeline',
            key: 'live-prep-timeline',
            Inner: LivePrepTimelineSection,
          },
        ])
      }
    } else if (
      ['scheduled', 'application', 'postApplication', 'completed'].includes(
        status,
      )
    ) {
      defaultTab = 'summary'
      const tabs = [
        {
          label: 'Summary',
          key: 'summary',
          Inner: SummarySection,
        },
        {
          label: 'Tasks',
          key: 'tasks',
          Inner: TasksSection,
        },
        {
          label: 'Documents',
          key: 'docs',
          Inner: DocumentsTab,
        },
        {
          label: 'Timeline',
          key: 'timeline',
          Inner: TimelineSection,
        },
        {
          label: 'Universities',
          key: 'universities',
          Inner: UniversitiesSection,
        },
        {
          label: 'Meetings',
          key: 'mettings',
          Inner: MettingsSection,
        },
        {
          label: 'Payments',
          key: 'payments',
          Inner: PaymentsSection,
        },
        {
          label: 'Activity',
          key: 'Activity',
          Inner: ActivitySection,
        },
      ]
      if (!noTimeline) {
        tabs.push({
          label: 'Live Prep Timeline',
          key: 'live-prep-timeline',
          Inner: LivePrepTimelineSection,
        })
      }
      baseSections = baseSections.concat(tabs)
    }
    return {baseSections, defaultTab}
  }, [status, noTimeline])

  return {
    sections: sections,
    defaultTab,
  }
}

export default useTabsByStatus
