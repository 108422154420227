export default {
  beginwork: '¡Estamos emocionados de comenzar a trabajar contigo!',
  paymentTitle: 'Agendar plan de pagos',
  paymentTotal: 'Precio total',
  paymentPlanap: 'Al aprobar plan',
  paymentEndof: 'Final de',
  paymentTotalCost: 'Costo total del plan',
  weeklyTitle: 'Programa semanal',
  weeklyItemLesson: ({lpw, name}) => {
    return `${lpw} lecciones individuales de ${name} por semana`
  },
  weeklyItemLessonFixed: ({lpw, name}) => {
    return `${lpw} lecciones de ${name}`
  },
  weeklyItemLessonGroup: ({lpw, name}) => {
    return `${lpw} lecciones grupales de ${name} por semana`
  },
  weeklyItemOrientation: ({lpw, name}) => {
    return parseInt(lpw) === 1
      ? `${lpw} sesion de ${name} antes de comenzar`
      : `${lpw} sesiones de ${name} antes de comenzar`
  },
  breakdownTitle: 'Desglose del plan',
  breakdownRegistration: 'Cuota de registro',
  breakdownLessons: 'lección',
  breakdownPerlesson: 'por lección',
  approvePlan: 'Aprobar plan',
  selecttime: 'Seleccionar Timezone',
  selectedu: ({topic}) => `Selecciona tu educador de ${topic}`,
}
