import React, {useState, useEffect} from 'react'
import {
  Action,
  Button,
  useWindowDimensions,
  IconBrand,
  StickyButton,
  DayFrame,
  ListItem,
  IconArrowRight,
  IconPassword,
  Avatar,
} from '@Knowledge-OTP/znk-ui-components'
import {useLazyQuery, gql} from '@apollo/client'
import {changeEducatorForTopicPart, parseDataToMutation} from '../../utils'
import AdjustPlan from '../../components/AdjustPlan'
import ChangeEducator from '../../components/ChangeEducator'
import ChangeEducatorConfirm from '../../components/ChangeEducatorConfirm'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'

export const ConfirmScheduledHeader = (props) => {
  const {translate} = useTranslation()
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  // const now = useMemo(() => {
  //   return moment()
  // }, [])
  // const allLessonsDistributed = Object.entries(scheduledTimes).reduce((bool, [__, value]) => {
  //   return bool && value?.distributed
  // }, true)
  const timeLabel = `${translate('misc.rangeDate', {
    from: moment(dataPlan?.startDate.split('T')[0]).format('MMM Do, YYYY'),
    to: moment(dataPlan?.endDate.split('T')[0]).format('MMM Do, YYYY'),
  })}`

  const titlePlan = dataPlan?.isTestDate
    ? `${dataPlan?.serviceName} - ${moment(dataPlan?.testDate).format(
        'MMM Do, YYYY',
      )}`
    : `${dataPlan?.serviceName} - ${moment(dataPlan?.startDate).format(
        'MMM Do, YYYY',
      )}`
  return (
    <div className='m-0 p-0'>
      <IconBrand size={80} color='white' />
      <div>
        <h1 className='mb-4'>
          <b>{translate('home.onboard.approve.confirm.title')}</b>
        </h1>
        <div className='my-2 d-flex flex-column'>
          <h3 className='my-2'>
            <b>{titlePlan}</b>
          </h3>
          <span className='my-2'>{timeLabel}</span>
        </div>
      </div>
    </div>
  )
}

export const ConfirmScheduledBody = (props) => {
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const reduxOnboarding = useSelector((state) => state.modal.onboarding)
  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {page} = useSelector((state) => state.modal.onboarding.mediumModal)
  const smallModal = useSelector((state) => state.modal.onboarding.smallModal)
  const {scheduledTimes, dataPlan, scheduled, orientationKey} = reduxData
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'
  const zone = useSelector((state) => state.user.info.timezone)
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )

  const rescheduledLesson = (topicKey, indexLesson) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {...reduxData, keyLesson: topicKey, indexLesson},
      // data: {...reduxData, ...adjustTopicLesson(scheduledTimes, topicKey, indexLesson) },
    })
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.smallModal',
      data: {
        ...smallModal,
        isOpen: true,
        page:
          topicKey === ORIENTATION_KEY ? 'scheduledOrientation' : 'scheduled',
      },
    })
  }

  const educators = Object.entries(scheduledTimes)
    .filter(([keyLesson]) => keyLesson !== ORIENTATION_KEY)
    .map(([keyLesson, objTopic]) => {
      return {
        ...objTopic?.educator.filter((edu) => edu.selected)[0],
        topic: {...objTopic?.topic},
        keyLesson,
      }
    })

  const [openEducatorModal, setOpenEducatorModal] = useState(false)
  const toggleEducatorModal = () => {
    setOpenEducatorModal(!openEducatorModal)
  }

  const [openEducatorModalConfirm, setOpenEducatorModalConfirm] = useState(
    false,
  )
  const toggleEducatorModalConfirm = () => {
    setOpenEducatorModalConfirm(!openEducatorModalConfirm)
  }

  const validationConfirm = scheduledTimes
    ? Object.entries(scheduledTimes).reduce((val, [keyLesson, objTopic]) => {
        return val && !objTopic.times.includes(null)
      }, true)
    : false

  const [educatorParams, setEducatorParams] = useState({
    setEducator: () => {},
    educator: '',
    idTopic: '',
    idService: '',
  })

  const [
    simulatedMutation,
    {loading: loadingSimulated, data: simulatedData},
  ] = useLazyQuery(
    gql`
      query(
        $planId: ID!
        $lessonsPerWeekPlanPart: [LessonPerWeekPlanSchedulePart!]!
      ) {
        simulateMyLessonsPerWeekPlanSchedule(
          schedule: {
            planId: $planId
            lessonsPerWeekPlanPart: $lessonsPerWeekPlanPart
          }
        ) {
          ... on OcurrenceResult {
            hasOccurrences
            occurrencesList {
              topicId
              lessonName
              from
              to
              educatorUserId
              educatorFirstName
              educatorLastName
              serviceId
            }
          }
        }
      }
    `,
  )

  useEffect(() => {
    if (page === 'confirmScheduled') {
      simulatedMutation({
        variables: parseDataToMutation(
          scheduledTimes,
          dataPlan,
          JSON.stringify(reduxOnboarding),
        ),
      })
    }
    // eslint-disable-next-line
  }, [page, JSON.stringify(scheduledTimes)])

  const notificationData = simulatedData?.simulateMyLessonsPerWeekPlanSchedule?.occurrencesList.reduce(
    (arrayLessons, lesson) => {
      if (!arrayLessons.includes(lesson?.lessonName))
        arrayLessons.push(lesson?.lessonName)
      return arrayLessons
    },
    [],
  )

  const services = useSelector((state) => state.services.services)
  const groupLessons = dataPlan?.groupInfo?.PartsSchedule?.reduce(
    (arrayTimes, {lessonsPerWeekGroup}) => {
      arrayTimes.push(
        lessonsPerWeekGroup.map(({day, hour, topicId}) => {
          const momento = moment()
            .tz('utc')
            .day(day)
            .set({
              hour: parseInt(hour?.split(':')[0]),
              minutes: parseInt(hour?.split(':')[1]),
            })
          return {
            time: momento.tz(zone),
            name: services[dataPlan?.serviceId].topics[topicId].name,
          }
        }),
      )
      return arrayTimes
    },
    [],
  )

  const groupEducators = dataPlan?.recommendedEducators
    ?.filter((edu) => edu.teachingType === 'group')
    ?.map((edu, indexGroup) => {
      return {
        html: (
          <ListItem
            key={'group' + indexGroup}
            showBottomLine={false}
            showTopLine={false}
            className='py-3'
            Left={
              <div className='d-flex align-items-center text-muted'>
                <Avatar
                  name={`${edu.firstName} ${edu.lastName}`}
                  named={false}
                  uri={null}
                  size='avatar-medium'
                />
                <span className='ml-2'>
                  {`${edu.firstName} ${edu.lastName} 
                      (${
                        services[dataPlan?.serviceId].topics[edu?.topicId].name
                      }-Group)`}
                </span>
              </div>
            }
            Right={<IconPassword />}
          />
        ),
      }
    })

  const orientationTime =
    scheduledTimes[ORIENTATION_KEY]?.times?.length > 0
      ? scheduledTimes[ORIENTATION_KEY]?.times[0]
      : null
  const scheduledAfter = orientationTime
    ? moment(orientationTime).format('YYYY-MM') === '2000-01'
    : false

  return (
    <div className='m-0 p-0'>
      {scheduledTimes[ORIENTATION_KEY] && (
        <div
          className='m-0 py-6 px-3 px-md-5 px-xl-10'
          style={{backgroundColor: 'rgba(255, 181, 25, 0.1)'}}
          onClick={() => {
            if (!scheduled && !scheduledAfter) {
              rescheduledLesson(ORIENTATION_KEY, 0)
            }
          }}
        >
          {scheduledTimes[ORIENTATION_KEY]?.times.length > 0 && (
            <div className='m-0 p-0 d-flex flex-column text-title'>
              <h3>
                <b>{translate('home.onboard.approve.confirm.orientation')}</b>
              </h3>
              <span>
                {translate('home.onboard.approve.confirm.orientationbody')}
              </span>
              <ListItem
                showTopLine={false}
                className='pl-0'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <DayFrame
                      className='listItemDay'
                      free={scheduledTimes[ORIENTATION_KEY]?.times[0] === null}
                      selected={
                        scheduledTimes[ORIENTATION_KEY]?.times[0] !== null
                      }
                      check={false}
                      span={
                        scheduledTimes[ORIENTATION_KEY]?.times[0] === null
                          ? 'O'
                          : null
                      }
                      spanColor={
                        scheduledTimes[ORIENTATION_KEY]?.times[0] === null
                          ? '#DCE1E7'
                          : 'white'
                      }
                    />
                    {scheduledTimes[ORIENTATION_KEY]?.times[0] === null ? (
                      <span className='ml-2'>Scheduled</span>
                    ) : (
                      <span className='ml-2'>
                        {scheduledAfter
                          ? translate(
                              'home.onboard.approve.confirm.scheduledAfter',
                            )
                          : moment(scheduledTimes[ORIENTATION_KEY]?.times[0])
                              .seconds(0)
                              .milliseconds(0)
                              .format('MMMM Do YYYY, h:mm a') +
                            ' - ' +
                            moment(scheduledTimes[ORIENTATION_KEY]?.times[0])
                              .add(15, 'minutes')
                              .seconds(0)
                              .milliseconds(0)
                              .format('h:mm a')}
                      </span>
                    )}
                  </div>
                }
              />
            </div>
          )}
        </div>
      )}
      <div className='m-0 p-0 pb-6 px-3 px-md-5 px-xl-10'>
        {loadingSimulated ? (
          <Skeleton
            height={80}
            style={{borderRadius: '0.3rem'}}
            className='w-100 my-3'
          />
        ) : (
          notificationData &&
          notificationData.length > 0 && (
            <Action
              color='transparent'
              textColor='#7079FF'
              border='solid 1px #7079FF'
              className='my-3'
              labelLeft={
                <span className='w-100 d-flex flex-column mt-4 mb-3'>
                  <label>
                    {translate('home.onboard.approve.confirm.notif')}
                  </label>
                  <label>
                    {translate(
                      'home.onboard.approve.confirm.subnotifOcurrences',
                      notificationData.reduce(
                        (acu, item, index) => {
                          if (index === 0) {
                            return item
                          } else if (index === Object.values({}).length - 1) {
                            return acu + ' & ' + item
                          } else {
                            return acu + ', ' + item
                          }
                        },
                        [''],
                      ),
                    )}
                  </label>
                </span>
              }
              labelRight=''
              action='primary'
            />
          )
        )}
        <div className='my-5'>
          <h3>
            <b>{translate('home.onboard.approve.confirm.educators')}</b>
          </h3>
          <div>
            <ChangeEducator
              isOpen={openEducatorModal}
              toggle={toggleEducatorModal}
              {...educatorParams}
            />
            <ChangeEducatorConfirm
              isOpen={openEducatorModalConfirm}
              toggle={toggleEducatorModalConfirm}
              confirmAction={() => {
                toggleEducatorModal()
                toggleEducatorModalConfirm()
              }}
            />
            {educators.map((educator, index) => {
              return (
                <ListItem
                  key={index}
                  showBottomLine={false}
                  showTopLine={false}
                  className='py-3'
                  onClick={() => {
                    if (!educator.lock) {
                      setEducatorParams({
                        setEducator: (newEducator) => {
                          dispatch({
                            type: SET_DATA_MODAL_FORM_LIST,
                            payload: 'onboarding.data',
                            data: {
                              ...reduxData,
                              ...changeEducatorForTopicPart(
                                scheduledTimes,
                                educator?.keyLesson,
                                newEducator,
                              ),
                            },
                          })
                          toggleEducatorModal()
                        },
                        educator: {
                          id: educator?.id,
                          name: educator?.name,
                          lesson: educator?.topic?.name,
                        },
                        idTopic: educator?.topic?.id,
                        idService: dataPlan?.serviceId,
                      })
                      toggleEducatorModalConfirm()
                    }
                  }}
                  Left={
                    <div className='d-flex align-items-center text-muted'>
                      <Avatar
                        name={educator?.name}
                        named={false}
                        uri={null}
                        size='avatar-medium'
                      />
                      <span className='ml-2'>{`${educator?.name} (${educator?.topic?.name})`}</span>
                    </div>
                  }
                  Right={
                    educator.lock || scheduled ? (
                      <IconPassword />
                    ) : (
                      <IconArrowRight />
                    )
                  }
                />
              )
            })}
            {groupEducators.map(({html}) => {
              return html
            })}
          </div>
        </div>
        <div key={0} className='m-0'>
          <h3>
            <b>{translate('home.onboard.approve.weeklyTitle')}</b>
          </h3>
          <div className='my-2'>
            {dataPlan?.ranges?.map((range, indexPart) => {
              const [from, to] = range
              return (
                <div className='my-2' key={indexPart}>
                  <span className='text-muted'>
                    {`${translate('home.onboard.approve.before.from')} 
                            ${moment(from.split('T')[0]).format('MMMM Do')} 
                            ${translate('home.onboard.approve.before.to')} 
                            ${moment(to.split('T')[0]).format('MMMM Do')}`}
                  </span>
                  <div>
                    {Object.entries(scheduledTimes)
                      .filter(
                        ([topicKey]) =>
                          parseInt(topicKey?.split('-')[1]) === indexPart &&
                          topicKey !== ORIENTATION_KEY,
                      )
                      .map(([topicKey, objTopic], i) => {
                        return objTopic?.times?.map((time, indexLesson) => {
                          return (
                            <ListItem
                              key={i}
                              showBottomLine={false}
                              showTopLine={false}
                              className='pl-0 py-3'
                              Left={
                                <div className='d-flex align-items-center text-muted'>
                                  <DayFrame
                                    className='listItemDay'
                                    free={time === null}
                                    selected={time !== null}
                                    check={false}
                                    span={
                                      time === null
                                        ? objTopic?.topic?.name?.substring(0, 1)
                                        : null
                                    }
                                    spanColor={
                                      time === null ? '#DCE1E7' : 'white'
                                    }
                                  />
                                  <span key={i} className='ml-2'>
                                    {objTopic?.topic?.name}
                                  </span>
                                </div>
                              }
                              Right={
                                <span
                                  className='text-muted'
                                  onClick={() => {
                                    if (!scheduled)
                                      rescheduledLesson(topicKey, indexLesson)
                                  }}
                                >
                                  {time === null ? (
                                    translate(
                                      'home.onboard.approve.scheduled.scheduled',
                                    )
                                  ) : (
                                    <>
                                      {`${moment(time).format(
                                        'dddd, h:mm a',
                                      )}${' '}`}{' '}
                                      {scheduled ? (
                                        <IconPassword />
                                      ) : (
                                        <IconArrowRight />
                                      )}
                                    </>
                                  )}
                                </span>
                              }
                            />
                          )
                        })
                      })}
                    {groupLessons?.length > 0 &&
                      groupLessons[indexPart]?.map(
                        ({time, name}, indexGroup) => {
                          return (
                            <ListItem
                              key={indexGroup}
                              showBottomLine={false}
                              showTopLine={false}
                              className='pl-0 py-3'
                              Left={
                                <div className='d-flex align-items-center text-muted'>
                                  <DayFrame
                                    className='listItemDay'
                                    free={time === null}
                                    selected={time !== null}
                                    check
                                    span={
                                      time === null
                                        ? name.substring(0, 1)
                                        : null
                                    }
                                    spanColor={
                                      time === null ? '#DCE1E7' : 'white'
                                    }
                                  />
                                  <span key={indexGroup} className='ml-2'>
                                    {name}{' '}
                                    <span className='text-gray'>(Group)</span>
                                  </span>
                                </div>
                              }
                              Right={
                                <span className='text-muted'>
                                  {time === null ? (
                                    translate(
                                      'home.onboard.approve.scheduled.scheduled',
                                    )
                                  ) : (
                                    <>{`${moment(time).format(
                                      'dddd, h:mm a',
                                    )}${' '}`}</>
                                  )}
                                </span>
                              }
                            />
                          )
                        },
                      )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <StickyButton
        className={`d-flex flex-row w-100 ${
          breakWidth === 'SM' ? 'px-3' : breakWidth === 'MD' ? 'px-5' : 'px-10'
        }`}
        condition
      >
        <AdjustPlan className='w-50' disabled={scheduled}>
          <Button
            disabled={scheduled}
            styleText={{
              lineHeight:
                breakWidth === 'SM' || breakWidth === 'MD' ? '1' : '1.5',
            }}
            className='w-100'
            style={{backgroundColor: `#FFB519`, border: 'none'}}
            label={translate('home.onboard.approve.confirm.adjust')}
            check
          />
        </AdjustPlan>
        <Button
          styleText={{
            lineHeight:
              breakWidth === 'SM' || breakWidth === 'MD' ? '1' : '1.5',
          }}
          disabled={!validationConfirm}
          className='w-50 mx-2'
          style={{backgroundColor: `#FFB519`, border: 'none'}}
          label={translate('home.onboard.approve.confirm.confirm')}
          check
          onClick={() => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.conflictModal',
              data: {
                ...conflictModal,
                isOpen: true,
                page: reduxData?.scheduled
                  ? 'listConflict'
                  : 'schedulingRecurring',
              },
            })
          }}
        />
      </StickyButton>
    </div>
  )
}
