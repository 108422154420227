import es from './es'
import en from './en'
import actions from './actions'
import payments from './payments'

export default {
  es,
  en,
  actions,
  payments,
}
