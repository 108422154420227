export default {
  add: 'Agregar Método de Pago',
  change: 'Cambiar Método de Pago',
  method: 'Método de Pago',
  added: 'agregado',
  changed: 'cambiado',
  current: 'Tú actual tarjeta',
  accept: 'Tarjetas aceptadas',
  info:
    'Su tarjeta de crédito no se cargará en este momento, sino que se preautorizará para cargos futuros según el calendario de pagos que se describe aquí.',
  success:
    'Cool, we send an email, integer propuse, erat an ante venenatis depabis posuere vellit aliquentaium ut eser no drema',
}
