import React, {Fragment} from 'react'
import {
  IconDolarRefresh,
  IconLesson,
  IconStarFilled,
  ResumenInvoice,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import * as moment from 'moment'
import useModalState from '../../../../../hooks/useModalState'

const PaymentsListItem = (props) => {
  const {invoice} = props
  const {onChange} = useModalState('payments.viewInvoice')
  const {translate} = useTranslation()

  return (
    <Fragment>
      <ResumenInvoice
        text={
          <span className='d-flex justify-content-between'>
            {translate(
              'profile.payments.endOf',
              moment(invoice.to).format('MMMM'),
            )}
          </span>
        }
        type={invoice.status === 'paid' ? 'green' : 'warn'}
        amount={invoice.amount}
        className='mb-2'
        key={invoice.id}
        icon={
          invoice.category === 'livePrep' ? (
            <IconLesson size='20' />
          ) : (
            <IconStarFilled size='20' />
          )
        }
        onClick={() => {
          onChange(() => ({invoiceId: invoice.id}))
        }}
      />
      {invoice.issuedInvoices[0].issuedCreditNotes.map((refund) => {
        return (
          <ResumenInvoice
            text={
              <span className='d-flex justify-content-between'>
                <span>
                  {translate(
                    'profile.payments.endOf',
                    moment(new Date(refund.stripeInfo.created * 1000)).format(
                      'MMM',
                    ),
                  )}
                </span>
                <span className='ml-7'>
                  {translate('profile.payments.refund')}
                </span>
              </span>
            }
            type='normal'
            amount={refund.amount}
            className='mb-2'
            key={invoice.id}
            icon={<IconDolarRefresh size='20' />}
            onClick={() => {
              onChange(() => ({invoiceId: invoice.id}))
            }}
          />
        )
      })}
    </Fragment>
  )
}

export default PaymentsListItem
