export const removeSpaces = (link = '') => {
  return link.split(' ').join('')
}
export const hasHttp = (parseLink) => {
  const link = removeSpaces(parseLink)
  // link incluye "http://" o "https://" al inicio
  if (
    link?.substring(0, 7) === 'http://' ||
    link?.substring(0, 8) === 'https://'
  )
    return link
  else return `https://${link}` // sino los tiene se concatena "https://" al inicio del link
}

export default {
  hasHttp,
}
