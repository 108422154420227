import {useMutation, gql} from '@apollo/client'
import planQueryResult from './queryResults/plan'

const useOperationTaskMutation = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
            mutation (
                $universities: [ID!]
                $operation: OperationTask!
                $userId: ID
                $taskId: ID!
                $stepId: ID!
                $phase: PhaseEnum!
                $planId: ID!
                ) {
                operationTask (
                    universities: $universities
                    operation: $operation
                    userId: $userId
                    taskId: $taskId
                    stepId: $stepId
                    phase: $phase
                    planId: $planId
                )  {
                    ${planQueryResult}
                }
            }
        `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useOperationTaskMutation
