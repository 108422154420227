import { useCallback } from 'react'
import useLazyPlan from './Model/useLazyPlan'
import useLazyTask from './Model/useLazyTask'
import useSetExamScore from './Model/useSetExamScore'
import useSetScoresToPlan from './Model/useSetScoresToPlan'
import useSubmitScoreModal from './useSubmitScoreModal'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../common/Alert/util'

//indica a que modelo se le adjutará el score
export const FORMODEL = {
    task: 'task',
    lessonPerWeekPlan: 'lessonPerWeekPlan'
    // plan: 'plan',
    // student: 'student'
}

const snakeToCamel = (str) => {
    return str.replace(/-([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    });
}

const useHandlerExamForm = (forModel = FORMODEL.task) => {
    const dispatch = useDispatch()

    const reduxData = useSubmitScoreModal()
    const { setLoading = () => {}, close, openForCreate, openForEdit } = reduxData

    const [getPlan] = useLazyPlan()
    const [setScoresToPlan] = useSetScoresToPlan()

    const [getTask] = useLazyTask()
    const [setExamScore] = useSetExamScore()


    //crea o edita score en task (resolveTask.score)
    const createScoreForTask = async (examInput = {}, task = {}) => {
        setLoading(true)
        const variables = {
            examInput,
            taskId: task?.taskId,
            stepId: task?.stepId,
            phase: snakeToCamel(task?.phaseId),
            planId: task?.planId,
        }
        return setExamScore({ variables })
        
    }
    //crea o edita score en plan (selectedExam)
    const createScoreForPlan = async (examInput = {}, plan = {}) => {
        setLoading(true)
        const variables = {
            examScore: examInput,
            planId: plan.id
        }
        return setScoresToPlan({ variables })
    }

    const onSuccess = () => {
        showAlert({text: 'Success!', status: 'success'}, dispatch)
    }
    const onError = (txt) => {
        showAlert({text: txt || 'Error!', status: 'error'}, dispatch)
    }

    const openScoreFormCreate = useCallback(
        (examScoreId, onChange = () => { }, data = {}) => {
            if (!examScoreId) {
                onError("No se encontró examen. ¡Falta información!")
                return
            }
            openForCreate((examInput) => {
                setLoading(true)
                if (forModel === FORMODEL.task) {
                    createScoreForTask(examInput, data).then(() => {
                        if (onChange) onChange(examInput)
                        onSuccess()
                        close()
                    }).catch(() => {onError()}).finally(() => { setLoading(false) })
                } else if (forModel === FORMODEL.lessonPerWeekPlan) {
                    createScoreForPlan(examInput, data).then(() => {
                        if (onChange) onChange(examInput)
                        onSuccess()
                        close()
                    }).catch(() => {onError()}).finally(() => { setLoading(false) })
                } else setLoading(false)
            }, examScoreId)
            // eslint-disable-next-line
        }, [forModel, setLoading]
    )
    const openScoreFormEdit = useCallback(
        (examData = {}, onChange = () => { }, data = {}) => {
            if (!examData) {
                alert("No se encontró examen. ¡Falta información!")
                return
            }
            openForEdit((examInput) => {
                setLoading(true)
                if (forModel === FORMODEL.task) {
                    createScoreForTask(examInput, data).then(() => {
                        if (onChange) onChange(examInput)
                        onSuccess()
                        close()
                    }).catch(() => {onError()}).finally(() => { setLoading(false) })
                } else if (forModel === FORMODEL.lessonPerWeekPlan) {
                    createScoreForPlan(examInput, data).then(() => {
                        if (onChange) onChange(examInput)
                        onSuccess()
                        close()
                    }).catch(() => {onError()}).finally(() => { setLoading(false) })
                } else setLoading(false)
            }, examData)
            // eslint-disable-next-line
        }, [forModel, setLoading]
    )



    const asyncOpen = useCallback(
        async (onChange = () => {}, modelId) => {
            let data
            let examData
            let examScoreId
            if (forModel === FORMODEL.lessonPerWeekPlan) {
                const planResult = await getPlan({ variables: { planId: modelId } })
                data = planResult?.data?.plan
                examScoreId = data?.examScores?.id
                examData = data?.selectedExam?.scores
            } else if (forModel === FORMODEL.task) {
                const taskResult = await getTask({ variables: { taskId: modelId } })
                data = taskResult.data.getTask
                examScoreId = data?.task?.selectedExamId
                examData = data?.task?.resolveTask?.score
            }
            //ya tiene score registrado. Se usará dicho score para armar el formulario y no el template
            if (examData) openScoreFormEdit(examData, onChange, data)
            else openScoreFormCreate(examScoreId, onChange, data)
            return true
            // eslint-disable-next-line
        }, [forModel, getTask, getPlan]
    )
    return {
        ...reduxData,
        open: asyncOpen
    }
}

export default useHandlerExamForm