export default {
  choose: ({index, name}) => {
    index += 1
    const string =
      index +
      (['th', 'st', 'nd', 'rd'][((index % 100) - 20) % 10] ||
        ['th', 'st', 'nd', 'rd'][index % 100] ||
        ['th', 'st', 'nd', 'rd'][0])
    return `Choose your ${string} ${name} lesson`
  },
  schedulingYourLessonTime: ({timeLabel}) => {
    return `Scheduling lesson from ${timeLabel}`
  },
  chooseOr: 'Choose your orientation session',
  orSubtitle:
    'Duis mollis, est non commodo luctus, nisni erat portitor ligula, eget laciia odio.',
  weekav: 'View week availability',
  giveMe: 'Give me a few seconds. \n Your lessons are scheduling.',
  someReason:
    'If for some reason we cannot schedule all the lessons automatically you will have to schedule them manually',
  notScheduled: "We couldn't schedule all lessons automatically",
  notScheduledsubtitle: 'Go to each lesson to scheduled',
  scheduled: 'Scheduled',
  recommended: 'Recommended',
}
