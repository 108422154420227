import React, {useEffect} from 'react'
import {useLazyQuery, gql} from '@apollo/client'
import {useLocation} from 'react-router'
import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'

const RedirectToLessonModal = (props) => {
  const {callBackFunction} = props
  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const lessonId = searchParams.get('lessonId')
  const isOrientation = searchParams.get('isOrientation')

  const [getDataLesson, {data: dataLesson}] = useLazyQuery(
    gql`
            query($lessonId: String) {
                getLesson(
                    lessonId: $lessonId
                ) {
                    ${queryLessonResult}
                }
            }
        `,
  )

  useEffect(() => {
    if (lessonId) getDataLesson({variables: {lessonId}})
  }, [lessonId, getDataLesson])

  useEffect(() => {
    if (dataLesson?.getLesson) {
      const {getLesson} = dataLesson
      callBackFunction(lessonId, getLesson, isOrientation)
    }
    // eslint-disable-next-line
    }, [dataLesson, isOrientation])

  return <div />
}

export default RedirectToLessonModal
