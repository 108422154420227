export default {
  notes: 'Lesson notes',
  rating: 'Lesson rating',
  duedate: 'Due date',
  score: 'Score',
  qone: 'What did the student learn today?',
  qtwo: "How was the student's effort and participation?",
  ocomment: 'Other Comments',
  minbefore: '30 min before',
  waiting: 'Waiting for educator to complete lesson notes.',
  description: 'Cras mattis consectetur purus sit amet fermentum donec.',
  recording: 'View lesson recording',
  norecording: 'Lesson without recording',
}
