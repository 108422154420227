import React, {useMemo, useState} from 'react'
import useFreeTimeFrames from './myFreeTimeFrames'
import Skeleton from 'react-loading-skeleton'
import * as moment from 'moment'
import {
  Avatar,
  LessonSelectCalendar,
  ListItem,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'

const EducatorScheduleSelection = (props) => {
  const {educator, setConfirm, selectedBlock: prevBlock} = props
  const [now, setNow] = useState(() =>
    moment().day(0).hours(7).minutes(0).seconds(0).milliseconds(0),
  )
  const [selectedBlock, setSelectedBlock] = useState(prevBlock)
  const [from, to] = useMemo(() => {
    const from = now.toISOString()
    const to = now.clone().add(7, 'd').toISOString()
    return [from, to]
  }, [now])
  const {data, loading} = useFreeTimeFrames({
    educatorId: educator.educatorUserId,
    from,
    to,
    minDurationInMinutes: 50,
    involveMyself: true,
  })
  return (
    <>
      <ListItem
        showTopLine
        Left={
          <div className='d-flex align-items-center text-muted'>
            <>
              <Avatar
                name={`${educator.firstName} ${educator.lastName}`}
                named={false}
                uri={null}
                size='avatar-medium'
              />
              <span className='ml-2'>
                {educator.firstName} {educator.lastName}
              </span>
            </>
          </div>
        }
      />
      {loading ? (
        <Skeleton height={100} />
      ) : (
        <LessonSelectCalendar
          moment={now}
          setMoment={setNow}
          availabilityBlocks={data}
          loading={loading}
          selectedBlock={selectedBlock}
          setSelected={setSelectedBlock}
        />
      )}
      <StickyButton
        condition={selectedBlock !== null}
        onClickButtonFunction={() => setConfirm(selectedBlock)}
      />
    </>
  )
}

export default EducatorScheduleSelection
