export default function (amount) {
  if (isNaN(amount)) {
    return '0.00'
  }
  const value = amount
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return value
}
