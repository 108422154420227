import React, {useMemo} from 'react'
import {
  Modal,
  IconClose,
  Button,
  InputSelect,
  PlainTextInput,
  Field,
  useWindowDimensions,
  IconSeen,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'
import useUser from '../../../../../../hooks/auth/useUser'
import setAccountFromUser from '../../../../../../Model/Users/Accounts/CreateAccount/setAccountFromUser'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
// import getAllAccountsFromUser from '../../../../../../Model/Users/Accounts/AllAccounts/getAllAccountsFromUser'
import useGetAccounts from '../../../../../../Model/Users/Accounts/IdsAccounts/useGetAccounts'
import {showAlert} from '../../../../../../common/Alert/util'

const AccountCreate = (props) => {
  const {isOpen, toggle, refetch = () => {}} = props
  const account = useSelector(
    (state) => state?.modal?.profile?.account?.info?.account,
  )

  const {user} = useUser()

  const dispatch = useDispatch()

  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  // eslint-disable-next-line
  // const { data: allAccountsData, loading: allAccountsLoading } = getAllAccountsFromUser()
  // const accountsIncompleted = useMemo(() => {
  //   if (allAccountsData) {
  //     return allAccountsData.filter(
  //       (account) => account?.password === '' || account?.username === '',
  //     )
  //   }
  // }, [allAccountsData])
  const {data: allAccountsData} = useGetAccounts()
  const accounts = useMemo(() => allAccountsData?.getAccounts || [], [
    allAccountsData,
  ])
  const {
    submit: createAccountMutation,
    loading: createAccountLoading,
    // eslint-disable-next-line
        data: createAccountData
  } = setAccountFromUser(
    (res) => {
      showAlert({text: '¡Success!', status: 'success'}, dispatch)
      refetch()
    },
    (err) => {
      console.error(err)
      showAlert({text: err.toString(), status: 'error'}, dispatch)
    },
  )
  function passwordVisibility() {
    const passVisibility = document.getElementById('pass')
    if (passVisibility.type === 'password') {
      passVisibility.type = 'text'
    } else {
      passVisibility.type = 'password'
    }
  }

  const onChangeFunction = (value) => {
    const filterItem = accounts.filter((item) => {
      return item?.id === value
    })
    const object = filterItem[0]
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'profile.account.info',
      data: {
        account: object,
      },
    })
  }
  if (user) {
    return (
      <>
        <Modal
          className={`modal-${
            breakWidth === 'SM' ? 'botbar' : 'sidebar'
          } d-flex flex-column`}
          backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <ModalHeader
              className='px-0 pt-1 pb-0 mb-1'
              toggle={toggle}
              close={
                <div className='flex-grow-1 d-flex flex-row justify-content-end'>
                  <div className='hover-icon w-min-content'>
                    <IconClose size={20} onClick={toggle} />
                  </div>
                </div>
              }
            >
              <div className='d-flex flex-column'>
                <span className='h2 font-weight-bold'>
                  {translate('profile.actions.accounts.addKey')}
                </span>
                <span className='h4 my-4 text-muted'>
                  {translate('profile.actions.accountsText')}
                </span>
              </div>
            </ModalHeader>
          }
        >
          <>
            <div className='w-100 pr-2 mb-2 mt-2 h45 text-gray'>Account</div>
            <Field
              type={InputSelect}
              component={PlainTextInput}
              fieldName='account'
              placeholder={translate('profile.actions.accounts.select')}
              value={account?.id}
              options={accounts?.map((account) => ({
                value: account?.id,
                label: account?.name,
              }))}
              label={translate('profile.actions.accounts.account')}
              onChange={(value) => {
                onChangeFunction(value)
              }}
            />

            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('profile.actions.accounts.user')}
            </div>
            <div
              className='w-100 pr-2 pl-3 mb-2 d-flex flex-row'
              style={{
                border: 'none',
                backgroundColor: '#f0f4f8',
                borderRadius: '14px',
                height: '44px',
                outline: 'none',
              }}
            >
              <input
                className='w-100 d-flex flex-row'
                style={{
                  border: 'none',
                  backgroundColor: '#f0f4f8',
                  width: '90%',
                  height: '100%',
                  outline: 'none',
                }}
                type='input'
                id='user'
                name='user'
                defaultValue=''
                onChange={() => {}}
              />
            </div>

            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('counseling.password')}
            </div>
            <div
              className='w-100 pr-2 pl-3 mb-4 d-flex flex-row'
              style={{
                border: 'none',
                backgroundColor: '#f0f4f8',
                borderRadius: '14px',
                height: '44px',
              }}
            >
              <input
                className='w-100 d-flex flex-row'
                style={{
                  border: 'none',
                  backgroundColor: '#f0f4f8',
                  width: '90%',
                  height: '100%',
                  borderWidth: '0px',
                  outline: 'none',
                }}
                type='password'
                id='pass'
                name='pass'
                defaultValue=''
              />
              <IconSeen
                style={{cursor: 'pointer'}}
                className='align-self-center'
                color='#73819e'
                onClick={() => passwordVisibility()}
              />
            </div>

            <Button
              label={translate('profile.actions.accounts.createAccount')}
              color='primary'
              activity={createAccountLoading}
              check
              size='large'
              onClick={() =>
                createAccountMutation({
                  accountInput: {
                    platformName: account?.name,
                    username: document.getElementById('user')?.value,
                    password: document.getElementById('pass')?.value,
                    type: 'custom',
                  },
                })
              }
            />
          </>
        </Modal>
      </>
    )
  } else return null
}

export default AccountCreate
