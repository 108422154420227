import React from 'react'
import {TemplateTimeline} from '@Knowledge-OTP/znk-ui-components'
// import RenderInputOption from '../../../../../newPlanModal/Components/RenderInputOption'
import useTemplateReducer from '../../../../../../../reducers/template'
// import useViewTaskModal from '../../../../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'

import './index.css'

const ScheduleDetails = (props) => {
  const {className, plan} = props

  const {filterDelete} = useTemplateReducer()

  // const {open: openTask} = useViewTaskModal()

  // plan?.timeline es el template que renderiza TemplateTimeline (no el del reduce)
  const templatePhases = plan?.tasks

  return (
    <div className={`m-0 p-0 d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Schedule details</span>
      {/* <RenderInputOption 
                key={'range'} 
                label={`FROM ${moment(plan?.from).format('MMM YYYY').toUpperCase()} TO ${moment(plan?.to).format('MMM YYYY').toUpperCase()}`} /> */}
      {templatePhases /** && !templateReduce?.isInitialState **/ ? (
        <div className='mx-2 my-3 timeline-content'>
          <TemplateTimeline
            className='w-100 p-0'
            isEdit={false}
            stages={
              templatePhases
                ? filterDelete({phases: templatePhases}).phases
                : []
            }
            onClickTask={(task) => {
              // abre modal para ver tarea
              // openTask({...task, isEdit: false})
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default ScheduleDetails
