import moment from 'moment-timezone'
export default (lessons, upcomingLessonId, orientationId) => {
  // const [upcomingLessonID, setUpcomming] = useState(null)
  // let upcomming = null // se usa para no sobreescribir el state mientras se setea
  if (!lessons) return []
  return lessons
    .filter((less) => less.__typename !== 'Counselor')
    .map((lesson) => {
      const {
        status,
        startDate,
        endDate,
        id,
        student,
        studentPlan,
        studentGroup,
      } = lesson
      // if (
      //  upcomingLessonID === id &&
      //  moment(endDate) > moment()
      // ) {
      //  setUpcomming(id)
      //  upcomming = id
      // }
      const currentStudent = student || studentPlan || studentGroup || {}
      return {
        ...lesson,
        isOrientation: lesson?.service?.id === orientationId,
        status:
          status === 'finished'
            ? currentStudent?.attendanceStatus === 'attended'
              ? 'attended'
              : 'missed'
            : status === 'scheduled'
            ? moment(startDate) < moment()
              ? moment(endDate) > moment()
                ? 'upcoming' // 'inprogress'
                : 'incomplete'
              : upcomingLessonId === id
              ? 'upcoming'
              : 'scheduled'
            : status,
      }
    })
}
