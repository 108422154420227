import React from 'react'
import useTranslation from '../../../../i18n/useTranslation'

const NeedHelpComponent = () => {
  // translation
  const {translate} = useTranslation()

  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-end'>
      <span className='h4-bold mr-6 mt-4' style={{lineHeight: '1.6'}}>
        {translate('workspace.teach.right.bottominfo.title')}
      </span>
      <span className='h4 text-gray mt-2 mb-4' style={{lineHeight: '1.6'}}>
        {translate('workspace.teach.right.bottominfo.body')}
      </span>
      <span className='h4 btn-link' style={{lineHeight: '1.6'}}>
        {translate('counseling.contact')}
      </span>
    </div>
  )
}

export default NeedHelpComponent
