import {gql, useMutation} from '@apollo/client'

const UseUpdateTimezone = () => {
  return useMutation(
    gql`
      mutation($userId: String, $newTimezone: String!) {
        updateTimezone(userId: $userId, newTimezone: $newTimezone)
      }
    `,
  )
}

export default UseUpdateTimezone
