import PropTypes from 'prop-types'

export const defaultProps = {
  emails: [],
  firstName: '',
  lastName: '',
  phone: '',
  timezone: '',
  birthdate: '',
  studentInfo: {
    institution: '',
    graduationDate: '',
    gpa: '',
  },
}
export const propTypes = {
  emails: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  timezone: PropTypes.string,
  birthdate: PropTypes.string,
  studentInfo: PropTypes.object,
  parentInfo: PropTypes.object,
}
