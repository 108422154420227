import React, {useEffect, useState} from 'react'
// import { PDFViewer } from '@react-pdf/renderer'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

const EmbedPdf = (props) => {
  const {url, className, ...otherProps} = props

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [url])
  // useEffect(
  //     () => {
  //         if(
  //             iframe?.current?.contentWindow?.location && url
  //         ) {
  //             console.log("refresh")
  //             iframe.current.contentWindow.location.reload(true)
  //         }
  //     }, [iframe, url]
  // )
  return (
    // <embed id={'pdfDocument'} src={url} type="application/pdf" {...otherProps}></embed>
    <TabContent
      activeTab={!loading ? 'document' : 'loading'}
      className='h-inherit'
    >
      <TabPane tabId='document' className='h-100vh'>
        {!loading && (
          <iframe
            title='pdfDocument'
            src={url}
            type='application/pdf'
            className={`h-100vh ${className}`}
            {...otherProps}
          />
        )}
      </TabPane>
      <TabPane tabId='loading' className='h-100vh'>
        <Skeleton count={1} height={700} className='w-100' />
      </TabPane>
    </TabContent>
    // <PDFViewer src={url} {...otherProps} ref={iframe}></PDFViewer>
  )
}

export default EmbedPdf
