import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import * as moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import {
  ModalLayout,
  IconDate,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import ShowScheduledMeeting from '../ShowScheduledMeeting'
import CompleteScore from './Components/completeScore'
import useShowTask from './useShowTask'
import taskTypes from '../../Utils/taskTypes'
import LinkToShow from './Components/linkToShow'
import UploadFile from './Components/uploadFile'
import CompletionTask from './Components/completionTask'
import {UseMyTeam} from '../../../../Model/Users/MyTeam/useMyTeam'
import useAllUserTasks from '../../../Task/Model/useAllUserTasks'
import VirtualChat from '../../../VirtualChat'
import collectionType from '../../../VirtualChat/utils/collectionType'
import {db} from '../../../VirtualChat/fireBase'
import rolesNames from '../../Utils/rolesNames'
import ScheduledMeetingComponent from './Components/scheduledMeetingComponent'
import LoginInfoModal from './Components/loginInfo'
import IsSpecific from './Components/IsSpecific'
import {setAvatarToUsers} from '../../Utils/setAvatarToUsers'
import ViewCounselor from './Components/ViewCounselor'
import UploadFileButton from './Components/UploadFileButton'
import useUsersQuery from '../../../../Model/Users/List/useGetUsersList'

const ShowTask = () => {
  const {isOpen, toggle /*, task */, close, setTaskData} = useShowTask()
  const [usersQuery, {data: dataUsers}] = useUsersQuery()
  const [scheduledTaskModalOpen, setScheduledTaskModalOpen] = useState(false)
  const [filesUploadedByUser, setFilesUploadedByUser] = useState([])
  const [staffNotTeam, setStaffNotTeam] = useState(null)
  // eslint-disable-next-line
  const task = useSelector((state) => state.modal.counseling.viewTaskModal?.task)
  const [uploadStatusOk, setUploadStatus] = useState('')
  // eslint-disable-next-line
  const [usersListData, setUsersListData] = useState([])
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )
  const userInfo = useSelector((state) => state?.user?.info)
  // eslint-disable-next-line
  const [getMyPlan, { data: dataTeam, loading: loadingTeam }] = UseMyTeam()

  // eslint-disable-next-line
  const { data: dataAllTasks, refetch: refetchTasks } = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })
  const [onlineStatus, setOnlineStatus] = useState({})
  const [usersList, setUsersList] = useState([])
  const ParsedUsersList = (actualUserId, list = {}) => {
    const rolesList = Object.keys(list || {}).filter(
      (role) => role !== '__typename',
    )
    setUsersList(rolesList)
    db.collection('usersStatus')
      .doc(actualUserId)
      .onSnapshot((snapshot) => setOnlineStatus(snapshot.data()))

    setUsersList((usersList) => {
      return usersList?.map((rol) => {
        let userID = ''
        userID = list[rol]?.teamUserId
        return {...list[rol], id: userID, role: rolesNames[rol] || rol}
      })
    })

    return {onlineStatus, usersList}
  }

  useEffect(() => {
    if (taskTypes.scheduledMeettingArray.includes(task?.type)) {
      usersQuery({
        variables: {
          role: 'SUPER_ADMIN',
          limit: 0,
          showInactive: false,
        },
      })
    }
    // eslint-disable-next-line
}, [task])

  useEffect(() => {
    if (dataUsers?.allUsersFilter?.docs?.length > 0 && task?.userMeetingId) {
      setStaffNotTeam(
        dataUsers.allUsersFilter.docs.filter((user) => {
          return user.id === task?.userMeetingId
        }),
      )
    } else if (task?.userMeetingId === null) {
      setStaffNotTeam([
        {
          ...dataTeam?.myPlan?.councelor,
          avatar: dataTeam?.myPlan?.aditionalInfo?.avatarCounselor,
        },
      ])
    } else {
      setStaffNotTeam(null)
    }
    // eslint-disable-next-line
}, [dataUsers, task])

  useEffect(() => {
    if (planId && planId !== '') {
      getMyPlan({
        variables: {
          planId,
        },
      })
    }
    // eslint-disable-next-line
  }, [planId])

  useEffect(() => {
    if (dataTeam) {
      setUsersListData(ParsedUsersList(userInfo?.id, dataTeam?.myPlan))
    }
    // eslint-disable-next-line
  }, [dataTeam])

  useEffect(() => {
    if (filesUploadedByUser.length > 0) {
      setUploadStatus(true)
    } else if (filesUploadedByUser.length === 0 && !task?.uploadDocs) {
      setUploadStatus(true)
    } else {
      setUploadStatus(false)
    }
  }, [filesUploadedByUser, task])
  const {breakWidth} = useWindowDimensions()
  return (
    <>
      <ModalLayout
        className={`modal-colored modal-${
          breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
        }  white-modal`}
        isOpen={isOpen}
        toggle={() => {
          setTaskData({})
          toggle()
        }}
        topHead={
          <span className='h2' style={{color: '#485a70'}}>
            {task?.name}
          </span>
        }
        minHead={
          <span className='h2' style={{color: '#485a70'}}>
            {task?.name}
          </span>
        }
        useRightSection={false}
        disabledScrollAnimation={false}
      >
        {<span>{`Task id: ${task?.id}`}</span>}
        {/* Renderizar si la Task está en Review */}
        {task?.status === 'review' &&
          (taskTypes.scheduledMeettingArray.includes(task?.type) ? (
            // staffNotTeam?.length > 0 ?

            staffNotTeam ? (
              <ViewCounselor user={staffNotTeam[0]} />
            ) : (
              <Skeleton count={1} height={60} />
            )
          ) : (
            <ViewCounselor
              user={{
                ...dataTeam?.myPlan?.councelor,
                avatar: dataTeam?.myPlan?.aditionalInfo?.avatarCounselor,
              }}
            />
          ))}

        <div className='d-flex flex-row justify-content-between my-4'>
          <div className='d-flex flex-row align-items-center m-0 p-0'>
            <IconDate
              size={15}
              color={`${
                moment(task?.dueDate) < moment()
                  ? '#FF3E1D'
                  : // : moment(task?.dueDate) > moment()
                    // ? '#6c757d'
                    '#2C3F78'
              }`}
            />
            <span
              className={`h45 ml-2 ${
                moment(task?.dueDate) < moment()
                  ? 'text-error'
                  : // : moment(task?.dueDate) > moment()
                    //   ? 'date-ontime'
                    'text-info'
              }`}
            >
              {`${moment(task?.dueDate).fromNow()}`}
            </span>
          </div>

          {task?.isEspecific && (
            <IsSpecific universitiesList={task?.universities || []} findInDB />
          )}
        </div>
        <div className='d-flex flex-row mb-6'>
          <span className='h4 mt-2'>{task?.instructions}</span>
        </div>

        {/* Renderizar según el type de la task */}

        {taskTypes.linkArray.includes(task?.type) && (
          <LinkToShow
            task={task}
            uploadStatusOk={uploadStatusOk}
            filesUploadedByUser={filesUploadedByUser}
          />
        )}

        {taskTypes.loginInfoArray.includes(task?.type) && (
          <LoginInfoModal task={task} />
        )}

        {taskTypes.uploadFileArray.includes(task?.type) && (
          <UploadFile task={task} />
        )}

        {taskTypes.scoreCollectionArray.includes(task?.type) && (
          <CompleteScore
            task={task}
            closeModal={() => close()}
            uploadStatusOk={uploadStatusOk}
            filesUploadedByUser={filesUploadedByUser}
          />
        )}
        {taskTypes.completionArray.includes(task?.type) && (
          <CompletionTask
            task={task}
            uploadStatusOk={uploadStatusOk}
            filesUploadedByUser={filesUploadedByUser}
          />
        )}

        {taskTypes.scheduledMeettingArray.includes(task?.type) && (
          <ScheduledMeetingComponent
            task={task}
            team={setAvatarToUsers(usersList)}
          />
        )}

        {/* Fin renderizados según el type de la task */}

        {/* Muestra recuadro para agregar documentos a la task */}
        {task?.uploadDocs &&
          task?.status === 'pending' &&
          task?.type !== taskTypes.uploadFile &&
          task?.type !== taskTypes.scoreCollection && (
            <UploadFileButton
              filesUploadedByUser={filesUploadedByUser}
              setFilesUploadedByUser={setFilesUploadedByUser}
              uploadStatusOk={uploadStatusOk}
              setUploadStatus={setUploadStatus}
            />
          )}

        <div className='borderTop mt-7' />

        {task && !['completed', 'unassigned'].includes(task?.status) && (
          <VirtualChat
            // {...task}
            collectionType={collectionType}
            usersList={setAvatarToUsers(usersList)}
            collection={task}
            actualUserId={userInfo?.id}
            toggle={() => {
              toggle()
            }}
          />
        )}
      </ModalLayout>

      <ShowScheduledMeeting
        isOpen={scheduledTaskModalOpen}
        toggle={() => setScheduledTaskModalOpen(!scheduledTaskModalOpen)}
        task={task}
      />
    </>
  )
}

ShowTask.defaultProps = {
  isOpen: false,
  toggle: () => {},
}

ShowTask.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ShowTask
