import {useState} from 'react'
import useGetOnboardingSession from './Model/useGetOnboardingSession'
import useSocketEntities from '../SocketGateway/useSocketEntities'

const useOnboardingRooms = () => {
  const {connection: socket} = useSocketEntities()
  const [hasSession] = useState(false)
  const [getSession] = useGetOnboardingSession()
  const connectToRoomSession = (idPlan, status = null) => {
    if (socket) socket.emit('join', idPlan, status)
  }
  // Things to do before unloading/closing the tab
  const leaveRoomSession = (idPlan) => {
    socket.emit('leave', idPlan)
  }

  return {
    hasSession,
    // crea room
    createSession: (planId, status = null) => {
      connectToRoomSession(planId, status)
    },
    leaveSession: (planId) => {
      leaveRoomSession(planId)
    },
    // recibe planId y retorna true en caso
    // de que exista otra session en curso o false si no.
    checkSession: async (planId) => {
      const res = await getSession({
        variables: {planId},
      })
      const bool = res?.data?.getOnboardingSession || false
      return bool
    },
  }
}

export default useOnboardingRooms
