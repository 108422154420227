import React, {useEffect} from 'react'
import {Modal, IconClose, LessonCard} from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'
import useTranslation from '../../../../i18n/useTranslation'

const IncompleteModal = (props) => {
  const {lessons} = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const {isOpen} = useSelector(
    (state) => state.modal.calendar.incompleteLessonModal,
  )
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.incompleteLessonModal',
      data: {
        isOpen: !isOpen,
      },
    })
  }
  useEffect(() => {
    if (lessons.length === 0) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.incompleteLessonModal',
        data: {
          isOpen: false,
        },
      })
    }
  }, [lessons, dispatch])
  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-4 d-flex flex-column'>
          <div className='w-100 d-flex flex-row justify-content-between'>
            <span className='h2 font-weight-bold'>
              {translate('modal.incompletelessons.title')}
            </span>
            <IconClose onClick={toggle} />
          </div>
          <span className='h4 text-gray text-justify'>
            {translate('modal.incompletelessons.body')}
          </span>
        </div>
      }
    >
      <div className='m-0 px-6'>
        <div className='d-flex flex-column my-2'>
          {lessons.map((lesson) => (
            <LessonCard
              title={lesson.title}
              status='incompleteLesson'
              preLabelTime={null}
              lessonTime={moment(lesson.startDate)}
              lessonEndTime={moment(lesson.endDate)}
              attendants={[
                {
                  name: `${lesson?.educator?.firstName} ${lesson?.educator?.lastName}`,
                  avatar_url: null,
                },
              ]}
              showCountdown={false}
              className='w-100 m-1 mb-1'
              onClick={() => {
                // redirige a modal de lección incompleta
                toggle()
                dispatch({
                  type: 'SET_DATA_MODAL_FORM_LIST',
                  payload: 'calendar.infoLessonModal',
                  data: {
                    isOpen: true,
                    dataLesson: {
                      ...lesson,
                      showInCenter: true,
                      redirectToListAfter: true,
                    },
                  },
                })
              }}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default IncompleteModal
