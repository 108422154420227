import validate from 'validate.js'

const constraints = {
  newPass: {
    presence: true,
    length: {
      minimum: 6,
      maximum: 15,
    },
  },
  confirmPass: {
    presence: true,
    equality: 'newPass',
  },
}

export default (data) => {
  return validate(data, constraints)
}
