import { useQuery, gql } from "@apollo/client"

const useGetGoogleCalendarDocument = (variables = {}) => {
    return useQuery(
        gql`
        query (
            $userId: String!
            $role: String!
            ) {
            getGoogleCalendarDocument (
                userId: $userId
                role: $role
            )
        }`, {variables}
    )
}

export default useGetGoogleCalendarDocument