// String con modelo de objeto que será utilizado para
// obtener toda la información de una Universidad.
const universityCompleteQueryResult = `
id
ranking
isPrincipal
isActive
createdAt
updatedAt
deleteAt
collegeId
name
shortDescription
stateAbbr
city
typeCategory
undergraduateSize
acceptanceRate
fourYearGraduationRate
rankingsBestTotal
rankingsBestValue
satAverage
actCumulativeMidpoint
typeYear
avgCostOfAttendance
logo
collegeUnitId
`

export default universityCompleteQueryResult
