import React from 'react'
import {
  IconArrowDown,
  InputSearchSelector,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {Badge} from 'reactstrap'

export const baseCategories = ['livePrep', 'counseling']

const CategoriesFilter = (props) => {
  const {categories, setCategories} = props
  const {translate} = useTranslation()
  return (
    <InputSearchSelector
      setDataFilters={setCategories}
      dataFilters={categories}
      direction='down'
      data={baseCategories.map((cat) => ({
        name: translate(`profile.payments.categories.${cat}`),
        id: cat,
      }))}
      hoverEnabled
      typeFilter='paymentCategories'
      strings={{
        viewAll: translate('misc.filters.viewAll'),
        buttonCancel: translate('misc.filters.buttonCancel'),
        buttonApply: translate('misc.filters.buttonApply'),
        search: translate('misc.filters.search'),
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{cursor: 'pointer'}} className='m-0 p-0'>
          {translate('profile.payments.categories.label')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </InputSearchSelector>
  )
}

export default CategoriesFilter
