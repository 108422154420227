import {useQuery, gql} from '@apollo/client'

const UseTeachStudentDetails = (
  vars = {},
  options = {fetchPolicy: 'no-cache'},
) => {
  return useQuery(
    gql`
      query($services: [String!], $search: String, $page: Int, $limit: Int) {
        getTeachStudentDetails(
          servicesId: $services
          search: $search
          page: $page
          limit: $limit
        ) {
          totalDocs
          docs {
            studentUserId
            firstName
            lastName
            avatar
            studentInfo {
              materials
            }
            topics {
              topicId: id
              name
              serviceId
              serviceName
            }
            materialsTopic {
              topicId
              topicName
              serviceId
              serviceName
              link
            }
            action {
              key
              idLesson
              finishedAt
            }
          }
        }
      }
    `,
    {
      variables: vars,
      // ...options,
    },
  )
}

export default UseTeachStudentDetails
