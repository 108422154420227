import React from 'react'
import Skeleton from 'react-loading-skeleton'
import PlanModal from '../modal'
import useTranslation from '../../../../i18n/useTranslation'
import CounselingPlanCard from './CounselingPlanCard'
import {TabContent, TabPane} from 'reactstrap'

const PlanSection = (props) => {
  const {translate} = useTranslation()
  const {plans, loading, title} = props
  return (
    <>
      <span className='text-muted h5 mt-2'>
        {title || <Skeleton height={2} />}
      </span>
      <TabContent
        activeTab={loading ? 'loading' : plans?.length > 0 ? 'show' : 'blank'}
      >
        <TabPane tabId='loading'>
          <Skeleton count={1} height={100} />
        </TabPane>
        <TabPane tabId='show'>
          {plans?.length > 0 &&
            plans.map((item) => {
              if (item?.kind === 'counceling')
                return <CounselingPlanCard plan={item} />
              const totalLesson =
                item.kind === 'lessonsFixed'
                  ? item.lessonPerTopic.reduce((total, lesson) => {
                      return lesson.totalLessons + total
                    }, 0)
                  : item.parts.reduce((total, lesson) => {
                      return lesson.lessonsPerWeek + total
                    }, 0)
              const totalPlan =
                item.kind === 'lessonsFixed' &&
                item.lessonPerTopic.reduce((isTotal, lesson) => {
                  return isTotal || lesson.type === 'totalLesson'
                }, false)
              return (
                <PlanModal
                  info={item}
                  key={item.id}
                  status={item.status}
                  isFixedPlan={item.isFixedPlan}
                  isTotalLesson={item.isTotalLesson}
                  lessonsPerWeek={
                    totalPlan
                      ? translate('program.plan.totalLessons', totalLesson)
                      : translate('program.plan.lessonsPerWeek', totalLesson)
                  }
                />
              )
            })}
        </TabPane>
        <TabPane tabId='blank'>
          <div className='d-flex flex-row m-0 p-0 w-100 justify-content-center'>
            <span className='my-4 h45 text-gray'>
              {translate('program.plan.noFound')}
            </span>
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}
PlanSection.defaultProps = {
  plans: [],
  title: null,
  loading: true,
}
export default PlanSection
