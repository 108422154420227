import {gql, useQuery} from '@apollo/client'

export default (vars) => {
  const {data, error, loading} = useQuery(
    gql`
      query(
        $limit: Int
        $userId: [String!]
        $status: [PaymentStatus!]
        $sortField: String
      ) {
        payslips(
          limit: $limit
          userId: $userId
          status: $status
          sortField: $sortField
        ) {
          docs {
            id
            payslipDate
            to
            status
            payslipNumber
            payslipProps {
              amount
            }
          }
        }
      }
    `,
    {
      variables: vars,
      nextFetchPolicy: 'network-only',
    },
  )
  return {loading, data: data ? data.payslips : [], error}
}
