import React from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {useQuery} from '@apollo/client'
import {
  Modal,
  IconClose,
  ListItem,
  IconRight,
  Avatar,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import paymentMethodString from './paymentMethodString'
import useTranslation from '../../../../i18n/useTranslation'
import useInvoiceModal from './useInvoiceModal'
import useProcessInvoice from '../../../../Model/Users/ProcessInvoice'
import invoicesQuerys from '../../../../Model/Users/ProcessInvoice/invoicesQuerys'

const InvoiceModal = () => {
  const studentInfo = useSelector((state) => state.user.info)
  const {isOpen, toggle, invoice} = useInvoiceModal()
  const {translate} = useTranslation()
  // eslint-disable-next-line
    const { data: dataInvoice, loading: loadingInvoice, refetch: refetchInvoices } = useQuery(invoicesQuerys)
  const {loading: processingLoading, submit} = useProcessInvoice(() => {
    refetchInvoices()
  })

  return (
    <>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header px-2 flex-row'>
            <div className='d-flex flex-column  '>
              <span className='flex-grow-1 text-title font-weight-bold h3'>
                {translate('payments.paymentTitle')}
              </span>
              <span className='text-title font-weight-bold h2'>
                {invoice?.description}
              </span>
            </div>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <>
          <ListItem
            className='borderTop w-100 m-0 p-4'
            Left={
              <div className='d-flex flex-row'>
                <Avatar
                  classNameAvatar='w-100 text-align-center'
                  uri={null}
                  name={studentInfo?.firstName + ' ' + studentInfo?.lastName}
                  size='avatar-small'
                />
                <span
                  style={{
                    color: '#2c3f78',
                  }}
                  className='align-self-center ml-2'
                >
                  {studentInfo?.firstName + ' ' + studentInfo?.lastName}
                </span>
              </div>
            }
            Right={<IconRight size={16} color='#818ea7' />}
            onClick={() => {}}
          />

          <ListItem
            className='borderTop w-100 m-0 px-2'
            showBottomLine
            Left={
              <span
                className='align-self-center'
                style={{
                  color: '#000000',
                }}
              >
                {translate('payments.paymentMethod')}
              </span>
            }
            Right={
              <div>
                <span
                  className='align-self-center'
                  style={{
                    color: '#000000',
                  }}
                >
                  {paymentMethodString[studentInfo?.paymentMethod?.__typename]}
                </span>
                <IconRight
                  className='align-self-center'
                  size={16}
                  color='#818ea7'
                />
              </div>
            }
            onClick={() => {}}
          />
          <div
            className='d-flex align-self-center justify-content-between h45 px-1 my-2 p-4'
            style={{
              borderRadius: '16px',
              backgroundColor: '#696bff',
              color: '#ffffff',
            }}
          >
            <span className='h45 ml-2'>
              {moment(invoice?.date).format('MMM Do')}
            </span>
            <span className='h45 ml-2'>$ {invoice?.amount}</span>
          </div>
          <Button
            label='Process Invoice'
            color='primary'
            check
            size='medium'
            className='w-70 mt-4'
            activity={processingLoading}
            onClick={() => {
              submit({
                amount: invoice.amount || 0,
                invoiceId: invoice.id,
              })
            }}
          />
        </>
      </Modal>
    </>
  )
}

export default InvoiceModal
