import React, {Suspense, useMemo, useState} from 'react'
import {Row} from 'reactstrap'
import {Navbar, useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {navItems} from './navItems'
import {Route, Switch} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import {Redirect, useHistory} from 'react-router'
import InfoCards from './Info'
import PaymentsCard from './Pyments'
import FullProfile from './Info/FullPersonalInfo'
import FullPayments from './Pyments/FullPayments'
import {useSelector} from 'react-redux'
import PayslipsCard from '../Educator/Payments'

const ProfileView = () => {
  const info = useSelector((state) => state.user.info)
  const Roles = [].concat(info.roles)
  const user = useMemo(() => {
    let isStudent = false
    let isEducator = false
    if (Roles) {
      if (Roles.filter((e) => e === 'STUDENT').length > 0) {
        isStudent = true
      } else if (Roles.filter((e) => e === 'EDUCATOR')) {
        isEducator = true
      }
      return {
        isStudent,
        isEducator,
      }
    }
    return {
      isStudent: false,
      isEducator: false,
    }
  }, [Roles])
  const [mouseOver, setMouseOver] = useState(false)
  const history = useHistory()
  const {breakWidth} = useWindowDimensions()
  return (
    <div className='d-flex h-100'>
      <div
        className='col-12 col-md-6 col-lg-5 col-xl-4 position-relative px-0 d-flex flex-column hidde-scroll-vertical'
        style={{
          overflowY: !mouseOver ? 'auto' : 'visible',
          overflowX: 'hidden',
        }}
      >
        <div className='mt-md-5'>
          <Navbar
            light
            expand='sm'
            className='d-flex px-3 pl-md-7 brandless tabTexts align-items-start'
            navClass='d-flex align-items-center'
            history={history}
            FootBrand={<></>}
            items={navItems}
          />
        </div>
        <div className='flex-grow-1'>
          <Switch>
            <Route exact path='/profile/info' component={InfoCards} />
            <Route
              exact
              path='/profile/payments'
              render={() =>
                user.isStudent ? (
                  <PaymentsCard setMouseOver={setMouseOver} />
                ) : user.isEducator ? (
                  <PayslipsCard setMouseOver={setMouseOver} />
                ) : (
                  <></>
                )
              }
            />
            <Route render={() => <Redirect to='/profile/info' />} />
          </Switch>
        </div>
      </div>
      {breakWidth !== 'SM' && (
        <div className='col-md-6 col-lg-7 col-xl-8 subBody hidde-scroll-vertical'>
          <Row className='w-100 pl-md-4 pr-md-0 pl-lg-6 pr-lg-3 px-xl-8'>
            <Suspense fallback={<Skeleton count={3} height={10} />}>
              <Switch>
                <Route exact path='/profile/info' component={FullProfile} />
                <Route
                  exact
                  path='/profile/payments'
                  component={FullPayments}
                />
                <Route render={() => <Redirect to='/profile/info' />} />
              </Switch>
            </Suspense>
          </Row>
        </div>
      )}
    </div>
  )
}

export default ProfileView
