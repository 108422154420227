export default function reducer(state, action) {
  switch (action.type) {
    case 'set-data':
      return {
        ...state,
      }
    default:
      throw new Error()
  }
}
