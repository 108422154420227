import React, {useState} from 'react'
import {IconArrowRight, IconLoad} from '@Knowledge-OTP/znk-ui-components'
import taskTypes from '../../../../Utils/taskTypes'
import useHandlerExamForm, {FORMODEL} from '../../../DocsAndExams/SubmitScore/useHandlerExamForm'
import ShowScheduledMeeting from '../../../ShowScheduledMeeting'
import InfoTaskButton from './infoTaskButton'

const CompleteScoreButton = (props) => {
  const {task /** , uploadStatusOk, filesUploadedByUser */} = props
  const [taskData /** , setTaskData */] = useState(task)
  const [scheduledTaskModalOpen, setScheduledTaskModalOpen] = useState(false)

  // const {open: openRegisterScores} = useRegisterScoresModal()
  const {open: openRegisterScores} = useHandlerExamForm(FORMODEL.task)
  const [loadingScoreModal, setLoadingScoreModal] = useState(false)

  const showTaskByType = (task) => {
    if (task.type === taskTypes.scoreCollection) {
      setLoadingScoreModal(true)
      openRegisterScores(() => {}, task.id).finally(() => {setLoadingScoreModal(false)})
    }
    if (task.type === taskTypes.scheduledMeetting) {
      setScheduledTaskModalOpen(!scheduledTaskModalOpen)
    }
  }

  return (
    <>
      <InfoTaskButton
        background='#F2F2FF'
        colorText='#696CFF'
        task={taskData}
        onClick={() => {
          showTaskByType(taskData)
        }}
        icon={
          loadingScoreModal ? 
            <IconLoad
              size={16}
              // style={{verticalAlign: 'inherit'}}
              // color='#696CFF'
            /> :
            <IconArrowRight
              size={16}
              style={{verticalAlign: 'inherit'}}
              color='#696CFF'
            />
        }
        textLabel={`${task?.buttonLabel}`}
      />

      <ShowScheduledMeeting
        isOpen={scheduledTaskModalOpen}
        toggle={() => {
            setScheduledTaskModalOpen(!scheduledTaskModalOpen)
          }
        }
        task={taskData}
      />
    </>
  )
}

export default CompleteScoreButton
