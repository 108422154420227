import React from 'react'
import {ConfirmModal, Activity} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const SelectContinueOnboard = (props) => {
  const {isOpen, toggle, confirmAction, cancelAction, loading} = props
  const {translate} = useTranslation()
  return (
    <ConfirmModal
      backdrop={[false, 'static']}
      isOpen={isOpen}
      toggle={toggle}
      title={translate('home.onboard.checkpoint.title')}
      body={translate('home.onboard.checkpoint.body')}
      confirmAction={!loading && confirmAction}
      cancelAction={!loading && cancelAction}
      confirmText={
        loading ? <Activity /> : translate('home.onboard.checkpoint.continue')
      }
      cancelText={
        loading ? (
          <Activity />
        ) : (
          translate('home.onboard.checkpoint.deleteLessons')
        )
      }
    />
  )
}

SelectContinueOnboard.propTypes = {}
export default SelectContinueOnboard
