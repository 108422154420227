import {useSelector, useDispatch} from 'react-redux'

const useCounselingOnboarding = () => {
  const counselingOnboarding = useSelector(
    (state) => state.modal.counselingOnboarding,
  )
  const dispatch = useDispatch()
  const setData = (data = {}) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'counselingOnboarding',
      data: {
        ...data,
      },
    })
  }
  return {
    counselingOnboarding,
    setData,
  }
}

export default useCounselingOnboarding
