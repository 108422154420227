import React, {useMemo} from 'react'
import {Task, Action} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import useShowTask from '../../../../Workspace/Student/ViewTask/useShowTask'
import phaseNameNormalizer from '../../../../Workspace/Utils/taskPhaseName'
import moment from 'moment'
import IsSpecific from '../../../../Workspace/Student/ViewTask/Components/IsSpecific'

const Tasks = (props) => {
  const {plan} = props
  const {translate} = useTranslation()
  const tasksCategories = useMemo(() => {
    if (plan?.tasks) {
      const allTasks = plan?.tasks.reduce((array, {steps, phaseName}) => {
        return array.concat(
          steps?.reduce((array2, {tasks, stepId}) => {
            return array2.concat(
              tasks.map((task) => {
                return {
                  ...task,
                  stepId,
                  phaseName: phaseNameNormalizer(phaseName),
                }
              }),
            )
          }, []),
        )
      }, [])
      return [
        /** unasignedTasks: **/
        // {
        //     tasks: allTasks.filter(({ status }) => status === 'unassigned'),
        //     title: 'Unassigned task'
        // },
        /** pendingTasks: **/
        {
          tasks: allTasks.filter(({status}) => ['pending'].includes(status)),
          title: translate('counseling.pendingTask'),
        },
        /** reviewTasks: **/
        {
          tasks: allTasks.filter(({status}) => ['review'].includes(status)),
          title: translate('counseling.reviewTask'),
        },
        /** completedTasks: **/
        {
          tasks: allTasks.filter(({status}) => ['completed'].includes(status)),
          title: translate('counseling.completedTask'),
        },
      ]
    }
    return []
    // eslint-disable-next-line
  }, [plan])

  const {open: openTask} = useShowTask()

  const showTask = (task) => {
    openTask(() => {}, task)
  }

  return (
    <div className='d-flex flex-column m-0 p-0'>
      {tasksCategories.map(({tasks, title}) => {
        return (
          <div className='my-3'>
            <label className='h4 font-weight-bold text-title my-3'>
              {title}
            </label>
            {tasks?.length > 0 ? (
              tasks?.map((task) => {
                // const {name} = task
                const {
                  taskName,
                  isEspecific,
                  dueDate,
                  status,
                  universities,
                } = task
                const isUnasigned = status === 'unassigned'
                const late = moment(dueDate) < moment()
                return (
                  <Task
                    taskClassName='mb-1 cursor-pointer'
                    leftClassName={`${!isUnasigned ? `w-70` : `w-100`}`}
                    labelLeft={
                      <span
                        title={taskName || 'No defined'}
                        className='text-truncate w-100'
                      >
                        {taskName}
                      </span>
                    }
                    className={`${!isUnasigned ? 'w-30' : ''} ${
                      late ? 'text-error' : ''
                    }`}
                    showLabelRight={!isUnasigned}
                    labelRight={
                      <div className='d-flex flex-row align-items-center m-0 p-0'>
                        {isEspecific && (
                          <IsSpecific
                            className='mr-2'
                            findInDB
                            universitiesList={universities}
                            showLabel={false}
                            showTooltip
                          />
                        )}
                        {moment(dueDate).format('MMM Do')}
                      </div>
                    }
                    titleLabelRight={null}
                    isCounseling
                    onClick={() => {
                      showTask(task)
                    }}
                  />
                )
              })
            ) : (
              <Action
                color='transparent'
                textColor='#7079FF'
                border='dashed 1px #7079FF'
                className='my-1'
                labelLeft={
                  <span className='w-100 d-flex flex-column my-3'>
                    <label className='m-0'>No task</label>
                  </span>
                }
                labelRight={null}
                action={null}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Tasks
