import React, {useState, useEffect, useMemo} from 'react'
import {
  Avatar,
  ListItem,
  ScheduledCalendar,
  IconArrowRight,
  IconCountry,
  IconDate,
  IconArrowLeft,
  CarouselFrame,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import ChangeTimezone from '../../../components/ChangeTimezone'
import LessonsIcons from '../../../components/LessonsIcons'
import useTranslation from '../../../../../i18n/useTranslation'
import {useSelector, useDispatch} from 'react-redux'
import {gql, useLazyQuery} from '@apollo/client'
import * as moment from 'moment-timezone'
import UpdateUser from '../../../../../Model/UpdateUser'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import {rescheduledOcurrenceLessonCalendar} from '../../../utils'

export const ScheduledConflictHeader = (props) => {
  const {translate} = useTranslation()
  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimesOcurrences, indexOcurrence} = reduxData
  const currentConflict = scheduledTimesOcurrences
    ? scheduledTimesOcurrences[indexOcurrence]
    : {}

  return (
    <div className='m-0 p-0'>
      <div className='d-flex my-2 overflow-hidden'>
        <CarouselFrame>
          <LessonsIcons
            data={
              scheduledTimesOcurrences
                ? scheduledTimesOcurrences.map((objLesson) => {
                    return {
                      scheduled: objLesson?.newTime !== null,
                      lesson: objLesson?.topic?.name,
                      idTopic: objLesson?.topic?.id.toString(),
                      onClick: () => {},
                    }
                  })
                : []
            }
            idTopic={currentConflict?.topic?.id}
            lesson={1}
            checked
            manual
            selected={false}
          />
        </CarouselFrame>
      </div>
      <CarouselFrame type='animation'>
        <ListItem
          showTopLine={false}
          className='pl-0 py-1'
          Left={
            <div
              className='d-flex align-items-center text-muted'
              title={translate(
                'home.onboard.approve.scheduled.schedulingYourLessonTime',
                {
                  timeLabel: moment(currentConflict?.isostring).format(
                    'MMMM Do YYYY, h:mm a',
                  ),
                },
              )}
            >
              <span className='ml-4' style={{whiteSpace: 'nowrap'}}>
                {translate(
                  'home.onboard.approve.scheduled.schedulingYourLessonTime',
                  {
                    timeLabel: moment(currentConflict?.isostring).format(
                      'MMMM Do YYYY, h:mm a',
                    ),
                  },
                )}
              </span>
            </div>
          }
        />
      </CarouselFrame>
    </div>
  )
}
export const ScheduledConflictBody = (props) => {
  const {isAdmin} = props
  const dispatch = useDispatch()

  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimesOcurrences, indexOcurrence, dataPlan} = reduxData
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )

  const currentConflict = scheduledTimesOcurrences
    ? scheduledTimesOcurrences[indexOcurrence]
    : {}

  const [fromDate, setFromDate] = useState(
    currentConflict && currentConflict?.isostring
      ? moment(currentConflict.isostring)
      : moment(),
  )
  useEffect(() => {
    if (currentConflict && currentConflict?.isostring)
      setFromDate(moment(currentConflict.isostring))
  }, [currentConflict])

  const [fromRange, toRange] = useMemo(() => {
    return [
      fromDate.clone().startOf('isoweek').toISOString(),
      fromDate.clone().endOf('isoweek').toISOString(),
    ]
  }, [fromDate])
  const zone = useSelector((state) => state.user.info.timezone)

  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO
  const {submit: changeTimezone, loading: loadZone} = UpdateUser(
    () => {
      // cambio de timezone exitoso
      // dispatch({
      //   type: SET_DATA_MODAL_FORM_LIST,
      //   payload: 'onboarding.data',
      //   data: {...reduxData, ...adjustConflict(scheduledTimesOcurrences)},
      // })
    },
    (error) => {
      console.error(error)
    },
  )
  const setZone = (time) => {
    // ejecutar mutación updateUser
    changeTimezone({
      input: {
        timezone: time,
      },
    })
  }
  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO - FIN

  const {translate, selectedLang} = useTranslation()
  const [myFreeTimeFramesQuery, {data, loading}] = useLazyQuery(
    gql`
      query(
        $educatorId: ID!
        $minDurationInMinutes: Int
        $from: DateTime!
        $to: DateTime!
        $involveMyself: Boolean!
        $studentIds: [ID!]
      ) {
        myFreeTimeFrames(
          educatorUserId: $educatorId
          minDurationInMinutes: $minDurationInMinutes
          from: $from
          to: $to
          involveMyself: $involveMyself
          studentIds: $studentIds
        )
      }
    `,
  )
  useEffect(() => {
    const [initialFrom, initialTo] = [
      fromDate.clone().startOf('isoweek').toISOString(),
      fromDate.clone().endOf('isoweek').toISOString(),
    ]
    const from =
      moment(initialFrom) < moment()
        ? moment() > moment(dataPlan?.startDate)
          ? moment().toISOString()
          : moment(dataPlan?.startDate).toISOString()
        : initialFrom
    const to =
      moment(initialTo) > moment(dataPlan?.endDate)
        ? moment(dataPlan?.endDate)
        : moment(initialTo) < moment()
        ? moment().toISOString()
        : moment(initialTo).toISOString()
    if (currentConflict?.educator?.id && dataPlan.studentUserId) {
      myFreeTimeFramesQuery({
        variables: {
          educatorId: currentConflict?.educator?.id,
          minDurationInMinutes: 50,
          from,
          to,
          involveMyself: !isAdmin,
          studentIds: isAdmin ? [dataPlan.studentUserId] : [],
        },
      })
    }
    // eslint-disable-next-line
  }, [fromDate, currentConflict?.educator?.id, isAdmin, dataPlan])

  const previusWeek = () => {
    setFromDate(moment(fromDate).tz(zone).subtract(7, 'd'))
  }
  const nextWeek = () => {
    setFromDate(moment(fromDate).tz(zone).add(7, 'd'))
  }
  const resetFromDate = () => {
    setFromDate(
      currentConflict?.isostring
        ? moment(currentConflict?.isostring).tz(zone)
        : moment().tz(zone),
    )
  }
  const startOfWeek = moment(fromDate).tz(zone).clone().startOf('isoWeek')
  const endOfWeek = moment(fromDate).tz(zone).clone().endOf('isoWeek')

  const setTimeOnConflict = (
    scheduledTimesOcurrences,
    newTimeSelected,
    indexO,
  ) => {
    scheduledTimesOcurrences[indexO].newTime = newTimeSelected
    /** scheduledTimesOcurrences = scheduledTimesOcurrences.map((ocu, index) => {
      if((ocu.newTime === null || index === scheduledTimesOcurrences.length - 1) && !found){
        found = true
        return {...ocu, newTime: newTimeSelected}
      }
      return ocu
    })**/
    const nextIndexOcurrence = scheduledTimesOcurrences.reduce(
      (nextIndex, ocu, index) => {
        if (nextIndex === -1 && ocu.newTime === null) return index
        return nextIndex
      },
      -1,
    )
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {
        ...reduxData,
        scheduledTimesOcurrences,
        indexOcurrence:
          nextIndexOcurrence === -1 // no quedan ocurrencias por agendar
            ? indexO // mantiene el mismo indice
            : nextIndexOcurrence, // pasa a la siguiente ocurrencia con newTime nulo
      },
    })
  }

  const checkedBlocks = useMemo(() => {
    return scheduledTimesOcurrences
      ? scheduledTimesOcurrences.reduce((array, ocu, indexO) => {
          if (
            ocu.newTime !== null &&
            startOfWeek <= moment(ocu.newTime.time).tz(zone) &&
            moment(ocu.newTime.time).tz(zone) <= endOfWeek
          ) {
            return array.concat([
              {
                time: moment(ocu.newTime.time).tz(zone),
                current: true,
                indexLesson: indexO,
              },
            ])
          }
          return array
        }, [])
      : []
    // eslint-disable-next-line
  }, [JSON.stringify(scheduledTimesOcurrences), startOfWeek, endOfWeek, zone])

  // volver a seleccionar bloque del tópico actual
  const rescheduledLesson = (indexL) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {
        ...reduxData,
        ...rescheduledOcurrenceLessonCalendar(scheduledTimesOcurrences, indexL),
      },
    })
  }

  return (
    <div className='m-0 p-0'>
      <ListItem
        showBottomLine={false}
        showTopLine
        Left={
          <div className='d-flex align-items-center text-muted'>
            <Avatar
              name={currentConflict?.educator?.name}
              named={false}
              uri={null}
              size='avatar-medium'
            />
            <span className='ml-2'>{`${
              currentConflict?.educator?.name
            } ${`(${translate(
              'home.onboard.approve.scheduled.recommended',
            )})`}`}</span>
          </div>
        }
      />
      <ChangeTimezone setTime={setZone} time={zone}>
        <ListItem
          showBottomLine
          showTopLine
          Left={
            <div className='d-flex align-items-center text-muted'>
              <IconCountry className='mx-3' size={16} />
              <span className='ml-2'>{zone}</span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      </ChangeTimezone>
      <ListItem
        showBottomLine
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconDate className='mx-3' size={16} />
            <span
              className='ml-2 text-muted'
              style={{
                whiteSpace: 'nowrap',
                width: '175px',
                minWidth: '175px',
                maxWidth: '175px',
              }}
            >
              {`${startOfWeek.format('DD MMM YY').toUpperCase()}
              - ${endOfWeek.format('DD MMM YY').toUpperCase()}`}
            </span>
          </div>
        }
        Right={
          <div
            className='m-0 p-0 d-flex flex-row align-items-center'
            style={{whiteSpace: 'nowrap'}}
          >
            <div className='m-0 p-0'>
              <span
                onClick={() => {
                  if (
                    fromDate?.clone()?.toISOString() !==
                    currentConflict?.isostring
                  )
                    resetFromDate()
                }}
                className={`ml-1 ${
                  fromDate?.clone()?.toISOString() !==
                  currentConflict?.isostring
                    ? 'btn-link'
                    : 'text-white'
                }`}
              >
                Reset
              </span>
            </div>
            <div className='d-flex flex-row'>
              <IconArrowLeft
                className={`mr-3 ${
                  moment(fromRange) < moment(dataPlan?.startDate)
                    ? 'text-white'
                    : ''
                }`}
                onClick={() => {
                  if (!(moment(fromRange) < moment(dataPlan?.startDate)))
                    previusWeek()
                }}
                style={
                  !(moment(fromRange) < moment(dataPlan?.startDate))
                    ? {cursor: 'pointer'}
                    : {}
                }
              />
              <IconArrowRight
                className={`${
                  moment(dataPlan?.endDate) < moment(toRange)
                    ? 'text-white'
                    : ''
                }`}
                onClick={() => {
                  if (!(moment(dataPlan?.endDate) < moment(toRange))) nextWeek()
                }}
                style={
                  !(moment(dataPlan?.endDate) < moment(toRange))
                    ? {cursor: 'pointer'}
                    : {}
                }
              />
            </div>
          </div>
        }
      />
      <ScheduledCalendar
        loading={loading || loadZone}
        setSelected={(newTime) => {
          setTimeOnConflict(scheduledTimesOcurrences, newTime, indexOcurrence)
        }}
        selectedDay={{}}
        educator={currentConflict?.educator}
        checkedDays={checkedBlocks}
        myFreeTimeFrames={data?.myFreeTimeFrames}
        manual
        lang={selectedLang}
        from={moment(fromRange).clone()}
        fromPart={fromRange}
        toPart={toRange}
        zone={zone}
        className='px-0 pt-6 pb-0'
        getIndexOfLesson={(indexL) => {
          rescheduledLesson(indexL)
        }}
      />
      <StickyButton
        className='mt-3'
        condition={
          scheduledTimesOcurrences &&
          scheduledTimesOcurrences.filter((ocu) => ocu.newTime === null)
            .length === 0
        }
        color='#696CFF'
        onClickButtonFunction={() => {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.conflictModal',
            data: {...conflictModal, page: 'schedulingConflict'},
          })
        }}
      />
    </div>
  )
}

export default {ScheduledConflictHeader, ScheduledConflictBody}
