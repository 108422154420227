import React from 'react'
import {IconBank, ListItem} from '@Knowledge-OTP/znk-ui-components'
import iconZelle from '../../../../../../assets/zelle-icon.png'
import useTranslation from '../../../../../../i18n/useTranslation'

const ZelleMethod = (props) => {
  const {isClickable} = props
  const {translate} = useTranslation()
  return (
    <>
      <ListItem
        className='px-4'
        showTopLine
        showBottomLine
        hasColorHover={isClickable}
        hasHover={isClickable}
        Left={
          <span className='d-flex align-items-center text-gray'>
            <IconBank className='mr-2' />
            {`${translate(
              'profile.actions.addManualPaymentMethod.manualPaymentMethod',
            )}`}
          </span>
        }
        Right={
          <span className='d-flex align-items-center text-gray'>
            <img src={iconZelle} alt='zelle' width={25} className='mr-2' />
            Zelle
          </span>
        }
      />
    </>
  )
}

export default ZelleMethod
