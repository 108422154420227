import React, {Fragment, useState, useMemo} from 'react'
import {
  IconCircleDolar,
  IconClose,
  IconRight,
  ListItem,
  Modal,
  useWindowDimensions,
  IconLink,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {ModalHeader} from 'reactstrap'
import formatNumber from '../../../../../helpers/formatNumber'
import SendTeachTopics from '../sendTeachTopic'
import {showAlert} from '../../../../../common/Alert/util'
import ChangeEducatorInfo from '../ChangeEducatorInfo'
import DisabledForStudent from './DisabledForStudent'
import {useDispatch} from 'react-redux'
import useUser from './Model/useUser'
import EducatorCalendarLink from '../../FullPersonalInfo/PersonalBlock/Components/EducatorCalendarLink'

const EmploymentDetails = (props) => {
  const {id, roles} = props
  const {data, refetch} = useUser({id})
  const {
    disableForStudents,
    bankNumber,
    bankRouting,
    backupLink,
    wFileUpload,
    ndaFileUpload,
    handbook,
    services,
    weeklyCapHours,
    canTeach,
  } = useMemo(() => {
    if (data?.user) return data?.user?.educatorInfo || {}
    return {}
  }, [data])


  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const [isOpenSend, setIsOpenSend] = useState(false)
  const [isOpenInfo, setIsOpenInfo] = useState(false)
  const {breakWidth} = useWindowDimensions()
  const toggle = () => setOpen((open) => !open)
  const changeToPassView = (value) => {
    if (value) {
      const len = String(value).length
      return ''.padStart(len - 4, '*') + String(value).substr(len - 4, len)
    }
    return ''
  }
  const sendOpen = () => {
    setIsOpenSend(true)
  }
  const copyText = (text) => {
    navigator.clipboard.writeText(text)
    showAlert(
      {
        text: translate('misc.copy'),
        status: 'success', // error, warning
      },
      dispatch,
    )
  }
  const openInfo = () => {
    setIsOpenInfo(true)
  }
  return (
    <Fragment>
      <ListItem
        showBottomLine
        hasHover
        hasColorHover
        onClick={toggle}
        Left={
          <span className='d-flex align-items-center'>
            <IconCircleDolar size={16} color='gray' className='mr-3' />
            <span className='text-black'>
              {translate('profile.actions.editemployment')}
            </span>
          </span>
        }
      />
      <Modal
        buttonLabel={null}
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
          >
            <span className='h2 font-weight-bold'>
              {translate('profile.actions.editemployment')}
            </span>
          </ModalHeader>
        }
      >
        <span className='d-flex text-dark justify-content-between mb-1 mt-6'>
          <span className='font-weight-bold'>Payment</span>
        </span>
        <ListItem
          className='px-0'
          showTopLine
          showBottomLine
          Left={
            <span>{translate('profile.actions.employment.banknumber')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {bankNumber ? `${changeToPassView(bankNumber)}` : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.employment.bankrouting')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {bankRouting ? `${changeToPassView(bankRouting)}` : '-'}
            </span>
          }
        />

        <span className='d-flex text-dark justify-content-between mb-1 mt-6'>
          <span className='font-weight-bold'>
            {translate('profile.actions.employment.services')}
          </span>
          <button className='btn btn-link p-0' onClick={sendOpen}>
            {translate('profile.actions.employment.editservices')}
          </button>
        </span>
        <ListItem
          className='px-0'
          showTopLine
          showBottomLine
          Left={<span>{translate('profile.actions.employment.maxhours')}</span>}
          Right={
            <div
              className='d-flex fle-row justify-content-between align-items-center'
              onClick={openInfo}
            >
              <span className='text-secondary font-weight-light mr-4'>
                {weeklyCapHours ? `${weeklyCapHours}` : '-'}
              </span>
              <IconRight />
            </div>
          }
        />
        <DisabledForStudent
          disableForStudents={disableForStudents}
          educatorId={id}
          refetch={() => {
            if (refetch) refetch()
          }}
        />
        <div className='borderTop mt-3' />

        {(services || [])?.map((item, indexA) => {
          return item.topics
            ? item.topics.map((element, index) => {
                return element.teachingTypes &&
                  element.teachingTypes.length > 0 ? (
                  <ListItem
                    className='px-0'
                    showBottomLine
                    Left={
                      <span>
                        {item.name} - {element.name}
                      </span>
                    }
                    Right={
                      <span className='text-secondary font-weight-light'>
                        {!element.teachingTypes.includes('individual')
                          ? '-'
                          : element.isWaiveIndividual
                          ? 'Waive'
                          : 'USD $' + formatNumber(element.individualRate)}{' '}
                        /{' '}
                        {!element.teachingTypes.includes('group')
                          ? '-'
                          : element.isWaiveGroup
                          ? 'Waive'
                          : 'USD $' + formatNumber(element.groupRate)}
                      </span>
                    }
                  />
                ) : null
              })
            : null
        })}

        <span className='d-flex text-dark justify-content-between mb-1 mt-6'>
          <span className='font-weight-bold'>
            {translate('profile.actions.employment.general')}
          </span>
        </span>
        {/* <ListItem
          className='px-0'
          showTopLine
          showBottomLine
          classNameRight='w-60'
          Left={<span>{translate('profile.actions.employment.google')}</span>}
          Right={
            <div className='d-flex flex-row'>
              <span className='text-secondary font-weight-light text-truncate'>
                {googleCalendar ? `${googleCalendar} ...` : '-'}
              </span>
              <div>
                {googleCalendar ? (
                  <div className='d-flex flex-row'>
                    <IconDuplicate
                      onClick={() => {
                        copyText(googleCalendar)
                      }}
                      className='mr-3'
                    />
                    <IconInfo id='TooltipExample' />
                    <Tooltip
                      placement='right'
                      isOpen={tooltipOpen}
                      target='TooltipExample'
                      toggle={toggles}
                    >
                      {translate('misc.gogolecalendar')}
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          }
        /> */}
        {
          (roles || []).includes("EDUCATOR") && (
            <EducatorCalendarLink classNameText='text-secondary font-weight-light' user={props} refetch={() => {}} />
          )
        }
        <div className='borderTop'></div>
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.employment.backupLink')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {backupLink ? `${backupLink}` : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.employment.wfileupload')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {wFileUpload ? `${wFileUpload}` : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.employment.ndafileupload')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {ndaFileUpload ? `${ndaFileUpload}` : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={<span>{translate('profile.actions.employment.handbook')}</span>}
          Right={
            <div className='d-flex align-items-center'>
              <span className='text-secondary font-weight-light'>
                {handbook ? `${handbook}` : '-'}
              </span>
              <div className='hover-icon'>
                <IconLink
                  size={16}
                  color='gray'
                  onClick={() => {
                    copyText(handbook)
                  }}
                />
              </div>
            </div>
          }
        />
      </Modal>
      <SendTeachTopics
        isOpen={isOpenSend}
        setIsOpen={setIsOpenSend}
        canTeach={canTeach}
      />
      <ChangeEducatorInfo
        isOpen={isOpenInfo}
        setIsOpen={setIsOpenInfo}
        weeklyCapHours={weeklyCapHours}
      />
    </Fragment>
  )
}

export default EmploymentDetails
