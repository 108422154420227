import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import {FormGroup, Label} from 'reactstrap'

const DuolingoComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  } = propsToSend
  const scale = '10-160'
  const scaleMin = parseInt(scale.split('-')[0])
  const scaleMax = parseInt(scale.split('-')[1])
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const scores = reduxData?.scores
  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scores?.literacy >= scaleMin &&
      scores?.literacy <= scaleMax &&
      scores?.conversation >= scaleMin &&
      scores?.conversation <= scaleMax &&
      scores?.comprehension >= scaleMin &&
      scores?.comprehension <= scaleMax &&
      scores?.production >= scaleMin &&
      scores?.production <= scaleMax &&
      scores?.overall >= scaleMin &&
      scores?.overall <= scaleMax
      // && !!scores?.examDate
    )
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.overall,
      results: [
        {
          name: 'Literacy',
          scale: scale,
          values: [
            {
              name: 'Literacy',
              scale: scale,
              value: scores?.literacy,
            },
          ],
        },
        {
          name: 'Conversation',
          scale: scale,
          values: [
            {
              name: 'Conversation',
              scale: scale,
              value: scores?.conversation,
            },
          ],
        },
        {
          name: 'Comprehension',
          scale: scale,
          values: [
            {
              name: 'Comprehension',
              scale: scale,
              value: scores?.comprehension,
            },
          ],
        },
        {
          name: 'Production',
          scale: scale,
          values: [
            {
              name: 'Production',
              scale: scale,
              value: scores?.production,
            },
          ],
        },
        {
          name: 'Overall',
          scale: scale,
          values: [
            {
              name: 'Overall',
              scale: scale,
              value: scores?.overall,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  return (
    <div className={`${scoresComponentClass}`}>
      <Form Form value={scores}>
        <FormGroup className='mt-0 mb-1'>
          <Label style={{color: sectionTitleColor}}>Duolingo</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='literacy'
                className='m-1'
                label='Literacy'
                value={scores?.literacy}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    literacy: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='conversation'
                className='m-1'
                label='Conversation'
                value={scores?.conversation}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    conversation: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='comprehension'
                className='m-1'
                label='Comprehension'
                value={scores?.comprehension}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    comprehension: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='production'
                className='m-1'
                label='Production'
                value={scores?.production}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    production: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className='mt-7 mb-0'>
          <Label>Overall</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='overall'
                className='m-1'
                label='Overall'
                value={scores?.overall}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    overall: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default DuolingoComponent
