import {useMutation, gql} from '@apollo/client'

const useScheduledCounselorFromStudent = (
  onCompleted = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation(
        $isCounseling: Boolean = false
        $isAdmin: Boolean = false
        $isMeeting: Boolean = false
        $councelorInput: CouncelornputForStudent!
      ) {
        scheduleCounselorFromStudent(
          isCounseling: $isCounseling
          isAdmin: $isAdmin
          isMeeting: $isMeeting
          councelorInput: $councelorInput
        ) {
          id
          kind
        }
      }
    `,
    {
      onCompleted,
      onError,
    },
  )
}

export default useScheduledCounselorFromStudent
