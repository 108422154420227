import React, {useEffect, useState} from 'react'
import {
  Avatar,
  Button,
  IconSearch,
  Input,
  ListOption,
  LoadingSpinner,
} from '@Knowledge-OTP/znk-ui-components'
import isEmpty from 'lodash/isEmpty'
import useTranslation from '../../../../../../i18n/useTranslation'
import useModalState from '../../../../../../hooks/useModalState'
import {gql, useLazyQuery} from '@apollo/client'
import escapeRegExp from 'lodash/escapeRegExp'

const EducatorSelector = (props) => {
  const {modalData, onChange} = useModalState('program.newManualLesson')
  const {topicId, serviceId, stage, educator} = modalData
  const {translate} = useTranslation()
  const [searchEducators, {data, loading}] = useLazyQuery(
    gql`
      query($topicId: ID!, $serviceId: ID!) {
        allEducatorByTopic(topicId: $topicId, serviceId: $serviceId) {
          id
          avatar
          firstName
          lastName
          educatorInfo {
            disableForStudents
          }
        }
      }
    `,
  )
  useEffect(() => {
    if (stage === 1 && topicId && serviceId) {
      searchEducators({
        variables: {
          serviceId,
          topicId,
        },
      })
    }
  }, [topicId, serviceId, stage, searchEducators])
  const educators = isEmpty(data) ? [] : data.allEducatorByTopic
  const [search, setSearch] = useState('')
  const searchRegex = new RegExp(escapeRegExp(search), 'i')
  return (
    <div className='p-0 m-0'>
      <Input
        type='addon'
        className='mt-0 mb-2 px-0'
        placeholder='Search'
        addOnComponent={<IconSearch className='text-gray' />}
        value={search}
        onChange={({target: {value}}) => setSearch(value)}
      />
      <div className='d-flex flex-column'>
        {loading ? (
          <div className='w-100 d-flex flex-row align-items-center justify-content-center my-2'>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {educators
              .filter((item) => !item.educatorInfo.disableForStudents)
              .filter((item) =>
                search !== ''
                  ? searchRegex.test(item.firstName) ||
                    searchRegex.test(item.lastName)
                  : true,
              )
              .map((item) => (
                <ListOption
                  key={item.id}
                  className='mb-2 px-3'
                  onClick={() => onChange((s) => ({...s, educator: item}))}
                  label={null}
                  selectedInput={educator && educator.id === item.id}
                  childClassName='w-100'
                  icon={
                    <div className='d-flex align-items-center text-muted'>
                      <Avatar
                        name={`${item.firstName} ${item.lastName}`}
                        named={false}
                        uri={item.avatar}
                        size='avatar-small avatar-span-white'
                      />
                      <span className='ml-2'>
                        {item.firstName} {item.lastName}
                      </span>
                    </div>
                  }
                />
              ))}
            {educators.length === 0 && (
              <div className='d-flex align-items-center justify-content-center text-body'>
                {translate('misc.notFound')}
              </div>
            )}
          </>
        )}
      </div>
      <Button
        color='primary'
        className='w-100 mt-4'
        check
        disabled={!educator}
        onClick={() => onChange((s) => ({...s, stage: 2}))}
        label={translate('modal.newlesson.continue')}
      />
    </div>
  )
}

export default EducatorSelector
