import React from 'react'
import {
  IconArrowDown,
  IconCountry,
  ListItem,
  RadioSelector,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../../../../common/Alert/util'

const DesktopView = (props) => {
  const {translate} = useTranslation()
  const {timezone, updInfo, id, from, noText = false, noArrow = false} = props
  const dispatch = useDispatch()
  const submit = (timezone) => {
    try {
      updInfo({variables: {input: {timezone}, id: id}}).then(
        () => {
          showAlert(
            {
              text: translate('profile.actions.changeTimezone.successAlert'),
              status: 'success',
            },
            dispatch,
          )
        },
        () => {
          showAlert(
            {
              text: translate('profile.actions.changeTimezone.errorAlert'),
              status: 'error',
            },
            dispatch,
          )
        },
      )
    } catch (e) {
      // eslint-disable-next-line
      console.error({e})
      showAlert(
        {
          text: translate('profile.actions.changeTimezone.errorAlert'),
          status: 'error',
        },
        dispatch,
      )
    }
  }
  const timezones = moment.tz.names() || []
  return (
    <RadioSelector
      direction='down'
      setSelected={submit}
      selected={{id: timezone, name: timezone}}
      data={timezones
        .map((item) => ({name: item, id: item}))
        .sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='timezoneSelector'
      strings={{
        title: translate('profile.actions.editTimezone'),
        buttonCancel: translate('misc.cancelStartCase'),
        buttonApply: translate('misc.submit'),
        search: translate('misc.search'),
        changingTo: translate('misc.changingTo'),
      }}
    >
      {from !== 'calendar' ? (
        <ListItem
          showBottomLine
          hasHover
          hasColorHover
          Left={
            <span className='d-flex align-items-center'>
              <IconCountry size={16} className='mr-3' />
              <span className='text-black'>
                {translate('profile.actions.editTimezone')}
              </span>
            </span>
          }
        />
      ) : (
        <span className='d-flex align-items-center'>
          {!noText && (
            <span className='mr-2 font-weight-light text-body small'>
              {timezone}
            </span>
          )}
          <IconCountry color='#72809D' size={20} />
          {!noArrow && (
            <IconArrowDown color='#72809D' size={12} className='ml-1' />
          )}
        </span>
      )}
    </RadioSelector>
  )
}

export default DesktopView
