import React, {useMemo, useState} from 'react'
import useModalState from '../../../../../../hooks/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'
import {
  Avatar,
  Button,
  CheckboxOption,
  IconProgram,
  IconTopic,
  ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import {gql, useMutation} from '@apollo/client'
import {useSelector, useDispatch} from 'react-redux'
import {showAlert} from '../../../../../../common/Alert/util'
const ConfirmLesson = (props) => {
  const {clearAndExit} = props
  const dispatch = useDispatch()
  const {modalData, onChange} = useModalState('program.newManualLesson')
  const {studentInfo} = useSelector((state) => state.user.info)
  const services = useSelector((state) => state.services.services)
  const {translate} = useTranslation()
  const {educator, dateTime, serviceId, topicId} = modalData
  const [acceptRate, setAcceptRate] = useState(false)
  const [submit, {loading: mutationLoading, error}] = useMutation(
    gql`
      mutation($input: IndividualLessonInputForStudent!) {
        scheduleNewLessonFromStudent(lessonInput: $input) {
          id
        }
      }
    `,
    {
      update: () => {
        clearAndExit()
        // client.resetStore()
      },
    },
  )
  const studentRate = studentInfo?.servicesRate?.find(
    (s) => s.serviceId === serviceId,
  ) ?? {defaultRate: studentInfo?.defaultRate ?? 75}
  const thisService = serviceId ? services[serviceId] : {}
  const thisTopic =
    serviceId && topicId ? services[serviceId].topics[topicId] : {}
  const startTime = useMemo(() => {
    return moment(dateTime)
  }, [dateTime])
  return (
    <>
      <ListItem
        className='hover-no-border px-1'
        showTopLine
        showBottomLine
        hasHover
        onClick={() => onChange((s) => ({...s, stage: 2}))}
        Left={
          <div
            className='d-flex align-items-center text-muted'
            style={{maxWidth: '90%'}}
          >
            <IconProgram fill='logo' className='znk-icon-service' />
            {startTime && (
              <span className='ml-2 text-truncate'>{`${startTime.format(
                'h:mma',
              )} to ${startTime
                .clone()
                .add(50, 'm')
                .format('h:mma')} | ${startTime.format('MMM D, YYYY')}`}</span>
            )}
            {!startTime && (
              <span className='ml-2'>{translate('misc.notFound')}</span>
            )}
          </div>
        }
      />
      <ListItem
        className='hover-no-border px-1'
        showBottomLine
        hasHover
        onClick={() => onChange((s) => ({...s, stage: 0}))}
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconTopic fill='logo' className='znk-icon-service' />
            {topicId ? (
              <span className='ml-2'>
                {thisService.name}&nbsp;{thisTopic.name}
              </span>
            ) : (
              <span className='ml-2'>{translate('misc.notFound')}</span>
            )}
          </div>
        }
      />
      <ListItem
        className='hover-no-border px-1'
        showBottomLine
        hasHover
        onClick={() => onChange((s) => ({...s, stage: 1}))}
        Left={
          <div className='d-flex align-items-center text-muted'>
            {educator ? (
              <>
                <Avatar
                  name={`${educator.firstName} ${educator.lastName}`}
                  named={false}
                  uri={educator.avatar}
                  size='avatar-medium'
                />
                <span className='ml-2'>
                  {educator.firstName} {educator.lastName}
                </span>
              </>
            ) : (
              <span className='text-secondary'>
                {translate('lessons.selectAnEducator')}
              </span>
            )}
          </div>
        }
      />
      <span className='pl-0 mt-4 d-flex'>
        <CheckboxOption onChange={(val) => setAcceptRate(val)} />
        <span className='ml-2 text-body'>
          {translate('lessons.errorscheduled')}
          <span className='font-weight-bold'>${studentRate.defaultRate}</span>
        </span>
      </span>
      {error ? (
        <span className='pl-0 mt-4 d-flex'>
          <span className='ml-2 text-error'>
            {translate('modal.newlesson.errorScheduling')}
          </span>
        </span>
      ) : null}
      <div className='pl-0 py-2 pr-2'>
        <Button
          label={translate('lessons.scheduledlesson')}
          check
          color='primary'
          className='w-100'
          disabled={!acceptRate || !topicId || !educator || !startTime}
          activity={mutationLoading}
          onClick={() => {
            submit({
              variables: {
                input: {
                  topicId,
                  serviceId,
                  title: `${thisService.name} ${thisTopic.name}`,
                  educatorUserId: educator.id,
                  startDate: startTime.toISOString(),
                },
              },
            }).then(
              () => {
                showAlert(
                  {
                    text: translate('modal.newlesson.successAlert'),
                    status: 'success',
                  },
                  dispatch,
                )
              },
              () => {
                showAlert(
                  {
                    text: translate('modal.newlesson.errorAlert'),
                    status: 'error',
                  },
                  dispatch,
                )
              },
            )
          }}
        />
      </div>
    </>
  )
}

export default ConfirmLesson
