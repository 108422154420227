import React, {useMemo} from 'react'
import { ToolTipInfo } from '@Knowledge-OTP/znk-ui-components/dist/index'

const CountryFlag = (props) => {
    const {country, tooltipDisabled = false, size = 25, className = '', ...otherProps} = props
    const CountryComponent = useMemo(
        () => {
            const code = country.code
            const Components = require("@Knowledge-OTP/znk-ui-components/dist/index")
            return Components[`Icon${code}`] || Components[`IconDefaultCountry`]
        }, [country]
    )

    return (
        <div className={`d-flex flex-row justify-content-center align-items-center ${className}`} {...otherProps}>
            <ToolTipInfo className={'m-0 p-0'} disable={tooltipDisabled} place='left' tooltipId={`tooltip-country-${country?.code}`} innerElement={country?.name || 'No defined'}>
                <CountryComponent size={size}/>
            </ToolTipInfo>
        </div>
    )
}

export default CountryFlag