import React from 'react'
import useTranslation from '../../../../../../i18n/useTranslation'
import earlyActionYellowIcon from '../../../../../../assets/earlyActionYellowIcon.svg'
const EarlyActionStatus = (props) => {
  const {translate} = useTranslation()

  return (
    <div className='mb-2 w-100'>
      <div
        className='row d-flex w-100 flex-row justify-content-start py-3'
        style={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: '#f0f2f5',
          whiteSpace: 'nowrap',
          marginLeft: '0.1rem',
        }}
      >
        <div className='col-1 mr-3 align-self-center'>
          {' '}
          <img
            src={earlyActionYellowIcon}
            alt='Early Action Icon'
            color='#ffd322'
            width='26'
            height='26'
          />
        </div>
        <div className='col-10 align-self-center'>
          <span className='d-flex justify-content-start align-middle h4 text-muted'>
            {translate('counseling.earlyActionTitle')}
          </span>
        </div>
      </div>
    </div>
  )
}
export default EarlyActionStatus
