import React, {useState, useEffect, useMemo} from 'react'
import {
  Modal,
  IconClose,
  StickyButton,
  CheckboxOption,
  Avatar,
  ListItem,
  IconIllustratedAlert,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SendAlert from '../../../../Model/Lessons/SendAlert'
import {gql, useApolloClient} from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

const WspAlert = () => {
  const dispatch = useDispatch()
  const {isOpen, dataLesson, studentsId, educatorId} = useSelector(
    (state) => state.modal.calendar.wspAlert,
  )
  const {translate} = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.wspAlert',
      data: {
        isOpen: !isOpen,
        dataLesson,
        studentsId: [],
        educatorId: null,
      },
    })
  }
  const {submit: submitAlert, loading: loadAlert} = SendAlert(
    () => {
      toggle()
      // reinicia el formulario
      setForm({})
    },
    (e) => {
      alert('error')
      console.error(e)
    },
  )

  const client = useApolloClient()
  const getStudentParents = async (students) => {
    let query = students.reduce((stringQuery, student, index) => {
      return (
        stringQuery +
        `parents${index}: user(id: "${student.studentUserId}") {
        id
        studentInfo {
          parents {
            parentUserId
            managePayments
            manageApprovals
            firstName
            lastName
            email
            phone
          }
        }
      }\n`
      )
    }, '')
    query = 'query {\n' + query + '}'
    try {
      const usersArray = await client.query({
        query: gql`
          ${query}
        `,
      })
      return Object.values(usersArray.data)
    } catch (error) {
      console.error(error)
      return []
    }
  }

  const [form, setForm] = useState({})

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    let stu =
      dataLesson?.student ||
      dataLesson?.studentPlan ||
      dataLesson?.studentGroup ||
      []
    if (!Array.isArray(stu)) stu = [stu]
    const edu =
      dataLesson.substitute !== null
        ? dataLesson?.substitute || {}
        : dataLesson?.educator || {}
    if (stu.length > 0) {
      getStudentParents(stu).then(
        (array) => {
          setForm({
            student: stu.map((studentVar) => {
              let parentOfStudent = array.filter(
                (stuData) => stuData.id === studentVar.studentUserId,
              )
              if (parentOfStudent.length > 0)
                parentOfStudent = parentOfStudent[0].studentInfo.parents
              return {
                id: studentVar.studentUserId,
                name: `${studentVar.firstName} ${studentVar.lastName}`,
                check: studentsId.includes(studentVar.studentUserId),
                parent: parentOfStudent.map((parent) => {
                  return {
                    id: parent.parentUserId,
                    name: `${parent?.firstName} ${parent?.lastName}`,
                    check: false,
                  }
                }),
              }
            }),
            educator: {
              id: edu?.educatorUserId,
              name: `${edu?.firstName} ${edu?.lastName}`,
              check: educatorId === edu?.educatorUserId,
            },
          })
          setLoading(false)
        },
        (error) => {
          console.error(error)
          setForm({
            student: stu.map((studentVar) => {
              return {
                id: studentVar.studentUserId,
                name: `${studentVar.firstName} ${studentVar.lastName}`,
                check: studentsId.includes(studentVar.studentUserId),
                parent: [],
              }
            }),
            educator: {
              id: edu?.educatorUserId,
              name: `${edu?.firstName} ${edu?.lastName}`,
              check: educatorId === edu?.educatorUserId,
            },
          })
          setLoading(false)
        },
      )
    }
    // eslint-disable-next-line
  }, [dataLesson, isOpen])

  const validation = useMemo(() => {
    let val = false
    if (form?.student && form?.educator) {
      val = form?.student.reduce((validAccu, stu) => {
        const parentCheck = stu.parent.reduce((validAccuParent, par) => {
          return validAccuParent || par.check
        }, false)
        return stu.check || parentCheck || validAccu
      }, false)
      val = val || form?.educator?.check
    }
    return val
  }, [form])

  const parseFormToMutation = (data) => {
    const mutationObj = {
      // arreglo puede estar vacio
      students: data.student.reduce((arrayStudent, stu) => {
        if (
          stu.check ||
          stu.parent.reduce((accuParent, par) => {
            return accuParent || par.check
          }, false)
        ) {
          arrayStudent.push({
            studentId: stu.id,
            sendToStudent: stu.check,
            parentIds: stu.parent.reduce((accuParent, parent) => {
              if (parent.check) accuParent.push(parent.id)
              return accuParent
            }, []),
          })
        }
        return arrayStudent
      }, []),
    }
    if (data.educator.check) {
      // si está seleccionado, se incluye el id del educador
      mutationObj.educatorId = data.educator.id
      if (data.student.length === 1) {
        // si solo hay un estudiante involucrado, se incluye el nombre
        mutationObj.studentName = data.student[0].name
      }
    }
    return mutationObj
  }

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-3 d-flex flex-row justify-content-between'>
          <div className='d-flex flex-column'>
            <IconIllustratedAlert className='mb-3' size={80} />
            <span className='h2 font-weight-bold'>
              {translate('modal.sendalert.title')}
            </span>
          </div>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <span className='text-gray'>{translate('modal.sendalert.text')}</span>
        <div className='d-flex flex-column py-4'>
          {loading ? (
            <Skeleton count={3} height={40} />
          ) : (
            <>
              {form?.student &&
                form.student.map((stu, stuIndex) => {
                  return (
                    <>
                      <ListItem
                        className='py-2'
                        Left={
                          <div className='d-flex flex-row align-items-baseline'>
                            <Avatar name={stu?.name} />
                            <span className='ml-2'>{stu?.name}</span>
                            <span className='text-gray h5 ml-1'>{`(${translate(
                              'modal.infolesson.student',
                            )})`}</span>
                          </div>
                        }
                        Right={
                          <CheckboxOption
                            defaultState={form?.student[stuIndex]?.check}
                            onChange={(val) => {
                              setForm({
                                ...form,
                                student: form.student.map(
                                  (stuAux, indexAux) => {
                                    if (stuIndex === indexAux)
                                      return {...stuAux, check: val}
                                    return stuAux
                                  },
                                ),
                              })
                            }}
                          />
                        }
                      />
                      {stu.parent.map((par, parIndex) => {
                        return (
                          <ListItem
                            className='py-2 pl-2'
                            Left={
                              <div className='d-flex flex-row align-items-baseline'>
                                <Avatar name={par?.name} />
                                <span className='ml-2'>{par?.name}</span>
                                <span className='text-gray h5 ml-1'>{`(${translate(
                                  'modal.infolesson.parent',
                                )})`}</span>
                              </div>
                            }
                            Right={
                              <CheckboxOption
                                defaultState={
                                  form?.student[stuIndex]?.parent[parIndex]
                                    ?.check
                                }
                                onChange={(val) => {
                                  setForm({
                                    ...form,
                                    student: form.student.map(
                                      (stuAux, indexAux) => {
                                        if (stuIndex === indexAux) {
                                          return {
                                            ...stuAux,
                                            parent: stuAux.parent.map(
                                              (parAux, indexAuxPar) => {
                                                if (indexAuxPar === parIndex)
                                                  return {...parAux, check: val}
                                                return parAux
                                              },
                                            ),
                                          }
                                        }
                                        return stuAux
                                      },
                                    ),
                                  })
                                }}
                              />
                            }
                          />
                        )
                      })}
                    </>
                  )
                })}
              {/** <ListItem
                  className='py-2'
                  Left={
                    <div className='d-flex flex-row align-items-baseline'>
                      <Avatar
                        name={form?.educator?.name}
                      />
                      <span className='ml-2'>{form?.educator?.name}</span>
                      <span className='text-gray h5 ml-1'>{`(${translate('modal.infolesson.educator')})`}</span>
                    </div>
                  }
                  Right={
                    <CheckboxOption
                      defaultState={form?.educator?.check}
                      onChange={(val) => {
                        setForm({
                          ...form,
                          educator: {
                            ...form.educator,
                            check: val
                          }
                        })
                      }}
                    />
                  }
                />**/}
            </>
          )}
        </div>
        <StickyButton
          disabled={!validation}
          onClickButtonFunction={() => {
            submitAlert(parseFormToMutation(form))
          }}
          activity={loadAlert}
          color='#696CFF'
          className='w-100 mb-6'
          size='medium'
          label={translate('modal.sendalert.sendwsp')}
        />
      </div>
    </Modal>
  )
}

export default WspAlert
