import React from 'react'
import PlanProgressSection from './Sections/PlanProgress'
import PlanActionStatus from '../Resume/Sections/PlanActionStatus'
import Team from './Sections/Team'
import PaymentScheduled from './Sections/PaymentScheduled'
import ApplicationStatusSection from './Sections/ApplicationStatus'
import PaymentProgressSection from './Sections/PaymentProgress'

const Summary = (props) => {
  const {plan, setData} = props
  const sectionProps = {plan, setData}

  return (
    <div className='d-flex flex-column'>
      {plan?.status === 'cancel' && (
        <>
          <PlanActionStatus className='my-5' {...sectionProps} />
          <div className='borderTop w-100 my-0' />
        </>
      )}
      <PlanProgressSection className='my-5' {...sectionProps} />
      <div className='borderTop w-100 my-0' />
      <ApplicationStatusSection className='my-5' {...sectionProps} />
      <div className='borderTop w-100 my-0' />
      <Team className='my-5' {...sectionProps} />

      {/* <div className='borderTop w-100 my-0' />
      <PaymentInfo className='my-5' {...sectionProps} /> */}

      <div className='borderTop w-100 my-0' />
      <PaymentScheduled className='my-5' {...sectionProps} />

      <div className='borderTop w-100 my-0' />
      <PaymentProgressSection className='my-5' {...sectionProps} />
    </div>
  )
}

export default Summary
