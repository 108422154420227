import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const useInvoiceModal = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.counseling.invoiceModal)
  const {isOpen, onChange, invoice} = reduxData

  const setData = (invoice) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.invoiceModal',
      data: {
        ...reduxData,
        invoice,
      },
    })
  }
  const setOpen = (open, onChange = () => {}, invoice) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.invoiceModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        invoice,
      },
    })
  }

  return {
    isOpen,
    onChange,
    open: (onChange = () => {}, invoice = {}) => {
      setOpen(true, onChange, invoice)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    invoice,
    setData,
  }
}
export default useInvoiceModal
