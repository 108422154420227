import es from './es'
import en from './en'
import plan from './plan'
import calendar from './calendar'
import timelines from './timelines'
import nav from './nav'

export default {
  es,
  en,
  plan,
  calendar,
  nav,
  timelines,
}
