import {useLazyQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'

export const UseGetLesson = (onSuccess, onError) => {
  return useLazyQuery(
    gql`
      query($lessonId: String!) {
        getLesson(
          lessonId: $lessonId
        ) {
          ${queryLessonResult}
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError: onError,
    },
  )
}
