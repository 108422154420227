import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  IconRemove,
  IconRight,
  ListItem,
  ConfirmModal,
  IconStar,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import useMarkMyUniversity from '../../../../University/Model/useMarkMyUniversity'
import useRemoveMyUniversity from '../../../../University/Model/useRemoveMyUniversity'
import useMyCollegeModal from '../useMyCollegeModal'
import MessageModal from '../../ViewTask/Components/Modals/messageModal'
import EarlyDecisionItem from '../Components/EarlyDecisionItem'
import EarlyActionItem from '../Components/EarlyActionItem'
import EarlyActionStatus from '../Components/EarlyStatus/EarlyAction'
import EarlyDecisionStatus from '../Components/EarlyStatus/EarlyDecision'
import earlyDecisionNames from '../../../Utils/earlyDecision'

const SummaryTab = (props) => {
  const collegesList = useSelector(
    (state) => state?.user?.info?.studentInfo?.universities,
  )
  const collegeData = useSelector(
    (state) => state?.modal?.counseling?.collegeModal?.college,
  )
  const {translate} = useTranslation()
  const [activeTab, setActiveTab] = useState('status')
  const {
    submit: removeUniversity,
    // eslint-disable-next-line
    loading: loadingRemoveUniversity,
    data: dataRemoveUniversity,
  } = useRemoveMyUniversity(() => { })
  const {
    submit: markUniversity,
    // eslint-disable-next-line
    loading: loadingMarkUniversity,
    data: dataMarkUniversity,
  } = useMarkMyUniversity(() => { })

  // la marca como si no se usara, pero se usa
  // eslint-disable-next-line
  let myCollegesFilter = collegesList?.filter(
    (x) => x?.id === collegeData?.collegeUnitId,
  )

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  // Textos y variables para pasarle al modal
  const [bodyModal, setBodyModal] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  // eslint-disable-next-line
  const [message, setMessage] = useState('')
  const [titleModal, setTitleModal] = useState('')
  const [confirmButton, setConfirmButton] = useState('')
  const [variables, setVariables] = useState({})
  // const [variablesRemoveDecision, setVariablesRemoveDecision] = useState(null)
  const refetchUserData = useSelector((state) => state.user.refetch)

  const { setUpdateUniversity } = useMyCollegeModal()

  const checkEarlyDecision = (edVersion) => {
    // Se le pasa la version de Early Decision.
    // decision o decision2, en formato string
    // Busca si hay ya una universidad marcada como early decision y si es distinta a la seleccionada
    const earlyDecisionCollege = collegesList?.filter(
      (x) => x.early === edVersion && x.id !== collegeData?.id,
    )
    // Si otra está marcada como seleccionada:
    if (earlyDecisionCollege.length > 0) {
      setTitleModal(
        `${collegeData?.early === edVersion ? 'Remove' : 'Mark'} ${
          earlyDecisionNames[edVersion]
        }`,
      )
      setConfirmButton(
        `Ok, ${collegeData?.early === edVersion ? 'Remove from' : 'Mark as'} ${
          earlyDecisionNames[edVersion]
        }`,
      )
      setBodyModal(
        `You have another university as ${earlyDecisionNames[edVersion]}: ${earlyDecisionCollege[0]?.name}. Remove an mark ${collegeData?.name}?`,
      )

      setVariables({
        universityId: collegeData?.id,
        mark: edVersion,
        action: 'mark',
        makeValidation: true,
      })
      setConfirmModalOpen(true)
    }
    // Si no hay una universidad marcada como early decision o si la marcada es la seleccionada
    else {
      setTitleModal(
        `${collegeData?.early === edVersion ? 'Remove' : 'Mark'} ${
          earlyDecisionNames[edVersion]
        }`,
      )
      setConfirmButton(
        `Ok, ${collegeData?.early === edVersion ? 'Remove from' : 'Mark as'} ${
          earlyDecisionNames[edVersion]
        }`,
      )
      setVariables({
        universityId: collegeData?.id,
        mark: collegeData?.early === edVersion ? 'nothing' : edVersion,
        action:
          collegeData?.early === 'nothing' || 'action' ? 'mark' : 'unmark',
      })
      setConfirmModalOpen(true)
    }
  }

  const confirmFunction = () => {
    titleModal === 'Remove University'
      ? removeUniversity(variables)
      : markUniversity(variables)
  }

  const cancelFunction = () => {
    // Setea en string vacío los estados seteados al abrir el modal de confirmación
    setTitleModal('')
    setConfirmButton('')
    setBodyModal('')

    setVariables({
      universityId: '',
      mark: '',
      action: '',
    })
    setConfirmModalOpen(false)
  }

  useEffect(() => {
    if (dataMarkUniversity) {
      refetchUserData()
      // eslint-disable-next-line
      myCollegesFilter = dataMarkUniversity?.studentInfo?.universities.filter((x) => x?.id === collegeData?.id)
      const collegeToUpdate = dataMarkUniversity?.studentInfo?.universities.find(
        (c) => c.id === collegeData?.id,
      )
      if (collegeToUpdate) {
        setUpdateUniversity({
          ...collegeData,
          early: collegeToUpdate?.early,
          status: collegeToUpdate?.status,
        })
      }
      setConfirmModalOpen(false)
    }
  }, [dataMarkUniversity])

  useEffect(() => {
    if (dataRemoveUniversity) {
      refetchUserData()
      // eslint-disable-next-line
      myCollegesFilter = dataRemoveUniversity?.studentInfo?.universities.filter((x) => x?.collegeId === collegeData?.collegeUnitId)
      setUpdateUniversity({ ...collegeData, early: null, status: null })
      setConfirmModalOpen(false)
    }
  }, [dataRemoveUniversity])

  useEffect(() => {
    setActiveTab('status')
  }, [])

  return (
    <div className='d-flex flex-column w-100'>
      <div className='d-flex flex-row w-100'>
        <Nav className='znk-scroll-horizontal ml-2 mb-3 flex-nowrap w-100 d-flex justify-content-between'>
          <NavItem
            className='m-0 p-0 h5 text-primary'
            active={activeTab === 'status'}
          >
            <NavLink
              className='nav-title m-0 p-0 h5 '
              active={activeTab === 'status'}
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                setActiveTab('status')
              }}
            >
              {translate('counseling.applicationStatus')}
            </NavLink>
          </NavItem>
          <NavItem
            className='m-0 p-0 h5 text-primary'
            active={activeTab === 'steps'}
          >
            <NavLink
              className='nav-title m-0 p-0 h5'
              active={activeTab === 'steps'}
              style={{
                fontSize: '15px',
              }}
              onClick={() => {
                setActiveTab('steps')
              }}
            >
              {translate('counseling.stepsTitle')}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className='w-100 d-flex flex-row'>
        <TabContent activeTab={activeTab} className='px-2 w-100'>
          <TabPane tabId='status' className='w-100'>
            {/* Renderiza solo si está marcado como Early Action */}
            {collegeData?.early === 'action' ? (
              <EarlyActionStatus />
            ) : ['decision', 'decision2'].includes(collegeData?.early) ? (
              <EarlyDecisionStatus edVersion={collegeData?.early} />
            ) : null}
            <div
              title='The application process has not started yet'
              className='row d-flex flex-row justify-content-start py-2 w-100'
              style={{
                borderRadius: '10px',
                border: '2px dashed',
                borderColor: '#f0f2f5',
                marginLeft: '0.1rem',
              }}
            >
              <div className='col-1 mr-3 align-self-center'>
                {' '}
                <IconStar color='#d7e1ea' size={25} />
              </div>
              <div className='col-10 align-self-center'>
                <span className='d-flex justify-content-start h4 text-muted'>
                  {translate('counseling.processNotStartedText')}
                </span>
              </div>
            </div>

            {/* }
            /> */}
            <div className='d-flex flex-column justify-content-around mt-5 h-40 w-100'>
              <EarlyActionItem
                college={collegeData}
                checkEarlyAction={() => {
                  setTitleModal(
                    `${collegeData?.early === 'action' ? 'Remove' : 'Mark'
                    } Early Action`,
                  )
                  setConfirmButton(
                    `Ok, ${collegeData?.early === 'action'
                      ? 'Remove from'
                      : 'Mark as'
                    } Early Action`,
                  )
                  setVariables({
                    universityId: collegeData?.id,
                    mark:
                      collegeData?.early === 'action' ? 'nothing' : 'action',
                    action: collegeData?.early !== 'action' ? 'mark' : 'unmark',
                  })
                  setConfirmModalOpen(true)
                }}
              />
              <EarlyDecisionItem
                college={collegeData}
                checkEarlyDecision={() => {
                  checkEarlyDecision('decision')
                }}
                edVersion='decision'
              />
              <EarlyDecisionItem
                college={collegeData}
                checkEarlyDecision={() => {
                  checkEarlyDecision('decision2')
                }}
                edVersion='decision2'
              />
              <ListItem
                className='borderTop w-100 ml-1 pr-3 pl-2'
                hasHover
                Left={
                  <div>
                    <IconRemove size={60} color='#ff3f1d' />
                    <span
                      className='ml-5 h4'
                      style={{
                        color: '#ff3f1d',
                      }}
                    >
                      {translate('counseling.removeMyUniversityTitle')}
                    </span>
                  </div>
                }
                Right={<IconRight size={20} />}
                onClick={() => {
                  setVariables({
                    universityId: collegeData?.id,
                  })
                  setTitleModal(translate('counseling.removeMyUnivTitleModal'))
                  setBodyModal(translate('counseling.removeMyUnivMsgModal'))
                  setConfirmButton(translate('counseling.removeMyUnivBtnModal'))
                  setConfirmModalOpen(true)
                }}
              />
            </div>
          </TabPane>
          <TabPane tabId='steps' className='w-100' />
        </TabContent>
      </div>
      <ConfirmModal
        className='m-4'
        isOpen={confirmModalOpen}
        toggle={!confirmModalOpen}
        loading={loadingRemoveUniversity || loadingMarkUniversity}
        title={titleModal}
        body={bodyModal}
        classNameActionDiv='d-flex flex-column justify-content-end w-80'
        confirmAction={() => {
          confirmFunction()
        }}
        cancelAction={() => cancelFunction()}
        confirmText={confirmButton}
        cancelText='Cancel'
      />
      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
    </div>
  )
}

export default SummaryTab
