import React, {useState} from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const ChangePaymentPeriodicity = (props) => {
  const {setPeriodicity, primary = false} = props
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }
  const [selectedPeriodicity, setSelectedPeriodicity] = useState({
    value: 'recomended',
    text: translate('home.onboard.change.recomended'),
  })

  const buttonProp = primary ? {color: 'primary'} : {}
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex mb-0 px-5'>
            <div className='d-flex flex-column justify-content-start mr-4'>
              <span className='text-title font-weight-bold h2'>
                {translate('home.onboard.change.changepaymentperiocity')}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start'
                size={20}
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        <div className='m-0 p-0 mx-5 mt-0'>
          <div className='m-0 px-0 pb-4 pt-0'>
            <ListOption
              className={`mb-2 ${
                selectedPeriodicity.value === 'monthly'
                  ? `selected${primary ? '' : '-secondary'}`
                  : ''
              }`}
              id='x-0'
              label={null}
              icon={
                <div className='d-flex align-items-center text-muted m-0'>
                  <span className='ml-2'>
                    {translate('home.onboard.change.monthly')}
                  </span>
                </div>
              }
              onClick={() => {
                setSelectedPeriodicity({
                  value: 'monthly',
                  text: translate('home.onboard.change.monthly'),
                })
              }}
            />
            <ListOption
              className={`mb-2 ${
                selectedPeriodicity.value === 'oneTime'
                  ? `selected${primary ? '' : '-secondary'}`
                  : ''
              }`}
              id='x-0'
              label={null}
              icon={
                <div className='d-flex align-items-center text-muted m-0'>
                  <span className='ml-2'>
                    {translate('home.onboard.change.onetime')}
                  </span>
                </div>
              }
              onClick={() => {
                setSelectedPeriodicity({
                  value: 'oneTime',
                  text: translate('home.onboard.change.onetime'),
                })
              }}
            />
            <ListOption
              className={`mb-2 ${
                selectedPeriodicity.value === 'twoTime'
                  ? `selected${primary ? '' : '-secondary'}`
                  : ''
              }`}
              id='x-0'
              label={null}
              icon={
                <div className='d-flex align-items-center text-muted m-0'>
                  <span className='ml-2'>
                    {translate('home.onboard.change.twotime')}
                  </span>
                </div>
              }
              onClick={() => {
                setSelectedPeriodicity({
                  value: 'twoTime',
                  text: translate('home.onboard.change.twotime'),
                })
              }}
            />
            <ListOption
              className={`mb-2 ${
                selectedPeriodicity.value === 'recomended'
                  ? `selected${primary ? '' : '-secondary'}`
                  : ''
              }`}
              id='x-0'
              label={null}
              icon={
                <div className='d-flex align-items-center text-muted m-0'>
                  <span className='ml-2'>
                    {translate('home.onboard.change.recomended')}
                  </span>
                </div>
              }
              onClick={() => {
                setSelectedPeriodicity({
                  value: 'recomended',
                  text: translate('home.onboard.change.recomended'),
                })
              }}
            />
          </div>
          <StickyButton
            condition
            label={translate('home.onboard.change.changepaymentperiocity')}
            onClickButtonFunction={() => {
              setPeriodicity(selectedPeriodicity)
              toggle()
            }}
            {...buttonProp}
          />
        </div>
      </Modal>
    </>
  )
}

ChangePaymentPeriodicity.propTypes = {}

ChangePaymentPeriodicity.defaultProps = {}

export default ChangePaymentPeriodicity
