import React from 'react'
import * as moment from 'moment'
import {IconTask} from '@Knowledge-OTP/znk-ui-components'

const TaskCompletedButton = (props) => {
  const {task} = props

  return (
    <div
      className='d-flex justify-content-center py-2'
      style={{
        width: '100%',
        border: '1px solid',
        borderRadius: '10px',
        borderColor: '#f3fcd5',
        backgroundColor: '#f3fcd5',
        color: '#8fb33a',
      }}
    >
      <div className='d-flex justify-content-between my-auto w-100'>
        <div className='col-10'>
          <span className='d-flex justify-content-start align-middle py-2'>
            {`Task completed ${moment(task?.completedDate).fromNow()}`}
          </span>
        </div>
        <div className='col-2 d-flex justify-content-end align-items-center'>
          <IconTask
            color='#aae61d'
            fill='#aae61d'
            stroke='transparent'
            strokeWidth='2'
            size={16}
          />
        </div>
      </div>
    </div>
  )
}

export default TaskCompletedButton
