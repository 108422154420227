import React from 'react'
import {
  ListItem,
  IconArrowRight,
  IconDate,
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'

const SelectDate = (props) => {
  const {examDate, onChange} = props
  const today = moment().format('YYYY-MM-DD')

  return (
    <>
      <ListItem
        Left={
          <div className='d-flex flex-row align-items-center m-0 p-0'>
            <IconDate className='text-info' />
            <span
              id='examDateText'
              name='examDateText'
              className='ml-4 text-info'
            >
              {examDate || 'Select Date'}
            </span>
            <input
              type='date'
              id='examDate'
              name='examDate'
              // min={today}
              defaultValue={today}
              style={{
                border: '0',
                outline: '0',
                position: 'absolute',
                top: '200',
                right: '0',
                marginRight: '50px',
                opacity: '0',
                cursor: 'pointer',
              }}
              onChange={
                // ()=> {}
                (e) => {
                  onChange(e?.target.value)
                }
              }
            />
          </div>
        }
        Right={
          // isEdit &&
          <div className='d-flex flex-row'>
            <div className='hover-icon mr-2'>
              <IconArrowRight />
            </div>
          </div>
        }
        showBottomLine
      />
    </>
  )
}

export default SelectDate
