import {useLazyQuery, gql} from '@apollo/client'

const useGetOnboardingSession = () => {
  return useLazyQuery(
    gql`
      query($planId: ID!) {
        getOnboardingSession(planId: $planId)
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  )
}

export default useGetOnboardingSession
