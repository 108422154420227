import React, {useState} from 'react'
import {
  Modal,
  Form,
  IconClose,
  Button,
  ListItem,
  IconPassword,
  LabeledField,
  PlainTextInput,
  Field,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import validateForm from './validate'
import isEmpty from 'lodash/isEmpty'
import useUser from '../../../../../hooks/auth/useUser'
import SuccessView from './SuccessMessage'
import {useDispatch, useSelector} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../../store/modal/const'
const ChangePassword = (props) => {
  const {user} = useUser()
  const [success, setSuccess] = useState(false)
  const isOpen = useSelector((state) => state.modal.profile.password)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.password'})
  }
  const [form, setForm] = useState({
    newPass: '',
    confirmPass: '',
  })
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const [validationErrors, setValidationErrors] = useState({})
  if (
    user &&
    user.providerData.find((item) => item.providerId === 'password')
  ) {
    const submit = async () => {
      const errors = validateForm(form)
      if (!isEmpty(errors)) {
        setValidationErrors(errors)
        return
      }
      try {
        await user.updatePassword(form.confirmPass)
        setSuccess(true)
      } catch (e) {
        setValidationErrors({general: 'Error al cambiar la contraseña'})
      }
    }
    return (
      <>
        <ListItem
          onClick={toggle}
          showBottomLine
          hasHover
          hasColorHover
          Left={
            <span className='d-flex align-items-left'>
              <IconPassword size={16} className='mr-3' />
              <span className='text-black'>
                {translate('profile.actions.changePassword')}
              </span>
            </span>
          }
        />
        <Modal
          className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
          backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <ModalHeader
              toggle={toggle}
              close={<IconClose size={20} onClick={toggle} />}
              className='mt-md-4 pl-0'
            >
              <span className='h2 font-weight-bold'>
                {!success && translate('profile.actions.changePassword')}
              </span>
            </ModalHeader>
          }
        >
          {success ? (
            <SuccessView />
          ) : (
            <>
              {validationErrors && validationErrors.general && (
                <span className='text-error my-2'>
                  {translate('profile.actions.changePassword.error')}
                </span>
              )}
              <Form
                value={form}
                onChange={setForm}
                validationErrors={validationErrors}
              >
                <Field
                  type={LabeledField}
                  component={PlainTextInput}
                  fieldName='newPass'
                  placeholder=''
                  fieldType='password'
                  label={translate('profile.actions.changePassword.new')}
                />
                <Field
                  type={LabeledField}
                  component={PlainTextInput}
                  fieldName='confirmPass'
                  placeholder=''
                  fieldType='password'
                  label={translate('profile.actions.changePassword.confirm')}
                />
              </Form>
              <Button
                label={translate('profile.actions.changePassword')}
                color='primary'
                check
                size='large'
                onClick={submit}
              />
            </>
          )}
        </Modal>
      </>
    )
  } else return null
}

ChangePassword.propTypes = {}

export default ChangePassword
