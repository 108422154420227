import {useMutation, gql} from '@apollo/client'

const useApproveCounselingPlan = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation(
        $parentSign: String
        $studentSign: String
        $approveInput: ApproveInput!
      ) {
        approveCounselingPlan(
          parentSign: $parentSign
          studentSign: $studentSign
          approveInput: $approveInput
        ) {
          success
          errorMsg
        }
      }
    `,
    // comentario
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default useApproveCounselingPlan
