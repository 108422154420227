import {
  Modal,
  IconSignMinus,
  IconIllustratedTopic,
  useWindowDimensions,
  IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'

import useModalState from './../../../../../hooks/useModalState'
import ConfirmLesson from './ConfirmLesson'
import EducatorScheduleSelection from './TimeSelection'

const CopyLessonModal = (props) => {
  const {isOpen, modalData, onChange, toggle} = useModalState(
    'program.copyLesson',
  )
  const setConfirm = (selectedBlock) =>
    onChange((val) => ({...val, stage: 'confirm', selectedBlock}))
  const goBack = () => onChange((val) => ({...val, stage: 'timeSelection'}))
  const {stage, educator, topic, service, selectedBlock} = modalData
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`modal-colored modal-${
        breakWidth === 'SM' ? 'botbar' : 'centered'
      } white-modal modal-unavailability`}
      Head={
        <div className='modal-header d-flex flex-column px-4 px-md-5 pt-2'>
          <div className='w-100 d-flex justify-content-center mt-1'>
            {breakWidth === 'SM' && <IconSignMinus size={20} />}
          </div>
          <div className='w-100 d-flex'>
            <div className='flex-grow-1'>
              <IconIllustratedTopic size={80} />
              <div className='d-flex flex-column mt-2 justify-content-around h-auto'>
                <h2 className='font-weight-bold text-title'>
                  {stage && stage === 'timeSelection'
                    ? translate('lessons.selectdate')
                    : translate('lessons.scheduleLesson')}
                </h2>
                <h4 className='font-weight-light text-title'>
                  {translate('lessons.fortimezone', {
                    lesson: `${service && service.name} ${topic && topic.name}`,
                    zone: 'Santiago, GMT-4',
                  })}
                </h4>
              </div>
            </div>
            <div>
              <IconClose className='text-gray' size={20} onClick={toggle} />
            </div>
          </div>
        </div>
      }
    >
      <div className='w-100 px-0 px-md-5'>
        {stage && stage === 'timeSelection' ? (
          <EducatorScheduleSelection
            {...{educator, setConfirm, selectedBlock}}
          />
        ) : (
          <ConfirmLesson
            {...{topic, service, educator, selectedBlock, goBack, toggle}}
          />
        )}
      </div>
    </Modal>
  )
}

export default CopyLessonModal
