export default {
  studentName: 'Student name',
  firstName: 'Name',
  lastName: 'Last name',
  email: 'Email',
  birthday: 'Birthday',
  phone: 'Mobile phone',
  address: 'Address',
  country: 'Country',
  city: 'City',
  state: 'State',
  zipCode: 'Zipcode',
  spokenLanguages: 'Spoken languages',
  gender: 'Gender',
}
