import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
import {InputSelect} from '@Knowledge-OTP/znk-ui-components'
import {Label} from 'reactstrap'
import examTypes from '../examTypes'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import ActComponent from './Exams/ACTComponent'
import SatComponent from './Exams/SATComponent'
import DuolingoComponent from './Exams/DuolingoComponent'
import ApComponent from './Exams/APComponent'
import ToeflComponent from './Exams/TOEFLComponent'
import IELTSComponent from './Exams/IELTSComponent'
import SsatComponent from './Exams/SSATComponent'
import IseeComponent from './Exams/ISEEComponent'
import GreComponent from './Exams/GREComponent'
import LsatComponent from './Exams/LSATComponent'
import useActivities from '../../../../Student/modal/InfoStudent/useQueries/useActivities'
import InputDate from './inputDate'
import UploadFileButton from '../../ViewTask/Components/UploadFileButton'
import typesnames from '../../../Utils/typenames'
import taskTypes from '../../../Utils/taskTypes'

const ScoresComponent = (props) => {
  const {
    scores,
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    taskStatus,
    filesUploadedByUser,
    setFilesUploadedByUser,
    uploadStatusOk,
    setUploadStatus,
    activity,
  } = props
  const taskData = useSelector(
    (state) => state?.modal?.counseling?.viewTaskModal?.task,
  )
  const today = moment().format('YYYY-MM-DD')
  const isEditable = taskStatus === 'pending' || taskStatus === 'draft'
  const dispatch = useDispatch()
  const [examState, setExamState] = useState({})
  const [examDate, setExamDate] = useState(taskData?.serviceDate)
  const buttonStyles =
    'd-flex justify-content-center align-items-center py-2 w-85 mt-6 mx-auto'

  const divButtonClass = 'mt-2 w-100 pl-4'
  const sectionTitleColor = '#94A0AF'
  const scoresComponentClass =
    'd-flex flex-column w-100 justify-content-between pl-4'
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const [activitiesToShow, setActivitiesToShow] = useState([])

  const onChange = (exam) => {
    const date = exam.startDate ? exam.startDate : today
    setExamState(exam)
    setScoresData({
      ...scoresRedux,
      examDate: date,
    })
    setExamDate(moment(date).format('MMM Do, YYYY'))
  }

  // eslint-disable-next-line
{/* Muestra recuadro para agregar documentos a la task */}
  const uploadFileOption = ((taskData?.__typename ===
    typesnames.LessonsPerWeekPlan &&
    !taskData?.selectedExam?.scores) ||
    (taskData?.uploadDocs &&
      taskData?.status === 'pending' &&
      taskData?.type !== taskTypes.uploadFile)) && (
    <UploadFileButton
      filesUploadedByUser={filesUploadedByUser}
      setFilesUploadedByUser={setFilesUploadedByUser}
      uploadStatusOk={uploadStatusOk}
      setUploadStatus={setUploadStatus}
    />
  )
  const {isOpen, scores: scoresRedux, task} = reduxData

  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const [
    activitiesQuery,
    // eslint-disable-next-line
    { data: dataActivities, loading: loadActivities },
  ] = useActivities()

  useEffect(() => {
    if (taskStatus === 'pending' && planId) {
      activitiesQuery({
        variables: {
          kinds: ['test'],
        },
      })
    }
    // eslint-disable-next-line
  }, [reduxData, activitiesQuery])

  useEffect(() => {
    if (dataActivities) {
      const acitivitiesFilter = dataActivities?.allActivities?.filter(
        (activity) => activity.serviceId === scores?.serviceId,
      )
      acitivitiesFilter.push({id: '000', name: 'Other'})
      // eslint-disable-next-line
      const activityToShow = acitivitiesFilter?.filter((activity) =>
        activity.startDate === taskData?.serviceDate
      )
      activitiesToShow?.length === 1 && setExamState(activitiesToShow[0])
      setActivitiesToShow(acitivitiesFilter)
    }
    // eslint-disable-next-line
  }, [dataActivities])

  const parseExamToScores = (exam) => {
    const parsedExam = {}
    parsedExam.examDate =
      exam?.examDate || taskData?.testDate || taskData?.serviceDate
    switch (true) {
      case exam?.name === examTypes.SAT:
        parsedExam.name = exam?.name || ''
        parsedExam.verbal = exam?.results[0]?.value || 0
        parsedExam.math = exam?.results[1]?.value || 0
        parsedExam.reading = exam?.results[2]?.values[0]?.value || 0
        parsedExam.writing = exam?.results[2]?.values[1]?.value || 0
        parsedExam.analysis = exam?.results[2]?.values[2]?.value || 0
        parsedExam.satScore = parsedExam?.verbal + parsedExam?.math
        parsedExam.essayScore =
          (parsedExam?.reading + parsedExam?.writing + parsedExam?.analysis) / 3
        break
      case exam?.name === examTypes.ACT:
        parsedExam.name = exam?.name || ''
        parsedExam.math = exam?.results[0]?.values[0]?.value || 0
        parsedExam.science = exam?.results[1]?.values[0]?.value || 0
        parsedExam.english = exam?.results[2]?.values[0]?.value || 0
        parsedExam.reading = exam?.results[3]?.values[0]?.value || 0
        parsedExam.composite =
          (parsedExam.math +
            parsedExam.science +
            parsedExam.english +
            parsedExam.reading) /
          4
        parsedExam.writing = exam?.results[5]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.AdvancedPlacement:
        parsedExam.name = exam?.name || ''
        parsedExam.subjectName = exam?.results[0].name || ''
        parsedExam.subject = exam?.results[0].values[0]?.value || 0
        break
      case exam?.name === examTypes.TOEFL:
        parsedExam.name = exam?.name || ''
        parsedExam.reading = exam?.results[0]?.values[0]?.value || 0
        parsedExam.listening = exam?.results[1]?.values[0]?.value || 0
        parsedExam.speaking = exam?.results[2]?.values[0]?.value || 0
        parsedExam.writing = exam?.results[3]?.values[0]?.value || 0
        parsedExam.overall =
          parsedExam.reading +
          parsedExam.listening +
          parsedExam.speaking +
          parsedExam.writing
        break
      case exam?.name === examTypes.IELTS:
        parsedExam.name = exam?.name || ''
        parsedExam.listening = exam?.results[0]?.values[0]?.value || 0
        parsedExam.reading = exam?.results[1]?.values[0]?.value || 0
        parsedExam.writing = exam?.results[2]?.values[0]?.value || 0
        parsedExam.speaking = exam?.results[3]?.values[0]?.value || 0
        parsedExam.overall = exam?.results[4]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.Duolingo:
        parsedExam.name = exam?.name || ''
        parsedExam.literacy = exam?.results[0]?.values[0]?.value || 0
        parsedExam.conversation = exam?.results[1]?.values[0]?.value || 0
        parsedExam.comprehension = exam?.results[2]?.values[0]?.value || 0
        parsedExam.production = exam?.results[3]?.values[0]?.value || 0
        parsedExam.overall = exam?.results[4]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.SSAT:
        parsedExam.name = exam?.name || ''
        parsedExam.verbal = exam?.results[0]?.values[0]?.value || 0
        parsedExam.math = exam?.results[1]?.values[0]?.value || 0
        parsedExam.reading = exam?.results[2]?.values[0]?.value || 0
        parsedExam.total = exam?.results[3]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.ISEE:
        parsedExam.name = exam?.name || ''
        parsedExam.total = exam?.overall || 0
        parsedExam.verbal = exam?.results[0]?.values[0]?.value || 0
        parsedExam.reading = exam?.results[1]?.values[0]?.value || 0
        parsedExam.reasoning = exam?.results[2]?.values[0]?.value || 0
        parsedExam.math = exam?.results[3]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.GRE:
        parsedExam.name = exam?.name || ''
        parsedExam.total = exam?.overall || 0
        parsedExam.verbal = exam?.results[0]?.values[0]?.value || 0
        parsedExam.reasoning = exam?.results[1]?.values[0]?.value || 0
        parsedExam.writing = exam?.results[2]?.values[0]?.value || 0
        break
      case exam?.name === examTypes.LSAT:
        parsedExam.name = exam?.name || ''
        parsedExam.total = exam?.overall || 0
        parsedExam.score = exam?.results[0]?.values[0]?.value || 0
        break
      default:
        console.error('Exam type not found')
    }
    setScoresData(parsedExam)
  }

  useEffect(() => {
    if (isOpen && scores) {
      parseExamToScores(scores)
    }
    // eslint-disable-next-line
  }, [scores, isOpen])

  const showDateSelector = () => {
    if (taskData?.__typename === typesnames.LessonsPerWeekPlan) {
      if (taskData?.testDate && taskData?.testDate !== null) return false
    }
    if (taskData?.__typename === typesnames.UserTasksOut) {
      if (taskData?.status !== 'pending') return false
      if (taskData?.serviceDate && taskData?.serviceDate !== null) return false
      if (
        task?.resolveTask?.score?.examDate &&
        task?.resolveTask?.score?.examDate !== null
      )
        return false
    }
    return true
  }
  const propsToSend = {
    mutation,
    planId:
      reduxData?.task?.__typename === typesnames.UserTasksOut
        ? planId
        : taskData.id,
    phase,
    stepId,
    taskId,
    taskStatus,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  }

  return (
    <div className='w-100'>
      {showDateSelector() && (
        <div className={`${scoresComponentClass} w-100`}>
          <Label style={{color: sectionTitleColor}}>Test Date</Label>

          <InputSelect
            style={{color: '#2C3F58'}}
            value={examState?.id}
            isClearable={false}
            menuPlacement='top'
            options={activitiesToShow.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })}
            onChange={(e) => {
              const exam = activitiesToShow.find(({id}) => id === e)
              onChange(exam)
            }}
          />
          <div className='mt-1 mb-2'>
            {examState?.id === '000' && (
              <div className={`${scoresComponentClass}`}>
                <InputDate
                  examDate={examDate}
                  onChange={(newValue) => {
                    setScoresData({
                      ...scoresRedux,
                      examDate: newValue,
                    })
                    setExamDate(moment(newValue).format('MMM Do, YYYY'))
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {/* Renderizar según el tipo de examen */}

      {scores?.name === examTypes.SAT && (
        <SatComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.ACT && (
        <ActComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.Duolingo && (
        <DuolingoComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.AdvancedPlacement && (
        <ApComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.TOEFL && (
        <ToeflComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.IELTS && (
        <IELTSComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.SSAT && (
        <SsatComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.ISEE && (
        <IseeComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.GRE && (
        <GreComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}

      {scores?.name === examTypes.LSAT && (
        <LsatComponent
          propsToSend={propsToSend}
          examDate={examState?.startDate}
        />
      )}
    </div>
  )
}

export default ScoresComponent
