export default {
  choose: ({index, name}) => {
    index += 1
    if (index === 1) {
      return `Escoge tu ${index}era lección de ${name}`
    } else {
      return `Escoge tu ${index}a lección de ${name}`
    }
  },
  schedulingYourLessonTime: ({timeLabel}) => {
    return `Agendando lección de ${timeLabel}`
  },
  chooseOr: 'Escoge tu sesión de Orientación.',
  orSubtitle:
    'Duis mollis, est non commodo luctus, nisni erat portitor ligula, eget laciia odio.',
  weekav: 'Ver disponibilidad semanal',
  giveMe: 'Dame unos segundos. \n Tus lecciones están siendo programadas.',
  someReason:
    'Si por alguna razón no podemos programar todas las lecciones automáticamente, tendrá que programarlas manualmente',
  notScheduled: 'No pudimos programar todas las lecciones automáticamente',
  notScheduledsubtitle: 'Ir a cada lección para programar',
  scheduled: 'Agendar',
  recommended: 'Recomendado',
}
