import React from 'react'
import useTranslation from '../../../../../../i18n/useTranslation'
import {IconCheckmark, Button} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../../../store/modal/const'

const SuccessView = () => {
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.password'})
  }
  const {translate} = useTranslation()
  return (
    <div className='d-flex flex-column m-auto'>
      <IconCheckmark size={48} />
      <p className='h3 font-weight-bold mt-5 mb-4'>
        {translate('profile.actions.changePassword.successTitle')}
      </p>
      <p className='h4 font-weight-light mb-5'>
        {translate('profile.actions.changePassword.successBody')}
      </p>
      <Button
        check
        size='medium'
        color='primary'
        label={translate('profile.actions.changePassword.successButton')}
        onClick={toggle}
      />
    </div>
  )
}

export default SuccessView
