import {gql, useQuery} from '@apollo/client'
import queryLessonResult from '../../../QueryResult/useLessonResult'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: {}}
  }

  const {data, error, loading, refetch} = useQuery(
    gql`query(
            $studentId: ID!
            $to: DateTime!
            $from: DateTime!
          ) {
        calendarViewStudentForEducator(
            studentId: $studentId
            to: $to
            from: $from
      ) {  
          ${queryLessonResult}
          ... on Counselor {
            id
            kind
            createdAt
            updatedAt
            startDate
            endDate
            busy
            userIdsInvolved
            counselorId
            studentId
          }
      }
    }`,
    {
      variables: vars,
    },
  )
  return {
    loading,
    data: data ? data.calendarViewStudentForEducator : [],
    error,
    refetch,
  }
}
