import React from 'react'
import {
  FilterBetweenDates,
  IconArrowDown,
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'

const DateFilter = (props) => {
  const {setDateFilter} = props
  const {translate} = useTranslation()
  return (
    <FilterBetweenDates
      strings={{
        title: translate('profile.payments.date'),
        buttonCancel: translate('misc.filters.buttonCancel'),
        buttonApply: translate('misc.filters.buttonApply'),
        from: translate('misc.filters.fromDate'),
        to: translate('misc.filters.toDate'),
      }}
      direction='bottom'
      setDates={setDateFilter}
      typeFilter='span'
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{cursor: 'pointer'}} className='m-0 p-0'>
          {translate('profile.payments.date')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </FilterBetweenDates>
  )
}

export default DateFilter
