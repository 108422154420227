import {db} from '../fireBase'

export const getMessages = (lessonID, setMessages) => {
  db.collection('messages')
    .doc(lessonID)
    .onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data()
          setMessages({
            messages: messagesToArray(data),
            error: null,
            loading: true,
          })
        } else {
          setMessages({
            messages: [],
            error: null,
            loading: true,
          })
        }
      },
      (error) =>
        setMessages((resp) => {
          return {
            ...resp,
            error: error,
            loading: true,
          }
        }),
    )
}

// marca todos los mensajes del chat como leidos (por un usuario)
export const markAsReader = (eventID, userID) => {
  // marcar mensaje como leido (en firebase)
  db.collection('messages')
    .doc(eventID)
    .get()
    .then(async (resp) => {
      const resObj = resp?.data()
      const resArray = resObj ? Object.entries(resObj) : []
      if (!(resArray?.length > 0)) return
      const newCollection = {}
      for (let i = 0; i < resArray.length; i++) {
        const [key, message] = resArray[i]
        const oldReaded = message?.readed ? message?.readed[userID] || {} : {}
        newCollection[key] = {
          ...message,
          readed: {
            ...(message?.readed || {}),
            [userID]: {...oldReaded, readed: true},
          },
        }
      }
      await db.collection('messages').doc(eventID).update(newCollection)
    })
}

export const messagesToArray = (objectMessages) => {
  return Object.keys(objectMessages)
    .map((key) => objectMessages[key])
    .sort((a, b) => a.index - b.index)
}
