import {gql, useLazyQuery} from '@apollo/client'
import {
  IconClose,
  IconPayslip,
  IconRight,
  IconSuccess,
  InvoiceCard,
  ListItem,
  Modal,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import formatNumber from '../../../../helpers/formatNumber'
import useTranslation from '../../../../i18n/useTranslation'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const PayslipModal = () => {
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const [form, setForm] = useState({
    student: undefined,
    items: [],
  })
  const {isOpen, payslipId} = useSelector(
    (state) => state.modal.payslips.viewPayslip,
  )
  const [mePayslip, {data}] = useLazyQuery(
    gql`
      query($payslipId: String!) {
        payslip(payslipId: $payslipId) {
          id
          userId {
            id
            firstName
            lastName
          }
          to
          category
          payslipDate
          payslipNumber
          paymentItemIds
          isManual
          createdAt
          updatedAt
          status
          lastProcessedAt
          payslipProps {
            amount
            isPenalized
          }
          paymentItemsPayslip {
            generatedDescription
            id
            amount
            date
            description
            type
            lessonId
            isWaive
          }
        }
      }
    `,
    {
      variables: {payslipId: payslipId},
      fetchPolicy: 'network-only',
    },
  )
  useEffect(() => {
    ;(async () => {
      if (payslipId) {
        await mePayslip({payslipId})
      }
    })()
    // eslint-disable-next-line
  }, [payslipId])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.payslip.userId,
        items: data.payslip.paymentItemsPayslip.map((e) => {
          return {
            ...e,
            hover: false,
          }
        }),
      })
    }
    // eslint-disable-next-line
  }, [data])
  const getStatus = (invoice, witch) => {
    let modalState
    switch (invoice.status) {
      case 'draft':
        modalState = 'draft'
        break
      case 'pendingPayment':
        if (invoice.category === 'livePrep') {
          modalState = 'live'
        } else {
          modalState = 'counceling'
        }
        break
      case 'procesingPayment':
        modalState = 'live'
        break
      case 'unPaid':
        modalState = 'pendingPayment'
        break
      case 'paid':
        modalState = 'paid'
        break
      case 'canceled':
        modalState = 'canceled'
        break
      default:
        modalState = 'scheduled'
        break
    }
    if (witch === 'modal') {
      switch (modalState) {
        case 'draft':
          return 'scheduled'
        case 'live':
          return 'upcoming'
        case 'counceling':
          return 'counceling'
        case 'pendingPayment':
          return 'error'
        case 'paid':
          return 'paid'
        case 'canceled':
          return 'canceled'
        default:
          return ''
      }
    } else {
      return modalState
    }
  }
  const getTop = (status) => {
    switch (status) {
      case 'canceled':
        return ' - CANCELED'
      case 'paid':
        return ' - PAID'
      case 'unPaid':
        return ' - ERROR'
      default:
        return ''
    }
  }
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'payslips.viewPayslip',
      data: {isOpen: false, payslipId: ''},
    })
  }
  return (
    <Modal
      buttonLabel={null}
      className={`modal-colored modal-${
        breakWidth === 'SM' ? 'botbar' : 'sidebar'
      } ${data ? getStatus(data.payslip, 'modal') : 'scheduled'}-modal`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header listItemBorders px-4 pt-4 px-sm-6 pt-md-6 px-lg-8 pt-lg-7 px-xl-10 d-flex flex-column'>
          <InvoiceCard
            type={data ? getStatus(data.payslip, 'invoice') : 'draft'}
            textTop={
              data ? 'End of ' + moment(data.payslip.to).format('MMMM') : ''
            }
            text={`${data ? getTop(data.payslip.status) : ''}`}
            typePayment={<IconClose size={20} onClick={toggle} />}
            className='mt-1 px-0'
            amount={data ? data.payslip.payslipProps.amount : 0}
            of=' '
            invoice={`${
              data
                ? 'PAY-' + String(data.payslip.payslipNumber).padStart(5, 0)
                : ''
            }`}
          />
          {data && data.payslip.status === 'paid' && (
            <ListItem
              className='mb-2 border-top w-100'
              Left={
                <h3 style={{color: '#81B023'}}>
                  Payslip paid on{' '}
                  {moment(data.payslip.lastProcessedAt).format(
                    'MMM DD, YYYY hh:mm a',
                  )}
                </h3>
              }
              Right={
                <span>
                  <IconSuccess className='ml-1' color='#A7E521' size={24} />
                </span>
              }
            />
          )}
        </div>
      }
    >
      {data ? (
        <div className='px-4 pt-4 px-sm-6 pt-md-6 px-lg-8 pt-lg-7 px-xl-10 d-flex flex-column'>
          <div className='d-flex flex-row mt-5'>
            <IconPayslip />
            <span className='ml-2'>{translate('payslips.description')}</span>
          </div>
          <div className='borderTop mt-5' />
          <div className='d-flex flex-row justify-content-between mt-3'>
            <h3 className='text-gray'>Item</h3>
            <h3 className='text-gray'>Amount</h3>
          </div>
          <div className='borderTop mt-5' />
          {data &&
            form.items
              .filter((e) => !e.isWaive)
              .map((element) => {
                return (
                  <div
                    key={`item-${element.id}`}
                    className='d-flex flex-row justify-content-between align-items-center'
                  >
                    <div className='w-100'>
                      <div className='mt-3 mb-3' />
                      <div className='d-flex flex-row justify-content-between'>
                        <div>
                          <span className='text-bold'>
                            {translate(`filters.payslips.${element.type}`)}
                          </span>
                          <span className='text-gray'>
                            {element.percentDiscount > 0
                              ? ' (' +
                                element.percentDiscount.toFixed(2) +
                                '% Discount)'
                              : ''}
                          </span>
                        </div>
                        <span className='text-gray'>
                          {element.isWaive
                            ? 'Waive'
                            : '$' + formatNumber(element.amount)}
                        </span>
                      </div>
                      <div className='d-flex flex-row justify-content-between'>
                        <span className='text-gray'>
                          {element.generatedDescription ?? element.description}
                        </span>
                        {element.isWaive && (
                          <span
                            className='text-gray text-muted'
                            style={{textDecoration: 'line-through'}}
                          >
                            ${formatNumber(element.amount)}
                          </span>
                        )}
                      </div>
                      <div className='borderTop mt-3' />
                    </div>
                  </div>
                )
              })}
          <div className='pt-5'>
            <div className='d-flex flex-row justify-content-between mt-3'>
              <div>
                <h2 className='font-weight-bold'>Total</h2>
              </div>
              <h2 className='font-weight-bold'>
                $ {data && formatNumber(data.payslip.payslipProps.amount)}
              </h2>
            </div>
            <div className='borderTop mt-3' />
          </div>
          <div className='mt-3 pt-5'>
            <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row'>
                  <h5 className='font-weight-bold h14 text-gray'>Created</h5>
                  <h5 className='ml-2 font-weight-light h14 text-gray'>
                    {data
                      ? 'at ' +
                        moment(data.payslip.createdAt).format(
                          'MMM DD, YYYY hh:mm a',
                        )
                      : ''}
                  </h5>
                </div>
                <div className='d-flex flex-row'>
                  <h4 className='font-weight-bold h14 text-gray'>Updated</h4>
                  <h4 className='ml-2 font-weight-light h14 text-gray'>
                    {data
                      ? 'at ' +
                        moment(data.payslip.updateAt).format(
                          'MMM DD, YYYY hh:mm a',
                        )
                      : ''}
                  </h4>
                </div>
              </div>
              <IconRight size={16} />
            </div>
          </div>
          <div className='borderTop mt-6' />
        </div>
      ) : (
        <div className='px-4 pt-4 px-sm-6 pt-md-6 px-lg-8 pt-lg-7 px-xl-10 d-flex flex-column'>
          <Skeleton count={10} height={40} />
        </div>
      )}
    </Modal>
  )
}

export default PayslipModal
