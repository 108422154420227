import React from 'react'
import {Image as PDFImage} from '@react-pdf/renderer'

const Image = (props) => {
  return (
    <PDFImage style={props?.style || {}} src={props?.src ? props.src : null} />
  )
}

export default Image
