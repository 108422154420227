import React, {useEffect, useState, useMemo /*, useRef */} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import {
  ConfirmModal,
  // Button,
  IconArrowRight,
  IconSignCheck,
  // Input,
  // InputPassword,
} from '@Knowledge-OTP/znk-ui-components'
import useTaskLoginInfo from '../../../../Task/Model/useTaskLoginInfo'
import MessageModal from '../../ViewTask/Components/Modals/messageModal'
import {UseGetUserTask} from '../../../../../Model/Users/Student/getUserTask/useGetUserTask'
import ShowButtonByStatus from '../../ViewTask/Components/showButtonsByStatus'
import useTranslation from '../../../../../i18n/useTranslation'
import useAllUserTasks from '../../../../Task/Model/useAllUserTasks'
import useGetAccounts from '../../../../../Model/Users/Accounts/IdsAccounts/useGetAccounts'
import useOperationTaskMutation from '../../../../Task/Model/useOperationTask'
import {
  // UPDATE_TASK,
  COUNSELING_ALL_TASKS,
} from '../../../../../store/user/const'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import InfoTaskButton from './Buttons/infoTaskButton'
import useShowLoginInfoModal from '../../ShowLoginInfoModal/useShowLoginInfoModal'
import ShowLoginInfoModal from '../../ShowLoginInfoModal'
import './index.css'
import {SendToReviewButtonOff} from './Buttons'
import phaseNames from '../../../Utils/phaseNames'

const LoginInfoModal = (props) => {
  const planId = useSelector(
    (state) =>
      state?.user?.info.studentInfo?.homeNotification?.councelingPlanId,
  )
  const {task} = props
  const dispatch = useDispatch()
  //   const { isOpen, toggle, task } = useLoginInfoModal()
  const [taskData, setTaskData] = useState(task)
  const refetchUserData = useSelector((state) => state.user.refetch)
  // eslint-disable-next-line
  const [username, setUsername] = useState('')
  // eslint-disable-next-line
  const [passwordForm, setPasswordForm] = useState('')
  const {translate} = useTranslation()
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const bodyModal = translate('counseling.loginInfoConfirmBody')
  const dataRedux = useSelector(
    (state) => state.modal.counseling.loginInfoModal.data,
  )

  const titleModal = translate('counseling.loginInfoConfirmTitle')

  // const disabled = useMemo(() => {
  //   return username === '' || passwordForm === ''
  //   // eslint-disable-next-line
  // }, [username, passwordForm])

  // eslint-disable-next-line
  const { data: accountsIds, refetch: refetchAccounts, loading: loadAccount } = useGetAccounts()

  const {open: openLoginModal} = useShowLoginInfoModal()

  const accountsNames = useMemo(() => {
    if (accountsIds) {
      const accountFound = accountsIds?.getAccounts.filter(
        (acc) => acc.id === task?.accountsPlatform,
      )
      return accountFound[0]
    }
    // eslint-disable-next-line
  }, [accountsIds])

  // platform value
  const platform = useMemo(() => {
    return (
      taskData?.resolveTask?.loginInfo?.platform?.name || accountsNames?.name
    )
  }, [taskData, accountsNames])

  const [
    // eslint-disable-next-line
    operationMutation, { loading: loadOperationTask, data: dataOperationTask },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
    },
    (err) => {
      console.error('Error on send to review task : ', err)
    },
  )
  const infoByStatus = {
    pending: {
      text: taskData?.buttonLabel,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconArrowRight
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    review: {
      text: taskData?.resolveTask?.loginInfo?.platform?.name || taskData?.name,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    completed: {
      text: taskData?.resolveTask?.loginInfo?.platform?.name,
      colorText: '#72809D',
      background: '#F0F4F8',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#72809D'
        />
      ),
    },
  }

  // const sendToReviewFunction = () => {
  //   operationMutation({
  //     variables: {
  //       universities: taskData?.universities,
  //       operation: 'review',
  //       userId: taskData?.userId,
  //       taskId: taskData?.id,
  //       stepId: taskData?.stepId,
  //       phase: taskData?.phase.replace(/-([a-z])/g, function (g) {
  //         return g[1].toUpperCase()
  //       }),
  //       planId: planId,
  //     },
  //   })
  // }

  // Query para traer una sola task
  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      const taskToSave = resp?.getUserTask
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.loginInfoModal',
        data: {
          ...dataRedux,
          task: taskToSave,
        },
      })

      refetchUserData()
      refetchTasks().then((resp) => {
        dispatch({
          type: COUNSELING_ALL_TASKS,
          payload: 'counseling.allTasks',
          data: resp?.data?.getUserTasks,
        })
      })
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  const {
    submit: taskLoginInfoMutation,
    loading: loadMutation,
    data: logininfoData,
  } = useTaskLoginInfo(() => {})

  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  // const validationFunction = () => {
  //   if (username === '' || passwordForm === '') {
  //     setMessage(translate('counseling.msgValidationLoginTask'))
  //     setMessageModalOpen(true)
  //   } else {
  //     setConfirmModalOpen(true)
  //   }
  // }
  const confirmFunction = async () => {
    try {
      await taskLoginInfoMutation({
        accountInput: {
          platformName: platform,
          username: username,
          password: passwordForm,
        },
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId: planId,
      })
      // refetchUserData()
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
    setConfirmModalOpen(false)
  }

  //   useEffect(() => {
  // // sendToReviewFunction()
  // // dispatch({
  // //   type: UPDATE_TASK,
  // //   data: logininfoData,
  // // })
  //   }, [logininfoData])

  useEffect(() => {
    setTaskData(task)
    // eslint-disable-next-line
  }, [task])

  useEffect(() => {
    if (logininfoData) {
      refetchOneTask().then((resp) => {
        setTaskData(resp?.data?.getUserTask)
      })
    }
    // eslint-disable-next-line
  }, [logininfoData])

  return (
    <>
      <TabContent activeTab={loadMutation ? 'loading' : 'show'}>
        <TabPane tabId='loading'>
          <Skeleton height={60} count={3} />
        </TabPane>
        <TabPane tabId='show'>
          <TabContent
            // activeTab={taskData?.status === 'pending' ? 'form' : 'info'}
            activeTab='info'
          >
            <TabPane tabId='info'>
              <InfoTaskButton
                background={infoByStatus[taskData?.status].background}
                colorText={infoByStatus[taskData?.status].colorText}
                task={taskData}
                onClick={() => {
                  openLoginModal(() => {}, taskData)
                }}
                icon={infoByStatus[taskData?.status].icon}
                textLabel={infoByStatus[taskData?.status].text}
              />
              {taskData?.status === 'pending' &&
              !taskData?.resolveTask?.loginInfo ? (
                <SendToReviewButtonOff />
              ) : (
                <ShowButtonByStatus taskData={taskData} />
              )}
            </TabPane>
            {/* <TabPane tabId='form'>
              <div className='d-flex flex-column mx-0 mt-4 mb-0 p-0'>
                <div className='d-flex flex-column mb-5'>
                  <span className='h45 text-gray mb-2'>
                    {translate('counseling.account')}
                  </span>
                  <Input
                    className='znk-input h45 pl-3'
                    type='text'
                    id='platform'
                    name='platform'
                    placeholder='Account name'
                    disabled
                    value={platform}
                  />
                </div>

                <div className='d-flex flex-column mb-5'>
                  <span className='h4 mb-2 text-gray h45'>
                    {translate('counseling.user')}
                  </span>
                  <Input
                    className='h45 pl-3'
                    type='text'
                    // id='user'
                    // name='user'
                    placeholder='username or email'
                    defaultValue={
                      taskData?.resolveTask
                        ? taskData?.resolveTask?.loginInfo?.platform?.username
                        : ''
                    }
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={taskData?.status !== 'pending'}
                  />
                </div>

                <div className='d-flex flex-column mb-5'>
                  <span className='h4 mb-2 text-gray h45'>
                    {translate('counseling.password')}
                  </span>
                  <InputPassword
                    type='password'
                    // id='pass'
                    // name='pass'
                    ref={inputPass}
                    placeholder='password'
                    defaultValue={
                      taskData?.resolveTask
                        ? taskData?.resolveTask?.loginInfo?.platform?.password
                        : ''
                    }
                    onChange={(e) => setPasswordForm(e.target.value)}
                    disabled={taskData?.status !== 'pending'}
                  />
                </div>
                <Button
                  style={{
                    width: '100%',
                    height: '42px',
                    border: '1px solid',
                    borderRadius: '10px',
                    borderColor: '#999bf7',
                    backgroundColor: '#696bff',
                    color: '#ffffff',
                  }}
                  disabled={disabled}
                  className='w-100 mt-3'
                  label={
                    <div className='d-flex justify-content-between mx-n2 my-auto w-100'>
                      <div style={{cursor: 'pointer'}} className='col-10'>
                        <span className='d-flex justify-content-start align-middle'>
                          {task?.buttonLabel || translate('counseling.save')}
                        </span>
                      </div>
                      <div className='col-2 mr-n5 d-flex justify-content-end align-items-center'>
                        <IconArrowRight size={16} />
                      </div>
                    </div>
                  }
                  color='primary'
                  activity={loadOperationTask || loadMutation}
                  onClick={() => validationFunction()}
                />
              </div>
            </TabPane> */}
          </TabContent>
        </TabPane>
      </TabContent>
      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
      <ShowLoginInfoModal />
      <ConfirmModal
        className='m-4'
        isOpen={confirmModalOpen}
        toggle={!confirmModalOpen}
        loading={loadMutation}
        title={titleModal}
        body={bodyModal}
        classNameActionDiv='d-flex flex-column justify-content-end w-80'
        confirmAction={() => {
          confirmFunction()
        }}
        cancelAction={() => setConfirmModalOpen(false)}
        confirmText={translate('counseling.loginInfoConfirmButton')}
        cancelText='Cancel'
      />
    </>
  )
}

export default LoginInfoModal
