import moment from 'moment'

export default function reducer(state, action) {
  switch (action.type) {
    case 'add-phase':
      return {
        ...state,
        phases: state.phases.concat([
          {
            steps: [],
            ...action.phase,
          },
        ]),
      }
    case 'add-step':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (action.step.phaseId === phase.phaseId)
            return {
              ...phase,
              steps: phase.steps.concat([
                {
                  tasks: [],
                  ...action.step,
                  statusBuild: 'new',
                  deleteAt: null,
                },
              ]),
            }
          return phase
        }),
      }
    case 'edit-step':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (action.step.phaseId === phase.phaseId)
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (step.stepId === action.step.stepId)
                  return {
                    ...step,
                    ...action.step,
                    statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                  }
                return step
              }),
            }
          return phase
        }),
      }
    case 'add-task':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (action.task.phaseId === phase.phaseId)
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (action.task.stepId === step.stepId)
                  return {
                    ...step,
                    // statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                    tasks: step.tasks
                      .map((task) => {
                        return {...task, isNew: false}
                      })
                      .concat([
                        {...action.task, statusBuild: 'new', deleteAt: null},
                      ]),
                  }
                return step
              }),
            }
          return phase
        }),
      }
    case 'edit-task':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (action.task.phaseId === phase.phaseId)
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (action.task.stepId === step.stepId) {
                  return {
                    ...step,
                    // statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                    tasks: step.tasks.map((task) => {
                      const {phaseId, stepId, ...editTask} = action.task
                      if (task.taskId === action.task.taskId) {
                        return {
                          ...task,
                          ...editTask,
                          statusBuild:
                            task?.statusBuild === 'new' ? 'new' : 'edit',
                        }
                      }
                      return task
                    }),
                  }
                }
                return step
              }),
            }
          return phase
        }),
      }
    case 'remove-step':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (phase.phaseId === action.step.phaseId)
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (step.stepId === action.step.stepId)
                  return {
                    ...step,
                    deleteAt: moment().toISOString(),
                    statusBuild: 'delete',
                  }
                return step
              }),
            }
          return phase
        }),
      }

    case 'remove-task':
      return {
        ...state,
        phases: state.phases.map((phase) => {
          if (phase.phaseId === action.task.phaseId) {
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (step.stepId === action.task.stepId) {
                  return {
                    ...step,
                    // statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                    tasks: step.tasks.map((task) => {
                      if (task.taskId === action.task.taskId)
                        return {
                          ...task,
                          deleteAt: moment().toISOString(),
                          statusBuild: 'delete',
                        }
                      return {...task, isNew: false}
                    }),
                  }
                }
                return step
              }),
            }
          }
          return phase
        }),
      }
    case 'set-data':
      return {
        ...state,
        ...action.data,
      }
    case 'move-task':
      let auxState = state
      const {taskId, oldStepId, newStepId, newIndex} = action.task
      let oldPhaseId
      let newPhaseId
      // arreglo contiene todas las tasks
      const tasks = state.phases.reduce((arr, {steps, phaseId}) => {
        return arr.concat(
          steps.reduce((arr, {tasks, stepId}) => {
            if (stepId === oldStepId) oldPhaseId = phaseId
            if (stepId === newStepId) newPhaseId = phaseId
            return arr.concat(
              tasks.map((task) => {
                return {...task, isNew: false}
              }),
            )
          }, []),
        )
      }, [])
      let task = tasks.find(({taskId: taskIdI}) => taskId === taskIdI)
      // marca la task como moved
      if (task) task = {...task, statusBuild: 'moved'}
      auxState = {
        ...auxState,
        phases: auxState.phases.map((phase) => {
          if (phase.phaseId === oldPhaseId) {
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (step.stepId === oldStepId) {
                  return {
                    ...step,
                    // statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                    tasks: step.tasks.filter(
                      ({taskId: auxTaskId}) => auxTaskId !== taskId,
                    ),
                  }
                }
                return step
              }),
            }
          }
          return phase
        }),
      }
      auxState = {
        ...auxState,
        phases: auxState.phases.map((phase) => {
          if (phase.phaseId === newPhaseId) {
            return {
              ...phase,
              steps: phase.steps.map((step) => {
                if (step.stepId === newStepId) {
                  let newTasks = step.tasks
                  if (newTasks?.length === 0) newTasks = [task]
                  else if (newIndex === newTasks?.length)
                    newTasks = newTasks.concat([task])
                  else {
                    newTasks = newTasks.reduce((newArray, taskAux, index) => {
                      if (index === newIndex) newArray.push(task)
                      newArray.push(taskAux)
                      return newArray
                    }, [])
                  }
                  return {
                    ...step,
                    // statusBuild: step?.statusBuild !== 'new' ? 'edit' : 'new',
                    tasks: newTasks,
                  }
                }
                return step
              }),
            }
          }
          return phase
        }),
      }
      return {
        ...auxState,
      }
    default:
      throw new Error()
  }
}
