import React, {useEffect, useState, useMemo} from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
// import {showAlert} from '../Alert/util'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import escapeRegExp from 'lodash/escapeRegExp'

// Despliega modal de confirmación que obliga al usuario a redireccionar
// a localUrl
const RedirectToLocalUrl = (props) => {
  const {title, body, actionText, localUrl, show} = props
  const history = useHistory()
  const location = useLocation()
  // usuario está en la pagina a la que redirecciona el modal
  // si es asi el modal no se muestra
  const isOnUrl = useMemo(() => {
    if (localUrl?.split('?')?.length > 0) {
      return (
        !!new RegExp(escapeRegExp(localUrl?.split('?')[0]), 'i').exec(
          location?.pathname,
        ) &&
        !!new RegExp(escapeRegExp(`?${localUrl?.split('?')[1]}`), 'i').exec(
          location?.search,
        )
      )
    } else {
      return !!new RegExp(escapeRegExp(localUrl), 'i').exec(location?.pathname)
    }
  }, [localUrl, location.pathname, location.search])
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }
  useEffect(() => {
    if (!isOnUrl && show) setOpen(true)
    else setOpen(false)
    // showAlert({
    //    text: "No hay método de pago registrado",
    //    status: 'error'
    // }, dispatch)
  }, [dispatch, isOnUrl, show])
  return (
    <ConfirmModal
      className=''
      classNameActionDiv=''
      classNameConfirm=''
      classNameCancel=''
      isOpen={isOpen}
      toggle={toggle}
      title={title}
      body={body}
      confirmAction={() => {
        history.push(localUrl)
        toggle()
      }}
      cancelAction={() => {}}
      confirmText={actionText}
      cancelText={null}
      disabledCancel
      backdrop={[false, 'static']}
    />
  )
}

export default RedirectToLocalUrl
