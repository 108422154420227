import React, {useMemo} from 'react'
import {ProcessItem, IconMessages} from '@Knowledge-OTP/znk-ui-components'
import useTemplateReducer from '../../../../../../../reducers/template'

const ApplicationStatus = (props) => {
  const {className, plan} = props
  const {getCurrentStep} = useTemplateReducer()
  const currentStep = useMemo(() => {
    return plan?.tasks ? getCurrentStep(plan.tasks) : null
  }, [plan.tasks, getCurrentStep])

  return (
    <div className={`d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Application Status</span>
      <ProcessItem
        className='w-90 mt-2'
        onClick={() => {}}
        icon={<IconMessages />}
        title={currentStep?.phaseName || 'No defined'}
        content={currentStep?.stepName || 'No defined'}
        status='scheduled'
      />
    </div>
  )
}

export default ApplicationStatus
