export default {
  modalTitle: 'Change notification settings',
  lessonReminder: 'Lesson reminder',
  lessonReminderDesc:
    'We will send you a Whatsapp message before starting a lesson.',
  missedLessons: 'Missed lesson',
  missedLessonsDesc:
    'We will send you a Whatsapp message whenever you miss a lesson.',
  lessonNotesReady: 'Lesson Notes Ready',
  lessonNotesReadyDesc:
    'We will send you a Whatsapp message whene lesson notes are ready.',
  reminderSet: 'Reminder set',
  reminderLessonNotesSet: "When it's ready",
  reminderSetLessonReminder:
    'Here you can modify when you want to be reminded when an upcoming lesson is starting',
  reminderSetLessonNotes:
    'Here you can modify if you want to be notified when an Educator uploads the Lesson Notes of an attended Lesson',
  reminderSetMissedLessons:
    'Here you can modify if you want to be notified when you miss a lesson',
  reminderRemove: 'Remove reminder',
  reminderActivate: 'Activate reminder',
  reminderChange: 'Change reminder',
  reminderMinutes: (num) => `${num} min before`,
}
