import {useLazyQuery, gql} from '@apollo/client'

export const useGetUniversitiesById = (
  onSuccess = () => {},
  onError = () => {},
) => {
  // Sirve para traer una lesson en particular.
  return useLazyQuery(
    gql`
      query($universityIds: [ID!] = []) {
        getUniversitiesById(universityIds: $universityIds) {
          id
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError,
    },
  )
}
