import React from 'react'

import {
  TaskCompletedButton,
  InReviewButton,
  SendToReviewButton,
} from './Buttons'

const ShowButtonByStatus = (props) => {
  const {taskData, filesUploadedByUser, completionLoading = false} = props

  return (
    <div className='mt-4'>
      {/* Se muestra el botón según el status de la task */}
      {taskData?.status === 'pending' ? (
        <SendToReviewButton
          className='d-flex justify-content-center align-items-center'
          task={taskData}
          completionLoading={completionLoading}
          filesUploadedByUser={filesUploadedByUser}
        />
      ) : taskData?.status === 'review' ? (
        <>
          <InReviewButton task={taskData} />
        </>
      ) : taskData?.status === 'completed' ? (
        <>
          <TaskCompletedButton task={taskData} />
        </>
      ) : null}
    </div>
  )
}

export default ShowButtonByStatus
