const queryInvoiceResult = `
id
userId {
  id
}
billedUserId
processedTimes
createdAt
updatedAt
lastProcessedAt
from
to
status
category
paymentItems {
  ... on PlanPaymentItem {
    id
    amount
    draft
    status
    lastProcessedAt
    description
    category
  }
  ... on CustomPaymentItem {
    id
    amount
    draft
    status
    lastProcessedAt
    description
    category
  }
  ... on LessonPaymentItem {
    id
    amount
    draft
    status
    lastProcessedAt
    description
    category
  }
  ... on EducatorPaymentItem {
    id
    amount
    draft
    status
    lastProcessedAt
    description
    category
  }
}
`

export default queryInvoiceResult
