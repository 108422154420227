import React, {useEffect} from 'react'
import {gql, useLazyQuery} from '@apollo/client'
import {useLocation} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import StudentProfile from '../index'

const RedirectToStudentModal = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const roles = useSelector((state) => state.user.info.roles)
  const studentUserId = searchParams.get('studentUserId')
  const dispatch = useDispatch()
  const [verifyUser] = useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'student.infoStudentModal',
          data: {
            isOpen: true,
            dataStudent: {id: studentUserId},
            initPage: 'summary',
            full: false,
            profilePage: 'viewProfile',
          },
        })
      },
      onError: (err) => {
        console.error(err.toString())
      },
    },
  )
  useEffect(() => {
    if (studentUserId && verifyUser && roles.includes('EDUCATOR')) {
      verifyUser({
        variables: {
          id: studentUserId,
        },
      })
    }
    // eslint-disable-next-line
  }, [studentUserId, verifyUser])

  return <StudentProfile />
}

export default RedirectToStudentModal
