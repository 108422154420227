import React from 'react'
import DocsAndExamsComponent from '../../../../Workspace/Student/DocsAndExams/DocComponent'
import DocsAndExamsFilter from '../../../../Workspace/Student/DocsAndExams/Filter'
const DocumentsTab = () => {
  return (
    <div className='mt-2'>
      <DocsAndExamsFilter />
      <DocsAndExamsComponent />
    </div>
  )
}

export default DocumentsTab
