// Recibe el array que se pasa en el VirtualChat, que es un array
// y les setea los IDs

export const setIdToUsers = (usersList) => {
  if (!Array.isArray(usersList)) return []
  const usersWithId = []
  if (usersList?.length < 1) return
  usersList.map((user) =>
    usersWithId.push({
      ...user,
      id:
        user?.__typename === 'LessonEducator'
          ? user?.educatorUserId
          : user?.studentUserId,
    }),
  )

  return usersWithId
}
