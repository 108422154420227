import {useCallback, useState} from 'react'
import {gql, useMutation} from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
      mutation update($updatedUser: UpdateUserInput!, $userId: ID!) {
        updateUser(input: $updatedUser, id: $userId) {
          id
          isActive
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
          }
          parentInfo {
            children {
              studentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
          }
          educatorInfo {
            id
            defaultRate
            services {
              serviceId
              name
              defaultRate
              topics {
                topicId
                teachingTypes
                name
              }
            }
            backupLink
          }
          roles
        }
      }
    `,
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback(
    (obj) => {
      const errors = validate(obj)
      if (errors && Object.keys(errors).length) {
        console.error(errors)
        setValidationErrors(errors)
        onError('validation Error')
        return
      }
      delete obj.updatedUser.phoneDialCode
      delete obj.updatedUser.phoneNumber
      delete obj.updatedUser.birthYear
      delete obj.updatedUser.birthMonth
      delete obj.updatedUser.birthDay
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          setValidationErrors({general: 'misc.apiError'})
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    data: data ? data.updateUser : null,
    error,
    validationErrors,
    submit,
  }
}
