import React, {useEffect} from 'react'
import {Activity} from '@Knowledge-OTP/znk-ui-components'
import {Button} from 'reactstrap'
import {useLazyQuery, gql} from '@apollo/client'
import {hasHttp} from '../../../../CalendarLessons/modal/utils'
import useTranslation from '../../../../../i18n/useTranslation'
import './index.css'

const BackupLinkButton = (props) => {
  const {className, educatorId} = props
  const {translate} = useTranslation()
  const [getBackUpLink, {data, loading, error}] = useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          educatorInfo {
            backupLink
          }
        }
      }
    `,
    {fetchPolicy: 'no-cache'},
  )
  useEffect(() => {
    if (data?.user?.educatorInfo?.backupLink) {
      window.open(hasHttp(data.user.educatorInfo.backupLink), '_blank')
    }
  }, [data])
  useEffect(() => {
    console.error(error)
  }, [error])
  return (
    <Button
      className={`${className} backup-link-button`}
      onClick={() => {
        getBackUpLink({variables: {id: educatorId}})
      }}
    >
      {loading ? (
        <div className='w-100 d-flex flex-row justify-content-center'>
          <Activity inverse />
        </div>
      ) : (
        <span className='text-center'>
          {translate('modal.infolesson.backupcall')}
        </span>
      )}
    </Button>
  )
}

export default BackupLinkButton
