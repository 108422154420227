import React from 'react'
import {LayoutPayment} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'

const FullPayments = () => {
  const {translate} = useTranslation()
  return (
    <LayoutPayment
      textTop={translate('profile.payments.topTitle')}
      textBottom={translate('profile.payments.botTitle')}
    />
  )
}

export default FullPayments
