import React from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import PlanModal from '../modal'
import Skeleton from 'react-loading-skeleton'
import CounselingPlanCard from '../Section/CounselingPlanCard'
import {Notification} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import {useHistory} from 'react-router'

const PlansViewScroll = (props) => {
  const {translate} = useTranslation()
  const history = useHistory()
  const {plans, sentPlan, loading} = props
  return (
    <>
      <TabContent activeTab={loading ? `loading` : `show`}>
        <TabPane tabId='loading'>
          <Skeleton height={112} style={{borderRadius: '12px'}} />
        </TabPane>
        <TabPane tabId='show'>
          {sentPlan && sentPlan.length > 0 && (
            <Notification
              label={translate('program.plan.plantoapprove')}
              number={1}
              className='mb-3'
              onClick={() => {
                history.push(`/onboarding/${sentPlan[0].id}`)
              }}
            />
          )}
          <div className='d-flex flex-row m-0 p-0 znk-scroll-horizontal-v2 znk-scroll-horizontal-planCard'>
            {plans ? (
              plans.map((item) => {
                if (item.kind === 'counceling')
                  return <CounselingPlanCard className='mr-2' plan={item} />
                const totalLesson =
                  item.kind === 'lessonsFixed'
                    ? item.lessonPerTopic.reduce((total, lesson) => {
                        return lesson.totalLessons + total
                      }, 0)
                    : item.parts.reduce((total, lesson) => {
                        return lesson.lessonsPerWeek + total
                      }, 0)
                const totalPlan =
                  item.kind === 'lessonsFixed' &&
                  item.lessonPerTopic.reduce((isTotal, lesson) => {
                    return isTotal || lesson.type === 'totalLesson'
                  }, false)

                return (
                  <div className='mr-2' key={item.id}>
                    <PlanModal
                      info={item}
                      status={item.status}
                      isTotalLessonsPlan={item.isTotalLessonsPlan}
                      lessonsPerWeek={
                        totalPlan
                          ? translate('program.plan.totalLessons', totalLesson)
                          : translate(
                              'program.plan.lessonsPerWeek',
                              totalLesson,
                            )
                      }
                    />
                  </div>
                )
              })
            ) : (
              <Skeleton height={100} />
            )}
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}

export default PlansViewScroll
