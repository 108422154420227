import React, {useEffect, useState, useMemo} from 'react'
import {
  YoutubeCard,
  YoutubeAccount,
  useWindowDimensions,
  Carousel,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import SubscriberButton from './Components/Subscriber'

const YOUTUBE_URL_SEARCH = 'https://www.googleapis.com/youtube/v3/search'
const YOUTUBE_URL_CHANNEL = 'https://www.googleapis.com/youtube/v3/channels'
const TOTALVIDEOS = 5

export async function GetYoutubeVideos() {
  const KEY = process.env.REACT_APP_YOUTUBE_API_KEY || 'x'
  const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID

  const res = await fetch(
    `${YOUTUBE_URL_SEARCH}?order=date&part=id,snippet&maxResults=${TOTALVIDEOS}&channelId=${CHANNEL_ID}&key=${KEY}`,
  )
  const resChannel = await fetch(
    `${YOUTUBE_URL_CHANNEL}?part=id,snippet,statistics&maxResults=1&id=${CHANNEL_ID}&key=${KEY}`,
  )

  const data = await res.json()
  const dataChannel = await resChannel.json()

  const channel = dataChannel.items?.find(
    ({kind}) => kind === 'youtube#channel',
  )
  const channelAvatar = channel?.snippet?.thumbnails?.high?.url
  const channelName = channel?.snippet?.title
  const channelId = channel?.id
  return {
    props: {
      data: {
        ...data,
        items: (data?.items || [])?.filter(
          ({id}) => id?.kind === 'youtube#video',
        ),
        channel,
        channelAvatar,
        channelName,
        channelId,
      },
    },
  }
}

const YoutubeGidget = () => {
  const [data, setData] = useState(null)
  const {breakWidth} = useWindowDimensions()

  useEffect(() => {
    const cacheName = 'youtubeGidgetData'
    let cacheData = global.localStorage.getItem(cacheName)
    cacheData = JSON.parse(cacheData)
    if (cacheData && moment(cacheData?.expiredAt) > moment()) {
      setData(cacheData)
    } else {
      // console.log("storage PETICION ****")
      GetYoutubeVideos().then(
        (data) => {
          const cache = data?.props?.data
          if (!cache?.error) {
            // petición exitosa
            const cacheString = JSON.stringify({
              ...cache,
              expiredAt: moment().add(6, 'hours').toISOString(),
            })
            global.localStorage.setItem(cacheName, cacheString)
            setData(cache)
          }

          // SI NO ACTUALIZA LA DATA DEL CACHE VOLVERÁ
          // A EJECUTAR LA PETICIÓN EN CADA ACTUALIZACIÓN
          // HASTA QUE RECIBA UNA RESPUESTA
        },
        (errors) => {
          console.error(errors.toString())
        },
      )
    }
  }, [])

  const [isSubscriber, setIsSubscriber] = useState(false)
  const [subscribersCount, setSubscribersCount] = useState(0)
  const subscribers = useMemo(
    () =>
      parseInt(data?.channel?.statistics?.subscriberCount || 0) +
      parseInt(subscribersCount),
    [subscribersCount, data],
  )

  return (
    <>
      <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
        <YoutubeAccount
          className='cursor-pointer'
          onClick={() => {
            window.open(
              `https://www.youtube.com/channel/${data?.channelId}`,
              '__branch',
            )
          }}
          channelAvatar={
            data?.channelAvatar ||
            'https://yt3.ggpht.com/JI1bLZZm4-H1cl-n89dbw3weVI8K8v72k5S_lVVE_v3D46zt--UW81hCwMSMwRZnD4E2goIb=s800-c-k-c0x00ffffff-no-rj'
          }
          title={data?.channelName || `Zinkerz Prep`}
          subtitle={`${subscribers} subscribers`}
        />
        <div>
          <SubscriberButton
            onSubscriber={(bool, newSubscribersCount = 0) => {
              setSubscribersCount(newSubscribersCount)
              setIsSubscriber(bool)
            }}
            isSubscriber={isSubscriber}
          />
        </div>
      </div>
      <Carousel
        itemsPerPage={breakWidth === 'SM' ? 1 : 2}
        items={(data?.items || []).map((props, index) => {
          return {
            ...props,
            onClick: (props) => {
              const videoId = props?.id?.videoId
              if (videoId)
                window.open(
                  `https://www.youtube.com/watch?v=${videoId}`,
                  '__blank',
                )
            },
            mode: 'fixed',
            channelName: data?.channelName || `Zinkerz Prep`,
            channelAvatar: data?.channelAvatar || null,
          }
        })}
        ItemComponent={YoutubeCard}
      />
    </>
  )
}

export default YoutubeGidget
