import React, { useState } from 'react'
import {
  Button,
  IconClose,
  IconArrowRight,
  IconIllustratedLesson,
  Avatar,
  ListItem,
  IconSignMinus,
  DropDown,
  IconOptions,
  IconArrowDown,
  IconArrowUp,
  IconPenalize,
  Countdown,
  IconWspAlert,
  IconSignCheck,
  IconSignPlus,
  Activity,
  IconComment,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import { Badge, Collapse } from 'reactstrap'
import { useDispatch } from 'react-redux'
import useLinkLesson from '../useLinkLesson'
import useTranslation from '../../../../../i18n/useTranslation'
import sendLessonAttendance from '../../../../../Model/Lessons/SendLessonAttendance'
import { hasHttp } from '../../utils'
import CheckInLesson from '../../../../../Model/Lessons/CheckInLesson/index'
import { showAlert } from '../../../../../common/Alert/util'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import BackupLinkButton from '../Components/BackupLinkButton'
import PopupBackupLink from '../Components/PopUpBackUpLink'
import WasSubstitutedMsg from '../../../Components/WasSubstitutedMsg'
import VirtualClassroomButton from '../Components/VirtualClassroomButton'
const InProgressHeaderModal = (props) => {
  const { dataLesson, toggle } = props
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }

  const { translate } = useTranslation()
  const makeup = dataLesson?.isMakeUp || false
  const madeup = dataLesson?.makeUpInfo !== null || false

  const penalizationInfo = dataLesson?.penalizationInfo

  const topic =
    dataLesson.groupTopic ||
    dataLesson.individualTopic ||
    dataLesson.planTopic ||
    {}
  const service = dataLesson.service

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus className='ml-auto text-cancel' />
        <IconClose className='ml-auto text-cancel' onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <IconIllustratedLesson size={80} />
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <h2 className='my-1'>
            <b>{dataLesson && service && `${dataLesson.title}`}</b>
          </h2>
          <h4 className='my-1'>
            {dataLesson &&
              dataLesson.startDate &&
              dataLesson.endDate &&
              `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(
                dataLesson.endDate,
              ).format('hh:mm a')} | ${separateCamelCase(
                moment(dataLesson.startDate).format('MMM'),
              )} ${moment(dataLesson.startDate).format('DD, YYYY')}`}
          </h4>
          <h5 className='h45 my-1 pr-2'>
            {dataLesson &&
              dataLesson.__typename &&
              service &&
              `${separateCamelCase(service.name)} | ${separateCamelCase(
                topic?.name,
              )} | ${separateCamelCase(
                dataLesson?.location?.type || 'No defined',
              )} | ${separateCamelCase(
                dataLesson.__typename?.replace('Lesson', ''),
              )}`}
          </h5>
          <div className='d-flex flex-row m-0 p-0'>
            {makeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.makeup')}
              </Badge>
            )}
            {madeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.madeup')}
              </Badge>
            )}
            {penalizationInfo && (
              <Badge
                className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                  <DropDown
                    className='d-flex flex-row align-items-center'
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component: (
                          <div className='d-flex flex-row h45 px-2 py-1'>
                            <div
                              title={translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                              className='text-title mr-3 ellipsis-text'
                              style={{ width: '124px' }}
                            >
                              {translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                            </div>
                            <div className='text-title'>{`-$${parseFloat(
                              penalizationInfo?.amount,
                            ).toFixed(2)}`}</div>
                          </div>
                        ),
                      },
                    ]}
                  >
                    <IconPenalize color='red' size={15} />
                  </DropDown>
                </div>
              </Badge>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const InProgressBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    // createdDate,
    // updateDate,
    RenderStudents,
    setDataLessonToRedux,
    // educatorAttendanceState,
    studentAttendanceState,
    checkAttendanceChanges,
    resetAttendance,
    // addSubstituteMutation,
    // setStatusOfInfoLessonState,
    // refetchCalendarQuery
    studentOptions,
    setTabValue,
    wasSubstituted,
  } = props

  // const [educatorAttendance, setEducatorAttendance] = educatorAttendanceState
  const [studentAttendance, setStudentAttendace] = studentAttendanceState
  const [backupLinkOpen, setBackupLinkOpen] = useState(false)

  const [setAttendanceMutation, { loading }] = sendLessonAttendance(
    (result) => {
      setDataLessonToRedux(result.data.sendLessonAttendance)
      showAlert(
        {
          text: translate('modal.infolesson.saveAttendanceSuccess'),
          status: 'success',
        },
        dispatch,
      )
    },
    (error) => {
      console.error(error)
      showAlert(
        {
          text: translate('modal.infolesson.saveAttendanceError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const { translate } = useTranslation()

  // const [substituteEducator, setSubstituteEducator] = useState(null)
  const dispatch = useDispatch()

  // useEffect(() => {
  //  if (dataLesson?.substitute) setSubstituteEducator(dataLesson?.substitute)
  //  else setSubstituteEducator(null)
  // }, [dataLesson])

  // const lessonPast = dataLesson?.endDate ? moment() >= moment(dataLesson?.endDate) : false

  // const rateStudent = Array.isArray(student)
  //  ? `$${student.reduce((minRate, stu) => {
  //    if (minRate > stu?.rate) return stu?.rate
  //    return minRate
  //  }, Number.POSITIVE_INFINITY).toFixed(2)} ~
  //  $${student.reduce((maxRate, stu) => {
  //    if (maxRate < stu?.rate) return stu?.rate
  //    return maxRate
  //  }, Number.NEGATIVE_INFINITY).toFixed(2)}`
  //  : (student) && `$${(student?.rate || 0).toFixed(2)}`
  // const ignorePaymentStudent = Array.isArray(student)
  //  ? false
  //  : student?.ignorePaymentItem
  // const makeup = dataLesson?.isMakeUp || false

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }
  const penalizationInfo = dataLesson?.penalizationInfo

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)

  const { submit: checkIn, loading: loadCheckIn } = CheckInLesson(
    ({ data }) => {
      // actualizar attendance
      setDataLessonToRedux(data?.checkInLesson || dataLesson)
    },
    (err) => {
      showAlert(
        {
          text: err.toString(),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const isCheckIn = educator?.attendanceStatus === 'attended'

  return (
    <div className='d-flex flex-column m-0 p-0 justify-content-between h-100'>
      <div className='m-0 p-0 d-flex flex-column'>
        {/** <div className='d-flex flex-row justify-content-between my-2'>
          <span className='font-weight-bold'>{translate('modal.infolesson.price')}</span>
          <span className={`font-weight-bold ${ignorePaymentStudent ? 'text-gray' : ''}`} style={ignorePaymentStudent ? { textDecoration: 'line-through' } : {}}>{rateStudent}</span>
        </div>**/}
        <div
          className={`d-flex flex-row justify-content-between my-2 ${!penalizationInfo ? 'mb-6' : ''
            }`}
        >
          <span className='font-weight-bold'>
            {translate('modal.newlesson.educatorwage')}
          </span>
          <span
            className={`font-weight-bold ${educator?.ignorePaymentItem ? 'text-gray' : ''
              }`}
            style={
              educator?.ignorePaymentItem
                ? { textDecoration: 'line-through' }
                : {}
            }
          >{`$${educator && educator.rate.toFixed(2)}`}</span>
        </div>
        {penalizationInfo && (
          <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
            <span className='text-error'>
              {translate('modal.penalizelesson.penalty')}
            </span>
            <span className='text-error'>{`-$${penalizationInfo?.amount && penalizationInfo.amount.toFixed(2)
              }`}</span>
          </div>
        )}
        <div className='m-0 p-0 d-flex flex-column fragment-students-educator'>
          {!wasSubstituted && (
            <Collapse isOpen={checkAttendanceChanges}>
              <ListItem
                showTopLine
                showBottomLine={false}
                Left={
                  <span
                    className='btn-link text-error h45'
                    onClick={resetAttendance}
                  >
                    {loading ? <Activity /> : 'Reset'}
                  </span>
                }
                Right={
                  <span
                    className='btn-link h45'
                    onClick={() => {
                      setAttendanceMutation({
                        lessonId: dataLesson?.id,
                        educatorAttendance: 'attended',
                        attendance: Object.entries(studentAttendance).reduce(
                          (arrayAttendance, [idStudent, atte]) => {
                            arrayAttendance.push({
                              studentId: idStudent,
                              status: atte,
                            })
                            return arrayAttendance
                          },
                          [],
                        ),
                      })
                    }}
                  >
                    {loading ? (
                      <Activity />
                    ) : (
                      translate('modal.infolesson.saveAttendance')
                    )}
                  </span>
                }
              />
            </Collapse>
          )}
          <ListItem
            showTopLine
            showBottomLine={collapseGroupStudent || true}
            Left={<RenderStudents student={student} />}
            Right={
              Array.isArray(student) ? (
                <div
                  className='hover-icon py-1 px-2'
                  onClick={toggleCollapseGroupStudent}
                >
                  {collapseGroupStudent ? <IconArrowUp /> : <IconArrowDown />}
                </div>
              ) : (
                <div className='m-0 p-0 d-flex flex-row'>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick={false}
                      options={[
                        {
                          component: (
                            <div className='d-flex flex-row m-0 py-0 px-1'>
                              <div
                                className='hover-icon'
                                onClick={
                                  !wasSubstituted
                                    ? () => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: 'missed',
                                      })
                                    }
                                    : () => { }
                                }
                              >
                                <IconSignPlus color='red' />
                              </div>
                              <div
                                className='hover-icon'
                                onClick={
                                  !wasSubstituted
                                    ? () => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: 'pending',
                                      })
                                    }
                                    : () => { }
                                }
                              >
                                <IconSignMinus color='#72809D' />
                              </div>
                              <div
                                className='hover-icon'
                                onClick={
                                  !wasSubstituted
                                    ? () => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: 'attended',
                                      })
                                    }
                                    : () => { }
                                }
                              >
                                <IconSignCheck color='#8FC813' />
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    >
                      <div className='hover-icon py-1 px-2'>
                        {studentAttendance[student.studentUserId] ===
                          'missed' ? (
                          <IconSignPlus color='red' />
                        ) : studentAttendance[student.studentUserId] ===
                          'attended' ? (
                          <IconSignCheck color='#8FC813' />
                        ) : (
                          <IconSignMinus color='#72809D' />
                        )}
                      </div>
                    </DropDown>
                  </div>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick
                      options={studentOptions(student)}
                    >
                      <div className='hover-icon py-1 px-2'>
                        <IconOptions style={{ transform: 'rotate(90deg)' }} />
                      </div>
                    </DropDown>
                  </div>
                </div>
              )
            }
          />
          <Collapse isOpen={collapseGroupStudent}>
            {Array.isArray(student) &&
              student.map((stu) => {
                return (
                  <div>
                    <ListItem
                      className='py-2'
                      Left={
                        <div className='d-flex flex-row my-0 py-0 mr-7'>
                          <Avatar
                            name={stu && `${stu.firstName} ${stu.lastName}`}
                            named={false}
                            uri={null}
                            innerDiv
                            size='avatar-small mr-1'
                          />
                          <div
                            className='h45 align-self-center text-dark'
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {stu && `${stu.firstName} ${stu.lastName} `}
                          </div>
                        </div>
                      }
                      Right={
                        <div className='d-flex flex-row align-items-center'>
                          {!wasSubstituted && (
                            <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                              <DropDown
                                direction='bottom'
                                fixedWidth
                                hoverEnabled={false}
                                closeMenuWhenClick={false}
                                options={[
                                  {
                                    component: (
                                      <div className='d-flex flex-row m-0 py-0 px-1'>
                                        <div
                                          className='hover-icon'
                                          onClick={() => {
                                            setStudentAttendace({
                                              ...studentAttendance,
                                              [stu.studentUserId]: 'missed',
                                            })
                                          }}
                                        >
                                          <IconSignPlus color='red' />
                                        </div>
                                        <div
                                          className='hover-icon'
                                          onClick={() => {
                                            setStudentAttendace({
                                              ...studentAttendance,
                                              [stu.studentUserId]: 'pending',
                                            })
                                          }}
                                        >
                                          <IconSignMinus color='#72809D' />
                                        </div>
                                        <div
                                          className='hover-icon'
                                          onClick={() => {
                                            setStudentAttendace({
                                              ...studentAttendance,
                                              [stu.studentUserId]: 'attended',
                                            })
                                          }}
                                        >
                                          <IconSignCheck color='#8FC813' />
                                        </div>
                                      </div>
                                    ),
                                  },
                                ]}
                              >
                                <div className='hover-icon py-1 px-2'>
                                  {studentAttendance[stu.studentUserId] ===
                                    'missed' ? (
                                    <IconSignPlus color='red' />
                                  ) : studentAttendance[stu.studentUserId] ===
                                    'attended' ? (
                                    <IconSignCheck color='#8FC813' />
                                  ) : (
                                    <IconSignMinus color='#72809D' />
                                  )}
                                </div>
                              </DropDown>
                            </div>
                          )}
                          <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                            <DropDown
                              direction='bottom'
                              fixedWidth
                              hoverEnabled={false}
                              closeMenuWhenClick
                              options={studentOptions(stu)}
                            >
                              <div className='hover-icon py-1 px-2'>
                                <IconOptions
                                  style={{ transform: 'rotate(90deg)' }}
                                />
                              </div>
                            </DropDown>
                          </div>
                        </div>
                      }
                    />
                  </div>
                )
              })}
            <div className='borderTop' />
          </Collapse>
        </div>
        <WasSubstitutedMsg wasSubstituted={wasSubstituted} />
        {!wasSubstituted && (
          <div className='d-flex flex-row mt-6'>
            {isCheckIn ? (
              <>
                <BackupLinkButton
                  className='w-50 mr-1'
                  educatorId={
                    dataLesson?.substitute?.educatorUserId ||
                    dataLesson?.educator?.educatorUserId
                  }
                  backupPopupImplementation
                  clickButton={() => setBackupLinkOpen(true)}
                />
                <VirtualClassroomButton
                  locationType={dataLesson.location.type}
                  clickButton={() => {
                    // si location es VCR es launchURL, sino es location.linkStudent!
                    setGoToClassLoad(true)
                    getvclink(dataLesson?.id, null)
                      .then(
                        ({ launchUrl, location }) => {
                          if (
                            location.type === 'virtualClassroom' ||
                            location.type === 'zinkerzPlatform'
                          )
                            window.open(hasHttp(launchUrl), '_blank')
                          else
                            window.open(hasHttp(location.linkStudent), '_blank')
                          setGoToClassLoad(false)
                        },
                        (error) => {
                          showAlert(
                            {
                              text: translate('modal.infolesson.educatorErrorClassroom'),
                              status: 'error',
                            },
                            dispatch,
                          )
                          console.error(error)
                          // setVcLinkError(true)
                          setGoToClassLoad(false)
                        },
                      )
                      .catch((error) => {
                        console.error(error)
                        // setVcLinkError(true)
                        setGoToClassLoad(false)
                      })
                  }}
                  label={
                    goToClassLoad ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <span className='text-center'>
                        {translate('modal.infolesson.gotoclassroom')}
                      </span>
                    )
                  }
                  onClickResetVC={() => {
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'calendar.setBraincertToLesson',
                      data: {
                        isOpen: true,
                        lessonId: dataLesson.id,
                      },
                    })
                  }}
                />
              </>
            ) : (
              <Button
                style={{ backgroundColor: '#72809d' }}
                className='w-100 my-0'
                size='large'
                onClick={() => {
                  checkIn({ lessonId: dataLesson?.id || '' })
                }}
                label={
                  loadCheckIn ? (
                    <div className='w-100 d-flex flex-row justify-content-center'>
                      <Activity inverse />
                    </div>
                  ) : (
                    <div className='d-flex flex-row justify-content-between'>
                      <span>{translate('modal.infolesson.checkin')}</span>
                      <IconArrowRight />
                    </div>
                  )
                }
              />
            )}
          </div>
        )}
        <div
          className='zkn-btn znk-size-small w-100 my-6 btn'
          style={{ backgroundColor: '#F0F1FF' }}
        >
          <div className='d-flex flex-row justify-content-between'>
            <span className='h45 text-primary'>
              {translate('modal.infolesson.startat')}
            </span>
            <Countdown
              endTime={
                dataLesson?.startDate ? moment(dataLesson?.startDate) : moment()
              }
              iconColor='#696CFF'
              timeOverLabel={translate('modal.infolesson.timeisover')}
              spanTimeClassName='h45 text-primary'
              spanSecondsClassName='h45 text-primary'
            />
          </div>
        </div>
        {/** <ListItem
          showTopLine
          className='py-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'calendar.sendEmailReminder',
              data: {
                isOpen: true,
                dataLesson,
                studentsId: [],
                educatorId: null,
              },
            })
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconEmail color='#696CFF' className='align-self-center mr-2' />
              <span className='align-self-center text-primary'>
                {translate('modal.infolesson.sendreminder')}
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />**/}
        {!wasSubstituted && (
          <ListItem
            showTopLine
            showBottomLine
            className='py-4'
            onClick={() => {
              dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.wspAlert',
                data: {
                  isOpen: true,
                  dataLesson: dataLesson,
                  studentsId: Array.isArray(student)
                    ? student?.map((stu) => stu?.id || stu?.studentUserId) || []
                    : [student?.id || student?.studentUserId],
                  educatorId: null,
                },
              })
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconWspAlert
                  color='#696CFF'
                  className='align-self-center mr-2'
                />
                <span className='align-self-center text-primary'>
                  {translate('modal.infolesson.sendwspalert')}
                </span>
              </div>
            }
            Right={<IconArrowRight />}
          />
        )}

        {/* <ToolTipInfo
          className='m-0 p-0'
          innerElement={
            <span>
              {translate('student.infolesson.options.viewMessageNotif')}
            </span>
          }
          showOneTime
          place='bottom'
          effect='solid'
          delayToShow={2000}
          tooltipId='newChatAddOn'
          onClick={() => {
            setTabValue('chat')
          }}
        > */}
        {!wasSubstituted && (
          <ListItem
            onClick={() => {
              setTabValue('chat')
            }}
            showTopLine
            showBottomLine
            className='py-4'
            Left={
              <div className='d-flex flex-row'>
                <IconComment
                  color='#696CFF'
                  className='align-self-center mr-2'
                />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.chatStudent')}
                </span>
              </div>
            }
            Right={<IconArrowRight color='#696CFF' />}
          />
        )}
        {/* </ToolTipInfo> */}
      </div>
      {!wasSubstituted && (
        <div
          className='py-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'calendar.postponeLesson',
              data: {
                isOpen: true,
                lessonId: dataLesson?.id || '',
                isOrientation: false,
              },
            })
          }}
        >
          <span className='h5 text-gray'>
            {translate('modal.infolesson.youneedremove')}
          </span>
        </div>
      )}
      <PopupBackupLink
        isOpen={backupLinkOpen}
        toggle={() => setBackupLinkOpen(!backupLinkOpen)}
        educatorId={
          dataLesson?.substitute?.educatorUserId ||
          dataLesson?.educator?.educatorUserId
        }
        collectionId={dataLesson?.id}
        link={dataLesson.backupLink}
        userInfo={dataLesson?.substitute || dataLesson?.educator}
        isEducator
      />
    </div>
  )
}

export { InProgressHeaderModal, InProgressBodyModal }
