import {gql, useMutation} from '@apollo/client'

const useSendWspWhenStudentsCheckinFirst = (
  onCompleted = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation(
        $lessonId: ID!
      ) {
        sendWspWhenStudentsCheckinFirst(
          lessonId: $lessonId
        )
      }
    `,
    {onCompleted, onError},
  )
}

export default useSendWspWhenStudentsCheckinFirst
