import React, {useEffect, useState} from 'react'
import {
  Modal,
  IconClose,
  Button,
  InputSelect,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup, Label, ModalHeader} from 'reactstrap'
import {useMutation, gql} from '@apollo/client'
import {pick, isEmpty} from 'lodash'
import moment from 'moment'
import validateForm from './validation'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../store/modal/const'
import {defaultProps} from '../../Info/propTypes'
import escapeRegExp from 'lodash/escapeRegExp'

const TOTAL_YEARS = 50
const START_YEARS = 2010

const StudentModalForm = (props) => {
  const {studentInfo, id: userId} = props
  const classInfo = studentInfo || defaultProps.studentInfo
  const isOpen = useSelector((state) => state.modal.profile.studentInfo)
  const {refetch: refetchUser} = useSelector((state) => state.user)
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()

  const {translate} = useTranslation()

  const [validationErrors, setValidationErrors] = useState({})

  const [form, setForm] = useState({
    month: moment(classInfo.graduationDate).month(),
    year: moment(classInfo.graduationDate).year(),
    ...classInfo,
    userId,
  })
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.studentInfo'})
    setValidationErrors({})
    setForm({
      month: moment(classInfo.graduationDate).month(),
      year: moment(classInfo.graduationDate).year(),
      ...classInfo,
      userId,
    })
  }
  useEffect(() => {
    setForm({
      month: moment(classInfo.graduationDate).month(),
      year: moment(classInfo.graduationDate).year(),
      ...classInfo,
      userId,
    })
  }, [classInfo, userId])

  const [updInfo, {loading}] = useMutation(
    gql`
      mutation($studentInfo: StudentInput!) {
        setMyStudentInfo(studentInfo: $studentInfo) {
          id
          paymentMethod {
            ... on CardPaymentMethod {
              last4
              brand
              expYear
              expMonth
            }
          }
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            aditionalInfo
            parents {
              firstName
              lastName
              managePayments
              manageApprovals
              email
              parentUserId
              phone
            }
          }
          roles
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetchUser()
      },
    },
  )

  const submit = () => {
    const errors = validateForm(form)
    if (!isEmpty(errors)) {
      setValidationErrors(errors)
      return
    }
    setValidationErrors({})
    const updData = pick(form, ['institution', 'aditionalInfo'])
    updData.gpa = parseFloat(form.gpa)
    updData.graduationDate = moment()
      .year(form.year)
      .month(form.month)
      .date('01')
      .toISOString()
    try {
      updInfo({variables: {studentInfo: updData}}).then(() => {
        toggle()
      })
    } catch (e) {
      // eslint-disable-next-line
      console.error({e})
    }
  }

  return (
    <span>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        isOpen={isOpen}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        toggle={toggle}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
            className='mt-md-4 pl-0'
          >
            <span className='h2 font-weight-bold mr-4'>
              {translate('profile.studentInfo')}
            </span>
          </ModalHeader>
        }
      >
        <Form
          value={form}
          onChange={setForm}
          validationErrors={validationErrors}
        >
          <FormGroup>
            <Label>
              {translate('profile.actions.editStudentInfo.institution')}
            </Label>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='institution'
              placeholder='Institution'
            />
          </FormGroup>
          {/* <FormGroup>
            <Label>{translate('profile.actions.editStudentInfo.gpa')}</Label>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='gpa'
              placeholder='0'
            />
          </FormGroup> */}
          <FormGroup>
            <Label>
              {translate('profile.actions.editStudentInfo.gradDate')}
            </Label>
            <div className='row'>
              <div className='col-7 pr-0'>
                <Field
                  type={LabeledField}
                  component={InputSelect}
                  fieldName='month'
                  className='h-100'
                  options={new Array(12).fill(0).map((_, idx) => ({
                    label: moment().month(idx).format('MMMM'),
                    value: idx,
                  }))}
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                      return true
                    return false
                  }}
                  listItem='small'
                  itemDivider='middle'
                  multiple={false}
                  border='noRight'
                />
              </div>
              <div className='col-5 pl-1'>
                <Field
                  type={LabeledField}
                  component={InputSelect}
                  fieldName='year'
                  className='h-100'
                  options={new Array(TOTAL_YEARS).fill(0).map((_, idx) => ({
                    label: START_YEARS + idx,
                    value: START_YEARS + idx,
                  }))}
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                      return true
                    return false
                  }}
                  listItem='small'
                  itemDivider='middle'
                  multiple={false}
                  border='noLeft'
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              {translate('profile.actions.editStudentInfo.additionalInfo')}
            </Label>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='aditionalInfo'
              fieldType='textarea'
              placeholder={translate(
                'profile.actions.editStudentInfo.additionalInfoPlaceholder',
              )}
            />
          </FormGroup>
          <Button
            color='primary'
            label={translate('misc.saveChanges')}
            disabled={loading}
            size='medium'
            activity={loading}
            check
            style={{width: '100%'}}
            className='mt-3'
            onClick={submit}
          />
        </Form>
      </Modal>
    </span>
  )
}

StudentModalForm.propTypes = {}

export default StudentModalForm
