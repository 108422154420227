import es from './es'
import en from './en'
import changePassword from './changePassword'
import editStudentInfo from './editStudentInfo'
import editPersonalInfo from './editPersonalInfo'
import changeMethod from './changeMethod'
import changeNotifications from './changeNotifications'
import payments from './payments'

export default {
  es,
  en,
  changePassword,
  editStudentInfo,
  editPersonalInfo,
  changeMethod,
  changeNotifications,
  payments,
}
