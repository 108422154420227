import React from 'react'
import {useSelector} from 'react-redux'
import {ScoreItem} from '@Knowledge-OTP/znk-ui-components'

const GreTitle = (props) => {
  const {titleClass} = props
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const scores = reduxData?.scores
  const elementsToShow = [
    {title: 'OVERALL SCORE', value: parseInt(scores?.total)},
  ]
  return (
    <div className={titleClass}>
      {elementsToShow?.map((el) => (
        <ScoreItem
          className='m-0 mr-1 py-5'
          serviceLabel={el?.title}
          serviceScore={null}
          scoreLabel={el.value ? el.value : 0}
        />
      ))}
    </div>
  )
}

export default GreTitle
