import React from 'react'
import {Avatar} from '@Knowledge-OTP/znk-ui-components'

const showAvatarAssigned = (userId, dataTeam, named) => {
  const userAssigned = dataTeam?.filter((user) => user.teamUserId === userId)[0]

  return (
    <div className='d-flex flex-row align-items-center'>
      {named && (
        <div className='mr-2'>
          <span
            style={{
              color: '#2C3F78',
            }}
          >
            {userAssigned?.firstName +
              ' ' +
              userAssigned?.lastName?.charAt(0) +
              '.'}
          </span>
        </div>
      )}
      <Avatar
        classNameAvatar='w-100 text-align-center'
        uri={userAssigned?.avatar || ''}
        name={userAssigned?.firstName + ' ' + userAssigned?.lastName}
        size='avatar-small'
      />
    </div>
  )
}

export default showAvatarAssigned
