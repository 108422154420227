import {gql, useQuery} from '@apollo/client'
import queryMyCounselingPlan from '../../../QueryResult/useMyCounselingPlan'

export default (vars) => {
  // Sirve para traer el counseling plan del alumno logueado.
  const {data, loading} = useQuery(
    gql`query(
                    $planId: ID!
                      )
                      {
                myPlan(
                    planId: $planId
            ) {
                ${queryMyCounselingPlan}
            }
        }`,
    {
      variables: vars,
    },
  )

  return {
    loading,
    data: data?.myPlan || {},
  }
}
