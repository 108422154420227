import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import {FormGroup, Label} from 'reactstrap'

const ActComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  } = propsToSend
  const scale = '1-36'
  const scaleWriting = '2-12'
  const scaleMin = parseInt(scale.split('-')[0])
  const scaleMax = parseInt(scale.split('-')[1])
  const scaleWritingMin = parseInt(scaleWriting.split('-')[0])
  const scaleWritingMax = parseInt(scaleWriting.split('-')[1])
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const reduxDataTaskModal = useSelector(
    (state) => state.modal.counseling.viewTaskModal,
  )
  const scores = reduxData?.scores

  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scores?.math >= scaleMin &&
      scores?.math <= scaleMax &&
      scores?.science >= scaleMin &&
      scores?.science <= scaleMax &&
      scores?.english >= scaleMin &&
      scores?.english <= scaleMax &&
      scores?.reading >= scaleMin &&
      scores?.reading <= scaleMax &&
      ((scores?.writing >= scaleWritingMin &&
        scores?.writing <= scaleWritingMax) ||
        scores?.writing === 0) &&
      (reduxDataTaskModal?.task?.kind !== 'lessonsPerWeek' ||
        !!scores?.examDate)
    )
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.composite,
      results: [
        {
          name: 'Math',
          scale: scale,
          values: [
            {
              name: 'Math',
              scale: scale,
              value: scores?.math,
            },
          ],
        },
        {
          name: 'Science',
          scale: scale,
          values: [
            {
              name: 'Science',
              scale: scale,
              value: scores?.science,
            },
          ],
        },
        {
          name: 'English',
          scale: scale,
          values: [
            {
              name: 'English',
              scale: scale,
              value: scores?.english,
            },
          ],
        },
        {
          name: 'Reading',
          scale: scale,
          values: [
            {
              name: 'Reading',
              scale: scale,
              value: scores?.reading,
            },
          ],
        },
        {
          name: 'Composite',
          scale: scale,
          values: [
            {
              name: 'Composite',
              scale: scale,
              value: scores?.composite,
            },
          ],
        },
        {
          name: 'Writing',
          scale: scaleWriting,
          values: [
            {
              name: 'Writing',
              scale: scaleWriting,
              value: scores?.writing,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  return (
    <div className={`${scoresComponentClass}`}>
      <Form Form value={scores}>
        <FormGroup className='mt-7 mb-0'>
          <Label style={{color: sectionTitleColor}}>ACT</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='math'
                className='m-1'
                label='Math'
                value={scores?.math}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    math: parseInt(e),
                    composite:
                      (parseInt(e) +
                        scores?.science +
                        scores?.english +
                        scores?.reading) /
                      4,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='science'
                className='m-1'
                label='Science'
                value={scores?.science}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    science: parseInt(e),
                    composite:
                      (parseInt(e) +
                        scores?.english +
                        scores?.reading +
                        scores?.math) /
                      4,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='english'
                className='m-1'
                label='English'
                value={scores?.english}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    english: parseInt(e),
                    composite:
                      (parseInt(e) +
                        scores?.science +
                        scores?.reading +
                        scores?.math) /
                      4,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='reading'
                className='m-1'
                label='Reading'
                value={scores?.reading}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    reading: parseInt(e),
                    composite:
                      (parseInt(e) +
                        scores?.science +
                        scores?.english +
                        scores?.math) /
                      4,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className='mt-7 mb-0'>
          <Label>Writing</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='writing'
                className='m-1'
                label='Writing'
                value={scores?.writing}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    writing: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleWritingMin}
                max={scaleWritingMax}
              />
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default ActComponent
