import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import {FormGroup, Label} from 'reactstrap'

const GreComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  } = propsToSend
  const scale = '137-170'
  const scaleWriting = '0-6'
  const scaleMin = parseInt(scale.split('-')[0])
  const scaleMax = parseInt(scale.split('-')[1])
  const scaleWritingMin = parseInt(scaleWriting.split('-')[0])
  const scaleWritingMax = parseInt(scaleWriting.split('-')[1])
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const scores = reduxData?.scores
  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scaleMin <= scores?.verbal &&
      scores?.verbal <= scaleMax &&
      scaleMin <= scores?.reasoning &&
      scores?.reasoning <= scaleMax &&
      scaleWritingMin <= scores?.analytical &&
      scores?.analytical <= scaleWritingMax
      // && !!scores?.examDate
    )
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.total,
      results: [
        {
          name: 'Verbal Reasoning',
          scale: scale,
          values: [
            {
              name: 'Verbal Reasoning',
              scale: scale,
              value: scores?.verbal,
            },
          ],
        },
        {
          name: 'Quantitative Reasoning',
          scale: scale,
          values: [
            {
              name: 'Quantitative Reasoning',
              scale: scale,
              value: scores?.reasoning,
            },
          ],
        },
        {
          name: 'Analytical Writing',
          scale: scaleWriting,
          values: [
            {
              name: 'Analytical Writing',
              scale: scaleWriting,
              value: scores?.analytical,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  return (
    <div className={`${scoresComponentClass}`}>
      <Form Form value={scores}>
        <FormGroup className='mt-7 mb-0'>
          <Label style={{color: sectionTitleColor}}>GRE</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='verbal'
                className='m-1'
                label='Verbal'
                value={scores?.verbal}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    verbal: parseInt(e),
                    total: parseInt(e) + scores?.reasoning,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='reasoning'
                className='m-1'
                label='Reasoning'
                value={scores?.reasoning}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    reasoning: parseInt(e),
                    total: parseInt(e) + scores?.verbal,
                  })
                }}
                isEditable={isEditable}
                min={scaleMin}
                max={scaleMax}
              />
              <ScoreListItem
                id='analytical'
                className='m-1'
                label='Analytical'
                value={scores?.analytical}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    analytical: parseInt(e),
                  })
                }}
                isEditable={isEditable}
                min={scaleWritingMin}
                max={scaleWritingMax}
              />
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default GreComponent
