import validate from 'validate.js'

const constraints = {
  kinds: {
    presence: true,
  },
  userId: {
    presence: false,
  },
  from: {
    presence: true,
  },
  to: {
    presence: true,
  },
}

export default (data) => {
  return validate(data, constraints)
}
