import {gql, useMutation} from '@apollo/client'

const UseDelSessionWithPlanId = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($planId: ID!) {
        delOnboardingSessionWithPlanId(planId: $planId)
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default UseDelSessionWithPlanId
