import React, {useState, useMemo} from 'react'
import {
  ModalLayout,
  Button,
  useWindowDimensions,
  IconIllustratedTopic,
  SelectOption,
  InputSelect,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  PhoneSelector,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup, Collapse} from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {pick, isEmpty} from 'lodash'
import validateForm from './validationCompleteProfile'
import useCompleteProfile from './useCompleteProfile'
import moment from 'moment'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import escapeRegExp from 'lodash/escapeRegExp'
import useUpdateOnboardingStatus from '../Model/useUpdateOnboardingStatus'
import useSendWspWhenFirstPlanIsApproved from '../Model/useSendWspWhenFirstPlanIsApproved'
import {showAlert} from '../../../common/Alert/util'
import usStatesList from '../../../common/CompleteProfile/Utils/us_states.json'

const mesDefault = 0
const anoDefault = '1985'

const CompleteProfile = (props) => {
  const {
    isOpen,
    toggle,
    isCounselingOnboarding = false,
    onChange = () => {},
  } = props
  const mediumModal = useSelector((state) => state.modal.onboarding.mediumModal)
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const userInfo = useSelector((state) => state.user.info)
  const [sendWsp, {loading: loadWsp}] = useSendWspWhenFirstPlanIsApproved(
    () => {
      showAlert({text: 'Wsp sent!', status: 'success'}, dispatch)
    },
    (err) => {
      showAlert({text: err?.toString(), status: 'error'}, dispatch)
    },
  )
  const parseUserInfoToForm = (user) => {
    // setea la información del usuario en el formulario
    const phone = user?.phone
    const dialCode = phone
      ? phone
          .split(' ')
          .slice(0, phone.split(' ')?.length - 1)
          .join(' ')
      : ''

    const phoneNumber = phone
      ? phone.split(' ')[phone.split(' ')?.length - 1]
      : ''

    const parent =
      user?.studentInfo?.parents?.length > 0
        ? user?.studentInfo?.parents[0]
        : null

    const parentPhone = parent?.phone
    const dialCodeParent = parentPhone
      ? parentPhone
          .split(' ')
          .slice(0, parentPhone.split(' ')?.length - 1)
          .join(' ')
      : ''

    const parentPhoneNumber = parentPhone
      ? parentPhone.split(' ')[parentPhone.split(' ')?.length - 1]
      : ''

    return {
      first: user?.firstName || null,
      last: user?.lastName || null,
      country: user?.countryId || null,
      address: user?.address || null,
      city: user?.city || null,
      state: user?.state || null,
      zip: user?.zipCode || null,
      spoken: user?.spokenLanguages || null,
      gender: user?.gender || null,
      tel: phoneNumber || null,
      prefijo: dialCode || null,
      email: user?.emails?.length > 0 ? user?.emails[0]?.address || null : null,
      dia: user?.birthdate
        ? parseInt(moment(user.birthdate).format('DD'))
        : null,
      mes: user?.birthdate
        ? parseInt(moment(user.birthdate).format('MM')) - 1
        : mesDefault,
      ano: user?.birthdate
        ? parseInt(moment(user.birthdate).format('YYYY'))
        : anoDefault,
      // new keys
      studentType: user?.studentInfo?.studentType || null,
      IdParent:
        user?.studentInfo?.parents?.length > 0
          ? user?.studentInfo?.parents[0]?.parentUserId
          : null,
      firstNameParent:
        user?.studentInfo?.parents?.length > 0
          ? user?.studentInfo?.parents[0]?.firstName
          : null,
      lastNameParent:
        user?.studentInfo?.parents?.length > 0
          ? user?.studentInfo?.parents[0]?.lastName
          : null,
      emailParent:
        user?.studentInfo?.parents?.length > 0
          ? user?.studentInfo?.parents[0]?.email
          : null,

      telParent: parentPhoneNumber,
      prefijoParent: dialCodeParent,

      aditionalInfo: user?.studentInfo?.aditionalInfo || null,
      gpa: user?.studentInfo?.gpa || null,
      anoGraduacion: user?.studentInfo?.graduationDate
        ? moment(user.studentInfo.graduationDate).year().toString()
        : anoDefault,
      mesGraduacion: user?.studentInfo?.graduationDate
        ? moment(user.studentInfo.graduationDate).month()
        : mesDefault,
      institution: user?.studentInfo?.institution || null,
    }
  }
  const [data, setData] = useState({
    mes: mesDefault,
    ano: anoDefault,
    ...parseUserInfoToForm(userInfo),
  })
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {updUser, loading} = useCompleteProfile()
  const [validationErrors, setValidationErrors] = useState({})
  const parseDataToMutation = (form) => {
    /**
     * countryId: $country
     * gender: $gender
     * email: $email
     * firstName: $first
     * lastName: $last
     * phone: $phone
     * birthdate: $birth
     * spokenLanguages: $spoken
     * address: $address
     * city: $city
     * state: $state
     * zipCode: $zip
     * studentType: $studentType
     * parent: {
        parentUserId: ID!
        firstName: String!
        lastName: String!
        email: String!  
       }
     * academicInfo: {
        institution: String
        graduationDate: DateTime
        gpa: Float
        defaultRate: Float
        aditionalInfo: String
       }
     * **/
    let dat = {
      countryId: form?.country,
      gender: form?.gender,
      email: form?.email,
      firstName: form?.first,
      lastName: form?.last,
      phone: form?.phone,
      birthdate: form?.birth,
      spokenLanguages: form?.spoken,
      address: form?.address,
      city: form?.city,
      state: form?.state,
      zipCode: form?.zip,
      studentType: form?.studentType,
      academicInfo: {
        institution: form?.institution,
        graduationDate: moment()
          .month(parseInt(form?.mesGraduacion))
          .year(parseInt(form?.anoGraduacion))
          .toISOString(),
        gpa: parseFloat(form?.gpa),
        aditionalInfo: form?.aditionalInfo,
      },
    }
    if (form?.studentType === 'family') {
      dat = {
        ...dat,
        parent: {
          parentUserId: form.IdParent,
          firstName: form.firstNameParent,
          lastName: form.lastNameParent,
          email: form.emailParent,
          phone: form?.parentPhone,
        },
      }
      if (form.IdParent) dat.parent.parentUserId = form.IdParent
    }
    return dat
  }

  const [updateOnboarding] = useUpdateOnboardingStatus(
    () => {},
    (err) => {
      console.error(err.toString())
    },
  )
  const updateOnboardingStatus = async (planId, newStatus = 'confirm') => {
    await updateOnboarding({
      variables: {planId, newStatus},
    })
  }

  const submit = (family) => {
    const errors = validateForm(data, family)
    if (!isEmpty(errors)) {
      console.error(errors)
      setValidationErrors(errors)
      return
    }
    setValidationErrors({})
    const updData = pick(data, [
      'first',
      'last',
      'country',
      'address',
      'city',
      'state',
      'zip',
      'spoken',
      'gender',
      'email',
      'studentType',
      'IdParent',
      'emailParent',
      'firstNameParent',
      'lastNameParent',
      'aditionalInfo',
      'gpa',
      'anoGraduacion',
      'mesGraduacion',
      'institution',
    ])
    updData.phone = `${data.prefijo} ${data.tel}`
    updData.parentPhone = `${data.prefijoParent} ${data.telParent}`
    updData.birth = moment()
      .year(data.ano)
      .month(data.mes)
      .date(data.dia)
      .toISOString()
    updUser({variables: parseDataToMutation(updData)})
      .then((result) => {
        if (isCounselingOnboarding) {
          if (onChange) onChange()
        } else {
          const onSuccess = () => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.mediumModal',
              data: {...mediumModal, isOpen: false},
            })
            toggle()
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.data.dataPlan',
              data: {
                ...dataPlan,
                // studentName: result?.data?.updateUser?.firstName,
              },
            })
          }
          const planId = dataPlan?.idPlan || dataPlan?.id
          updateOnboardingStatus(planId, 'approved').then(
            () => {
              sendWsp({variables: {planId}}).then(
                () => {
                  onSuccess()
                },
                (err) => {
                  showAlert({status: 'error', text: err.toString()}, dispatch)
                  onSuccess()
                },
              )
            },
            () => {
              onSuccess()
            },
          )
        }
      })
      .catch((e) => {})
  }
  const countries = useSelector((state) => state.countries.countries)

  const sortCountries = (countryArray) => {
    const sort = countryArray.sort((a, b) => {
      const r = a.label.localeCompare(b.label, 'en', {sensitivity: 'base'})
      return r
    })
    return sort
  }

  const dias = useMemo(() => {
    const string = `${data.ano ? data.ano : '1985'}-${
      data.mes ? parseInt(data.mes) + 1 : '01'
    }`
    const daysInMonth = moment(string).daysInMonth() || 31
    if (daysInMonth > 0)
      return new Array(daysInMonth).fill(0).map((i, index) => {
        return {
          value: index + 1,
          label: index + 1,
        }
      })
  }, [data.mes, data.ano])
  // const pre = Object.values(countries).map((coun) => {
  //   return coun.dialCode
  // })
  const usStates = usStatesList?.map((e) => {
    return {label: e.name, abb: e.abbreviation}
  })
  let [selectedCountry, setSelectedCountry] = useState(data.country)
  let [easyState, setEasyState] = useState(data.state)
  const handleCountryChange = (value) => {
    setSelectedCountry(value)
    setEasyState(null)
  }

  return (
    <>
      <ModalLayout
        disabledScrollAnimation
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'modal-desktop-half'
        }`}
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <IconIllustratedTopic size={breakWidth === 'SM' ? 60 : 100} color />
        }
        style={{borderBottom: 'none', backgroundColor: 'white'}}
        underHead={null}
        minHead={null}
      >
        <label className='htext mt-3 mx-4 d-flex flex-column'>
          <span className='h2 font-weight-bold'>
            {translate('home.onboard.approve.profile.title')}
          </span>
          {/**
             * <span className='h2 font-weight-bold'>
              {translate('home.onboard.approve.profile.complete')}
            </span>
             ***/}
        </label>
        <Form
          className='stripe mt-3'
          value={data}
          onChange={setData}
          validationErrors={validationErrors}
        >
          <FormGroup className='row mx-4'>
            <label className='col-16 m-0 mb-3'>
              {translate('home.onboard.approve.profile.subtitle')}
            </label>
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.student')}
            </label>
            <div className='mr-2 w-50'>
              <Field
                className='m-0'
                type={LabeledField}
                component={PlainTextInput}
                fieldName='first'
                placeholder={translate(
                  'home.onboard.approve.profile.studentFirtsName',
                )}
                max={20}
              />
            </div>
            <Field
              className='w-100 m-0'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='last'
              placeholder={translate(
                'home.onboard.approve.profile.studentLastName',
              )}
              max={20}
            />
            <label className='col-12 m-0'>Email</label>
            <Field
              className='col-12 m-0 readonly'
              type={LabeledField}
              component={PlainTextInput}
              readonly="readonly"
              fieldName='email'
              fieldType='email'
              placeholder='Email'
            /> 
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.gender')}
            </label>
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={SelectOption}
              fieldName='gender'
              options={[
                {
                  id: 'male',
                  text: translate('home.onboard.approve.profile.male'),
                },
                {
                  id: 'female',
                  text: translate('home.onboard.approve.profile.female'),
                },
                {
                  id: 'other',
                  text: translate('home.onboard.approve.profile.other'),
                },
              ]}
            />
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.birth')}
            </label>
            <div className='col-3 m-0 p-0'>
              <Field
                type={LabeledField}
                component={InputSelect}
                fieldName='dia'
                options={dias}
                PlaceholderSelect={translate(
                  'home.onboard.approve.profile.day',
                )}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                    return true
                  return false
                }}
              />
            </div>
            <div className='col-5 m-0 py-0 px-1'>
              <Field
                type={LabeledField}
                component={InputSelect}
                fieldName='mes'
                options={moment.months().map((month, index) => {
                  return {value: index, label: month}
                })}
                PlaceholderSelect={translate(
                  'home.onboard.approve.profile.month',
                )}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                    return true
                  return false
                }}
              />
            </div>
            <div className='col-4 m-0 p-0'>
              <Field
                type={InputSelect}
                component={InputSelect}
                fieldName='ano'
                options={new Array(50).fill(0).map((zero, ano) => {
                  return {
                    value: (ano + 1985).toString(),
                    label: (ano + 1985).toString(),
                  }
                })}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                    return true
                  return false
                }}
                PlaceholderSelect={translate(
                  'home.onboard.approve.profile.year',
                )}
              />
            </div>
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.mobile')}
            </label>
            <div className='col-5 m-0 p-0'>
              <PhoneSelector
                defaultValue={data?.prefijo || ''}
                fieldName='prefijo'
                PlaceholderSelect={translate(
                  'home.onboard.approve.profile.dialcode',
                )}
                selectCode={data?.prefijo}
                setSelectCode={(newPre) => {
                  setData({...data, prefijo: newPre})
                }}
              />
              <label style={{color: 'red', fontSize: '12px'}}>
                {validationErrors?.prefijo ? validationErrors?.prefijo : ''}
              </label>
            </div>
            <div className='col-7 m-0'>
              <Field
                className='w-100 ml-3'
                type={LabeledField}
                component={PlainTextInput}
                fieldName='tel'
                placeholder='912345678'
              />
            </div>
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.spoken')}
            </label>
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='spoken'
              placeholder={translate('home.onboard.approve.profile.language')}
            />
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.address')}
            </label>
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='address'
              placeholder={translate('home.onboard.approve.profile.address')}
            />
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.city')}
            </label>
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='city'
              placeholder={translate('home.onboard.approve.profile.city')}
            />
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.state')}
            </label>
            {selectedCountry === '5f778da89097bc5330ab1739' ? (
              <Field
                className='col-12 m-0 p-0'
                type={LabeledField}
                component={InputSelect}
                fieldName='state'
                defaultValue={easyState}
                value={easyState}
                onChange={(name) => {
                  setEasyState(name)
                  setData({
                    ...data,
                    state: name,
                  })
                }}
                placeholder={translate('home.onboard.approve.profile.state')}
                options={usStates.map((state, _) => ({
                  label: state.label,
                  value: state.label,
                }))}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                    return true
                  return false
                }}
                isClearable={false}
              />
            ) : (
              <Field
                className='col-12 m-0'
                type={LabeledField}
                component={PlainTextInput}
                fieldName='state'
                value={easyState}
                placeholder={translate('home.onboard.approve.profile.state')}
              />
            )}
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.zip')}
            </label>
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='zip'
              placeholder='8320000'
            />
            <label className='col-12 m-0'>
              {translate('home.onboard.approve.profile.country')}
            </label>
            <Field
              className='col-12 m-0 p-0'
              type={LabeledField}
              component={InputSelect}
              onChange={(name) => {
                handleCountryChange(name)
                setData({
                  ...data,
                  country: name,
                })
              }}
              fieldName='country'
              value={selectedCountry}
              options={sortCountries(
                Object.values(countries).map((coun) => {
                  return {value: coun.id, label: coun.name}
                }),
              )}
              PlaceholderSelect={translate(
                'home.onboard.approve.profile.selectcountry',
              )}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
              isClearable={false}
            />
            {data?.studentType === 'family' && (
              <>
                <div className='borderTop my-6' />
                <label className='col-12 m-0 mb-3 font-weight-bold'>
                  Family Info
                </label>
              </>
            )}
            {/** <Field
              className='row col-12'
              fieldName='studentType'
              fieldType='date'
              type={SelectOption}
              options={[
                { id: 'family', text: 'Family' },
                { id: 'independent', text: 'Independent' }
              ]}
              OptionClassName='col-6'
            />**/}
            <Collapse
              className='w-100 m-0 row'
              isOpen={data?.studentType === 'family'}
            >
              <label className='col-12 m-0'>Parent Name</label>
              <Field
                className='col-12 noRight m-0'
                fieldName='firstNameParent'
                fieldType='text'
                placeholder='Firts Name'
                type={LabeledField}
                component={PlainTextInput}
              />
              <Field
                className='col-12 noLeft m-0'
                fieldName='lastNameParent'
                fieldType='text'
                placeholder='Last Name'
                type={LabeledField}
                component={PlainTextInput}
              />
              <label className='col-12 m-0'>Parent Email</label>
              <Field
                className='col-12 m-0'
                fieldName='emailParent'
                fieldType='email'
                placeholder='name@yourmail.com'
                type={LabeledField}
                component={PlainTextInput}
              />
              <label className='col-12 m-0'>Parent Phone</label>
              <div className='col-5 m-0 p-0' style={{alignSelf: 'center'}}>
                <PhoneSelector
                  className='m-0'
                  defaultValue={data?.prefijoParent || ''}
                  fieldName='prefijo'
                  PlaceholderSelect={translate(
                    'home.onboard.approve.profile.dialcode',
                  )}
                  selectCode={data?.prefijoParent}
                  setSelectCode={(newPre) => {
                    setData({...data, prefijoParent: newPre})
                  }}
                />
              </div>
              <div className='col-7 m-0'>
                <Field
                  className='w-100 m-0 ml-3'
                  type={LabeledField}
                  component={PlainTextInput}
                  labelContainerClassName='m-0'
                  fieldName='telParent'
                  placeholder='912345678'
                />
              </div>
            </Collapse>
            <div className='borderTop my-6' />
            <label className='col-12 m-0 mb-3 font-weight-bold'>
              Academic info
            </label>
            <label className='col-12 m-0'>Institution</label>
            <Field
              className='col-12 m-0'
              fieldName='institution'
              fieldType='text'
              placeholder='Institution'
              type={LabeledField}
              component={PlainTextInput}
            />
            {/* <label className='col-12 m-0'>GPA</label>
            <Field
              className='col-12 m-0'
              fieldName='gpa'
              fieldType='number'
              placeholder='GPA'
              type={LabeledField}
              component={PlainTextInput}
            /> */}
            <label className='col-12 m-0'>Gradiuation Date</label>
            <Field
              className='col-12 pl-0 pr-1 py-0 m-0'
              type={LabeledField}
              component={InputSelect}
              InputClassName='m-0'
              fieldName='mesGraduacion'
              options={moment.months().map((month, index) => {
                return {value: index, label: month}
              })}
              PlaceholderSelect={translate(
                'home.onboard.approve.profile.month',
              )}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
            />
            <Field
              className='col-12 m-0'
              type={LabeledField}
              component={InputSelect}
              InputClassName='m-0'
              fieldName='anoGraduacion'
              options={new Array(60).fill(0).map((zero, ano) => {
                return {
                  value: (ano + 1985).toString(),
                  label: (ano + 1985).toString(),
                }
              })}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
              PlaceholderSelect={translate('home.onboard.approve.profile.year')}
            />
            <label className='col-12 m-0'>Aditional Info</label>
            <textarea
              className='col-12 znk-input pt-2 m-0 mb-3'
              name='aditionalInfo'
              placeholder='Aditional Info'
              value={data?.aditionalInfo}
              onChange={(e) => {
                setData({
                  ...data,
                  aditionalInfo: e.target.value,
                })
              }}
            />
          </FormGroup>
        </Form>
        <Button
          className='mx-5 mb-3'
          label={translate('home.onboard.approve.profile.confirm')}
          activity={loading || loadWsp}
          check
          color={isCounselingOnboarding ? 'counseling' : 'gold'}
          style={{border: 'none', width: '90%'}}
          onClick={() => {
            // cargando
            // realizar mutación
            // updUser({variables: parseFormToMutation(data)})
            submit(data?.studentType === 'family')
          }}
        />
      </ModalLayout>
    </>
  )
}

CompleteProfile.propTypes = {}

export default CompleteProfile
