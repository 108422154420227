const navItemClassName = 'm-0'
const navLinkClassName = 'pr-4 py-2'
export const navItems = [
  {
    href: '/profile/info',
    Body: 'Profile',
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/profile/payments',
    Body: 'Payments',
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  // {
  //   href: '/profile/history',
  //   Body: 'History',
  //   linkClass: navLinkClassName,
  //   itemClass: navItemClassName,
  // },
]
