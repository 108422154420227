import moment from 'moment-timezone'

const makeObjectOnboarding = (planQuery, services, orientationQuery, zone) => {
  /**
     *  createdAt: "2021-04-06T18:00:09.776Z"
        groupId: "606ca1a92ebf9341f06c3a6f"
        groupPerWeekInfo: {__typename: "GroupPLan", PartsSchedule: Array(1)}
        groupPricingRate: 0
        id: "606ca1a92ebf9341f06c3a7f"
        isFirstPlan: true
        kind: "lessonsPerWeek"
        parts: Array(1)
            0:
                from: "2021-04-12T00:00:00.000Z"
                lessonPerWeekPerTopic: [
                    {
                        lessonsPerWeek: 2
                        topicId: "600703afb6cb3c12b7e5cb77"
                    }
                ]
                lessonsPerWeek: 4
                name: "Part 1"
                to: "2021-05-03T00:00:00.000Z"
                totalLessons: 12
                __typename: "LessonsPerWeekPlanPart"
                __proto__: Object
                length: 1
            __proto__: Array(0)
        paymentItemsConfig: (4) [{…}, {…}, {…}, {…}]
        recommendedEducators: (4) [
            {
                educatorUserId: "5f92deac586824186078a36c"
                firstName: "Daniel"
                isLockEducator: false
                lastName: "Admin"
                topicId: "5f58e6cf1b8c4862420e394d"
            },
            {…}, {…}, {…}]
        serviceId: "5f88571de3cc10244852476c"
        status: "sent"
        studentUser: {__typename: "PlanStudent", studentUserId: "5f92deac586824186078a36c", firstName: "Daniel", lastName: "Admin", rate: 300}
        typePlanPricing: "permonth"
        __typename: "LessonsPerWeekPlan"
     * **/
  let arrayPart =
    // planQuery.adjustedConfig?.adjustedParts ||
    planQuery?.parts || planQuery?.partsFixed || []
  const isTotalLesson = arrayPart.reduce((isTotal, part) => {
    return isTotal || part?.type === 'totalLesson'
  }, false)
  const scheduledTimes = {}
  let totalLessons = 0
  let keyLesson = null
  const isTimeLess = planQuery?.kind !== 'lessonsPerWeek'
  const rangesPlan = []
  let orientationKey = 'Orientation-0' // default
  const daysFromStartToNow = Math.floor(
    arrayPart[0] && zone
      ? moment
          .duration(moment(arrayPart[0]?.from).diff(moment().tz(zone)))
          .asDays()
      : 0,
  )
  if (daysFromStartToNow < 0 && !isTimeLess) {
    arrayPart = planQuery.adjustedConfig.adjustedParts.map((part) => {
      if (moment(part?.from) < moment())
        return {
          ...part,
          from: moment().add(1, 'day').startOf('day').toISOString(),
        }
      return part
    })
  } else if (isTimeLess && planQuery?.partsFixed[0]?.type === 'lessonPerWeek') {
    arrayPart = arrayPart.map((part) => {
      if (moment(part?.from) < moment()) {
        const diffDays = Math.abs(
          Math.floor(
            part && zone
              ? moment
                  .duration(moment(part?.from).diff(moment().tz(zone)))
                  .asDays()
              : 0,
          ),
        )
        // calcula los dias de diferencia entre hoy y el inicio del plan
        return {
          ...part,
          from: moment(part?.from)
            .add(diffDays + 1, 'day')
            .startOf('day')
            .toISOString(),
          to: moment(part?.to)
            .add(diffDays + 1, 'day')
            .startOf('day')
            .toISOString(),
        }
      }
      return part
    })
  }

  const orientationObject = {
    times: new Array(1).fill(null),
    lpw: 1,
    totalLessons: 1,
    distributed: true,
    range: ['', ''],
    topic: {
      id: 'Orientation',
      name: 'Orientation',
    },
    educator: [
      {
        id: orientationQuery.id,
        name: `${orientationQuery.firstName} ${orientationQuery.lastName}`,
        selected: true,
        timezone: orientationQuery.timezone,
      },
    ],
  }
  arrayPart.forEach((part, indexPart) => {
    const topicArray = part?.lessonPerWeekPerTopic || part?.TotalPerTopic || []
    const isPartCanceled = part.canceled ?? false
    // const isPartCanceled = false
    rangesPlan.push([part.from, part.to])
    if (isTimeLess && moment() > moment(rangesPlan[indexPart][0])) {
      // modificar fechas
      rangesPlan[indexPart][0] = moment(rangesPlan[indexPart][0])
        .tz(zone)
        .add(daysFromStartToNow, 'days')
        .toISOString()
      rangesPlan[indexPart][1] = moment(rangesPlan[indexPart][1])
        .tz(zone)
        .add(daysFromStartToNow, 'days')
        .toISOString()
    }
    if (isPartCanceled) return
    topicArray.forEach((topic, indexTopic) => {
      if (indexTopic === 0 && keyLesson === null)
        keyLesson = `${topic.topicId}-${indexPart}`
      totalLessons += topic?.totalLessons
      scheduledTimes[`${topic.topicId}-${indexPart}`] = {
        times: new Array(topic?.lessonsPerWeek || 0).fill(null),
        lpw: topic?.lessonsPerWeek || 0,
        totalLessons: topic?.lessonsPerWeek || 0,
        distributed: !isTotalLesson,
        range: rangesPlan[indexPart],
        educator: planQuery?.recommendedEducators
          .filter(
            (edu) =>
              edu.topicId === topic.topicId &&
              edu.teachingType === 'individual',
          )
          .map((edu, indx) => {
            return {
              id: edu.educatorUserId,
              name: `${edu.firstName} ${edu.lastName}`,
              lock: edu.isLockEducator,
              selected: indx === 0,
            }
          }),
        topic: {
          id: topic?.topicId,
          name: services[planQuery?.serviceId]?.topics[topic.topicId]?.name,
        },
        lockLesson: topic?.lockLessons || false,
      }
    })

    if (indexPart === arrayPart.length - 1 && planQuery?.isFirstPlan) {
      // incluye orientation
      orientationKey = `Orientation-${arrayPart.length - 1}`
      scheduledTimes[orientationKey] = orientationObject
    }
  })

  // incluye orientación si no lo agregó antes
  if (
    !Object.keys(scheduledTimes).reduce((bool, topicKey) => {
      return (
        bool ||
        topicKey
          .split('-')
          .map((text) => text?.toUpperCase())
          .includes('ORIENTATION')
      )
    }, false) &&
    planQuery?.isFirstPlan
  ) {
    orientationKey = `Orientation-${arrayPart.length - 1}`
    scheduledTimes[orientationKey] = orientationObject
  }

  if (!keyLesson) {
    if (planQuery?.isFirstPlan)
      keyLesson = orientationKey || `Orientation-${arrayPart.length - 1}`
    else keyLesson = `Nodefined-0`
  }

  // fecha de agendamiento (FA)
  // fecha de primera cuota (FP)
  // si FP es posterior a FA => todo normal
  // si FA es posterior a FP => ajusta la fecha de todos los pagos

  // ajusta payment items si el agendamiento se lleva a cabo
  // despues de la fecha de pago de la primera cuota
  let paymentItems = planQuery.paymentItemsConfig
    .filter(({type}) => type === 'lessons-payment')
    .sort(({date: dateA}, {date: dateB}) => {
      if (moment(dateA) < moment(dateB)) {
        return -1
      }
      if (moment(dateA) >= moment(dateB)) {
        return 1
      }
      // a must be equal to b
      return 0
    })
  const paymentNeedAjusted = moment() > moment(paymentItems[0].date)
  const otherPaymentItems = planQuery.paymentItemsConfig.filter(
    ({type}) => type !== 'lessons-payment',
  )

  if (paymentNeedAjusted) {
    if (isTimeLess) {
      // CORRECTO
      const monthDiff = Math.abs(
        moment.duration(moment().diff(paymentItems[0].date)).asMonths(),
      )
      paymentItems = paymentItems.map((payment) => {
        const {date} = payment
        return {
          ...payment,
          date: moment(date).add(monthDiff, 'month').toISOString(),
        }
      })
    } else {
      // recalcula pagos
      paymentItems =
        planQuery?.adjustedConfig?.adjustedPayments.filter(
          ({type}) => type === 'lessons-payment',
        ) || []
    }
  }

  let adjustCheckInDates = planQuery?.checkInDates || []
  if (isTimeLess && adjustCheckInDates.length > 0) {
    const daysDiff = Math.abs(
      moment.duration(moment().diff(planQuery.createdAt)).asDays(),
    )
    adjustCheckInDates = adjustCheckInDates.map((check) => {
      return {
        ...check,
        date: moment(check.date).add(daysDiff, 'days').toISOString(),
      }
    })
  }

  const dataPlan = {
    idPlan: planQuery?.id,
    planName: planQuery?.name,
    testDate: planQuery?.testDate,
    studentName: planQuery?.studentUser?.firstName,
    studentUserId: planQuery?.studentUser?.studentUserId,
    serviceId: planQuery?.serviceId,
    serviceName: services[planQuery?.serviceId].name,
    isFirstPlan: planQuery?.isFirstPlan,
    isTestDate: !isTimeLess,
    isTotalLesson,
    adjustedConfig: planQuery?.adjustedConfig,
    recommendedEducators: planQuery?.recommendedEducators,
    paymentPerMonth: planQuery?.typePlanPricing === 'permonth',
    paymentItemsConfig: otherPaymentItems.concat(paymentItems),
    lessonRate: planQuery?.pricingRate,
    groupLessonRate: planQuery?.groupPricingRate,
    groupInfo: planQuery?.groupPerWeekInfo || [],
    isGroup: planQuery?.groupId || false,
    totalLessons,
    ranges: rangesPlan, // rango de fecha cada parte
    checkInDates: adjustCheckInDates,
  }

  const startPlan = rangesPlan.reduce((minDate, [from]) => {
    if (moment(from).toDate() < minDate) return moment(from).toDate()
    return minDate
  }, Number.POSITIVE_INFINITY)
  const endPlan = rangesPlan.reduce((maxDate, [_, to]) => {
    if (moment(to).toDate() > maxDate) return moment(to).toDate()
    return maxDate
  }, Number.NEGATIVE_INFINITY)

  dataPlan.startDate = new Date(startPlan).toISOString()
  dataPlan.endDate = new Date(endPlan).toISOString()

  return {
    scheduledTimes,
    dataPlan,
    keyLesson,
    indexLesson: 0,
    beginPart: false,
    ready: true,
    orientationKey,
  }
}
const getCurrentTopicPart = (indexPart, scheduledTimes, idTopic) => {
  return scheduledTimes[`${idTopic}-${indexPart}`]
}

const nextLesson = (scheduledTimes) => {
  // retorna key idTopic-indexPart con datos topico-parte
  // wizard ubica el primer null en times y coloca el horario seleccionado allí
  return Object.entries(scheduledTimes).reduce(
    (next, [key, valueTopicPart]) => {
      if (next === -1 && valueTopicPart.times.includes(null))
        return {nextKey: key, nextIndex: valueTopicPart.times.indexOf(null)}
      return next
    },
    -1,
  )
}

const detectChanges = (oldKey, newKey) => {
  // recibe nueva y antigua llave
  if (newKey.split('-')[0] === 'Orientation') {
    return 'scheduledOrientation'
  } else if (oldKey.split('-')[1] !== newKey.split('-')[1]) {
    return 'introductionPart'
  } else if (oldKey !== newKey) {
    return 'introductionTopic'
  }
  return 'scheduled'
}

const checkIfNeedSelectLpw = (scheduledTimes, indexPart) => {
  return Object.entries(scheduledTimes)
    .filter(([key]) => parseInt(key.split('-')[1]) === parseInt(indexPart))
    .reduce((needLpw, [_, obj]) => needLpw || !obj.distributed, false)
}

const setTimeOnTopicPart = (scheduledTimes, key, newTime) => {
  // setea newTime en el primer nulo de scheduledTimes[key].times
  let indexNull = scheduledTimes[key].times.indexOf(null)
  if (indexNull === -1) indexNull = scheduledTimes[key].times.length - 1
  scheduledTimes[key].times[indexNull] = newTime
  return scheduledTimes
}

const setTimeOnTopicPartLesson = (
  scheduledTimes,
  key,
  newTime,
  indexLesson,
) => {
  // setea newTime en el indice indexLesson de scheduledTimes[key].times
  scheduledTimes[key].times[indexLesson] = newTime
  return scheduledTimes
}

const adjustAll = (scheduledTimes, isTotalLesson) => {
  let newKey = null
  const newObj = Object.entries(scheduledTimes).reduce(
    (newObj, [key, value]) => {
      if (newKey === null) newKey = key
      const totalObj = isTotalLesson
        ? {
            distributed: false,
            lpw: value.totalLessons,
          }
        : {}
      const times = isTotalLesson
        ? new Array(value.totalLessons).fill(null)
        : value.times.map(() => null)
      return {
        ...newObj,
        [key]: {
          ...value,
          times,
          ...totalObj,
        },
      }
    },
    {},
  )
  return {scheduledTimes: newObj, keyLesson: newKey, indexLesson: 0}
}

const adjustTopicPart = (scheduledTimes, keyLesson, isTotalLesson) => {
  if (isTotalLesson) {
    scheduledTimes[keyLesson].distributed = false
    // scheduledTimes[keyLesson].lpw = scheduledTimes[keyLesson].totalLessons
    // scheduledTimes[keyLesson].times = new Array(scheduledTimes[keyLesson].times.length).fill(null)
  }
  scheduledTimes[keyLesson].times = new Array(
    scheduledTimes[keyLesson].times.length,
  ).fill(null)
  return {scheduledTimes, keyLesson, indexLesson: 0}
}

const adjustTopicLesson = (scheduledTimes, keyLesson, indexLesson) => {
  // deja como null el horario de una lección (solo una no el tópico completo)
  // scheduledTimes[keyLesson].times[indexLesson] = null
  return {scheduledTimes, keyLesson, indexLesson}
}

const getToForTopic = (from, lpw, total) => {
  const weeks = Math.round(total / lpw)
  const to = moment(from).add(weeks, 'week').toISOString()
  return to
}

const selectLpwForTopicPart = (
  scheduledTimes,
  keysLesson,
  startDate,
  dataPlan,
) => {
  // keysLesson = [{key, lpw}]
  let endDate = Number.NEGATIVE_INFINITY
  keysLesson.forEach(({key, lpw}) => {
    scheduledTimes[key].lpw = lpw
    scheduledTimes[key].times = new Array(lpw).fill(null)
    scheduledTimes[key].range[0] = startDate
    scheduledTimes[key].range[1] = getToForTopic(
      startDate,
      lpw,
      scheduledTimes[key].totalLessons,
    )
    // guarda fecha mas lejana, será la fecha de termino de la parte
    if (moment(scheduledTimes[key].range[1]).toDate() > endDate)
      endDate = moment(scheduledTimes[key].range[1]).toDate()
    scheduledTimes[key].distributed = true

    dataPlan.ranges[parseInt(key?.split('-')[1])][0] = startDate // se actualiza la fecha de inicio de la parte
    // se actualiza la fecha de fin de la parte
    dataPlan.ranges[parseInt(key?.split('-')[1])][1] = moment(
      endDate,
    ).toISOString()
  })

  // dataPlan.ranges[parseInt(keysLesson[0]?.split('-')[1])][0] = startDate //se actualiza la fecha de inicio de la parte
  // se actualiza la fecha de fin de la parte
  // dataPlan.ranges[parseInt(keysLesson[0]?.split('-')[1])][1] = moment(endDate).toISOString()

  // actualiza fecha de inicio y fin del PLAN
  dataPlan.startDate = dataPlan.ranges.reduce((minDate, [from]) => {
    if (moment(from).toDate() < minDate) return moment(from).toDate()
    return minDate
  }, Number.POSITIVE_INFINITY)
  dataPlan.startDate = dataPlan.startDate.toISOString()
  dataPlan.endDate = dataPlan.ranges.reduce((maxDate, [__, to]) => {
    if (moment(to).toDate() > maxDate) return moment(to).toDate()
    return maxDate
  }, Number.NEGATIVE_INFINITY)
  dataPlan.endDate = dataPlan.endDate.toISOString()

  return {scheduledTimes, indexLesson: 0, dataPlan}
}

const nextPage = (scheduledTimes, keyLesson) => {
  const next = nextLesson(scheduledTimes)
  const {nextKey, nextIndex} = next
  if (keyLesson === 'Nodefined-0') {
    return {
      page: 'end',
      key: keyLesson,
      index: 0,
    }
  }
  if (next !== -1) {
    return {
      page: detectChanges(keyLesson, nextKey),
      key: nextKey,
      index: nextIndex,
    } // next page
  }
  return {
    page: 'end',
    key: keyLesson,
    index: scheduledTimes[keyLesson].times.length - 1,
  }
}

const makeObjectConflictOnboarding = (notscheduled, services) => {
  /**
     *  topicId
        lessonName
        from
        to
        educatorUserId
        educatorFirstName
        educatorLastName
        serviceId
        recurringId
     * **/
  return {
    indexOcurrence: 0,
    scheduledTimesOcurrences: notscheduled.map((lesson) => {
      return {
        isostring: new Date(lesson.from).toISOString(),
        recurringId: lesson.recurringId,
        topic: {
          id: lesson.topicId,
          name: services[lesson?.serviceId].topics[lesson.topicId].name,
        },
        educator: {
          id: lesson.educatorUserId,
          name: `${lesson.educatorFirstName} ${lesson.educatorLastName}`,
        },
        newTime: null,
        lockLesson: lesson.lockLesson,
      }
    }),
  }
}

const parseDataToMutation = (
  scheduledTimes,
  dataPlan,
  onboardingState,
  isAdmin = false,
) => {
  const mut = {planId: dataPlan.idPlan, onboardingState}
  const lessonsPerWeekPlanPart = dataPlan.ranges.map((range, indexPart) => {
    return {
      from: moment(range[0]).toDate(),
      to: moment(range[1]).toDate(),
      topicsPerWeek: Object.entries(scheduledTimes)
        .filter(
          ([key]) =>
            parseInt(key.split('-')[1]) === parseInt(indexPart) &&
            key.split('-')[0] !== 'Orientation',
        )
        .map(([key, objTopic]) => {
          const educator = objTopic.educator.filter((edu) => edu.selected)[0]
          return {
            topicId: objTopic?.topic?.id,
            lessonsBlocks: objTopic?.times?.map((time) => {
              return {
                time: moment(time).toDate(),
                lessonName: objTopic?.topic?.name,
                part: parseInt(key.split('-')[1]),
              }
            }),
            educatorInfo: {
              educatorUserId: educator?.id,
              firstName: educator?.name?.split(' ')[0],
              lastName: educator?.name?.split(' ')[1],
            },
            fixedNumberLessonPerWeek: objTopic?.times?.length,
            lockLesson: objTopic?.lockLesson || false,
          }
        }),
    }
  })
  return {...mut, lessonsPerWeekPlanPart, isAdmin}
}

const parseDataToMutationManual = (
  scheduledTimesOcurrences,
  dataPlan,
  onboardingState,
  isAdmin = false,
) => {
  /**
   *  {
        isostring: new Date(lesson.from).toISOString(),
        recurringId: lesson.recurringId,
        topic: {
          id: lesson.topicId,
          name: services[lesson?.serviceId].topics[lesson.topicId].name,
        },
        educator: {
          id: lesson.educatorUserId,
          name: `${lesson.educatorFirstName} ${lesson.educatorLastName}`,
        },
        newTime: null,
        lockLesson: lesson?.lockLesson || false
      }
   * **/
  const mut = {planId: dataPlan.idPlan, onboardingState}
  /**
   * {
    *  topicId: ID!
       educatorId: ID!
       lessonName: String!
       lessonDuration: Float!
       time: DateTime!
       recurringId: String!
     }
   * **/
  const lessons = scheduledTimesOcurrences.map((lesson) => {
    return {
      topicId: lesson?.topic?.id,
      educatorId: lesson?.educator?.id,
      lessonName: lesson?.topic?.name,
      lessonDuration: 50,
      time: lesson?.newTime?.time,
      recurringId: lesson?.recurringId,
      lockLesson: lesson?.lockLesson || false,
    }
  })
  return {
    isAdmin,
    lessons: {
      ...mut,
      lessons: lessons,
    },
  }
}

const parseDataOrToMutation = (scheduledTimes, dataPlan, isAdmin = false) => {
  const orientationKey = Object.keys(scheduledTimes).find((key) =>
    key
      .split('-')
      .map((text) => text.toUpperCase())
      .includes('ORIENTATION'),
  )
  let orObj = scheduledTimes[orientationKey || 'Orientation-0']
  orObj = {
    startDate: orObj?.times?.length
      ? moment(orObj.times[0]).toISOString()
      : moment().toISOString(),
    endDate: orObj?.times?.length
      ? moment(orObj.times[0]).add(15, 'minutes').toISOString()
      : moment().add(15, 'minutes').toISOString(),
    councelorId: orObj.educator.length ? orObj.educator[0].id : '',
    planId: dataPlan.idPlan,
  }
  return {councelorInput: orObj, isAdmin, defaultStatus: 'draft'}
}

const parseIsoToMoment = (newScheduledTimes, zone) => {
  newScheduledTimes.data.scheduledTimes = Object.entries(
    newScheduledTimes?.data?.scheduledTimes,
  ).reduce((objScheduled, [topicKey, objTopic]) => {
    return {
      ...objScheduled,
      [topicKey]: {
        ...objTopic,
        times: objTopic.times.map((time) => {
          return moment(time).tz(zone)
        }),
      },
    }
  }, {})

  return newScheduledTimes
}

const changeEducatorForTopicPart = (scheduledTimes, keyLesson, newEducator) => {
  let educatorExist = false
  scheduledTimes[keyLesson].educator = scheduledTimes[keyLesson].educator.map(
    (edu) => {
      if (newEducator.id === edu.id) {
        educatorExist = true
        return {...newEducator, selected: true}
      }
      return {...edu, selected: false}
    },
  )
  // EDUCADOR NO ESTABA EN EL ARREGLO DE EDUCADORES
  if (!educatorExist)
    scheduledTimes[keyLesson].educator.push({...newEducator, selected: true})
  scheduledTimes[keyLesson].times = new Array(
    scheduledTimes[keyLesson].times.length,
  ).fill(null)
  return {scheduledTimes, indexLesson: 0}
}

const adjustConflict = (scheduledTimesOcurrences) => {
  return {
    indexOcurrence: 0,
    scheduledTimesOcurrences: scheduledTimesOcurrences.map((lesson) => {
      return {
        ...lesson,
        newTime: null,
      }
    }),
  }
}

const GetFeeFromRangeDate = (from, to, form) => {
  // Caso 1: Si tiene más lecciones en el primer mes que la cantidad de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (COMIENZA AL MENOS UNA SEMANA ANTES DEL PRIMER MES)
  // Caso 2: Si tiene más lecciones en el último mes que el doble de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (si NO ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES)
  // Caso 3: Si la parte es tan corta como para NO COMENZAR AL MENOS UNA SEMANA ANTES DEL PRIMER MES y
  // ademas ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES se debe aplicar un solo pago al final
  // del segundo mes
  const BeginAtLeastOneWeekBeforeFirtsMonth =
    moment(from).endOf('month').diff(moment(from), 'days') >= 7
  const FinishBeforeSecondWeekOfFinalMonth =
    moment(to).diff(moment(to).startOf('month'), 'days') < 14
  from = moment(from).startOf('month')
  to = moment(to).endOf('month')
  const payments = []
  let c = 0
  while (moment(from) < moment(to)) {
    if (c === 0) {
      // primer payment
      if (BeginAtLeastOneWeekBeforeFirtsMonth) {
        // aplicar payment al final del mes FROM
        // caso 1
        payments.push({
          time: moment(from),
          value: form.amount ? form.amount : 0,
          custom: false,
        })
      }
    } else if (
      parseInt(moment(from).clone().format('M')) ===
      parseInt(moment(to).format('M'))
    ) {
      // ultimo payment
      if (!FinishBeforeSecondWeekOfFinalMonth || payments.length === 0) {
        // aplicar payment al final del mes TO
        // caso 2
        payments.push({
          time: moment(from),
          value: form.amount ? form.amount : 0,
          custom: false,
        })
      }
    } else {
      payments.push({
        time: moment(from),
        value: form.amount ? form.amount : 0,
        custom: false,
      })
    }
    from = moment(from).add(1, 'M')
    c++
  }
  return payments
}
const GetFeeCountFromRangeDate = (from, to) => {
  // Caso 1: Si tiene más lecciones en el primer mes que la cantidad de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (COMIENZA AL MENOS UNA SEMANA ANTES DEL PRIMER MES)
  // Caso 2: Si tiene más lecciones en el último mes que el doble de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (si NO ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES)
  // Caso 3: Si la parte es tan corta como para NO COMENZAR AL MENOS UNA SEMANA ANTES DEL PRIMER MES y
  // ademas ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES se debe aplicar un solo pago al final
  // del segundo mes
  if (from.valueOf() < moment().valueOf()) {
    from = moment()
  }
  const BeginAtLeastOneWeekBeforeFirtsMonth =
    moment(from).endOf('month').diff(moment(from), 'days') >= 7
  const FinishBeforeSecondWeekOfFinalMonth =
    moment(to).diff(moment(to).startOf('month'), 'days') < 14
  from = moment(from).startOf('month')
  to = moment(to).endOf('month')
  const payments = []
  let c = 0
  while (moment(from) < moment(to)) {
    if (c === 0) {
      // primer payment
      if (BeginAtLeastOneWeekBeforeFirtsMonth) {
        // aplicar payment al final del mes FROM
        // caso 1
        payments.push({
          time: moment(from),
          value: 0,
          custom: false,
        })
      }
    } else if (
      parseInt(moment(from).clone().format('M')) ===
      parseInt(moment(to).format('M'))
    ) {
      // ultimo payment
      if (!FinishBeforeSecondWeekOfFinalMonth || payments.length === 0) {
        // aplicar payment al final del mes TO
        // caso 2
        payments.push({
          time: moment(from),
          value: 0,
          custom: false,
        })
      }
    } else {
      payments.push({
        time: moment(from),
        value: 0,
        custom: false,
      })
    }
    from = moment(from).add(1, 'M')
    c++
  }
  return payments
}
const getReduxInitialState = () => {
  return {
    mainPage: {
      page: 'welcome',
    },
    smallModal: {
      isOpen: false,
      page: 'introductionPart',
    },
    mediumModal: {
      isOpen: false,
      page: 'information',
    },
    conflictModal: {
      isOpen: false,
      page: 'listConflict',
    },
    data: {},
  }
}

const rescheduledLessonCalendar = (scheduledTimes, keyLesson, indexLesson) => {
  scheduledTimes[keyLesson].times[indexLesson] = null
  return {
    scheduledTimes,
    keyLesson,
    indexLesson,
  }
}

const rescheduledOcurrenceLessonCalendar = (
  scheduledTimesOcurrences,
  indexOcurrence,
) => {
  scheduledTimesOcurrences[indexOcurrence].newTime = null
  return {
    scheduledTimesOcurrences,
    indexOcurrence,
  }
}

export {
  makeObjectOnboarding,
  nextLesson,
  getCurrentTopicPart,
  detectChanges,
  checkIfNeedSelectLpw,
  setTimeOnTopicPart,
  adjustAll,
  adjustTopicPart,
  selectLpwForTopicPart,
  nextPage,
  makeObjectConflictOnboarding,
  parseDataToMutation,
  parseDataOrToMutation,
  parseDataToMutationManual,
  adjustTopicLesson,
  parseIsoToMoment,
  setTimeOnTopicPartLesson,
  changeEducatorForTopicPart,
  adjustConflict,
  GetFeeFromRangeDate,
  GetFeeCountFromRangeDate,
  getReduxInitialState,
  rescheduledLessonCalendar,
  rescheduledOcurrenceLessonCalendar,
}
