const queryPartialUniversityResult = `
id
status
early
name
collegeId
logo
isPrincipal
`

export default queryPartialUniversityResult
