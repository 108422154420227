import stanford from './iconsMock/stanford.png'
import harvard from './iconsMock/harvard.png'
import johnsHopkinsUniversity from './iconsMock/johnsHopkinsUniversity.png'

export const collegesMock = [
  {
    id: 1,
    img: stanford,
    name: 'Stanford University',
  },
  {
    id: 2,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 3,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
  {
    id: 4,
    img: stanford,
    name: 'Stanford University',
  },
  {
    id: 5,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 6,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
  {
    id: 7,
    img: stanford,
    name: 'Stanford University',
  },
  {
    id: 8,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 9,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
  {
    id: 10,
    img: stanford,
    name: 'Stanford University',
  },
  {
    id: 11,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 12,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
  {
    id: 13,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 14,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
  {
    id: 15,
    img: stanford,
    name: 'Stanford University',
  },
  {
    id: 16,
    img: harvard,
    name: 'Harvard University',
  },
  {
    id: 17,
    img: johnsHopkinsUniversity,
    name: 'Johns Hopkins University',
  },
]
