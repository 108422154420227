export default {
  totalLessons: (num) => {
    return `${num} clases`
  },
  lessonRate: (num) => {
    return `$${num} por clases`
  },
  registrationFee: 'Matrículo',
  toeflUpgrade: 'Acceso TOEFL Pro',
  approvalTitle: 'Nos entusiasma trabajar contigo!',
  breakdown: 'Desglose de plan',
  paymentSchedule: 'Calendario de pago',
}
