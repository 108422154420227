import React, {useState, useEffect} from 'react'
import {
  Button,
  IconArrowRight,
  IconArrowLeft,
  IconProgram,
  ToolTipInfo,
  IconWspAlert,
  IconEdit,
  IconEmail,
  CheckboxOption,
  IconErase,
  useWindowDimensions,
  IconOptions,
  IconLoad,
} from '@Knowledge-OTP/znk-ui-components'
import CalendarLesson from '../../../CalendarLessons/Components/CalendarLesson'
import moment from 'moment'
import reScheduleLessonFromAdmin from '../../../../Model/Lessons/ReScheduleLessonFromAdmin'
import {useDispatch, useSelector} from 'react-redux'
import {parseLesson} from '../../utils'
import {useHistory} from 'react-router'
import useTranslation from '../../../../i18n/useTranslation'
import {TabContent, TabPane} from 'reactstrap'

const CalendarSection = (props) => {
  const {lessons, week, setWeek, from, upcomming, loading, openSideLeft} = props
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const history = useHistory()
  const {translate} = useTranslation()
  const zone = useSelector((state) => state.user.info.timezone)
  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find((serv) => serv.isOrientation)

  // MUTACIÓN PARA REAGENDAR LECCIÓN
  const {submit: rescheduleSubmit} = reScheduleLessonFromAdmin(
    (result, {startDate: newTime, lessonId: draggableId, oldDataLesson}) => {
      if (result?.data?.reScheduleLessonFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.reScheduleLessonFromAdmin?.occurrencesList
        // mostrar confirmación de agendamiento
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.confirmDropRescheduled',
          data: {
            isOpen: true,
            collissionKinds: ocu[0].collissionKinds,
            confirmAction: () => {
              setLessonsCalendarArray(
                lessonsCalendarArray.map((lesson) => {
                  if (lesson.idLesson === oldDataLesson.idLesson)
                    return oldDataLesson
                  return lesson
                }),
              )
            }, // UNDO
            cancelAction: () => {
              // EJECUTA LA MUTACIÓN
              rescheduleSubmit({
                startDate: newTime,
                lessonId: draggableId,
                ignoreEducatorUnavailability: true,
                oldDataLesson,
              })
            }, // SCHEDULED ANYWAY
          },
        })
      } else {
        // mostrar alerta de agendamiento exitoso
      }
    },
    (e, oldDataLesson) => {
      console.error(e)
      setLessonsCalendarArray(
        lessonsCalendarArray.map((lesson) => {
          if (lesson.idLesson === oldDataLesson.idLesson) return oldDataLesson
          return lesson
        }),
      )
    },
  )

  const [lessonsSelected, setLessonsSelected] = useState([])

  const appendLessonToSelected = (id) => {
    setLessonsSelected(lessonsSelected.concat([id]))
  }
  const removeLessonFromSelected = (id) => {
    setLessonsSelected(
      lessonsSelected.reduce((newArray, idLesson) => {
        if (idLesson !== id) newArray.push(idLesson)
        return newArray
      }, []),
    )
  }

  const OptionSelection = ({IconComponent, label, onClick}) => {
    // renderiza las opciones que aparecen al seleccionar algun bloque
    return (
      <span className='mx-2' onClick={onClick} style={{cursor: 'pointer'}}>
        <IconComponent size={15} />
        <span className='ml-1 h5'>{label}</span>
      </span>
    )
  }

  const SetMultipleLessonsInPending = (lessons) => {
    if (lessons.length === 1) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.setPendingLesson',
        data: {
          isOpen: true,
          lessonId: lessons[0] || '',
        },
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'lesson.setPendingMultiple',
        data: {
          isOpen: true,
          lessonsId: lessons,
        },
      })
    }
  }

  const RemoveMultipleLessons = (lessons) => {
    if (lessons.length === 1) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.cancelLesson',
        data: {
          isOpen: true,
          lessonId: lessons[0] || '',
        },
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'lesson.cancelMultiple',
        data: {
          isOpen: true,
          lessonsId: lessons,
        },
      })
    }
  }

  const [lessonsCalendarArray, setLessonsCalendarArray] = useState([])

  useEffect(() => {
    if (lessons)
      setLessonsCalendarArray(
        parseLesson(
          lessons,
          upcomming,
          dispatch,
          translate,
          zone,
          orientation?.id,
        ),
      )
    // eslint-disable-next-line
  }, [lessons])

  useEffect(() => {
    if (lessonsSelected.length === 1) {
      const isGroupLesson = lessonsCalendarArray.filter(
        (lesson) => lesson.idLesson === lessonsSelected[0],
      )[0]?.isGroup
      // si la primera lección seleccinada es grupal
      // => solo puedo continuar seleccionando grupales
      // si es individual => solo puedo continuar seleccionando individuales
      setLessonsCalendarArray(
        lessonsCalendarArray.map((lesson) => {
          if (lesson?.isGroup) lesson.canChecked = isGroupLesson
          else lesson.canChecked = !isGroupLesson
          return lesson
        }),
      )
    } else if (
      lessonsSelected.length === 0 &&
      lessonsCalendarArray.length > 0
    ) {
      // todos se pueden seleccionar
      setLessonsCalendarArray(
        lessonsCalendarArray.map((lesson) => {
          lesson.canChecked = true
          return lesson
        }),
      )
    }
    // eslint-disable-next-line
  }, [lessonsSelected]) 
  return (
    <div
      className={`d-flex flex-column m-0 py-0 pr-0 ${
        openSideLeft ? 'pl-6' : 'pl-0'
      }`}
    >
      <div
        className='w-100 d-flex flex-row justify-content-between py-4'
        style={{
          maxHeight: '10vh',
          height: '10vh',
        }}
      >
        {lessonsSelected.length > 0 ? (
          <div className='d-flex flex-row justify-content-between w-100'>
            <div className='d-flex flex-row'>
              <CheckboxOption
                onChange={(e) => {
                  if (!e) setLessonsSelected([])
                }}
                className='custom-control-input-calendar-admin'
                defaultState={lessonsSelected.length > 0}
              />
              <span className='mr-2 ml-0'>
                <span className='ml-0 h4'>
                  {`${lessonsSelected.length} ${
                    lessonsSelected.length === 1
                      ? translate('lesson.multipleselection.lessonselec')
                      : translate('lesson.multipleselection.lessonsselec')
                  }`}
                </span>
              </span>
            </div>
            <div className='d-flex flex-row'>
              {lessonsSelected.length === 1 && (
                <>
                  <OptionSelection
                    IconComponent={IconWspAlert}
                    label={translate('modal.infolesson.sendwspalert')}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.wspAlert',
                        data: {
                          isOpen: true,
                          dataLesson: lessons.filter(
                            (lesson) => lesson.id === lessonsSelected[0],
                          )[0],
                        },
                      })
                    }}
                  />
                  <OptionSelection
                    IconComponent={IconEmail}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.sendEmailReminder',
                        data: {
                          isOpen: true,
                          dataLesson: lessons.filter(
                            (lesson) => lesson.id === lessonsSelected[0],
                          )[0],
                        },
                      })
                    }}
                    label={translate('modal.infolesson.sendreminder')}
                  />
                  <OptionSelection
                    IconComponent={IconEdit}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.editLessonModal',
                        data: {
                          isOpen: true,
                          dataLesson: lessons.filter(
                            (lesson) => lesson.id === lessonsSelected[0],
                          )[0],
                        },
                      })
                    }}
                    label={translate('modal.infolesson.editlesson')}
                  />
                </>
              )}
              {!lessonsCalendarArray.filter(
                (lesson) => lesson.idLesson === lessonsSelected[0],
              )[0]?.isGroup && (
                <OptionSelection
                  IconComponent={IconProgram}
                  label={translate('modal.infolesson.leavepending')}
                  onClick={() => {
                    SetMultipleLessonsInPending(lessonsSelected)
                  }}
                />
              )}
              <OptionSelection
                IconComponent={IconErase}
                label={translate('lesson.multipleselection.remove')}
                onClick={() => {
                  RemoveMultipleLessons(lessonsSelected)
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className='d-flex flex-row justify-content-between align-item-center w-100'>
              <div className='m-0 p-0 d-flex flex-row'>
                <Button
                  className='align-self-center'
                  onClick={() => {
                    setWeek(0)
                  }}
                  style={{
                    width: '100px',
                    color: '#254050',
                    lineHeight: '22px',
                    border: 'solid 1px #DCE1E7',
                  }}
                  label={translate('lessons.calendar.today').toUpperCase()}
                  size='small'
                />
                <div className='week-control d-flex flex-row align-items-center px-4'>
                  <div
                    style={{width: '80px'}}
                    className='d-flex flex-row justify-content-between'
                  >
                    <ToolTipInfo
                      tooltipId='last-week'
                      innerElement={
                        <span className='h45 text-pause'>Last week</span>
                      }
                      place='bottom'
                    >
                      <div
                        className='ml-2 mr-0 my-0 hover-icon'
                        onClick={() => {
                          setWeek(week - 1)
                        }}
                      >
                        <IconArrowLeft size={20} />
                      </div>
                    </ToolTipInfo>
                    <ToolTipInfo
                      tooltipId='next-week'
                      innerElement={
                        <span className='h45 text-pause'>Next week</span>
                      }
                      place='bottom'
                    >
                      <div
                        className='mx-2 mr-0 my-0 hover-icon'
                        onClick={() => {
                          setWeek(week + 1)
                        }}
                      >
                        <IconArrowRight size={20} />
                      </div>
                    </ToolTipInfo>
                  </div>
                  <span className='ml-4' style={{textTransform: 'capitalize'}}>
                    {`
                      ${moment(from)
                        .startOf('isoWeek')
                        .format('MMMM Do')} - ${moment(from)
                      .endOf('isoWeek')
                      .format('MMMM Do')}
                    `}
                  </span>
                </div>
              </div>
              <div className='m-0 p-0 d-flex flex-row align-items-center mr-4'>
                <TabContent activeTab={loading ? 'loading' : 'none'}>
                  <TabPane tabId='loading'>
                    <IconLoad className='ml-2' size={20} />
                  </TabPane>
                  <TabPane tabId='none' />
                </TabContent>
                <div
                  className='hover-icon'
                  onClick={() => {
                    history.push('lessons')
                  }}
                >
                  <IconOptions style={{transform: 'rotate(90deg)'}} size={15} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CalendarLesson
        selectedBlocks={lessonsSelected}
        selectBlock={(idLesson) => {
          appendLessonToSelected(idLesson)
        }}
        deselectBlock={(idLesson) => {
          removeLessonFromSelected(idLesson)
        }}
        from={from}
        heightColumnPx={72}
        widthColumnPx={180}
        verticalView={breakWidth === 'SM'}
        lessons={lessonsCalendarArray}
        style={
          breakWidth === 'SM'
            ? {}
            : {
                maxHeight: '90vh',
                height: '90vh',
              }
        }
        zone={zone}
        changeTimezoneBullet={
          <div className='m-0 p-0 d-flex flex-column text-center'>
            <span className='h45 text-pause'>{zone}</span>
            <span className='h45 text-gray'>
              {translate('lessons.calendar.clickToChange')}
            </span>
          </div>
        }
        changeTimezoneClickAction={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'calendar.changeTimezoneModal',
            data: {
              isOpen: true,
            },
          })
        }}
        onClickInFreeBloc={({day, hour, fromCalendar}) => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.newLessonModal',
            data: {
              isOpen: true,
              defaultForm: {
                timeStart: moment(fromCalendar)
                  .add(day, 'day')
                  .set({hour: hour, minutes: 0})
                  .format('HH:mm'),
                timeEnd: moment(fromCalendar)
                  .add(day, 'day')
                  .set({hour: hour, minutes: 50})
                  .format('HH:mm'),
                date: moment(fromCalendar).add(day, 'day').format('YYYY-MM-DD'),
                paymentDate: moment().format('YYYY-MM-DD'),
              },
            },
          })
        }}
        handleDropLesson={null}
        loading={loading}
        disabledDragAndDrop
        disabledFreeAction
      />
    </div>
  )
}

export default CalendarSection
