// String con modelo de objeto que será utilizado para
// obtener una Universidad de un estudiante.
const myUniversity = `
studentInfo{
    universities {
        id
        status
        early
        name
        collegeId
        }
    }
`

export default myUniversity
