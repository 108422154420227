import React from 'react'
import {
  IconOutLine,
  IconProgram,
  IconNotes,
  IconStudent,
  Button,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'

const Welcome = (props) => {
  const {setSection, ready} = props
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()

  return (
    <>
      {/* <div
        className='d-flex flex-row'
        style={{
          position: 'absolute',
          left: '15px',
          top: '10px',
          cursor: 'pointer',
        }}
      >
        <IconBrand size={50} color='#35425B' />
        {breakWidth !== 'SM' && (
          <IconLogo className='ml-4' size={50} color='#35425B' />
        )}
      </div> */}
      <div className='d-flex flex-column w-100 h-100 align-items-center justify-content-center'>
        <div
          className='d-flex flex-column'
          style={breakWidth !== 'SM' ? {width: '32%'} : {width: '90%'}}
        >
          <div
            className={`d-flex flex-column ${
              breakWidth === 'SM' ? 'mt-7' : ''
            }`}
          >
            <h1>
              <b>{translate('counselingOnboarding.welcome.title')}</b>
            </h1>
            <h2 className='text-muted'>
              {translate('counselingOnboarding.welcome.follow')}
              <br />
              {translate('counselingOnboarding.welcome.followSub')}
            </h2>
          </div>
          <div className='d-flex my-5 w-100'>
            <div className='m-0 p-0 w-auto d-flex flex-column'>
              <IconOutLine
                className='p-3'
                backgroundColor='#A7E521'
                color='white'
                ImgComponent={IconNotes}
                sizePx={40}
              />
              <div
                className='m-0 p-0 my-1 align-self-center'
                style={{
                  height: '16px',
                  width: '3px',
                  backgroundColor: '#122A55',
                  opacity: '10%',
                }}
              />
              <IconOutLine
                className='p-3'
                backgroundColor='#03C3EC'
                color='white'
                ImgComponent={IconProgram}
                sizePx={40}
              />
              <div
                className='m-0 p-0 my-1 align-self-center'
                style={{
                  height: '16px',
                  width: '3px',
                  backgroundColor: '#122A55',
                  opacity: '10%',
                }}
              />
              <IconOutLine
                className='p-3'
                backgroundColor='#FFD422'
                color='white'
                ImgComponent={IconStudent}
                sizePx={40}
              />
            </div>
            <div className='w-auto text-muted d-flex flex-column justify-content-between'>
              <span className='ml-4'>
                {translate('counselingOnboarding.welcome.step')} 1 <br />{' '}
                {translate('counselingOnboarding.welcome.step1')}
              </span>
              <span className='ml-4'>
                {translate('counselingOnboarding.welcome.step')} 2 <br />{' '}
                {translate('counselingOnboarding.welcome.step2')}
              </span>
              <span className='ml-4'>
                {translate('counselingOnboarding.welcome.step')} 3 <br />{' '}
                {translate('counselingOnboarding.welcome.step3')}
              </span>
            </div>
          </div>
          <div className='w-75 m-0 p-0 d-flex flex-column align-self-baseline'>
            <Button
              className='w-100 m-0'
              color='counseling'
              label={translate('counselingOnboarding.welcome.goto')}
              check
              activity={!ready}
              onClick={() => {
                setSection('proposal')
              }}
            />
          </div>
          {/* <div
            style={{
              bottom: '25px',
              right: '25px',
            }}
            className='znk-option-item znk-option-item-primary position-absolute'
            onClick={() => {
              showFrontappChat()
            }}
          >
            <IconMessages />
          </div> */}
        </div>
      </div>
    </>
  )
}

Welcome.propTypes = {}
export default Welcome
