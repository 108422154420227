import React from 'react'
import {IconClose, Input, Button} from '@Knowledge-OTP/znk-ui-components'

const ChangePassword = (props) => {
  const {toggle} = props
  return (
    <div className='w-100 py-5 px-8' style={{overflowY: 'scroll'}}>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Change Password</h2>
        <IconClose
          size={20}
          onClick={toggle}
          color='rgba(119, 134, 153, .4)'
          style={{cursor: 'pointer'}}
        />
      </span>
      <div className='container-fluid flex-column my-2'>
        <div className='p-0 mx-0 my-4'>
          <span className='text-title'>New Password</span>
          <Input type='password' />
        </div>
        <div className='p-0 mx-0 my-4'>
          <span className='text-title'>Confirm Password</span>
          <Input type='password' />
        </div>
        <Button color='primary' label='Change password' check />
      </div>
    </div>
  )
}
export default ChangePassword
