export const SET_USER_INFO_ACTION = 'SET_USER_INFO_ACTION'
export const SET_USER_LOADING_INFO_ACTION = 'SET_USER_LOADING_INFO_ACTION'
export const SET_QUERY_ME = 'SET_QUERY_ME'
export const CHANGE_TIMEZONE = 'CHANGE_TIMEZONE'
export const UPDATE_UNIVERSITIES = 'UPDATE_UNIVERSITIES'
export const UPDATE_COLLEGE = 'UPDATE_COLLEGE'
// export const FILTER_DOCS_AND_EXAMS = 'FILTER_DOCS_AND_EXAMS'
export const FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY =
  'FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY'
export const FILTER_DOCS_AND_EXAMS_BY_TYPE = 'FILTER_DOCS_AND_EXAMS_BY_TYPE'
export const UPDATE_TASK = 'UPDATE_TASK'
export const COUNSELING_ALL_TASKS = 'COUNSELING_ALL_TASKS'
export const COUNSELING_MY_TEAM = 'COUNSELING_MY_TEAM'
