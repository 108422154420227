import misc from './misc'
import program from './program'
import profile from './Profile'
import lessons from './Lessons'
import home from './Home'
import modal from './modal'
import virtualChat from './VirtualChat'
import counselingPlan from './CounselingPlan'
export default {
  misc,
  program,
  profile,
  lessons,
  home,
  modal,
  virtualChat,
  counselingPlan,
}
