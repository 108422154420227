import React from 'react'
import {IconIllustratedPending} from '@Knowledge-OTP/znk-ui-components'

export const OrientationErrorHeader = (props) => {
  const {error = null, toggle = () => {}} = props
  return (
    <div className='m-0 p-0 my-2'>
      <IconIllustratedPending size={80} />
      <h2 className='w-100 my-3'>
        <b>Scheduled error</b>
      </h2>
      <h4 className='text-error'>{error?.msg?.toString()}</h4>
      <div className='mt-5 w-100 d-flex flex-column justify-content-center align-items-end'>
        <span
          onClick={() => {
            // cierra modal
            if (error?.action) error.action()
          }}
          className='btn-link font-weight-bold text-error'
        >
          {error?.actionLabel}
        </span>
        <span className='btn-link text-gray mr-2' onClick={toggle}>
          Cancel
        </span>
      </div>
    </div>
  )
}
