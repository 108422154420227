import React from 'react'
import {useSelector} from 'react-redux'
import {
  useWindowDimensions,
  ModalLayout,
  IconArrowLeft,
} from '@Knowledge-OTP/znk-ui-components'
import EducatorSelector from './EducatorSelector'
import useModalState from '../../../../../hooks/useModalState'
import {TabContent, TabPane} from 'reactstrap'
import TopicSelection from './TopicSelection'
import TopicSelectionHeader from './TopicSelection/header'
import EducatorSelectorHeader from './EducatorSelector/header'
import TimeSelectionHeader from './TimeSelectionModal/header'
import TimeSelection from './TimeSelectionModal'
import ConfirmLesson from './ConfirmLesson'
import ConfirmLessonHeader from './ConfirmLesson/header'
import PaymentModalForm from '../../../../Profile/Modal/ChangePM'
import PaymentSelectionHeader from './PaymentSelectionHeader'

const NewLesson = (props) => {
  const paymentMethod = useSelector((state) => state.user.info.paymentMethod)
  const {breakWidth} = useWindowDimensions()
  const {isOpen, toggle, onChange, modalData} = useModalState(
    'program.newManualLesson',
  )

  const stage = paymentMethod === null ? -1 : modalData?.stage
  const clearAndExit = () => {
    onChange(() => ({
      serviceId: null,
      topicId: null,
      educator: null,
      dateTime: null,
      stage: 0,
    }))
    if (modalData?.refetch) modalData.refetch()
    toggle()
  }

  const Back = ({stage}) => {
    const disabled = [0].includes(stage)
    return (
      <div
        onClick={() => {
          if (disabled) return
          onChange((s) => ({...s, stage: s.stage - 1}))
        }}
        className={`${
          disabled ? 'hover-icon cursor-not-allowed' : 'hover-icon'
        }`}
      >
        <IconArrowLeft
          size={20}
          className={`${
            disabled ? 'cursor-not-allowed text-muted' : 'text-gray'
          }`}
        />
      </div>
    )
  }

  return (
    <ModalLayout
      disabledScrollAnimation
      className={`modal-colored modal-${
        breakWidth === 'SM' ? 'botbar' : 'centered'
      } white-modal`}
      isOpen={isOpen}
      toggle={toggle}
      topHead={
        <Back stage={stage} />
        // <TabContent activeTab={stage}>
        //   <TabPane tabId={0}>
        //     <TopicSelectionHeader clearAndExit={clearAndExit} />
        //   </TabPane>
        //   <TabPane tabId={1}>
        //     <IconArrowLeft
        //       size={20}
        //       className='text-gray'
        //       onClick={() => {
        //         onChange((s) => ({ ...s, stage: s.stage - 1 }))
        //       }}
        //     />
        //     <EducatorSelectorHeader clearAndExit={clearAndExit} />
        //   </TabPane>
        // </TabContent>
      }
      underHead={
        <div className='modal-header d-flex flex-column pb-0'>
          <TabContent activeTab={stage} className='w-100'>
            <TabPane tabId={-1}>
              <PaymentSelectionHeader />
            </TabPane>
            <TabPane tabId={0}>
              <TopicSelectionHeader clearAndExit={clearAndExit} />
            </TabPane>
            <TabPane tabId={1}>
              <EducatorSelectorHeader clearAndExit={clearAndExit} />
            </TabPane>
            <TabPane tabId={2}>
              <TimeSelectionHeader clearAndExit={clearAndExit} />
            </TabPane>
            <TabPane tabId={3}>
              <ConfirmLessonHeader clearAndExit={clearAndExit} />
            </TabPane>
          </TabContent>
        </div>
      }
    >
      <TabContent activeTab={stage} className='pb-4 px-4 pt-0'>
        <TabPane tabId={-1}>
          <PaymentModalForm />
        </TabPane>
        <TabPane tabId={0}>
          <TopicSelection />
        </TabPane>
        <TabPane tabId={1}>
          <EducatorSelector />
        </TabPane>
        <TabPane tabId={2}>
          <TimeSelection />
        </TabPane>
        <TabPane tabId={3}>
          <ConfirmLesson clearAndExit={clearAndExit} />
        </TabPane>
      </TabContent>
    </ModalLayout>
  )
}

export default NewLesson
