import React, {useState, useMemo, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ModalLayout,
  Input,
  InputPassword,
  Button,
  IconArrowRight,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useShowLoginInfoModal from './useShowLoginInfoModal'
import useTranslation from '../../../../i18n/useTranslation'
import useGetAccounts from '../../../../Model/Users/Accounts/IdsAccounts/useGetAccounts'
import useTaskLoginInfo from '../../../Task/Model/useTaskLoginInfo'
import useAllUserTasks from '../../../Task/Model/useAllUserTasks'
import useOperationTaskMutation from '../../../Task/Model/useOperationTask'
import {UseGetUserTask} from '../../../../Model/Users/Student/getUserTask/useGetUserTask'
import {COUNSELING_ALL_TASKS, UPDATE_TASK} from '../../../../store/user/const'

import MessageModal from '../ViewTask/Components/Modals/messageModal'
import useShowTask from '../ViewTask/useShowTask'
import phaseNames from '../../Utils/phaseNames'

const ShowLoginInfoModal = () => {
  const {isOpen, toggle, task, close} = useShowLoginInfoModal()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const [username, setUsername] = useState('')
  const [passwordForm, setPasswordForm] = useState('')
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const {setTaskData} = useShowTask()
  const planId = useSelector(
    (state) =>
      state?.user?.info.studentInfo?.homeNotification?.councelingPlanId,
  )
  const titleModal = translate('counseling.loginInfoConfirmTitle')
  const bodyModal = translate('counseling.loginInfoConfirmBody')

  const confirmFunction = async () => {
    try {
      await taskLoginInfoMutation({
        accountInput: {
          platformName: platform,
          username: username,
          password: passwordForm,
        },
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || task?.phaseName || phaseNames[task?.phaseId],
        planId: planId,
      })
      await sendToReviewFunction()
      dispatch({
        type: UPDATE_TASK,
        data: logininfoData,
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
    setConfirmModalOpen(false)
  }

  const sendToReviewFunction = () => {
    operationMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || task?.phaseName || phaseNames[task?.phaseId],
        planId: planId,
      },
    })
  }
  const inputPass = useRef(null)

  const isDisabled = task?.status !== 'pending'

  const disabled = useMemo(() => {
    return username === '' || passwordForm === ''
    // eslint-disable-next-line
  }, [username, passwordForm])

  // eslint-disable-next-line
  const { data: accountsIds, refetch: refetchAccounts, loading: loadAccount } = useGetAccounts()

  const accountsNames = useMemo(() => {
    if (accountsIds) {
      const accountFound = accountsIds?.getAccounts.filter(
        (acc) => acc.id === task?.accountsPlatform,
      )
      return accountFound[0]
    }
    // eslint-disable-next-line
  }, [accountsIds, task])

  // platform value
  const platform = useMemo(() => {
    return task?.resolveTask?.loginInfo?.platform?.name || accountsNames?.name
  }, [task, accountsNames])

  const validationFunction = () => {
    if (username === '' || passwordForm === '') {
      setMessage(translate('counseling.msgValidationLoginTask'))
      setMessageModalOpen(true)
    } else {
      setConfirmModalOpen(true)
    }
  }

  const {
    submit: taskLoginInfoMutation,
    loading: loadMutation,
    data: logininfoData,
  } = useTaskLoginInfo(() => {})

  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  const [
    // eslint-disable-next-line
      operationMutation, { loading: loadOperationTask, data: dataOperationTask },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
    },
    (err) => {
      console.error('Error on send to review task : ', err)
    },
  )

  // eslint-disable-next-line
    const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      setTaskData(resp?.getUserTask)
      close()
      refetchTasks().then((resp) => {
        dispatch({
          type: COUNSELING_ALL_TASKS,
          payload: 'counseling.allTasks',
          data: resp?.data?.getUserTasks,
        })
      })
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={toggle}
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        topHead={
          <span className='h2' style={{color: '#485a70'}}>
            {task?.name}
          </span>
        }
      >
        <div className='d-flex flex-column mx-0 mt-4 mb-0 p-0'>
          <div className='d-flex flex-column mb-5'>
            <span className='h45 text-gray mb-2'>
              {translate('counseling.account')}
            </span>
            <Input
              className='znk-input h45 pl-3'
              type='text'
              id='platform'
              name='platform'
              placeholder='Account name'
              disabled
              value={platform}
            />
          </div>

          <div className='d-flex flex-column mb-5'>
            <span className='h4 mb-2 text-gray h45'>
              {translate('counseling.user')}
            </span>
            <Input
              className='h45 pl-3'
              type='text'
              // id='user'
              // name='user'
              placeholder='username or email'
              defaultValue={
                task?.resolveTask
                  ? task?.resolveTask?.loginInfo?.platform?.username
                  : ''
              }
              onChange={(e) => setUsername(e.target.value)}
              disabled={isDisabled}
            />
          </div>

          <div className='d-flex flex-column mb-5'>
            <span className='h4 mb-2 text-gray h45'>
              {translate('counseling.password')}
            </span>
            <InputPassword
              type='password'
              // id='pass'
              // name='pass'
              ref={inputPass}
              placeholder='password'
              defaultValue={
                task?.resolveTask
                  ? task?.resolveTask?.loginInfo?.platform?.password
                  : ''
              }
              onChange={(e) => setPasswordForm(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          {!isDisabled && (
            <Button
              style={{
                width: '100%',
                height: '42px',
                border: '1px solid',
                borderRadius: '10px',
                borderColor: '#999bf7',
                backgroundColor: '#696bff',
                color: '#ffffff',
              }}
              disabled={disabled}
              className='w-100 mt-3'
              label={
                <div className='d-flex justify-content-between mx-n2 my-auto w-100'>
                  <div style={{cursor: 'pointer'}} className='col-10'>
                    <span className='d-flex justify-content-start align-middle'>
                      {task?.buttonLabel || translate('counseling.save')}
                    </span>
                  </div>
                  <div className='col-2 mr-n5 d-flex justify-content-end align-items-center'>
                    <IconArrowRight size={16} />
                  </div>
                </div>
              }
              color='primary'
              activity={loadOperationTask || loadMutation}
              onClick={() => validationFunction()}
            />
          )}
        </div>

        <MessageModal
          isOpen={messageModalOpen}
          toggle={() => setMessageModalOpen(!messageModalOpen)}
          message={message}
        />

        <ConfirmModal
          className='m-4'
          isOpen={confirmModalOpen}
          toggle={!confirmModalOpen}
          loading={loadMutation}
          title={titleModal}
          body={bodyModal}
          classNameActionDiv='d-flex flex-column justify-content-end w-80'
          confirmAction={() => {
            confirmFunction()
          }}
          cancelAction={() => setConfirmModalOpen(false)}
          confirmText={translate('counseling.loginInfoConfirmButton')}
          cancelText='Cancel'
        />
      </ModalLayout>
    </>
  )
}

ShowLoginInfoModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
}

ShowLoginInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ShowLoginInfoModal
