import {initialState} from './initialState'
import {
  SET_USER_INFO_ACTION,
  SET_QUERY_ME,
  CHANGE_TIMEZONE,
  UPDATE_UNIVERSITIES,
  UPDATE_COLLEGE,
  // FILTER_DOCS_AND_EXAMS
  FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY,
  FILTER_DOCS_AND_EXAMS_BY_TYPE,
  UPDATE_TASK,
  COUNSELING_ALL_TASKS,
  COUNSELING_MY_TEAM,
} from './const'
import moment from 'moment-timezone'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_INFO_ACTION:
      moment.tz.setDefault(action.payload.timezone || moment.tz.guess())
      return {
        ...state,
        info: action.payload,
        isSet: true,
      }
    case SET_QUERY_ME:
      return {
        ...state,
        refetch: action.payload,
      }
    case CHANGE_TIMEZONE:
      return {
        ...state,
        info: {
          ...state.info,
          timezone: action.data,
        },
      }
    case UPDATE_UNIVERSITIES:
      return {
        ...state,
        info: {
          ...state.info,
          studentInfo: {
            ...state.info.studentInfo,
            universities: action.payload,
          },
        },
      }
    case UPDATE_COLLEGE:
      return {
        ...state,
        info: {
          ...state.info,
          studentInfo: {
            ...state.info.studentInfo,
            universities: action.data,
          },
        },
      }
    case UPDATE_TASK:
      return {
        ...state,
        info: {
          ...state.info,
          studentInfo: {
            ...state.info.studentInfo,
            tasks: action.data,
          },
        },
      }
    case FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY:
      return {
        ...state,
        info: {
          ...state.info,
          studentInfo: {
            ...state.info.studentInfo,
            docsAndExamsFilter: action.data,
          },
        },
      }
    case FILTER_DOCS_AND_EXAMS_BY_TYPE:
      return {
        ...state,
        info: {
          ...state.info,
          studentInfo: {
            ...state.info.studentInfo,
            filterByType: action.data,
          },
        },
      }
    case COUNSELING_ALL_TASKS:
      return {
        ...state,
        counseling: {
          ...state.counseling,
          allTasks: action.data,
        },
      }
    case COUNSELING_MY_TEAM:
      return {
        ...state,
        counseling: {
          ...state.counseling,
          myTeam: action.data,
        },
      }
    default:
      return {...state}
  }
}
