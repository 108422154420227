const queryAccountResult = `
username
password
platform
originAccount 
taskInfo { 
    planId
    phase
    stepId
    taskId
}
`

export default queryAccountResult
