import React from 'react'
import examTypes from '../examTypes'
import ActTitle from './Exams/ACTTitle'
import ApTitle from './Exams/APTitle'
import DuolingoTitle from './Exams/DuolingoTitle'
import GreTitle from './Exams/GRETitle'
import IELTSTitle from './Exams/IELTSTitle'
import ISEETitle from './Exams/ISEETitle'
import LsatTitle from './Exams/LSATTitle'
import SatTitle from './Exams/SATTitle'
import SsatTitle from './Exams/SSATTitle'
import ToeflTitle from './Exams/TOEFLTitle'

const TitleScoresComponent = (props) => {
  const {scores} = props
  const titleClass = 'd-flex flex-row w-100 justify-content-between pl-7'
  const scoreItemClass = 'm-0 mr-1 py-4'

  return (
    <div>
      {/* Renderizar según el tipo de examen */}

      {scores?.name === examTypes.SAT && (
        <SatTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.ACT && (
        <ActTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.Duolingo && (
        <DuolingoTitle
          titleClass={titleClass}
          scoreItemClass={scoreItemClass}
        />
      )}

      {scores?.name === examTypes.AdvancedPlacement && (
        <ApTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.TOEFL && (
        <ToeflTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.IELTS && (
        <IELTSTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.SSAT && (
        <SsatTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.ISEE && (
        <ISEETitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.GRE && (
        <GreTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}

      {scores?.name === examTypes.LSAT && (
        <LsatTitle titleClass={titleClass} scoreItemClass={scoreItemClass} />
      )}
    </div>
  )
}

export default TitleScoresComponent
