import React from 'react'
import {IconIllustratedLesson} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const EducatorSelectorHeader = () => {
  const {translate} = useTranslation()
  return (
    <>
      <div className='w-100 p-0 mt-2'>
        <IconIllustratedLesson size={80} />
        <div className='mt-2 h2 text-body font-weight-bold'>
          {translate('lessons.whateducatorwant')}
        </div>
      </div>
    </>
  )
}

export default EducatorSelectorHeader
