import {initialState} from './initialState'
import {
  TOGGLE_MODAL_ACTION,
  TOGGLE_LOADING_MODAL_ACTION,
  SET_DATA_MODAL_FORM_LIST,
  SET_MODAL_ACTION,
} from './const'
import {set, get} from 'lodash'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LOADING_MODAL_ACTION:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_MODAL_ACTION:
      return set(state, action.payload, action.bool)
    case TOGGLE_MODAL_ACTION:
      return set(state, action.payload, !get(state, action.payload))
    case SET_DATA_MODAL_FORM_LIST:
      return set(state, action.payload, action.data)
    default:
      return {...state}
  }
}
