import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useModalState from '../../../../../../hooks/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'

const UnauthorizedReschedule = (props) => {
  const {isOpen, toggle, modalData} = useModalState('program.cantReschedule')
  const {translate} = useTranslation()
  const {isLocked, isOnNext24, isGroupLesson} = modalData
  return (
    <ConfirmModal
      {...{isOpen, toggle}}
      title={translate('lessons.modal.rescheduledpending.cantReschedule')}
      body={translate(
        `lessons.modal.rescheduledpending.cantReschedule${
          isGroupLesson
            ? 'Group'
            : isLocked
            ? 'Locked'
            : isOnNext24
            ? '24Hrs'
            : 'Nodefined'
        }`,
      )}
      confirmText={translate('lessons.modal.rescheduledpending.agree')}
      confirmAction={toggle}
    />
  )
}

export default UnauthorizedReschedule
