import React, {useState, useMemo, useEffect} from 'react'
import {Avatar, Button, Input} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'
import useEmailSignup from './useEmailSignup'

const Signup = (props) => {
  const {back, isEducator} = props
  const {translate} = useTranslation()
  const email = global.localStorage.getItem('signupEmail')
  const storage = global.localStorage.getItem('foundUser')
  const parsedInfo = JSON.parse(storage) ?? {}
  const [form, setForm] = useState({})
  useEffect(() => {
    if (!isEmpty(parsedInfo)) {
      setForm({
        ...form,
        firstName: parsedInfo.firstName,
        lastName: parsedInfo.lastName,
      })
    }
    // eslint-disable-next-line
  }, [parsedInfo.firstName, parsedInfo.lastName])
  // determina si el usuario no tiene nombre o apellido en zinkerz API
  const nameNotDefined = useMemo(() => {
    return [parsedInfo?.lastName, parsedInfo?.firstName].includes('')
  }, [parsedInfo])
  const name = `${parsedInfo.firstName} ${parsedInfo.lastName}`
  const goBack = () => {
    global.localStorage.removeItem('signupEmail')
    global.localStorage.removeItem('foundUser')
    back()
  }
  // crea el usuario en firebase (no modifica datos en la API de zinkerz)
  const {submit, loading, validationErrors} = useEmailSignup((result) => {
    // displayName en checkByUid //si no es nulo sobreescribe nombre del usuario
    global.localStorage.setItem(
      'displayNameToMutation',
      `${form.firstName} ${form.lastName}`,
    )
    global.localStorage.removeItem('signupEmail')
    global.localStorage.removeItem('foundUser')
  }, console.log)
  const onSubmit = () => {
    const {password, confirmPassword, firstName, lastName} = form
    if (!isEmpty(parsedInfo) && !nameNotDefined) {
      const {firstName, lastName, email} = parsedInfo
      submit({
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
        asEducator: !!isEducator,
      })
    } else {
      submit({
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
        asEducator: !!isEducator,
      })
    }
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <div
        className='btn-link text-underline text-gray font-weight-light'
        onClick={goBack}
      >
        &lt;&nbsp;{translate('login.signin.existing.back')}
      </div>
      <div className='h1 font-weight-light text-logo'>
        {translate('login.signup.welcome')}
      </div>
      <div className='h4 my-2 font-weight-light text-secondary'>
        {translate('login.signup.description')}
      </div>
      {!isEmpty(parsedInfo) && !nameNotDefined ? (
        <div className='my-3 p-3 znk-input h-auto d-flex align-items-center'>
          <div className='flex-grow-1 d-flex align-items-center'>
            <Avatar name={name} />
            <span className='ml-2'>{name}</span>
          </div>
          <div
            className='btn-link text-underline text-gray font-weight-light'
            onClick={goBack}
          >
            {translate('login.signup.back')}
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column w-100'>
          <div className='d-flex align-items-center'>
            <div className='flex-grow-1 pr-1'>
              <span className='text-secondary font-weight-light'>
                {translate('login.signup.firstNameLabel')}
              </span>
              <Input
                className='my-2 noRight'
                name='firstName'
                value={form?.firstName}
                type='text'
                placeholder={translate('login.signup.firstNamePlaceholder')}
                onChange={({target: {value}}) =>
                  setForm((f) => ({...f, firstName: value}))
                }
              />
            </div>
            <div className='flex-grow-1'>
              <span className='text-secondary font-weight-light'>
                {translate('login.signup.lastNameLabel')}
              </span>
              <Input
                className='my-2 noLeft'
                name='lastName'
                type='text'
                placeholder={translate('login.signup.lastNamePlaceholder')}
                value={form?.lastName}
                onChange={({target: {value}}) =>
                  setForm((f) => ({...f, lastName: value}))
                }
              />
            </div>
          </div>
          {validationErrors?.firstName || validationErrors?.lastName ? (
            <div className='flex-grow-1 text-error text-center h3'>
              {translate('login.signup.errors.namesNotSet')}
            </div>
          ) : null}
        </div>
      )}
      <div className='w-100'>
        <span className='text-secondary font-weight-light'>
          {translate('login.signup.passLabel')}
        </span>
        <Input
          className='my-2'
          name='password'
          type='password'
          placeholder={translate('login.signup.passPlaceholder')}
          value={form.password}
          onChange={({target: {value}}) =>
            setForm((f) => ({...f, password: value}))
          }
        />
        {validationErrors?.password ? (
          <div className='text-error text-center h3'>
            {translate('login.signup.errors.invalidPassword')}
          </div>
        ) : null}
      </div>
      <div className='w-100'>
        <span className='text-secondary font-weight-light'>
          {translate('login.signup.confirmPassLabel')}
        </span>
        <Input
          className='my-2'
          name='confirmPassword'
          type='password'
          placeholder={translate('login.signup.confirmPassPlaceholder')}
          value={form.confirmPassword}
          onChange={({target: {value}}) =>
            setForm((f) => ({...f, confirmPassword: value}))
          }
        />
        {validationErrors?.confirmPassword ? (
          <div className='text-error text-center h3'>
            {translate('login.signup.errors.passwordsDontMatch')}
          </div>
        ) : null}
      </div>
      <Button
        className='w-100 mt-2'
        color='primary'
        check
        activity={loading}
        type='submit'
        label={translate('login.signup.submit')}
      />
    </form>
  )
}

export default Signup
