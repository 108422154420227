import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import {FormGroup, Label} from 'reactstrap'

const SatComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  } = propsToSend
  const scaleSat = '200-800'
  const scaleEssay = '10-40'
  const scaleSatMin = parseInt(scaleSat.split('-')[0])
  const scaleSatMax = parseInt(scaleSat.split('-')[1])
  const scaleEssayMin = parseInt(scaleEssay.split('-')[0])
  const scaleEssayMax = parseInt(scaleEssay.split('-')[1])

  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  // const reduxDataTaskModal = useSelector(
  //   (state) => state.modal.counseling.viewTaskModal,
  // )
  const scores = reduxData?.scores
  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scores?.math >= scaleSatMin &&
      scores?.math <= scaleSatMax &&
      scores?.verbal >= scaleSatMin &&
      scores?.verbal <= scaleSatMax &&
      ((scores?.reading >= scaleEssayMin && scores?.reading <= scaleEssayMax) ||
        scores?.reading === 0) &&
      ((scores?.analysis >= scaleEssayMin &&
        scores?.analysis <= scaleEssayMax) ||
        scores?.analysis === 0) &&
      ((scores?.writing >= scaleEssayMin && scores?.writing <= scaleEssayMax) ||
        scores?.writing === 0)
      // && !!scores?.examDate
    )
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.overall,
      results: [
        {
          name: 'Evidence-Based Reading and Writing Score',
          scale: scaleSat,
          value: scores?.verbal,
          values: [],
        },
        {
          name: 'Math SCORE',
          scale: scaleSat,
          value: scores?.math,
          values: [],
        },
        {
          name: 'Test Scores',
          scale: '',
          value: 0,
          values: [
            {
              name: 'Reading',
              scale: scaleEssay,
              value: scores?.reading,
            },
            {
              name: 'Writing and Language',
              scale: scaleEssay,
              value: scores?.writing,
            },
            {
              name: 'Math',
              scale: scaleEssay,
              value: scores?.analysis,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  return (
    <div className={`${scoresComponentClass}`}>
      <Form Form value={scores}>
        <FormGroup className='mt-7 mb-0'>
          <Label style={{color: sectionTitleColor}}>SAT</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='math'
                className='m-1'
                label='Math'
                value={scores?.math || 0}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    math: parseInt(e),
                    satScore: parseInt(e) + scores?.verbal,
                  })
                }}
                isEditable={isEditable}
                min={scaleSatMin}
                max={scaleSatMax}
              />
              <ScoreListItem
                id='verbal'
                className='m-1'
                label='Verbal'
                value={scores?.verbal || 0}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    verbal: parseInt(e),
                    satScore: parseInt(e) + scores?.math,
                  })
                }}
                isEditable={isEditable}
                min={scaleSatMin}
                max={scaleSatMax}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className='mt-7 mb-0'>
          <Label style={{color: '#94A0AF'}}>ESSAY</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='reading'
                className='m-1'
                label='Reading'
                value={scores?.reading || 0}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    reading: parseInt(e),
                    essayScore: Math.round(
                      (parseInt(e) + scores?.analysis + scores?.writing) / 3,
                    ),
                  })
                }}
                isEditable={isEditable}
                min={scaleEssayMin}
                max={scaleEssayMax}
              />
              <ScoreListItem
                id='analysis'
                className='m-1'
                label='Analysis'
                value={scores?.analysis || 0}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    analysis: parseInt(e),
                    essayScore: Math.round(
                      (parseInt(e) + scores?.reading + scores?.writing) / 3,
                    ),
                  })
                }}
                isEditable={isEditable}
                min={scaleEssayMin}
                max={scaleEssayMax}
              />
              <ScoreListItem
                id='writing'
                className='m-1'
                label='Writing'
                value={scores?.writing || 0}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    writing: parseInt(e),
                    essayScore: Math.round(
                      (parseInt(e) + scores?.analysis + scores?.reading) / 3,
                    ),
                  })
                }}
                isEditable={isEditable}
                min={scaleEssayMin}
                max={scaleEssayMax}
              />
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default SatComponent
