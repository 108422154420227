import React from 'react'
import {useSelector} from 'react-redux'
import {ScoreItem} from '@Knowledge-OTP/znk-ui-components'

const ActTitle = (props) => {
  const {titleClass, scoreItemClass} = props
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const scores = reduxData?.scores
  const elementsToShow = [
    {title: 'ACT SCORE', value: scores?.composite},
    {title: 'WRITING SCORE', value: scores?.writing},
  ]

  return (
    <div className={titleClass}>
      {elementsToShow?.map((el) => (
        <ScoreItem
          className={scoreItemClass}
          serviceLabel={el?.title}
          serviceScore={null}
          scoreLabel={el.value ? el.value : 0}
        />
      ))}
    </div>
  )
}

export default ActTitle
