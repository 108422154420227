import {gql, useQuery} from '@apollo/client'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: {}}
  }

  const {data, error, loading} = useQuery(
    gql`
      query($planId: String) {
        myPaymentsItems(planId: $planId) {
          hasNext
          hasPrevious
          previous
          totalDocs
          docs {
            id
            userId
            processedTimes
            billedUserId
            kind
            createdAt
            updatedAt
            amount
            date
            draft
            status
            lastProcessedAt
            description
            category
            planId
            type
          }
        }
      }
    `,
    {
      variables: vars,
    },
  )
  return {loading, data: data ? data.myPaymentsItems : {}, error}
}
