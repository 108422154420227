import React, {useEffect, useState} from 'react'
import {TabContent, TabPane} from 'reactstrap'
import ViewCounselor from '../../../ViewTask/Components/ViewCounselor'
import rolesNames from '../../../../Utils/rolesNames'
import useUser from './Model/useUser'

const ShowCounselorAssigned = (props) => {
  const {team, userAssignedId} = props
  const [selectUser, setSelectUser] = useState('')
  const [getUser] = useUser()
  let selectedUser = {}
  useEffect(() => {
    if (team.length > 0) {
      if (userAssignedId) {
        // eslint-disable-next-line
        selectedUser = team.filter(user => user.id === userAssignedId)
        if (selectedUser[0]) setSelectUser(selectedUser[0])
        else {
          getUser({variables: {id: userAssignedId}}).then((res) => {
            setSelectUser(res?.data?.user)
          }, console.error)
        }
      } else {
        // eslint-disable-next-line
        selectedUser = team.filter(user => user.role === rolesNames.counselor)
        setSelectUser(selectedUser[0])
      }
    }
  }, [team])

  return (
    <TabContent
      className='mb-3'
      activeTab={selectUser !== {} ? 'show' : 'notShow'}
    >
      <TabPane tabId='notShow'>{null}</TabPane>
      <TabPane tabId='show'>
        <ViewCounselor user={selectUser} />
      </TabPane>
    </TabContent>
  )
}

export default ShowCounselorAssigned
