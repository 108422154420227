import React, {useEffect, useState} from 'react'
import useTranslation from '../../../i18n/useTranslation'
import {Collapse} from 'reactstrap'
import {
  setStatusOnFirebase,
  getStatus,
} from '../../../common/FirebaseAlerts/helpers'

const KEY = 'liveprepLessonsMsg'
const CollapseMsg = (props) => {
  const {userId} = props
  const {translate} = useTranslation()
  // booleano
  const [showMsg, setShowMsg] = useState(false)
  // snapshot state
  const [snapshotState, setSnapshotState] = useState(null)
  useEffect(() => {
    // busca booleano y snapshot state según userId
    getStatus(userId, KEY, setSnapshotState, setShowMsg)
  }, [userId])

  return (
    <Collapse isOpen={showMsg}>
      <div className='w-100 m-0 py-7'>
        <h2 className='font-weight-bold'>
          {translate('program.calendar.studyProgram')}
        </h2>
        <p className='font-weight-lighter text-muted my-3'>
          <small>
            <span>{translate('program.calendar.msgStudyProgram')}</span>
          </small>
        </p>
        <button
          onClick={() => {
            // actualiza el registro y si no existe lo crea
            setStatusOnFirebase(userId, KEY, snapshotState)
            setShowMsg(false)
          }}
          className='btn btn-link p-0'
          style={{textDecorationLine: 'underline'}}
        >
          {translate('program.calendar.coolConfirm')}
        </button>
      </div>
    </Collapse>
  )
}

export default CollapseMsg
