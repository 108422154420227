import React from 'react'
import MessagesItems from '../MessagesItems'

const ChatBox = (collection) => {
  return (
    <>
      <MessagesItems {...collection} />
    </>
  )
}

export default ChatBox
