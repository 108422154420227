import React, {useEffect, useState} from 'react'
import {gql, useLazyQuery} from '@apollo/client'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../i18n/useTranslation'
import {useSelector} from 'react-redux'

const ChangeEducator = (props) => {
  const {
    idTopic,
    setEducator,
    educator,
    reScheduledBool,
    idService,
    isOpen,
    toggle,
  } = props
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()

  const {scheduledTimes, keyLesson, dataPlan} = useSelector(
    (state) => state.modal.onboarding.data,
  )
  const recommendedEducators = dataPlan?.recommendedEducators

  const [getEducatorByTopic, {data}] = useLazyQuery(
    gql`
      query(
        $educatorsId: [ID!]
        $idtopic: ID!
        $teachingTypes: [TeachingType!]
        $serviceId: ID!
      ) {
        allEducatorByTopic(
          educatorsId: $educatorsId
          topicId: $idtopic
          teachingTypes: $teachingTypes
          serviceId: $serviceId
        ) {
          id
          emails {
            address
            verified
          }
          countryId
          firstName
          lastName
          timezone
          educatorInfo {
            id
            defaultRate
            services {
              serviceId
              name
              defaultRate
              topics {
                topicId
                teachingTypes
                name
              }
            }
            backupLink
          }
        }
      }
    `,
  )
  useEffect(() => {
    if (idTopic && idService && recommendedEducators) {
      getEducatorByTopic({
        variables: {
          idtopic: idTopic,
          serviceId: idService,
          educatorsId: recommendedEducators
            .filter(({topicId}) => topicId === idTopic)
            .map(({educatorUserId}) => {
              return educatorUserId
            }),
        },
      })
    }
  }, [idTopic, idService, getEducatorByTopic, recommendedEducators])

  const [selectedEducator, setSelectedEducator] = useState({
    id: educator?.id,
    name: educator?.name,
  })
  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered modal-confirm'
        }`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex mb-4 px-5'>
            <div className='d-flex flex-column justify-content-start mr-4'>
              <span className='text-title font-weight-bold h2'>
                {translate('home.onboard.approve.selectedu', {
                  topic: educator.lesson,
                })}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start'
                size={20}
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        <div className='m-0 p-0 mx-5'>
          <div className='m-0 px-0 py-4'>
            {data &&
              !dataPlan?.isGroup &&
              data.allEducatorByTopic &&
              (data.allEducatorByTopic.length > 0 ? (
                data.allEducatorByTopic.map((item, index) => {
                  return (
                    <ListOption
                      key={index}
                      className={`mb-2 ${
                        selectedEducator.id
                          ? selectedEducator.id === item.id
                            ? 'selected-secondary'
                            : ''
                          : ''
                      }`}
                      id='x-0'
                      label={null}
                      childClassName='w-100'
                      icon={
                        <div className='d-flex align-items-center text-muted'>
                          <Avatar
                            name={`${item.firstName} ${item.lastName}`}
                            named={false}
                            uri={null}
                            size='avatar-medium'
                          />
                          <div className='d-flex justify-content-between w-100'>
                            <span className='ml-2 h4'>
                              {`${item.firstName} ${item.lastName} ${
                                educator.recommended === item.id
                                  ? `(${translate(
                                      'home.onboard.approve.scheduled.recommended',
                                    )})`
                                  : ''
                              }`}
                            </span>
                          </div>
                        </div>
                      }
                      onClick={() => {
                        if (reScheduledBool) {
                          // REAGENDAR LECCION DEL TOPICO ESPECIFICO
                        }
                        setSelectedEducator({
                          id: item.id,
                          name: `${item.firstName} ${item.lastName}`,
                        })
                      }}
                    />
                  )
                })
              ) : (
                <label>No hay educadores para el tópico seleccionado.</label>
              ))}
            {dataPlan?.isGroup &&
              scheduledTimes[keyLesson] &&
              scheduledTimes[keyLesson].educator.map((edu, index) => {
                return (
                  <ListOption
                    key={index}
                    className={`mb-2 ${
                      selectedEducator.id
                        ? selectedEducator.id === edu.id
                          ? 'selected-secondary'
                          : ''
                        : ''
                    }`}
                    id='x-0'
                    label={null}
                    childClassName='w-100'
                    icon={
                      <div className='d-flex align-items-center text-muted'>
                        <Avatar
                          name={`${edu.name}`}
                          named={false}
                          uri={null}
                          size='avatar-medium'
                        />
                        <div className='d-flex justify-content-between w-100'>
                          <span className='ml-2 h4'>
                            {`${edu.name} ${
                              educator.recommended === edu.id
                                ? `(${translate(
                                    'home.onboard.approve.scheduled.recommended',
                                  )})`
                                : ''
                            }`}
                          </span>
                        </div>
                      </div>
                    }
                    onClick={() => {
                      if (reScheduledBool) {
                        // REAGENDAR LECCION DEL TOPICO ESPECIFICO
                      }
                      setSelectedEducator({
                        id: edu.id,
                        name: `${edu.name}`,
                      })
                    }}
                  />
                )
              })}
          </div>
          <StickyButton
            condition={educator.id !== ''}
            label={translate('home.onboard.approve.before.changeEducator')}
            onClickButtonFunction={() => {
              // cambiar educador seleccionado
              setEducator(selectedEducator)
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

ChangeEducator.propTypes = {
  idTopic: PropTypes.string,
  setEducator: PropTypes.func,
  educator: PropTypes.object,
}

ChangeEducator.defaultProps = {
  idTopic: '',
  setEducator: () => {},
  educator: {id: '', educator: ''},
}
export default ChangeEducator
