import React, {useEffect, useMemo, useState, useRef} from 'react'
import {NewMessageBadge} from '@Knowledge-OTP/znk-ui-components'
import ActualUser from './components/ActualUser'
import ChatBox from './components/ChatBox'
import {useSelector} from 'react-redux'
import NoHaveMessages from './components/NoHaveMessages'
import {createOnlineStatus} from './helpers/createOnlineStatus'
import moment from 'moment-timezone'
import './style.css'
import SendMessage from './components/SendMessage'
import {useChatNotification} from './Model/useChatNotification'
import phaseNames from '../Workspace/Utils/phaseNames'
import {setIdToUsers} from './utils/setIdToUsers'

const VirtualChat = (props) => {
  // eslint-disable-next-line
  const { 
    invokeAdminInfo,
    refetchLessons = () => {},
    usersList,
    collection,
    setDataLessonToRedux,
    backArrow = () => {},
    active = false,
  } = props
  const eventID = collection?.id || collection?.taskId
  let variablesToSend = {}
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )

  // Educator y substitute no existen en Task.
  const educator = collection?.educator || {}
  const substitute = collection?.substitute || {}
  const userInfo = useSelector((state) => state.user.info)
  const {id: userID, roles} = userInfo
  const [loading, setLoading] = useState(false)
  const finishTime = collection.endDate
    ? moment() >= moment(collection?.endDate)
    : collection.status === 'completed'
  // Para saber si el usuario es educador. Revisa si el id que viene en el dataLesson, objeto educator o substitute coincide con el id del usuario loguegado
  const isEducator =
    roles.includes('EDUCATOR') &&
    // (substitute || educator)?.educatorUserId === userID
    substitute?.educatorUserId
      ? substitute?.educatorUserId === userID
      : educator?.educatorUserId === userID
  const isStudent = roles.includes('STUDENT')
  const {attendanceStatus} = useMemo(() => {
    if (['UserTasksOut', 'Task'].includes(collection?.__typename))
      return {...userInfo, attendanceStatus: 'attended'}
    if (collection && userID) {
      if (isEducator)
        return collection?.substitute || collection?.educator || {}

      if (
        ['UserTasksOut', 'Task'].includes(collection?.__typename) &&
        collection?.userId === userID
      ) {
        return {...userInfo, attendanceStatus: 'attended'}
      }
      return (
        collection?.student ||
        collection?.studentPlan ||
        collection?.studentGroup?.find(
          (user) => user?.studentUserId === userID,
        ) ||
        {}
      )
    }
    return {}
    // eslint-disable-next-line
  }, [collection, userID, isEducator])

  // eslint-disable-next-line
  const [chatNotification, { data: dataChatNotification, loading: loadignChatNotification, refetch: refetchChatNotification }] = useChatNotification(
    () => {},
    (err) => {
      console.error('Error with chat notification : ', err)
    },
  )

  const usersLesson = useMemo(() => {
    if (
      collection &&
      collection?.__typename &&
      collection?.__typename.toLowerCase()?.includes('lesson')
    ) {
      if (collection?.__typename === 'GroupLesson') {
        if (
          collection.hasOwnProperty('educator') &&
          collection.hasOwnProperty('studentGroup')
        ) {
          if (isEducator) {
            return [
              ...collection?.studentGroup.filter(
                (user) =>
                  user[
                    user.__typename === 'LessonEducator'
                      ? 'educatorUserId'
                      : 'studentUserId'
                  ] !== userID,
              ),
            ]
          } else {
            return [
              collection?.substitute || collection.educator,
              ...collection?.studentGroup.filter(
                (user) =>
                  user[
                    user.__typename === 'LessonEducator'
                      ? 'educatorUserId'
                      : 'studentUserId'
                  ] !== userID,
              ),
            ]
          }
        }
        return []
      }
      if (
        collection?.__typename === 'IndividualLesson' ||
        collection?.__typename === 'PlanLesson'
      ) {
        if (
          collection.hasOwnProperty('educator') &&
          (collection.hasOwnProperty('student') ||
            collection.hasOwnProperty('studentPlan'))
        ) {
          return [
            collection?.substitute || collection?.educator,
            collection?.student || collection?.studentPlan,
          ].filter(
            (user) =>
              user[
                user.__typename === 'LessonEducator'
                  ? 'educatorUserId'
                  : 'studentUserId'
              ] !== userID,
          )
        }
        return []
      }
    }
    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  useEffect(() => {
    if (['UserTasksOut', 'Task'].includes(collection?.__typename)) {
      // eslint-disable-next-line
      variablesToSend = {
        userIn: true,
        taskId: collection?.id,
        stepId: collection?.stepId,
        phase:
          phaseNames[collection?.phase] || phaseNames[collection?.phaseName],
        planId,
        chanel: 'counselingTasks',
      }
    } else if (
      collection &&
      collection?.__typename &&
      collection?.__typename.toLowerCase().includes('lesson')
    ) {
      variablesToSend = {
        userIn: true,
        lessonId: collection?.id,
        chanel: 'lessons',
      }
    }
    chatNotification({
      variables: variablesToSend,
    })
    const handleLoadPage = (event) => {
      event.preventDefault()
      createOnlineStatus(userID, setLoading, false, eventID).then(() =>
        window.location.reload(),
      )
    }
    if (!finishTime) {
      createOnlineStatus(userID, setLoading, true, eventID).then((resp) =>
        console.log(resp),
      )
      window.addEventListener('beforeunload', handleLoadPage)
    }
    return () => {
      createOnlineStatus(userID, setLoading, false, eventID).then((resp) =>
        console.log(resp),
      )
      window.removeEventListener('beforeunload', handleLoadPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputRef = useRef(null)
  const chatBoxRef = useRef(null)
  const scrollToRef = (behavior = 'smooth') => {
    if (chatBoxRef?.current?.parentElement) {
      chatBoxRef.current.parentElement.scrollIntoView({behavior, block: 'end'})
    }
  }

  useEffect(() => {
    if (active) scrollToRef('auto')
  }, [active])
  const [adminInfo, setAdminInfo] = useState(null)

  const [openBagde, setOpenBagde] = useState(false)

  const onNewMessageRecieved = (messages) => {
    const messagesList = messages?.messages || []
    const lastMessage =
      messagesList?.length > 0 && messagesList[messagesList.length - 1]
    const {userID: userIdMessage, readed = {}} = lastMessage

    if (userID === userIdMessage || readed[userID]?.readed) return
    // no hace nada
    else if (chatBoxRef?.current) {
      // const rect = inputRef.current.getBoundingClientRect()
      const offset = chatBoxRef.current.getBoundingClientRect()?.bottom
      if (offset >= window.innerHeight + 150) setOpenBagde(true)
      else {
        setTimeout(() => {
          scrollToRef('auto')
        }, 1000)
      }
    }
  }

  return (
    <>
      <ActualUser
        setAdminInfo={setAdminInfo}
        refetch={refetchLessons}
        invokeAdminInfo={invokeAdminInfo}
        usersOnChat={
          collection &&
          collection?.__typename &&
          collection.__typename.toLowerCase().includes('lesson')
            ? setIdToUsers(usersLesson)
            : usersList
        } // Le pasa la lista adecuada, según sea algún tipo de lesson o una task
        collection={collection}
        loading={loading}
        backArrow={backArrow}
      />
      {collection?.id && attendanceStatus === 'attended' && (
        <div
          style={{flexGrow: 1}}
          className='m-0 p-0 chatBoxRef'
          ref={chatBoxRef}
        >
          <ChatBox
            {...collection}
            onNewMessage={onNewMessageRecieved}
            usersList={usersList}
            isActive={active}
            joinedUser={
              adminInfo
                ? [
                    {
                      ...adminInfo,
                      name: `${adminInfo?.firstName} ${adminInfo?.lastName}`,
                      date: invokeAdminInfo?.invokeDate,
                    },
                  ]
                : []
            }
          />
        </div>
      )}
      {attendanceStatus !== 'attended' && (
        <div
          className='d-flex flex-column justify-content-center align-items-center'
          style={{flexGrow: 1}}
        >
          <NoHaveMessages {...collection} />
        </div>
      )}
      <SendMessage
        onSend={(inputRef) => {
          if (inputRef?.current) inputRef.current.focus()
          scrollToRef()
        }}
        ref={inputRef}
        setDataLessonToRedux={setDataLessonToRedux}
        collection={collection}
        isEducator={isEducator}
        isStudent={isStudent}
        attendanceStatus={attendanceStatus}
        collectionType='messages'
      />

      <div
        className={`new-message-content ${
          openBagde ? 'open' : 'close'
        }-new-message`}
      >
        <NewMessageBadge
          onClick={() => {
            setOpenBagde(false)
            setTimeout(() => {
              scrollToRef()
            }, 1000)
          }}
          className='new-message'
        >
          New message(s)
        </NewMessageBadge>
      </div>
    </>
  )
}

export default VirtualChat
