import React from 'react'
import {Canvas as CanvasRenderer} from '@react-pdf/renderer'

const Canvas = (props) => {
  return (
    <CanvasRenderer style={props?.style || {}} paint={props?.painter}>
      {props.children}
    </CanvasRenderer>
  )
}

export default Canvas
