import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import {
  Modal,
  IconClose,
  ListItem,
  useWindowDimensions,
  IconRight,
  IconAdd,
  IconOptions,
  DropDownNew,
  DropDownItem,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader, TabContent, TabPane} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import keyAccount from '../../../../../assets/keyAccount.svg'
import iconKey from '../../../../../assets/keyIcon.svg'
import useUser from '../../../../../hooks/auth/useUser'
import AccountInfo from './accountInfo'
import AccountsOptionsMenu from './accountOptionsMenu'
import AccountCreate from './accountCreate'
import AccountEdit from './accountEdit'
import AccountRemove from './accountRemove'
import {
  TOGGLE_MODAL_ACTION,
  SET_DATA_MODAL_FORM_LIST,
} from '../../../../../store/modal/const'
import getAllAccountsFromUser from '../../../../../Model/Users/Accounts/AllAccounts/getAllAccountsFromUser'

const Accounts = (props) => {
  const {user} = useUser()
  const isOpen = useSelector((state) => state.modal.profile.accounts)
  const account = useSelector(
    (state) => state.modal?.profile?.account?.info?.account,
  )
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.accounts'})
  }

  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()

  const [accountInfoModalOpen, setAccountInfoModalOpen] = useState(false)
  const [optionsModalOpen, setOptionsModalOpen] = useState(false)
  const [accountCreateModalOpen, setAccountCreateModalOpen] = useState(false)
  const [accountEditModalOpen, setAccountEditModalOpen] = useState(false)
  const [accountRemoveModalOpen, setAccountRemoveModalOpen] = useState(false)

  const {
    data: allAccountsData,
    loading: allAccountsLoading,
    refetch: allAccountsRefetch,
  } = getAllAccountsFromUser()

  const setAccountFunction = (account) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'profile.account.info',
      data: {
        account,
      },
    })
  }

  if (user) {
    return (
      <>
        <ListItem
          onClick={toggle}
          showBottomLine
          hasHover
          hasColorHover
          Left={
            <span className='d-flex align-items-center'>
              <img
                src={iconKey}
                alt='icon/key'
                width='16'
                height='16'
                className='mr-3'
              />
              <span className='text-black'>
                {translate('profile.actions.accounts')}
              </span>
            </span>
          }
        />
        <Modal
          className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
          backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <ModalHeader
              className='px-0 py-1 mb-3'
              toggle={toggle}
              close={
                <div className='flex-grow-1 d-flex flex-row justify-content-end'>
                  <div className='hover-icon w-min-content'>
                    <IconClose size={20} onClick={toggle} />
                  </div>
                </div>
              }
            >
              <div className='d-flex flex-column'>
                <img
                  src={keyAccount}
                  alt='key'
                  width='90'
                  height='90'
                  className='mb-4'
                />
                <span className='h2 font-weight-bold'>
                  {translate('profile.actions.accounts')}
                </span>
                <span className='h4 text-muted my-4'>
                  {translate('profile.actions.accountsText')}
                </span>
              </div>
            </ModalHeader>
          }
        >
          <>
            {false ? null : (
              <div>
                <ListItem
                  className='borderTop w-100 m-0 px-2'
                  hasHover
                  Left={
                    <div>
                      <IconAdd size={20} color='#696bff' />
                      <span className='ml-5 h4 text-primary pl-1'>
                        {translate('profile.actions.accounts.addKey')}
                      </span>
                    </div>
                  }
                  Right={
                    <div className='hover-icon'>
                      <IconRight size={20} />
                    </div>
                  }
                  onClick={() => {
                    setAccountCreateModalOpen(true)
                  }}
                />
                <TabContent activeTab={allAccountsLoading ? 'loading' : 'show'}>
                  <TabPane tabId='loading'>
                    <Skeleton count={5} height={60} className='my-1' />
                  </TabPane>
                  <TabPane tabId='show'>
                    {(allAccountsData || []).map((account) => {
                      return (
                        <ListItem
                          className='borderTop w-100 m-0 px-2'
                          Left={
                            <div>
                              <img
                                src={iconKey}
                                alt='icon/key'
                                width='20'
                                height='20'
                              />
                              <span
                                className='ml-5 h4'
                                style={{color: '#293e58'}}
                                onClick={() => {
                                  setAccountFunction(account)
                                  setAccountInfoModalOpen(true)
                                }}
                              >
                                {account.platform}
                              </span>
                            </div>
                          }
                          Right={
                            <DropDownNew
                              hoverEnabled
                              direction='bottomCenter'
                              classNameMenu='m-0 p-0'
                              options={[
                                {
                                  component: (
                                    <DropDownItem
                                      onClick={() => {
                                        setAccountFunction(account)
                                        setAccountEditModalOpen(true)
                                      }}
                                    >
                                      Edit Account
                                    </DropDownItem>
                                  ),
                                },
                                {
                                  component: (
                                    <DropDownItem
                                      onClick={() => {
                                        setAccountFunction(account)
                                        setAccountRemoveModalOpen(true)
                                      }}
                                      className='text-error'
                                    >
                                      Remove Account
                                    </DropDownItem>
                                  ),
                                },
                              ]}
                            >
                              <div className='hover-icon'>
                                <IconOptions
                                  className='rotate-90'
                                  size={20}
                                  // onClick={() => {
                                  //   setAccountFunction(account)
                                  //   setOptionsModalOpen(true)
                                  // }}
                                />
                              </div>
                            </DropDownNew>
                          }
                        />
                      )
                    })}
                  </TabPane>
                </TabContent>
              </div>
            )}
          </>
        </Modal>
        <AccountInfo
          isOpen={accountInfoModalOpen}
          toggle={() => setAccountInfoModalOpen(!accountInfoModalOpen)}
        />
        <AccountCreate
          isOpen={accountCreateModalOpen}
          toggle={() => setAccountCreateModalOpen(!accountCreateModalOpen)}
          refetch={() => {
            allAccountsRefetch()
            setAccountCreateModalOpen(false)
          }}
        />
        <AccountEdit
          isOpen={accountEditModalOpen}
          toggle={() => setAccountEditModalOpen(!accountEditModalOpen)}
          account={account}
          refetch={() => {
            allAccountsRefetch()
            setAccountEditModalOpen(false)
          }}
        />
        <AccountRemove
          isOpen={accountRemoveModalOpen}
          toggle={() => {
            setAccountRemoveModalOpen(!accountRemoveModalOpen)
          }}
          refetch={() => {
            allAccountsRefetch()
          }}
        />

        <AccountsOptionsMenu
          isOpen={optionsModalOpen}
          toggle={() => setOptionsModalOpen(!optionsModalOpen)}
        />
      </>
    )
  } else return null
}

export default Accounts
