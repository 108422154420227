import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  Form,
  Field,
  LabeledField,
  PlainTextInput,
  InputAndButtons,
  StickyButton,
  IconIllustratedLesson,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup} from 'reactstrap'
import {selectLpwForTopicPart} from '../../utils'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import moment from 'moment'
export const SelectLpwHeader = (props) => {
  return (
    <div className='m-0 p-0'>
      <IconIllustratedLesson size={80} />
      <h2 className='w-100 my-3'>
        <b>
          When do you want to start and how many lessons per week do you want?
        </b>
      </h2>
    </div>
  )
}
export const SelectLpwBody = (props) => {
  const {setPage, page} = props
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimes, dataPlan, keyLesson, orientationKey} = reduxData
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'
  const topicList = scheduledTimes
    ? Object.entries(scheduledTimes).reduce((topicArray, [key, value]) => {
        if (!value.distributed && key !== ORIENTATION_KEY) {
          topicArray.push({
            key,
            topic: value.topic,
          })
        }
        return topicArray
      }, [])
    : []

  const [form, setForm] = useState({
    date:
      moment(dataPlan?.ranges[parseInt(keyLesson?.split('-')[1])][0]) >
      moment().add(1, 'day')
        ? moment(
            dataPlan?.ranges[parseInt(keyLesson?.split('-')[1])][0],
          ).format('YYYY-MM-DD')
        : moment().add(1, 'day').format('YYYY-MM-DD'),
    ...topicList.reduce((newObj, topic) => {
      return {...newObj, [topic.key]: 0}
    }, {}),
  })

  useEffect(() => {
    if (page === 'selectLpw') {
      // setForm({
      //  ...form,
      //  date: moment(dataPlan?.ranges[parseInt(keyLesson?.split('-')[1])][0]).format('YYYY-MM-DD')
      // })
    }
  }, [page])

  return (
    <div className='m-0 p-0'>
      <Form className='stripe' value={form} onChange={setForm}>
        <FormGroup className='row'>
          <label className='col-12 text-muted mt-2'>Start date</label>
          <Field
            className='col-12'
            type={LabeledField}
            component={PlainTextInput}
            fieldName='date'
            fieldType='date'
            placeholder='MM/DD/YYYY'
            value={form.date}
            min={moment().add(1, 'day').format('YYYY-MM-DD')}
          />
          {topicList.map((topic, indexLpw) => {
            return (
              <InputAndButtons
                key={indexLpw}
                title={topic.topic.name}
                max={scheduledTimes[topic.key].totalLessons}
                inputName={topic.key}
                form={form}
                setForm={setForm}
              />
            )
          })}
        </FormGroup>
      </Form>
      <StickyButton
        condition
        disabled={
          !Object.entries(form).reduce((bool, [key, value]) => {
            if (key !== 'date') return bool && value > 0
            return bool
          }, true)
        }
        onClickButtonFunction={() => {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.data',
            data: {
              ...reduxData,
              ...selectLpwForTopicPart(
                scheduledTimes,
                Object.entries(form).reduce((keysLesson, [key, value]) => {
                  if (key !== 'date') keysLesson.push({key, lpw: value})
                  return keysLesson
                }, []),
                form.date,
                dataPlan,
              ),
            },
          })
          setPage('introductionTopic')
        }}
      />
    </div>
  )
}

export default {SelectLpwHeader, SelectLpwBody}
