export default {
  title: 'Confirm your lesson schedule',
  educators: 'Your educators',
  adjust: 'Adjust',
  confirm: 'Confirm schedule',
  notif: 'You have unmatched lessons',
  subnotif: (names) => {
    return `For ${names} topics`
  },
  subnotifOcurrences: (names) => {
    return `For ${names} topics`
  },
  confirmPayment: 'Confirm your payment schedule',
  confirmPaymentsubtitle:
    'Below you will see your weekly schedule according to your availability.\nIf you want, you can change it.',
  orientation: 'Orientation',
}
