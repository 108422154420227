export default {
  title: 'One more step.',
  complete: 'Complete your profile.',
  subtitle:
    'In order to improve your experience Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero.',
  student: 'Student name',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  other: "I don't know, I don't answer",
  birth: 'Birth date',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  language: 'Language',
  dialcode: 'Dial code',
  mobile: 'Mobile phone',
  spoken: 'Spoken languages',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip code',
  country: 'Country',
  confirm: 'Complete profile',
  selectcountry: 'Select country',
}
