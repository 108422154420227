import React, {Suspense, useEffect, useState} from 'react'
import {useApolloClient, gql} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {SET_SERVICES_ACTION} from '../../../store/services/const'
import {SET_COUNTRIES_ACTION} from '../../../store/countries/const'
import {LoadingPage} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const GlobalDataProvider = (props) => {
  const dispatch = useDispatch()
  const client = useApolloClient()
  const {translate} = useTranslation()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    async function fetchServices() {
      const data = await client.query({
        query: gql`
          query {
            paginatedCountries(limit: 0) {
              docs {
                id
                name
                code
                dialCode
                defaultStudentRate
                updatedAt
              }
            }
            paginatedServices(limit: 0) {
              docs {
                id
                name
                isOrientation
                updatedAt
                topics {
                  id
                  name
                }
              }
            }
          }
        `,
      })
      dispatch({
        type: SET_SERVICES_ACTION,
        payload: data.data.paginatedServices.docs,
      })
      dispatch({
        type: SET_COUNTRIES_ACTION,
        payload: data.data.paginatedCountries.docs,
      })
    }
    fetchServices().then(() => {
      setLoading(false)
    })
  }, [client, dispatch])
  return (
    <Suspense fallback={<LoadingPage />}>
      {loading ? (
        <LoadingPage text={translate('loading.gatheringGlobalInfo')} />
      ) : (
        props.children
      )}
    </Suspense>
  )
}

export default GlobalDataProvider
