import {gql, useQuery} from '@apollo/client'
import queryLessonResult from '../../../QueryResult/useLessonResult'
import validate from './validation'

export default (vars, fetchPolicy = 'cache-first') => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: []}
  }

  const {data, error, loading, refetch} = useQuery(
    gql`query(
            $educatorIds: [ID!]
            $from: DateTime!
            $to: DateTime!
        ) {
        calendarViewStudent(
            educatorIds: $educatorIds,
            from: $from,
            to: $to
        ) {  
            ${queryLessonResult}
            ... on Counselor {
              id
              kind
              createdAt
              updatedAt
              startDate
              endDate
              busy
              userIdsInvolved
              counselorId
              studentId
              isMeeting
            }
        }
    }`,
    {
      variables: vars,
      fetchPolicy,
    },
  )
  return {loading, data: data ? data.calendarViewStudent : [], error, refetch}
}
