import {useEffect, useReducer} from 'react'
import reducer from './reducer'
import initialState from './initialState'
import moment from 'moment'
import {nanoid} from 'nanoid'

const usePaymentScheduledReducer = (
  totalAmount = 0,
  defaultPayments = null,
) => {
  // multi: una task o un arreglo de tasks
  const [state, dispatch] = useReducer(reducer, initialState)
  const actionData = (
    data,
    key, // [status, ...]
    action, // [set]
  ) => {
    dispatch({
      type: `${action}-${key}`,
      data,
    })
  }
  const setTotalAmount = (total) => {
    dispatch({
      type: `set-total`,
      data: {total},
    })
  }
  useEffect(() => {
    setTotalAmount(totalAmount)
  }, [totalAmount])

  const setDefaultPayment = (def = null) => {
    actionData(def, 'default', 'set')
  }

  useEffect(() => {
    if (defaultPayments) setDefaultPayment(defaultPayments)
    else {
      setDefaultPayment()
      // console.log("setear payment por defecto calculados")
    }
    // eslint-disable-next-line
    }, [defaultPayments])



  const formatNumber = (num, size = 2) => {
    if(!num) return 0
    // Agregar miles
    let s = num.toString()
    // eslint-disable-next-line
      if(/[^0-9\.]/.test(s)) return "invalid value";
    s = s.replace(/^(\d*)$/, '$1.')
    s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1')
    s = s.replace('.', ',')
    const re = /(\d)(\d{3},)/
    while (re.test(s)) {
      s = s.replace(re, '$1,$2')
    }
    s = s.replace(/,(\d\d)$/, '.$1')
    s = s.replace(/^\./, '0.')
    // s = s.split(',').join('%').split('.').join(',').split('%').join('.')
    if (size === 0) return s.split('.')[0]
    else {
      s = s.split('.')
      s[1] = s[1].substring(0, size)
      return s.join('.')
    }
  }

  return {
    state,
    dispatch,
    formatNumber,
    setData: (data) => {
      actionData(data, 'data', 'update')
    },
    setDefaultPayment,
    editPayment: (data) => {
      // edita el pago
      actionData(data, 'payment', 'edit')
      // marca el payment como editado, así cuando el remanente
      // del total debe distribuirse no se toma en cuenta por estar editada
    },
    addPayment: (inData = {}) => {
      const {paymentId = nanoid(), ...data} = inData
      // calcula la fecha de pago
      let date =
        state.payments?.length > 0
          ? state.payments.reduce((maxDate, {date}) => {
              if (maxDate < moment(date).valueOf())
                return moment(date).valueOf()
              return maxDate
            }, Number.NEGATIVE_INFINITY)
          : moment().subtract(1, 'month').valueOf()
      date = moment(date).add(1, 'month').format('YYYY-MM')
      // Agrega la cuota
      actionData(
        {
          ...data,
          date,
          name: `End of ${moment(date).format('MMM')}`,
          amount: 0,
          paymentId,
        },
        'payment',
        'add',
      )
      // redistribuye el valor del remanente
      // entre los payments restantes no editados
      actionData({}, 'payment', 'distribute')
    },
    removePayment: (data) => {
      // elimina la cuota
      actionData(data, 'payment', 'remove')

      // redistribuye el valor de la cuota
      // eliminada entre los payments restantes no editados
      actionData({}, 'payment', 'distribute')
    },
    distributePayments: (editIncluded = false) => {
      // todos los editados pasan a no editados
      if (editIncluded) {
        actionData(
          {
            payments: state.payments.map((payment) => {
              return {
                ...payment,
                isEdit: false,
              }
            }),
          },
          'data',
          'update',
        )
      }
      actionData({}, 'payment', 'distribute')
    },
    resetPayments: () => {
      actionData({}, 'payment', 'reset')
      // redistribuye el valor total entre los payments
      actionData({}, 'payment', 'distribute')
    },
    setRangeTime: (from, to) => {
      actionData({from, to}, 'range', 'set')
      actionData({}, 'payment', 'reset')
      // redistribuye el valor total entre los payments
      actionData({}, 'payment', 'distribute')
    },
  }
}

export default usePaymentScheduledReducer
