import React, {useMemo} from 'react'
import {PaymentProgress} from '@Knowledge-OTP/znk-ui-components'
import useMyPaymentsItems from '../../../../Model/useMyPaymentsItems'

const PaymentProgressSection = (props) => {
  const {plan, className} = props
  const {data: paymentsQuery} = useMyPaymentsItems({planId: plan.id})

  const dataProgress = useMemo(() => {
    let pendingTotal = 0
    let paidTotal = 0
    let total = 0
    let countPending = 0
    let countPaid = 0

    if (
      paymentsQuery &&
      paymentsQuery.myPaymentsItems &&
      paymentsQuery.myPaymentsItems.docs
    ) {
      paymentsQuery.myPaymentsItems.docs.forEach((data) => {
        if (data.type !== 'otherFixed') {
          if (data.status === 'pendingPayment' || data.status === 'unPaid') {
            countPending++
            pendingTotal += data.amount
          }
          if (data.status === 'paid') {
            paidTotal += data.amount
            countPaid++
          }
        }
      })
      total = pendingTotal + paidTotal
    }
    return {
      pendingTotal,
      paidTotal,
      total,
      countPending,
      countPaid,
    }
  }, [paymentsQuery])
  const {paidTotal, total} = dataProgress

  return (
    <div className={`d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Payment Progress</span>
      <PaymentProgress
        className='mt-7'
        data={dataProgress}
        progressColor='#A7E521'
        bgColor='#FF3E1D'
        completed={(paidTotal * 100) / total}
      />
    </div>
  )
}

export default PaymentProgressSection
