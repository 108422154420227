import React from 'react'
import {
  IconIllustratedLesson,
  IconClose,
  IconSignMinus,
  Rating,
  IconArrowRight,
  ListItem,
  IconRepeat,
  IconComment,
  IconVideo,
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import {hasHttp} from '../utils'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../i18n/useTranslation'
import useModalState from '../../../../../../hooks/useModalState'

const AttendedHeaderModal = (props) => {
  const {RenderTitleLesson, toggle, currentEducator} = props
  const noshow = currentEducator?.attendanceStatus === 'missed'
  const {translate} = useTranslation()
  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1 text-title'>
        <IconSignMinus size={20} className='ml-auto text-cancel' />
        <IconClose className='ml-auto text-cancel' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <IconIllustratedLesson size={80} />
        <RenderTitleLesson />
      </div>
      <div className='m-0 p-0 d-flex flex-row justify-content-start'>
        {noshow ? (
          <Badge
            className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-danger p-0'
            pill
          >
            {translate('student.infolesson.attendance.noshow')}
          </Badge>
        ) : (
          <Badge
            className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-success p-0'
            pill
          >
            {translate('student.infolesson.attendance.attended')}
          </Badge>
        )}
      </div>
    </>
  )
}

const AttendedBodyModal = (props) => {
  const {
    RenderEducator,
    RenderLessonNotes,
    dataLesson,
    currentStudent,
    currentEducator,
  } = props
  const {rating} = currentStudent
  const {service, topic, recordingUrl} = dataLesson
  const {translate} = useTranslation()
  const lessonFinished = moment() >= moment(dataLesson?.endDate)
  const {onChange} = useModalState('program.newManualLesson')
  const openCopyLesson = () => {
    const {educatorUserId, firstName, lastName} = currentEducator
    onChange((s) => ({
      stage: 2,
      serviceId: service.id,
      topic: topic.id,
      educator: {
        id: educatorUserId,
        firstName,
        lastName,
      },
      dateTime: null,
    }))
  }
  return (
    <>
      <RenderEducator />
      {rating !== null && (
        <>
          <div className='m-0 p-0 d-flex flex-column py-6'>
            <span className='text-title h4'>
              {translate('student.infolesson.rating.yourRating')}
            </span>
            <Rating mode='results' initialRating={rating} />
          </div>
          <div className='borderTop' />
        </>
      )}
      <RenderLessonNotes />
      <div className='m-0 p-0 d-flex flex-column'>
        {lessonFinished ? (
          <>
            {recordingUrl && (
              <ListItem
                showTopLine
                className='py-4'
                onClick={() => {
                  window.open(hasHttp(recordingUrl), '_blank')
                }}
                Left={
                  <div className='d-flex flex-row'>
                    <IconVideo
                      color='#72809D'
                      className='align-self-center mr-2'
                    />
                    <span className='align-self-center text-title'>
                      {translate('modal.infolesson.viewlessonrecording')}
                    </span>
                  </div>
                }
                Right={<IconArrowRight />}
              />
            )}
            <ListItem
              showTopLine
              showBottomLine={false}
              className='py-4'
              Left={
                <div className='d-flex flex-row'>
                  <IconComment className='align-self-center mr-2' />
                  <span className='align-self-center'>
                    {translate('student.infolesson.options.viewMessages')}
                  </span>
                </div>
              }
              Right={<IconArrowRight />}
            />
            <ListItem
              showTopLine
              showBottomLine
              className='py-4'
              onClick={() => {
                openCopyLesson()
              }}
              Left={
                <div className='d-flex flex-row'>
                  <IconRepeat className='align-self-center mr-2 text-primary' />
                  <span className='align-self-center text-primary'>
                    {translate('student.infolesson.options.repeat')}
                  </span>
                </div>
              }
              Right={<IconArrowRight className='text-primary' />}
            />
          </>
        ) : (
          <>
            <ListItem
              showTopLine
              showBottomLine
              className='py-4'
              Left={
                <div className='d-flex flex-row'>
                  <IconComment
                    color='#696CFF'
                    className='align-self-center mr-2'
                  />
                  <span className='align-self-center text-primary'>
                    {translate('student.infolesson.options.sendMessage')}
                  </span>
                </div>
              }
              Right={<IconArrowRight color='#696CFF' />}
            />
          </>
        )}
      </div>
    </>
  )
}

export {AttendedHeaderModal, AttendedBodyModal}
