export default {
  add: 'Add Payment Method',
  change: 'Change Payment Method',
  method: 'Payment Method',
  added: 'Added',
  changed: 'Changed',
  current: 'Your current card',
  accept: 'Accepted cards',
  info:
    'You credit card will not be charged at this time but instead pre-authorizated for futire charges based on the payment schedule describe here.',
  success:
    'Cool, we send an email, integer propuse, erat an ante venenatis depabis posuere vellit aliquentaium ut eser no drema',
}
