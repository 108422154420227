import React from 'react'
import PropTypes from 'prop-types'
import usePaginatedLessons from './usePlanLessons'
import Resume from './Resume'
import isEmpty from 'lodash/isEmpty'
import * as moment from 'moment'
import {useSelector} from 'react-redux'
import startCase from 'lodash/startCase'
import LessonsModal from './Lessons'
const InfoModal = (props) => {
  const {info, groupInfo} = props
  const services = useSelector((state) => state.services.services)
  const topicKeys = services[info.serviceId].topics
  const {data: lessons, loading} = usePaginatedLessons({
    planId: info.id,
    status: ['scheduled', 'canceled', 'pending', 'finished'],
    limit: 0,
  })
  const groupLessons = groupInfo?.lessonsGroups ?? []
  const lessonParser = (lessons) => {
    return !isEmpty(lessons) && lessons.totalDocs
      ? lessons.docs
          .map((item) => ({
            ...item,
            status: startCase(item.status),
            lessonTime: moment(item.startDate),
            lessonEndTime: moment(item.endDate),
            attendants: [
              {name: `${item.educator.firstName} ${item.educator.lastName}`},
            ],
          }))
          .sort((a, b) => a.lessonTime.valueOf() - b.lessonTime.valueOf())
      : []
  }
  const parsedLessons = lessonParser(lessons)
  const parsedGroupLessons = lessonParser({
    totalDocs: groupLessons.length,
    docs: groupLessons,
  })
  return (
    <div className='mt-4 mb-7 px-6 px-sm-7 d-flex flex-column'>
      <Resume
        topicKeys={topicKeys}
        info={info}
        lessons={parsedLessons}
        lessonsLoading={loading}
        groupLessons={parsedGroupLessons}
        groupLoading={false}
        groupInfo={groupInfo}
      />
      <LessonsModal lessons={parsedLessons} lessonsLoading={loading} />
    </div>
  )
}
InfoModal.propTypes = {
  lessons: PropTypes.array,
}
InfoModal.defaultProps = {
  lessons: [],
}
export default InfoModal
