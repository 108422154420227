import React, {useState} from 'react'
import {
  Modal,
  TimezoneSelector,
  IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import UpdateUser from '../../../../../Model/UpdateUser/index'
import {ModalHeader} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'

const ChangeTimezoneModal = (props) => {
  const {timezone} = props
  const {translate} = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }
  const {submit: changeTimezone} = UpdateUser(
    ({
      data: {
        updateUser: {id, timezone},
      },
    }) => {
      toggle()
      // alert("success")
    },
    (error) => {
      console.error(error)
    },
  )
  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className='modal-centered'
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
          >
            <span className='h2 font-weight-bold'>
              {translate('profile.actions.editTimezone')}
            </span>
          </ModalHeader>
        }
      >
        <div className='m-0 p-0 d-flex flex-column px-4 mb-4 pt-0'>
          <TimezoneSelector
            title={null}
            selected={timezone}
            mutation={(newZone) => {
              changeTimezone({
                input: {timezone: newZone},
              })
            }}
            className={null}
          />
        </div>
      </Modal>
    </>
  )
}
export default ChangeTimezoneModal
