import React, {useEffect, useState} from 'react'
import {Input, Button} from '@Knowledge-OTP/znk-ui-components'
import {useMutation, gql} from '@apollo/client'
import useTranslation from '../../../../i18n/useTranslation'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'

const SendEmailRecovery = (props) => {
  const {defaultEmail} = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const [email, setEmail] = useState(null)
  useEffect(() => {
    if (defaultEmail) setEmail(defaultEmail)
  }, [defaultEmail])
  const [getCode, {loading}] = useMutation(
    gql`
      mutation($email: String!) {
        getRecoveryPasswordCode(email: $email)
      }
    `,
  )
  return (
    <div>
      <form onSubmit={(e) => {}}>
        <div className='h1 font-weight-light text-logo'>
          {translate('login.signin.email.recoveryPassBigTitle')}
        </div>
        <div className='h4 my-2 font-weight-light text-secondary'>
          {translate('login.signin.email.recoveryPassTitle')}
        </div>
        <span className='text-secondary font-weight-light'>
          {translate('login.signin.email.label')}
        </span>
        <Input
          className='my-2'
          name='email'
          value={email}
          placeholder={translate(`login.signin.email.placeholder`)}
          onChange={({target: {value}}) => {
            setEmail(value)
          }}
        />
        {false && (
          <div className='my-2 text-center text-error h3 w-100'>
            {translate('login.signin.email.invalid')}
          </div>
        )}
        <Button
          className='w-100 mt-2'
          color='primary'
          check
          activity={loading}
          type='button'
          onClick={() => {
            getCode({
              variables: {email},
            }).then(
              () => {
                showAlert(
                  {
                    text: translate(
                      'login.signin.email.recoveryPassEmailSuccess',
                    ),
                    status: 'success',
                  },
                  dispatch,
                )
                // const {getRecoveryPasswordCode} = data
                // if(getRecoveryPasswordCode) history.push(`?page=reset&code=${getRecoveryPasswordCode}`)
              },
              (error) => {
                showAlert(
                  {
                    text: translate(
                      'login.signin.email.recoveryPassEmailError',
                    ),
                    status: 'error',
                  },
                  dispatch,
                )
                console.error(error)
              },
            )
          }}
          label={translate('login.signin.email.recoveryPassSendEmail')}
        />
      </form>
    </div>
  )
}

export default SendEmailRecovery
