import es from './es'
import en from './en'
import status from './status'
import categories from './categories'

export default {
  es,
  en,
  status,
  categories,
}
