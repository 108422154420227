import clone from 'lodash/clone'
import strings from './strings'
import getLocale from './getLocale'

const defaultLang = 'en'

const getString = function (key, lang) {
  const parts = key.split('.')
  let data = clone(strings)
  for (let i = 0; i < parts.length; i++) {
    const currentKey = parts[i]
    if (i === parts.length - 1) {
      const final = data[lang]
      if (!final) return null
      const result = final[currentKey]
      return result
    }
    data = data[currentKey]
    if (!data) return null
  }
  return null
}

const generateStringTemplate = function (string) {
  const sanitized = string
    .replace(
      /\$\{([\s]*[^\s]+[\s]*)\}/g,
      (_, match) => `\${map.${match.trim()}}`,
    )
    .replace(/(\$\{(?!map\.)[^}]+\})/g, '')

  // eslint-disable-next-line
  const fn = Function('map', `return \`${sanitized}\``)
  return fn
}

const evaluateString = function (string, params) {
  if (typeof string === 'function') {
    return string(params)
  }
  const template = generateStringTemplate(string)
  return template(params)
}

const translate = function (key, params, lang) {
  if (!key) return ''
  lang = lang || getLocale()
  let string = getString(key, lang)
  if (!string) string = getString(key, defaultLang)
  if (!string) {
    // eslint-disable-next-line
    console.warn(`No translation for "${key}" in lang "${lang}"`)
    return key
  }
  try {
    return evaluateString(string, params)
  } catch (e) {
    if (params) {
      // eslint-disable-next-line
      console.log(
        `Error translating "${key}" in lang "${lang}" with params`,
        params,
        e,
      )
    } else {
      // eslint-disable-next-line
      console.log(`Error translating "${key}" in lang "${lang}"`, e)
    }
    return key
  }
}

global.translate = translate

export default translate
