import {useLazyQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../Model/QueryResult/useLessonResult'

export const UseCalendarView = () => {
  return useLazyQuery(
    gql`query(
            $kinds: [String!]
            $userId: ID
            $from: DateTime!
            $to: DateTime!
          ) {
        calendarView(
            kinds: $kinds
            userId: $userId,
            from: $from,
            to: $to
        ) {  
            ${queryLessonResult}
            ... on Counselor {
                id
                startDate
                endDate
                counselorId
                CounselorCreated : createdAt
                CounselorUpdate : updatedAt
            }
            ... on EducatorUnavailability {
                id
                kind
                startDate
                endDate
                busy
                educatorId
                userIdsInvolved
            }
        }
        }`,
    {
      fetchPolicy: 'no-cache',
    },
  )
}

export default UseCalendarView
