export default {
  title: 'Your study plan is ready!',
  subtitle:
    'Cool, we send an email Integer posuere erat a ante venenatis dapibus posuere velit aliquettiam a sem malesuad.',
  effortTitle: 'Effort/Attendance',
  effortText:
    'Students are expected to attend all lessons and actively participate in class.',
  cancelationTitle: 'Cancellation',
  cancelationText:
    "Classes need to be rescheduled 24 hours in advance of the lesson time. It is the student's responsibility to do so within their MyZinkerz calendar.",
  homeworkTitle: 'Homework',
  homeworkText:
    'Homework should be completed before the start of every lesson. Students should review lesson notes to know the assignments that are due.',
  supportTitle: 'Support',
  supportText:
    'We are here for you. Please reach out to us via WhatsApp for anything you need.',
  goto: 'Go to My Zinkerz',
}
