import {useMutation, gql} from '@apollo/client'

const useUploadPdfBase64Mutation = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation(
        $bucket: String!
        $file: String!
        $fileName: String
        $type: String
      ) {
        uploadPdfBase64(
          bucket: $bucket
          file: $file
          fileName: $fileName
          type: $type
        )
      }
    `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useUploadPdfBase64Mutation
