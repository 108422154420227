import {gql, useMutation} from '@apollo/client'

const useSetScoresToPlan = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $examScore: ExamInput!
                $planId: ID!
            ) {
                setScoresToPlan (
                    examScore: $examScore
                    planId: $planId
                ) {
                    id
                }
            }
        `, {onCompleted, onError}
    )
}

export default useSetScoresToPlan