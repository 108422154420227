import React from 'react'
import {Task} from '@Knowledge-OTP/znk-ui-components'

const InfoTaskButton = (props) => {
  // eslint-disable-next-line
  const { task, onClick = () => { }, icon = null, textLabel, background = '#F2F2FF', colorText = '#696CFF'} = props

  return (
    <>
      <Task
        style={{
          cursor: 'pointer',
          backgroundColor: background,
        }}
        noIcon
        taskClassName='d-flex justify-content-between mb-1'
        className='d-flex justify-content-end'
        labelLeft={
          <span
            style={{
              color: colorText,
            }}
            className='text-truncate w-100'
          >
            {textLabel}
          </span>
        }
        labelRight={icon}
        onClick={onClick}
      />
    </>
  )
}

export default InfoTaskButton
