import React from 'react'
import {IconOutLine, ListItem} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'

const PlanBreakdown = (props) => {
  const {className, plan} = props
  const {discount, pricingRate} = plan
  const discountAmount = (pricingRate || 0) * (discount / 100) || 0
  return (
    <div className={`m-0 p-0 d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Plan Breakdown</span>
      {/* <RenderInputOption key={'amount'} label={`Amount`}>
                <AmountInput value={pricingRate} onChange={(e) => {
                    setData({pricingRate: e})
                }} />
            </RenderInputOption> */}
      <ListItem
        className='w-100 mt-2'
        showBottomLine={false}
        showTopLine={false}
        Left={<span>Total price</span>}
        Right={<span>{`$${parseFloat(pricingRate || 0).toFixed(2)}`}</span>}
      />
      {discount > 0 && (
        <>
          <TabContent activeTab='set' className='mt-2'>
            <TabPane tabId='set'>
              <ListItem
                className='w-100'
                showBottomLine={false}
                showTopLine={false}
                Left={
                  <div className='d-flex'>
                    <IconOutLine
                      className='p-3 mr-1'
                      backgroundColor='#FF3E1D'
                      color='#FFFFFF'
                      textChildren={
                        <span className='m-0' style={{color: 'white'}}>
                          x
                        </span>
                      }
                      onClick={() => {}}
                      sizePx={10}
                    />
                    <span>Discount</span>
                  </div>
                }
                Right={<span>{`%${parseInt(discount)}`}</span>}
              />
            </TabPane>
          </TabContent>
          <ListItem
            className='w-100 mt-2'
            showBottomLine={false}
            showTopLine={false}
            Left={<span>Total price</span>}
            Right={
              <span>{`$${parseFloat(
                (pricingRate || 0) - (discountAmount || 0),
              ).toFixed(2)}`}</span>
            }
          />
        </>
      )}
    </div>
  )
}

export default PlanBreakdown
