import React from 'react'
import {ListItem} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import {useSelector} from 'react-redux'
import formatNumber from '../../../helpers/formatNumber'

const PaymentSchedule = (props) => {
  const {data, selectedPayments, post} = props
  const {translate} = useTranslation()
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const useLogicOfTimeless = !dataPlan?.isTestDate
  /** *const parseFeeToPaymentScheduled = (arr) => {
    const cuotas = arr.length
    return arr.map((item, index) => {
      return {
        amount: item.value / cuotas,
        date: item.time,
        id: index,
      }
    })
  }
  const totalCost = data.filter((pay) => pay.type === 'lessons-payment').reduce((sum, pay) => {
    return sum + pay.amount
  }, 0)
  const totalValue =
    dataPlan?.kind === 'lessonsFixed'
      ? parseFeeToPaymentScheduled(
          GetFeeFromRangeDate(
            moment(dataPlan.startDate),
            moment(dataPlan.endDate),
            {amount: totalCost},
          ),
        )
          .map((pay) => {
            return pay.amount
          })
          .reduce((count, number) => {
            return count + number
          }, 0)
      : data
          .map((pay) => {
            return pay.amount
          })
          .reduce((count, number) => {
            return count + number
          }, 0)**/

  /** Al aprobar plan: {translate('home.onboard.approve.paymentPlanap')}**/
  return (
    <div className='my-5 mx-0'>
      <h3>
        <b>{translate('home.onboard.approve.paymentTitle')}</b>
      </h3>
      <div className='m-0 p-0 my-4'>
        {data ? (
          <>
            <ListItem
              showTopLine={false}
              className='pl-0 py-1'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <span>
                    {translate('home.onboard.approve.before.whenPlanIsApprove')}
                  </span>
                </div>
              }
              Right={
                <span>
                  $
                  {`${formatNumber(
                    data
                      .filter((pay) => pay.type === 'others-fixed')
                      .reduce((cuota, pay) => {
                        return cuota + pay.amount
                      }, 0),
                  )}`}
                </span>
              }
            />
            {dataPlan && useLogicOfTimeless && !post ? (
              <ListItem
                showTopLine={false}
                className='pl-0 py-1'
                Left={
                  <div className='d-flex align-items-center text-muted'>
                    <span>{translate('home.onboard.approve.tbd')}</span>
                  </div>
                }
                Right={
                  <span>
                    $
                    {`${formatNumber(
                      data
                        .filter((pay) => pay.type !== 'others-fixed')
                        .reduce((total, pay) => {
                          return pay.amount + total
                        }, 0),
                    )}`}
                  </span>
                }
              />
            ) : (
              selectedPayments.map((pay, idx) => {
                // aca cambiar la periodicidad del pago
                return (
                  <ListItem
                    key={idx}
                    showTopLine={false}
                    className='pl-0 py-1'
                    Left={
                      <div className='d-flex align-items-center text-muted'>
                        <span>
                          {pay.id === 'totalCost'
                            ? `${translate(
                                'home.onboard.approve.paymentTotalCost',
                              )}`
                            : `${translate(
                                'home.onboard.approve.paymentEndof',
                              )} ${moment.utc(pay.date).format('MMMM')}`}
                        </span>
                      </div>
                    }
                    Right={<span>${`${formatNumber(pay.amount)}`}</span>}
                  />
                )
              })
            )}
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
    </div>
  )
}

export default PaymentSchedule
