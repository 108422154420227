export default {
  modalTitle: 'Cambiar configuración de notificaciones',
  lessonReminder: 'Recordatorio de Lección',
  lessonReminderDesc:
    'Te enviaremos un mensaje de Whatsapp antes de que comienze tu próxima lección.',
  missedLessons: 'Lección perdida',
  missedLessonsDesc:
    'Te enviaremos un mensaje de Whatsapp cuando faltes a alguna lección.',
  reminderSet: 'Añadir recordatorio',
  reminderMinutes: (num) => `${num} min antes`,
}
