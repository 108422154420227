import {gql, useQuery} from '@apollo/client'
import validate from './validation'
import educatorNotesResult from '../../../../../../Model/QueryResult/useEducatorNotes'
export default (vars) => {
  const {data, error, loading} = useQuery(
    gql`
      query(
        $planId: String
        $sortField: String
        $previous: String
        $next: String
        $limit: Int
        $status: [LessonStatus!]!
      ) {
        myLessons(
          planId: $planId
          sortField: $sortField
          previous: $previous
          next: $next
          limit: $limit
          status: $status
        ) {
          hasNext
          hasPrevious
          totalDocs
          docs {
            ... on PlanLesson {
              student {
                studentUserId
                planId
                firstName
                lastName
                rate
                attendanceStatus
                isManualRate
                ignoreAvailabilty
                whatsappAlertSent
                wasLate
                rating
              }
            }
            ... on GroupLesson {
              students {
                firstName
                lastName
                studentUserId
                rating
                wasLate
                attendanceStatus
                rate
                planId
              }
            }
            id
            status
            title
            description
            educator {
              educatorUserId
              firstName
              lastName
              rate
              isManualRate
              ignoreAvailabilty
              ignorePaymentItem
            }
            service {
              id
              name
            }
            topic {
              id
              name
            }
            commentForEducator
            recordingUrl
            braincertId
            backupLink
            recurringId
            kind
            startDate
            endDate
            busy
            userIdsInvolved
            notes {
              status
              educatorNotes {
                ${educatorNotesResult}
              }
              sentTime
            }
          }
        }
      }
    `,
    {
      variables: vars,
    },
  )
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: {}}
  }
  return {loading, data: data ? data.myLessons : {}, error}
}
