// String con modelo de objeto que será utilizado para
// obtener una Phase de un plan.
const phasesQueryResult = `
    phaseName
    steps {
        id
        stepName
        idReference
        deleteAt
        tasks {
            id
            name
            idReference
            instructions
            type
            dueTime
            buttonLabel
            linkToShow
            isEspecific
            isReviewable
            status
            userId
            deleteAt
        }
    }
`

export default phasesQueryResult
