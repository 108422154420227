import React, {useEffect, useState} from 'react'
import Welcome from './inner/Welcome'
import End from './inner/End'
import {TabContent, TabPane} from 'reactstrap'
import MediumModal from './mediumModal'
import SmallModal from './smallModal'
import ConflictModal from './smallModal/scheduledConflict'
import './style.css'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../store/modal/const'
import SelectContinueOnboard from './components/SelectContinueOnboard'
import ReloadOnboarding from './components/ReloadOnboarding'
import {makeObjectOnboarding} from './utils.js'
import {gql, useLazyQuery, useMutation} from '@apollo/client'
import {useParams, useHistory} from 'react-router'
import useTranslation from '../../i18n/useTranslation'
import bean from '../../assets/bean.svg'
import beanBlue from '../../assets/beanBlue.svg'
import beanMort from '../../assets/beanMort.svg'
import beanWar from '../../assets/beanWar.svg'
import moment from 'moment-timezone'
import {showAlert} from '../../common/Alert/util'
import {useLocation} from 'react-router-dom'
import useSocketEntities from '../../common/SocketGateway/useSocketEntities'
import AdjustTimezone from './components/AdjustTimezone'
import UseDelSessionWithPlanId from './Model/useDelOnboardingSessionWithPlanId'
import firebase from 'firebase/app'
import {useCookies} from 'react-cookie'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useGetOwnerPlan from './Model/useGetOwnerPlan'

/**
 * Mutaciones y Queries
 * myPlan
 * getOnboardingSession
 * setOnboardingState
 * cancelScheduledPlanFromStudent
 *
 * **/

const OnboardingPage = () => {
  const [isOpenModalLogout, setOpenModalLogout] = useState(false)
  const {connection: socket} = useSocketEntities()
  // estilo de fondo
  const style = {
    backgroundImage: `url(${bean}), url(${beanBlue}), url(${beanWar}), url(${beanMort})`,
    backgroundPosition: '-5% 83%, 15% 24%, 90% -35%, 84% 112%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200px, 50px, 250px, 200px',
  }

  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {idPlan} = useParams()
  const {page} = useSelector((state) => state.modal.onboarding.mainPage)
  // const reduxMedium = useSelector((state) => state.modal.onboarding.mediumModal)
  const countdown = useSelector((state) => state.modal.onboarding.countdown)
  const meUser = useSelector((state) => state.user.info)
  const {timezone: zone, roles} = meUser
  const [delSessionWithPlanId] = UseDelSessionWithPlanId()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // si isAdmin es true verifica si los datos del usuario entrante realmente son de administrador
  const isAdmin =
    searchParams.get('isAdmin') === 'true' && roles?.includes('SUPER_ADMIN')

  const history = useHistory()
  // Things to do before unloading/closing the tab
  const doSomethingBeforeUnload = (idPlan) => {
    // Do something
    // Elimina la sesion y elimina las
    // lecciones/reservaciones vinculadas al plan
    socket.emit('leave', idPlan)
  }
  // Setup the `beforeunload` event listener
  const setupBeforeUnloadListener = (idPlan) => {
    window.addEventListener(
      'beforeunload',
      (ev) => {
        ev.preventDefault()
        doSomethingBeforeUnload(idPlan)
      },
      {capture: true},
    )
    window.addEventListener(
      'popstate',
      (ev) => {
        ev.preventDefault()
        doSomethingBeforeUnload(idPlan)
      },
      {capture: true},
    )
  }

  const setCountdown = (momentIso) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.countdown',
      data: momentIso,
    })
  }

  const connectToRoomSession = (idPlan, status = null) => {
    if (socket) socket.emit('join', idPlan, status)
  }

  const [checkIfHaveRooms, {data: haveRoom}] = useLazyQuery(
    gql`
      query($planId: ID!) {
        getOnboardingSession(planId: $planId)
      }
    `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  )
  const [, {loading: loadState}] = useMutation(
    gql`
      mutation update($PlanInputStateOnboarding: PlanInputStateOnboarding!) {
        setOnboardingState(input: $PlanInputStateOnboarding)
      }
    `,
    {
      update: () => {
        cancelOnboard({
          variables: {
            planId: idPlan,
          },
        })
      },
    },
  )
  const [cancelOnboard, {loading: loadCancel}] = useMutation(
    gql`
      mutation($planId: ID!, $isAdmin: Boolean) {
        cancelScheduledPlanFromStudent(planId: $planId, isAdmin: $isAdmin)
      }
    `,
    {
      update: () => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'onboarding.data',
          data: makeObjectOnboarding(
            data?.myPlan,
            services,
            data?.allUsersCounseler[0],
            zone,
          ),
        })
        // se cierra el modal de retomar onboarding
        setOpenContinue(false)
        // dispatch({
        //  type: SET_DATA_MODAL_FORM_LIST,
        //  payload: 'onboarding.mediumModal',
        //  data: {...reduxMedium, isOpen: true},
        // })
      },
    },
  )
  const [getDataPlan, {data}] = useLazyQuery(
    gql`
      query($planId: ID!, $isAdmin: Boolean, $type: PaymentCategory!) {
        myPlan(planId: $planId, isAdmin: $isAdmin) {
          id
          serviceId
          createdAt
          name
          testDate
          onboardingState
          checkInDates {
            id
            date
            type
          }
          studentUser {
            studentUserId
            firstName
            lastName
            rate
          }
          createdAt
          typePlanPricing
          pricingRate
          recommendedEducators {
            firstName
            lastName
            topicId
            educatorUserId
            isLockEducator
            teachingType
            isLockEducator
          }
          status
          kind
          groupId
          groupPricingRate
          paymentItemsConfig {
            amount
            percentDiscount
            amountWithoutDiscount
            date
            description
            type
          }
          isFirstPlan
          ... on LessonsPerWeekPlan {
            parts {
              lessonsPerWeek
              totalLessons
              from
              to
              name
              lessonPerWeekPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
                totalLessons
              }
            }
            adjustedConfig {
              adjustedParts {
                lessonsPerWeek
                totalLessons
                from
                to
                name
                canceled
                lessonPerWeekPerTopic {
                  topicId
                  lessonsPerWeek
                  lockLessons
                  totalLessons
                }
                lessonsPerWeekGroup {
                  topicId
                  educatorId
                  day
                  duration
                  hour
                  totalLessons
                }
              }
              adjustedPayments {
                amount
                percentDiscount
                amountWithoutDiscount
                date
                description
                type
              }
              ignoredIndividualLessons
              ignoredGroupLessons
            }
            groupPerWeekInfo {
              PartsSchedule {
                name
                lessonsPerWeekGroup {
                  topicId
                  educatorId
                  day
                  duration
                  hour
                  totalLessons
                }
                from
              }
            }
          }
          ... on LessonsFixedPlan {
            partsFixed: parts {
              TotalPerTopic {
                topicId
                lessonsPerWeek
                lockLessons
                totalLessons
              }
              type
              name
              totalLessons
              from
              to
            }
            totalCost
            groupFixedInfo {
              PartsSchedule {
                name
                lessonsPerWeekGroup {
                  topicId
                  educatorId
                  day
                  duration
                  hour
                  totalLessons
                }
                from
              }
            }
          }
        }
        allUsersCounseler(type: $type) {
          id
          firstName
          lastName
          timezone
          emails {
            address
            verified
          }
          phone
        }
      }
    `,
  )

  useEffect(() => {
    checkIfHaveRooms({
      variables: {planId: idPlan},
    })
    getDataPlan({
      variables: {planId: idPlan, isAdmin, type: 'livePrep'},
    })
    // getOrientationData({variables: {}})
    // eslint-disable-next-line
  }, [isAdmin])

  const [isOpenReload, setOpenReload] = useState(false)
  const toggleReload = () => {
    setOpenReload(!isOpenReload)
  }
  // INICIA CONTADOR
  useEffect(() => {
    if (countdown) {
      const timer = setTimeout(() => {
        setCountdown(null)
        toggleReload()
      }, moment.duration(moment(countdown).diff(moment())).asMilliseconds())
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [countdown])
  const deleteSessionFn = (idAlert) => {
    delSessionWithPlanId({
      variables: {planId: idPlan},
    }).then(() => {
      dispatch({
        type: 'REMOVE_ALERT',
        payload: {id: idAlert},
      })
      window.location.href = `/onboarding/${idPlan}`
    })
  }
  const services = useSelector((state) => state.services.services)
  const [wasLoaded, setWasLoaded] = useState(false)
  useEffect(() => {
    if (haveRoom?.getOnboardingSession !== undefined) {
      const idAlert = new Date().getTime()
      if (haveRoom.getOnboardingSession) {
        showAlert(
          {
            text: translate('home.onboard.sessionActiveRedirect'),
            status: 'error',
            permanet: false,
            id: idAlert,
            onClick: () => {
              deleteSessionFn(idAlert)
            },
          },
          dispatch,
        )
        history.push(`/calendar/lessons`)
      } else if (
        data?.myPlan &&
        data?.allUsersCounseler?.length > 0 &&
        !wasLoaded
      ) {
        if (
          userIdOwnerPlan &&
          userIdOwnerPlan !== meUser?.id &&
          !approveFromParent &&
          !isAdmin
        ) {
          setOpenModalLogout(true)
        } else {
          // añade el evento que reinicia el plan cuando se cierra la pagina
          // (tambien se ejecuta el evento cuando se hace back o cmabia de url)
          setupBeforeUnloadListener(data?.myPlan?.id)
          // inicia sesion para idPlan
          connectToRoomSession(data?.myPlan?.id, 'welcome')
          // y actualiza status de onboarding

          let statusNotValid = false
          // verifica que el estado del plan sea 'sent' para el estudiante y 'draft' o 'sent' para admin
          // si el plan no está en alguno de estos estados plataforma redirige a calendar/lessons
          if (isAdmin)
            statusNotValid = !['draft', 'sent'].includes(data?.myPlan?.status)
          else statusNotValid = !['sent'].includes(data?.myPlan?.status)

          if (statusNotValid) {
            // SI EL PLAN YA ESTÁ AGENDADO NO ELIMINA NI BLANQUEA LAS LECCIONES
            history.push(`/calendar/lessons`)
          } else {
            if (
              data?.myPlan?.onboardingState !== null &&
              data?.myPlan?.onboardingState !== ''
            ) {
              cancelOnboard({
                variables: {
                  planId: idPlan,
                  isAdmin,
                },
              })
            } else {
              dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'onboarding.data',
                data: makeObjectOnboarding(
                  data?.myPlan,
                  services,
                  data?.allUsersCounseler[0],
                  zone,
                ),
              })
            }
            setWasLoaded(true)
          }
        }
      }
      // else if (data) {
      // if (!data.myPlan) history.push(`/calendar/lessons`)
      // }
    }
    // eslint-disable-next-line
  }, [data, haveRoom])

  const userIdOwnerPlan = data?.myPlan?.studentUser?.studentUserId
  const approveFromParent = meUser?.parentInfo?.children.find(
    ({studentUserId}) => studentUserId === userIdOwnerPlan,
  )
  const [, , removeCookie] = useCookies(['znk-signup'])
  const {data: dataUserOwnerPlan} = useGetOwnerPlan({
    id: userIdOwnerPlan,
  })

  const [isOpenContinue, setOpenContinue] = useState(false)
  const [continueModalProps] = useState({
    confirmAction: () => {},
    cancelAction: () => {},
  })
  return (
    <>
      <TabContent
        activeTab={page}
        className='px-2 h-100'
        transition
        style={style}
      >
        <TabPane tabId='welcome' className='h-100'>
          {!isOpenModalLogout ? (
            <Welcome
              setCoundown={setCountdown}
              countdown={countdown}
              whenRedirect={() => {
                doSomethingBeforeUnload(idPlan)
              }}
            />
          ) : (
            <ConfirmModal
              className=''
              classNameActionDiv=''
              classNameConfirm=''
              classNameCancel=''
              loading={false}
              isOpen={isOpenModalLogout}
              toggle={false}
              title={'Wrong Email'}
              body={
                `You are logged in with ${meUser?.emails[0]?.address}. Please log in using the student email (${dataUserOwnerPlan?.user?.emails[0]?.address})` +
                (dataUserOwnerPlan?.user?.studentInfo?.parents[0]?.email
                  ? ` or parent email (${dataUserOwnerPlan?.user?.studentInfo?.parents[0]?.email}) associated with this plan.`
                  : '.')
              }
              confirmAction={() => {
                removeCookie('znk-signup')
                firebase.auth().signOut()
              }}
              cancelAction={() => {}}
              confirmText={'Logout'}
              cancelText={null}
              disabledCancel
              backdrop={[false, 'static']}
            />
          )}
        </TabPane>
        <TabPane tabId='end'>
          <End />
        </TabPane>
      </TabContent>
      <MediumModal isAdmin={isAdmin} />
      <SmallModal isAdmin={isAdmin} />
      <ConflictModal isAdmin={isAdmin} />
      <SelectContinueOnboard
        loading={loadState || loadCancel}
        isOpen={isOpenContinue}
        toggle={() => {
          setOpenContinue(!isOpenContinue)
        }}
        {...continueModalProps}
      />
      <ReloadOnboarding
        loading={false}
        isOpen={isOpenReload}
        toggle={toggleReload}
        confirmAction={() => {
          window.location.reload()
        }}
      />
      <AdjustTimezone />
    </>
  )
}

export default OnboardingPage
