import React from 'react'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import {useSelector} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import useMyCollegeModal from '../../MyCollegeModal/useMyCollegeModal'
import useUniversityDirectory from '../../../../University/UniversityDirectory/useUniversityDirectory'
import axios from 'axios'
import {
  BlankSpace,
  useWindowDimensions,
  CarouselList,
} from '@Knowledge-OTP/znk-ui-components'
import {useGetUniversity} from '../../../../MyPlans/viewCounselingPlanModal/Tabs/Universities/Model/useGetUniversity'

const CollegeURL = process.env.COLLEGE_URL || process.env.REACT_APP_COLLEGE_URL
const CollegeAPI = process.env.COLLEGE_API || process.env.REACT_APP_COLLEGE_API

// Arreglo con el listado de información que necesitamos traer desde la API de Colleges
const infoToObtain = [
  'name',
  'short_description',
  'city',
  'state_abbr',
  'type_category',
  'undergraduate_size',
  'acceptance_rate',
  'four_year_graduation_rate',
  'rankings_best_colleges',
  'sat_average',
  'act_cumulative_midpoint',
  'type_year',
  'avg_cost_of_attendance',
  'logo_image',
  'website',
]

const UniversitiesSection = (props) => {
  //  eslint-disable-next-line
  const {plan = {}} = props

  const {open: openCollegeModal, setUpdateUniversity} = useMyCollegeModal()
  const {open: openUniversityDirectory} = useUniversityDirectory()
  const collegesListState = useSelector(
    (state) => state?.user?.info?.studentInfo?.universities,
  )
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()

  // eslint-disable-next-line
    const [getOneUniversity, { data: dataOneUniversity, loading: loadingOneUniversity, refetch: refetchOneUniversity }] = useGetUniversity()

  const GetModalUniversityInfo = async (college, infoToObtain = []) => {
    let object = {}
    // Convertir el arreglo a una string para pasar por params a la API
    const params = infoToObtain.join('%2C')
    if (!college?.isPrincipal) {
      const res = await axios.get(
        `${CollegeURL}/college/info?api_key=${CollegeAPI}&college_unit_ids=${college.collegeId}&info_ids=${params}`,
      )
      object = res?.data.colleges[0]
      object.collegeUnitId = college.collegeId
      object.id = college.id
      object.status = college.status
      object.early = college.early
      object.isActive = college.isActive
      object.isPrincipal = college.isPrincipal
      object.deletedAt = college.deletedAt || null
      object.logo = college.logo
      setUpdateUniversity(object)
      // return object
    } else {
      const res = await getOneUniversity({
        variables: {
          universityId: college?.id,
        },
      })
      setUpdateUniversity(res?.data?.getUniversity)
      object = res?.data?.getUniversity
    }
    return object
  }

  return (
    <TabContent
      activeTab={
        collegesListState
          ? collegesListState?.length > 0
            ? 'show'
            : 'blank'
          : 'loading'
      }
    >
      <TabPane tabId='loading'>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between mx-2 w-100'>
            <Skeleton count={1} height={10} width={70} />
            <Skeleton count={1} height={10} width={40} />
          </div>
          {breakWidth === 'SM' ? (
            <Skeleton count={5} height={90} className='w-100 mb-2' />
          ) : (
            <div className='d-flex flex-row'>
              {new Array(5).fill(0).map(() => {
                return (
                  <Skeleton
                    className='mx-1'
                    count={1}
                    height={110}
                    width={110}
                  />
                )
              })}
            </div>
          )}
        </div>
      </TabPane>
      <TabPane tabId='show'>
        <CarouselList
          title={translate('counseling.myUniversities')}
          onClickItem={async (college) => {
            const collegeInfo = await GetModalUniversityInfo(
              college,
              infoToObtain,
            )
            openCollegeModal(() => {}, collegeInfo, plan)
          }}
          onClickExplore={() => {
            openUniversityDirectory()
          }}
          studentColleges={collegesListState}
        />
      </TabPane>
      <TabPane tabId='blank'>
        <div>
          <div className='mb-3 d-flex flex-row justify-content-between align-items-center'>
            <span className='h3'>{translate('counseling.myUniversities')}</span>
            <span
              onClick={() => openUniversityDirectory()}
              className='btn-link h5 cursor-pointer'
            >
              {translate('counseling.explore')}
            </span>
          </div>
          <BlankSpace
            className='w-100 p-7'
            textClassName='p-0 mb-2'
            contentClassName='p-0'
            iconClassName='m-0 p-0'
            linkProps={{
              text: (
                <span className='h45'>
                  {translate('counseling.learnMoreCounseling')}
                </span>
              ),
              link: '',
            }}
            mainText={
              <span className='h45'>
                {translate('counseling.emptyCarrousel')}
              </span>
            }
          />
        </div>
      </TabPane>
    </TabContent>
  )
}

export default UniversitiesSection
