import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import queryUserAccount from '../../../QueryResult/useUserAccountResult'

export default (onSuccess = () => {}, onError = () => {}) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
          mutation(
            $accountInput: AccountStudentInput!
          ) {
            setAccountFromUser(
              accountInput: $accountInput
            ) {
                ${queryUserAccount}
            }
          }
        `,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {loading, data: data ? data.setAccountFromUser : null, error, submit}
}
