import {useLazyQuery, gql} from '@apollo/client'
import taskQueryResult from '../../../QueryResult/useTaskResult'

export const UseGetAllUserTasks = () => {
  // Sirve para traer todas las tasks de un alumno.
  // Pero esta misma query la podemos usar para traer las tasks de una universidad,
  // solo las de un cierto estado, etc.
  return useLazyQuery(
    gql`query(
            $universityId: ID
            $status: [StatusTask!]
            $planId: ID!
              )
              {
        getUserTasks(universitieId: $universityId
                     status: $status
                     planId: $planId
    ) {
        ${taskQueryResult}
    }
}`,
    {
      fetchPolicy: 'no-cache',
    },
  )
}
