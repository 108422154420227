import es from './es'
import en from './en'
import modal from './modal'
import rating from './rating'

export default {
  es,
  en,
  modal,
  rating,
}
