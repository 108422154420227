import {useLazyQuery, gql} from '@apollo/client'

const useLazyGetExamScore = (variables={}) => {
    return useLazyQuery(
        gql`
            query (
                $examScoreId: ID!
            ) {
                getOneExamScore (
                    examScoreId: $examScoreId
                ) {
                    name
                    results {
                        name
                        scale
                        method
                        isOverallHidden
                        isPrincipal
                        values {
                            name
                            scale
                            isActive
                            isOnMethod
                        }

                    }
                }
            }
        `,
        {
            variables
        }
    )
}

export default useLazyGetExamScore