const queryStudentResult = `
    id
    gender
    countryId
    createdAt
    updatedAt
    firstName
    lastName
    phone
    timezone
    spokenLanguages
    address
    city
    state
    zipCode
    birthdate
    roles
    isActive
    isCounselor
    studentInfo {
        institution
        graduationDate
        studentType
        defaultRate
        gpa
        servicesRate {
            defaultRate
            serviceId
            name
        }
        parents {
            parentUserId
            managePayments
            manageApprovals
            firstName
            lastName
            email
            phone
        }
        notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
            isAvailable
            duration
            }
        }
        upcomingLesson {
            id
        }
    }
    `

export default queryStudentResult
