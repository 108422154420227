import React from 'react'
import {
  Avatar,
  IconLesson,
  IconRight,
  LessonCard,
  ListItem,
  LoadingSpinner,
  Stats,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
import useTranslation from '../../../../../../i18n/useTranslation'
import startCase from 'lodash/startCase'
import PaymentInfo from '../Payments'
import {useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import upperCase from 'lodash/upperCase'
import CheckInsSection from './Sections/CheckInsSection'
const Resume = (props) => {
  const {
    lessons,
    lessonsLoading,
    info,
    topicKeys,
    groupLessons,
    groupLoading,
    groupInfo,
  } = props
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const parts = info.parts || info.lessonPerTopic
  const now = moment()
  const upcomingGroupLesson = groupLessons.find(
    (l) => l.status === 'Scheduled' && l.lessonTime > now,
  )
  const upcomingIndividualLesson = lessons.find(
    (l) => l.status === 'Scheduled' && l.lessonTime > now,
  )
  let nextLesson = null
  if (upcomingGroupLesson || upcomingIndividualLesson) {
    if (upcomingGroupLesson) {
      if (
        upcomingIndividualLesson &&
        upcomingIndividualLesson.lessonTime < upcomingGroupLesson.lessonTime
      ) {
        nextLesson = upcomingIndividualLesson
      } else {
        nextLesson = upcomingGroupLesson
      }
    } else {
      nextLesson = upcomingIndividualLesson
    }
  }
  return (
    <div className='d-flex flex-column'>
      <p className='h4 font-weight-bold my-2'>
        {translate('program.plan.infoModal.planProgress')}
      </p>
      <div className='d-flex justify-content-center text-body'>
        <Stats
          loading={lessonsLoading}
          items={lessons.map((item) => ({
            ...item,
            status: startCase(item.status),
          }))}
        />
      </div>
      <div className='borderTop my-2 forceOverflow' />
      {groupLessons.length ? (
        <>
          <p className='h4 font-weight-bold my-2'>
            {translate('program.plan.infoModal.planGroupProgress')}
          </p>
          <div className='d-flex justify-content-center text-body'>
            <Stats
              loading={groupLoading}
              items={groupLessons.map((item) => ({
                ...item,
                status: startCase(item.status),
              }))}
            />
          </div>
          <div className='borderTop my-2 forceOverflow' />
        </>
      ) : null}
      <p className='h4 font-weight-bold mt-1 mb-2'>
        {translate('program.plan.infoModal.upcomingLesson')}
      </p>
      {lessonsLoading ? (
        <div className='w-100 my-4 d-flex flex-row align-items-center justify-content-center'>
          <LoadingSpinner />
        </div>
      ) : nextLesson ? (
        <LessonCard {...nextLesson} status='upcoming' />
      ) : (
        <p className='d-flex justify-content-center text-body p-4 mb-0'>
          {translate('misc.noLessonFound')}
        </p>
      )}
      <div className='borderTop my-2 forceOverflow' />
      <p className='h4 font-weight-bold mt-3 mb-2'>
        {translate('program.plan.weeklySchedule')}
      </p>
      {parts.map((part, idx) => {
        const groupPart = groupInfo?.PartsSchedule[idx]
        const groupLessonPerWeekPart = groupPart
          ? groupPart.lessonsPerWeekGroup.reduce((topicObj, groupBlock) => {
              if (topicObj[groupBlock.topicId]) {
                const prevInfo = topicObj[groupBlock.topicId]
                topicObj[groupBlock.topicId] = {
                  ...prevInfo,
                  lessonsPerWeek: prevInfo.lessonsPerWeek + 1,
                  totalLessons: prevInfo.totalLessons + groupBlock.totalLessons, // se suma para le mismo topico
                }
              } else {
                topicObj[groupBlock.topicId] = {
                  topicId: groupBlock.topicId,
                  lessonsPerWeek: 1,
                  totalLessons: groupBlock.totalLessons || 1,
                }
              }
              return topicObj
            }, {})
          : {}
        return (
          <div key={idx} className='my-2 d-flex flex-column text-body'>
            <span className='h5 my-2 text-gray font-weight-light'>
              {upperCase(
                translate('program.plan.infoModal.rangeDates', {
                  from: moment(part.from.split('T')[0]).format('MMMM Do'),
                  to: moment(part.to.split('T')[0]).format('MMMM Do'),
                }),
              )}
            </span>
            {Object.values(groupLessonPerWeekPart).map((topicPart) => {
              return (
                <ListItem
                  key={`${topicPart.topicId}-${idx}-group`}
                  className='py-2'
                  Left={
                    <div className='d-flex align-items-center text-body'>
                      <IconLesson size={16} className='text-body' />
                      <span className='ml-2 text-body text-truncate overflow-hidden'>
                        {translate('program.plan.infoModal.groupalLesson', {
                          num: topicPart.lessonsPerWeek,
                          topic: topicKeys[topicPart.topicId].name,
                        })}
                      </span>
                      <span className='text-gray ml-2'>
                        ({topicPart.totalLessons})
                      </span>
                    </div>
                  }
                  Right={<IconRight />}
                />
              )
            })}
            {(part.lessonPerWeekPerTopic || part.TotalPerTopic).map((lpw) => {
              return (
                <ListItem
                  key={`${lpw.topicId}-${idx}`}
                  className='py-2'
                  onClick={() =>
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'program.topicLessons',
                      data: {
                        isOpen: true,
                        topicId: lpw.topicId,
                        name: `${translate(
                          'program.plan.infoModal.lessonsIndividual',
                        )} ${
                          topicKeys[lpw.topicId].name || 'No Found'
                        } ${translate('program.plan.infoModal.lessons')}`,
                      },
                    })
                  }
                  Left={
                    <>
                      <IconLesson size={16} className='text-body' />
                      <span className='ml-2 text-body text-truncate overflow-hidden'>
                        {translate('program.plan.infoModal.individualLesson', {
                          num: lpw.lessonsPerWeek,
                          name: topicKeys[lpw.topicId].name || 'No Found',
                        })}
                      </span>
                    </>
                  }
                  Right={<IconRight />}
                />
              )
            })}
          </div>
        )
      })}
      <div className='borderTop my-2 forceOverflow' />
      <>
        <p className='h4 font-weight-bold mt-3 mb-2'>
          {translate('program.plan.checkInsSection')}
        </p>
        <CheckInsSection checkIns={info?.checkInDates || []} />
      </>
      <div className='borderTop my-2 forceOverflow' />
      <p className='h4 font-weight-bold my-1 mb-3'>
        {translate('program.plan.infoModal.educators')}
      </p>
      {info.recommendedEducators.map((item) => {
        return (
          <ListItem
            key={`${item.educatorUserId}-${item.topicId}`}
            className='py-1'
            Left={
              <div className='d-flex align-items-center text-body'>
                {breakWidth !== 'SM' ? (
                  <Avatar
                    name={`${item.firstName} ${item.lastName}`}
                    named={false}
                    uri={null}
                    size='avatar-small'
                  />
                ) : null}
                <span className='ml-2 mr-1'>
                  {`${item.firstName} ${item.lastName}`}
                </span>
                <span className='font-weight-light text-muted'>
                  ({topicKeys[item.topicId].name})
                </span>
              </div>
            }
            Right={<IconRight />}
          />
        )
      })}
      <div className='borderTop my-2 forceOverflow' />
      <PaymentInfo planId={info.id} />
    </div>
  )
}

export default Resume
