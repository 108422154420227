import {gql} from '@apollo/client'
import queryUserHashResult from '../Model/QueryResult/useUserHashResult'

export default gql`
  query {
    me {
      ${queryUserHashResult}
    }
    getUserHash
  }
`
