import React, {useEffect} from 'react'
import * as moment from 'moment'
import {getStringsAction} from '../../../store/translation/action'
import {connect} from 'react-redux'
import {LoadingPage} from '@Knowledge-OTP/znk-ui-components'

const I18nProvider = (props) => {
  // Get remote translations
  const {getTranslations, selectedLang, loading} = props
  useEffect(() => {
    getTranslations()
  }, [getTranslations])
  moment.locale(selectedLang)
  return loading ? <LoadingPage text='...' /> : props.children
}
const mapDispatchToProps = (dispatch) => ({
  getTranslations: () => dispatch(getStringsAction()),
})
const mapStateToProps = (state) => ({
  loading: state.translation.translationDictLoading,
  selectedLang: state.translation.selectedLang,
})
export default connect(mapStateToProps, mapDispatchToProps)(I18nProvider)
