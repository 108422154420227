import {useSelector, useDispatch} from 'react-redux'

const useScheduledMeetingModal = () => {
  const reduxData = useSelector((state) => state.modal.scheduledMeeting)
  const dispatch = useDispatch()
  const {isOpen} = reduxData
  const setData = (
    open = false,
    onChange = () => {},
    studentData = {},
    counselorData = {},
    useMutation = false,
    scheduledToStudent = false,
  ) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'scheduledMeeting',
      data: {
        isOpen: open,
        onChange,
        studentData,
        counselorData,
        useMutation,
        scheduledToStudent,
      },
    })
  }
  return {
    ...reduxData,
    open: (
      onChange = () => {},
      studentData = {},
      counselorData = {},
      useMutation = false,
      scheduledToStudent = false,
    ) => {
      setData(
        true,
        onChange,
        studentData,
        counselorData,
        useMutation,
        scheduledToStudent,
      )
    },
    close: () => {
      setData(false)
    },
    toggle: () => {
      setData(!isOpen)
    },
  }
}

export default useScheduledMeetingModal
