import React, {useEffect, useState} from 'react'
import {UserBubble} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {db} from '../../fireBase'

const UserItems = (props) => {
  const {usersList, eventID} = props
  const [visibleUsers] = useState(usersList)
  const {translate} = useTranslation()
  const [onlineStatus, setOnlineStates] = useState([])
  useEffect(() => {
    setOnlineStates([])
    // eslint-disable-next-line
    visibleUsers?.map(user => {
      let userID = ''
      switch (true) {
        case 'studentUserId' in user:
          userID = user.studentUserId
          break
        case 'educatorUserId' in user:
          userID = user.educatorUserId
          break
        default:
          userID = user.id
      }
      // eslint-disable-next-line
      if (!userID) return
      db.collection('usersStatus')
        .doc(eventID)
        .collection(userID)
        .doc('status')
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setOnlineStates((data) => {
              return {...data, [userID]: snapshot.data().online}
            })
          } else {
            setOnlineStates((data) => {
              return {...data, [userID]: false}
            })
          }
        })

      return {...user, id: userID}
    })
    // eslint-disable-next-line
  }, []);



  return (
    <>
      {visibleUsers instanceof Array &&
        visibleUsers.map((user) => (
          <>
            {user.id && (
              <UserBubble
                // imgContent={{img: user.avatar, size: 16}}
                toolTipProperties={{
                  referenceID: `userBubble${user.id}`,
                  toolTipText: `${user.firstName} ${user.lastName}`,
                  alignment: 'top',
                }}
                name={`${user.firstName} ${user.lastName}`}
                online={onlineStatus[user.id]}
                className='mr-4'
              />
            )}
          </>
        ))}
      {visibleUsers instanceof Array && visibleUsers.length >= 5 && (
        <div
          className='ml-3 mt-1 text-secondary'
          onClick={() => console.log('click')}
        >
          {translate('virtualChat.usersItems.usersConnected', {
            message: usersList.length,
          })}
        </div>
      )}
    </>
  )
}

export default UserItems
