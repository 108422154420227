import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import axios from 'axios'
import {
  ListItem,
  IconArrowRight,
  CheckboxOption,
  IconBank,
  IconAddUniversity,
  LogoItem,
  EarlyActionBadge,
  EarlyDecisionBadge,
} from '@Knowledge-OTP/znk-ui-components'
import useAllUniversities from '../../../../University/Model/useAllUniversities'
import useUniveristyDirectory from '../../../../University/UniversityDirectory/useUniversityDirectory'
import useMyCollegeModal from '../../../../Workspace/Student/MyCollegeModal/useMyCollegeModal'
import {useGetUniversity} from './Model/useGetUniversity'

const Universities = (props) => {
  const {
    selector = false,
    universities = null,
    setSelected,
    selected,
    plan,
    onAddUniversity,
  } = props
  const [obj, setObj] = useState({})
  const collegesList = useSelector(
    (state) => state.user.info.studentInfo.universities,
  )
  // eslint-disable-next-line
  const [inputValue, setInputValue] = useState('')
  // eslint-disable-next-line
  const { loading, data: universitiesQuery } = useAllUniversities({
    search: inputValue?.toLowerCase() || '',
  })
  // university directory
  const {open: openUniversityDirectory} = useUniveristyDirectory()
  const selectUniversity = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((idUniversity) => idUniversity !== id))
    } else setSelected(selected.concat([id]))
  }
  const {open: openMyCollegeModal, setUpdateUniversity} = useMyCollegeModal()

  // Arreglo con el listado de información que necesitamos traer desde la API de Colleges
  const infoToObtain = [
    'name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'logo_image',
    'website',
  ]

  // Mover a otro archivo??
  let object = {}
  const CollegeURL =
    process.env.COLLEGE_URL || process.env.REACT_APP_COLLEGE_URL
  const CollegeAPI =
    process.env.COLLEGE_API || process.env.REACT_APP_COLLEGE_API

  // eslint-disable-next-line
  const [getOneUniversity, { data: dataOneUniversity, loading: loadingOneUniversity, refetch: refetchOneUniversity }] = useGetUniversity()

  const GetModalUniversityInfo = async (
    college,
    infoToObtain = [],
    onChange = () => {},
  ) => {
    // Convertir el arreglo a una string para pasar por params a la API
    const params = infoToObtain.join('%2C')
    if (!college?.isPrincipal) {
      await axios
        .get(
          `${CollegeURL}/college/info?api_key=${CollegeAPI}&college_unit_ids=${college.collegeId}&info_ids=${params}`,
        )
        .then((res) => {
          object = res?.data.colleges[0]
          object.collegeUnitId = college.collegeId
          object.id = college.id
          object.status = college.status
          object.early = college.early
          object.isActive = college.isActive
          object.isPrincipal = college.isPrincipal
          object.deletedAt = college.deletedAt || null
          object.logo = college.logo

          setObj(object)
        })
      return object
    } else {
      getOneUniversity({
        variables: {
          universityId: college?.id,
        },
      })
    }
  }

  useEffect(() => {
    setUpdateUniversity(dataOneUniversity?.getUniversity)
    // eslint-disable-next-line
  }, [dataOneUniversity])

  useEffect(() => {
    setUpdateUniversity(obj)
    // eslint-disable-next-line
  }, [obj])

  return (
    <div className='d-flex flex-column'>
      {(universities || universitiesQuery?.docs || []).map((item) => {
        return (
          <ListItem
            className='pt-1'
            hasHover
            showBottomLine
            onClick={() => {
              if (!selector) {
                openMyCollegeModal(
                  () => {
                    // actualiza listado de universidades
                    // en viewPlanModal // Universities
                    onAddUniversity()
                  }, // onchange
                  item, // college
                  plan, // plan
                )
              }
            }}
            Left={
              <div className='d-flex flex-row ml-2'>
                <img
                  style={{
                    alignSelf: 'center',
                    width: selector ? '25px' : '40px',
                    height: selector ? '25px' : '40px',
                  }}
                  src={item?.logo}
                  alt=''
                />
                <span className='align-self-center ml-2'>{item?.name}</span>
              </div>
            }
            Right={
              selector ? (
                <CheckboxOption
                  onChange={(e) => {
                    selectUniversity(item?.name)
                  }}
                  defaultState={selected?.includes(item.name)}
                  value={selected?.includes(item.name)}
                />
              ) : (
                <div className='hover-icon'>
                  <IconArrowRight />
                </div>
              )
            }
          />
        )
      })}

      {collegesList?.length > 0 &&
        collegesList?.map((item, index) => {
          return (
            <ListItem
              hasHover
              showBottomLine
              onClick={() => {
                openMyCollegeModal(
                  () => {
                    onAddUniversity()
                  },
                  GetModalUniversityInfo(item, infoToObtain),
                  plan,
                )
              }}
              Left={
                <div className='d-flex flex-row ml-2'>
                  {/* eslint-disable-next-line */}
                  <div className={`d-flex flex-row justify-content-center`} style={{
                      width: selector ? '25px' : '40px',
                      height: selector ? '25px' : '40px',
                    }}
                  >
                    {selector ? (
                      /* eslint-disable */
                      item?.logo ? <img style={{ alignSelf: 'center', width: '25px', height: '25px' }} src={item?.logo} alt={item?.name} /> 
                      : (
                        <div
                          className='d-flex flex-row justify-content-center'
                          style={{
                            width: '25px',
                            height: '25px',
                          }}
                        >
                          <IconBank
                            className='align-self-center'
                            color='gray'
                            size={25}
                          />
                        </div>
                      )
                    ) : (
                      <LogoItem
                        className='ml-4 mr-2 bg-transparent'
                        key={index}
                        tooltipId={item?.collegeId}
                        size={40}
                        early={item?.early}
                        image={item?.logo}
                        status={item?.status}
                        showTolTip
                      />
                      /* eslint-enable */
                    )}
                  </div>
                  <span
                    className='align-self-center ml-4 text-info'
                    // style={{color: '#2C3F78'}}
                  >
                    {item?.name}
                  </span>
                </div>
              }
              Right={
                <div className='hover-icon'>
                  {item?.early === 'action' ? (
                    <EarlyActionBadge />
                  ) : ['decision', 'decision2'].includes(item?.early) ? (
                    <EarlyDecisionBadge edVersion={item?.early || 'decision'} />
                  ) : (
                    <IconArrowRight className='mr-4' />
                  )}
                </div>
              }
            />
          )
        })}

      <ListItem
        hasHover
        showBottomLine
        onClick={() => {
          openUniversityDirectory(() => {
            onAddUniversity()
          }, plan)
        }}
        Left={
          <div className='d-flex flex-row ml-2'>
            <IconAddUniversity size={40} />
            <span className='align-self-center ml-3 text-primary'>
              Add University
            </span>
          </div>
        }
        Right={
          <div className='hover-icon mr-4'>
            <IconArrowRight />
          </div>
        }
      />
    </div>
  )
}

export default Universities
