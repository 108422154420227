import React from 'react'
import useModalState from '../../../../hooks/useModalState'
import {
  IconClose,
  IconInvoice,
  Modal,
  ResumenInvoice,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment'

const PendingPaymentsModal = (props) => {
  const {isOpen, modalData, toggle} = useModalState('profile.pendingPayments')
  const {onChange} = useModalState('payments.viewInvoice')
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const {invoices} = modalData
  return (
    <Modal
      className={`modal-${
        breakWidth === 'SM'
          ? 'botbar'
          : 'sidebar modal-desktop-half modal-colored '
      }`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header pt-4 d-flex flex-row align-items-center justify-content-between'>
          <span className='h2 font-weight-bold'>
            {translate('profile.payments.pendingInvoices')}
          </span>
          <IconClose size={20} onClick={toggle} />
        </div>
      }
    >
      <div className='w-100 d-flex flex-column px-md-4 '>
        <span className='text-muted'>
          {translate('profile.payments.pendingInvoicesDesc')}
        </span>
        <div className='py-5 d-flex flex-column justify-content-between'>
          {invoices && invoices.length
            ? invoices.map((invoice) => {
                return (
                  <ResumenInvoice
                    className='mt-2'
                    key={invoice.id}
                    text={
                      invoice.description
                        ? invoice.description
                        : 'End of ' + moment(invoice.to).format('MMMM')
                    }
                    type='warn'
                    amount={invoice.amount}
                    // amount={invoice.paymentItems.filter((item) => item.draft === false).reduce((sum, current) => sum + current.amount, 0)}
                    icon={<IconInvoice size='20' className='normal' />}
                    onClick={() => onChange(() => ({invoiceId: invoice.id}))}
                  />
                )
              })
            : null}
        </div>
        {/* <Button
          label={translate('misc.continue')}
          className='w-100 text-center'
          check={false}
          color='primary'
          disabled={!!invoices || (invoices && invoices.length === 0)}
        /> */}
      </div>
    </Modal>
  )
}

export default PendingPaymentsModal
