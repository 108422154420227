export default {
  upcoming: 'UPCOMING',
  pendingApproval: 'PENDING APPROVAL',
  inProgress: 'IN PROGRESS',
  lessonsPerWeek: (num) => {
    return `${num} lessons per week`
  },
  totalLessons: (num) => {
    return `${num} lessons`
  },
  weeklySchedule: 'Weekly schedule',
  weeklyLPT: ({num, type, topic}) => ` ${num} ${type} ${topic} lessons`,
  plans: 'Plans',
  calendar: 'Calendar',
  noFound: 'No plans found',
  plantoapprove: 'You have a plan to approve',
}
