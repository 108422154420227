import queryLessonResult from './useLessonResult.js'

const queryScheduleResult = `
    ... on OcurrenceResult {
            hasOccurrences
            occurrencesList {
                educatorUserId
                educatorFirstName
                educatorLastName
                topicId
                serviceId
                from
                to
                lessonName
                recurringId
                ocurrenceId
                collissionKinds
                lessonOriginal {
                    originalLessonId
                    students {
                        studentUserId
                        planId
                        firstName
                        lastName
                        rate
                        attendanceStatus
                        isManualRate
                        ignoreAvailabilty
                        whatsappAlertSent
                        wasLate
                        rating
                        ignorePaymentItem
                    }
                    topic {
                        id
                        name
                    }
                    service {
                        id
                        name
                    }
                }
            }
    }
    ... on ScheduleError {
        errorKey
        message
    }
    ... on ScheduleSuccess {
        newLessons {
            ${queryLessonResult}
        }
    }
    `

export default queryScheduleResult
