import React from 'react'
import {
  Button,
  useWindowDimensions,
  IconPrepared,
  IconAttendance,
  IconSupport,
  IconRespond,
} from '@Knowledge-OTP/znk-ui-components'
import {useHistory} from 'react-router'
import useTranslation from '../../../../i18n/useTranslation'
import useOnboardingRooms from '../../../../common/OnboardingRooms'

const Congratulations = (props) => {
  const {counselingOnboarding} = props
  const history = useHistory()
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const {leaveSession} = useOnboardingRooms()
  return (
    <>
      {/* <div
            className='d-flex'
            style={{
              position: 'absolute',
              left: '15px',
              top: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              history.push('/home')
            }}
          >
            <IconBrand size={50} color='#35425B' />
            {breakWidth !== 'SM' && (
              <IconLogo className='ml-4' size={50} color='#35425B' />
            )}
          </div> */}
      <div className='m-0 p-0 d-flex flex-column w-100 h-100 align-items-center justify-content-center text-title'>
        <div
          className='m-0 p-0 d-flex flex-column'
          style={breakWidth !== 'SM' ? {width: '32%'} : {width: '90%'}}
        >
          <div className='d-flex mt-5'>
            <h2 className='htext mt-7'>
              {`Hey ${counselingOnboarding?.studentUser?.firstName},`}
              <br />
              {translate('counselingOnboarding.success.title')}
            </h2>
          </div>
          <span>{translate('counselingOnboarding.success.info')}</span>
          <div className='mt-3'>
            <div className='d-flex my-2'>
              <IconAttendance
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>{translate('counselingOnboarding.success.effortTitle')}</b>
                </span>
                <br />
                <span>
                  {translate('counselingOnboarding.success.effortText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconPrepared
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 46 : 46}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>{translate('counselingOnboarding.success.taskTitle')}</b>
                </span>
                <br />
                <span>
                  {translate('counselingOnboarding.success.taskText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconRespond
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>
                    {translate(
                      'counselingOnboarding.success.communicationTitle',
                    )}
                  </b>
                </span>
                <br />
                <span>
                  {translate('counselingOnboarding.success.communicationText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconSupport
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>
                    {translate('counselingOnboarding.success.supportTitle')}
                  </b>
                </span>
                <br />
                <span>
                  {translate('counselingOnboarding.success.supportText')}
                </span>
              </div>
            </div>
          </div>
          <Button
            className='mb-6 mt-4'
            label={translate('counselingOnboarding.success.goToLearn')}
            check
            color='primary'
            style={{border: 'none', width: '70%'}}
            onClick={() => {
              const planId =
                counselingOnboarding?.myPlan?.id ||
                counselingOnboarding?.myPlan?.planId
              leaveSession(planId)
              history.push('/learn')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Congratulations
