import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Modal,
  IconClose,
  Button,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import {useMutation, gql} from '@apollo/client'
import {pick, isEmpty} from 'lodash'
import * as moment from 'moment'
import validateForm from './validation'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../store/modal/const'
import PersonalForm from './Form'
import {showAlert} from '../../../../common/Alert/util'
import queryUserHashResult from '../../../../Model/QueryResult/useUserHashResult'

const PersonalModalForm = (props) => {
  const isModalOpen = useSelector((state) => state.modal.profile.personal)
  const dispatch = useDispatch()
  const {refetch: refetchUser} = useSelector((state) => state.user)
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const [validationErrors, setValidationErrors] = useState({})
  const {phone, birthdate} = props
  const [form, setForm] = useState(props)
  const parsedForm = useMemo(() => {
    return {
      phoneDialCode: phone ? phone.split(' ')[0] : '+56',
      phoneNumber: phone ? phone.split(' ')[1] : '',
      birthDay: birthdate ? moment(birthdate).date() : 1,
      birthMonth: birthdate ? moment(birthdate).month() : 0,
      birthYear: birthdate ? moment(birthdate).year() : moment().year(),
    }
  }, [phone, birthdate])
  const resetFormValues = useCallback(() => {
    setForm((form) => ({
      ...form,
      ...parsedForm,
    }))
  }, [setForm, parsedForm])
  useEffect(() => {
    resetFormValues()
  }, [resetFormValues])
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.personal'})
    setValidationErrors({})
    resetFormValues()
  }
  const [updInfo, {loading}] = useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          ${queryUserHashResult}
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetchUser()
      },
    },
  )
  const submit = () => {
    const errors = validateForm(form)
    if (!isEmpty(errors)) {
      setValidationErrors(errors)
      return
    }
    setValidationErrors({})
    const updData = pick(form, [
      'firstName',
      'lastName',
      'countryId',
      'address',
      'city',
      'gender',
      'state',
      'zipCode',
      'spokenLanguages',
    ])
    updData.phone = `${form.phoneDialCode} ${form.phoneNumber}`
    updData.birthdate = moment()
      .year(form.birthYear)
      .month(form.birthMonth)
      .date(form.birthDay)
      .toISOString()
    updInfo({variables: {input: updData, id: form.id}})
      .then(() => {
        // actualiza cache
        const storage = global.localStorage.getItem('loggedUser')
        const parsedInfo = JSON.parse(storage) ?? {}
        global.localStorage.setItem(
          'loggedUser',
          JSON.stringify({
            ...parsedInfo,
            firstName: form.firstName,
            lastName: form.lastName,
          }),
        )

        // success
        showAlert(
          {
            text: translate('profile.editProfile.successAlert'),
            status: 'success',
          },
          dispatch,
        )
        toggle()
      })
      .catch(() => {
        // error
        showAlert(
          {
            text: translate('profile.editProfile.errorAlert'),
            status: 'error',
          },
          dispatch,
        )
      })
  }
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isModalOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4 pl-0'
        >
          <span className='d-flex w-100 flex-row justify-content-between'>
            <h2 className='font-weight-bold'>
              {translate('profile.personalInfo')}
            </h2>
          </span>
        </ModalHeader>
      }
    >
      <PersonalForm
        value={form}
        onChange={setForm}
        validationErrors={validationErrors}
      />
      <Button
        label={translate('misc.saveChanges')}
        color='primary'
        check
        size='medium'
        onClick={submit}
        disabled={loading}
        activity={loading}
        className='w-100 mt-5 mb-3'
      />
    </Modal>
  )
}

PersonalModalForm.propTypes = {}

export default PersonalModalForm
