export default {
  welcome: 'Bienvenido a Zinkerz',
  follow: 'Por favor, siga los siguientes pasos',
  followSub: ' para establecer su primer plan de estudio.',
  step: 'Paso',
  approve: 'Aprueba tu plan de estudio',
  confirm: 'Confirma tu horario',
  complete: 'Completa tu perfil',
  goto: 'Ir a aprobar plan',
  continue: 'Continuar',
}
