import React, {useState, useMemo} from 'react'
import moment from 'moment-timezone'
import {
  CalendarNew,
  Action,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import LessonsWeek from './LessonsWeek/index'
import {Col} from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'
import RateLesson from './Modal/RateLesson'
import {useLocation} from 'react-router-dom'
import CalendarViewStudent from '../../../Model/CalendarEvent/List/CalendarViewStudent/index'
import CopyLessonModal from './Modal/CopyLesson'
import {useSelector, useDispatch} from 'react-redux'
import ReschedulePendingLesson from './Modal/ReschedulePendingLesson'
import UnauthorizedReschedule from './Modal/ReschedulePendingLesson/Unauthorized'
import InfoModalLesson from './Modal/InfoModal/index'
import useModalState from '../../../hooks/useModalState'
import useParseLessonFromQuery from './useParseLessonFromQuery'
import OrientationLessonModal from '../../Workspace/Student/ViewTask/Components/Modals/OrientationLessonModal'
import PendingLessonNotification from '../../CalendarStudent/PendingLessonNotification'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const LessonsView = (props) => {
  const {translate} = useTranslation()
  const {timezone} = useSelector((state) => ({
    timezone: state.user.info.timezone,
  }))
  const dispatch = useDispatch()
  const {isActive} = useSelector((state) => state.user.info)
  const reduxData = useSelector(
    (state) => state.modal?.student?.orientationLessonModal,
  )

  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'student.orientationLessonModal',
      data: {
        isOpen: !reduxData?.isOpen,
        idLesson: reduxData?.idLesson,
      },
    })
  }
  const {onChange} = useModalState('program.newManualLesson')
  const openNewLessonModal = () => {
    onChange(() => ({
      serviceId: null,
      topicId: null,
      educator: null,
      dateTime: null,
      stage: 0,
      refetch: refetchLessons,
    }))
  }
  const [now, setNow] = useState(moment().utc().startOf('isoWeek'))
  const today = useMemo(() => {
    setNow(now.clone().utc() || now)
    return moment().utc()
    // eslint-disable-next-line
  }, [timezone, setNow])
  const weekOfMonth = useMemo(() => {
    return now.clone().weeks() - now.clone().startOf('month').weeks() + 1
  }, [now])

  const {startWeek, endWeek, startMonth, endMonth} = useMemo(() => {
    return {
      startWeek: now?.clone()?.startOf('isoWeek').toISOString(),
      endWeek: now?.clone()?.endOf('isoWeek').toISOString(),
      startMonth: now
        ?.clone()
        ?.startOf('month')
        .startOf('isoWeek')
        .toISOString(),
      endMonth: now?.clone()?.endOf('month').endOf('isoWeek').toISOString(),
    }
  }, [now])
  const {
    data: lessons,
    loading,
    error,
    refetch: refetchLessons,
  } = CalendarViewStudent(
    {
      educatorIds: [],
      from: startMonth,
      to: endMonth,
    },
    'no-cache',
  )

  let renderRate = false
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  if (params) {
    if (
      params.has('roomId') &&
      params.has('userId') &&
      params.has('userName') &&
      params.has('isTeacher')
    ) {
      renderRate = true
    }
  }

  // const {data: pendingLessons, refetch: refetchPendingLessons} = useQuery(
  //  gql`
  //    query($status: [LessonStatus!]!, $serviceIds: [ID!]) {
  //      myLessons(status: $status, serviceIds: $serviceIds, limit: 0) {
  //        docs {
  //          id
  //          status
  //        }
  //      }
  //    }
  //  `,
  //  {
  //    variables: {
  //      status: ['pending'],
  //    },
  //  },
  // )

  // obtiene upcommingLessonId
  const {studentInfo} = useSelector((state) => state.user.info)
  const {upcomingLesson} = studentInfo
  const {id: upcomingLessonId} = upcomingLesson ?? {}

  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find((serv) => serv.isOrientation)

  // lecciones del mes (para calendario)
  const lessonsArrayCalendar = useParseLessonFromQuery(
    lessons,
    upcomingLessonId,
    orientation?.id,
  )

  // lecciones de la semana
  const lessonsArray = useMemo(() => {
    return lessonsArrayCalendar.filter(
      ({startDate}) =>
        moment(startDate) >= moment(startWeek) &&
        moment(startDate) < moment(endWeek),
    )
  }, [lessonsArrayCalendar, startWeek, endWeek])
  return (
    <div className='container-fluid px-0'>
      {renderRate && (
        <RateLesson open={renderRate} roomId={params.get('roomId')} />
      )}
      <PendingLessonNotification refetch={refetchLessons} className='mb-3' />
      <CalendarNew
        data={lessonsArrayCalendar}
        today={today}
        moment={now}
        onToday={() => {
          setNow(moment().startOf('isoWeek'))
        }}
        setMoment={(mon, open) => {
          // si la fecha es posterior a la actual
          if (mon > now) {
            // avanza
            setNow(mon)
            // if (open) setNow(now?.clone()?.add(1, 'month').startOf('isoWeek'))
            // else setNow(now?.clone()?.add(1, 'week').startOf('isoWeek'))
          } else {
            // retrocede
            setNow(mon)
            // if (open)
            //  setNow(now?.clone()?.subtract(1, 'month').startOf('isoWeek'))
            // else setNow(now?.clone()?.subtract(1, 'week').startOf('isoWeek'))
          }
        }}
        viewMode='scrollMonth'
        focusWeek={weekOfMonth}
      />
      <LessonsWeek
        refetchLessons={() => {
          refetchLessons()
        }}
        lessonsData={lessonsArray}
        loading={loading}
        error={error}
      />
      <hr className='mt-2' />
      <Action
        color='#F2F2FF'
        textColor='#7079FF'
        border=''
        style={{marginTop: 10}}
        labelLeft={translate('lessons.nextweek')}
        labelRight=''
        action='primary'
        onClick={() => {
          setNow((now) => now.clone().add(7, 'd'))
        }}
      />
      <div className='row my-4 mx-1'>
        <Col
          md={12}
          style={{padding: '10px', paddingLeft: '20px', paddingRight: '30%'}}
        >
          <h2 className='font-weight-bold'>
            {translate('lessons.footermsg1')}
          </h2>
          <p className='font-weight-lighter' style={{marginBottom: '5px'}}>
            <small>
              <span>
                {translate('lessons.footermsg2')}
                <br />
                {translate('lessons.footermsg3')}
              </span>
            </small>
          </p>
          <ToolTipInfo
            innerElement={translate('lessons.footermsg4disabled')}
            disable={isActive}
          >
            <button
              className={`btn ${isActive ? 'btn-link' : 'text-gray'} p-0`}
              style={
                isActive
                  ? {textDecorationLine: 'underline'}
                  : {cursor: 'not-allowed'}
              }
              onClick={() => {
                if (isActive) openNewLessonModal()
              }}
            >
              {translate('lessons.footermsg4')}
            </button>
          </ToolTipInfo>
        </Col>
      </div>
      <ReschedulePendingLesson />
      <UnauthorizedReschedule />
      <CopyLessonModal />
      <InfoModalLesson
        refetchLessons={() => {
          refetchLessons()
          // refetchPendingLessons()
        }}
      />
      <OrientationLessonModal
        role='student'
        dataLesson={
          lessonsArray?.filter((lesson) => lesson.id === reduxData?.idLesson)[0]
        }
        onCancel={() => {}}
        isOpen={reduxData?.isOpen}
        toggle={toggle}
      />
    </div>
  )
}

export default LessonsView
