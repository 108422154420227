import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  IconFilter,
  DropDownNew,
  DropDownItem,
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import TypesFilter from './Types'
import {
  FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY,
  FILTER_DOCS_AND_EXAMS_BY_TYPE,
} from '../../../../../store/user/const'

const DocsAndExamsFilter = () => {
  const [collegesList /** , setCollegesList */] = useState(
    useSelector((state) => state.user.info.studentInfo.universities),
  )
  const {translate} = useTranslation()
  const baseTypes = ['Document', 'Exam']
  // objeto con filtros aplicados a listado de planes
  const [filters, setFilters] = useState({
    limit: 10,
    previous: null,
    next: null,
    search: '',
    kind: ['counceling'],
    type: baseTypes,
    finishSoon: false,
    lastApproved: false,
    previouslySent: false,
    shortRecenlySent: false,
    allPlans: true,
  })
  const dispatch = useDispatch()

  return (
    <div className='d-flex flex-row my-4 justify-content-between'>
      <div className='d-flex flex-row m-0 p-0'>
        <TypesFilter
          types={baseTypes}
          typesFilters={filters.type}
          setTypesFilters={(newTypes) => {
            setFilters({...filters, type: newTypes})
            dispatch({
              type: FILTER_DOCS_AND_EXAMS_BY_TYPE,
              data: newTypes,
            })
          }}
        />
        <DropDownNew
          className=''
          options={[
            {
              component: collegesList.map((college) => (
                <DropDownItem>
                  <span
                    onClick={() =>
                      dispatch({
                        type: FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY,
                        data: college.id,
                      })
                    }
                    style={{color: '#5a687c'}}
                  >
                    {college.name}
                  </span>
                </DropDownItem>
              )),
            },
            {
              component: (
                <DropDownItem>
                  <span
                    onClick={() =>
                      dispatch({
                        type: FILTER_DOCS_AND_EXAMS_BY_UNIVERSITY,
                        data: '',
                      })
                    }
                    style={{color: '#5a687c'}}
                  >
                    {translate('counseling.allUniversities')}
                  </span>
                </DropDownItem>
              ),
            },
          ]}
          direction='bottomLeft'
        >
          <Badge className='mx-1 filters' pill color='option'>
            <label style={{cursor: 'pointer'}} className='m-0 p-0'>
              <IconFilter className='mr-2' size={15} />
              {translate('counseling.university')}
            </label>
          </Badge>
        </DropDownNew>
      </div>
    </div>
  )
}
export default DocsAndExamsFilter
