import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import React, {useState} from 'react'
import useLinkLesson from '../../../CalendarLessons/modal/InfoLesson/useLinkLesson'
import CheckInLesson from '../../../../Model/Lessons/CheckInLesson'

import {
  Activity,
  Countdown,
  IconArrowRight,
  IconRepeat,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import BackupLinkButton from './BackUpLinkButton'
import {hasHttp} from '../../../CalendarLessons/modal/utils'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import moment from 'moment'
import './index.css'

const CheckInEducator = (props) => {
  const {dataLesson, educator /*, setDataLessonToRedux */} = props
  const reduxData = useSelector((state) => state.modal.calendar.infoLessonModal)

  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const penalizationInfo = dataLesson?.penalizationInfo
  const getvclink = useLinkLesson()
  // eslint-disable-next-line
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const {submit: checkIn, loading: loadCheckIn} = CheckInLesson(
    ({data}) => {
      // setDataLessonToRedux(data?.checkInLesson || dataLesson)
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.infoLessonModal',
        data: {
          ...reduxData,
          dataLesson: data?.checkInLesson || dataLesson,
          modalType: 'chat',
        },
      })
    },
    () => {
      alert('error')
    },
  )

  const isCheckIn = educator?.attendanceStatus === 'attended'

  return (
    <div
      className='m-0 p-0 d-flex flex-column mt-2'
      style={{position: 'sticky', bottom: '0px'}}
    >
      <div className='m-0 p-0 d-flex flex-column'>
        {penalizationInfo && (
          <div className='d-flex flex-row mt-1 mb-1'>
            <span className='text-error'>
              {translate('modal.penalizelesson.penalty')}
            </span>
            <span className='text-error'>{`-$${
              penalizationInfo?.amount && penalizationInfo.amount.toFixed(2)
            }`}</span>
          </div>
        )}
        <div className='d-flex flex-row mb-3'>
          {isCheckIn ? (
            <>
              <BackupLinkButton
                className='w-50 mr-1'
                educatorId={
                  dataLesson?.substitute?.educatorUserId ||
                  dataLesson?.educator?.educatorUserId
                }
              />
              <Button
                style={
                  dataLesson.location.type === 'virtualClassroom'
                    ? {
                        borderRadius: '16px 0px 0px 16px',
                        backgroundColor: '#696CFF',
                        width: '40%',
                      }
                    : {backgroundColor: '#696CFF', width: '50%'}
                }
                onClick={() => {
                  // si location es VCR es launchURL, sino es location.linkStudent!
                  setGoToClassLoad(true)
                  getvclink(dataLesson?.id, null)
                    .then(
                      ({launchUrl, location}) => {
                        if (
                          location.type === 'virtualClassroom' ||
                          location.type === 'zinkerzPlatform'
                        )
                          window.open(hasHttp(launchUrl), '_blank')
                        else
                          window.open(hasHttp(location.linkStudent), '_blank')
                        setGoToClassLoad(false)
                      },
                      (error) => {
                        console.error(error)
                        // setVcLinkError(true)
                        setGoToClassLoad(false)
                      },
                    )
                    .catch((error) => {
                      console.error(error)
                      // setVcLinkError(true)
                      setGoToClassLoad(false)
                    })
                }}
                label={translate('modal.infolesson.gotoclassroom')}
              />
              {dataLesson.location.type === 'virtualClassroom' && (
                <Button
                  style={{
                    backgroundColor: '#696CFF',
                    borderRadius: '0px 16px 16px 0px',
                    marginLeft: '1px',
                    width: '10%',
                  }}
                  size='large'
                  className='d-flex flex-row justify-content-center align-items-center'
                  onClick={() => {
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'calendar.setBraincertToLesson',
                      data: {
                        isOpen: true,
                        lessonId: dataLesson.id,
                      },
                    })
                  }}
                  label={<IconRepeat size={15} />}
                />
              )}
            </>
          ) : (
            <Button
              style={{backgroundColor: '#72809d'}}
              className='w-100 my-0'
              size='large'
              onClick={() => {
                checkIn({lessonId: dataLesson?.id || ''})
              }}
              label={
                loadCheckIn ? (
                  <div className='w-100 d-flex flex-row justify-content-center'>
                    <Activity inverse />
                  </div>
                ) : (
                  <div className='d-flex flex-row justify-content-between'>
                    <span>
                      {translate('virtualChat.checkInStudent.checkInChat')}
                    </span>
                    <IconArrowRight />
                  </div>
                )
              }
            />
          )}
        </div>
        <div
          className='zkn-btn znk-size-small w-100 mb-1 btn'
          style={{backgroundColor: '#F0F1FF'}}
        >
          <div className='d-flex flex-row justify-content-between'>
            <span className='h45 text-primary'>
              {translate('modal.infolesson.startat')}
            </span>
            <Countdown
              endTime={
                dataLesson?.startDate ? moment(dataLesson?.startDate) : moment()
              }
              iconColor='#696CFF'
              timeOverLabel={translate('modal.infolesson.timeisover')}
              spanTimeClassName='h45 text-primary'
              spanSecondsClassName='h45 text-primary'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckInEducator
