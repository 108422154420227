import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import useGetUserDocsAndExams from '../../../../Task/Model/useGetUserDocsAndExams'
import taskTypes from '../../../Utils/taskTypes'
import DocsItemComponent from './DocItem'
import ExamItemComponent from './ExamItem'
import useRegisterScoresModal from '../../RegisterScoresModal/useRegisterScoresModal'
import {typesOnDocs} from '../../../Utils/taskTypesOnDocs'

const DocsAndExamsComponent = () => {
  const [docsAndExamsData, setDocsAndExamsData] = useState([])
  const {open: openRegisterScores} = useRegisterScoresModal()
  const universityToFilter = useSelector(
    (state) => state.user.info.studentInfo?.docsAndExamsFilter || '',
  )
  const typeToFilter = useSelector(
    (state) => state.user.info.studentInfo?.filterByType || '',
  )

  // eslint-disable-next-line
  const { loading: loadingDocs, data: dataDocs, refetch: refetchDocs } = useGetUserDocsAndExams({
  })

  useEffect(() => {
    if (dataDocs && dataDocs.length > 0) {
      // Filtro los docs que tengan showInDocs en true.
      setDocsAndExamsData(
        dataDocs?.filter(
          (x) =>
            (typesOnDocs.includes(x.type) &&
              x.showInDocs &&
              x.resolveTask?.uploadFiles !== null) ||
            x.type === taskTypes?.scoreCollection,
        ),
      )
    }
  }, [dataDocs])

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div className='d-flex flex-column justify-content-between mb-1'>
        {docsAndExamsData?.length > 0 &&
          docsAndExamsData
            .filter((x) => {
              if (universityToFilter === '') return docsAndExamsData
              const arrayFilterByUniv = x?.universities[0]?.id.includes(
                universityToFilter,
              )
              return arrayFilterByUniv
            })
            .filter((y) => {
              if (
                (typeToFilter.includes('Exam') &&
                  typeToFilter.includes('Document')) ||
                typeToFilter.includes('')
              )
                return docsAndExamsData
              const filterDocs = typeToFilter.includes('Exam')
                ? [taskTypes.scoreCollection] // Si es examen, filtro por scoreCollection
                : [taskTypes.uploadFile, taskTypes.link, taskTypes.completion] // Si es documento, filtro por uploadFile, link y completion
              // const arrayToShow = y?.type.includes(filterDocs)
              const arrayToShow = filterDocs.includes(y.type)
              return arrayToShow
            })
            // eslint-disable-next-line
            .map((x) => {
                if (x.type === taskTypes.scoreCollection) {
                  return (
                    <div
                      className='d-flex justify-content-between mb-5'
                      onClick={() => {
                        openRegisterScores(() => {}, x)
                      }}
                    >
                      <ExamItemComponent examData={x} />
                    </div>
                  )
                } else {
                  return (
                    /* eslint-disable */
                  <div className='d-flex justify-content-between mb-5'>
                    <a
                      className='w-100'
                      href={x?.resolveTask?.uploadFiles[0]?.url}
                      target="_blank">
                      <DocsItemComponent docData={x} />
                    </a>
                  </div>
                  /* eslint-enable */
                  )
                }
              },
              /* eslint-enable */
            )}
      </div>
    </div>
  )
}

export default DocsAndExamsComponent
