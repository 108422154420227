import {useCallback, useState} from 'react'
import {gql, useMutation} from '@apollo/client'
import validate from './validation'
import {useSelector} from 'react-redux'
export default (onSuccess, onError) => {
  const {refetch: refetchUser} = useSelector((state) => state.user)
  const [mutation, {data, error, loading}] = useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          id
          timezone
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetchUser()
        // cache.writeQuery({
        //  query: meQuery,
        //  data: {me: data.updateUser},
        // })
      },
    },
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback(
    (obj) => {
      const errors = validate(obj)
      if (errors && Object.keys(errors).length) {
        setValidationErrors(errors)
        onError('validation Error')
        return
      }
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          setValidationErrors({general: 'misc.apiError'})
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    data: data ? data.updateUser : null,
    error,
    validationErrors,
    submit,
  }
}
