import React, {Suspense, useEffect, useState} from 'react'
import {
  Navbar,
  useWindowDimensions,
  IconProgram,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../i18n/useTranslation'
import {navItems} from './navItems'
import {Route, useHistory, Switch, Redirect, useLocation} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import {Row} from 'reactstrap'
import LessonsView from './Lessons'
import PlansView from './Plans'
import TimelineView from './Timeline'
import ChangeTimezone from './../Profile/Info/ProfileActions/ChangeTimezone'
import beanWar from '../../assets/beanWar.svg'
// import OptionsButton from './Lessons/Modal/Options'
import ChangeTimezoneModal from '../CalendarLessons/Components/ChangeTimezoneModal'
import CollapseMsg from './CollapseMsg'

const CalendarView = (props) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const {timezone, id} = useSelector((state) => state.user.info)
  const data = {from: 'calendar', timezone, id, ...props}
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const history = useHistory()

  const dispatch = useDispatch()
  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const lessonId = searchParams.get('lessonId')
  const isOrientation = searchParams.get('isOrientation')
  useEffect(() => {
    if (lessonId) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: `student.${
          isOrientation ? 'orientationLessonModal' : 'infoLessonModal'
        }`,
        data: {
          isOpen: true,
          idLesson: lessonId,
        },
      })
    }
    // eslint-disable-next-line
  }, [lessonId])

  const style = {
    backgroundImage: `url(${beanWar}), url(${beanWar}), url(${beanWar})`,
    backgroundPosition: '115% -50%, 30% -15%, 70% 15%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px, 200px, 50px',
    maxHeight: '100vh',
    overflowY: 'scroll',
  }

  const showDropDown = () => {
    setShowDropdown(true)
    setTimeout(function () {
      setShowDropdown(false)
    }, 60000)
  }

  return (
    <div className='d-flex'>
      <div
        className='col-12 col-md-6 col-lg-5 col-xl-4 p-0 px-3 px-md-4 pt-md-5 px-lg-5 px-xl-6'
        style={{
          // overflowY: !showDropdown ? 'auto' : 'visible',
          overflowX: !showDropdown ? 'hidden' : 'visible',
          maxHeight: '100vh',
          overflowY: 'scroll',
        }}
      >
        <Row className='w-100 m-0 px-0'>
          {breakWidth === 'SM' && (
            <Navbar
              light
              expand='xs'
              className='d-flex brandless tabTexts container-fluid m-0 p-0'
              navClass='d-flex align-items-center'
              history={history}
              FootBrand={
                <>
                  <div style={{flex: '1'}} />
                  <ChangeTimezone {...data} />
                </>
              }
              items={navItems}
            />
          )}

          <Switch>
            <Route
              exact
              path='/calendar/lessons'
              render={() => (
                <div className='w-100 m-0'>
                  <Suspense fallback={() => <Skeleton count={5} />}>
                    {breakWidth !== 'SM' ? (
                      <h3 className='font-weight-bold pb-3 d-flex'>
                        {translate('program.calendar.calendar')}
                        <div style={{flex: '1'}} />
                        <div className='m-0 p-0 d-flex flex-row justify-content-center align-items-center'>
                          <span onClick={() => showDropDown()}>
                            <ChangeTimezone {...data} />
                          </span>
                          <div
                            className='ml-3 hover-icon'
                            onClick={() => {
                              history.push('calendar-view')
                              // setCalendarView(true)
                            }}
                          >
                            <IconProgram size={15} />
                          </div>
                        </div>
                      </h3>
                    ) : null}
                    <LessonsView />
                  </Suspense>
                </div>
              )}
            />
            <Route
              exact
              path='/calendar/timeline'
              render={() =>
                breakWidth !== 'SM' ? (
                  <Redirect to={`/calendar/lessons${location?.search}`} />
                ) : (
                  <TimelineView className='w-100' />
                )
              }
            />
            <Route
              exact
              path='/calendar/plans'
              render={() =>
                breakWidth !== 'SM' ? (
                  <Redirect to={`/calendar/lessons${location?.search}`} />
                ) : (
                  <PlansView />
                )
              }
            />
            <Route exact path='/calendar/calendar-view' render={() => null} />
            <Route
              render={() => (
                <Redirect to={`/calendar/lessons${location?.search}`} />
              )}
            />
          </Switch>
        </Row>
      </div>
      {breakWidth !== 'SM' && (
        <>
          <div
            className='col-md-6 col-lg-7 col-xl-8 px-md-5 px-lg-6 px-xl-7 subBody'
            style={style}
          >
            <CollapseMsg userId={id} />
            <div className='d-md-block d-lg-flex pt-md-5 pb-md-9 justify-content-between'>
              <div
                className={`m-0 px-0 pb-3 order-1 ${
                  breakWidth === 'MD' ? '' : 'w-50'
                }`}
              >
                <h3 className='font-weight-bold mb-3'>
                  {translate('program.plan.plans')}
                </h3>
                <PlansView />
              </div>
              <div
                className={`m-0 px-0 pb-3 order-0 ${
                  breakWidth === 'MD' ? '' : 'w-50'
                }`}
              >
                {/* <h3 className='font-weight-bold mb-3'>Timeline</h3> */}
                <TimelineView className='p-0 m-0 my-2 w-100' showTitle />
              </div>
            </div>
          </div>
          {/* <OptionsButton /> */}
        </>
      )}
      <ChangeTimezoneModal />
    </div>
  )
}

CalendarView.propTypes = {}
export default CalendarView
