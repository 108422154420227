import React, {useMemo} from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SetLessonToPending from '../../../../Model/Lessons/SetLessonToPending'
import moment from 'moment-timezone'
import {showAlert} from '../../../../common/Alert/util'

const PostponeLesson = () => {
  const dispatch = useDispatch()
  const reduxPostpone = useSelector(
    (state) => state.modal.calendar.postponeLesson,
  )
  const {isOpen, lessonId, isOrientation} = reduxPostpone
  const {translate, setVariables} = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.postponeLesson',
      data: {
        ...reduxPostpone,
        isOpen: !isOpen,
      },
    })
  }

  const modalData = useSelector((state) => state.modal.calendar.infoLessonModal)
  const modalDataOrientation = useSelector(
    (state) => state.modal.calendar.orientationLessonModal,
  )

  const [timeToStart, penaltyAmount] = useMemo(() => {
    const dataLesson = modalData?.dataLesson
    if (dataLesson) {
      const educatorRate =
        dataLesson?.substitute?.rate || dataLesson?.educator?.rate
      const penaltyAmount = educatorRate ? educatorRate / 2 : 5
      const timeToStart = moment
        .duration(moment(dataLesson?.startDate).diff(moment()))
        .asHours()
      return [timeToStart, penaltyAmount]
    }
    return []
  }, [modalData])

  const {submit: setLessonToPendingMutation, loading} = SetLessonToPending(
    () => {
      toggle()
      if (isOrientation) {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'calendar.orientationLessonModal',
          data: {
            ...modalDataOrientation,
            isOpen: false,
          },
        })
      } else {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'calendar.infoLessonModal',
          data: {
            ...modalData,
            isOpen: false,
          },
        })
      }
    },
    (e) => {
      showAlert({text: e.message, status: 'error'}, dispatch)
      toggle()
    },
  )

  return (
    <>
      <ConfirmModal
        className=''
        loading={loading}
        classNameActionDiv='flex-column'
        classNameConfirm='text-right text-error'
        classNameCancel='mx-3 text-right'
        isOpen={isOpen}
        toggle={toggle}
        title={translate(
          `modal.postponelesson.${timeToStart <= 24 ? 'start24' : ''}title`,
        )}
        body={
          <div className='w-100'>
            <label>
              {setVariables(
                translate(
                  `modal.postponelesson.${
                    timeToStart <= 24 ? 'start24' : ''
                  }body`,
                ),
                {PENALTY: `$${penaltyAmount.toFixed(2)}`},
              )}
            </label>
          </div>
        }
        alignRight
        confirmAction={() => {
          if (isOrientation) setLessonToPendingMutation({lessonId})
          else setLessonToPendingMutation({lessonId})
        }}
        cancelAction={toggle}
        confirmText={translate(
          `modal.postponelesson.${
            timeToStart <= 24 ? 'start24' : ''
          }postponethis`,
        )}
        cancelText={translate('modal.postponelesson.cancel')}
      />
    </>
  )
}

export default PostponeLesson
