import {gql, useMutation} from '@apollo/client'
import meQuery from '../../../queries/me'
export default () => {
  const [updUser, {loading}] = useMutation(
    gql`
      mutation(
        $countryId: ID!
        $gender: Gender!
        $email: String!
        $firstName: String!
        $lastName: String!
        $phone: String!
        $birthdate: String!
        $spokenLanguages: String!
        $address: String!
        $city: String!
        $state: String!
        $zipCode: String!
        $studentType: StudentType
        $parent: ParentOboardingInput
        $academicInfo: StudentboardingInput!
      ) {
        updateUserFromOnboarding(
          input: {
            countryId: $countryId
            gender: $gender
            email: $email
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            birthdate: $birthdate
            spokenLanguages: $spokenLanguages
            address: $address
            city: $city
            state: $state
            zipCode: $zipCode
            studentType: $studentType
            parent: $parent
            academicInfo: $academicInfo
          }
        ) {
          id
          firstName
          lastName
        }
      }
    `,
    {
      update: (cache, {data}) => {
        cache.writeQuery({
          query: meQuery,
          data: {me: data.updateUser},
        })
      },
    },
  )
  return {updUser, loading}
}
