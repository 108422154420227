export default {
  title: 'To approve your plan start scheduling your lessons',
  from: 'from',
  to: 'to',
  monthlyFees: (num) => {
    if (num > 1) {
      return `${num} monthly fees`
    } else {
      return `${num} monthly fee`
    }
  },
  monthlyGroupFees: (num) => {
    if (num > 1) {
      return `${num} monthly group fees`
    } else {
      return `${num} monthly group fee`
    }
  },
  rateLesson: ({num, rate}) => {
    if (num > 1) {
      return `${num} lessons at $${rate} each`
    } else {
      return `${num} lesson at $${rate}`
    }
  },
  rateLessonGroup: ({num, rate}) => {
    if (num > 1) {
      return `${num} group lessons at $${rate} each`
    } else {
      return `${num} group lesson at $${rate}`
    }
  },
}
