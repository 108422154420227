import React, {useState, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  useWindowDimensions,
  ListItem,
  IconCountry,
  IconArrowRight,
  IconClose,
  Modal,
  AvailabilityCalendarRecurring,
  CheckboxOption,
  Button,
  Input,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../i18n/useTranslation'
import ChangeTimezoneModal from '../../ChangeTimezoneModal'
import SetMyRecurrentAvailability from '../../../../../../Model/CalendarEvent/SetMyRecurrentAvailability'
import {useQuery} from '@apollo/client'
import me from '../../../../../../Model/Users/Me'
import {showAlert} from '../../../../../../common/Alert/util'

const SetAvailableRecurring = () => {
  const isOpen = useSelector((state) => state.modal.profile.availableRecurring)
  const {timezone} = useSelector((state) => state.user.info)
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    // reinicio
    setSelectedBlocks([])
    setUnavailableBlocks([])
    refetch()
    setNow(moment().toISOString())
    dispatch({
      type: 'TOGGLE_MODAL_ACTION',
      payload: 'profile.availableRecurring',
    })
  }
  const {breakWidth} = useWindowDimensions()
  const [now, setNow] = useState(moment().toISOString())

  const {data: meData, loading: loadUser, refetch} = useQuery(me, {
    fetchPolicy: 'network-only',
  })
  // useEffect(() => {
  //   if (isOpen) getMe()
  //   // eslint-disable-next-line
  // }, [isOpen])
  const availableBlocks = useMemo(() => {
    if (meData?.me?.educatorInfo?.availabilityBlocks)
      return meData.me.educatorInfo.availabilityBlocks
    return []
  }, [meData])
  const parseAvailable = (lessons) => {
    /**
        {
            day: Float! // 0: Domingo, Sabado : 6 
            hour: Float!
        }
         * **/
    // parsea el arreglo de availabilitys a un arreglo de
    // objetos con llaves startDate y endDate
    const arr = lessons.map(({day, hour}) => {
      const momento = moment()
        .utc()
        .startOf('isoWeek')
        .add(day, 'days')
        .hour(hour)
        .set({minutes: 0, seconds: 0, milliseconds: 0})
      return {
        startDate: momento.tz(timezone).toISOString(),
        endDate: momento.add(1, 'hour').tz(timezone).toISOString(),
        __typename: 'EducatorAvailability',
      }
    })
    return arr
  }
  const {
    submit: setRecurringAvailability,
    loading: loadMutation,
  } = SetMyRecurrentAvailability(
    () => {
      // alert("success")
      setSelectedBlocks([])
      setUnavailableBlocks([])
      refetch()

      showAlert(
        {
          text: startDate
            ? translate(
                `profile.actions.availability.recurringSuccessAlertPartial`,
              )
            : // 'Recurring availability has been updated partialy, please check the user temporary availability.' :
              translate(`profile.actions.availability.recurringSuccessAlert`),
          // 'Recurring availability has been updated',
          status: 'success',
        },
        dispatch,
      )

      setApplyParcial(false)
      setStartDate(null)
    },
    () => {
      showAlert(
        {
          text: translate('profile.actions.availability.recurringErrorAlert'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [unavailableBlocks, setUnavailableBlocks] = useState([])
  const parseToMutation = () => {
    const originalBlocks = parseAvailable(availableBlocks)

    return {
      blocks: originalBlocks
        .reduce((newArray, {startDate}) => {
          if (
            unavailableBlocks.reduce((bool, isoDate) => {
              if (
                moment(isoDate).day() === moment(startDate).day() &&
                moment(isoDate).hour() === moment(startDate).hour()
              )
                return false
              return bool
            }, true)
          ) {
            const day = moment(startDate).utc().day()
            newArray.push({
              day: parseFloat(day === 0 ? 6 : day - 1),
              hour: parseFloat(moment(startDate).utc().hour()),
            })
            // se elimina de la lección original
          }
          return newArray
        }, [])
        .concat(
          selectedBlocks.map((block) => {
            const day = moment(block).utc().day()
            return {
              day: parseFloat(day === 0 ? 6 : day - 1),
              hour: parseFloat(moment(block).utc().hour()),
            }
          }),
        ),
    }
  }

  const [applyParcial, setApplyParcial] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const validateFields = useMemo(() => {
    return !applyParcial || (applyParcial && startDate)
  }, [applyParcial, startDate])

  return (
    <>
      <ListItem
        id='availableRecurring'
        className='m-0 py-3 px-6 w-100 font-weight-light'
        showTopLine={false}
        showBottomLine
        hasHover
        hasColorHover
        onClick={toggle}
        Left={translate('profile.actions.availability.recurrent')}
      />
      <Modal
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
          >
            <span className='h2 font-weight-bold'>
              {translate('profile.actions.availability.recurrentTitle')}
            </span>
          </ModalHeader>
        }
      >
        <div className='p-0 my-0 mx-7'>
          <AvailabilityCalendarRecurring
            loading={loadUser}
            setMoment={(newMoment) => {
              setNow(newMoment.toISOString())
            }}
            moment={moment(now)}
            isRecurring
            lessons={parseAvailable(availableBlocks)}
            setSelectedBlocks={setSelectedBlocks}
            selectedBlocks={selectedBlocks}
            setUnavailableBlocks={setUnavailableBlocks}
            unavailableBlocks={unavailableBlocks}
            loadSave={loadMutation}
            saveLabel={translate('profile.actions.save')}
            onSave={() => {}}
            zone={timezone}
            changeTimezone={
              <ChangeTimezoneModal timezone={timezone}>
                <ListItem
                  showTopLine
                  showBottomLine
                  Left={
                    <div className='m-0 p-0 d-flex flex-row align-items-center'>
                      <IconCountry className='mr-2' />
                      <span>{timezone}</span>
                    </div>
                  }
                  Right={
                    <div className='m-0 p-0 d-flex flex-row align-items-center'>
                      <div className='hover-icon px-2 py-1'>
                        <IconArrowRight />
                      </div>
                    </div>
                  }
                />
              </ChangeTimezoneModal>
            }
          />
          <div className='borderTop w-100 my-2' />
          <div className='w-100 d-flex flex-row align-items-center justify-content-start my-2'>
            {/* <div className={`d-flex flex-row align-items-center my-2`}> */}
            <CheckboxOption
              defaultState={applyParcial}
              value={applyParcial}
              onChange={(e) => {
                setApplyParcial(e)
              }}
              className='mr-2'
            />
            {/* </div> */}
            <div className='w-50 d-flex flex-column'>
              <span className='text-gray mb-1'>
                {translate(`profile.actions.availability.applyFrom`)}
              </span>
              <Input
                onChange={(e) => {
                  setStartDate(e?.target?.value)
                }}
                value={startDate}
                disabled={!applyParcial}
                type='date'
              />
            </div>
          </div>

          <div className='d-flex flex-row w-100 justify-content-end pt-3 pb-5'>
            <Button
              activity={loadMutation}
              onClick={() => {
                const variables = {
                  recurrentAvailability: parseToMutation(),
                }
                if (applyParcial) {
                  if (!startDate) {
                    showAlert(
                      {
                        text: translate(
                          'profile.actions.availability.pleaseSelectDate',
                        ),
                        status: 'warning',
                      },
                      dispatch,
                    )
                    return
                  }
                  variables.startDate = new Date(startDate)
                }
                setRecurringAvailability(variables)
              }}
              color='primary'
              disabled={
                (selectedBlocks.length === 0 &&
                  unavailableBlocks.length === 0) ||
                !validateFields
              }
              label={translate('profile.actions.save')}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
export default SetAvailableRecurring
