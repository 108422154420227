import React from 'react'
import {
  Avatar,
  IconSignCheck,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'

const LessonsIcons = (props) => {
  const {classNameItem, data, idTopic, manual} = props

  const RenderIcon = (props) => {
    const {item, selected, scheduled, checked, key, onClick} = props
    const avatarClass = !manual
      ? `
          ${!selected ? 'no' : ''}set-avatar 
          ${scheduled ? 'scheduled-avatar' : ''} 
          ${checked && selected ? 'selected-avatar' : ''}`
      : `
          ${!selected ? 'no' : ''}set-avatar-manual 
          ${scheduled ? 'scheduled-avatar-manual' : ''} 
          ${checked && selected ? 'selected-avatar-manual' : ''}
        `
    return (
      <Avatar
        key={key}
        className={`m-1 ${classNameItem}`}
        named={false}
        size={`avatar-medium ${avatarClass}`}
        name={item.lesson.substring(0, 1).toUpperCase()}
        IconComponent={checked && IconSignCheck}
        uri={null}
        onClick={onClick}
      />
    )
  }
  // {lesson, idTopic, lpw}
  return (
    <>
      {data.length > 0 &&
        data.map((item, ind) => {
          if (manual) {
            return (
              <ToolTipInfo
                className='p-0 m-0'
                tooltipId={`${item.idTopic}%${ind}`}
                innerElement={item.lesson}
              >
                <RenderIcon
                  key={ind}
                  item={item}
                  checked={item.scheduled}
                  selected={idTopic === item.idTopic}
                  scheduled={item.scheduled}
                  onClick={() => {
                    if (item?.onClick) item.onClick()
                  }}
                />
              </ToolTipInfo>
            )
          } else {
            return (
              <ToolTipInfo
                className='p-0 m-0'
                tooltipId={`${item.idTopic}%${ind}`}
                innerElement={item.lesson}
              >
                <RenderIcon
                  key={ind}
                  item={item}
                  checked={item.scheduled}
                  selected={idTopic === item.idTopic}
                  scheduled={item.scheduled}
                  onClick={() => {
                    if (item?.onClick) item.onClick()
                  }}
                />
              </ToolTipInfo>
            )
          }
        })}
    </>
  )
}

LessonsIcons.propTypes = {
  classNameItem: PropTypes.string,
  data: PropTypes.array,
  idLesson: PropTypes.string,
  idTopic: PropTypes.string,
  lesson: PropTypes.number,
  checked: PropTypes.bool,
  selected: PropTypes.bool,
}
LessonsIcons.defaultProps = {
  classNameItem: '',
  data: [],
  idLesson: null,
  idTopic: '',
  lesson: 0,
  checked: false,
  selected: false,
}

export default LessonsIcons
