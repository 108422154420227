import React from 'react'
import earlyDecisionBlueIcon from '../../../../../../assets/earlyDecisionBlueIcon.svg'
import earlyDecisionNames from '../../../../Utils/earlyDecision'
const EarlyActionStatus = (props) => {
  const {edVersion} = props

  return (
    <div className='mb-2 w-100'>
      <div
        className='w-100 row d-flex flex-row justify-content-start py-3'
        style={{
          borderRadius: '10px',
          border: '1px solid',
          borderColor: '#f0f2f5',
          whiteSpace: 'nowrap',
          marginLeft: '0.1rem',
        }}
      >
        <div className='col-1 mr-3 align-self-center'>
          {' '}
          <img
            src={earlyDecisionBlueIcon}
            alt='Early Decision Icon'
            color='#696bff'
            width='26'
            height='26'
          />
        </div>
        <div className='col-10 align-self-center'>
          <span className='d-flex justify-content-start h4 text-muted'>
            {earlyDecisionNames[edVersion]}
          </span>
        </div>
      </div>
    </div>
  )
}
export default EarlyActionStatus
