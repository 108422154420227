import React, {useEffect, useState, useRef} from 'react'
import {Alert, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap'
import {IconLoad} from '@Knowledge-OTP/znk-ui-components'

import {db} from '../../fireBase'
import {useSelector} from 'react-redux'
import './index.css'
import useTranslation from '../../../../i18n/useTranslation'
import CheckInEducator from '../CheckInEducator'
import CheckInStudent from '../CheckInStudent'
import moment from 'moment-timezone'
import {useChatNotification} from '../../Model/useChatNotification'
import phaseNames from '../../../Workspace/Utils/phaseNames'

const SendMessage = (props) => {
  const {translate} = useTranslation()
  const {
    collection,
    collectionType = 'messages',
    isEducator,
    isStudent,
    attendanceStatus,
    setDataLessonToRedux,
    ref = null,
    onSend = () => {},
  } = props
  // eslint-disable-next-line
  const { id: collectionID } = collection
  const type = collection?.__typename ? collection?.__typename : 'UserTasksOut'
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )
  const [message, setMessage] = useState('')
  const finishTime = collection.endDate
    ? moment() >= moment(collection?.endDate)
    : collection?.status === 'completed'
  const {firstName, lastName, id: userID} = useSelector(
    (state) => state.user.info,
  )
  const [messageData, setMessageData] = useState({
    error: false,
    loading: null,
  })
  const {loading, error} = messageData
  const sendMessage = async () => {
    let variablesToSend = {}
    const regexPhone = /((?:\+|00)[17](?: |)?|(?:\+|00)[1-9]\d{0,2}(?: |)?|(?:\+|00)1\d{3}(?: |)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |)[0-9]{3}(?: |)[0-9]{4})|([0-9]{7}))/g
    // eslint-disable-next-line
    const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (!message || message.match(regexPhone) || message.match(regexEmail))
      return
    setMessageData({
      ...messageData,
      loading: true,
    })
    const snapshot = await db.collection(collectionType).doc(collectionID).get()
    const numberKey = Object.keys(snapshot.data()).map(
      (key) => snapshot.data()[key],
    ).length
    const keyMessage = `message${numberKey}`
    const objectMessage = {
      [keyMessage]: {
        text: message,
        userName: `${firstName} ${lastName}`,
        userID,
        createdAt: new Date().toISOString(),
        index: numberKey,
      },
    }
    try {
      await db
        .collection(collectionType)
        .doc(collectionID)
        .update(objectMessage)
      setMessageData({
        ...messageData,
        loading: false,
      })

      if (onSend) onSend(inputRef)

      if (type === 'UserTasksOut') {
        variablesToSend = {
          taskId: collection?.id,
          stepId: collection?.stepId,
          phase:
            phaseNames[collection?.phase] || phaseNames[collection?.phaseName],
          planId,
          chanel: 'counselingTasks',
        }
      } else if (type.toLowerCase().includes('lesson')) {
        variablesToSend = {
          lessonId: collection?.id,
          chanel: 'lessons',
        }
      }
      chatNotification({
        variables: variablesToSend,
      })
      setMessage('')
    } catch (error) {
      setMessageData({
        ...messageData,
        error: true,
        loading: false,
      })
    }
    // inputRef.current.focus()
  }
  const inputRef = useRef(null)
  const handleSubmit = async (event) => {
    event.preventDefault()
    const fireStoreDocument = db.collection(collectionType).doc(collectionID)
    fireStoreDocument.get().then((doc) => {
      if (doc.exists) {
        sendMessage()
      } else {
        fireStoreDocument.set({}).then(() => {
          sendMessage()
        })
      }
    })
  }
  // eslint-disable-next-line
  const [chatNotification, { data: dataChatNotification, loading: loadignChatNotification, refetch: refetchChatNotification }] = useChatNotification(   
    () => {},
    (err) => {
      console.error('Error with chat notification : ', err)
    },
  )
  useEffect(() => {
    setTimeout(
      () =>
        setMessageData({
          ...messageData,
          error: false,
        }),
      3000,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  // useEffect(() => {
  //   if (inputRef?.current) {
  //     inputRef.current.focus()
  //   }
  // }, [messageData])

  return (
    <div
      ref={ref}
      style={{
        position: 'sticky',
        paddingBottom: '10px',
        bottom: '0px',
        backgroundColor: 'white',
      }}
    >
      {attendanceStatus === 'attended' && !finishTime && (
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert
              style={{
                backgroundColor: '#f8d7da',
                color: '#842029',
                borderColor: '#f5c2c7',
              }}
            >
              Error sending message.
            </Alert>
          )}
          <InputGroup>
            <input
              // autoFocus
              ref={inputRef}
              id='input-chat'
              className='znk-input trans no-border-right w-80 px-2'
              placeholder={translate('virtualChat.sendMessage.typeSomething')}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              disabled={loading || error}
            />
            <InputGroupAddon addonType='prepend' className='icon'>
              <InputGroupText type='text' className='znk-no-border'>
                {loading ? (
                  <IconLoad />
                ) : (
                  <span
                    className='text-primary'
                    onClick={handleSubmit}
                    style={{cursor: 'pointer'}}
                  >
                    {translate('virtualChat.sendMessage.send')}
                  </span>
                )}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </form>
      )}
      {!finishTime &&
        isStudent &&
        !isEducator &&
        type !== 'UserTasksOut' &&
        attendanceStatus !== 'attended' && (
          <CheckInStudent dataLesson={collection} />
        )}
      {!finishTime &&
        isEducator &&
        type !== 'UserTasksOut' &&
        attendanceStatus !== 'attended' && (
          <CheckInEducator
            dataLesson={collection}
            setDataLessonToRedux={setDataLessonToRedux}
          />
        )}
    </div>
  )
}

export default SendMessage
