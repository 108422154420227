import React from 'react'
import PropTypes from 'prop-types'
import FirebaseProvider from './Providers/FirebaseProvider'
import ApolloProvider from './Providers/ApolloProvider'
import {CookiesProvider} from 'react-cookie'
import AuthProvider from './Providers/AuthProvider'
import {Provider as ReduxProvider} from 'react-redux'
import store from '../store/store'
import GlobalDataProvider from './Providers/GlobaldataProvider'
import I18nProvider from './Providers/I18nProvider'
import ForceLogin from './Providers/ForceLogin'
import {useLocation} from 'react-router-dom'
import OfflineModule from '../Pages/OfflineModule'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {}

const Root = (props) => {
  // evita que aparesca la pantalla de login mientras recarga
  // global.loadingForceLogin = true

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const nologinParam = searchParams.get('nologin')
  const nologin = nologinParam === '1'
  console.log('no login *** ', nologin)

  return (
    <CookiesProvider>
      {/** ESCUCHA AL USUARIO DE FIREEBASE AUTENTICADO Y MONTA EL CLIENTE APOLLO RESPECTIVAMENTE **/}
      <ApolloProvider>
        {/** INICIALIZA LA APLICACIÓN DE FIREBASE DESDE EL CACHE SI ES QUE ESTÁ (SINO LA CREA) **/}
        <FirebaseProvider>
          <ReduxProvider store={store}>
            <I18nProvider>
              {nologin ? (
                <OfflineModule />
              ) : (
                <ForceLogin>
                  <AuthProvider>
                    <GlobalDataProvider>{props.children}</GlobalDataProvider>
                  </AuthProvider>
                </ForceLogin>
              )}
              {/* Force Login is in charge of showing Login Page or not - MUESTRA LOGIN PAGE O NO */}
            </I18nProvider>
          </ReduxProvider>
        </FirebaseProvider>
      </ApolloProvider>
    </CookiesProvider>
  )
}
Root.propTypes = propTypes
Root.defaultProps = defaultProps

export default Root
