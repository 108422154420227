import {useLazyQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../Model/QueryResult/useLessonResult'

export const UsePendingAction = () => {
  return useLazyQuery(
    gql`query {
            pendingActionLessonsForEducator {  
                ${queryLessonResult}
            }
        }`,
    {
      fetchPolicy: 'no-cache',
    },
  )
}

export default UsePendingAction
