import React from 'react'

const WasSubstitutedMsg = (props) => {
  const {wasSubstituted} = props


  return (
    wasSubstituted && 
        <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
        <span className='text-error'>
        You were substituted for this lesson. You are seeing this lesson for awareness purposes.
        </span>
      </div>

  )
}
export default WasSubstitutedMsg
