import {useQuery, gql} from '@apollo/client'

const useUser = (vars = {}, fetchPolicy = 'cache-first') => {
  return useQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          educatorInfo {
            id
            isWaivePayments
            defaultRate
            disableForStudents
            rating
            services {
              serviceId
              name
              defaultRate
              topics {
                topicId
                teachingTypes
                name
                individualRate
                groupRate
                isWaiveIndividual
                isWaiveGroup
              }
            }
            myPayslips {
              id
              status
              category
              payslipDate
              to
              payslipNumber
              payslipProps {
                amount
                isPenalized
              }
            }
            backupLink
            weeklyCapHours
            canTeach
            isActive
            bankNumber
            bankRouting
            googleCalendar
            backupLink
            handbook
          }
        }
      }
    `,
    {variables: vars, fetchPolicy},
  )
}

export default useUser
