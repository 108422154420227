import React, {useMemo} from 'react'
import PersonalBlock from './PersonalBlock'
import {defaultProps, propTypes} from '../propTypes'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../i18n/useTranslation'
import StudentBlock from './StudentBlock'
import FamilyModalForm from '../../Modal/changeFamilyInfo'
const FullProfile = (props) => {
  const userData = useSelector((state) => state.user.info)
  const Roles = [].concat(userData.roles)
  const user = useMemo(() => {
    let isStudent = false
    let isEducator = false
    if (Roles) {
      if (Roles.filter((e) => e === 'STUDENT').length > 0) {
        isStudent = true
      } else if (Roles.filter((e) => e === 'EDUCATOR')) {
        isEducator = true
      }
      return {
        isStudent,
        isEducator,
      }
    }
    return {
      isStudent: false,
      isEducator: false,
    }
  }, [Roles])
  const {translate} = useTranslation()
  return (
    <div className='container-fluid d-flex flex-column pt-4'>
      <h2 className='font-weight-bold text-logo my-4'>
        {translate('profile.infoTitle')}
      </h2>
      {userData ? (
        <>
          {<PersonalBlock {...userData} />}
          {user.isStudent && <StudentBlock {...userData} />}
          {user.isStudent && <FamilyModalForm {...userData} />}
        </>
      ) : (
        <Skeleton count={3} height={35} />
      )}
    </div>
  )
}
FullProfile.propTypes = propTypes
FullProfile.defaultProps = defaultProps
export default FullProfile
