import React from 'react'
import {Button, IconLesson, IconTask} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'

const ApprovalModal = () => {
  const {translate} = useTranslation()
  return (
    <div className='mx-4'>
      <p className='h4 font-weight-bold my-2'>
        {translate('program.plan.approvalModal.breakdown')}
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>
            <IconTask className='text-primary' size={16} />{' '}
            {translate('program.plan.approvalModal.totalLessons', 40)}
          </span>
          <span>{translate('program.plan.approvalModal.lessonRate', 75)}</span>
        </span>
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>
            <IconTask className='text-primary' size={16} />{' '}
            {translate('program.plan.approvalModal.registrationFee')}
          </span>
          <span>$300</span>
        </span>
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>
            <IconTask className='text-primary' size={16} />{' '}
            {translate('program.plan.approvalModal.toeflUpgrade')}
          </span>
          <span>$80</span>
        </span>
      </p>
      <hr className='py-1' />
      <p className='h4 font-weight-bold my-1 mb-2'>
        {translate('program.plan.weeklySchedule')}
      </p>
      <p>
        <IconLesson size={16} />
        <span>
          {translate('program.plan.weeklyLPT', {
            num: 2,
            type: 'individual',
            topic: 'math',
          })}
        </span>
      </p>
      <p>
        <IconLesson size={16} />
        <span>
          {translate('program.plan.weeklyLPT', {
            num: 2,
            type: 'individual',
            topic: 'english',
          })}
        </span>
      </p>
      <hr className='py-1' />
      <p className='h4 font-weight-bold my-1 mb-2'>
        {translate('program.plan.approvalModal.paymentSchedule')}
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>Initial payment</span>
          <span>$300</span>
        </span>
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>End of September</span>
          <span>$80</span>
        </span>
      </p>
      <p>
        <span className='d-flex justify-content-between'>
          <span>End of October</span>
          <span>$80</span>
        </span>
      </p>
      <hr className='py-1' />
      <Button
        color='primary'
        label={translate('misc.saveChanges')}
        className='w-100'
        check
      />
    </div>
  )
}

export default ApprovalModal
