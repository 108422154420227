import {
  IconClose,
  Modal,
  Form,
  StickyButton,
  Button,
  Field,
  AmountInput,
  LabeledField,
} from '@Knowledge-OTP/znk-ui-components'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {isEmpty} from 'validate.js'
import useTranslation from '../../../../../i18n/useTranslation'
import sendWeeklyCapHoursFromEducxator from '../../../../../Model/Users/Educators/sendWeeklyCapHoursFromEducxator'

const ChangeEducatorInfo = (props) => {
  const {isOpen, setIsOpen, weeklyCapHours} = props
  const [form, setForm] = useState({weeklyCapHours: ''})
  const refetch = useSelector((state) => state.user.refetch)
  const {translate} = useTranslation()
  const [errors, setErrors] = useState({})
  const toggle = () => {
    setErrors({})
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    if (weeklyCapHours) {
      setForm({weeklyCapHours: weeklyCapHours})
    }
  }, [weeklyCapHours])
  const {submit, loading, validationErrors} = sendWeeklyCapHoursFromEducxator(
    () => {
      refetch()
      toggle()
    },
    console.log,
  )
  useEffect(() => {
    if (validationErrors) setErrors(validationErrors)
  }, [validationErrors])
  const onSubmit = () => {
    submit({weeklyCapHours: form.weeklyCapHours})
  }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>
              {translate('profile.actions.employment.sendweekly')}
            </span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='m-0 p-0 mb-2 px-3'>
          <Form value={form} onChange={setForm}>
            <label className='text-gray font-weight-light mt-3 mb-2'>
              {translate('profile.actions.employment.weeklycaphours')}
            </label>
            <Field
              className='col-12 placeholder-to-right'
              component={AmountInput}
              type={LabeledField}
              fieldName='weeklyCapHours'
              fieldType='number'
              addOn=''
              placeholder='0'
              min='0'
            />
            {!isEmpty(errors) && (
              <h5 className='text-error'>
                {translate('profile.actions.employment.errweeklycaphours')}
              </h5>
            )}
            <div className='row d-flex m-0 p-0 mt-5'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{borderRadius: '16px'}}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}
export default ChangeEducatorInfo
