import React, {useEffect, useState} from 'react'
// import { useSelector } from 'react-redux'
import {ModalLayout, IconSearch, Input} from '@Knowledge-OTP/znk-ui-components'
import './index.css'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import ListItem from './ListItem'
import {collegesMock} from './mocks/universitiesMock'
import useUniveristyDirectory from './useUniversityDirectory'
import useAllUniversities from '../Model/useAllUniversities'
import Pagination from '../../../common/Pagination'
import useTranslation from '../../../i18n/useTranslation'
import useMyCollegeModal from '../../Workspace/Student/MyCollegeModal/useMyCollegeModal'
import {useGetUniversity} from '../../MyPlans/viewCounselingPlanModal/Tabs/Universities/Model/useGetUniversity'

const UniversityDirectory = (props) => {
  // const {/** universities, */ addUniversity } = props
  const {
    isOpen,
    // onChange,
    // open,
    // close,
    plan,
    toggle,
  } = useUniveristyDirectory()
  const {open: openCollegeModal, setUpdateUniversity} = useMyCollegeModal()
  const [inputValue, setInputValue] = useState('')
  const {translate} = useTranslation()
  const [obj, setObj] = useState({})
  const CollegeURL =
    process.env.COLLEGE_URL || process.env.REACT_APP_COLLEGE_URL
  const CollegeAPI =
    process.env.COLLEGE_API || process.env.REACT_APP_COLLEGE_API
  // Arreglo con el listado de información que necesitamos traer desde la API de Colleges
  const infoToObtain = [
    'name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'logo_image',
    'website',
  ]

  // eslint-disable-next-line
const [getOneUniversity, { data: dataOneUniversity, loading: loadingOneUniversity, refetch: refetchOneUniversity }] = useGetUniversity()

  const GetModalUniversityInfo = async (
    college,
    infoToObtain = [],
    onChange = () => {},
  ) => {
    let object = {}
    // Convertir el arreglo a una string para pasar por params a la API
    const params = infoToObtain.join('%2C')
    if (!college?.isPrincipal && college?.collegeUnitId) {
      await axios
        .get(
          `${CollegeURL}/college/info?api_key=${CollegeAPI}&college_unit_ids=${college.collegeUnitId}&info_ids=${params}`,
        )
        .then((res) => {
          object = res?.data?.colleges[0]
          object.collegeUnitId = college.collegeId
          object.id = college.id
          object.status = college.status
          object.early = college.early
          object.isActive = college.isActive
          object.isPrincipal = college.isPrincipal
          object.deletedAt = college.deletedAt || null
          object.logo = college.logo

          setObj(object)
        })
      return object
    } else {
      getOneUniversity({
        variables: {
          universityId: college?.id,
        },
      })
    }
  }

  // pagination
  const [page, setPage] = useState(1)
  const [next, setNext] = useState('')
  const [previous, setPrevious] = useState('')
  const [limit, setLimit] = useState(20)

  // data: es la información que traemos de la base de datos, con la query getUniversities
  const {loading, data} = useAllUniversities({
    limit: limit,
    next: next,
    previous: previous,
    search: inputValue.toLowerCase() || '',
  })

  useEffect(() => {}, [data, next, limit])

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  useEffect(() => {
    setUpdateUniversity(dataOneUniversity?.getUniversity)
    // eslint-disable-next-line
  }, [dataOneUniversity])

  useEffect(() => {
    setUpdateUniversity(obj)
    // eslint-disable-next-line
  }, [obj])

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={() => {
          toggle()
        }}
        topHead={
          <label className='h2 text-title'>
            {translate('counseling.univDirTitle')}
          </label>
        }
        minHead={
          <label className='h2 text-title'>
            {translate('counseling.univDirTitle')}
          </label>
        }
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        underHead={
          <div
            className='w-100 mt-2'
            style={{
              borderRadius: 16,
              backgroundColor: '#f0f4f8',
              height: '50px',
            }}
          >
            <Input
              type='addon'
              className='mr-5 px-2 d-flex flex-row-reverse'
              addOnComponent={
                <IconSearch
                  color='#bec5cf'
                  className='d-flex justify-content-start'
                  size={18}
                />
              }
              placeholder='Search'
              onChange={(event) => setInputValue(event.target.value)}
              value={inputValue}
            />
          </div>
        }
      >
        {/* Listado y filtrado de universidades */}
        {!loading ? (
          <div className='h-100 d-flex flex-column'>
            {data?.docs?.length > 0 &&
              data?.docs.map(
                (university, index) =>
                  (!university.deleteAt || university.deleteAt === null) && (
                    <ListItem
                      key={index}
                      item={university}
                      onClick={() => {
                        openCollegeModal(
                          () => {},
                          GetModalUniversityInfo(university, infoToObtain),
                          plan,
                        )
                      }}
                    />
                  ),
              )}

            <Pagination
              className='w-100 d-flex flex-row justify-content-between align-items-end pagination-opacity pt-2'
              pageSize={limit}
              changeSize={(size) => {
                setLimit(size)
              }}
              clickNext={() => {
                if (page < Math.floor(data?.totalDocs / limit)) {
                  setPage(page + 1)
                  setPrevious('')
                  setNext(data?.next)
                }
              }}
              clickPrevious={() => {
                if (page > 1) {
                  setPage(page - 1)
                  setNext('')
                  setPrevious(data?.previous)
                }
              }}
              hasPrevious={page > 1}
              hasNext={Math.floor(data?.totalDocs / limit) > page}
              activePage={page}
              pageCount={
                Math.floor(data?.totalDocs / limit) > 0
                  ? Math.floor(data?.totalDocs / limit)
                  : 1
              }
            />
          </div>
        ) : (
          <Skeleton count={5} height={40} />
        )}
      </ModalLayout>
    </>
  )
}

UniversityDirectory.defaultProps = {
  universities: collegesMock,
  addUniversity: () => {},
  isOpen: false,
  toggle: () => {},
}

UniversityDirectory.propTypes = {
  universities: PropTypes.array,
  addUniversity: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default UniversityDirectory
