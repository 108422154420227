import {useCallback, useState} from 'react'
import validate from 'validate.js'
import isEmpty from 'lodash/isEmpty'
import firebase from 'firebase/app'
import 'firebase/auth'

export default (onSuccess, onError) => {
  const [validationErrors, setValidationErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const submit = useCallback(
    (signupForm, whenSuccess, whenError) => {
      setValidationErrors({})
      const validation = validate(signupForm, {
        email: {email: true},
        password: {presence: true, length: {minimum: 6}},
        confirmPassword: {
          presence: true,
          equality: 'password',
        },
        firstName: {presence: {allowEmpty: false}},
        lastName: {presence: {allowEmpty: false}},
        asEducator: {presence: false},
      })
      if (!isEmpty(validation)) {
        setValidationErrors(validation)
        return
      }
      setLoading(true)
      global.localStorage.setItem(
        'loggedUser',
        JSON.stringify({
          firstName: signupForm.firstName,
          lastName: signupForm.lastName,
          email: signupForm.email,
          hasFirebaseAuth: false,
          hasLivePrepUser: false,
        }),
      )
      firebase
        .auth()
        .createUserWithEmailAndPassword(signupForm.email, signupForm.password)
        .then(
          (user) => {
            firebase
              .auth()
              .signInWithEmailAndPassword(signupForm.email, signupForm.password)
            whenSuccess()
            setLoading(false)
          },
          (err) => {
            whenError(err)
            setLoading(false)
            console.log('Error creating user on firebase')
            console.log(err)
          },
        )
    },
    [setLoading],
  )
  return {
    submit: (form) => submit(form, onSuccess, onError),
    loading,
    validationErrors,
  }
}
