import {getDict} from './server'

import {
  TRANSLATION_DICT_GET_ACTION_ERROR,
  TRANSLATION_DICT_GET_ACTION_INIT,
  TRANSLATION_DICT_GET_ACTION_SUCCESS,
  TRANSLATION_DICT_GET_ACTION_RESET,
  TRANSLATION_LANG_SET_ACTION,
} from './const'

export const getStringsAction = () => {
  return (dispatch) => {
    dispatch({type: TRANSLATION_DICT_GET_ACTION_INIT})
    getDict()
      .then((response) => {
        if (response.data.err)
          dispatch({
            type: TRANSLATION_DICT_GET_ACTION_ERROR,
            payload: response.data.error,
          })
        else
          dispatch({
            type: TRANSLATION_DICT_GET_ACTION_SUCCESS,
            payload: response.data,
          })
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.error(err)
        dispatch({
          type: TRANSLATION_DICT_GET_ACTION_ERROR,
          payload: {msg: 'Ha ocurrido un error desconocido'},
        })
      })
  }
}
export const setLangAction = (lang) => {
  return (dispatch) => {
    dispatch({type: TRANSLATION_LANG_SET_ACTION, payload: lang})
  }
}
export const resetCheckLoginAction = () => {
  return (dispatch) => {
    dispatch({type: TRANSLATION_DICT_GET_ACTION_RESET})
  }
}
