import validate from 'validate.js'

const constraints = {
  weeklyCapHours: {
    numericality: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
}

export default (data) => {
  const t = validate(data, constraints)
  console.log('¡***', t)
  return t
}
