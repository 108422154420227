import React from 'react'
import {
  Activity,
  Modal,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useModalState from '../../../../../../hooks/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'
import useSetMyNotificationInfo from '../setMyNotificationInfo'
import {useApolloClient} from '@apollo/client'
import meQuery from '../../../../../../queries/me'
const ToggleReminder = (props) => {
  const {isOpen, modalData, toggle} = useModalState(
    'profile.notificationToggle',
  )
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const client = useApolloClient()
  const onSuccess = (result) => {
    client.cache.writeQuery({
      query: meQuery,
      data: {me: result.data.setMyStudentInfo},
    })
    toggle()
  }
  const {submit, loading} = useSetMyNotificationInfo(onSuccess, console.log)
  const {reminderValue, reminderKey, prevNotifs} = modalData
  const onSubmit = () => {
    submit({notificationInfo: {...prevNotifs, [reminderKey]: !reminderValue}})
  }
  return (
    <Modal
      buttonLabel={null}
      className={`modal-float modal-${
        breakWidth === 'SM' ? 'botbar' : 'center'
      } modal-hauto`}
      StyleModalBody={{backgroundColor: 'white'}}
      isOpen={isOpen}
      toggle={toggle}
      Foot={
        <span
          className='bg-white py-5 text-muted font-weight-light text-center w-100'
          style={{borderRadius: '12px'}}
          onClick={toggle}
        >
          {translate('misc.cancel')}
        </span>
      }
    >
      <div className='d-flex flex-column align-items-center pt-2'>
        <span className='h3 font-weight-bold py-4 px-7'>
          {translate('profile.actions.changeNotifications.reminderSet')}
        </span>
        <span className='h3 font-weight-light text-secondary text-center pb-5 px-7'>
          {translate(
            `profile.actions.changeNotifications.reminderSet${
              modalData && modalData.reminderType
            }`,
          )}
        </span>
        <span
          className={`modal-selectable ${
            modalData && modalData.reminderValue ? 'error' : 'primary'
          }`}
          onClick={onSubmit}
        >
          {loading ? (
            <Activity />
          ) : (
            translate(
              `profile.actions.changeNotifications.reminder${
                modalData && modalData.reminderValue ? 'Remove' : 'Activate'
              }`,
            )
          )}
        </span>
      </div>
    </Modal>
  )
}

export default ToggleReminder
