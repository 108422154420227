import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'
// import * as moment from 'moment'
import {ModalLayout, RedRibbon} from '@Knowledge-OTP/znk-ui-components'
import './index.css'
import useRegisterScoresModal from './useRegisterScoresModal'
import useSetExamScore from '../../../Task/Model/useSetExamScore'
import useAllUserTasks from '../../../Task/Model/useAllUserTasks'
import MessageModal from '../ViewTask/Components/Modals/messageModal'
import {UseGetUserTask} from '../../../../Model/Users/Student/getUserTask/useGetUserTask'
import {COUNSELING_ALL_TASKS} from '../../../../store/user/const'
import TitleScoresComponent from './Components/TitleScores'
import ScoresComponent from './Components/ScoresComponent'
import useOperationTaskMutation from '../../../Task/Model/useOperationTask'
import useAddScoresToPlan from '../Sections/Docs/model/useAddScoresToPlan'
import useAddExamScoresToPlan from '../Sections/Docs/model/useAddExamScoresToPlan'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import phaseNames from '../../Utils/phaseNames'
import taskTypes from '../../Utils/taskTypes'
import typenames from '../../Utils/typenames'
import useUploadFileToPlan from '../Sections/Docs/model/useUploadFileToPlan'
import useUploadFileToTask from '../../../Task/Model/useUploadFileToTask'
import {showAlert} from '../../../../common/Alert/util'
import useTranslation from '../../../../i18n/useTranslation'

const RegisterScoresModal = (props) => {
  const {translate} = useTranslation()
  let examScoreId = ''
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )
  const taskData = useSelector(
    (state) => state?.modal?.counseling?.viewTaskModal?.task,
  )

  const examScores = useSelector((state) => state?.examScore?.examScores)
  const [filesUploadedByUser, setFilesUploadedByUser] = useState([])
  const [uploadStatusOk, setUploadStatus] = useState('')
  const task = taskData
  const dateToShow =
    taskData?.serviceDate ||
    taskData?.resolveTask?.score?.examDate ||
    taskData?.testDate
  const dataRedux = useSelector((state) => state.modal.counseling.viewTaskModal)
  const {notAllowClose} = dataRedux
  const dispatch = useDispatch()
  // Mutation para subir archivos al plan
  // eslint-disable-next-line
  const { submit: submitUploadToPlan, loading: uploadLoadingToPlan, data: uploadDataToPlan } = useUploadFileToPlan(
    () => {},
    () => {},
  )
  // Mutation para subir archivos a la task
  // eslint-disable-next-line
  const { submit: submitUploadToTask, loading: uploadLoadingToTask, data: uploadDataToTask } = useUploadFileToTask(
    () => {
      sendToReviewFunction()
    },
    () => {},
  )
  const universityId = ''
  const taskStatus = []
  const {
    isOpen: isOpenRegisterScoresModal,
    toggle,
    close: closeRegisterScoresModal,
    onChange,
  } = useRegisterScoresModal()
  // eslint-disable-next-line
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const {/** loading, data, */ refetch: refetchAllTasks} = useAllUserTasks({
    universityId,
    status: taskStatus,
    planId,
  })

  const [scoresToShow, setScoresToShow] = useState()

  const [
    operationMutation,
    // eslint-disable-next-line
    { loading: loadMutation, data: dataSetScore },
  ] = useSetExamScore(
    () => {
      // Si hay files, subirlos y ejecutar el review fn en el onsuccess de esta. Si no, ejecutar el review fn directamente.
      if (filesUploadedByUser?.length > 0) {
        try {
          submitUploadToTask({
            files: filesUploadedByUser,
            taskId: task?.id,
            stepId: task?.stepId,
            phase: phaseNames[task?.phase] || task?.phaseName || phaseNames[task?.phaseId],
            planId: planId,
          })
        } catch (error) {
          showAlert(
            {
              text: translate('modal.task.uploadFileError'),
              status: 'error',
            },
            dispatch,
          )
        }
      } else {
        sendToReviewFunction()
      }
    },
    (err) => {
      showAlert(
        {
          text: translate('modal.task.addScoresError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const [
    addScoresMutation,
    // eslint-disable-next-line
    { loading: loadingAddScores, data: dataAddScores },
  ] = useAddScoresToPlan(
    (resp) => {
      onChange()
      if (filesUploadedByUser?.length > 0) {
        try {
          submitUploadToPlan({
            files: filesUploadedByUser,
            planId: taskData.id,
          })
        } catch (error) {
          showAlert(
            {
              text: translate('modal.task.uploadFileError'),
              status: 'error',
            },
            dispatch,
          )
        }
      }
      toggle()
    },
    (err) => {
      showAlert(
        {
          text: translate('modal.task.addScoresError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const [
    addExamScoresToPlanMutation,
    // eslint-disable-next-line
    { loading: loadingAddExamScoresToPlan, data: dataAddExamScoresToPlan },
  ] = useAddExamScoresToPlan(
    (resp) => {
      // setTaskData()
    },
    (err) => {},
  )

  useEffect(() => {
    if (taskData?.serviceId && !taskData?.selectedExam?.scores) {
      setScoresToShow(
        examScores?.filter(
          (examScore) => examScore?.serviceId === taskData?.serviceId,
        )[0],
      )
    } else {
      setScoresToShow(
        taskData?.resolveTask?.score ||
          taskData?.selectedExam?.scores ||
          taskData?.selectedExam,
      )
    }
    // eslint-disable-next-line
  }, [taskData/*, dataAddExamScoresToPlan*/]) 

  useEffect(() => {
    if (
      scoresToShow === null &&
      taskData?.__typename !== typenames.UserTasksOut
    ) {
      // eslint-disable-next-line
      examScoreId = examScores?.filter((examScore) => examScore?.serviceId === taskData?.serviceId)[0]
      // llamar la mutacion para agregar el exam al plan
      addExamScoresToPlanMutation({
        variables: {
          planId: taskData?.id,
          examScoreId: examScoreId?.id || examScoreId?._id,
          // taskData?.selectedExam?.examId?._id,
        },
      })
    }
  }, [scoresToShow])

  const [
    sendToReviewMutation,
    // eslint-disable-next-line
    {loading: loadingsendToReview, data: datasendToReview},
  ] = useOperationTaskMutation(
    (resp) => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
      // refetchUserData()
      // refetchAllTasks().then((resp) => {
      //   dispatch({
      //     type: COUNSELING_ALL_TASKS,
      //     payload: 'counseling.allTasks',
      //     data: resp?.data?.getUserTasks,
      //   })
      // })

      // closeShowTask()
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )

  const sendToReviewFunction = () => {
    sendToReviewMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || task?.phaseName || phaseNames[task?.phaseId],
        planId: planId,
      },
    })
  }

  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      // setTaskData(resp?.getUserTask)
      const taskToSave = resp?.getUserTask
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.viewTaskModal',
        data: {
          ...dataRedux,
          task: taskToSave,
        },
      })
      refetchAllTasks()
        .then((resp) => {
          dispatch({
            type: COUNSELING_ALL_TASKS,
            payload: 'counseling.allTasks',
            data: resp?.data?.getUserTasks,
          })
        })
        .then(() => {
          closeRegisterScoresModal()
        })
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  useEffect(() => {
    if (taskData?.resolveTask?.score)
      setScoresToShow(taskData?.resolveTask?.score)
  }, [taskData])

  return (
    <>
      <ModalLayout
        className='d-block modal-colored modal-sidebar modal-desktop-half exam-modal'
        dimensionClassName='m-0 pr-5 py-3 pl-3'
        centerHeaderOverall='w-90'
        isOpen={isOpenRegisterScoresModal}
        toggle={!notAllowClose && toggle}
        topHead={
          <div className='background-head'>
            <div className='modal-header mt-0 pt-8 d-flex flex-column'>
              <RedRibbon
                // className='px-4 px-sm-4'
                left='znk-ribbon-task left'
                right='znk-ribbon-task right'
              />
              <div className='m-0 p-0 d-flex flex-column my-2'>
                <span className='h2 text-white'>
                  {scoresToShow?.name !== 'Advanced Placement'
                    ? scoresToShow?.name
                    : scoresToShow?.results[0]?.name
                    ? 'AP - ' + scoresToShow?.results[0]?.name
                    : scoresToShow?.name}
                </span>
                {dateToShow && (
                  <span className='h4 text-white'>
                    {moment(dateToShow).format('MMMM Do, YYYY')}
                  </span>
                )}
              </div>
              <div className='d-flex flex-row w-100 justify-content-center'>
                {/* Muestra los scores generales o promedios */}
                <TitleScoresComponent scores={scoresToShow} />
              </div>
            </div>
          </div>
        }
      >
        {/* Muestra los scores de cada materia  */}
        <div className='px-4 px-sm-4 mt-2'>
          <ScoresComponent
            scores={scoresToShow}
            mutation={
              taskTypes?.scoreCollectionArray?.includes(task?.type)
                ? operationMutation
                : addScoresMutation
            }
            planId={planId}
            phase={phaseNames[task?.phase] || phaseNames[task?.phaseName]}
            stepId={task?.stepId}
            taskId={task?.id}
            taskStatus={
              task?.__typename === 'UserTasksOut'
                ? task?.status
                : task?.selectedExam?.scores
                ? 'completed'
                : 'pending'
            }
            filesUploadedByUser={filesUploadedByUser}
            setFilesUploadedByUser={setFilesUploadedByUser}
            uploadStatusOk={uploadStatusOk}
            setUploadStatus={setUploadStatus}
            activity={
              uploadLoadingToPlan ||
              uploadLoadingToTask ||
              loadMutation ||
              loadingAddScores ||
              loadingAddExamScoresToPlan ||
              loadingsendToReview ||
              loadingOneTask
            }
          />
        </div>
      </ModalLayout>

      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
    </>
  )
}

export default RegisterScoresModal
