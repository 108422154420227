import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import PersonalBlock from '../../Info/FullPersonalInfo/PersonalBlock'
import StudentBlock from '../../Info/FullPersonalInfo/StudentBlock'
import {IconClose, Modal} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import {TOGGLE_MODAL_ACTION} from '../../../../store/modal/const'
import FamilyModalForm from '../changeFamilyInfo'

const PersonalInfoModal = (props) => {
  const isModalOpen = useSelector((state) => state.modal.profile.personalInfo)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.personalInfo'})
  }
  const {translate} = useTranslation()
  return (
    <Modal
      className='modal-botbar'
      isOpen={isModalOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold text-logo mr-4'>
            {translate('profile.infoTitle')}
          </span>
        </ModalHeader>
      }
    >
      <PersonalBlock {...props} />
      <StudentBlock {...props} />
      <FamilyModalForm {...props} />
    </Modal>
  )
}

export default PersonalInfoModal
