import React, { useEffect, useState } from 'react'
import {
  ModalLayout,
  PhoneSelector,
  PlainTextInput,
  Field,
  LabeledField,
  Button,
  useWindowDimensions,
  Form,
  Activity,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import useSetStudentPhoneModal from './useSetStudentPhoneModal'
import useGetUserPhone from './Model/useGetUserPhone'
import useUpdateUserPhone from './Model/useUpdateUserPhone'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'

const SetStudentPhoneModal = () => {
  const {
    onChange = () => {},
    isOpen = false,
    toggle = () => {},
    studentId = null,
  } = useSetStudentPhoneModal()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [studentPhone, setStudentPhone] = useState('')
  const {breakWidth} = useWindowDimensions()
  const [activeTab, setActiveTab] = useState('noPhones')
  let parentsArray = []
  const onError = (err) => {
    showAlert({text: err.toString(), status: 'error'}, dispatch)
  }
  const [updatePhone, {loading: loadMutation}] = useUpdateUserPhone(() => {
    showAlert({text: 'User phone updated!', status: 'success'}, dispatch)
    if (onChange) onChange(true)
  }, onError)
  const [getUserPhone, {loading: loadQuery}] = useGetUserPhone()
  const parsePhone = (phone) => {
    const dialCode = phone
      ? phone
          .split(' ')
          .slice(0, phone.split(' ')?.length - 1)
          .join(' ')
      : ''

    const phoneNumber = phone
      ? phone.split(' ')[phone.split(' ')?.length - 1]
      : ''

    return [dialCode, phoneNumber]
  }
  const updateFathersPhones = (parents) => {
    if (parents === [] || parents?.length === 0) return
    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i]
      updatePhone({
        variables: {
          id: parent.parentUserId,
          input: {phone: `${parent.prefijo} ${parent.tel}`},
        },
      })
    }
  }
  useEffect(() => {
    if (studentId) {
      getUserPhone({variables: {id: studentId}}).then((res) => {
        if (res.data?.user?.phone) {
          const [prefijo, tel] = parsePhone(res.data.user.phone)
          setStudentPhone(res?.data?.user?.phone)
          setData({...data, prefijo, tel})
        }
        if (res.data?.user?.studentInfo?.studentType === 'family') {
          // eslint-disable-next-line
          parentsArray = res.data.user.studentInfo.parents.filter((parent) => parent?.phone === null || parent?.phone?.length < 3
          )
          setData({...data, parents: parentsArray})
        }
      }, onError)
    }

    // eslint-disable-next-line
  }, [studentId])

  useEffect(() => {
    if (studentPhone) {
      if (studentPhone && (data?.parents?.length < 1 || !data?.parents)) {
        setActiveTab('withPhones')
        if (onChange) onChange(true)
        toggle()
      }
    }
    // eslint-disable-next-line
  }, [studentPhone])

  return (
    <ModalLayout
      className={`modal-centered ${
        ['SM'].includes(breakWidth) ? 'bottom-modal' : ''
      }`}
      topHead={
        <span className='text-title h2 font-weight-bold'>
          {translate('modal.onboard.payment.sendwspmodal.title')}
        </span>
      }
      isOpen={isOpen}
      toggle={toggle}
      disabledScrollAnimation
    >
      <TabContent activeTab={activeTab} className='w-100'>
        <TabPane tabId='noPhones'>
          {!(studentPhone === null || studentPhone?.length < 3) && (
            <>
              <label className='text-info'>
                {translate('modal.onboard.payment.sendwspmodal.body')}
              </label>
              <div className='w-100 d-flex flex-row'>
                <Form
                  value={data}
                  onChange={(res) => {
                    setData(res)
                  }}
                >
                  <div className='col-5 m-0 p-0' style={{alignSelf: 'center'}}>
                    <PhoneSelector
                      className='m-0 mt-n4'
                      defaultValue={data?.prefijo || ''}
                      fieldName='prefijo'
                      PlaceholderSelect={translate(
                        'home.onboard.approve.profile.dialcode',
                      )}
                      selectCode={data?.prefijo}
                      setSelectCode={(newPre) => {
                        setData({...data, prefijo: newPre})
                      }}
                    />
                  </div>
                  <div className='col-7 m-0'>
                    <Field
                      className='w-100 ml-3'
                      defaultValue={data?.tel || ''}
                      type={LabeledField}
                      component={PlainTextInput}
                      fieldName='tel'
                      placeholder='912345678'
                    />
                  </div>
                </Form>
              </div>
            </>
          )}

          {data?.parents?.length > 0 && (
            <label className='text-info'>
              {translate('modal.onboard.payment.sendwspmodal.parentsPart')}
            </label>
          )}
          {data?.parents?.length > 0 &&
            data?.parents.map((parent, index) => {
              return (
                <div className='w-100 d-flex flex-row'>
                  <div className='col-5 m-0 p-0' style={{alignSelf: 'center'}}>
                    <PhoneSelector
                      className='m-0 mt-n4'
                      defaultValue={data?.parents[index]?.prefijo || ''}
                      fieldName={`data.parents['${index}'].prefijo`}
                      PlaceholderSelect={translate(
                        'home.onboard.approve.profile.dialcode',
                      )}
                      selectCode={data?.parents[index]?.prefijo}
                      setSelectCode={(newPre) => {
                        setData({
                          ...data,
                          parents: data.parents.map((item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                prefijo: newPre,
                              }
                            } else {
                              return item
                            }
                          }),
                        })
                      }}
                    />
                  </div>
                  <div className='col-7 m-0'>
                    <Field
                      className='w-100 ml-3'
                      defaultValue={data?.parents[index]?.tel || ''}
                      value={data?.parents[index]?.tel || ''}
                      type={LabeledField}
                      component={PlainTextInput}
                      fieldName={`data.parents['${index}'].tel`}
                      placeholder='912345678'
                      onChange={(newPhone) => {
                        setData({
                          ...data,
                          parents: data.parents.map((item, idx) => {
                            if (index === idx) {
                              return {
                                ...item,
                                tel: newPhone,
                              }
                            } else {
                              return item
                            }
                          }),
                        })
                      }}
                    />
                  </div>
                </div>
              )
            })}

          <div className='w-100 d-flex flex-row align-items-center justify-content-end'>
            <span
              className='btn-link text-error'
              onClick={() => {
                onChange(false)
              }}
            >
              {translate('modal.onboard.payment.sendwspmodal.notSent')}
            </span>
            <Button
              label={translate('modal.onboard.payment.sendwspmodal.confirm')}
              activity={loadQuery || loadMutation}
              disabled={!data.prefijo || !data.tel}
              onClick={() => {
                updatePhone({
                  variables: {
                    id: studentId,
                    input: {phone: `${data.prefijo} ${data.tel}`},
                  },
                }).then((res) => {
                  updateFathersPhones(data?.parents)
                })
              }}
              color='primary'
              className='ml-3 w-40'
            />
          </div>
        </TabPane>
        <TabPane tabId='withPhones'>
          <span className='text-info'>
            {translate('modal.onboard.payment.sendwspmodal.allWithPhones')}
          </span>
          <Activity inverse className='test' />
        </TabPane>
      </TabContent>
    </ModalLayout>
  )
}

export default SetStudentPhoneModal
