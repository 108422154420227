import React, {useState, useMemo, useEffect} from 'react'
import {
  Modal,
  IconClose,
  Input,
  SelectOption,
  StickyButton,
  ListItem,
  Avatar,
  IconSignCheck,
  IconSignPlus,
  IconSignMinus,
  DropDown,
  CheckboxOption,
  IconArrowRight,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import {FormGroup} from 'reactstrap'
import SendLessonNotesMutation from '../../../../../Model/Lessons/SetLessonNotes/index.js'
import moment from 'moment'
import {showAlert} from '../../../../../common/Alert/util.js'
import useTranslation from '../../../../../i18n/useTranslation'

const SendLessonNotesModal = (props) => {
  const {isOpen, toggle, dataLesson, refetch, mutationCallBack} = props

  const timeSince = dataLesson?.endDate
    ? moment.duration(moment().diff(moment(dataLesson.endDate))).asHours()
    : moment.duration(moment().diff(moment())).asHours()
  const {translate} = useTranslation()
  const dispatch = useDispatch()

  const [form, setForm] = useState({
    didHw: null,
    newHw: false,
  })

  const {
    submit: sendLessonNotesSubmit,
    loading: loadMutation,
  } = SendLessonNotesMutation(
    (result) => {
      // actualiza calendario
      refetch()
      // setea nuevos datos de la lección en redux
      mutationCallBack(
        // datos de la lección actualizados
        result?.data?.setLessonNotes,
      )
      // cierra modal
      toggle()
      if (dataLesson?.redirectToListAfter) {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'calendar.incompleteLessonModal',
          data: {
            isOpen: true,
          },
        })
      }
      // ALERTA MUTACIÓN EJECUTADO CON EXITO
      showAlert(
        {
          text: translate('modal.sendnotes.successAlert'),
          status: 'success',
        },
        dispatch,
      )
      // reiniciar formulario
      setForm({
        didHw: null,
        newHw: false,
      })
    },
    () => {
      // ALERTA ERROR EN MUTACIÓN
      showAlert(
        {
          text: translate('modal.sendnotes.errorAlert'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  useEffect(() => {
    if (isOpen) {
      if (dataLesson?.notes) {
        setForm({
          ...dataLesson?.notes?.educatorNotes,
          dueDateHw: dataLesson?.notes?.educatorNotes?.dueDateHw
            ? moment(dataLesson?.notes?.educatorNotes?.dueDateHw).format(
                'YYYY-MM-DD',
              )
            : moment().format('YYYY-MM-DD'),
        })
      } else {
        // reiniciar formulario
        setForm({
          didHw: null,
          newHw: false,
        })
      }
    }
  }, [isOpen, dataLesson])

  const student = useMemo(() => {
    const student = dataLesson
      ? dataLesson.student || dataLesson.studentPlan || dataLesson.studentGroup
      : null
    if (!Array.isArray(student)) {
      return [student]
    }
    return student
  }, [dataLesson])

  const educator = useMemo(() => {
    return dataLesson?.substitute || dataLesson?.educator
  }, [dataLesson])
  const [educatorAttendance, setEducatorAttendance] = useState(
    educator?.attendanceStatus === 'checkIn'
      ? 'attended'
      : educator?.attendanceStatus === 'pending'
      ? 'missed'
      : educator?.attendanceStatus,
  )
  // educator?.attendanceStatus,
  const [studentAttendance, setStudentAttendance] = useState(
    student.reduce((atte, stu) => {
      return {
        ...atte,
        [stu?.studentUserId]:
          stu?.attendanceStatus === 'checkIn'
            ? 'attended'
            : stu?.attendanceStatus === 'pending'
            ? 'missed'
            : stu?.attendanceStatus,
      }
    }, {}),
  )

  useEffect(() => {
    // ACTUALIZA LOS ATTENDANCE SI SE MODIFICA dataLesson EN REDUX
    if (student && educator && isOpen) {
      setStudentAttendance(
        student.reduce((atte, stu) => {
          return {
            ...atte,
            [stu.studentUserId]:
              stu?.attendanceStatus === 'checkIn'
                ? 'attended'
                : stu?.attendanceStatus === 'pending'
                ? 'missed'
                : stu?.attendanceStatus,
          }
        }, {}),
      )
      setEducatorAttendance(
        educator?.attendanceStatus === 'checkIn'
          ? 'attended'
          : educator?.attendanceStatus === 'pending'
          ? 'missed'
          : educator?.attendanceStatus,
      )
    }
  }, [student, educator, isOpen])

  const parseDataToMutationLessonNotes = (
    data,
    lessonData,
    studentAtte,
    status,
    isGroup,
    allStudentMissed,
  ) => {
    const eduAtte =
      timeSince >= 1 && educatorAttendance === 'pending'
        ? ['checkIn', 'attended'].includes(educator.attendanceStatus)
          ? 'attended'
          : 'missed'
        : educatorAttendance
    const stuAtte = Object.keys(studentAtte).map((idStu) => {
      return {
        studentId: idStu,
        status:
          timeSince >= 1 && studentAtte[idStu] === 'pending'
            ? ['checkIn', 'attended'].includes(
                student.find((stu) => stu.studentUserId === idStu)
                  .attendanceStatus,
              )
              ? 'attended'
              : 'missed'
            : studentAtte[idStu],
      }
    })
    let obj = {}
    if (data?.cantcomplete || allStudentMissed) {
      /**
       * => cuando la leccion NO se completó
       *  attendanceStudents: [AttendanceStudentsInput!]!
          status: NoteStatus!
          lostReason: String

          acqKnowledge: String!
          newHw: Boolean!
          descriptionHw: String!
          dueDateHw: DateTime!
          others: String!
      * **/
      obj = {
        educatorAttendance: eduAtte,
        lessonNoteInput: {
          attendanceStudents: stuAtte,
          status: allStudentMissed ? status : 'draft',
          lostReason: data?.lostReason || null,
          studentEffort: '',
          acqKnowledge: '',
          newHw: false,
          descriptionHw: '',
          dueDateHw: moment().toISOString(),
          others: '',
        },
        lessonId: lessonData?.id,
      }
      /** if (data?.didHw !== null) {
        obj = {
          ...obj,
          lessonNoteInput: {
            ...obj.lessonNoteInput,
            didHw: data?.didHw,
            hadHw: true
          }
        }
      }**/
    } else {
      /**
       * => cuando la leccion se completó
       *  attendanceStudents: [AttendanceStudentsInput!]!
          status: NoteStatus!
          hadHw: Boolean
          didHw: Boolean
          score: Float
          studentEffort: String
          acqKnowledge: String!
          newHw: Boolean!
          descriptionHw: String!
          dueDateHw: DateTime!
          others: String!
          lostReason: String
      * **/
      obj = {
        educatorAttendance,
        lessonNoteInput: {
          attendanceStudents: stuAtte,
          status: status,
          studentEffort: data?.studentEffort || '',
          acqKnowledge: data?.acqKnowledge || '',
          newHw: data?.newHw || false,
          descriptionHw: data?.descriptionHw || '',
          dueDateHw: data?.dueDateHw
            ? moment(data.dueDateHw).toISOString()
            : moment().toISOString(),
          others: data?.others || '',
        },
        lessonId: lessonData?.id,
      }
      if (data?.didHw !== null && !isGroup) {
        obj = {
          ...obj,
          lessonNoteInput: {
            ...obj.lessonNoteInput,
            didHw: data?.didHw || false,
            scoreAlphanumeric: data?.scoreAlphanumeric || null,
            hadHw: true,
          },
        }
      }
    }
    return obj
  }
  const setAttendanceOfStudent = (stuId, value) => {
    setStudentAttendance({
      ...studentAttendance,
      [stuId]: value,
    })
  }
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'

  const charsAcqMin = 125
  const charsEffortMin = 100
  const charsScoreMax = 15

  // SE VUELVE true CUANDO TODOS LOS ESTUDIANTES TIENEN ATTENDANCE missed
  const allStudentMissed = Object.values(studentAttendance).reduce(
    (allMissed, stuAttendance) => {
      if (stuAttendance !== 'missed') return false
      return allMissed
    },
    true,
  )

  const validation = useMemo(() => {
    let valid = true
    if (form?.cantcomplete) {
      valid =
        valid &&
        (timeSince >= 1 ||
          Object.keys(studentAttendance).reduce((validAccu, idStu) => {
            if (studentAttendance[idStu] === 'pending') return false
            return validAccu
          }, true)) &&
        (timeSince >= 1 || educatorAttendance !== 'pending') &&
        form?.lostReason !== undefined &&
        form?.lostReason !== null &&
        form?.lostReason !== ''
    } else if (!allStudentMissed) {
      valid =
        valid &&
        (timeSince >= 1 ||
          Object.keys(studentAttendance).reduce((validAccu, idStu) => {
            if (studentAttendance[idStu] === 'pending') return false
            return validAccu
          }, true)) &&
        (timeSince >= 1 || educatorAttendance !== 'pending') &&
        form?.acqKnowledge !== undefined &&
        form?.acqKnowledge !== null &&
        charsAcqMin <= form?.acqKnowledge?.length
      if (!isGroupLesson) {
        valid =
          valid &&
          charsEffortMin <= form?.studentEffort?.length &&
          form?.studentEffort !== undefined &&
          form?.studentEffort !== null
      }
      if (form?.newHw === true) {
        valid =
          valid &&
          form?.descriptionHw !== undefined &&
          form?.descriptionHw !== null &&
          form?.dueDateHw !== undefined &&
          form?.dueDateHw !== null
      }
      if (form?.didHw !== null && !isGroupLesson) {
        valid =
          valid &&
          form?.didHw !== undefined &&
          form?.didHw !== null &&
          (form?.didHw !== true ||
            (
              form?.scoreAlphanumeric !== undefined &&
              form?.scoreAlphanumeric !== null &&
              form?.scoreAlphanumeric !== '' &&
              form?.scoreAlphanumeric?.length <= charsScoreMax
            )
          )
      }
    }
    return valid
  }, [
    form,
    studentAttendance,
    educatorAttendance,
    isGroupLesson,
    allStudentMissed,
    timeSince,
  ])

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-4 d-flex flex-row justify-content-between'>
          <span className='h2 font-weight-bold'>
            {translate('modal.sendnotes.title')}
          </span>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <FormGroup className='w-100 d-flex flex-column'>
          <div className='d-flex flex-row my-4 pl-2'>
            <CheckboxOption
              name='cantcomplete'
              onChange={(e) => {
                setForm({
                  ...form,
                  cantcomplete: e,
                })
              }}
            />
            <span>{translate('modal.sendnotes.cantcomplete')}</span>
          </div>
          <div className='borderTop' />
          <div>
            {student.map((stu, index) => {
              return (
                <ListItem
                  showBottomLine={false}
                  showTopLine={false}
                  className='py-3'
                  Left={
                    <div className='d-flex flex-row'>
                      <Avatar
                        name={`${stu?.firstName} ${stu?.lastName} `}
                        named={false}
                        uri={null}
                        size='avatar-small mr-1'
                      />
                      <span className='h45 ml-2 text-dark align-self-center'>
                        {`${stu?.firstName} ${stu?.lastName} `}
                        <small className='text-muted' />
                      </span>
                    </div>
                  }
                  Right={
                    <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                      <DropDown
                        direction='bottom'
                        fixedWidth
                        hoverEnabled={false}
                        closeMenuWhenClick
                        disabledDropdown={timeSince >= 1}
                        options={[
                          {
                            component: (
                              <div className='d-flex flex-row m-0 py-0 px-1'>
                                <div
                                  className='hover-icon'
                                  onClick={() => {
                                    setAttendanceOfStudent(
                                      student[index].studentUserId,
                                      'missed',
                                    )
                                  }}
                                >
                                  <IconSignPlus color='red' />
                                </div>
                                <div
                                  className='hover-icon'
                                  onClick={() => {
                                    setAttendanceOfStudent(
                                      student[index].studentUserId,
                                      'pending',
                                    )
                                  }}
                                >
                                  <IconSignMinus color='#72809D' />
                                </div>
                                <div
                                  className='hover-icon'
                                  onClick={() => {
                                    setAttendanceOfStudent(
                                      student[index].studentUserId,
                                      'attended',
                                    )
                                  }}
                                >
                                  <IconSignCheck color='#8FC813' />
                                </div>
                              </div>
                            ),
                          },
                        ]}
                      >
                        <div
                          className={`py-1 px-2 ${
                            timeSince >= 1 ? '' : 'hover-icon'
                          }`}
                        >
                          {['checkIn', 'pending'].includes(
                            studentAttendance[stu?.studentUserId],
                          ) ? (
                            <IconSignMinus color='#72809D' />
                          ) : studentAttendance[stu?.studentUserId] ===
                            'attended' ? (
                            <IconSignCheck color='#8FC813' />
                          ) : (
                            <IconSignPlus color='red' />
                          )}
                        </div>
                      </DropDown>
                    </div>
                  }
                />
              )
            })}
            {educator && (
              <ListItem
                showBottomLine={false}
                showTopLine={false}
                className='py-3'
                Left={
                  <div className='d-flex flex-row'>
                    <Avatar
                      name={`${educator?.firstName} ${educator?.lastName} `}
                      named={false}
                      uri={null}
                      size='avatar-small mr-1'
                    />
                    <span className='h45 ml-2 text-dark align-self-center'>
                      {`${educator?.firstName} ${educator?.lastName} `}
                      <span className='h5 text-gray'>{`(${translate(
                        'modal.infolesson.educator',
                      )})`}</span>
                      <small className='text-muted' />
                    </span>
                  </div>
                }
                Right={
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick
                      disabledDropdown={timeSince >= 1}
                      options={[
                        {
                          component: (
                            <div className='d-flex flex-row m-0 py-0 px-1'>
                              <div
                                className='hover-icon'
                                onClick={() => {
                                  setEducatorAttendance('missed')
                                }}
                              >
                                <IconSignPlus color='red' />
                              </div>
                              <div
                                className='hover-icon'
                                onClick={() => {
                                  setEducatorAttendance('pending')
                                }}
                              >
                                <IconSignMinus color='#72809D' />
                              </div>
                              <div
                                className='hover-icon'
                                onClick={() => {
                                  setEducatorAttendance('attended')
                                }}
                              >
                                <IconSignCheck color='#8FC813' />
                              </div>
                            </div>
                          ),
                        },
                      ]}
                    >
                      <div
                        className={`py-1 px-2 ${
                          timeSince >= 1 ? '' : 'hover-icon'
                        }`}
                      >
                        {['checkIn', 'pending'].includes(educatorAttendance) ? (
                          <IconSignMinus color='#72809D' />
                        ) : educatorAttendance === 'attended' ? (
                          <IconSignCheck color='#8FC813' />
                        ) : (
                          <IconSignPlus color='red' />
                        )}
                      </div>
                    </DropDown>
                  </div>
                }
              />
            )}
            <div className='borderTop' />
          </div>
          {!allStudentMissed ? (
            form.cantcomplete ? (
              <div className='w-100'>
                <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                  {translate('modal.sendnotes.reason')}
                </label>
                <textarea
                  onChange={(e) => {
                    setForm({...form, lostReason: e.target.value})
                  }}
                  value={form.lostReason}
                  placeholder='Type here'
                  className='znk-input w-100 p-3'
                  style={{height: '150px'}}
                />
              </div>
            ) : (
              <>
                {!isGroupLesson && (
                  <>
                    <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                      {translate('modal.sendnotes.didhw')}
                    </label>
                    <SelectOption
                      className='col-12 row inline'
                      name='didhw'
                      showNotSelected={false}
                      onChange={(e) => {
                        setForm({...form, didHw: e})
                      }}
                      options={[
                        {id: true, text: translate('modal.sendnotes.yes')},
                        {id: false, text: 'No'},
                        {id: null, text: 'N/A'},
                      ]}
                      value={form.didHw}
                      StyleOptionClassName={{
                        width: '32%',
                        whiteSpace: 'nowrap',
                      }}
                      StyleSelect={{
                        justifyContent: 'space-between',
                      }}
                    />
                    <div className='mx-0 my-3 p-0 d-flex flex-row'>
                      <div className='w-100 d-flex flex-column'>
                        <span className='p-0 text-gray'>
                          {translate('modal.sendnotes.score')}
                        </span>
                        <Input
                          disabled={form.didHw === null || form.didHw === false}
                          type='string'
                          name='scoreAlphanumeric'
                          placeholder={translate('modal.sendnotes.score')}
                          value={form.scoreAlphanumeric}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              scoreAlphanumeric: e.target.value,
                            })
                          }}
                        />
                         {
                            charsScoreMax < (form?.scoreAlphanumeric?.length || 0) &&
                            <span className='h5 text-error'>{`${translate('modal.sendnotes.charsMax', {
                                num: ((form?.scoreAlphanumeric?.length || 0) - charsScoreMax)
                            })}`}
                            </span>
                          }
                      </div>
                    </div>
                    <div className='borderTop my-3' />
                  </>
                )}
                <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                  {translate('modal.sendnotes.nexthw')}
                </label>
                <SelectOption
                  className='col-12 row inline'
                  name='newHw'
                  showNotSelected={false}
                  onChange={(e) => {
                    setForm({...form, newHw: e})
                  }}
                  options={[
                    {id: true, text: translate('modal.sendnotes.yes')},
                    {id: false, text: 'No'},
                  ]}
                  value={form.newHw}
                  StyleOptionClassName={{
                    width: '49%',
                    whiteSpace: 'nowrap',
                  }}
                  StyleSelect={{
                    justifyContent: 'space-between',
                  }}
                />
                <div className='mx-0 my-3 p-0 d-flex flex-row'>
                  <div className='w-100 d-flex flex-column'>
                    <span className='p-0 text-gray'>
                      {translate('modal.sendnotes.titlelabel')}
                    </span>
                    <Input
                      disabled={form.newHw === false}
                      type='text'
                      name='title'
                      value={form.descriptionHw}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          descriptionHw: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='mx-0 my-3 p-0 d-flex flex-row'>
                  <div className='w-100 d-flex flex-column'>
                    <span className='p-0 text-gray'>
                      {translate('modal.sendnotes.duedate')}
                    </span>
                    <Input
                      disabled={form.newHw === false}
                      type='date'
                      name='duedate'
                      value={form.dueDateHw}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          dueDateHw: e.target.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='borderTop my-3' />
                <div className='w-100'>
                  <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                    {translate('modal.sendnotes.acqknowledge')}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setForm({...form, acqKnowledge: e.target.value})
                    }}
                    value={form.acqKnowledge}
                    placeholder={translate('modal.sendnotes.typehere')}
                    className='znk-input w-100 p-3'
                    style={{height: '150px'}}
                  />
                  {charsAcqMin > (form?.acqKnowledge?.length || 0) && (
                    <span className='h5 text-error'>
                      {`${translate('modal.sendnotes.charsLeft', {
                        num: charsAcqMin - (form?.acqKnowledge?.length || 0),
                      })}`}
                    </span>
                  )}
                </div>
                {!isGroupLesson && (
                  <div className='w-100'>
                    <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                      {translate('modal.sendnotes.studenteffort')}
                    </label>
                    <textarea
                      onChange={(e) => {
                        setForm({...form, studentEffort: e.target.value})
                      }}
                      value={form.studentEffort}
                      placeholder={translate('modal.sendnotes.typehere')}
                      className='znk-input w-100 p-3'
                      style={{height: '150px'}}
                    />
                    {charsEffortMin > (form?.studentEffort?.length || 0) && (
                      <span className='h5 text-error'>
                        {`${translate('modal.sendnotes.charsLeft', {
                          num:
                            charsEffortMin - (form?.studentEffort?.length || 0),
                        })}`}
                      </span>
                    )}
                  </div>
                )}
                <div className='w-100'>
                  <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                    {translate('modal.sendnotes.othercomments')}
                  </label>
                  <textarea
                    onChange={(e) => {
                      setForm({...form, others: e.target.value})
                    }}
                    value={form.others}
                    placeholder={translate('modal.sendnotes.typehere')}
                    className='znk-input w-100 p-3'
                    style={{height: '150px'}}
                  />
                  <span className='h5 text-error'>
                    {translate('modal.sendnotes.optional')}
                  </span>
                </div>
              </>
            )
          ) : (
            <span className='d-flex flex-row justify-content-center h4 mt-4 mb-2'>
              {translate('modal.sendnotes.lessonNoteMissed')}
            </span>
          )}
          {!form?.cantcomplete && (
            <Button
              disabled={false}
              activity={loadMutation}
              onClick={() => {
                sendLessonNotesSubmit(
                  parseDataToMutationLessonNotes(
                    form,
                    dataLesson,
                    studentAttendance,
                    'draft',
                    isGroupLesson,
                    allStudentMissed,
                  ),
                )
              }}
              className='w-100 mt-4 mb-2'
              color='input'
              size='small'
              label={
                <div className='d-flex flex-row justify-content-between align-items-center m-0'>
                  <span className='h45' style={{color: '#7079FF'}}>
                    {translate('modal.sendnotes.saveindraft')}
                  </span>
                  <IconArrowRight size={15} color='#7079FF' />
                </div>
              }
            />
          )}
        </FormGroup>
        <StickyButton
          disabled={!validation}
          onClickButtonFunction={() => {
            sendLessonNotesSubmit(
              parseDataToMutationLessonNotes(
                form,
                dataLesson,
                studentAttendance,
                'sent',
                isGroupLesson,
                allStudentMissed,
              ),
            )
          }}
          activity={loadMutation}
          color='#696CFF'
          className='w-100 mb-6'
          size='large'
          label={
            <div className='d-flex flex-row justify-content-between'>
              <span>
                {form.cantcomplete
                  ? translate('modal.sendnotes.endlesson')
                  : translate('modal.sendnotes.sendnotes')}
              </span>
            </div>
          }
        />
      </div>
    </Modal>
  )
}

export default SendLessonNotesModal
