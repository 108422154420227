import getEnv from '../../../../helpers/getEnv'

const urls = {
  local: 'http://localhost:3000',
  // local: 'https://dev.api2.zinkerz.com',
  beta: 'https://dev.api2.zinkerz.com',
  production: 'https://api2.zinkerz.com',
}

const env = getEnv()
const url = urls[env]
console.log('*******', env)
if (!url) {
  throw new Error(`There is no defined url for env ${env}`)
}

export default url
