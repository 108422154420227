import React, {useMemo} from 'react'

const ProgressBar = (props) => {
  const {arrayScheduled} = props
  const progress = useMemo(() => {
    const notNulls = arrayScheduled.filter((time) => time !== null)
    return notNulls.length / arrayScheduled.length
  }, [arrayScheduled])
  return (
    <div
      style={{
        width: (progress * 100).toString() + '%',
        backgroundColor: '#696CFF',
        height: '4px',
        transition: '0.5s',
      }}
    />
  )
}

ProgressBar.propTypes = {}
ProgressBar.defaultProps = {}

export default ProgressBar
