import React from 'react'
import {
  Avatar,
  BlankSpace,
  AvatarBlank,
  ToolTipInfo,
  IconVideo,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'
import useScheduledMeetingModal from '../../ScheduledMeeting/useScheduledMeetingModal'

const Team = (props) => {
  const {loadingTeam, dataTeam} = props
  const {translate} = useTranslation()
  const {
    open: openScheduledMeeting,
    close: closeScheduledMeeting,
  } = useScheduledMeetingModal()
  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-start'>
      <span className='h4 mr-6 mt-4' style={{lineHeight: '1.6'}}>
        {translate('counseling.yourTeam')}
      </span>

      <TabContent
        activeTab={
          loadingTeam ? 'loading' : dataTeam?.length > 0 ? 'show' : 'blank'
        }
      >
        <TabPane tabId='loading'>
          <div className='d-flex flex-column'>
            {new Array(3).fill(0).map(() => {
              return (
                <div className='d-flex flex-row m-0 p-0 align-items-center'>
                  <Skeleton
                    count={1}
                    width={60}
                    height={60}
                    style={{borderRadius: '19px'}}
                  />
                  <div className='d-flex flex-column w-70 ml-2'>
                    <Skeleton count={1} className='w-100' height={15} />
                    <Skeleton count={1} className='w-80' height={13} />
                  </div>
                </div>
              )
            })}
          </div>
        </TabPane>
        <TabPane tabId='show'>
          <div className='d-flex flex-column'>
            {dataTeam?.map((staff) => (
              <div className='d-flex flex-row justify-content-between align-items-center m-0 p-0'>
                <div className='d-flex flex-row'>
                  <Avatar
                    uri={staff?.avatar || ''}
                    name={staff?.firstName + ' ' + staff?.lastName}
                    size='avatar-large'
                  />
                  <div className='m-2'>
                    <span>{staff?.firstName + ' ' + staff?.lastName}</span>
                    <span className='blockquote-footer'>
                      {staff?.role.charAt(0).toUpperCase() +
                        staff?.role.slice(1)}
                    </span>
                  </div>
                </div>
                <ToolTipInfo
                  tooltipId={`scheduleMeeting-${staff.teamUserId}`}
                  innerElement={<span>Schedule meeting</span>}
                >
                  <div
                    className='hover-icon'
                    onClick={() => {
                      openScheduledMeeting(
                        () => {
                          closeScheduledMeeting()
                        },
                        null,
                        {
                          id: staff?.teamUserId,
                        },
                        true /** useMutation**/,
                      )
                    }}
                  >
                    <IconVideo size={15} />
                  </div>
                </ToolTipInfo>
                {/**
                   * <DropDownNew
                  direction='bottomLeft'
                  classNameMenu='m-0 p-0'
                  options={[
                    {
                      component: (
                        <DropDownItem
                          onClick={() => {
                          }}
                          className='m-0 d-flex flex-row align-items-center'
                        >
                          <IconVideo size={15} className='mr-1' /> Scheduled
                          meeting
                        </DropDownItem>
                      ),
                    },
                  ]}
                >
                  <div className='hover-icon'>
                    <IconOptions size={15} className='text-gray rotate-90' />
                  </div>
                </DropDownNew> 
                   * **/}
              </div>
            ))}
          </div>
        </TabPane>
        <TabPane tabId='blank'>
          <BlankSpace
            textClassName='pt-3'
            className='w-100 py-5 mt-4'
            mainText={
              <span className='h45'>
                {translate('counseling.yourTeamBlank')}
              </span>
            }
            icon={<AvatarBlank size='large' iconSize={20} />}
          />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default Team
