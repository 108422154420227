import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {SET_SOCKET_CONNECTION} from '../../store/socket/const'
import useSocketEntities from './useSocketEntities'

const SocketGateway = () => {
  const {emit, connection: socketConnection} = useSocketEntities()
  const dispatch = useDispatch()
  useEffect(() => {
    socketConnection.on('updateClient', (data) => {
      if (data?.entities) {
        // agrega las entidades a redux
        // data.entities objeto con llaves task, university o docs con arreglos de ids
        // correspondientes a los registros actualizados
        emit(data.entities)
      }
    })
    dispatch({
      type: SET_SOCKET_CONNECTION,
      socket: socketConnection,
    })
    // return socket.close()
    // eslint-disable-next-line
    }, [socketConnection, dispatch])
  return <></>
}

export default SocketGateway
