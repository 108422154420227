import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'

const ErrorMessageModal = (props) => {
  const {isOpen, toggle, message = '', messageBody = ''} = props

  return (
    <ConfirmModal
      classNameActionDiv='d-flex flex-column'
      classNameConfirm='text-primary white-space-nowrap'
      isOpen={isOpen}
      toggle={toggle}
      title={message}
      body={messageBody}
      confirmAction={() => {
        toggle()
      }}
      confirmText='Ok'
    />
  )
}

export default ErrorMessageModal
