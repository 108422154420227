import {gql} from '@apollo/client'

export default gql`
  query {
    me {
      id
      paymentMethod {
        id
        kind
        ... on CardPaymentMethod {
          cardInfo {
            last4
            brand
            expYear
            expMonth
          }
        }
        ... on BankDebitPaymentMethod {
          validationStatus
          accountInfo {
            bankName
            last4
          }
        }
        ... on BankCreditPaymentMethod {
          status
          sourceInfo {
            bankName
            accountNumber
            swiftCode
            routingNumber
          }
        }
        ... on ManualPaymentMethod {
          manualPaymentInfo {
            bankName
            accountNumber
            routingNumber
            beneficiary
            address
            swiftCode
          }
        }
        ... on ZellePaymentMethod {
          kind
          zellePaymentInfo {
            emailToSend
          }
        }
      }
      emails {
        address
        verified
      }
      countryId
      gender
      spokenLanguages
      address
      city
      state
      zipCode
      firstName
      lastName
      phone
      timezone
      birthdate
      studentInfo {
        institution
        graduationDate
        gpa
        aditionalInfo
        parents {
          firstName
          lastName
          managePayments
          email
          phone
        }
        notifications {
          missedLesson
          lessonsNotesReady
          lessonReminder {
            isAvailable
            duration
          }
        }
        homeNotification {
          tasksPending {
            id
            status
            title
            startDate
            dueDate
            task
          }
          lessonsPending {
            id
            startDate
            endDate
            service {
              id
              name
            }
            topic {
              id
              name
            }
            educator {
              firstName
              lastName
            }
            substitute {
              firstName
              lastName
            }
          }
          invoicesPending {
            id
            invoiceNumber
            amount
            to
          }
          approvalPlans {
            _id
            from
            to
            name
          }
        }
      }
      roles
    }
  }
`
