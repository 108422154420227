import React from 'react'
import {Alert} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
const ArrayAlert = () => {
  const stack = useSelector((state) => state.alert.stack)
  return (
    <div
      className='d-flex flex-column align-items-start m-0 p-0 position-fixed'
      style={{bottom: '10px', left: '10px', zIndex: 10000}}
    >
      {stack.map(({text, status, onClick, unreadMsgs}) => (
        <Alert
          text={text}
          status={status}
          onClick={onClick}
          unreadMsgs={unreadMsgs}
        />
      ))}
    </div>
  )
}
export default ArrayAlert
