import React from 'react'
import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'
import {useDispatch, useSelector} from 'react-redux'
import {useMutation, gql} from '@apollo/client'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {ConfirmModal, Activity} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'

const ConfirmSetBraincertToLesson = (props) => {
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {refetchLesson} = props
  const reduxData = useSelector(
    (state) => state.modal.calendar.setBraincertToLesson,
  )
  const {isOpen, lessonId, isOrientation} = reduxData
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'calendar.setBraincertToLesson',
      data: {
        ...reduxData,
        isOpen: !isOpen,
      },
    })
  }
  const [setBraincert, {loading}] = useMutation(
    gql`
            mutation update($lessonId: String!) {
                setBraincertToLesson(lessonId: $lessonId) {
                    ${queryLessonResult}
                }
            }
        `,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
  )
  const isOrientationConfirmFunction = (lesson) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'student.orientationLessonModal',
      data: {
        isOpen: true,
        dataLesson: lesson,
        idLesson: lesson?.id,
      },
    })
    refetchLesson()
  }

  return (
    <ConfirmModal
      className=''
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('modal.setbraincert.title')}
      body={translate('modal.setbraincert.body')}
      confirmAction={() => {
        setBraincert({
          variables: {lessonId},
        }).then(
          ({data: {setBraincertToLesson}}) => {
            isOrientation
              ? isOrientationConfirmFunction(setBraincertToLesson)
              : dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'calendar.infoLessonModal',
                  data: {
                    isOpen: true,
                    dataLesson: setBraincertToLesson,
                  },
                })
            toggle()
          },
          () => {
            alert('error')
          },
        )
      }}
      cancelAction={() => {}}
      confirmText={
        loading ? <Activity /> : translate('modal.setbraincert.confirm')
      }
      cancelText={null}
    />
  )
}

export default ConfirmSetBraincertToLesson
