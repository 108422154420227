import {gql, useMutation} from '@apollo/client'
import {
  Modal,
  useWindowDimensions,
  Field,
  Form,
  IconClose,
  LabeledField,
  PlainTextInput,
  Button,
  PhoneSelector,
} from '@Knowledge-OTP/znk-ui-components'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ModalHeader, FormGroup, Label} from 'reactstrap'
import {isEmpty, validate} from 'validate.js'
import useTranslation from '../../../../i18n/useTranslation'
import {TOGGLE_MODAL_ACTION} from '../../../../store/modal/const'
import meQuery from '../../../../queries/me'
import {showAlert} from '../../../../common/Alert/util'
// import escapeRegExp from 'lodash/escapeRegExp'

const FamilyModalForm = (props) => {
  const {studentInfo, id: userId} = props
  const isOpen = useSelector((state) => state.modal.profile.familyInfo)
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const [form, setForm] = useState({newParents: []})
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.familyInfo'})
  }
  useEffect(() => {
    if (userId && studentInfo) {
      const aux = studentInfo.parents.reduce(
        (accumulator, element, index) => ({
          ...accumulator,
          [element.email]: {
            ...element,
            phoneDialCode: element.phone ? element.phone.split(' ')[0] : '',
            phoneNumber: element.phone ? element.phone.split(' ')[1] : '',
            isDelete: false,
            errorFirst: '',
            errorLast: '',
            errorCode: '',
            errorNumber: '',
            errorPayment: '',
            errorApproval: '',
          },
        }),
        {},
      )
      setForm({parents: aux, newParents: []})
    }
    // eslint-disable-next-line
  }, [studentInfo])
  // const {submit, loading} = addSetParentsToStudent((result) => {
  //   // refetchUser(userId)
  //   // if (actionAfterSubmit) actionAfterSubmit()
  //   toggle()
  // }, console.log)
  const selectCodeFn = (code, key) => {
    setForm({
      ...form,
      parents: {
        ...form.parents,
        [key]: {...form.parents[key], phoneDialCode: code},
      },
    })
  }
  const [updInfo, {loading}] = useMutation(
    gql`
      mutation($input: CreateParentInput!, $userStudentId: ID!) {
        addSetParentsToStudent(input: $input, userStudentId: $userStudentId) {
          id
          paymentMethod {
            ... on CardPaymentMethod {
              last4
              brand
              expYear
              expMonth
            }
          }
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            aditionalInfo
            parents {
              firstName
              lastName
              managePayments
              manageApprovals
              email
              parentUserId
              phone
            }
          }
          roles
        }
      }
    `,
    {
      update: (cache, {data}) => {
        cache.writeQuery({
          query: meQuery,
          data: {me: data.setMyStudentInfo},
        })
      },
    },
  )
  const onSubmit = async () => {
    const parentOld = Object.values(form.parents).map((element) => {
      return {
        userParentId: element.parentUserId,
        email: element.email,
        firstName: element.firstName,
        lastName: element.lastName,
        phone: element.phoneDialCode.trimEnd() + ' ' + element.phoneNumber,
        isNewParent: false,
        isDelete: element.isDelete,
        managePayments: element.managePayments,
        manageApprovals: element.manageApprovals,
      }
    })
    const parentNew = Object.values(form.newParents)
      .filter((e) => e.isDelete === false)
      .map((element) => {
        return {
          email: element.email,
          firstName: element.firstName,
          lastName: element.lastName,
          phone: element.phoneDialCode.trimEnd() + ' ' + element.phoneNumber,
          isNewParent: true,
          isDelete: false,
          managePayments: element.managePayments,
          manageApprovals: element.manageApprovals,
        }
      })
    const newVal = {
      parents: parentOld.concat(parentNew),
      userStudentId: userId,
    }

    let hasError = false
    const parentsError = Object.values(form.parents)
      .filter((e) => e.isDelete === false)
      .reduce((items, current, index) => {
        if (
          !hasError &&
          (isEmpty(current.firstName) ||
            isEmpty(current.lastName) ||
            isEmpty(current.phoneNumber) ||
            isEmpty(current.phoneDialCode))
        )
          hasError = true
        return {
          ...items,
          [index]: {
            ...current,
            errorFirst: isEmpty(current.firstName) ? 'first' : '',
            errorLast: isEmpty(current.lastName) ? 'last' : '',
            errorCode: isEmpty(current.phoneDialCode) ? 'phoneDialCode' : '',
            errorNumber: isEmpty(current.phoneNumber) ? 'phoneNumber' : '',
            errorPayment: '',
            errorApproval: '',
          },
        }
      }, {})
    const newParentsError = Object.values(form.newParents)
      .filter((e) => e.isDelete === false)
      .map((e) => {
        if (
          !hasError &&
          (validate({from: e.email}, {from: {email: true}}) !== undefined ||
            isEmpty(e.firstName) ||
            isEmpty(e.lastName) ||
            isEmpty(e.phoneNumber) ||
            isEmpty(e.phoneDialCode))
        )
          hasError = true
        return {
          ...e,
          errorFirst: isEmpty(e.firstName) ? 'first' : '',
          errorLast: isEmpty(e.lastName) ? 'last' : '',
          errorCode: isEmpty(e.phoneNumber) ? 'phoneNumber' : '',
          errorNumber: isEmpty(e.phoneNumber) ? 'phoneNumber' : '',
          errorPayment: '',
          errorApproval: '',
          errorEmail:
            validate({from: e.email}, {from: {email: true}}) === undefined
              ? ''
              : 'email',
        }
      })
    if (hasError) {
      setForm({
        ...form,
        parents: parentsError,
        newParents: newParentsError,
      })
      return
    }
    try {
      await updInfo({
        variables: {input: {parents: newVal.parents}, userStudentId: userId},
      })
      showAlert({
          text: translate('profile.actions.newParent.success'),
          status: 'success',
        }, dispatch )
        toggle()
    } catch (e) {
      showAlert(
        {
          text: translate('profile.actions.newParent.error'),
          status: 'error',
        }, 
        dispatch
      )
    }
    // submit({input: {parents: newVal.parents}, userStudentId: userId})
  }
  const addParent = () => {
    const parent = {
      firstName: '',
      lastName: '',
      email: '',
      phoneDialCode: '',
      phoneNumber: '',
      managePayments: false,
      manageApprovals: false,
      isDelete: false,
      errorFirst: '',
      errorLast: '',
      errorCode: '',
      errorNumber: '',
      errorPayment: '',
      errorApproval: '',
      errorEmail: '',
    }
    setForm({
      ...form,
      newParents: form.newParents.concat(parent),
    })
  }
  const deleteParent = (type, idx) => {
    switch (type) {
      case 'Old':
        setForm({
          ...form,
          parents: {
            ...form.parents,
            [idx]: {...form.parents[idx], isDelete: true},
          },
        })
        break
      default:
        setForm({
          ...form,
          // newParents: {
          //   ...form.newParents,
          //   [idx]: {...form.newParents[idx], isDelete: true},
          // },
          newParents: form.newParents.filter((e, index) => index !== idx),
        })
        break
    }
  }
  return (
    <span>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        isOpen={isOpen}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        toggle={toggle}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
            className='mt-md-4 pl-0'
          >
            <span className='h2 font-weight-bold mr-4'>
              {translate('profile.familyInfo')}
            </span>
          </ModalHeader>
        }
      >
        <Form value={form} onChange={setForm}>
          {form.parents &&
            Object.values(form.parents)
              .filter((e) => e.isDelete === false)
              .map((parent, index) => {
                return (
                  <div key={index}>
                    {index > 0 && <hr />}
                    <FormGroup className='mt-7 mb-0'>
                      <Label>Parent name</Label>
                      <div className='row'>
                        <div className='col-6 pr-0'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            placeholder={translate(
                              'profile.actions.editPersonalInfo.firstName',
                            )}
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            labelContainerClassName='mb-0'
                            onChange={(e) => {
                              setForm({
                                ...form,
                                parents: {
                                  ...form.parents,
                                  [parent.email]: {...parent, firstName: e},
                                },
                              })
                            }}
                            value={parent.firstName}
                          />
                          {parent.errorFirst !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.firstName',
                              )}
                            </h5>
                          )}
                        </div>
                        <div className='col-6 pl-1'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            placeholder={translate(
                              'profile.actions.editPersonalInfo.lastName',
                            )}
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            labelContainerClassName='mb-0'
                            value={parent.lastName}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                parents: {
                                  ...form.parents,
                                  [parent.email]: {...parent, lastName: e},
                                },
                              })
                            }}
                          />
                          {parent.errorLast !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.lastName',
                              )}
                            </h5>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className='mt-5'>
                      <Label>
                        {translate('profile.actions.editPersonalInfo.email')}
                      </Label>
                      <Field
                        type={LabeledField}
                        component={PlainTextInput}
                        disabled
                        value={parent.email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        {translate('profile.actions.editPersonalInfo.phone')}
                      </Label>
                      <div className='row'>
                        <div className='col-6 pr-1'>
                          <PhoneSelector
                            fieldName={`parents['${parent.email}'].phoneDialCode`}
                            defaultValue={
                              form.parents[parent.email].phoneDialCode || ''
                            }
                            selectCode={form.parents.phoneDialCode}
                            setSelectCode={selectCodeFn}
                            email={form.parents[parent.email].email}
                            multiple
                          />
                          {/* <Field
                            type={InputSelect}
                            component={Select}
                            fieldName={`parents['${parent.email}'].phoneDialCode`}
                            defaultSelected={[parent.phoneDialCode]}
                            options={Countries.countryArr.map((item, _) => ({
                              label: item.dialCode,
                              value: item.dialCode,
                            }))}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                parents: {
                                  ...form.parents,
                                  [parent.email]: {
                                    ...parent,
                                    phoneDialCode: e,
                                  },
                                },
                              })
                            }}
                            filterOption={(obj, string) => {
                              if (
                                new RegExp(escapeRegExp(string), 'i').test(
                                  obj.label,
                                )
                              )
                                return true
                              return false
                            }}
                          /> */}
                          {parent.errorCode !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.phoneCode',
                              )}
                            </h5>
                          )}
                        </div>
                        <div className='col-6 pl-0'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            fieldName='phoneNumber'
                            placeholder='12345621'
                            value={parent.phoneNumber}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                parents: {
                                  ...form.parents,
                                  [parent.email]: {...parent, phoneNumber: e},
                                },
                              })
                            }}
                          />
                          {parent.errorNumber !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.phoneNumber',
                              )}
                            </h5>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    {/* <div className='row'>
                        <div className='col-6 pr-1'>
                          <FormGroup check className=''>
                            <Label check>
                              <Input
                                type='checkbox'
                                fieldName='managePayments'
                                defaultChecked={parent.managePayments}
                                checked={parent.managePayments}
                                onChange={(e) => {
                                  changeManagePayment(parent)
                                }}
                              />{' '}
                              Manage payments
                            </Label>
                            {parent.errorPayment !== '' && (
                              <h5 className='text-error'>
                                {translate(
                                  'profile.actions.editFamilyInfo.errors.errorPayment',
                                )}
                              </h5>
                            )}
                          </FormGroup>
                        </div>
                        <div className='col-6 pr-1'>
                          <FormGroup check className=''>
                            <Label check>
                              <Input
                                type='checkbox'
                                fieldName='manageApprovals'
                                defaultChecked={parent.manageApprovals}
                                checked={parent.manageApprovals}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    parents: {
                                      ...form.parents,
                                      [parent.email]: {
                                        ...parent,
                                        manageApprovals: !parent.manageApprovals,
                                      },
                                    },
                                  })
                                }}
                              />{' '}
                              Manage approvals
                            </Label>
                            {parent.errorPayment !== '' && (
                              <h5 className='text-error'>
                                {translate(
                                  'profile.actions.editFamilyInfo.errors.errorApprovals',
                                )}
                              </h5>
                            )}
                          </FormGroup>
                        </div>
                      </div> */}
                    {/* <div className='d-flex flex-row justify-content-end mt-3'>
                        <h5
                          className='text-error cursor'
                          onClick={() => {
                            deleteParent('Old', parent.email)
                          }}
                        >
                          Remove this parent
                        </h5>
                      </div> */}
                  </div>
                )
              })}
          {form.newParents &&
            Object.values(form.newParents)
              .filter((e) => e.isDelete === false)
              .map((element, index) => {
                return (
                  <div key={index}>
                    <hr />
                    <FormGroup className='mt-7 mb-0'>
                      <Label>Parent name</Label>
                      <div className='row'>
                        <div className='col-6 pr-0'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            placeholder={translate(
                              'profile.actions.editPersonalInfo.firstName',
                            )}
                            style={{
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            }}
                            labelContainerClassName='mb-0'
                            onChange={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map((item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      firstName: e,
                                    }
                                  } else {
                                    return item
                                  }
                                }),
                              })
                            }}
                            value={element.firstName}
                          />
                          {element.errorFirst !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.firstName',
                              )}
                            </h5>
                          )}
                        </div>
                        <div className='col-6 pl-1'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            placeholder={translate(
                              'profile.actions.editPersonalInfo.lastName',
                            )}
                            style={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            labelContainerClassName='mb-0'
                            value={element.lastName}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map((item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      lastName: e,
                                    }
                                  } else {
                                    return item
                                  }
                                }),
                              })
                            }}
                          />
                          {element.errorLast !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.lastName',
                              )}
                            </h5>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className='mt-5'>
                      <Label>
                        {translate('profile.actions.editPersonalInfo.email')}
                      </Label>
                      <Field
                        type={LabeledField}
                        component={PlainTextInput}
                        value={element.email}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            newParents: form.newParents.map((item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  email: e,
                                }
                              } else {
                                return item
                              }
                            }),
                          })
                        }}
                      />
                      {element.errorEmail !== '' && (
                        <h5 className='text-error'>
                          {translate(
                            'profile.actions.editFamilyInfo.errors.lastName',
                          )}
                        </h5>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label>
                        {translate('profile.actions.editPersonalInfo.phone')}
                      </Label>
                      <div className='row'>
                        <div className='col-6 pr-1'>

                          <PhoneSelector
                            fieldName={`newParents['${index}'].phoneDialCode`}
                            defaultValue={
                              form.newParents &&
                              form.newParents[index] &&
                              form.newParents[index].phoneDialCode
                                ? form.newParents[index].phoneDialCode
                                : ''
                            }
                            selectCode={
                              form.newParents &&
                              form.newParents[index] &&
                              form.newParents[index].phoneDialCode
                                ? form.newParents[index].phoneDialCode
                                : ''
                            }
                            setSelectCode={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map((item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneDialCode: e,
                                    }
                                  } else {
                                    return item
                                  }
                                }),
                              })
                            }}
                            email={
                              form.newParents &&
                              form.newParents[index] &&
                              form.newParents[index].email
                                ? form.newParents[index].email
                                : ''
                            }
                            multiple
                          />
                          {/* <Field
                            type={InputSelect}
                            component={Select}
                            defaultSelected={[element.phoneDialCode]}
                            fieldName={`newParents['${index}'].phoneDialCode`}
                            options={Countries.countryArr.map((item, _) => ({
                              label: item.dialCode,
                              value: item.dialCode,
                            }))}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map((item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneDialCode: e,
                                    }
                                  } else {
                                    return item
                                  }
                                }),
                              })
                            }}
                            filterOption={(obj, string) => {
                              if (
                                new RegExp(escapeRegExp(string), 'i').test(
                                  obj.label,
                                )
                              )
                                return true
                              return false
                            }}
                          /> */}
                          {element.errorCode !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.phoneCode',
                              )}
                            </h5>
                          )}
                        </div>
                        <div className='col-6 pl-0'>
                          <Field
                            type={LabeledField}
                            component={PlainTextInput}
                            fieldName='phoneNumber'
                            placeholder='12345621'
                            value={element.phoneNumber}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map((item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneNumber: e,
                                    }
                                  } else {
                                    return item
                                  }
                                }),
                              })
                            }}
                          />
                          {element.errorNumber !== '' && (
                            <h5 className='text-error'>
                              {translate(
                                'profile.actions.editFamilyInfo.errors.phoneNumber',
                              )}
                            </h5>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                    {/* <div className='row'>
                        <div className='col-6 pr-1'>
                          <FormGroup check className=''>
                            <Label check>
                              <Input
                                type='checkbox'
                                fieldName='managePayments'
                                defaultChecked={element.managePayments}
                                checked={element.managePayments}
                                onChange={(e) => {
                                  changeManagePaymentNews(element, index)
                                }}
                              />{' '}
                              Manage payments
                            </Label>
                            {element.errorPayment !== '' && (
                              <h5 className='text-error'>
                                {translate(
                                  'profile.actions.editFamilyInfo.errors.errorPayment',
                                )}
                              </h5>
                            )}
                          </FormGroup>
                        </div>
                        <div className='col-6 pr-1'>
                          <FormGroup check className=''>
                            <Label check>
                              <Input
                                type='checkbox'
                                fieldName='managePayments'
                                defaultChecked={element.manageApprovals}
                                checked={element.manageApprovals}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    newParents: form.newParents.map(
                                      (item, idx) => {
                                        if (index === idx) {
                                          return {
                                            ...item,
                                            manageApprovals: !element.manageApprovals,
                                          }
                                        } else {
                                          return item
                                        }
                                      },
                                    ),
                                  })
                                }}
                              />{' '}
                              Manage approvals
                            </Label>
                            {element.errorPayment !== '' && (
                              <h5 className='text-error'>
                                {translate(
                                  'profile.actions.editFamilyInfo.errors.errorApprovals',
                                )}
                              </h5>
                            )}
                          </FormGroup>
                        </div>
                      </div> */}
                    <div className='d-flex flex-row justify-content-end mt-3'>
                      <h5
                        className='text-error cursor'
                        onClick={() => {
                          deleteParent('New', index)
                        }}
                      >
                        Remove this parent
                      </h5>
                    </div>
                  </div>
                )
              })}
          <div className='d-flex flex-row mt-4'>
            <span className='btn-link cursor' onClick={addParent}>
              Add new parent
            </span>
          </div>
          <Button
            label='Save changes'
            color='primary'
            check
            size='medium'
            onClick={onSubmit}
            disabled={loading}
            activity={loading}
            style={{width: '100%'}}
            className='mt-5'
          />
        </Form>
      </Modal>
    </span>
  )
}

export default FamilyModalForm
