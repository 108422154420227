import React from 'react'
import {
  IconIllustratedCalendar,
  IconClose,
  ListOption,
  IconProgram,
  LessonSelectCalendar,
  ListItem,
  Avatar,
  IconArrowRight,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

export const SelectDateHeader = (props) => {
  const {toggle, subtitle, title} = props
  return (
    <div className='modal-header p-0 m-0 d-flex flex-column'>
      <div className='d-flex flex-row justify-content-between w-100 px-7 pt-7 pb-5'>
        <div className='d-flex flex-column m-0 p-0'>
          <IconIllustratedCalendar size={80} />
          <span className='h2 font-weight-bold my-4'>{title}</span>
          <span className='h4'>{subtitle}</span>
        </div>
        <IconClose onClick={toggle} />
      </div>
    </div>
  )
}

export const DateList = (props) => {
  const {
    // needMoreOptionsFunction,
    // RenderTimezoneAndEducator,
    possibleBlocks,
    loading,
    timeScheduled,
    setTimeScheduled,
    addFiveElements,
    // isOrientation
  } = props
  return (
    <div className='m-0 py-0 px-6 d-flex flex-column'>
      {/* <div className='m-0 py-0 px-0 d-flex flex-column w-100'>
        {RenderTimezoneAndEducator}
      </div> */}
      <div className='m-0 px-0 pt-4 pb-0'>
        <label className='text-gray'>Suggestion : </label>
        <div className='m-0 p-0 d-flex flex-column'>
          {loading ? (
            <Skeleton count={5} height={40} />
          ) : (
            possibleBlocks.map((block) => {
              return (
                <ListOption
                  className='mb-2 znk-select-option'
                  selectedInput={
                    timeScheduled === moment(block.moment).toISOString()
                  }
                  id='x-0'
                  label={
                    <>
                      <IconProgram size={17} />
                      <span className='h5'>{block.label}</span>
                    </>
                  }
                  icon={null}
                  onClick={() => {
                    setTimeScheduled(moment(block.moment).toISOString())
                  }}
                />
              )
            })
          )}
          <Button
            onClick={() => {
              addFiveElements()
            }}
            label='Show more'
            size='small'
            color='primary'
            className='w-100 mt-2 mb-4'
          />
        </div>
        {/* {
          !isOrientation &&
          <label className='h5 text-gray mt-4 mb-0' onClick={needMoreOptionsFunction}>{translate('modal.rescheduledlesson.needmore')}</label>
        } */}
      </div>
    </div>
  )
}

export const DateCalendar = (props) => {
  const {
    loading,
    RenderTimezoneAndEducator,
    freeFrames,
    timeScheduled,
    setTimeScheduled,
    setPage,
    setCurrentMoment,
    currentMoment,
    from,
  } = props
  return (
    <div className='m-0 py-0 px-5 d-flex flex-column'>
      <label
        className='btn-link'
        onClick={() => {
          setPage('list')
          setCurrentMoment(moment(from).startOf('isoWeek').toISOString())
        }}
      >
        Back
      </label>
      <div className='m-0 py-0 px-4 d-flex flex-column w-100'>
        {RenderTimezoneAndEducator}
      </div>
      <LessonSelectCalendar
        availabilityBlocks={freeFrames}
        setSelected={(e) => {
          setTimeScheduled(e)
        }}
        setMoment={setCurrentMoment}
        selectedBlock={timeScheduled}
        moment={moment(currentMoment)}
        loading={loading}
      />
    </div>
  )
}

export const ConfirmRescheduled = (props) => {
  const {educator, topic, timeScheduledLabel, setPage} = props

  const educatorName = `${educator?.firstName} ${educator?.lastName}`
  return (
    <div className='m-0 pt-0 pt-0 pb-4 px-6 d-flex flex-column'>
      <div className='m-0 py-0 px-0 d-flex flex-column w-100'>
        <ListItem
          showBottomLine={false}
          showTopLine
          className='py-4'
          onClick={() => {
            setPage('list')
          }}
          Left={
            <div className='m-0 p-0 d-flex flex-row align-items-center'>
              <div className='d-flex flex-row align-items-center'>
                <IconProgram size={20} />
                <label className='ml-4 mr-0 my-0 h45'>
                  {timeScheduledLabel}
                </label>
              </div>
            </div>
          }
          Right={<IconArrowRight />}
        />
        <ListItem
          showBottomLine
          showTopLine
          className='py-2'
          Left={
            <div className='m-0 p-0 d-flex flex-row align-items-center'>
              <Avatar name={educatorName} uri={null} size='avatar-medium' />
              <div className='d-flex flex-row align-items-center'>
                <label className='ml-4 mr-0 my-0 h45'>
                  {educatorName}{' '}
                  <span className='h5 text-gray'>{`(${topic?.name})`}</span>
                </label>
              </div>
            </div>
          }
          Right={null}
        />
        {/* <ListItem
          showBottomLine
          showTopLine={false}
          className='py-4'
          Left={
            <div className='m-0 p-0 d-flex flex-row align-items-center'>
              <div className='d-flex flex-row align-items-center'>
                <IconLearn size={20} />
                <label className='ml-4 mr-0 my-0 h45'>{`${service.name} ${topic.name}`}</label>
              </div>
            </div>
          }
          Right={null}
        /> */}
      </div>
    </div>
  )
}

export default {
  SelectDateHeader,
  DateList,
  DateCalendar,
  ConfirmRescheduled,
}
