import React, {useState} from 'react'
import {propTypes, defaultProps} from '../../propTypes'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import {useDispatch} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../../store/modal/const'
import {ListItem} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'

const StudentBlock = (props) => {
  const dispatch = useDispatch()
  const [collapseFamily, setCollapseFamily] = useState(false)
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.studentInfo'})
  }
  const familyToggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.familyInfo'})
  }
  const {translate} = useTranslation()
  const {studentInfo} = props
  const {institution, graduationDate, parents} =
    studentInfo || defaultProps.studentInfo
  return (
    <div className='d-flex flex-column my-4'>
      <span className='d-flex text-dark justify-content-between my-1'>
        <span className='font-weight-bold'>
          {translate('profile.studentInfo')}
        </span>
        <button className='btn btn-link p-0' onClick={toggle}>
          {translate('misc.edit')}
        </button>
      </span>
      <span className='d-flex flex-column'>
        <ListItem
          className='px-0'
          showBottomLine
          showTopLine
          Left={
            <span>
              {translate('profile.actions.editStudentInfo.institution')}
            </span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {institution || '-'}
            </span>
          }
        />
        {/* <ListItem
          className='px-0'
          showBottomLine
          Left={<span>{translate('profile.actions.editStudentInfo.gpa')}</span>}
          Right={
            <span className='text-secondary font-weight-light'>
              {gpa || '-'}
            </span>
          }
        /> */}
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editStudentInfo.gradDate')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {graduationDate
                ? moment(graduationDate).format('MMMM YYYY')
                : '-'}
            </span>
          }
        />
      </span>
      <span className='d-flex flex-column'>
        <div className='d-flex flex-row justify-content-between mt-7'>
          <div className='d-flex'>
            <span className='font-weight-bold'>Family Info</span>
          </div>
          <span
            className='btn-link cursor'
            onClick={() => {
              familyToggle()
            }}
          >
            {translate('misc.edit')}
          </span>
        </div>
        <div className='borderTop mt-3' />
        <div className='d-flex flex-column mt-4'>
          {parents &&
            parents.map((parent, index) => {
              return index < 2 ? (
                <>
                  <label className='text-primary mt-3'>
                    {' '}
                    Parent {index + 1}
                  </label>
                  <div className='d-flex flex-row justify-content-between mt-1'>
                    <span className='text-title'>Name</span>
                    <span className='text-gray'>
                      {parent.firstName} {parent.lastName}
                    </span>
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-row justify-content-between mt-3'>
                    <span className='text-title'>Email</span>
                    <span className='text-gray'>{parent.email}</span>
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-row justify-content-between mt-3'>
                    <span className='text-title'>Phone</span>
                    <span className='text-gray'>{parent.phone}</span>
                  </div>
                  <div className='borderTop mt-3' />
                </>
              ) : null
            })}
          {parents && parents.length > 2 && (
            <>
              <Collapse isOpen={collapseFamily}>
                {parents &&
                  parents.map((parent, index) => {
                    return index >= 2 ? (
                      <>
                        <label className='text-primary mt-3'>
                          {' '}
                          Parent {index + 1}
                        </label>
                        <div className='d-flex flex-row justify-content-between mt-1'>
                          <span className='text-title'>Name</span>
                          <span className='text-gray'>
                            {parent.firstName} {parent.lastName}
                          </span>
                        </div>
                        <div className='borderTop mt-3' />
                        <div className='d-flex flex-row justify-content-between mt-3'>
                          <span className='text-title'>Email</span>
                          <span className='text-gray'>{parent.email}</span>
                        </div>
                        <div className='borderTop mt-3' />
                        <div className='d-flex flex-row justify-content-between mt-3'>
                          <span className='text-title'>Phone</span>
                          <span className='text-gray'>{parent.phone}</span>
                        </div>
                        <div className='borderTop mt-3' />
                      </>
                    ) : null
                  })}
              </Collapse>
              <div className='d-flex flex-row mt-4'>
                <span
                  className='btn-link'
                  onClick={() => {
                    setCollapseFamily(!collapseFamily)
                  }}
                >
                  {collapseFamily ? 'View Less' : 'View all'}
                </span>
              </div>
            </>
          )}
        </div>
      </span>
    </div>
  )
}

StudentBlock.propTypes = propTypes
StudentBlock.defaultProps = defaultProps

export default StudentBlock
