import React, {useContext, useEffect, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {LoadingPage} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import AuthContext from '../../../contexts/AuthContext'
import LoginPage from '../../../Pages/Login'
import useTranslation from '../../../i18n/useTranslation'
import {useLocation} from 'react-router-dom'
import {decryptToken} from '../../../helpers/decrypt'
import InvoiceModal from '../../../Pages/Profile/Pyments/InvoiceDetails/Modal'
import invoiceQuery from '../../../Pages/Profile/Pyments/InvoiceDetails/query'
import useModalState from '../../../hooks/useModalState'
const propTypes = {
  children: PropTypes.node,
}
const ForceLogin = (props) => {
  const {translate} = useTranslation()
  const logged = useContext(AuthContext)
  const {onChange} = useModalState('payments.viewInvoice')
  const now = new Date().toISOString()
  const [showInvoice, setShowInvoice] = useState(false)
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(showInvoice)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const invoiceId = searchParams.get('invoiceId')
  const tokenParam = searchParams.get('token')
  const token = tokenParam ? decryptToken(tokenParam, 'zinkerz key') : ''
  const tokenDecrypted = token.split('id:') || [null, null]
  const [getInvoice, {data: invoiceData}] = useLazyQuery(invoiceQuery, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (now < tokenDecrypted[0] && tokenDecrypted[1] === invoiceId) {
      setShowInvoice(true)
    }
    // eslint-disable-next-line
  }, [token, invoiceId])

  const searchInvoiceById = async () => {
    if (invoiceId && invoiceId !== '' && typeof invoiceId === 'string') {
      getInvoice({variables: {invoiceId}})
    }
    setInvoiceModalOpen(!invoiceModalOpen)
  }
  useEffect(() => {
    if (invoiceData?.invoice?.id) {
      onChange(() => ({invoiceId: invoiceData.invoice.id}))
    }
    /* eslint-disable-next-line */
  }, [invoiceData])

  useEffect(() => {
    if (invoiceId) {
      searchInvoiceById()
    }
    /* eslint-disable-next-line */
  }, [invoiceId])

  return showInvoice ? (
    // <span> Probando</span>
    <InvoiceModal
      isOpen={invoiceModalOpen}
      toggle={() => setInvoiceModalOpen(!invoiceModalOpen)}
    />
  ) : logged ? (
    props.children
  ) : global.loadingForceLogin ? (
    <LoadingPage text={translate('loading.gatheringProfileInfo')} />
  ) : (
    <LoginPage />
  )
}
ForceLogin.propTypes = propTypes
export default ForceLogin
