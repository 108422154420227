import moment from 'moment'

export const parsePlan = (plans = []) => {
  return plans
    .filter(
      (plan) => !['counceling', 'timeline'].includes(plan?.kind?.toLowerCase()),
    )
    .map((plan) => {
      let startTime
      let endTime
      let partArray
      let isFixedPlan = false
      let isTotalLesson = false
      if (plan.kind === 'lessonsFixed') {
        partArray = plan.lessonPerTopic
        isFixedPlan = true
        isTotalLesson = plan.lessonPerTopic.reduce(
          (isTotal, lessonPart) => isTotal || lessonPart.type === 'totalLesson',
          false,
        )
        // isTotalLessonsPlan = plan.lessonPerTopic.reduce((isTotal, lessonPart) => isTotal || (lessonPart.type === 'totalLesson'), false)
      } else partArray = plan.parts
      startTime = (partArray || []).reduce(
        (num, item) =>
          moment(item.from.split('T')[0]).valueOf() < num
            ? moment(item.from.split('T')[0]).valueOf()
            : num,
        Number.POSITIVE_INFINITY,
      )
      endTime = (partArray || []).reduce(
        (num, item) =>
          moment(item.to.split('T')[0]).valueOf() > num
            ? moment(item.to.split('T')[0]).valueOf()
            : num,
        Number.NEGATIVE_INFINITY,
      )
      return {
        ...plan,
        startTime: moment(startTime),
        endTime: moment(endTime),
        isFixedPlan,
        isTotalLesson,
        status:
          plan.status === 'scheduled' || plan.status === 'paid'
            ? startTime > new Date().getTime()
              ? 'upcoming'
              : 'inprogress'
            : plan.status,
      }
    })
}

export const getTotals = (plan) => {
  if (!plan) return {}
  const totalLesson =
    plan.kind === 'lessonsFixed'
      ? plan.lessonPerTopic.reduce((total, lesson) => {
          return lesson.totalLessons + total
        }, 0)
      : plan.parts.reduce((total, lesson) => {
          return lesson.lessonsPerWeek + total
        }, 0)
  const totalPlan =
    plan.kind === 'lessonsFixed' &&
    plan.lessonPerTopic.reduce((isTotal, lesson) => {
      return isTotal || lesson.type === 'totalLesson'
    }, false)

  return {totalLesson, totalPlan}
}

export default {parsePlan}
