import React from 'react'
import {PlanCounselingCard} from '@Knowledge-OTP/znk-ui-components'
import {useHistory} from 'react-router'
import useViewPlanModal from '../../../../MyPlans/viewCounselingPlanModal/useViewPlanModal'
import useCounselingPlanReducer from '../../../../../reducers/counseling-plan'
import moment from 'moment-timezone'

const CounselingPlanCard = (props) => {
  const {plan, className} = props
  const history = useHistory()
  const {parsePlanFromAPI} = useCounselingPlanReducer()
  const {open: openCounselingPlanModal} = useViewPlanModal()
  return (
    <PlanCounselingCard
      className={className}
      styleCard={{cursor: 'pointer'}}
      // styleCard={{
      //   background: '#32405b',
      //   color: 'white',
      // }}
      title={plan?.name}
      subtitle={`${
        plan?.type
          ? plan.type.substring(0, 1).toUpperCase() +
            plan.type.substring(1, plan.type.length)
          : 'Type'
      } | ${
        plan?.aditionalInfo?.studentCountryName || 'Country'
      } | Until ${moment(plan?.to).format('MMM DD, YYYY')}`}
      text=''
      onClick={() => {
        if (plan.status === 'sent')
          history.push(`/counseling-onboarding/${plan.id}`)
        else openCounselingPlanModal(() => {}, parsePlanFromAPI(plan))
      }}
      status={plan.status === 'sent' ? 'blank' : 'scheduled'}
    />
  )
}

export default CounselingPlanCard
