import {useLazyQuery, gql} from '@apollo/client'

export const UseGetBackUpLink = () => {
  return useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          educatorInfo {
            backupLink
          }
        }
      }
    `,
    {fetchPolicy: 'no-cache'},
  )
}

export default UseGetBackUpLink
