import {useQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../Model/QueryResult/useLessonResult'

export const UseCalendarViewEducatorQuery = (
  vars = {},
  options = {fetchPolicy: 'cache-first'},
) => {
  return useQuery(
    gql`query(
            $kinds: [String!]
            $studentIds: [ID!]
            $from: DateTime!
            $to: DateTime!
          ) {
        calendarViewEducator(
            kinds: $kinds
            studentIds: $studentIds,
            from: $from,
            to: $to
        ) {  
            ${queryLessonResult}
            ... on Counselor {
                id
                startDate
                endDate
                counselorId
                CounselorCreated : createdAt
                CounselorUpdate : updatedAt
                isMeeting
            }
            ... on EducatorUnavailability {
                id
                kind
                startDate
                endDate
                busy
                educatorId
                userIdsInvolved
            }
        }
        }`,
    {
      variables: vars,
      ...options,
    },
  )
}

export default UseCalendarViewEducatorQuery
