import React, {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../common/Alert/util'
import {
  Avatar,
  DropDownItem,
  IconBook,
  IconLink,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {hasHttp} from '../../CalendarLessons/modal/utils'
import ActionField from './Fields/ActionFields'
import useActionsFields from './Fields/ActionFields/useActionsFields'
import OptionAction from './Fields/ActionFields/option'

const UseColumns = (refetch) => {
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()

  const RenderAvatar = (name, id, avatar) => {
    return (
      <div
        className='d-flex flex-row znk-menu-option ml-0'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'student.infoStudentModal',
            data: {
              isOpen: true,
              dataStudent: {id}, // basta con que el objeto tenga la llave "id"
              initPage: 'summary',
            },
          })
        }}
      >
        <Avatar
          name={name}
          named={false}
          uri={avatar}
          size='avatar-medium mr-1'
        />
        <span className='align-self-center'>{name}</span>
      </div>
    )
  }

  const RenderCompactAvatar = (name, id, avatar) => {
    return (
      <div
        className='d-flex flex-row znk-menu-option ml-0 pl-0 mr-0'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'student.infoStudentModal',
            data: {
              isOpen: true,
              dataStudent: {id}, // basta con que el objeto tenga la llave "id"
              initPage: 'summary',
            },
          })
        }}
      >
        <Avatar
          name={name}
          named={false}
          uri={avatar}
          size='avatar-medium mr-1'
        />
        <div className='d-flex flex-column m-0 pl-2'>
          <span>{name}</span>
          <span className='text-gray h45'>Service - Topic</span>
        </div>
      </div>
    )
  }

  const showAlertFunction = (text = '', status = 'success') => {
    showAlert({text, status}, dispatch)
  }
  const {getLabel} = useActionsFields(refetch)
  const getOptionsField = (colSpan = 2) => {
    return {
      render: (item) => {
        const {materialsTopic, topics, studentInfo} = item
        const courses = [
          {link: studentInfo?.materials, isGeneral: true},
        ].concat(
          materialsTopic?.filter(({topicId, serviceId}) => {
            return (
              topics.map(({topicId}) => topicId).includes(topicId) &&
              topics.map(({serviceId}) => serviceId).includes(serviceId)
            )
          }) || [],
        )
        return (
          <div
            className={`m-0 p-0 ${
              courses?.length > 0
                ? 'hover-icon cursor-pointer'
                : 'cursor-not-allowed'
            }`}
          >
            <IconBook
              color={courses?.length > 0 ? '#000000' : '#72809D'}
              size={16}
            />
          </div>
        )
      },
      generateOptions: (item) => {
        const {materialsTopic, topics, studentInfo} = item
        const courses = [
          {link: studentInfo?.materials, isGeneral: true},
        ].concat(
          materialsTopic?.filter(({topicId, serviceId}) => {
            return (
              topics.map(({topicId}) => topicId).includes(topicId) &&
              topics.map(({serviceId}) => serviceId).includes(serviceId)
            )
          }) || [],
        )
        const {hasAction} = getLabel(item)
        return (hasAction && breakWidth === 'SM'
          ? [
              {
                component: <OptionAction refetch={refetch} item={item} />,
              },
            ]
          : []
        ).concat(
          courses.map(({serviceName, topicName, link, isGeneral}) => {
            const name = isGeneral
              ? `General Course Materials`
              : `${serviceName} ${topicName}`
            return {
              component: (
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                  <DropDownItem className='m-0'>
                    <span
                      // style={{whiteSpace: 'nowrap'}}
                      onClick={() => {
                        if (link) window.open(hasHttp(link), '_blank')
                        else
                          showAlertFunction('Link does not exists!', 'warning')
                      }}
                      className='m-0 p-0 d-flex flex-row justify-content-start align-items-center'
                    >
                      <IconBook size={14} className='mr-2' /> {`${name}`}
                    </span>
                  </DropDownItem>
                  <div
                    className='ml-1 hover-icon'
                    onClick={() => {
                      if (!link) {
                        showAlertFunction('Link doesn`t exists!', 'warning')
                        return
                      }
                      navigator.clipboard.writeText(link).then(
                        () => {
                          showAlertFunction(
                            translate('workspace.teach.table.copySuccess'),
                            'success',
                          )
                        },
                        (err) => {
                          showAlertFunction(
                            translate('workspace.teach.table.copyError'),
                            'error',
                          )
                        },
                      )
                    }}
                  >
                    <IconLink size={14} />
                  </div>
                </div>
              ),
            }
          }),
        )
      },
      type: 'new-dropdown',
      direction: 'bottomRight',
      className: '',
      // classNameMenu: 'fixed-left-menu',
      id: 'course',
      colSpan,
    }
  }

  const getActionField = (colSpan = 3) => {
    return {
      render: (item) => {
        return <ActionField item={item} refetch={refetch} />
      },
      id: 'creation',
      colSpan,
      className: 'justify-content-center align-items-center',
    }
  }

  const getTopicField = (colSpan = 2) => {
    return {
      render: (item) => (
        <span
          className='m-0 p-0'
          title={item.topicsDisplay}
        >{`${item.topicsDisplay}`}</span>
      ),
      id: 'topics',
      colSpan,
      className: 'justify-content-start align-items-center text-gray',
    }
  }
  const getServiceFields = (colSpan = 2) => {
    return {
      render: (item) => (
        <span
          className='m-0 p-0'
          title={item.servicesDisplay}
        >{`${item.servicesDisplay}`}</span>
      ),
      id: 'service',
      colSpan,
      className: 'justify-content-start align-items-center text-gray',
    }
  }
  const getStudentField = (colSpan = 6, breakWidth) => {
    return {
      render: (item) => {
        if (breakWidth === 'SM') {
          return RenderCompactAvatar(item.name, item.id, item.avatar)
        }
        return RenderAvatar(item.name, item.id, item.avatar)
      },
      id: 'name',
      colSpan,
      className: 'justify-content-start align-items-center text-title',
    }
  }

  return useMemo(() => {
    if (breakWidth === 'SM') {
      return [getStudentField(8, breakWidth), getOptionsField(3)]
    } else if (breakWidth === 'MD') {
      return [
        getStudentField(6, breakWidth),
        getActionField(3),
        getOptionsField(3),
      ]
    }
    return [
      getStudentField(),
      getServiceFields(),
      getTopicField(),
      getActionField(),
      getOptionsField(),
    ]
    // eslint-disable-next-line
  }, [breakWidth])
}

export default UseColumns
