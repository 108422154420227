import React from 'react'
import noTasksImg from '../../../../assets/noTasks.png'
import useTranslation from '../../../../i18n/useTranslation'

const NoTasksComponent = () => {
  const {translate} = useTranslation()

  return (
    <>
      <span className='mb-2'>{translate('counseling.yourTasks')}</span>
      <div
        className='d-flex flex-column justify-content-center w-100 mb-2 mt-4 py-8'
        style={{
          borderRadius: '10px',
          border: '2px dashed',
          borderColor: '#e7e7e7',
        }}
      >
        <div className='d-flex justify-content-center w-100 mb-2'>
          <img
            src={noTasksImg}
            alt='No Tasks'
            color='#696bff'
            width='91'
            height='77'
          />
        </div>
        <div className='d-flex flex-column justify-content-center w-100 mt-2'>
          <span className='text-muted text-center h4 mb-4'>
            {translate('counseling.noTasksMainText')}
          </span>
          <span className='text-muted text-center h4'>
            {translate('counseling.docsExamsText')}
          </span>
        </div>
      </div>
    </>
  )
}

export default NoTasksComponent
