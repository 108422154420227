import {gql, useLazyQuery} from '@apollo/client'
import queryLessonResult from '../../../../../Model/QueryResult/useLessonResult'

export default (onSuccess, onError) => {
  return useLazyQuery(
    gql`query(
            $calendarEventId: ID!
          ) {
        calendarEvent(
          calendarEventId: $calendarEventId
      ) {  
          ${queryLessonResult}
      }
    }`,
    {
      onCompleted: onSuccess,
      onError: onError,
    },
  )
}
