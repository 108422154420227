import phasesQueryResult from './phase'

// String con modelo de objeto que será utilizado para
// obtener UN counseling plan.
const planQueryResult = `
    id
    status
    studentUser {
        studentUserId
        firstName 
        lastName
        rate
        isManualRate
    }
    name
    from
    to
    pricingRate
    sentDate
    paymentItemsConfig {
        amount
        percentDiscount
        amountWithoutDiscount
        date
        description
        type
    }
    ...on CouncelingPlan {
        type
        taskTemplate
        advisor {
            teamUserId
            firstName
            lastName
        }
        councelor {
            teamUserId
            firstName
            lastName
        }
        tasks {
            ${phasesQueryResult}
        }
    }
`

export default planQueryResult
