import React, {useMemo, useState, useEffect} from 'react'
import {
  Field,
  Form,
  LabeledField,
  PlainTextInput,
  SelectOption,
  InputSelect,
  PhoneSelector,
  Select as Rselect,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup, Label} from 'reactstrap'
import translate from '../../../../i18n/translate'
import * as moment from 'moment'
import {useSelector} from 'react-redux'
import escapeRegExp from 'lodash/escapeRegExp'
import usStatesList from '../../../../common/CompleteProfile/Utils/us_states.json'

const PersonalForm = (props) => {
  const countries = useSelector((state) => state.countries.countries)
  const [selectCode, setSelectCode] = useState('')
  const parseCountries = useMemo(() => {
    return Object.values(countries).sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }, [countries])
  const usStates = usStatesList?.map((e) => {
    return {label: e.name, abb: e.abbreviation}
  })
  let [selectedCountry, setSelectedCountry] = useState(props.value.countryId)

  let [easyState, setEasyState] = useState(props.value.state)
  const handleCountryChange = (value) => {
    const idCountry = parseCountries.find((country) => country.id === value)
    if (idCountry) {
      setSelectedCountry(idCountry.id)
      props.onChange({...props.value, countryId: idCountry.id})
    }
    setEasyState(null)
  }
  useEffect(() => {
    if (selectCode) {
      props.value.phoneDialCode = selectCode
    }
    // eslint-disable-next-line
  }, [selectCode])

  return (
    <Form {...props}>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.studentName')}</Label>
        <div className='row'>
          <div className='col-6 pr-0'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='firstName'
              placeholder={translate(
                'profile.actions.editPersonalInfo.firstName',
              )}
              className='noRight'
            />
          </div>
          <div className='col-6 pl-1'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='lastName'
              placeholder={translate(
                'profile.actions.editPersonalInfo.lastName',
              )}
              className='noLeft'
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.actions.editPersonalInfo.gender')}</Label>
        <Field
          type={LabeledField}
          component={SelectOption}
          className='w-100'
          fieldName='gender'
          options={[
            {
              id: 'male',
              text: translate('home.onboard.approve.profile.male'),
            },
            {
              id: 'female',
              text: translate('home.onboard.approve.profile.female'),
            },
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.birthday')}</Label>
        <div className='row'>
          <div className='col-3 pr-1'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthDay'
              options={new Array(31)
                .fill(0)
                .map((_, idx) => ({label: idx + 1, value: idx + 1}))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
              isClearable={false}
            />
          </div>
          <div className='col-6 pl-0 pr-1'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthMonth'
              options={new Array(12).fill(0).map((_, idx) => ({
                label: moment().month(idx).format('MMMM'),
                value: idx,
              }))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
              isClearable={false}
            />
          </div>
          <div className='col-3 pl-0'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthYear'
              options={new Array(80)
                .fill(0)
                .map((_, idx) => ({label: 2020 - idx, value: 2020 - idx}))}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
              isClearable={false}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.actions.editPersonalInfo.phone')}</Label>
        <div className='row'>
          <div className='col-6 pr-1'>
            <PhoneSelector
              fieldName='phoneDialCode'
              defaultValue={props.value?.phoneDialCode}
              selectCode={selectCode}
              setSelectCode={setSelectCode}
            />
          </div>
          <div className='col-6 pl-0'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              className='noLeft'
              fieldName='phoneNumber'
              placeholder='12345621'
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.address')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='address'
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.city')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='city'
        />
      </FormGroup>
      {selectedCountry === '5f778da89097bc5330ab1739' ? (
        <FormGroup>
          <Label>{translate('profile.actions.editPersonalInfo.state')}</Label>
          <Field
            type={InputSelect}
            component={Rselect}
            fieldName='state'
            //value={easyState}
            options={usStates.map((state, _) => ({
              label: state.label,
              value: state.label,
            }))}
            isClearable={false}
            onChange={(value) => {
              props.onChange({...props.value, state: value})
            }}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>{translate('profile.actions.editPersonalInfo.state')}</Label>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName='state'
            defaultValue={easyState}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.zipCode')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='zipCode'
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.country')}</Label>
        <Field
          type={InputSelect}
          component={Rselect}
          onChange={(name) => {
            handleCountryChange(name)
          }}
          fieldName='countryId'
          defaultValue={selectedCountry}
          value={selectedCountry}
          options={parseCountries.map((country, _) => ({
            label: country.name,
            value: country.id,
          }))}
          isClearable={false}
          filterOption={(obj, string) => {
            if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
              return true
            return false
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {translate('profile.actions.editPersonalInfo.spokenLanguages')}
        </Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='spokenLanguages'
        />
      </FormGroup>
    </Form>
  )
}

export default PersonalForm
