import React, {useState, useMemo, useEffect} from 'react'
import CalendarSection from './Sections/Calendar'
import {
  Avatar,
  Calendar,
  LayoutTwoSections,
  IconArrowLeft,
  IconArrowRight,
  IconProgram,
  IconUser,
  useWindowDimensions,
  ListItem,
  InputSelect,
} from '@Knowledge-OTP/znk-ui-components'
import {useQuery, gql} from '@apollo/client'
import CalendarViewQuery from '../../Model/CalendarEvent/List/CalendarView/index'
import CalendarViewStudentQuery from '../../Model/CalendarEvent/List/CalendarViewStudent/index'
import escapeRegExp from 'lodash/escapeRegExp'
import InfoModalLesson from '../Calendar/Lessons/Modal/InfoModal'
import OrientationModal from '../Calendar/Lessons/Modal/OrientationModal'
// import OrientationLessonModal from '../Workspace/Student/ViewTask/Components/Modals/OrientationLessonModal'

import CopyLessonModal from '../Calendar/Lessons/Modal/CopyLesson'
import ChangeTimezoneModal from '../CalendarLessons/Components/ChangeTimezoneModal'
import UnauthorizedReschedule from '../Calendar/Lessons/Modal/ReschedulePendingLesson/Unauthorized'
import ReschedulePendingLesson from '../Calendar/Lessons/Modal/ReschedulePendingLesson'
import RedirectToLessonModal from '../CalendarLessons/Components/RedirectToLessonModal'

import moment from 'moment-timezone'
import {useSelector, useDispatch} from 'react-redux'
import useTranslation from '../../i18n/useTranslation'
import queryLessonResult from '../../Model/QueryResult/useLessonResult'
import PendingLessonNotification from './PendingLessonNotification'
import {SET_DATA_MODAL_FORM_LIST} from '../../store/modal/const'

const CalendarViewStudent = () => {
  const info = useSelector((state) => state.user.info)

  const {breakWidth} = useWindowDimensions()
  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find((serv) => serv.isOrientation)

  const [week, setWeek] = useState(0)
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const zone = useSelector((state) => state.user.info.timezone)

  const [
    from /** start week**/,
    to /** end week**/,
    startMonth,
    endMonth,
  ] = useMemo(() => {
    let currentMoment = moment()
    if (zone) {
      currentMoment = moment().tz(zone)
      // return [
      //   moment().tz(zone).startOf('isoweek').add(week, 'week').toISOString(),
      //   moment()
      //     .tz(zone)
      //     .endOf('isoweek')
      //     .add(week, 'week')
      //     .add(1, 'millisecond')
      //     .toISOString(),
      // ]
    }
    const startWeek = currentMoment.clone().startOf('isoweek').add(week, 'week')
    const endWeek = currentMoment
      .clone()
      .endOf('isoweek')
      .add(week, 'week')
      .add(1, 'millisecond')
    return [
      startWeek.toISOString(), // start week
      endWeek.toISOString(), // end week
      currentMoment
        .clone()
        .add(week, 'week')
        .startOf('month')
        .startOf('isoWeek')
        .toISOString(), // start month
      currentMoment
        .clone()
        .add(week, 'week')
        .endOf('month')
        .endOf('isoWeek')
        .add(1, 'millisecond')
        .toISOString(), // end month
    ]
  }, [week, zone])

  const {selectedUserData} = useSelector(
    (state) => state.modal.calendar.selectUser,
  )

  const [selectUser, setSelectUser] = useState('')

  const {data: dataLinkedEducators, loading: loadUser} = useQuery(
    gql`
      query {
        getLinkedEducators {
          educatorUserId
          firstName
          lastName
        }
      }
    `,
  )

  const {data: dataUpcomingLesson} = useQuery(
    gql`query {
      getUpcomingLessonFromStudent {
        ${queryLessonResult}  
      }
    }
  `,
  )

  const dataUsers = dataLinkedEducators?.getLinkedEducators || []
  const upcomming = dataUpcomingLesson?.getUpcomingLessonFromStudent?.id || ''

  // educator unavailable
  const {
    data,
    refetch: refetchCalendarQuery,
    loading: loadCalendar,
  } = CalendarViewQuery({
    kinds: ['educator-unavailability'],
    userId: info?.id || '',
    from: startMonth,
    to: endMonth,
  })

  // todas las lecciones
  const {
    data: dataStudentLessonQuery,
    refetch: refetchCalendarStudentQuery,
    loading: loadEducatorCalendar,
  } = CalendarViewStudentQuery({
    // kinds: ['plan-lesson', 'individual-lesson', 'group-lesson'],
    educatorIds: selectUser ? [selectUser] : [],
    from: startMonth,
    to: endMonth,
  })
  const refetchAllLesson = () => {
    refetchCalendarQuery()
    refetchCalendarStudentQuery()
  }
  const {dataStudentLesson, dataUnavailability} = useMemo(() => {
    const studentLesson = (dataStudentLessonQuery || []).filter(
      ({startDate}) =>
        moment(startDate) >= moment(from) && moment(startDate) < moment(to),
    )
    const unavailabilityLesson = (data || []).filter(
      ({startDate}) =>
        moment(startDate) >= moment(from) && moment(startDate) < moment(to),
    )
    return {
      dataStudentLesson: studentLesson,
      dataUnavailability: unavailabilityLesson,
    }
  }, [dataStudentLessonQuery, data, from, to])

  const [now, currentNow] = useMemo(() => {
    return [moment(), moment().add(week, 'weeks')]
  }, [week])

  useEffect(() => {
    const educator = dataUsers?.find((stu) => stu.educatorUserId === selectUser)
    if (educator) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.selectUser',
        data: {
          upcomming: educator?.studentInfo?.upcomingLesson?.id,
          selectedUserData: educator,
        },
      })
    }
    // eslint-disable-next-line
  }, [selectUser, dataUsers])
  const ordenateUserArray = (array) => {
    return array
      .map((edu) => {
        return {
          name: `${edu.firstName} ${edu.lastName}`,
          id: edu.educatorUserId,
          avatar: edu.avatar || null,
        }
      })
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
  }

  // const incompleteLessons = scheduledLessons ? scheduledLessons.filter((lesson) => moment(lesson.endDate) < moment()) : []

  const [openSideLeft, setOpenSideLeft] = useState(true)
  const toggleSideLeft = () => {
    setOpenSideLeft(!openSideLeft)
  }

  const listLessons = dataUnavailability
    .concat(dataStudentLesson)
    .filter(
      (lesson) =>
        lesson.status !== 'canceled' &&
        lesson.status !== 'pending' &&
        lesson.status !== 'draftPending' &&
        lesson.status !== 'occurrence' &&
        lesson.status !== 'paused' &&
        lesson.status !== 'draft',
    )

  return (
    <>
      <LayoutTwoSections
        isOpen={openSideLeft}
        toggle={toggleSideLeft}
        disabledClasses={breakWidth === 'SM'}
        IconHide={IconArrowLeft}
        IconShow={IconArrowRight}
        className='h-100'
        LeftSideMax={
          <>
            <span className='h3 font-weight-bold'>
              {translate('lessons.calendar.title')}
            </span>
            <PendingLessonNotification
              className='mt-6'
              refetch={(lessonId) => {
                refetchCalendarStudentQuery()
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: `student.${
                    lessonId === orientation?.id
                      ? 'orientationLessonModal'
                      : 'infoLessonModal'
                  }`,
                  data: {
                    isOpen: true,
                    idLesson: lessonId,
                  },
                })
              }}
            />
            <Calendar
              theme='red'
              className='my-3 flex-grow-0'
              data={
                dataStudentLessonQuery
                // listLessons?.filter(
                //   (les) => les?.__typename !== 'EducatorUnavailability',
                // ) || []
              }
              today={now}
              onToday={() => {
                setWeek(0)
              }}
              moment={currentNow}
              setMoment={(selectWeek) => {
                const addWeeks = moment(selectWeek)
                if (addWeeks.isoWeekday() > 1) {
                  // returns 1-7 where 1 is Monday and 7 is Sunday
                  addWeeks.add(1, 'week')
                }
                setWeek(
                  week +
                    moment
                      .duration(
                        addWeeks
                          .startOf('isoWeek')
                          .set({
                            hour: 0,
                            minutes: 0,
                            seconds: 0,
                            milliseconds: 0,
                          })
                          .diff(from),
                      )
                      .asWeeks(),
                )
              }}
              viewMode='month'
              focusWeek={null}
              isOpen
              ShowBPLeft
              ShowBPRight
            />
            <div className='m-0 p-0 d-flex flex-column w-100 pb-5'>
              <div className='m-0 p-0 d-flex flex-row justify-content-between'>
                <span className='h45 text-gray'>Educator</span>
              </div>
              <InputSelect
                isLoading={loadUser}
                InputClassName='my-3'
                value={selectUser}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.data.name))
                    return true
                  return false
                }}
                options={
                  dataUsers
                    ? ordenateUserArray(dataUsers).map((edu) => {
                        return {
                          value: edu.id,
                          name: edu.name,
                          label: (
                            <ListItem
                              id='x-0'
                              className='py-0'
                              Left={
                                <div className='d-flex flex-row'>
                                  <Avatar
                                    name={edu.name}
                                    named={false}
                                    uri={null}
                                    size='avatar-small mr-1'
                                  />
                                  <span className='align-self-center'>
                                    {edu.name}
                                  </span>
                                </div>
                              }
                            />
                          ),
                        }
                      })
                    : []
                }
                onChange={(e) => {
                  setSelectUser(e)
                }}
              />
              {/* <PlansView horizontal />
              <span className={`my-2`}></span>
              <TimelineView showTitle className='' /> */}
            </div>
          </>
        }
        LeftSideMaxClass='d-flex flex-column justify-content-start m-5 p-0 h-100 hidde-scroll-vertical'
        LeftSideMin={
          <>
            <div className='znk-option-item mb-3'>
              <IconProgram size={15} />
            </div>
            <div className='znk-option-item'>
              {selectedUserData && selectUser ? (
                <Avatar
                  name={`${selectedUserData?.firstName} ${selectedUserData?.lastName}`}
                  named={false}
                  uri={null}
                  size='avatar-small m-0'
                />
              ) : (
                <IconUser size={15} />
              )}
            </div>
          </>
        }
        LeftSideMinClass='m-0 p-0 d-flex flex-column justify-content-start align-items-center'
        RightSide={
          <CalendarSection
            openSideLeft={openSideLeft}
            week={week}
            setWeek={setWeek}
            from={from}
            refetchCalendarQuery={refetchCalendarQuery}
            upcomming={upcomming}
            loading={loadCalendar || loadEducatorCalendar}
            lessons={listLessons}
          />
        }
        RightSideClass='m-0 p-0 h-100'
        RightSideStyle={{backgroundColor: 'white'}}
      />
      <ReschedulePendingLesson />
      <UnauthorizedReschedule />
      <CopyLessonModal />
      <ChangeTimezoneModal />
      <InfoModalLesson
        refetchLessons={() => {
          refetchAllLesson()
        }}
      />
      <OrientationModal typeUser='student' refetchLessons={() => {}} />
      <RedirectToLessonModal
        callBackFunction={(lessonId, dataLesson, isOrientation) => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: `student.${
              isOrientation ? 'orientationLessonModal' : 'infoLessonModal'
            }`,
            data: {
              isOpen: true,
              idLesson: lessonId,
            },
          })
        }}
      />
    </>
  )
}

export default CalendarViewStudent
