import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
// import meetingQueryResult from './queryResults/meeting'
import meetingQueryResult from './queryResults/meetingCounseling'

export default (onSuccess = () => {}, onError = () => {}) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
    mutation (
        $isAdmin: Boolean = false
        $councelorInput: CouncelornputForStudent!
        $isCounseling: Boolean = true
        ) {
            scheduleCounselorFromStudent (
             isAdmin: $isAdmin
             councelorInput: $councelorInput
             isCounseling: $isCounseling
        )  {
            ${meetingQueryResult}
        }
    }
`,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    data: data ? data.scheduleCounselorFromStudent : null,
    error,
    submit,
  }
}
