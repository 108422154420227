import React from 'react'
import useTranslation from '../../../../../../i18n/useTranslation'

const ShowPendingLessonHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <>
      <div className='d-flex flex-column mt-2 h-auto justify-content-around'>
        <h2>
          <b>{translate('lessons.youhavepending')}</b>
        </h2>
      </div>
    </>
  )
}

export default ShowPendingLessonHeader
