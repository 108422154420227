import React, {useEffect, useState} from 'react'
import {
  Avatar,
  IconUser,
  IconClose,
  IconOptions,
  ModalPushScroll,
  IconStudent,
  IconCountry,
} from '@Knowledge-OTP/znk-ui-components'
import {
  Collapse,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'
import './style.css'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'

import useSummary from './useQueries/useSummary'
import Skeleton from 'react-loading-skeleton'
import Summary from './Summary'
import Timeline from './Timeline'
import Plans from './Plans'
import Calendar from './Calendar'
import Learn from './Learn'
import Profile from './Profile'
// tabs profile
import ChangeTimezone from './Profile/ChangeTimezone'
import ChangePassword from './Profile/ChangePassword'
// change notification settings
import useCountries from '../../../../Model/Countries/List'
import moment from 'moment'

const InfoStudent = () => {
  const dispatch = useDispatch()
  const {isOpen, dataStudent /** solo se usa el id**/, initPage} = useSelector(
    (state) => state.modal.student.infoStudentModal,
  )
  const {translate} = useTranslation()

  // QUERIES
  const {data: countries} = useCountries({limit: 0})

  const [activeTab, setActiveTab] = useState('summary')
  useEffect(() => {
    if (initPage) setActiveTab(initPage)
  }, [initPage])
  const [activeTabRight, setActiveTabRight] = useState('')

  const [
    querySummary,
    {data: studentResume, loading: loadStudentResume},
  ] = useSummary()

  useEffect(() => {
    if (isOpen && dataStudent?.id) {
      querySummary({
        variables: {
          id: dataStudent?.id,
          studentsUserIds: [dataStudent?.id],
          kind: ['lessonsPerWeek', 'lessonsFixed'],
          status: [
            'draft',
            'sent',
            'scheduled',
            'paid',
            'pause',
            'cancel',
            'resume',
          ],
        },
      })
    }
  }, [isOpen, dataStudent.id, querySummary])

  const userData = studentResume?.user
  const studentName = `${userData?.firstName} ${userData?.lastName}`
  const parentName =
    userData?.studentInfo?.parents?.length > 0
      ? `${userData?.studentInfo?.parents[0].firstName} ${userData?.studentInfo?.parents[0].lastName}`
      : ''

  const [openSideModal, setOpenSideModal] = useState(false)
  const toggleSideModal = () => {
    setOpenSideModal(!openSideModal)
  }

  const toggle = () => {
    if (isOpen) setOpenSideModal(false)
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoStudentModal',
      data: {
        isOpen: !isOpen,
        dataStudent,
      },
    })
  }

  let navMenuDimensions = null
  let modalContentDimensions = null
  let modalHeaderDimensions = null
  const [profileMenuDimensions, setProfileMenuDimensions] = useState({})
  useEffect(() => {
    if (navMenuDimensions && modalContentDimensions && modalHeaderDimensions) {
      setProfileMenuDimensions({
        // determina el alto que debe tener el menu de profile para que el listado de tabs
        // aparesca en la parte inferior de la pantalla.
        minHeight: `${parseInt(
          modalContentDimensions.height -
            modalHeaderDimensions.height -
            2.5 * navMenuDimensions.height,
        )}px`,
      })
    }
  }, [navMenuDimensions, modalContentDimensions, modalHeaderDimensions, isOpen])
  const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()
    if (month >= 10 && month <= 12) {
      year += 1
    }
    const difY = year - moment().year()
    switch (type) {
      case 'class':
        return year.toString().substr(2, 2)
      default:
        return 12 - difY
    }
  }
  return (
    <>
      <ModalPushScroll
        buttonLabel={null}
        className='modal-colored modal-sidebar modal-desktop-half fixed-head-modal input-modal'
        isOpen={isOpen}
        toggle={toggle}
        disabledScrollAnimation={activeTab === 'profile'}
        refContent={(el) => {
          if (!el) return
          modalContentDimensions = el.getBoundingClientRect()
        }}
        MinHead={
          <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
            <div className='d-flex flex-row align-items-center'>
              {loadStudentResume ? (
                <>
                  <Skeleton circle count={1} height={50} width={50} />{' '}
                  <Skeleton count={1} width={50} height={25} className='ml-3' />
                </>
              ) : (
                <>
                  <Avatar
                    name={studentName}
                    uri={userData?.avatar || null}
                    innerDiv
                    size='avatar-small'
                  />{' '}
                  <span className='h4 text-title ml-2'>{studentName}</span>
                </>
              )}
            </div>
            <div className='d-flex flex-row align-items-center'>
              <IconOptions
                color='#D7E1EA'
                onClick={() => {}}
                className='mr-2'
                style={{transform: 'rotate(90deg)'}}
              />
              <IconClose color='#D7E1EA' onClick={toggle} />
            </div>
          </div>
        }
        Head={
          <div className='modal-header px-6 px-sm-7 d-flex flex-column pt-4 mt-0'>
            <div
              ref={(el) => {
                if (!el) return
                modalHeaderDimensions = el.getBoundingClientRect()
              }}
              className='d-flex flex-row justify-content-between w-100'
            >
              {loadStudentResume ? (
                <div className='d-flex flex-column w-100'>
                  <Skeleton
                    duration={2}
                    circle
                    count={1}
                    height={120}
                    width={120}
                  />
                  <Skeleton
                    duration={2}
                    count={1}
                    height={40}
                    className='w-100'
                  />
                  <Skeleton
                    duration={2}
                    count={4}
                    height={20}
                    className='w-100'
                  />
                </div>
              ) : (
                <div className='d-flex flex-column'>
                  <Avatar
                    name={studentName}
                    uri={userData?.avatar || null}
                    innerDiv
                    size='avatar-extra'
                  />
                  <div className='mt-5 mb-3 d-flex flex-column'>
                    <span className='h2 font-weight-bold text-title'>
                      {studentName}
                    </span>
                    {/** <span className='h45 text-gray mt-2'>{userEmail}</span>
                        <span className='h45 text-gray my-2'>
                          {
                                        `${capitalize(userData?.studentInfo?.studentType)} |  
                                        Facebook  |  
                                        ${userData?.isActive ? capitalize('active') : capitalize('disabled')}  | 
                                        Class of ‘${getValues(userData?.studentInfo?.graduationDate, 'class')}`
                          }
                        </span>**/}
                    <span
                      className='h45 d-flex flex-row mb-2'
                      style={{whiteSpace: 'nowrap'}}
                    >
                      <span className='d-flex flex-row align-items-center text-gray mr-2'>
                        <IconStudent
                          className='mr-2'
                          size={12}
                          color='#35425B'
                        />
                        {getValues(
                          userData?.studentInfo?.graduationDate,
                          'grade',
                        )}
                        °
                      </span>
                      {userData?.studentInfo?.studentType === 'family' && (
                        <span className='d-flex flex-row align-items-center text-gray mx-3'>
                          <IconUser className='mr-2' size={12} />
                          {parentName}
                        </span>
                      )}
                      <span className='d-flex flex-row align-items-center text-gray'>
                        <IconCountry
                          className='mr-2'
                          size={12}
                          color='#35425B'
                        />
                        {countries && userData
                          ? countries.docs.filter(
                              (e) => e.id === userData?.countryId,
                            )[0]?.name
                          : ''}
                      </span>
                    </span>
                    <div className='d-flex flex-row mt-4'>
                      <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1'>
                        <IconUser className='mr-1' size={12} />
                        Profile
                      </Badge>
                      <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1 mx-2'>
                        <IconUser className='mr-1' size={12} />
                        Course material
                      </Badge>
                      <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-0 font-weight-bold'>
                        +
                      </Badge>
                    </div>
                  </div>
                </div>
              )}
              <div className='d-flex flex-row'>
                <IconOptions
                  color='#D7E1EA'
                  onClick={() => {}}
                  className='mr-2'
                  style={{transform: 'rotate(90deg)'}}
                />
                <IconClose color='#D7E1EA' onClick={toggle} />
              </div>
            </div>
            <div className='m-0 p-0 w-100'>
              <Collapse isOpen={activeTab === 'profile'}>
                <Profile
                  style={profileMenuDimensions}
                  openSideModal={openSideModal}
                  toggleSideModal={toggleSideModal}
                  setActiveTabRight={setActiveTabRight}
                  userData={userData}
                />
              </Collapse>
            </div>
          </div>
        }
        sideLeft={
          <div className='pl-6 pl-sm-7 m-0 pt-4 pr-0'>
            <div
              ref={(el) => {
                if (!el) return
                navMenuDimensions = el.getBoundingClientRect()
              }}
              className='znk-scroll-horizontal'
              style={{overflowX: 'scroll'}}
            >
              <Nav className='w-100 flex-nowrap'>
                <NavItem
                  className='nav-title mb-2 mr-2'
                  active={activeTab === 'summary'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('summary')
                    }}
                  >
                    {translate('student.modal.summary.title')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mb-2 mr-2'
                  active={activeTab === 'calendar'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('calendar')
                    }}
                  >
                    {translate('student.modal.calendar.title')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2'
                  active={activeTab === 'learn'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('learn')
                    }}
                  >
                    {translate('student.modal.learn.title')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2'
                  active={activeTab === 'profile'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('profile')
                    }}
                  >
                    {translate('student.modal.profile.title')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2'
                  active={activeTab === 'plans'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('plans')
                    }}
                  >
                    {translate('student.modal.plans.title')}
                  </NavLink>
                </NavItem>
                <NavItem
                  className='nav-title mr-2'
                  active={activeTab === 'timeline'}
                >
                  <NavLink
                    onClick={() => {
                      setActiveTab('timeline')
                    }}
                  >
                    {translate('student.modal.timeline.title')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent
              activeTab={activeTab}
              className={`${
                activeTab === 'calendar' ? 'pr-2 pl-0 mr-5' : 'px-2'
              }`}
            >
              <TabPane tabId='summary'>
                <Summary
                  loading={loadStudentResume}
                  studentResume={studentResume}
                />
              </TabPane>
              <TabPane tabId='calendar'>
                <Calendar userData={userData} />
              </TabPane>
              <TabPane tabId='learn'>
                <Learn page={activeTab} idStudent={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='profile' />
              <TabPane tabId='plans'>
                <Plans page={activeTab} idStudent={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='timeline'>
                <Timeline />
              </TabPane>
            </TabContent>
          </div>
        }
        sideRight={
          <TabContent activeTab={activeTabRight} className='px-2'>
            <TabPane tabId='editProfile'>
              {/** <FullProfile user={userData} type={'student'} toggleRightSide={toggleSideModal}/>**/}
              <span>Edit profile</span>
            </TabPane>
            <TabPane tabId='viewProfile'>
              <span>view profile</span>
            </TabPane>
            <TabPane tabId='editStudentInfo'>
              <span>Change student info</span>
            </TabPane>
            <TabPane tabId='editFamilyInfo'>
              <span>Change family info</span>
            </TabPane>
            <TabPane tabId='setServiceRate'>
              <span>setServiceRate</span>
            </TabPane>
            <TabPane tabId='notificationSettings'>
              <span>Change notification setting</span>
            </TabPane>
            {/** <TabPane tabId='changePaymentMethod'>
              <div>changePaymentMethod</div>
              </TabPane>**/}
            <TabPane tabId='changeTimezone'>
              <ChangeTimezone
                currentTimezone={userData?.timezone}
                toggle={toggleSideModal}
                userId={userData?.id}
              />
            </TabPane>
            <TabPane tabId='changePassword'>
              <ChangePassword toggle={toggleSideModal} />
            </TabPane>
          </TabContent>
        }
        overRight={null}
        more={openSideModal}
      />
    </>
  )
}

export default InfoStudent
