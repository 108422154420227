import React from 'react'
import {Timeline} from '@Knowledge-OTP/znk-ui-components'

const TimelinePage = () => {
  return (
    <div
      className='d-flex flex-column mx-0 my-4 p-0 text-title'
      style={{filter: 'blur(3px)'}}
    >
      <Timeline />
      <Timeline />
      <Timeline />
    </div>
  )
}

export default TimelinePage
