import React from 'react'
import {Avatar, IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'

const ViewCounselor = (props) => {
  const {user} = props

  return (
    <TabContent activeTab={user ? 'show' : 'notShow'}>
      <TabPane tabId='notShow'>{null}</TabPane>
      <TabPane tabId='show'>
        <div className='d-flex flex-column'>
          <div className='d-flex py-3 justify-content-between flex-row border-top border-bottom'>
            <div className='d-flex flex-row'>
              <Avatar
                uri={user?.avatar}
                name={user?.firstName + ' ' + user?.lastName}
                size='avatar-medium'
              />
              <div className='d-flex flex-row m-2'>
                <span>
                  {user?.firstName + ' ' + user?.lastName?.charAt(0) + '.'}
                </span>
              </div>
            </div>
            <div className='m-2'>
              <IconArrowRight size={15} color='#D5D8DD' />
            </div>
          </div>
        </div>
      </TabPane>
    </TabContent>
  )
}

export default ViewCounselor
