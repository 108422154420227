import React from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  TimezoneSelector,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {CHANGE_TIMEZONE} from '../../../../store/user/const'
import UseUpdateTimezone from './Model/useUpdateTimezone'
import {showAlert} from '../../../../common/Alert/util'

const ChangeTimezoneModal = () => {
  const dispatch = useDispatch()
  const {isOpen} = useSelector(
    (state) => state.modal.calendar.changeTimezoneModal,
  )
  const time = useSelector((state) => state.user.info.timezone)
  const [changeTimezoneMutation, {loading: loadMutation}] = UseUpdateTimezone()

  const setTime = (time) => {
    // ejecutar mutación updateTimezone
    changeTimezoneMutation({
      variables: {
        newTimezone: time,
      },
    }).then(
      ({data: {updateTimezone}}) => {
        dispatch({
          type: CHANGE_TIMEZONE,
          data: updateTimezone,
        })
        showAlert(
          {
            text: translate('modal.changeTimezone.successAlert'),
            status: 'success',
          },
          dispatch,
        )
        toggle()
      },
      (err) => {
        console.error(err)
        showAlert(
          {
            text: translate('modal.changeTimezone.errorAlert'),
            status: 'error',
          },
          dispatch,
        )
      },
    )
  }
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'calendar.changeTimezoneModal',
      data: {
        isOpen: !isOpen,
      },
    })
  }
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex flex-row justify-content-between mb-4 px-5'>
            <div className='d-flex flex-column justify-content-start mr-4'>
              <span className='text-title font-weight-bold h2'>
                {translate('home.onboard.approve.selecttime')}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start text-cancel'
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        {breakWidth === 'SM' ? (
          <TimezoneSelector
            activity={loadMutation}
            selected={time}
            mutation={(time) => {
              if (setTime) setTime(time)
            }}
          />
        ) : (
          <TimezoneSelector
            activity={loadMutation}
            className='mx-5'
            selected={time}
            mutation={(time) => {
              if (setTime) setTime(time)
            }}
          />
        )}
      </Modal>
    </>
  )
}

ChangeTimezoneModal.propTypes = {}

export default ChangeTimezoneModal
