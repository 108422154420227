import React, {useState} from 'react'
import {
  Button,
  IconClose,
  IconArrowRight,
  Avatar,
  ListItem,
  IconSignMinus,
  IconIllustratedPending,
  IconVideo,
  IconPenalize,
  IconArrowDown,
  IconArrowUp,
  DropDown,
  Countdown,
  IconSignPlus,
  IconSignCheck,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import {Badge, Collapse} from 'reactstrap'
// import sendLessonAttendance from '../../../../../Model/Lessons/SendLessonAttendance'
import useTranslation from '../../../../../i18n/useTranslation'
import SendLessonNotes from '../../SendLessonNotes'
import {hasHttp} from '../../utils'
import WasSubstitutedMsg from '../../../Components/WasSubstitutedMsg'

const IncompleteHeaderModal = (props) => {
  const {dataLesson, toggle} = props
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }

  const {translate} = useTranslation()
  const makeup = dataLesson?.isMakeUp || false
  const madeup = dataLesson?.makeUpInfo !== null || false

  const topic =
    dataLesson.groupTopic ||
    dataLesson.individualTopic ||
    dataLesson.planTopic ||
    {}
  const service = dataLesson.service

  const penalizationInfo = dataLesson?.penalizationInfo

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus className='ml-auto text-cancel' />
        <IconClose className='ml-auto text-cancel' onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 pt-3 pb-1'>
        <IconIllustratedPending size={80} />
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <span className='my-1 h2 font-weight-bold text-title'>
            {dataLesson && `${dataLesson.title}`}
          </span>
          <span className='my-1 h4 text-title'>
            {dataLesson &&
              dataLesson.startDate &&
              dataLesson.endDate &&
              `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(
                dataLesson.endDate,
              ).format('hh:mm a')} | ${separateCamelCase(
                moment(dataLesson.startDate).format('MMM'),
              )} ${moment(dataLesson.startDate).format('DD, YYYY')}`}
          </span>
          <span className='h45 my-1 pr-2 text-gray'>
            {dataLesson &&
              dataLesson.__typename &&
              service &&
              `${separateCamelCase(service?.name)} | ${separateCamelCase(
                topic?.name,
              )} | ${separateCamelCase(
                dataLesson?.location?.type || 'No defined',
              )} | ${separateCamelCase(
                dataLesson.__typename?.replace('Lesson', ''),
              )}`}
          </span>
          <div className='d-flex flex-row m-0 p-0'>
            <Badge
              className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-warning p-0'
              pill
            >
              {translate('modal.infolesson.lessonincomplete')}
            </Badge>
            {makeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.makeup')}
              </Badge>
            )}
            {madeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.madeup')}
              </Badge>
            )}
            {penalizationInfo && (
              <Badge
                className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                  <DropDown
                    className='d-flex flex-row align-items-center'
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component: (
                          <div className='d-flex flex-row h45 px-2 py-1'>
                            <div
                              title={translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                              className='text-title mr-3 ellipsis-text'
                              style={{width: '124px'}}
                            >
                              {translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                            </div>
                            <div className='text-title'>{`-$${parseFloat(
                              penalizationInfo?.amount,
                            ).toFixed(2)}`}</div>
                          </div>
                        ),
                      },
                    ]}
                  >
                    <IconPenalize color='red' size={15} />
                  </DropDown>
                </div>
              </Badge>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const IncompleteBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    // createdDate,
    // updateDate,
    RenderStudents,
    refetchCalendarQuery,
    isSubstitute,
    wasSubstituted,
    // setStatusOfInfoLessonState,
    // setDataLessonToRedux,
    // educatorAttendanceState,
    // studentAttendanceState,
    // checkAttendanceChanges,
    // resetAttendance
  } = props

  // const [educatorAttendance, setEducatorAttendance] = educatorAttendanceState
  // const [studentAttendance, setStudentAttendace] = studentAttendanceState

  // const [setAttendanceMutation, { loading }] = sendLessonAttendance(
  //  (result) => { setDataLessonToRedux(result.data.sendLessonAttendance) },
  //  (error) => { console.error(error) }
  // )

  // const [substituteEducator, setSubstituteEducator] = useState(null)
  const {translate} = useTranslation()

  // useEffect(() => {
  //  if (dataLesson?.substitute) {
  //    setSubstituteEducator(dataLesson?.substitute)
  //    setEducatorAttendance(dataLesson?.substitute?.attendanceStatus)
  //  } else setSubstituteEducator(null)
  // }, [dataLesson, setSubstituteEducator, setEducatorAttendance])

  // const attended = 'attended'

  // const rating = (Array.isArray(student))
  //  ? student?.reduce((totalRating, stu) => {
  //    return totalRating + (stu?.rating === null ? 0 : stu?.rating)
  //  }, 0) / (student?.length > 0 ? student?.length : 1)
  //  : (student?.rating === null ? 0 : student?.rating)

  const timeToComplete = moment(dataLesson?.endDate).clone().add(24, 'hours')
  // const rateStudent = Array.isArray(student)
  //  ? `$${student.reduce((minRate, stu) => {
  //    if (minRate > stu?.rate) return stu?.rate
  //    return minRate
  //  }, Number.POSITIVE_INFINITY).toFixed(2)} ~
  //  $${student.reduce((maxRate, stu) => {
  //    if (maxRate < stu?.rate) return stu?.rate
  //    return maxRate
  //  }, Number.NEGATIVE_INFINITY).toFixed(2)}`
  //  : (student) && `$${(student?.rate || 0).toFixed(2)}`
  // const ignorePaymentStudent = Array.isArray(student)
  //  ? false
  //  : student?.ignorePaymentItem
  // const makeup = dataLesson?.isMakeUp || false

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }

  // const timeSince = dataLesson?.endDate
  //  ? moment.duration(moment().diff(moment(dataLesson.endDate)))
  //  : moment.duration(moment().diff(moment()))

  const penalizationInfo = dataLesson?.penalizationInfo
  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-between h-100'>
      <div className='d-flex flex-column m-0 p-0'>
        {/** <div className='d-flex flex-row justify-content-between my-2'>
          <span className='font-weight-bold'>{translate('modal.infolesson.price')}</span>
          <span className={`font-weight-bold ${ignorePaymentStudent ? 'text-gray' : ''}`} style={ignorePaymentStudent ? { textDecoration: 'line-through' } : {}}>{rateStudent}</span>
        </div>**/}
        <div
          className={`d-flex flex-row justify-content-between my-2 ${
            !penalizationInfo ? 'mb-6' : ''
          }`}
        >
          <span className='font-weight-bold'>
            {translate('modal.newlesson.educatorwage')}
          </span>
          <span
            className={`font-weight-bold ${
              educator?.ignorePaymentItem ? 'text-gray' : ''
            }`}
            style={
              educator?.ignorePaymentItem
                ? {textDecoration: 'line-through'}
                : {}
            }
          >{`$${educator && educator.rate.toFixed(2)}`}</span>
        </div>
        {penalizationInfo && (
          <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
            <span className='text-error'>
              {translate('modal.penalizelesson.penalty')}
            </span>
            <span className='text-error'>{`-$${
              penalizationInfo?.amount && penalizationInfo.amount.toFixed(2)
            }`}</span>
          </div>
        )}
        <div className='m-0 p-0 d-flex flex-column fragment-students-educator'>
          <ListItem
            showTopLine
            showBottomLine
            Left={<RenderStudents student={student} />}
            Right={
              <div className='d-flex flex-row align-items-center px-1'>
                {Array.isArray(student) ? (
                  <div
                    className='hover-icon py-1 px-2'
                    onClick={toggleCollapseGroupStudent}
                  >
                    {collapseGroupStudent ? <IconArrowUp /> : <IconArrowDown />}
                  </div>
                ) : student?.attendanceStatus === 'attended' ? (
                  <IconSignCheck color='#8FC813' />
                ) : student?.attendanceStatus === 'missed' ? (
                  <IconSignPlus color='red' />
                ) : (
                  <IconSignMinus color='#72809D' />
                )}
              </div>
            }
          />
          <Collapse isOpen={collapseGroupStudent}>
            {Array.isArray(student) &&
              student.map((stu) => {
                return (
                  <div>
                    <ListItem
                      className='py-2'
                      Left={
                        <div className='d-flex flex-row my-0 py-0 mr-7'>
                          <Avatar
                            name={stu && `${stu.firstName} ${stu.lastName}`}
                            named={false}
                            uri={null}
                            innerDiv
                            size='avatar-small mr-1'
                          />
                          <div
                            className='h45 align-self-center text-dark'
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {stu && `${stu.firstName} ${stu.lastName} `}
                          </div>
                        </div>
                      }
                      Right={
                        <div className='d-flex flex-row align-items-center px-1'>
                          {stu?.attendanceStatus === 'attended' ? (
                            <IconSignCheck color='#8FC813' />
                          ) : stu?.attendanceStatus === 'missed' ? (
                            <IconSignPlus color='red' />
                          ) : (
                            <IconSignMinus color='#72809D' />
                          )}
                        </div>
                      }
                    />
                  </div>
                )
              })}
            <div className='borderTop' />
          </Collapse>
        </div>
        <WasSubstitutedMsg wasSubstituted={wasSubstituted} />
        {!wasSubstituted && (
          <SendLessonNotes
            dataLesson={dataLesson}
            refetchCalendarQuery={refetchCalendarQuery}
          >
            {!isSubstitute && (
              <Button
                style={{backgroundColor: '#E7AD00'}}
                className='w-100 mt-6'
                size='large'
                label={
                  <div className='d-flex flex-row justify-content-between'>
                    <span>
                      {translate('modal.infolesson.completelessonnotes')}
                    </span>
                    <IconArrowRight />
                  </div>
                }
              />
            )}
          </SendLessonNotes>
        )}
        <div
          className='zkn-btn znk-size-small w-100 mb-6 mt-3 btn'
          style={{backgroundColor: '#FAEECD'}}
        >
          <div className='d-flex flex-row justify-content-between'>
            <span className='h45 text-incomplete'>
              {translate('modal.infolesson.timetocomplete')}
            </span>
            <Countdown
              endTime={timeToComplete}
              iconColor='#E7AD00'
              timeOverLabel={translate('modal.infolesson.timeisover')}
              spanTimeClassName='h45 text-incomplete'
            />
          </div>
        </div>
        {dataLesson.recordingUrl && (
          <ListItem
            showTopLine
            className='py-4'
            onClick={() => {
              window.open(hasHttp(dataLesson.recordingUrl), '_blank')
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconVideo color='#72809D' className='align-self-center mr-2' />
                <span className='align-self-center text-title'>
                  {translate('modal.infolesson.viewlessonrecording')}
                </span>
              </div>
            }
            Right={<IconArrowRight />}
          />
        )}
      </div>
      <div className='mb-6' />
      {/** <div
        className='pb-4 mt-5'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.cancelLesson',
            data: {
              isOpen: true,
              lessonId: dataLesson?.id || '',
            },
          })
        }}
      >
        <span className='h5 text-gray'>
          {translate('modal.infolesson.youneedremove')}
        </span>
      </div>**/}
    </div>
  )
}

export {IncompleteHeaderModal, IncompleteBodyModal}
