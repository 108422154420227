import React from 'react'
import {useCookies} from 'react-cookie'
import firebase from 'firebase/app'
import 'firebase/auth'
import {IconUser, ListItem} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
const Logout = () => {
  const [, , removeCookie] = useCookies(['znk-signup'])
  const signOut = () => {
    removeCookie('znk-signup')
    firebase.auth().signOut()
  }
  const {translate} = useTranslation()
  return (
    <ListItem
      className='w-100'
      showTopLine={false}
      showBottomLine
      hasHover
      hasColorHover
      onClick={signOut}
      Left={
        <span className='d-flex align-items-center text-error'>
          <IconUser size={16} className='mr-3 text-error' />
          <span className='text-error'>{translate('profile.signOut')}</span>
        </span>
      }
    />
  )
}

export default Logout
