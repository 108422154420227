import React, {useEffect, useState, useMemo} from 'react'
import {FormGroup, Label} from 'reactstrap'
import {
  Field,
  Form,
  InputSelect,
  LabeledField,
  PlainTextInput,
  Select as Rselect,
  SelectOption,
  PhoneSelector,
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
import {useSelector} from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import {gql, useMutation} from '@apollo/client'
import queryUserHashResult from '../../../Model/QueryResult/useUserHashResult'
import validateForm from './validation'
import {isEmpty, pick} from 'lodash'
import escapeRegExp from 'lodash/escapeRegExp'
import usStatesList from '../Utils/us_states.json'

const PersonalModalForm = (props) => {
  const countries = useSelector((state) => state.countries.countries)
  const parseCountries = useMemo(() => {
    return Object.values(countries).sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }, [countries])
  const usStates = usStatesList?.map((e) => {
    return {label: e.name, abb: e.abbreviation}
  })
  const {refetch: refetchUser} = useSelector((state) => state.user)
  const {translate} = useTranslation()
  const [validationErrors, setValidationErrors] = useState({})
  const {phone, birthdate, setPersonalInfoCallBack, setErrorValues} = props
  const [selectCode, setSelectCode] = useState('')
  const dialCode = phone
    ? phone
        .split(' ')
        .slice(0, phone.split(' ')?.length - 1)
        .join(' ')
    : ''
  const phoneNumber = phone
    ? phone.split(' ')[phone.split(' ')?.length - 1]
    : ''

  const [form, setForm] = useState({
    ...props,
    phoneDialCode: dialCode,
    phoneNumber: phoneNumber,
    birthDay: birthdate ? moment(birthdate).date() : null,
    birthMonth: birthdate ? moment(birthdate).month() : null,
    birthYear: birthdate ? moment(birthdate).year() : null,
  })
  const [updInfo] = useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          ${queryUserHashResult}
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetchUser()
      },
    },
  )

  const submit = () => {
    const updData = pick(form, [
      'firstName',
      'lastName',
      'countryId',
      'address',
      'city',
      'gender',
      'state',
      'zipCode',
      'spokenLanguages',
    ])
    updData.phone = `${form.phoneDialCode.trimEnd()} ${form.phoneNumber}`
    updData.birthdate = moment()
      .year(form.birthYear)
      .month(form.birthMonth)
      .date(form.birthDay)
      .toISOString()
    updInfo({variables: {input: updData, id: form.id}})
      .then((data) => {
        console.log(data)
      })
      .catch(() => {
        console.log('Error to update Personal Info.')
      })
  }
  const checkErrors = () => {
    const errors = validateForm(form)
    if (!isEmpty(errors)) {
      setValidationErrors(errors)
      return
    }
    setValidationErrors({})
  }

  const [selectedCountry, setSelectedCountry] = useState(form.countryId)
  const [easyState, setEasyState] = useState(form.state)
  const handleStateChange = (value) => {
    setEasyState(value)
  }
  const handleCountryChange = (value) => {
    const idCountry = parseCountries.find((country) => country.id === value)
    if (idCountry) {
      setSelectedCountry(idCountry.id)
    }
    setEasyState(null)
  }

  useEffect(() => {
    setErrorValues((v) => {
      return {
        ...v,
        personalInfoErrors: validateForm(form),
      }
    })
    setPersonalInfoCallBack(() => submit)
    checkErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const userData = useSelector((state) => state.user.info)
  const disabledNames = useMemo(() => {
    return !!userData?.firstName && !!userData?.lastName
  }, [userData])

  return (
    <Form value={form} onChange={setForm} validationErrors={validationErrors}>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.studentName')}</Label>
        <div className='row'>
          <div className='col-6 pr-0'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='firstName'
              placeholder={translate(
                'profile.actions.editPersonalInfo.firstName',
              )}
              className='noRight'
              disabled={disabledNames}
            />
          </div>
          <div className='col-6 pl-1'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='lastName'
              placeholder={translate(
                'profile.actions.editPersonalInfo.lastName',
              )}
              className='noLeft'
              disabled={disabledNames}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.actions.editPersonalInfo.gender')}</Label>
        <Field
          type={LabeledField}
          component={SelectOption}
          className='w-100'
          fieldName='gender'
          options={[
            {
              id: 'male',
              text: translate('home.onboard.approve.profile.male'),
            },
            {
              id: 'female',
              text: translate('home.onboard.approve.profile.female'),
            },
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.birthday')}</Label>
        <div className='row'>
          <div className='col-4 pr-1'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthDay'
              options={new Array(31)
                .fill(0)
                .map((_, idx) => ({label: idx + 1, value: idx + 1}))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              isClearable={false}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
            />
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='birthDay'
              style={{display: 'none'}}
            />
          </div>
          <div className='col-4 pl-0 pr-1'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthMonth'
              options={new Array(12).fill(0).map((_, idx) => ({
                label: moment().month(idx).format('MMMM'),
                value: idx,
              }))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              isClearable={false}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
            />
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='birthMonth'
              style={{display: 'none'}}
            />
          </div>
          <div className='col-4 pl-0'>
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName='birthYear'
              options={new Array(80)
                .fill(0)
                .map((_, idx) => ({label: 2020 - idx, value: 2020 - idx}))}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              isClearable={false}
              filterOption={(obj, string) => {
                if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
                  return true
                return false
              }}
            />
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName='birthYear'
              style={{display: 'none'}}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className='mb-0'>
        <Label>{translate('profile.actions.editPersonalInfo.phone')}</Label>
        <div className='row'>
          <div className='col-6 pr-1'>
            <PhoneSelector
              defaultValue={form.phoneDialCode || ''}
              selectCode={selectCode}
              setSelectCode={(dial) => {
                setForm({
                  ...form,
                  phoneDialCode: dial,
                })
                setSelectCode(dial)
              }}
            />
          </div>
          <div className='col-6 pl-0'>
            <Field
              type={LabeledField}
              component={PlainTextInput}
              className='noLeft'
              fieldName='phoneNumber'
              placeholder='12345621'
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.address')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='address'
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.city')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='city'
        />
      </FormGroup>
      {selectedCountry === '5f778da89097bc5330ab1739' ? (
        <FormGroup>
          <Label>{translate('profile.actions.editPersonalInfo.state')}</Label>
          <Field
            type={InputSelect}
            component={Rselect}
            fieldName='state'
            onChange={(name) => {
              handleStateChange(name)
              setForm({
                ...form,
                state: name,
              })
            }}
            value={easyState}
            options={usStates.map((state, _) => ({
              label: state.label,
              value: state.label,
            }))}
            isClearable={false}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>{translate('profile.actions.editPersonalInfo.state')}</Label>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName='state'
            value={easyState}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.zipCode')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='zipCode'
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate('profile.actions.editPersonalInfo.country')}</Label>
        <Field
          type={InputSelect}
          component={Rselect}
          onChange={(name) => {
            handleCountryChange(name)
            setForm({
              ...form,
              countryId: name,
            })
          }}
          fieldName='countryId'
          value={selectedCountry}
          options={parseCountries.map((country, _) => ({
            label: country.name,
            value: country.id,
          }))}
          isClearable={false}
          filterOption={(obj, string) => {
            if (new RegExp(escapeRegExp(string), 'i').test(obj.label))
              return true
            return false
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>
          {translate('profile.actions.editPersonalInfo.spokenLanguages')}
        </Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='spokenLanguages'
        />
      </FormGroup>
    </Form>
  )
}

export default PersonalModalForm
