import translate from '../../i18n/translate'
const navItemClassName = 'm-0'
const navLinkClassName = 'pr-2 pr-sm-4 py-2'
export const navItems = [
  {
    href: '/calendar/lessons',
    Body: translate('program.nav.calendar'),
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/calendar/timeline',
    Body: translate('program.nav.timeline'),
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/calendar/plans',
    Body: translate('program.nav.plans'),
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
]
