import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import * as moment from 'moment'
import {IconArrowRight, IconSignCheck} from '@Knowledge-OTP/znk-ui-components'
import useCompletionTask from '../../../../Task/Model/useCompletionTask'
import useAllUserTasks from '../../../../Task/Model/useAllUserTasks'
import useGetUserTask from '../../../../Task/Model/useGetUserTask'
import MessageModal from './Modals/messageModal'
import ShowButtonByStatus from './showButtonsByStatus'
import SendToReviewButtonOff from './Buttons/sendToReviewOff'
import InfoTaskButton from './Buttons/infoTaskButton'
import useTranslation from '../../../../../i18n/useTranslation'
import phaseNames from '../../../Utils/phaseNames'

const CompletionTask = (props) => {
  // eslint-disable-next-line
  const {task, uploadStatusOk, filesUploadedByUser } = props

  const [taskData, setTaskData] = useState(task)
  const planId = useSelector(
    (state) => state.user.info.studentInfo.homeNotification.councelingPlanId,
  )

  const refetchUserData = useSelector((state) => state.user.refetch)
  const [render, setRender] = useState(false)
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)

  const {translate} = useTranslation()

  const infoByStatus = {
    pending: {
      text: taskData?.buttonLabel,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconArrowRight
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
      onClick: () => CompletionTaskFunction(),
    },
    review: {
      text: `Submitted on: ${moment(task?.reviewDate).format(
        'MMM Do, YY h:mm a',
      )}`,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
      onClick: () => {},
    },
    completed: {
      text: translate('counseling.tasks.completionCompletedInfo'),
      colorText: '#72809D',
      background: '#F0F4F8',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#72809D'
        />
      ),
      onClick: () => {},
    },
  }

  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  // eslint-disable-next-line
  const { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask } = useGetUserTask({
    taskId: taskData?.id,
    stepId: taskData?.stepId,
    phase: phaseNames[task.phase] || phaseNames[task.phaseName],
    planId,
  })

  const {
    submit: completionSubmit,
    loading: completionLoading,
    data: completionData,
  } = useCompletionTask(() => {})

  const CompletionTaskFunction = () => {
    try {
      completionSubmit({
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[task?.phase] || task?.phaseName || phaseNames[task?.phaseId],
        planId,
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
  }

  /* eslint-disable */
  useEffect(() => {

    if (completionData) {
      refetchUserData()
      refetchTasks()

      refetchOneTask().then((resp) => {
        setTaskData(resp?.data?.getUserTask)
      })
      setRender(!render)
    }

  }, [completionData])
  /* eslint-enable */

  useEffect(() => {
    setTaskData(task)
  }, [task])

  return (
    <>
      <div className='d-flex flex-column justify-content-center py-2'>
        <InfoTaskButton
          background={infoByStatus[taskData?.status].background}
          colorText={infoByStatus[taskData?.status].colorText}
          task={taskData}
          onClick={infoByStatus[taskData?.status].onClick}
          icon={infoByStatus[taskData?.status].icon}
          textLabel={infoByStatus[taskData?.status].text}
        />
        {task?.status !== 'pending' &&
          task?.resolveTask?.uploadFiles?.length > 0 &&
          task?.resolveTask?.uploadFiles?.map((file) => {
            return (
              <InfoTaskButton
                background={infoByStatus[task?.status].background}
                colorText={infoByStatus[task?.status].colorText}
                onClick={() => {
                  window.open(file.url)
                }}
                icon={infoByStatus[task?.status].icon}
                textLabel={file.url.split('/').at(-1)}
              />
            )
          })}

        {taskData?.status === 'pending' && !uploadStatusOk ? (
          <SendToReviewButtonOff />
        ) : (
          <ShowButtonByStatus
            taskData={taskData}
            completionLoading={completionLoading}
            filesUploadedByUser={filesUploadedByUser}
          />
        )}
      </div>

      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        message={message}
      />
    </>
  )
}

export default CompletionTask
