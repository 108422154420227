import React, {useState, useMemo, useRef} from 'react'
import {
  IconClose,
  useWindowDimensions,
  Table,
  IconSuccess,
  IconArrowRight,
  IconArrowLeft,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import Pagination from '../../../common/Pagination'
import {Badge, TabContent, TabPane} from 'reactstrap'

import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

import LayoutSideRightSection from '../Components/LayoutViewRightSection'
import UseTeachStudentDetails from './Queries/useTeachStudentDetails'
import UseColumns from '../Teach/useColumns.js'
// import StudentModal from '../../Student/modal/InfoStudent'
import avatar from '../../../assets/Avatar.png'
import beanWar from '../../../assets/beanWar.svg'
import CollapseMsg from './CollapseMsg'

import SkeletonTable from './Skeleton'
// complete lesson modal (send lesson notes)
import SendLessonNotesModal from '../../CalendarLessons/modal/SendLessonNotes/SendLessonNotesModal'

const TeachView = () => {
  const dispatch = useDispatch()

  const [isOpen, setOpen] = useState(true)
  const toggle = () => {
    setOpen(!isOpen)
  }
  const {breakWidth} = useWindowDimensions()
  const mobile = ['XS', 'SM'].includes(breakWidth)

  const {educatorInfo, id: userId} = useSelector((state) => state.user.info)
  const {services} = educatorInfo

  // translation
  const {translate} = useTranslation()

  // input search
  const searchInput = useRef(null)

  // pagination
  // const [page, setPage] = useState(1)
  // const [pageSize, setPageSize] = useState(10)

  const [query, setQuery] = useState({
    services: [],
    search: null,
    page: 1,
    limit: 10,
  })
  // API Data
  const {
    data,
    loading: loadTeach,
    refetch: refetchTableData,
  } = UseTeachStudentDetails(query)
  const {docs: teachStudents, totalDocs} = useMemo(() => {
    return data?.getTeachStudentDetails || {docs: [], totalDocs: 0}
  }, [data])

  const columns = UseColumns(() => {
    refetchTableData()
  })

  // recibe el id de una servicio y aplica el filtro
  // si el id ya esta en el arreglo lo elimina
  const applyFilterService = (serviceId) => {
    if (query?.services?.includes(serviceId)) {
      // elimina el id del arreglo query.services
      setQuery({
        ...query,
        page: 1,
        services: (query.services || []).filter((id) => id !== serviceId),
      })
    } else {
      setQuery({
        ...query,
        page: 1,
        services: (query.services || []).concat([serviceId]),
      })
    }
  }

  const style = {
    backgroundImage: `url(${beanWar}), url(${beanWar}), url(${beanWar})`,
    backgroundPosition: '70% -74%, 30% -23%, 88% 52%',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed, fixed, fixed',
    backgroundSize: '300px, 200px, 150px',
  }

  // redux modal sendLessonNotes
  const reduxLessonNotes = useSelector(
    (state) => state.modal.calendar.sendLessonNotes,
  )
  const {isOpen: isOpenNotes, dataLesson: dataLessonNotes} = reduxLessonNotes
  const toggleModalSendNotes = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'calendar.sendLessonNotes',
      data: {
        ...reduxLessonNotes,
        isOpen: !isOpenNotes,
      },
    })
  }

  return (
    <>
      <LayoutSideRightSection
        isOpen={isOpen}
        toggle={toggle}
        styleLeftSection={style}
        leftSide={
          <div
            className='position-relative w-100 h-100 pt-5 pb-0 px-6 d-flex flex-column justify-content-between'
            style={{overflowY: 'scroll'}}
          >
            <div className='d-flex flex-column m-0 p-0'>
              {!mobile && (
                <div
                  className='znk-option-item'
                  style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                  }}
                  onClick={toggle}
                >
                  {isOpen ? (
                    <IconArrowRight size={15} />
                  ) : (
                    <IconArrowLeft size={15} />
                  )}
                </div>
              )}
              {/** i18n: workspace.teach.topMsg **/}
              <CollapseMsg userId={userId} />
              {/** i18n: workspace.teach.table **/}
              <div className='d-flex flex-row m-0 my-3 ml-4 p-0 justify-content-between align-items-center'>
                <div className='m-0 p-0 d-flex flex-row  justify-content-start align-items-center'>
                  <span className='h3 font-weight-bold text-title mr-4'>
                    {translate('workspace.teach.table.title')}
                  </span>
                </div>
                <input
                  name='search'
                  type='text'
                  id='search'
                  ref={searchInput}
                  onKeyUp={({key}) => {
                    if (key === 'Enter') {
                      setQuery({
                        ...query,
                        page: 1,
                        search: searchInput?.current?.value,
                      })
                    }
                  }}
                  placeholder={translate('workspace.teach.table.search')}
                  className='w-25 znk-input px-2 mr-5'
                />
              </div>
              <div className='d-flex flex-row mx-0 my-2 p-0'>
                <Badge
                  onClick={() => {
                    setQuery({...query, services: [], page: 1})
                  }}
                  color={query.services.length === 0 ? 'primary' : 'secondary'}
                  className='ml-0 mr-1 mt-3 px-3 mr-1 p-0 cursor-pointer'
                  pill
                >
                  {translate('workspace.teach.table.all')}
                </Badge>
                {(services || []).map(({serviceId, name}) => {
                  return (
                    <Badge
                      onClick={() => {
                        applyFilterService(serviceId)
                      }}
                      color={
                        query.services.includes(serviceId)
                          ? 'primary'
                          : 'secondary'
                      }
                      className='ml-0 mr-1 mt-3 px-3 mr-1 p-0 cursor-pointer'
                      pill
                    >
                      {name}
                    </Badge>
                  )
                })}
              </div>
              <div className='w-100'>
                <TabContent activeTab={loadTeach ? 'loading' : 'table'}>
                  <TabPane tabId='loading'>
                    <SkeletonTable />
                  </TabPane>
                  <TabPane tabId='table'>
                    <Table
                      loading={loadTeach}
                      tableData={teachStudents.map((stu) => {
                        return {
                          ...stu,
                          id: stu.studentUserId,
                          name: `${stu.firstName} ${stu.lastName}`,

                          servicesDisplay: stu.topics
                            .map((top) => {
                              return top.serviceName
                            })
                            .join(','),

                          topicsDisplay: stu.topics
                            .map((top) => {
                              return top.name
                            })
                            .join(','),
                          topics: stu.topics || [],
                          materialsTopic: stu?.materialsTopic || [],
                          action: stu?.action || {},
                        }
                      })}
                      columnList={columns}
                      style={{cursor: 'pointer'}}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <Pagination
              bottomFix
              loading={loadTeach}
              pageSize={query.limit}
              changeSize={(size) => {
                setQuery({...query, limit: size})
              }}
              clickNext={() => {
                if (query?.page < Math.ceil(totalDocs / (query?.limit || 1))) {
                  setQuery({...query, page: query.page + 1})
                }
              }}
              clickPrevious={() => {
                if (query?.page > 1) {
                  setQuery({...query, page: query.page - 1})
                }
              }}
              hasPrevious={query?.page > 1}
              hasNext={Math.ceil(totalDocs / (query?.limit || 0)) > query?.page}
              activePage={query?.page}
              pageCount={Math.ceil(totalDocs / (query?.limit || 0))}
            />
          </div>
        }
        rightSide={
          <div className='m-0 p-0 d-flex flex-column justify-content-between p-5 pl-6 h-100'>
            {/** i18n: workspace.teach.right **/}
            <div className='m-0 p-0 d-flex flex-column'>
              <div className='m-0 p-0 d-flex flex-row justify-content-between align-items-start'>
                {/** eslint-disable-next-line**/}
                <img src={avatar} className='w-25' alt='' />
                <div className='m-0 p-0 hover-icon' onClick={toggle}>
                  {!mobile && <IconClose className='text-cancel' size={20} />}
                </div>
              </div>
              <div className='d-flex flex-column m-0 p-0'>
                <span
                  className='h2 text-title font-weight-bold mr-6 mt-4'
                  style={{lineHeight: '1.6'}}
                >
                  {translate('workspace.teach.right.topinfo.title')}
                </span>
                <span className='h4 text-title' style={{lineHeight: '3'}}>
                  {translate('workspace.teach.right.topinfo.subtitle')}
                </span>
                <div
                  className='m-0 p-0 d-flex flex-column'
                  style={{lineHeight: '2'}}
                >
                  <span className='h45 d-flex flex-row align-items-center text-gray'>
                    <IconSuccess size={16} className='mr-2' />{' '}
                    {translate('workspace.teach.right.topinfo.attribute1')}
                  </span>
                  <span className='h45 d-flex flex-row align-items-center text-gray'>
                    <IconSuccess size={16} className='mr-2' />{' '}
                    {translate('workspace.teach.right.topinfo.attribute2')}
                  </span>
                  <span className='h45 d-flex flex-row align-items-center text-gray'>
                    <IconSuccess size={16} className='mr-2' />{' '}
                    {translate('workspace.teach.right.topinfo.attribute3')}
                  </span>
                  <span className='h45 d-flex flex-row align-items-center text-gray'>
                    <IconSuccess size={16} className='mr-2' />{' '}
                    {translate('workspace.teach.right.topinfo.attribute4')}
                  </span>
                </div>
              </div>
            </div>
            <div className='m-0 p-0 d-flex flex-column'>
              <span
                className='h2 text-title font-weight-bold mr-6 mt-4'
                style={{lineHeight: '1.6'}}
              >
                {translate('workspace.teach.right.bottominfo.title')}
              </span>
              <span
                className='h4 text-gray mt-2 mb-4'
                style={{lineHeight: '1.6'}}
              >
                {translate('workspace.teach.right.bottominfo.body')}
              </span>
              <span className='h4 btn-link' style={{lineHeight: '1.6'}}>
                {translate('workspace.teach.right.bottominfo.link')}
              </span>
            </div>
          </div>
        }
      />
      <SendLessonNotesModal
        isOpen={isOpenNotes}
        toggle={toggleModalSendNotes}
        dataLesson={dataLessonNotes}
        refetch={() => {
          refetchTableData()
        }}
        mutationCallBack={() => {}}
      />
      {/** <StudentModal />**/}
    </>
  )
}

export default TeachView
