import React from 'react'
import useTranslation from '../../../../../../i18n/useTranslation'

const TimeSelectionBlockHeader = (props) => {
  const {topic, time} = props
  const {translate} = useTranslation()
  return (
    <>
      <div className='d-flex flex-column my-2 h-auto justify-content-around'>
        <span className='h2 my-3'>
          <b>{translate('lessons.selectdate')}</b>
        </span>
        <span className='text-muted'>
          {translate('lessons.fortimezone', {
            lesson: topic,
            zone: time,
          })}
        </span>
      </div>
    </>
  )
}

export default TimeSelectionBlockHeader
