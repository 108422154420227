import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {ResumenInvoice, IconPayslip} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import PayslipModal from '../../../../../Educator/Payments/PayslipModal'
import usePayslips from '../../../../../../Model/Users/Educators/GetPayslips'

const PayslipsHistoryComponent = (props) => {
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const educator = useSelector((state) => state.user)
  const userId = educator?.info?.id
  const [payslipsData, setPayslipsData] = useState([])

  const openModal = (id) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'payslips.viewPayslip',
      data: {isOpen: true, payslipId: id},
    })
  }

  const {data, loading} = usePayslips(
    {
      limit: 0,
      userId: userId,
      status: ['paid', 'pendingPayment', 'procesingPayment'],
      sortField: 'payslipDate',
    },
    () => {},
    console.log,
  )

  useEffect(() => {
    if (data) {
      setPayslipsData(data?.docs)
    }
  }, [data])

  return (
    <TabContent activeTab={loading ? 'loading' : 'view'} className='h-inherit'>
      <TabPane tabId='view'>
        <div className='w-100 p-0 m-0 px-4 px-md-7 pb-4'>
          <div className='d-flex'>
            <span className='font-weight-bold btn btn-link p-0 text-logo'>
              {translate('misc.results', payslipsData?.length || 0)}
            </span>
          </div>
          <div className='d-flex justify-content-between py-2 mb-2' />
          <div className='d-flex flex-column align-items-center'>
            {payslipsData?.length ? (
              <>
                {payslipsData.map((invoice, index) => {
                  return (
                    <ResumenInvoice
                      key={index}
                      type='green'
                      text={`${translate(
                        'student.modal.payments.endOf',
                      )} ${moment(invoice.to).format('MMMM')}`}
                      className='mx-0 my-1 px-2 py-1'
                      amount={invoice.payslipProps.amount}
                      icon={<IconPayslip color='graytext' className='mr-3' />}
                      onClick={() => openModal(invoice.id)}
                    />
                  )
                })}
              </>
            ) : (
              <span className='w-100 text-center'>
                {translate('misc.nothingFound')}
              </span>
            )}
          </div>
          <PayslipModal />
        </div>
      </TabPane>
      <TabPane tabId='loading'>
        <Skeleton count={4} height={50} className='w-100' />
      </TabPane>
    </TabContent>
  )
}

export default PayslipsHistoryComponent
