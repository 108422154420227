import {gql, useQuery} from '@apollo/client'
import queryAccountResult from '../../../QueryResult/useAccountResult'

export default (vars) => {
  // Sirve para traer la lista de accounts del usuario logueado.
  const {data, loading, refetch} = useQuery(
    gql`query {
            getAllAccountsFromUser {
                ${queryAccountResult}
            }
        }`,
    {
      variables: vars,
    },
  )

  return {
    loading: loading,
    data: data?.getAllAccountsFromUser || [],
    refetch,
  }
}
