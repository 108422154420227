import React, {useState} from 'react'
import {BulletPoint} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SendLessonNotesModal from './SendLessonNotesModal'

const SendLessonNotes = (props) => {
  const {dataLesson, refetchCalendarQuery, disabled} = props

  const [isOpen, setOpen] = useState(false)
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    setOpen(!isOpen)
  }

  const setNewDataLesson = (newDataLesson, isOpenNew) => {
    // setea nuevos datos de la lección en redux
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.infoLessonModal',
      data: {
        isOpen: isOpenNew,
        dataLesson: newDataLesson,
      },
    })
  }

  return (
    <>
      <div className='m-0 p-0' onClick={!disabled && toggle}>
        {disabled ? (
          <BulletPoint
            disaperTime={1000}
            direction='top'
            innerComponent={
              <span className='h45 text-title'>
                {translate('modal.sendnotes.disabledMsg')}
              </span>
            }
          >
            {props.children}
          </BulletPoint>
        ) : (
          props.children
        )}
      </div>
      <SendLessonNotesModal
        refetch={refetchCalendarQuery}
        isOpen={isOpen}
        toggle={toggle}
        dataLesson={dataLesson}
        mutationCallBack={(newDataLesson) => {
          setNewDataLesson(
            newDataLesson,
            // info modal de la lección está abierto luego de ejecutada la mutación
            !dataLesson?.redirectToListAfter,
          )
        }}
      />
    </>
  )
}

export default SendLessonNotes
