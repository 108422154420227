import {useLazyQuery, gql} from '@apollo/client'

export const useGetExamScore = (onSuccess = () => {}, onError = () => {}) => {
  return useLazyQuery(
    gql`
      query {
        getExamScores {
          docs {
            id
            _id
            name
            results {
              name
              scale
              values {
                name
                scale
              }
            }
            serviceId
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError,
    },
  )
}
