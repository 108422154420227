import React from 'react'
import {useQuery} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {SET_USER_INFO_ACTION, SET_QUERY_ME} from '../../../store/user/const'
import hashQuery from '../../../queries/user-hash'
import {LoadingPage} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {useCookies} from 'react-cookie'
import * as moment from 'moment-timezone'
import UserContext from '../../../contexts/UserContext'
const AuthProvider = (props) => {
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const [cookie, setCookie] = useCookies(['znk-signup'])
  const {data, loading, refetch} = useQuery(hashQuery, {
    nextFetchPolicy: 'standby',
  })
  const userData = data && {
    ...data.me,
    studentInfo: data?.me?.studentInfo || {}, // si studentInfo o educatorInfo es null los reemplaza por {}
    educatorInfo: data?.me?.educatorInfo || {},
  }
  if (userData) {
    if (userData.adminSignupToken) {
      if (cookie['znk-signup'] !== userData.adminSignupToken) {
        console.log('setting admin cookie')
        setCookie('znk-signup', userData.adminSignupToken, {
          expires: moment().add(1, 'd').toDate(),
          path: '/',
          domain: '.zinkerz.com',
          secure: true,
          sameSite: 'none',
        })
      }
    }
    dispatch({type: SET_USER_INFO_ACTION, payload: userData})
    dispatch({type: SET_QUERY_ME, payload: refetch})
  }
  if (userData && data && data.getUserHash) {
    window.FrontChat('init', {
      chatId: process.env.REACT_APP_FRONTAPP_CHAT,
      useDefaultLauncher: true,
      email: userData.emails[0].address,
      userHash: data.getUserHash,
    })
  }
  return loading ? (
    <LoadingPage text={translate('loading.gatheringProfileInfo')} />
  ) : (
    <UserContext.Provider value={{refetch}}>
      {props.children}
    </UserContext.Provider>
  )
}

export default AuthProvider
