import {useLazyQuery, gql} from '@apollo/client'

export const UseMyFreeTimeFrames = () => {
  return useLazyQuery(
    gql`
      query(
        $involveMyself: Boolean!
        $to: DateTime!
        $from: DateTime!
        $educatorUserId: ID
        $minDurationInMinutes: Int
        $studentIds: [ID!]
      ) {
        myFreeTimeFrames(
          involveMyself: $involveMyself
          educatorUserId: $educatorUserId
          minDurationInMinutes: $minDurationInMinutes
          to: $to
          from: $from
          studentIds: $studentIds
        )
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  )
}
