import React, {useMemo, useState} from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {FormGroup, FormText} from 'reactstrap'
import {
  Button,
  IconArrowLeft,
  IconCardDiners,
  IconCardDiscover,
  IconCardMastercard,
  IconCardVisa,
  IconPayments,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useResponsiveFontSize from '../useResponsiveFontSize'
import {showAlert} from '../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useSetCard from './useCard'

const useOptions = () => {
  const fontSize = useResponsiveFontSize()
  const options = useMemo(
    () => ({
      style: {
        iconStyle: 'solid',
        base: {
          iconColor: '#c4f0ff',
          borderRaduis: 20,
          fontSize,
          color: '#2C3F58',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#778699',
          },
        },
        invalid: {
          color: '#FF3E1D',
        },
        borderRadius: 20,
      },
    }),
    [fontSize],
  )
  return options
}

const AddCard = (props) => {
  const {currentMethod, onSuccess, goBack} = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()
  const [status, setStatus] = useState({
    number: false,
    exp: false,
    cvc: false,
  })
  const [errors, setErrors] = useState({
    number: '',
    exp: '',
    cvc: '',
  })
  const [refNumber, setRefNumber] = useState(null)
  const [refExpire, setRefExpire] = useState(null)
  const [refCvc, setRefCvc] = useState(null)
  const {submit: setCard, loading} = useSetCard((data) => {
    const {success, error} = data.data.setCardPaymentMethod
    if (success) {
      refNumber.clear()
      refExpire.clear()
      refCvc.clear()
      onSuccess()
    } else {
      showAlert(
        {
          text: translate(`profile.actions.addCard.${error}`),
          status: 'error',
        },
        dispatch,
      )
    }
  }, console.log)
  const handleSubmit = async (event) => {
    if (!status.cvc && !status.exp && !status.number) {
      return
    }
    try {
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      })
      if (!error) {
        setCard({paymentMethodId: paymentMethod.id})
      } else {
        showAlert(
          {
            text: translate(`profile.actions.addCard.${error.code}`),
            status: 'error',
          },
          dispatch,
        )
      }
    } catch (e) {
      // eslint-disable-next-line
      showAlert(
        {
          text: JSON.stringify(e),
          status: 'error',
        },
        dispatch,
      )
    }
  }

  const manageCard = (event, type) => {
    switch (type) {
      case 'CN':
        setStatus({
          ...status,
          number: event.complete,
        })
        setErrors({
          ...errors,
          number: event.error === undefined ? '' : event.error.message,
        })
        break
      case 'CE':
        setStatus({
          ...status,
          exp: event.complete,
        })
        setErrors({
          ...errors,
          exp: event.error === undefined ? '' : event.error.message,
        })
        break
      case 'CV':
        setStatus({
          ...status,
          cvc: event.complete,
        })
        setErrors({
          ...errors,
          cvc: event.error === undefined ? '' : event.error.message,
        })
        break
      default:
        break
    }
  }
  const {breakWidth} = useWindowDimensions()
  const goExit = () => {
    refNumber.clear()
    refExpire.clear()
    refCvc.clear()
    goBack()
  }
  return (
    <form onSubmit={handleSubmit} className='stripe'>
      <div className='mb-4 d-flex justify-content-between'>
        <IconPayments size={breakWidth === 'SM' ? 48 : 70} color />
        <div onClick={goExit}>
          <span className='btn-link d-flex align-items-center'>
            <IconArrowLeft size={20} />
            {translate('misc.goBack')}
          </span>
        </div>
      </div>
      <FormGroup>
        <CardNumberElement
          options={options}
          onChange={(event) => {
            manageCard(event, 'CN')
          }}
          style={{width: '100%'}}
          onReady={(e) => setRefNumber(e)}
        />
        {errors.number !== '' && (
          <FormText>
            <span className='text-error'>{errors.number}</span>
          </FormText>
        )}
      </FormGroup>
      <div className='row'>
        <div className='col-6'>
          <FormGroup>
            <CardExpiryElement
              options={options}
              onChange={(event) => {
                manageCard(event, 'CE')
              }}
              onReady={(e) => setRefExpire(e)}
            />
            {errors.exp !== '' && (
              <FormText>
                <span className='text-error'>{errors.exp}</span>
              </FormText>
            )}
          </FormGroup>
        </div>
        <div className='col-6'>
          <FormGroup>
            <CardCvcElement
              options={options}
              onChange={(event) => {
                manageCard(event, 'CV')
              }}
              onReady={(e) => setRefCvc(e)}
            />
            {errors.cvc !== '' && (
              <FormText>
                <span className='text-error'>{errors.cvc}</span>
              </FormText>
            )}
          </FormGroup>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 pb-4 text-justify colort'>
          {translate('profile.actions.changeMethod.info')}
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mb-4 text-justify'>
          <div className='accepted'>
            <span className='span-accep'>
              {translate('profile.actions.changeMethod.accept')}
            </span>
            <IconCardVisa size={30} />
            <IconCardDiners size={30} className='cardp' />
            <IconCardDiscover size={30} className='cardp' />
            <IconCardMastercard size={30} className='cardp' />
          </div>
        </div>
      </div>
      <Button
        label={
          currentMethod === null
            ? 'Add payment method'
            : 'Change payment method'
        }
        color={
          status.cvc && status.exp && status.number ? 'primary' : 'secondary'
        }
        check
        size='medium'
        onClick={handleSubmit}
        disabled={loading}
        activity={loading}
        style={{width: '100%'}}
      />
    </form>
  )
}

export default AddCard
