import {gql} from '@apollo/client'

export default gql`
  query {
    myInvoices(
      sortField: "from"
      limit: 0
      status: [pendingPayment, procesingPayment, unPaid, paid]
    ) {
      docs {
        id
        status
        category
        billedUserId
        processedTimes
        invoiceNumber
        from
        to
        paymentItemIds
        createdAt
        updatedAt
        lastProcessedAt
        amount
        taxFee
        amounts {
          subTotal
          taxAmount
          amount
        }
        paymentItems {
          ... on PlanPaymentItem {
            id
            type
            amount
            draft
            status
            lastProcessedAt
            description
            category
          }
          ... on CustomPaymentItem {
            id
            type
            amount
            draft
            status
            lastProcessedAt
            description
            category
          }
          ... on LessonPaymentItem {
            id
            type
            amount
            draft
            status
            lastProcessedAt
            description
            category
          }
        }
        issuedInvoices {
          stripeInvoiceId
          status
          paymentMethod
          amount
          lastProcessedAt
          issuedCreditNotes {
            creditNoteId
            amount
            stripeInfo {
              created
              currency
              discount_amount
              number
              out_of_band_amount
              pdf
              subtotal
              total
            }
          }
        }
      }
    }
  }
`
