import React, {useEffect, useState, useContext} from 'react'
import {useMutation, gql} from '@apollo/client'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import FirebaseContext from '../../../contexts/FirebaseContext'
import firebaseOptions from './firebaseOptions'
import FirebaseUserContext from '../../../contexts/FirebaseUserContext'
import moment from 'moment-timezone'
import {useLocation} from 'react-router-dom'
import ActionsContext from '../../../contexts/ActionsContext'

const propTypes = {
  children: PropTypes.any,
}

function FirebaseProvider({children}) {
  const {changeAction} = useContext(ActionsContext)
  const [app, setApp] = useState(null)
  const {setFirebaseUser} = useContext(FirebaseUserContext)
  useEffect(() => {
    let result
    if (global.firebaseProvider) {
      result = global.firebaseProvider
    } else {
      result = !firebase.apps.length
        ? firebase.initializeApp(firebaseOptions)
        : firebase.app()
      global.firebaseProvider = result
    }
    setApp(result)
  }, [])

  const [checkByUID] = useMutation(
    gql`
      mutation(
        $uid: String!
        $asEducator: Boolean
        $timezone: String!
        $displayName: String
      ) {
        checkByUID(
          uid: $uid
          asEducator: $asEducator
          timezone: $timezone
          displayName: $displayName
        ) {
          id
        }
      }
    `,
  )

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const codeToken = searchParams.get('code')
  const isEducator = searchParams.get('isEducator')
  useEffect(() => {
    if (location.pathname === '/profile/info' && codeToken) {
      firebase
        .auth()
        .signInWithCustomToken(codeToken)
        .then(async (data) => {
          setFirebaseUser(data.user)
        })
        .catch((error) => {
          setFirebaseUser(null)
          changeAction('errorcode')
        })
    }
    // eslint-disable-next-line
  }, [codeToken])
  useEffect(() => {
    if (app) {
      app.auth().onAuthStateChanged(async (user) => {
        if (user?.uid) {
          // const storage = global.localStorage.getItem('loggedUser')
          // const parsedInfo = JSON.parse(storage) ?? {}
          // si displayName es nulo entonces checkByUID no sobreescribe el nombre del usuario
          const displayName =
            global.localStorage.getItem('displayNameToMutation') || null
          //  parsedInfo?.firstName?.length > 0 &&
          //  parsedInfo?.lastName?.length > 0
          //    ? `${parsedInfo?.firstName} ${parsedInfo?.lastName}`
          //    : user.displayName || null
          global.loadingForceLogin = true
          checkByUID({
            variables: {
              uid: user.uid,
              asEducator: isEducator === 'true',
              timezone: moment.tz.guess(true),
              displayName,
            },
          })
            .then(() => {
              global.loadingForceLogin = false
              // elimina displayName del cache
              global.localStorage.removeItem('displayNameToMutation')
              setFirebaseUser(user)
            })
            .catch((error) => {
              global.loadingForceLogin = false
              console.error(error)
            })
        } else {
          setFirebaseUser(null)
        }
      })
    }
  }, [app, isEducator, setFirebaseUser, checkByUID])

  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  )
}

FirebaseProvider.propTypes = propTypes

export default FirebaseProvider
