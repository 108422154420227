import {useContext} from 'react'
import FirebaseContext from '../../contexts/FirebaseContext'
import createSuspenseHook from '../../helpers/hooks/createSuspenseHook'

const getUserAsync = ({input}) => {
  let resolved = false
  return new Promise((resolve) => {
    if (input.firebase) {
      input.firebase.auth().onAuthStateChanged(async (user) => {
        if (!resolved) {
          if (user) {
            resolve({user, token: user ? await user.getIdToken() : null})
            resolved = true
          } else {
            resolve({user: null, token: null})
          }
        }
      })
    } else {
      resolve(null)
    }
  })
}

const useUser = createSuspenseHook(getUserAsync)

export default () => {
  const firebase = useContext(FirebaseContext)

  return useUser({firebase})
}
