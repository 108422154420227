import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import completeUniversityInfo from '../../Pages/University/Utils/completeUniversityInfo'
import ShowUniversity from '../../Pages/University/ShowUniversity'

const OpenUniversityModalFromUrl = () => {
  const location = useLocation()

  const [university, setUniversity] = useState({})
  const searchParams = new URLSearchParams(location.search)
  const collegeId = searchParams.get('collegeId')
  const [universityModalOpen, setUniversityModalOpen] = useState(false)
  const infoToObtain = [
    'name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'logo_image',
    'website',
  ]

  const showCollege = async () => {
    const info = await completeUniversityInfo(collegeId, infoToObtain)
    setUniversity(info)
    setUniversityModalOpen(!universityModalOpen)
  }
  /* eslint-disable */
    useEffect(() => {
        if(collegeId) {
            showCollege()     
        }
    }, [collegeId])
      /* eslint-enable */
  return (
    <>
      <ShowUniversity
        isOpen={universityModalOpen}
        toggle={() => setUniversityModalOpen(!universityModalOpen)}
        university={university}
      />
    </>
  )
}

export default OpenUniversityModalFromUrl
