import React, {useEffect, useState} from 'react'
import {Modal, useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import {
  ScheduledConflictHeader,
  ScheduledConflictBody,
} from './inner/ScheduledConflict'
import {ListConflictHeader, ListConflictBody} from './inner/ListConflict'
import {
  SchedulingConflictHeader,
  SchedulingConflictBody,
} from './inner/SchedulingConflict'
import {
  SchedulingRecurringHeader,
  SchedulingRecurringBody,
} from './inner/SchedulingRecurring'
import {OrientationErrorHeader} from './inner/OrientationError'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {TabContent, TabPane} from 'reactstrap'
import ProgressBar from '../../components/ProgressBar'

const ScheduledConflictPlan = (props) => {
  const {isAdmin} = props
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )
  const {isOpen, page} = conflictModal
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.conflictModal',
      data: {...conflictModal, isOpen: !isOpen},
    })
  }
  const setPage = (newPage) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.conflictModal',
      data: {...conflictModal, page: newPage},
    })
  }

  const {scheduledTimesOcurrences} = useSelector(
    (state) => state.modal.onboarding.data,
  )
  const arrayScheduled = scheduledTimesOcurrences
    ? scheduledTimesOcurrences.map((objConflict) => {
        return objConflict.newTime
      })
    : []

  const [error, setError] = useState(null)
  useEffect(() => {
    if (!isOpen) setError(null)
  }, [isOpen])
  const [status, setStatus] = useState(null)

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-colored modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored white-modal`}
        isOpen={isOpen}
        backdrop={[false, 'static']}
        toggle={toggle}
        Head={
          <div
            className={`${
              breakWidth === 'SM' ? 'm-0 pb-3 pt-0 pl-3' : 'm-0 pb-0 pt-0 px-0'
            } d-flex flex-column`}
            style={{
              borderRadius: '7px 7px 0 0',
            }}
          >
            {['scheduledConflict'].includes(page) && (
              <ProgressBar arrayScheduled={arrayScheduled} />
            )}
            <TabContent activeTab={page} className='px-6 pt-3'>
              <TabPane tabId='listConflict'>
                <ListConflictHeader />
              </TabPane>
              <TabPane tabId='scheduledConflict'>
                <ScheduledConflictHeader />
              </TabPane>
              <TabPane tabId='schedulingRecurring'>
                <SchedulingRecurringHeader status={status} error={error} />
              </TabPane>
              <TabPane tabId='schedulingConflict'>
                <SchedulingConflictHeader status={status} />
              </TabPane>
              <TabPane tabId='mutationError'>
                <OrientationErrorHeader toggle={toggle} error={error} />
              </TabPane>
            </TabContent>
          </div>
        }
      >
        <div
          className={`${
            breakWidth === 'SM' ? 'm-0 py-3 pl-3' : 'm-0 py-3 px-5'
          }`}
        >
          <TabContent activeTab={page} className='px-2'>
            <TabPane tabId='listConflict'>
              <ListConflictBody setPage={setPage} />
            </TabPane>
            <TabPane tabId='scheduledConflict'>
              <ScheduledConflictBody isAdmin={isAdmin} />
            </TabPane>
            <TabPane tabId='schedulingRecurring'>
              <SchedulingRecurringBody
                setPage={setPage}
                toggleModal={toggle}
                setStatus={setStatus}
                error={error}
                setError={setError}
                isOpen={isOpen}
                isAdmin={isAdmin}
              />
            </TabPane>
            <TabPane tabId='schedulingConflict'>
              <SchedulingConflictBody
                isAdmin={isAdmin}
                setStatus={setStatus}
                setPage={setPage}
                errorProps={error}
                isOpen={isOpen}
                setError={setError}
              />
            </TabPane>
          </TabContent>
        </div>
      </Modal>
    </>
  )
}

export default ScheduledConflictPlan
