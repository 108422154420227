import {gql, useQuery} from '@apollo/client'
import validate from './validation'

export default (vars) => {
  const {data, error, loading} = useQuery(
    gql`
      query(
        $educatorId: ID
        $minDurationInMinutes: Int
        $to: DateTime!
        $from: DateTime!
        $involveMyself: Boolean!
      ) {
        myFreeTimeFrames(
          educatorUserId: $educatorId
          minDurationInMinutes: $minDurationInMinutes
          to: $to
          from: $from
          involveMyself: $involveMyself
        )
      }
    `,
    {
      variables: vars,
      fetchPolicy: 'network-only',
    },
  )
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: []}
  }
  return {loading, data: data ? data.myFreeTimeFrames : [], error}
}
