import validate from 'validate.js'

const constraints = {
  lessonId: {
    presence: true,
  },
  lessonNoteInput: {
    presence: true,
  },
}

export default (data) => {
  return validate(data, constraints)
}
