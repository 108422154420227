import React from 'react'
import {
  FileItem,
  IconArrowRight,
  RedRibbon,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'

const ExamItemComponent = (props) => {
  const {examData} = props

  return (
    <div className='d-flex flex-row w-100 align-items-center'>
      <FileItem
        lineWidth='d-flex flex-column w-100 mt-6 mb-2'
        fileText={
          <div className='d-flex flex-column text-align-start'>
            <RedRibbon
              className='znk-ribbon-small ml-n4 mt-n2'
              left='znk-ribbon-small left'
              right=''
            />
          </div>
        }
        className='align-items-center'
        color='purple'
        size='small-plus'
      />
      <div className='d-flex flex-column mx-4 my-auto w-70'>
        <div className='d-flex flex-column ml-4 my-auto'>
          <span className='text-info'>
            {examData?.resolveTask?.score?.name}
          </span>
          <span className='text-info'>
            # {examData?.resolveTask?.score?.overall}
          </span>
          <span
            className='h5 text-truncate'
            style={{
              color: '#a8b2be',
              maxWidth: '40vh',
            }}
          >
            {moment(examData?.resolveTask?.score?.examDate).format('MMM YYYY')}
          </span>
        </div>
      </div>
      <div className='d-flex align-self-center justify-content-end mr-4'>
        <IconArrowRight size={12} className='text-gray' />
      </div>
    </div>
  )
}

export default ExamItemComponent
