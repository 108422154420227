import React, {useEffect, useMemo} from 'react'
import {
  ListItem,
  IconArrowRight,
  StickyButton,
  LessonCard,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import ChangeTimezoneModal from '../../../../../Profile/Info/ProfileActions/ChangeTimezoneModal'
import Skeleton from 'react-loading-skeleton'
import {gql, useQuery} from '@apollo/client'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'

const ShowPendingLesson = (props) => {
  const dispatch = useDispatch()
  const modalData = useSelector((state) => state.modal.program.rescheduledModal)
  const services = useSelector((state) => state.services.services)
  const orientation = useMemo(() => {
    if (services)
      return Object.values(services).find(({isOrientation}) => isOrientation)
    return {}
  }, [services])

  const {timezone} = useSelector((state) => state.user.info)
  const {isOpen} = modalData
  const {
    data: pendingLessons,
    loading,
    refetch: refetchPendingLessons,
  } = useQuery(
    gql`
      query($status: [LessonStatus!]!) {
        myLessons(status: $status) {
          docs {
            id
            status
            educator {
              educatorUserId
              firstName
              lastName
            }
            title
            startDate
            endDate
            service {
              name
              id
            }
            topic {
              name
            }
            location {
              type
            }
          }
        }
      }
    `,
    {
      variables: {
        status: ['pending'],
      },
    },
  )

  // actualiza listado de lecciones pendientes
  useEffect(() => {
    // if (!isOpen)
    refetchPendingLessons()
  }, [isOpen, refetchPendingLessons])

  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }

  return (
    <>
      <ChangeTimezoneModal timezone={timezone}>
        <ListItem
          showBottomLine
          showTopLine
          className='m-0 p-4'
          Left='Timezone'
          Right={
            <span className='text-muted d-flex flex-row align-items-center'>
              {timezone} <IconArrowRight className='ml-2' />
            </span>
          }
        />
      </ChangeTimezoneModal>
      <div className='m-0 p-4'>
        {!loading ? (
          pendingLessons.myLessons.docs
            .filter((lesson) => {
              return lesson?.service?.id !== orientation?.id
            })
            .map((lesson, index) => {
              return (
                <LessonCard
                  title={lesson.title}
                  status={
                    modalData.lesson &&
                    modalData.lesson.lessonId &&
                    modalData.lesson.lessonId === lesson.id
                      ? 'upcoming'
                      : 'pending'
                  }
                  showTime={false}
                  preLabelTime={`${separateCamelCase(
                    lesson?.location?.type || 'No defined',
                  )} 
                | ${separateCamelCase(
                  lesson.__typename?.replace('Lesson', ''),
                )}`}
                  lessonTime={moment(lesson.startDate)}
                  lessonEndTime={moment(lesson.endDate)}
                  attendants={[
                    {
                      name: `${lesson.educator.firstName} ${lesson.educator.lastName}`,
                      avatar_url: null,
                    },
                  ]}
                  key={index}
                  showCountdown={false}
                  className='w-100 m-1 mb-1'
                  onClick={() => {
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'program.rescheduledModal',
                      data: {
                        ...modalData,
                        educator: lesson.educator,
                        lesson: {
                          lessonId: lesson.id,
                          name: lesson.topic.name,
                          service: lesson.service,
                          topic: lesson.topic
                        },
                        plan: {},
                      },
                    })
                  }}
                />
              )
            })
        ) : (
          <Skeleton count={5} height={40} />
        )}
        <StickyButton
          className='my-4'
          color='#696CFF'
          disabled={!modalData?.lesson}
          condition
          onClickButtonFunction={() => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'program.rescheduledModal',
              data: {
                ...modalData,
                stage: 'timeSelectionBlock',
              },
            })
          }}
        />
      </div>
    </>
  )
}

export default ShowPendingLesson
