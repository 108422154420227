import {
  IconClose,
  Modal,
  Form,
  StickyButton,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import React, {useEffect, useState} from 'react'
import {isEmpty} from 'validate.js'
import useTranslation from '../../../../../i18n/useTranslation'
import sendTopicsTeach from '../../../../../Model/Users/Educators/sendTopicsTeach'

const SendTeachTopics = (props) => {
  const {isOpen, setIsOpen, canTeach} = props
  const [form, setForm] = useState({body: ''})
  const {translate} = useTranslation()
  const [errors, setErrors] = useState(false)
  const toggle = () => {
    setErrors({})
    setIsOpen(!isOpen)
  }
  useEffect(() => {
    if (canTeach) {
      setForm({body: canTeach})
    }
  }, [canTeach])
  const {submit, loading} = sendTopicsTeach(() => {
    toggle()
  }, console.log)
  const onSubmit = () => {
    setErrors({})
    if (isEmpty(form.body)) {
      setErrors(true)
      return
    }
    submit({bodyText: form.body})
  }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>
              {translate('profile.actions.employment.sendtopics')}
            </span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='m-0 p-0 mb-2 px-3'>
          <Form>
            <label className='text-gray font-weight-light mt-3 mb-2'>
              {translate('profile.actions.employment.bodytext')}
            </label>
            <textarea
              onChange={(e) => {
                setForm({...form, body: e.target.value})
              }}
              value={form.body}
              placeholder='Type here'
              className='znk-input w-100 p-3'
              style={{height: '150px'}}
            />
            {errors === true && (
              <span className='h5 text-error'>
                {translate('profile.actions.employment.errorbodytext')}
              </span>
            )}
            <div className='row d-flex m-0 p-0 mt-5'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{borderRadius: '16px'}}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default SendTeachTopics
