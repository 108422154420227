import React, {useEffect, useState, useMemo} from 'react'
import {
  Modal,
  ListItem,
  IconArrowRight,
  IconCountry,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import {
  SelectDateHeader,
  DateList,
  DateCalendar,
  ConfirmRescheduled,
} from './individual/index'
import moment from 'moment-timezone'
import {TabPane, TabContent} from 'reactstrap'
import './style.css'
import RescheduleCounselor from '../../../../../../../../Model/Lessons/RescheduleCounselor'
import {UseMyFreeTimeFrames} from './Model/useMyFreeTimeFrames'
import {showAlert} from '../../../../../../../../common/Alert/util'

const RescheduledOrientation = () => {
  const dispatch = useDispatch()
  const {isOpen, dataLesson} = useSelector(
    (state) => state.modal.calendar.rescheduledOrientation,
  )

  const zone = useSelector((state) => state.user.info.timezone)

  // SERVICIO ORIENTACIÓN
  const isOrientation = !dataLesson?.isMeeting
  const isMeeting = !!dataLesson?.isMeeting

  useEffect(() => {
    if (!isOpen) setTimeScheduled(null)
  }, [isOpen])

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.rescheduledOrientation',
      data: {
        isOpen: !isOpen,
        dataLesson,
      },
    })
  }

  const {
    loading: loadOrientationMutation,
    submit: rescheduledOrientation,
  } = RescheduleCounselor(
    (result) => {
      toggle()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.rescheduledOrientation',
        data: {
          // ...reduxOrientationModal,
          isOpen: false,
          dataLesson: result?.data?.reScheduleCounselor,
        },
      })
      showAlert(
        {
          text: 'Lesson has been rescheduled successfully!',
          status: 'success',
        },
        dispatch,
      )
    },
    (err) => {
      showAlert(
        {
          text: err.toString(),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const [page, setPage] = useState('list')
  const lessonDuration = moment
    .duration(moment(dataLesson?.endDate).diff(dataLesson?.startDate))
    .asMinutes()

  const [from] = useMemo(() => {
    const fromDate =
      dataLesson?.startDate && moment(dataLesson.startDate) > moment()
        ? moment(dataLesson.startDate).set('minute', 0).toISOString()
        : moment().add(1, 'hours').set('minute', 0).toISOString()
    return [fromDate, moment(fromDate).add(1, 'week').toISOString()]
  }, [dataLesson])

  useEffect(() => {
    setPage('list')
  }, [isOpen])

  const [educator, setEducator] = useState({})
  useEffect(() => {
    if (dataLesson?.substitute || dataLesson?.educator)
      setEducator(dataLesson.substitute || dataLesson.educator)
  }, [dataLesson])

  const [timeScheduled, setTimeScheduled] = useState(null)

  const topic =
    dataLesson?.individualTopic ||
    dataLesson?.planTopic ||
    dataLesson?.groupTopic ||
    {}
  const service = dataLesson?.service || {}
  const student =
    dataLesson?.student ||
    dataLesson?.studentPlan ||
    dataLesson?.studentGroup ||
    {}
  // STATE SE USA PARA DETERMINAR SEMANA DEL CALENDARIO LessonSelectCalendar
  const [currentMoment, setCurrentMoment] = useState(
    moment(from).startOf('isoWeek').toISOString(),
  )
  useEffect(() => {
    setCurrentMoment(moment(from).tz(zone)?.startOf('isoWeek').toISOString())
  }, [from, zone])

  // QUERY myFreeTimesFrames
  const [getFreeTimes, {data, loading}] = UseMyFreeTimeFrames()
  useEffect(() => {
    if (
      currentMoment &&
      educator?.educatorUserId &&
      (student?.studentUserId || student?.length > 0) &&
      page &&
      zone
    ) {
      const studentsIds =
        student?.length > 0
          ? student.map(({studentUserId}) => studentUserId)
          : [student.studentUserId]
      getFreeTimes({
        variables: {
          involveMyself: false,
          // from: from,
          from:
            page === 'list'
              ? moment().add(1, 'day').toISOString()
              : currentMoment,
          to:
            page === 'list'
              ? moment().add(1, 'week').toISOString()
              : moment(currentMoment).add(1, 'week').toISOString(),
          minDurationInMinutes: 60,
          educatorUserId: educator.educatorUserId,
          studentIds: studentsIds,
        },
      })
    }
  }, [currentMoment, educator, student, getFreeTimes, page, zone])
  const myFreeFrames = useMemo(() => {
    return data?.myFreeTimeFrames || []
  }, [data])

  // const educatorName = `${educator?.firstName} ${educator?.lastName}`
  const RenderTimezoneAndEducator = () => {
    const number = Math.floor(moment().tz(zone).utcOffset() / 60)
    const gmtLabel = `GMT${number > 0 ? `+${number}` : number}`
    return (
      <div className='d-flex flex-column mx-6'>
        <ListItem
          showTopLine
          showBottomLine
          className='py-4'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'lesson.changeTimezoneModal',
              data: {isOpen: true},
            })
          }}
          Left={
            <div className='m-0 p-0 d-flex flex-row align-items-center'>
              <IconCountry />
              <div className='d-flex flex-row align-items-center'>
                <label className='ml-4 mr-0 my-0'>
                  {zone?.replace('/', ', ')}{' '}
                  <span className='h5 text-gray'>{`(${gmtLabel})`}</span>
                </label>
              </div>
            </div>
          }
          Right={
            <div
              className='m-0 py-1 px-2 hover-icon'
              onClick={() => {
                dispatch({
                  type: 'SET_DATA_MODAL_FORM_LIST',
                  payload: 'calendar.changeTimezoneModal',
                  data: {
                    isOpen: true,
                  },
                })
              }}
            >
              <IconArrowRight />
            </div>
          }
        />
      </div>
    )
  }

  const now = useMemo(() => {
    return moment()
  }, [])
  const possibleBlocks = useMemo(() => {
    return myFreeFrames
      ?.reduce((arr, timeFrame) => {
        const [fromISO, toISO] = timeFrame
        const fromMoment = moment(fromISO).tz(zone)
        const toMoment = moment(toISO)
          .tz(zone)
          .subtract(50, 'minute')
          .milliseconds(0)
          .seconds(0)
          .minutes(0)
        // eslint-disable-next-line
        while (fromMoment < toMoment) {
          const lessonArr = toMoment.clone()
          if (toMoment > now.clone().add(1, 'hour')) {
            arr.unshift({
              moment: lessonArr,
              label: `${lessonArr.format('h:mma')} to ${lessonArr
                .clone()
                .add(lessonDuration, 'm')
                .format('h:mma')} | ${lessonArr.format('MMM D, YYYY')}`,
            })
          }
          // const durationMinutes = isOrientation ? 15 : 60
          toMoment.subtract(lessonDuration, 'm')
        }
        return arr
      }, [])
      ?.filter((block) => block.moment.hour() > 10 && block.moment.hour() < 17)
      ?.filter((block) => block.moment.day() !== 0 && block.moment.day() !== 6)
      ?.sort((a, b) => a.moment.valueOf() - b.moment.valueOf())
    // eslint-disable-next-line
  }, [myFreeFrames, zone])
  const [showElements, setShowElements] = useState(5)
  const addFiveElements = () => {
    setShowElements(showElements + 5)
  }

  const RenderBody = ({page}) => {
    return (
      <TabContent activeTab={page} className='px-2'>
        <TabPane tabId='list'>
          <DateList
            topic={topic}
            educator={educator}
            setEducator={setEducator}
            timezone={zone}
            loading={loading}
            needMoreOptionsFunction={() => {
              setTimeScheduled(null)
              setPage('calendar')
            }}
            RenderTimezoneAndEducator={RenderTimezoneAndEducator}
            possibleBlocks={possibleBlocks.slice(0, showElements)}
            addFiveElements={addFiveElements}
            lessonDuration={lessonDuration}
            setTimeScheduled={setTimeScheduled}
            timeScheduled={timeScheduled}
            isOrientation={isOrientation}
          />
        </TabPane>
        <TabPane tabId='calendar'>
          <DateCalendar
            setPage={setPage}
            topic={topic}
            educator={educator}
            setEducator={setEducator}
            timezone={zone}
            RenderTimezoneAndEducator={RenderTimezoneAndEducator}
            freeFrames={myFreeFrames}
            setTimeScheduled={setTimeScheduled}
            timeScheduled={timeScheduled}
            from={from}
            setCurrentMoment={setCurrentMoment}
            currentMoment={currentMoment}
            loading={loading}
          />
        </TabPane>
        <TabPane tabId='confirm'>
          <ConfirmRescheduled
            setPage={setPage}
            educator={educator}
            service={service}
            topic={topic}
            timeScheduledLabel={`${moment(
              timeScheduled?.date || timeScheduled,
            ).format('h:mma')} to ${moment(timeScheduled?.date || timeScheduled)
              .clone()
              .add(lessonDuration, 'm')
              .format('h:mma')} | ${moment(
              timeScheduled?.date || timeScheduled,
            ).format('MMM D, YYYY')}`}
          />
        </TabPane>
      </TabContent>
    )
  }

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <SelectDateHeader
          title='Select a Date'
          subtitle={
            isMeeting
              ? 'Counseling | Meeting'
              : `For ${service?.name} ${topic?.name} lesson | ${
                  page === 'confirm' ? `${zone.split('/')[1]}, GMT-4` : ''
                }`
          }
          showCalendar={page === 'calendar'}
          toggle={toggle}
        />
      }
    >
      <div className='m-0 p-0'>
        <RenderTimezoneAndEducator />
        <RenderBody page={page} />
        <StickyButton
          activity={loadOrientationMutation}
          condition
          disabled={timeScheduled === null}
          className='px-7 py-5 pt-0'
          label='Continue'
          color='#696CFF'
          onClickButtonFunction={() => {
            if (page === 'confirm') {
              if (isOrientation || isMeeting) {
                rescheduledOrientation({
                  changeCouncelorInput: {
                    startDate: timeScheduled?.date || timeScheduled,
                    endDate: moment(timeScheduled?.date || timeScheduled)
                      .add(lessonDuration, 'm')
                      .toISOString(),
                    councelorId: educator?.educatorUserId || educator?.id || '',
                    studentId: student?.studentUserId || '',
                    lessonId: dataLesson?.id || '',
                  },
                })
              }
            } else {
              setPage('confirm')
            }
          }}
        />
      </div>
    </Modal>
  )
}

export default RescheduledOrientation
