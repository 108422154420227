import strings from '../../i18n/strings'

export const initialState = {
  translationDictLoading: null,
  translationDictError: null,
  translationDictErrResponse: {},
  translationDictSuccess: null,

  selectedLang: 'en',
  strings: strings,
}
