import React from 'react'
import InfoCard from './UserCard'
import ProfileActions from './ProfileActions'
import useTranslation from '../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import PersonalModalForm from '../Modal/changePersonal'
import StudentModalForm from '../Modal/changeStudentInfo'
import {TOGGLE_MODAL_ACTION} from '../../../store/modal/const'
import PersonalInfoModal from '../Modal/readPersonal'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {useLocation} from 'react-router'
const InfoCards = (props) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const action = searchParams.get('action')
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const userData = useSelector((state) => state.user.info)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.personalInfo'})
  }
  return (
    <div className='w-100 h-100 znk-scroll-horizontal px-3 px-sm-5 px-md-7'>
      {userData && <InfoCard {...userData} />}
      <div
        className='btn-link font-weight-light align-self-start p-0 mb-4'
        onClick={breakWidth === 'SM' ? toggle : () => {}}
      >
        {translate('profile.viewAll')}
      </div>
      {userData && <ProfileActions {...userData} action={action} />}
      {userData && <PersonalModalForm {...userData} />}
      {userData && <StudentModalForm {...userData} />}
      {userData && <PersonalInfoModal {...userData} />}
    </div>
  )
}

export default InfoCards
