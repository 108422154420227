import {useLazyQuery, gql} from '@apollo/client'

export const useGetTestDatePlans = () => {
  return useLazyQuery(
    gql`
      query($testDate: DateTime) {
        myPlans(limit: 0, testDate: $testDate) {
          totalDocs
          docs {
            id
            kind
            name
            serviceId
            testDate
            status
            timesSkipped
            dateSkipped
            activityTest {
              scoreReleaseDate
            }
            selectedExam {
              examId {
                _id
              }
              scores {
                name
                results {
                  name
                  scale
                  value
                  values {
                    name
                    scale
                    value
                  }
                }
                examDate
                overall
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  )
}
