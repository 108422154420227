import React, {useEffect, useMemo, useState} from 'react'
import {
  ModalLayout,
  ListItem,
  IconCountry,
  IconArrowRight,
  ListOption,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import ChangeTimezone from '../../../../../Onboarding/components/ChangeTimezone'
import useScheduledOrientationModal from './useScheduledOrientation'
// MODEL
import useUpdateTimezone from '../../../../../Onboarding/Model/useUpdateTimezone'
import useGetOrientationHours from '../../../../Model/useGetOrientationHours'
import useAllUsersCounseler from '../../../../Model/useAllUsersCounseler'

import {showAlert} from '../../../../../../common/Alert/util'
import {CHANGE_TIMEZONE} from '../../../../../../store/user/const'
import moment from 'moment-timezone'
import {useSelector, useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import {TabContent, TabPane, Collapse} from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'

const MIN_OPTIONS = 7

const ScheduledOrientationModal = () => {
  const {
    isOpen,
    toggle,
    parseFrames,
    dataPlan,
    // isAdmin = false,
    onChange = () => {},
  } = useScheduledOrientationModal()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => state.user.info)
  const {timezone: userTimezone, utcOffSet} = useMemo(() => {
    if (userInfo?.timezone) {
      return {
        timezone: userInfo.timezone,
        utcOffSet: Math.ceil(moment().tz(userInfo.timezone).utcOffset() / 60),
      }
    }
    const myTimezone = moment.tz.guess()
    return {
      timezone: myTimezone,
      utcOffSet: Math.ceil(moment().tz(myTimezone).utcOffset() / 60),
    }
  }, [userInfo])
  // actualiza timezone en perfil del usuario
  const [updateTimezone, {loading: loadTimezone}] = useUpdateTimezone(
    (result) => {
      showAlert(
        {
          text: 'Success',
          status: 'success',
        },
        dispatch,
      )
      // actualiza timezone en redux
      dispatch({
        type: CHANGE_TIMEZONE,
        data: result?.updateTimezone,
      })
    },
    (err) => {
      console.error(err)
      showAlert(
        {
          text: err,
          status: 'error',
        },
        dispatch,
      )
    },
  )

  // obtiene orientador
  const {
    data: allUsersCounseler,
    loading: loadAllUsersCounseler,
  } = useAllUsersCounseler({type: 'counseling'})
  const counselers = useMemo(() => {
    return allUsersCounseler?.allUsersCounseler || []
  }, [allUsersCounseler])
  const currentCounseler = useMemo(() => {
    if (counselers?.length > 0) {
      return counselers[0]
    }
  }, [counselers])

  // obtiene disponibilidad
  const [
    getOrientationHours,
    {data: orientationHours, loading: loadOrientationHours},
  ] = useGetOrientationHours()
  useEffect(() => {
    if (currentCounseler) {
      getOrientationHours({
        variables: {
          educatorId: currentCounseler?.id || '',
          studentId: dataPlan?.studentUser?.studentUserId || userInfo.id,
          from: moment().toISOString(),
          to: moment().add(1, 'week').toISOString(),
        },
      })
    }
    // eslint-disable-next-line
    }, [userInfo, currentCounseler, dataPlan])

  const parsedFramesArray = useMemo(() => {
    if (
      orientationHours?.getOrientationHours?.length > 0 &&
      userTimezone &&
      counselers[0]?.timezone
    ) {
      return parseFrames(
        orientationHours.getOrientationHours || [],
        userTimezone,
        counselers[0]?.timezone,
        15,
      )
    }
    // setSelected(null)
    return []
    // eslint-disable-next-line
    }, [orientationHours, userTimezone, counselers]
  )

  const [selected, setSelected] = useState(null)
  const [showMore, setShowMore] = useState(false)
  useEffect(() => {
    setSelected(null)
    setShowMore(false)
  }, [parsedFramesArray])

  const RenderOption = ({frame}) => {
    const from = frame
    const timeLabel = `${moment(from).format('ddd DD, MMM hh:mma')} to ${moment(
      from,
    )
      .add(15, 'minutes')
      .format('hh:mma')}`
    return (
      <ListOption
        onClick={() => {
          setSelected(from)
        }}
        className={`mb-2 ${from === selected ? 'selected' : ''} `}
        spanClass='text-truncate'
        title={timeLabel}
        label={timeLabel}
      />
    )
  }
  return (
    <ModalLayout
      className='modal-colored modal-centered white-modal'
      topHead={
        <span className='h2 font-weight-bold text-title'>
          {translate('counselingOnboarding.orientation.modal.title')}
        </span>
      }
      isOpen={isOpen}
      toggle={toggle}
      // contentClassName={`h-auto`}
      disabledScrollAnimation
    >
      <div className='d-flex flex-column m-0 p-0'>
        <span className='mb-5'>
          {translate('counselingOnboarding.orientation.modal.info')}
        </span>
        <ChangeTimezone
          setTime={(newTimezone) => {
            updateTimezone({
              variables: {
                newTimezone,
                userId: userInfo?.id,
              },
            })
          }}
          time={userTimezone}
        >
          <ListItem
            hasHover
            className='hover-no-border'
            Left={
              <div className='d-flex flex-row align-items-center'>
                <IconCountry size={12} className='mx-2' />
                {loadTimezone ? (
                  <Skeleton height={20} width={100} count={1} className='' />
                ) : (
                  <span>
                    {userTimezone}
                    <span className='text-gray'>
                      (GMT{utcOffSet > 0 ? '+' : ''}
                      {utcOffSet})
                    </span>
                  </span>
                )}
              </div>
            }
            Right={
              <div className='hover-icon'>
                <IconArrowRight />
              </div>
            }
            showBottomLine
            showTopLine
          />
        </ChangeTimezone>
        <div className='d-flex flex-column p-0 m-0 my-4'>
          <TabContent
            activeTab={
              !loadOrientationHours &&
              !loadAllUsersCounseler &&
              parsedFramesArray
                ? 'show'
                : 'loading'
            }
          >
            <TabPane tabId='show'>
              {parsedFramesArray?.length > 0 ? parsedFramesArray
                .filter((_, index) => index < MIN_OPTIONS)
                .map((frame, index) => {
                  return <RenderOption index={index} frame={frame} />
                })
                : 
                (
                  <div className='d-flex flex-column m-0 p-0 my-2'>
                    <span className='text-title'>
                      {translate('home.onboard.approve.scheduled.noCounselorTime')}
                    </span>
                    <span
                      className='btn-link'
                      onClick={() => {
                        onChange(moment().year(2000).month(0).set('date', 15).toISOString(), currentCounseler)
                      }}
                    >
                      {translate(
                        'home.onboard.approve.scheduled.noCounselorTimeToContinue',
                      )}
                    </span>
                  </div>
                )
                }
              {parsedFramesArray?.length > MIN_OPTIONS && (
                <>
                  <Collapse isOpen={showMore}>
                    {parsedFramesArray
                      .filter((_, index) => index >= MIN_OPTIONS)
                      .map((frame, index) => {
                        return <RenderOption index={index} frame={frame} />
                      })}
                  </Collapse>
                  <div className='d-flex flex-row justify-content-center cursor-pointer text-primary'>
                    <span
                      className='btn-link'
                      onClick={() => {
                        setShowMore(!showMore)
                      }}
                    >
                      {showMore
                        ? translate(
                            'counselingOnboarding.orientation.modal.showLess',
                          )
                        : translate(
                            'counselingOnboarding.orientation.modal.showMore',
                          )}
                    </span>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane tabId='loading'>
              <Skeleton
                className='d-flex flex-column w-100 mb-2'
                count={5}
                height={40}
              />
            </TabPane>
          </TabContent>
          {parsedFramesArray?.length > 0 && <Button
            disabled={!selected || !currentCounseler}
            className='w-100 my-2'
            label={translate(
              'counselingOnboarding.orientation.modal.scheduledOrientation',
            )}
            color='primary'
            size='large'
            check
            onClick={() => {
              if (selected && currentCounseler)
                onChange(selected, currentCounseler)
            }}
          />}
        </div>
      </div>
    </ModalLayout>
  )
}

export default ScheduledOrientationModal
