import {useDispatch, useSelector} from 'react-redux'

const useViewPlanModal = () => {
  const dispatch = useDispatch()
  // Para que hook funciones debe haber solo UN componente NewTaskModal
  // renderizado en el dominio
  const reduxData = useSelector((state) => state.modal.counseling.viewPlanModal)
  const {isOpen, onChange, dataPlan, tab} = reduxData
  const setOpen = (open, onChange = () => {}, dataPlan = {}, tab = null) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'counseling.viewPlanModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        dataPlan,
        tab,
      },
    })
  }
  return {
    isOpen,
    onChange,
    dataPlan,
    tab, // cuando no se determina una tab externamente la variables queda nula
    setData: (newData = {}) => {
      setOpen(isOpen, onChange, newData)
    },
    open: (onChange = () => {}, dataPlan = {}, newTab = null) => {
      setOpen(true, onChange, dataPlan, newTab)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    setTab: (newTab = null) => {
      setOpen(isOpen, onChange, dataPlan, newTab)
    },
  }
}

export default useViewPlanModal
