import React, {useEffect, useState} from 'react'
import {
  ListItem,
  IconProgram,
  ListOption,
  Avatar,
  StickyButton,
  Button,
  CheckboxOption,
} from '@Knowledge-OTP/znk-ui-components'
import useFreeTimeFrames from '../../CopyLesson/TimeSelection/myFreeTimeFrames/index'
import useTranslation from '../../../../../../i18n/useTranslation'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import {useSelector} from 'react-redux'
import {TabContent, TabPane, Collapse} from 'reactstrap'
import {useGetOrientationHours} from '../Queries/useGetOrientationHours'

const TimeSelectionBlock = (props) => {
  const {
    selectData,
    setSelectData,
    onChange,
    educator,
    idUser,
    from,
    to,
    modalData,
    isOpen,
  } = props
  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find((serv) => serv.isOrientation)
  const isOrientation = orientation?.id === modalData?.lesson?.service?.id
  const isRecurring = !!modalData?.isRecurring
  const isPending = modalData?.lesson?.status === 'pending'
  // const zone = useSelector((state) => state.user.info.timezone)

  const {translate} = useTranslation()
  const {data, loading} = useFreeTimeFrames({
    educatorId: idUser,
    from,
    to,
    minDurationInMinutes: 50,
    involveMyself: true,
  })
  const [
    getOrientationHours,
    {loading: loadOrientationHours, data: dataOrientationHours},
  ] = useGetOrientationHours()
  useEffect(() => {
    if (isOrientation && idUser && from && to && isOpen) {
      getOrientationHours({
        variables: {
          educatorId: idUser,
          from,
          to,
        },
      })
    }
    // eslint-disable-next-line
  }, [idUser, from, to, isOpen, isOrientation])

  const goToCalendar = () =>
    onChange((val) => ({...val, stage: 'timeSelectionCalendar'}))
  const goToConfirm = () => {
    onChange((val) => ({...val, stage: 'confirmSelection'}))
  }
  let possibleBlocks = loading
    ? []
    : (isOrientation ? dataOrientationHours?.getOrientationHours || [] : data)
        .reduce((arr, timeFrame) => {
          const [fromISO, toISO] = timeFrame
          const fromMoment = moment(fromISO)
          const toMoment = moment(toISO)
            .subtract(50, 'minutes')
            .milliseconds(0)
            .seconds(0)
            .minutes(0)

          // eslint-disable-next-line
        while (fromMoment < toMoment) {
            const lessonArr = toMoment.clone()
            arr.unshift(lessonArr)
            if (isOrientation) toMoment.subtract(15, 'minutes')
            else toMoment.subtract(1, 'h')
          }
          return arr
        }, [])
        .filter((block) => block.hour() > 6 && block.hour() < 24)
        .sort((a, b) => a.valueOf() - b.valueOf())
  if (!isOrientation) possibleBlocks = possibleBlocks.filter((block) => block.valueOf() > from.valueOf())
  const [lengthBlocks, setLengthBlocks] = useState(5)
  // cantidad de bloques que se muestran en el listado
  const showMore = () => {
    setLengthBlocks(lengthBlocks + 5)
  }

  const setRescheduledRecurring = (bool) => {
    onChange((val) => ({
      ...val,
      isRecurring: bool,
      //, stage: 'timeSelectionCalendar'
    }))
  }
  return (
    <>
      <TabContent activeTab={educator ? 'show' : 'loading'}>
        <TabPane tabId='loading'>
          <div className='d-flex flex-row align-items-center justify-content-start mb-2 p-4'>
            <Skeleton count={1} className='avatar avatar-medium' />
            <Skeleton count={1} height={20} width={100} className='ml-2' />
          </div>
        </TabPane>
        <TabPane tabId='show'>
          <ListItem
            showBottomLine
            showTopLine
            className='m-0 px-4 py-4'
            Left={
              <div className='d-flex align-items-center text-muted d-inline-block'>
                <Avatar
                  name={`${educator?.firstName} ${educator?.lastName}`}
                  named={false}
                  uri={educator?.avatar || null}
                  size='avatar-medium'
                />
                <span className='ml-3 h4'>{`${educator?.firstName} ${educator?.lastName}`}</span>
              </div>
            }
          />
        </TabPane>
      </TabContent>

      <div className='m-0 pb-4 pt-3 px-4'>
        {/**
         * 
        <label className='h45 text-gray my-2'>
          {translate('lessons.modal.rescheduledpending.suggestions')} :{' '}
        </label>
        * **/}
        <TabContent
          activeTab={
            !loading && !loadOrientationHours && possibleBlocks.length > 0
              ? 'show'
              : 'loading'
          }
        >
          <TabPane tabId='loading'>
            <Skeleton count={5} height={47} style={{borderRadius: '12px'}} />
          </TabPane>
          <TabPane tabId='show'>
            <div className='m-0 p-0 d-flex flex-column'>
              {possibleBlocks.slice(0, lengthBlocks).map((frame, idx) => {
                const titleLabel = ` ${frame?.format('h:mma')} - ${frame
                  ?.clone()
                  .add(isOrientation ? 15 : 50, 'm')
                  .format('h:mma')}
                              | ${frame?.format('MMM Do, YYYY')}`
                return (
                  <ListOption
                    key={idx}
                    className='my-1'
                    classNameFilledIcon='znk-list-option-selected-inner-primary'
                    selected={selectData?.time?.id === frame.toISOString()}
                    onClick={() => {
                      setSelectData({
                        ...selectData,
                        time: {
                          id: frame.toISOString(),
                          time: frame.toISOString(),
                        },
                      })
                    }}
                    id='x-0'
                    childClassName='text-truncate'
                    label={
                      <span className='h45' title={titleLabel}>
                        {titleLabel}
                      </span>
                    }
                    icon={
                      <IconProgram
                        size={16}
                        style={
                          selectData?.time?.id === frame?.toISOString()
                            ? {backgroundColor: '#696CFF'}
                            : {}
                        }
                        color={
                          selectData?.time?.id === frame?.toISOString()
                            ? 'white'
                            : '#72809D'
                        }
                        className='znk-icon-service'
                      />
                    }
                  />
                )
              })}
              {isOrientation && (
                <Button
                  size='small'
                  className='w-100 mt-2'
                  color='primary'
                  label='Show more'
                  onClick={() => {
                    showMore()
                  }}
                />
              )}
            </div>
          </TabPane>
        </TabContent>
        <div className='my-2 d-flex flex-row align-items-center justify-content-start'>
          <CheckboxOption
            onChange={(e) => {
              setRescheduledRecurring(e)
            }}
            defaultState={isRecurring}
            value={isRecurring}
            className='mr-1'
          />
          <span
            className='h45 text-gray cursor-pointer'
            onClick={() => {
              setRescheduledRecurring(!isRecurring)
            }}
          >
            Apply to current & future lessons
          </span>
        </div>
        <Collapse isOpen={isRecurring && isPending}>
          <div className='d-flex flex-row m-0 p-0 justify-content-end'>
            <span className='h45 text-error cursor-pointer'>
              (Only pending lessons will be reschedule)
            </span>
          </div>
        </Collapse>
        <StickyButton
          className='my-4'
          color='#696CFF'
          disabled={!selectData?.time?.id}
          condition
          onClickButtonFunction={goToConfirm}
        />
        {!isOrientation && (
          <span
            className='h45 btn-link text-muted my-5'
            style={{cursor: 'pointer'}}
            onClick={goToCalendar}
          >
            {translate('lessons.modal.rescheduledpending.needmore')}
          </span>
        )}
      </div>
    </>
  )
}

export default TimeSelectionBlock
