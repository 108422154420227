import React, {useEffect, useState} from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import UseGetBackUpLink from '../../../../Model/useBackUpLink'
import {db} from '../../../../../VirtualChat/fireBase'
import {useChatNotification} from '../../../../../VirtualChat/Model/useChatNotification'
const PopupBackupLink = (props) => {
  const {
    isOpen,
    toggle,
    educatorId = '',
    collectionId = '',
    // linkFromProps = '', del lado del estudiante se puede obtener el link sin llamar a la DB
    userInfo,
    isEducator = false,
  } = props
  const userId = userInfo?.studentUserId
  const firstName = userInfo?.firstName || ''
  const lastName = userInfo?.lastName || ''
  const {translate} = useTranslation()
  const [link, setLink] = useState('')
  const [message, setMessage] = useState(
    `Hi! I'm waiting for you on the back up link. Join me here: ${link}`,
  )
  const [messageData, setMessageData] = useState({
    error: false,
    loading: null,
  })
  const [
    getBackUpLink,
    {
      data: dataBackUpLink,
      // refetch: refetchCalendarQuery,
      // eslint-disable-next-line
      loading: loadingBackUpLink,
    },
  ] = UseGetBackUpLink()
  // eslint-disable-next-line
  const [chatNotification, { data: dataChatNotification, loading: loadignChatNotification, refetch: refetchChatNotification }] = useChatNotification(   
    () => {},
    (err) => {
      console.error('Error with chat notification : ', err)
    },
  )
  const confirmFunction = () => {
    handleSubmit()
    toggle()
  }

  const cancelFunction = () => {
    toggle()
  }

  const sendMessage = async () => {
    const variablesToSend = {
      lessonId: collectionId,
      chanel: 'lessons',
    }
    const regexPhone = /((?:\+|00)[17](?: |)?|(?:\+|00)[1-9]\d{0,2}(?: |)?|(?:\+|00)1\d{3}(?: |)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |)[0-9]{3}(?: |)[0-9]{4})|([0-9]{7}))/g
    // eslint-disable-next-line
        const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (!message || message.match(regexPhone) || message.match(regexEmail))
      return
    setMessageData({
      ...messageData,
      loading: true,
    })
    const snapshot = await db.collection('messages').doc(collectionId).get()
    const numberKey =
      Object.keys(snapshot.data()).map((key) => snapshot.data()[key]).length ||
      0
    const keyMessage = `message${numberKey}`
    const objectMessage = {
      [keyMessage]: {
        text: message,
        userName: `${firstName} ${lastName}`,
        userID: isEducator ? `${educatorId}` : `${userId}`,
        createdAt: new Date().toISOString(),
        index: numberKey,
      },
    }
    try {
      await db.collection('messages').doc(collectionId).update(objectMessage)
      setMessageData({
        ...messageData,
        loading: false,
      })
      chatNotification({
        variables: variablesToSend,
      })
    } catch (error) {
      setMessageData({
        ...messageData,
        error: true,
        loading: false,
      })
    }
    // la funcion que envia el mensaje
  }

  const handleSubmit = async () => {
    const fireStoreDocument = db.collection('messages').doc(collectionId)
    fireStoreDocument.get().then((doc) => {
      if (doc.exists) {
        sendMessage()
      } else {
        fireStoreDocument.set({}).then(() => {
          sendMessage()
        })
      }
    })
  }

  useEffect(() => {
    if (educatorId !== '') {
      getBackUpLink({
        variables: {
          id: educatorId,
        },
      })
    }
    // eslint-disable-next-line
  }, [educatorId])

  useEffect(() => {
    setLink(dataBackUpLink?.user?.educatorInfo?.backupLink)
  }, [dataBackUpLink])

  useEffect(() => {
    setMessage(
      `Hi! I'm waiting for you on the back up link. Join me here: ${link}`,
    )
  }, [link])

  return (
    <ConfirmModal
      className='m-4 d-flex flex-column'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('lessons.modal.backupLinkPopup.title')}
      body={translate('lessons.modal.backupLinkPopup.body')}
      classNameActionDiv='d-flex flex-column justify-content-end w-80'
      alignRight
      confirmAction={() => {
        confirmFunction()
      }}
      cancelAction={() => {
        cancelFunction()
      }}
      confirmText={translate('lessons.modal.backupLinkPopup.confirmButton')}
      cancelText={translate('lessons.modal.backupLinkPopup.cancelButton')}
    />
  )
}

export default PopupBackupLink
