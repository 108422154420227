import React, {useEffect, useState} from 'react'
import {
  ListItem,
  IconCreditCard,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import ModalCard from './modalCard'

const PaymentModalForm = (props) => {
  const {paymentMethod, action, isProfileView = true, handleModalClose=false} = props
  const [isOpen, setOpen] = useState(false)
  const {translate} = useTranslation()
  const toggle = () => setOpen((open) => !open)
  const {breakWidth} = useWindowDimensions()
  const handleClose = () => {
    setOpen(false)
    if (handleModalClose) {
      handleModalClose()
    }
  }
  useEffect(() => {
    if (action && (action === 'addpayment' || action === 'addmethodpaymentfromprofile')) {
      toggle()
    }
  }, [action])
  return (
    <span>
      {action !== 'addmethodpaymentfromprofile' && (
        <ListItem
          onClick={toggle}
          showBottomLine={isProfileView}
          hasHover
          hasColorHover
          Left={
            <span className='d-flex align-items-center'>
              {isProfileView && <IconCreditCard size={16} className='mr-3' />}
              <span className='text-black'>
                {paymentMethod === null
                  ? translate('profile.actions.changeMethod.add')
                  : translate('profile.actions.changeMethod.change')}
              </span>
            </span>
          }
        />
      )}
      <ModalCard breakWidth={breakWidth} isOpen={isOpen} onClick={handleClose} />
    </span>
  )
}

PaymentModalForm.propTypes = {}

export default PaymentModalForm
