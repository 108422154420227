import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ScoreListItem,
  Form,
  Button,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import {FormGroup, Label} from 'reactstrap'

const IELTSComponent = (props) => {
  const {propsToSend} = props
  const {
    mutation,
    planId,
    phase,
    stepId,
    taskId,
    buttonStyles,
    divButtonClass,
    sectionTitleColor,
    scoresComponentClass,
    isEditable,
    uploadFileOption,
    activity,
  } = propsToSend
  const scale = '0-9'
  const scaleMin = parseInt(scale.split('-')[0])
  const scaleMax = parseInt(scale.split('-')[1])
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const scores = reduxData?.scores
  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  const enableSaveButton = () => {
    return (
      scaleMin <= scores?.listening &&
      scores?.listening <= scaleMax &&
      scaleMin <= scores?.reading &&
      scores?.reading <= scaleMax &&
      scaleMin <= scores?.writing &&
      scores?.writing <= scaleMax &&
      scaleMin <= scores?.speaking &&
      scores?.speaking <= scaleMax
      // && !!scores?.examDate
    )
  }

  const roundOverall = (overall) => {
    const decimal = overall - Math.floor(overall)

    let result = 0
    const pivot = (parseInt(overall) + (parseInt(overall) + 1)) / 2
    switch (true) {
      case overall === parseInt(overall):
        // Caso 1: El promedio es un número entero
        result = overall
        break
      case overall === pivot:
        // Caso 2: El promedio es 1.5 , 2.5, etc.
        result = pivot
        break
      case overall < pivot:
        // Caso 3: El promedio es un decimal mayor a X.0 y menor a X.5
        if (decimal < 0.25) {
          result = Math.floor(overall)
        } else {
          result = pivot
        }
        // result = pivot
        break
      case overall > pivot:
        // Caso 4: El promedio es un decimal mayor a X.5 y menor al entero próximo más grande
        if (decimal < 0.75) {
          result = pivot
        } else {
          result = Math.ceil(overall)
        }
        break
      default:
        console.error('case not found')
    }
    return result
  }

  const registerScoresFunction = () => {
    const examInput = {
      name: scores?.name,
      examDate: scores?.examDate,
      overall: scores?.overall,
      results: [
        {
          name: 'Listening',
          scale: scale,
          values: [
            {
              name: 'Listening',
              scale: scale,
              value: scores?.listening,
            },
          ],
        },
        {
          name: 'Reading',
          scale: scale,
          values: [
            {
              name: 'Reading',
              scale: scale,
              value: scores?.reading,
            },
          ],
        },
        {
          name: 'Writing',
          scale: scale,
          values: [
            {
              name: 'Writing',
              scale: scale,
              value: scores?.writing,
            },
          ],
        },
        {
          name: 'Speaking',
          scale: scale,
          values: [
            {
              name: 'Speaking',
              scale: scale,
              value: scores?.speaking,
            },
          ],
        },
        {
          name: 'Overall Band',
          scale: scale,
          values: [
            {
              name: 'Overall Band',
              scale: scale,
              value: scores?.overall,
            },
          ],
        },
      ],
    }
    mutation({
      variables: {
        planId: planId,
        phase: phase,
        stepId: stepId,
        taskId: taskId,
        examInput,
      },
    })
  }

  return (
    <div className={`${scoresComponentClass}`}>
      <Form Form value={scores}>
        <FormGroup className='mt-7 mb-0'>
          <Label style={{color: sectionTitleColor}}>IELTS</Label>
          <div className='row pl-2'>
            <div className='w-100'>
              <ScoreListItem
                id='listening'
                className='m-1'
                label='Listening'
                value={scores?.listening}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    listening: parseFloat(e),
                    overall: roundOverall(
                      (parseFloat(e) +
                        scores?.reading +
                        scores?.writing +
                        scores?.speaking) /
                        4,
                    ),
                  })
                }}
                isEditable={isEditable}
              />
              <ScoreListItem
                id='reading'
                className='m-1'
                label='Reading'
                value={scores?.reading}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    reading: parseFloat(e),
                    overall: roundOverall(
                      (parseFloat(e) +
                        scores?.writing +
                        scores?.speaking +
                        scores?.listening) /
                        4,
                    ),
                  })
                }}
                isEditable={isEditable}
              />
              <ScoreListItem
                id='writing'
                className='m-1'
                label='Writing'
                value={scores?.writing}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    writing: parseFloat(e),
                    overall: roundOverall(
                      (parseFloat(e) +
                        scores?.reading +
                        scores?.speaking +
                        scores?.listening) /
                        4,
                    ),
                  })
                }}
                isEditable={isEditable}
              />
              <ScoreListItem
                id='speaking'
                className='m-1'
                label='Speaking'
                value={scores?.speaking}
                onChange={(e) => {
                  setScoresData({
                    ...scores,
                    speaking: parseFloat(e),
                    overall: roundOverall(
                      (parseFloat(e) +
                        scores?.reading +
                        scores?.writing +
                        scores?.listening) /
                        4,
                    ),
                  })
                }}
                isEditable={isEditable}
              />
            </div>
          </div>
        </FormGroup>
        <div className={`${divButtonClass}`}>{uploadFileOption}</div>
        <div
          className={`${divButtonClass}`}
          style={{
            position: 'sticky',
            bottom: '5px',
          }}
        >
          {isEditable && (
            <Button
              style={{backgroundColor: 'rgb(181, 74, 226)'}}
              className={`${buttonStyles}`}
              size='large'
              activity={activity}
              disabled={!enableSaveButton() || activity}
              label={
                <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                  <span>Submit Scores</span>
                  <IconArrowRight
                    size={12}
                    style={{verticalAlign: 'inherit'}}
                    className='text-white'
                  />
                </div>
              }
              onClick={() => {
                registerScoresFunction()
              }}
            />
          )}
        </div>
      </Form>
    </div>
  )
}

export default IELTSComponent
