import {useLazyQuery, gql} from '@apollo/client'

const useGetOrientationHours = () => {
  return useLazyQuery(
    gql`
      query(
        $educatorId: ID!
        $studentId: ID
        $from: DateTime!
        $to: DateTime!
      ) {
        getOrientationHours(
          from: $from
          to: $to
          educatorId: $educatorId
          studentId: $studentId
        )
      }
    `,
  )
}

export default useGetOrientationHours
