import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import {Modal} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../../store/modal/const'
import SetPendingLesson from '../SetPendingLesson'
import moment from 'moment'

const CancelLesson = (props) => {
  const {
    lessonId,
    refetchLessons,
    startDate,
    isLocked,
    isGroupLesson,
    className,
  } = props
  const isModalOpen = useSelector((state) => state.modal.program.cancel)
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'program.cancel'})
  }
  const timeForLesson = moment
    .duration(moment(startDate).diff(moment()))
    .asHours()
  const time24HoursLess = timeForLesson >= 24
  const cannotPost = !time24HoursLess || isGroupLesson || isLocked

  return (
    <>
      <div className={`m-0 p-0 ${className}`} onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isModalOpen}
        toggle={toggle}
      >
        <div className='row m-5 p-0'>
          <span className='h2 m-0 pr-5'>
            <b>
              {
                !cannotPost
                  ? translate('lessons.modal.cancel.postpone') // si se puede posponer
                  : translate(
                      `lessons.modal.cancel.cantpostpone${
                        !time24HoursLess
                          ? '24'
                          : isGroupLesson
                          ? 'Group'
                          : isLocked
                          ? 'Locked'
                          : 'Nodefined'
                      }`,
                    ) // NO se puede posponer
              }
            </b>
          </span>
          <span className='text-muted h4 m-0 pr-5 py-4'>
            {
              !cannotPost
                ? translate('lessons.modal.cancel.postponeBody') // si se puede
                : translate(
                    `lessons.modal.cancel.cantpostponeBody${
                      !time24HoursLess
                        ? '24'
                        : isGroupLesson
                        ? 'Group'
                        : isLocked
                        ? 'Locked'
                        : 'Nodefined'
                    }`,
                  ) // NO se puede
            }
          </span>
          <div className='m-0 p-0 w-100 d-flex justify-content-end'>
            <div className='w-40 d-flex justify-content-end flex-column'>
              <button className='btn btn-link p-0' onClick={toggle}>
                {translate('lessons.modal.cancel.ok')}
              </button>
              {!cannotPost && (
                <SetPendingLesson
                  refetchLessons={refetchLessons}
                  lessonId={lessonId}
                  callBack={toggle}
                >
                  <button className='btn btn-link p-0 text-muted'>
                    {translate(
                      'lessons.modal.rescheduledpending.leavelessonpending',
                    )}
                  </button>
                </SetPendingLesson>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CancelLesson
