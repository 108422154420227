import React, {useState, useEffect, useMemo} from 'react'
import LessonsIcons from '../../components/LessonsIcons'
import {
  Avatar,
  ListItem,
  ScheduledCalendar,
  IconArrowRight,
  IconCountry,
  CarouselFrame,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useChangeTimezone from '../../components/ChangeTimezone/useChangeTimezone'
// import UpdateUser from '../../../../Model/UpdateUser'
import ChangeEducator from '../../components/ChangeEducator'
import ChangeTimezone from '../../components/ChangeTimezone'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {gql, useQuery} from '@apollo/client'
import moment from 'moment-timezone'
import {
  changeEducatorForTopicPart,
  nextPage,
  setTimeOnTopicPartLesson,
  rescheduledLessonCalendar,
} from '../../utils'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

export const ScheduledHeader = (props) => {
  const {translate} = useTranslation()

  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimes, keyLesson, dataPlan} = reduxData
  const {ranges} = dataPlan
  const currentRange = ranges[parseInt(keyLesson?.split('-')[1])]
  const timeLabel =
    currentRange[0] && currentRange[1]
      ? `${translate('home.onboard.approve.before.from')} 
      ${moment(currentRange[0]?.split('T')[0]).format('MMMM Do')} 
      ${translate('home.onboard.approve.before.to')} 
      ${moment(currentRange[1]?.split('T')[0]).format('MMMM Do')}`
      : 'No defined'

  return (
    <div className='m-0 p-0'>
      <div className='d-flex my-2 overflow-hidden'>
        <CarouselFrame>
          <LessonsIcons
            data={
              scheduledTimes
                ? Object.entries(scheduledTimes).reduce(
                    (arrayData, [key, objTopic]) => {
                      if (key.split('-')[1] === keyLesson.split('-')[1]) {
                        return arrayData.concat(
                          objTopic.times.map((item) => {
                            return {
                              scheduled: item !== null,
                              lesson: objTopic.topic.name,
                              idTopic: objTopic.topic.id.toString(),
                              onClick: () => {
                                // rescheduledLesson(key, indexLesson)
                              },
                            }
                          }),
                        )
                      }
                      return arrayData
                    },
                    [],
                  )
                : []
            }
            idTopic={keyLesson?.split('-')[0]}
            lesson={1}
            checked
            selected={false}
          />
        </CarouselFrame>
      </div>
      <div className='m-0 p-0 d-flex flex-column'>
        <span className='text-gray'>
          {translate('home.onboard.approve.scheduled.choose', {
            index:
              scheduledTimes[keyLesson]?.times.indexOf(null) === -1
                ? scheduledTimes[keyLesson]?.times.length - 1
                : scheduledTimes[keyLesson]?.times.indexOf(null),
            name: scheduledTimes[keyLesson]?.topic?.name,
          })}
        </span>
        <span className='text-gray'>{timeLabel}</span>
      </div>
    </div>
  )
}
export const ScheduledBody = (props) => {
  const {setPage, page, isAdmin} = props

  const dataRedux = useSelector((state) => state.modal.onboarding.data)
  const {
    scheduledTimes,
    keyLesson,
    indexLesson,
    dataPlan,
    orientationKey,
  } = dataRedux
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'

  const services = useSelector((state) => state.services.services)
  const {topics} = useMemo(() => {
    if (dataPlan?.serviceId) return services[dataPlan.serviceId]
    return []
  }, [services, dataPlan])

  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const dispatch = useDispatch()
  const topicObj = scheduledTimes && keyLesson ? scheduledTimes[keyLesson] : {}
  const zone = useSelector((state) => state.user.info.timezone)
  const reduxMedium = useSelector((state) => state.modal.onboarding.mediumModal)

  const {setZone, loading: loadZone} = useChangeTimezone()
  // const [changeTimezone, {loading: loadZone}] = UseUpdateTimezone()

  // const setZone = (time) => {
  //   // ejecutar mutación updateUser
  //   changeTimezone({
  //     variables: {newTimezone: time},
  //   }).then(
  //     ({data: {updateTimezone}}) => {
  //       dispatch({
  //         type: CHANGE_TIMEZONE,
  //         data: updateTimezone,
  //       })
  //       // deselecciona bloques
  //       // setCheckedFrames(getNewChecked())
  //       showAlert(
  //         {
  //           text: translate('modal.changeTimezone.successAlert'),
  //           status: 'success',
  //         },
  //         dispatch,
  //       )
  //     },
  //     (err) => {
  //       console.error(err)
  //       showAlert(
  //         {
  //           text: translate('modal.changeTimezone.errorAlert'),
  //           status: 'error',
  //         },
  //         dispatch,
  //       )
  //     },
  //   )
  // }

  const {translate, selectedLang} = useTranslation()

  const now = useMemo(() => moment(), [])
  const educator =
    topicObj?.educator?.filter((edu) => edu.selected).length > 0
      ? topicObj?.educator?.filter((edu) => edu.selected)[0]
      : topicObj?.educator[0]
  const from = topicObj?.range[0] || now.clone().toISOString()
  const to = topicObj?.range[1] || now.clone().toISOString()

  const variables = useMemo(() => {
    // luego de actualizar variables se ejecuta useQuery myFreeTimesFrames
    if (page && educator?.id && from && to) {
      const variables = {
        educatorId: educator?.id,
        minDurationInMinutes: 50,
        from,
        to,
      }

      if (isAdmin) {
        // revisa colisiones entre el educador y el estudiante
        variables.involveMyself = false
        variables.studentIds = [dataPlan?.studentUserId]
      } else variables.involveMyself = true
      return variables
    }
    return {}
    // eslint-disable-next-line
  }, [from, to, educator?.id, page, isAdmin, dataPlan.studentUserId])
  const {data, loading} = useQuery(
    gql`
      query(
        $educatorId: ID!
        $minDurationInMinutes: Int
        $from: DateTime!
        $to: DateTime!
        $involveMyself: Boolean!
        $studentIds: [ID!]
      ) {
        myFreeTimeFrames(
          educatorUserId: $educatorId
          minDurationInMinutes: $minDurationInMinutes
          from: $from
          to: $to
          involveMyself: $involveMyself
          studentIds: $studentIds
        )
      }
    `,
    {
      variables: variables,
    },
  )

  const [selectedFrame, setSelectedFrame] = useState({})

  const nextStep = () => {
    const {page: next, key: nextKey, index} = nextPage(
      scheduledTimes,
      keyLesson,
    )
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {...dataRedux, keyLesson: nextKey, indexLesson: index},
    })
    setSelectedFrame({})
    setCheckedFrames(getNewChecked())
    if (next === 'end') {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'onboarding.smallModal',
        data: {...reduxSmall, isOpen: false},
      })
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'onboarding.mediumModal',
        data: {...reduxMedium, page: 'confirmScheduled'},
      })
    } else setPage(next)
  }
  useEffect(() => {
    if (keyLesson === 'Nodefined-0') nextStep()
    // eslint-disable-next-line
  }, [keyLesson])

  const continueCheck = (scheduledTimes, newTime) => {
    // let newScheduledTimes = setTimeOnTopicPart( scheduledTimes,  keyLesson, newTime.time )
    const newScheduledTimes = setTimeOnTopicPartLesson(
      scheduledTimes,
      keyLesson,
      newTime.time,
      indexLesson,
    )
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {...dataRedux, scheduledTimes: newScheduledTimes},
    })
    setSelectedFrame({})
    if (['scheduled'].includes(nextPage(scheduledTimes, keyLesson).page))
      nextStep()
    else setCheckedFrames(getNewChecked())
  }

  const getNewChecked = () => {
    /** dataPlan.groupInfo.PartsSchedule:
        [{
          from: "2021-04-13T00:00:00.000Z"
          lessonsPerWeekGroup: Array(3)
          [
            day: 1
            duration: 50
            educatorId: "5fe2504237141078e0d4527f"
            hour: "23:00"
            topicId: "5f58e6cf1b8c4862420e394d"
            totalLessons: 2
          ] length = cantidad de lecciones por parte
        }] length = cantidad de partes 
    * **/
    const groupLessons = dataPlan?.groupInfo?.PartsSchedule?.reduce(
      (arrayTimes, {lessonsPerWeekGroup}, indexPart) => {
        if (parseInt(keyLesson?.split('-')[1]) === indexPart) {
          return arrayTimes.concat(
            lessonsPerWeekGroup.map((lesson) => {
              // console.log("lesson", lesson)
              const momento = moment()
                .tz('utc')
                .day(lesson?.day)
                .set({
                  hour: parseInt(lesson?.hour?.split(':')[0]),
                  minutes: parseInt(lesson?.hour?.split(':')[1]),
                })
              return {
                time: momento.tz(zone),
                current: false,
                text: `Group: ${topics[lesson?.topicId]?.name}`,
              }
            }),
          )
        }
        return arrayTimes
      },
      [],
    )
    return Object.entries(scheduledTimes)
      .filter(([key]) => key !== ORIENTATION_KEY)
      .reduce((array, [key, objValue]) => {
        if (
          key.split('-')[1].toString() === keyLesson.split('-')[1].toString()
        ) {
          return array.concat(
            objValue.times
              .filter((time) => time !== null)
              .map((item, indexLesson) => {
                return {
                  time: item.tz(zone),
                  current: keyLesson === key,
                  indexLesson,
                  text: objValue?.topic?.name,
                }
              }),
          )
        }
        return array
      }, groupLessons || [])
  }

  const [checkedFrames, setCheckedFrames] = useState(getNewChecked())
  useEffect(() => {
    // actualiza checked cuando se cambia de parte
    // if(page === 'introductionPart' || page === 'introductionTopic' || true) setCheckedFrames(getNewChecked())
    setCheckedFrames(getNewChecked())
    // eslint-disable-next-line
  }, [page, JSON.stringify(scheduledTimes), zone])

  const [isOpenEducator, setOpenEducator] = useState(false)
  const toggleEducator = () => {
    setOpenEducator(!isOpenEducator)
  }

  const [isOpenCollapseEducator, setOpenCollapseEducator] = useState(false)

  // volver a seleccionar bloque del tópico actual
  const rescheduledLesson = (indexL) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {
        ...dataRedux,
        ...rescheduledLessonCalendar(scheduledTimes, keyLesson, indexL),
      },
    })
  }

  return (
    <div className='m-0 p-0'>
      <ChangeEducator
        educator={{
          ...scheduledTimes[keyLesson]?.educator,
          lesson: scheduledTimes[keyLesson]?.topic?.name,
        }}
        setEducator={(newEducator) => {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.data',
            data: {
              ...dataRedux,
              ...changeEducatorForTopicPart(
                scheduledTimes,
                keyLesson,
                newEducator,
              ),
            },
          })
          setCheckedFrames(getNewChecked())
        }}
        idTopic={scheduledTimes[keyLesson]?.topic?.id}
        idService={dataPlan?.serviceId}
        isOpen={isOpenEducator && !educator?.lock}
        toggle={toggleEducator}
      />
      <div className='borderTop' />
      <label className='text-gray h4 mt-4'>
        {translate('program.plan.infoModal.educator')}
      </label>
      <ListItem
        hasHover={!educator?.lock}
        className='hover-no-border'
        onClick={() => {
          if (educator.lock) {
            setOpenCollapseEducator(true)
            setTimeout(() => {
              setOpenCollapseEducator(false)
            }, 3000)
          } else toggleEducator()
        }}
        Left={
          <div className='d-flex align-items-center text-muted pl-2'>
            <Avatar
              name={`${educator?.name}`}
              named={false}
              uri={null}
              size='avatar-medium'
            />
            <span className='ml-2'>
              {`
                  ${`${educator?.name}`} 
                  ${`(${translate(
                    'home.onboard.approve.scheduled.recommended',
                  )})`}
              `}
            </span>
          </div>
        }
        Right={
          !educator?.lock && (
            <div className='pr-2 hover-icon'>
              <IconArrowRight />
            </div>
          )
        }
      />
      <Collapse className='mb-3' isOpen={isOpenCollapseEducator}>
        <span className='text-gray h4'>
          {translate('home.onboard.change.cantchangeedu')}
        </span>
      </Collapse>
      <ChangeTimezone scrollTo={zone} time={zone} setTime={setZone}>
        <ListItem
          showBottomLine
          showTopLine
          hasHover
          className='hover-no-border'
          Left={
            <div className='d-flex align-items-center text-muted pl-2'>
              <IconCountry className='mx-3' size={16} />
              <span className='ml-2'>{zone}</span>
            </div>
          }
          Right={
            <div className='pr-2 hover-icon'>
              <IconArrowRight />
            </div>
          }
        />
      </ChangeTimezone>
      <ScheduledCalendar
        loading={loading || loadZone}
        setSelected={(newTime) => {
          setSelectedFrame(newTime)
          continueCheck(scheduledTimes, newTime)
        }}
        selectedDay={selectedFrame}
        educator={educator}
        checkedDays={checkedFrames}
        myFreeTimeFrames={data?.myFreeTimeFrames}
        from={moment(from)}
        fromPart={from}
        toPart={to}
        zone={zone}
        lang={selectedLang}
        className='pl-0 pr-0 pt-6 pb-0'
        getIndexOfLesson={(indexL) => {
          rescheduledLesson(indexL)
        }}
      />
      <StickyButton
        className='mt-3'
        condition
        disabled={
          ![
            'introductionPart',
            'introductionTopic',
            'end',
            'scheduledOrientation',
          ].includes(nextPage(scheduledTimes, keyLesson).page)
        }
        onClickButtonFunction={() => {
          nextStep()
        }}
      />
    </div>
  )
}

export default {ScheduledHeader, ScheduledBody}
