import {useMutation, gql} from '@apollo/client'

const useSetManuelPaymentMethod = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation update($userId: String) {
        setManualPaymentMethod(userId: $userId) {
          paymentMethod {
            ... on ManualPaymentMethod {
              kind
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default useSetManuelPaymentMethod
