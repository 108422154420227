import React from 'react'
import {defaultProps, propTypes} from '../../propTypes'
import {useDispatch, useSelector} from 'react-redux'
import {TOGGLE_MODAL_ACTION} from '../../../../../store/modal/const'
import useTranslation from '../../../../../i18n/useTranslation'
import {ListItem} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import startCase from 'lodash/startCase'
import CountryFlag from '../../../Components/CountryFlag'
import EducatorCalendarLink from './Components/EducatorCalendarLink'

const PersonalBlock = (props) => {
  const {
    firstName,
    lastName,
    emails,
    phone,
    birthdate,
    address,
    city,
    state,
    zipCode,
    countryId,
    spokenLanguages,
    gender,
    roles,
  } = props
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const countries = useSelector((state) => state.countries.countries)
  const toggle = () =>
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'profile.personal'})
  return (
    <div className='d-flex flex-column mb-3 mt-1'>
      <span className='d-flex text-logo justify-content-between my-1'>
        <span className='font-weight-bold'>
          {translate('profile.personalInfo')}
        </span>
        <button className='btn btn-link p-0' onClick={toggle}>
          {translate('misc.edit')}
        </button>
      </span>
      <div className='font-weight-normal d-flex flex-column'>
        <ListItem
          className='px-0'
          showTopLine
          showBottomLine
          Left={
            roles.includes('STUDENT') ? (
              <span>{translate('profile.studentName')}</span>
            ) : (
              <span>{translate('profile.userName')}</span>
            )
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {firstName && lastName ? `${firstName} ${lastName}` : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.email')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {emails && emails.length ? emails[0].address : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>
              {translate('profile.actions.editPersonalInfo.birthday')}
            </span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {birthdate ? moment(birthdate).format('MM-DD-YYYY') : '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.gender')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {startCase(gender) || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.phone')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {phone || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.address')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {address || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.city')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {city || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.state')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {state || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.zipCode')}</span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {zipCode || '-'}
            </span>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>{translate('profile.actions.editPersonalInfo.country')}</span>
          }
          Right={
            <div
              className='d-flex flex-row align-items-center'
              style={{gap: 10}}
            >
              <CountryFlag
                style={{marginBottom: '3px'}}
                // size={50}
                tooltipDisabled
                country={countries[countryId]}
              />
              <span className='text-secondary font-weight-light'>
                {countryId && countries && countries[countryId]
                  ? countries[countryId].name
                  : '-'}
              </span>
            </div>
          }
        />
        <ListItem
          className='px-0'
          showBottomLine
          Left={
            <span>
              {translate('profile.actions.editPersonalInfo.spokenLanguages')}
            </span>
          }
          Right={
            <span className='text-secondary font-weight-light'>
              {spokenLanguages || '-'}
            </span>
          }
        />
        {roles.includes('STUDENT') && (
          <EducatorCalendarLink
            classNameText={'text-secondary font-weight-light'}
            user={props}
            refetch={() => {}}
            role={'student'}
          />
        )}
      </div>
    </div>
  )
}
PersonalBlock.propTypes = propTypes
PersonalBlock.defaultProps = defaultProps

export default PersonalBlock
