import React from 'react'
import {
  IconInvoice,
  ResumenInvoice,
  IconStarFilled,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useModalState from '../../../../hooks/useModalState'

const InvoiceDetails = (props) => {
  const {onChange} = useModalState('payments.viewInvoice')
  const {invoice} = props

  return (
    <ResumenInvoice
      onClick={() => onChange(() => ({invoiceId: invoice.id}))}
      text={'End of ' + moment(invoice?.to).format('MMMM')}
      type={
        ['canceled', 'unPaid'].includes(invoice?.status)
          ? 'warn'
          : invoice?.status === 'paid'
          ? 'green'
          : 'normal'
      }
      amount={invoice?.realAmount}
      className='mb-2'
      icon={
        invoice?.category === 'counseling' ? (
          <IconStarFilled size='20' className='normal' />
        ) : (
          <IconInvoice size='20' className='normal' />
        )
      }
      {...props}
    />
  )
}

InvoiceDetails.propTypes = {}

export default InvoiceDetails
