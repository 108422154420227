import {useQuery, gql} from '@apollo/client'

const useGetAccounts = (vars = {}) => {
  return useQuery(
    gql`
      query {
        getAccounts {
          id
          name
        }
      }
    `,
    {
      variables: vars,
    },
  )
}

export default useGetAccounts
