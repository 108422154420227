import taskQueryResult from './useTaskResult'
import scoreCollectionQueryResult from './useScoresCollectionResult'

const queryMyCounselingPlan = `
... on CouncelingPlan {
  id
  proposalVersion
  proposal
  timelines {
    plans {
        id
        name
        lessonIds
        from
        to
        status
        testDate
        kind
        pricingRate
        typePlanPricing
        serviceId
        parts {
            lessonsPerWeek
            totalLessons
            lessonPerWeekPerTopic {
              topicId
              lessonsPerWeek
              totalLessons
              lockLessons
            }
            from
            to
            name
        }
        deleteAt
    }
    events {
        name
        tbd
        id
        kind
        startDate
    }
    tests {
        name
        tbd
        id
        kind
        startDate
    }
  }


  aditionalInfo {
    studentType
    studentCountryId
    studentCountryName
    studentSing
    parentUserId
    parentFirstName
    parentLastName
    parentCountryId
    parentSign
    parentCountryName
    applicationYear
    avatar
    avatarParent
    avatarCounselor: avatarCouncelor
    avatarAdvisor
    avatarHead
  }
  studentUser {
      studentUserId
      firstName
      lastName
      rate
      isManualRate
      grade
  }
  name
  lessonIds
  from
  to
  paymentItemIds
  recommendedEducators {
      educatorUserId
      firstName
      lastName
      rate
      isManualRate
      topicId
      teachingType 
      isLockEducator
      lessonLink
      isWaiveIndividual
      isWaiveGroup
  }
  selectedEducators{
    educatorUserId
    firstName
    lastName
    rate
    isManualRate
    topicId
    teachingType
    isLockEducator
    lessonLink
    isWaiveIndividual
    isWaiveGroup
  }
  status 
  testDate
  kind 
  groupId
  createdAt
  updatedAt
  isFirstPlan
  sentDate
  pricingRate
  groupPricingRate
  aprovedDate
  cancelDate
  pauseDate
  resumeDate
  paymentItemsConfig {
      amount
      percentDiscount
      amountWithoutDiscount
      date
      description
      type
  }
  location {
      type
      linkStudent
      linkEducator
  }
  typePlanPricing
  onboardingState

  type
  taskTemplate
  councelor{
    teamUserId
    firstName
    lastName
  }
  advisor{
    teamUserId
    firstName
    lastName
  }
  head{
    teamUserId
    firstName
    lastName
  }
  isCustom
  tasks {
    phaseName
    steps {
      id
      idReference
      stepName
      position
      tasks {
        id
  idReference
  name
  position
  instructions
  type
  dueTime
  buttonLabel
  isEspecific
  startDate
  dueDate
  reviewDate
  completedDate
  canceledDate
  linkToShow
  selectedExam {
    id: _id
      name
      results {
          name
          scale
          values {
              name
            scale
        }
    }
  }
  accountsPlatform
  universities
  status
  userId
  resolveTask {
      link {
          lastProcessedAt
          accessedCount
      }
      loginInfo {
          platform{
              name
              username
              password
          }
      }
      ${scoreCollectionQueryResult}
      meeting
      uploadFiles {
          url
          uploadDate
      }
      completion
  }
  isReviewable
  showInDocs
  uploadDocs
  deleteAt
        }
        deleteAt
      }
    }
  tasksMeetings {
     ${taskQueryResult}
  }
  }
`

export default queryMyCounselingPlan
