import React, {useMemo} from 'react'
import {
  IconIllustratedReminder,
  IconTaskComment,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {useSelector} from 'react-redux'

const NoHaveMessages = (props) => {
  const {collection, __typename} = props
  const {roles, id: userID} = useSelector((state) => state.user.info)
  const isEducator = roles.includes('EDUCATOR')
  const isStudent = roles.includes('STUDENT')
  const {translate} = useTranslation()

  const getText = useMemo(() => {
    if (__typename === 'UserTasksOut')
      return translate('virtualChat.noHaveMessages.canLeaveComment')
    if (isEducator && !isStudent) {
      return translate('virtualChat.noHaveMessages.canTalkStudent')
    }
    if (isStudent && !isEducator) {
      return translate('virtualChat.noHaveMessages.canTalkEducator')
    }
    if (isEducator && isStudent) {
      if (collection?.educator.educatorUserId === userID) {
        return translate('virtualChat.noHaveMessages.canTalkStudent')
      }
      if (__typename === 'GroupLesson') {
        if (
          collection?.studentGroup.find(
            ({studentUserId}) => studentUserId === userID,
          )
        ) {
          return translate('virtualChat.noHaveMessages.canTalkEducator')
        }
      }
      if (__typename === 'IndividualLesson' || __typename === 'PlanLesson') {
        if (collection?.student.studentUserId === userID) {
          return translate('virtualChat.noHaveMessages.canTalkEducator')
        }
        if (collection?.studentPlan.studentUserId === userID) {
          return translate('virtualChat.noHaveMessages.canTalkEducator')
        }
      }
    }
    // eslint-disable-next-line
  }, [collection])
  return (
    <div className='text-center pt-4' /**  style={{height: '500px'}} */>
      {__typename === 'UserTasksOut' ? (
        <IconTaskComment size={80} />
      ) : (
        <IconIllustratedReminder size={80} />
      )}
      <div className='m-4'>
        <span className='text-gray h4'>{getText}</span>
      </div>
    </div>
  )
}

export default NoHaveMessages
