import React, {useState} from 'react'
import {
  IconClose,
  IconArrowRight,
  IconSignCheck,
  Avatar,
  ListItem,
  IconSignMinus,
  DropDown,
  IconVideo,
  IconPenalize,
  IconArrowDown,
  IconArrowUp,
  IconSignPlus,
  IconTask,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import {Badge, Collapse} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import SendLessonNotes from '../../SendLessonNotes'
import {hasHttp} from '../../utils'
import WasSubstitutedMsg from '../../../Components/WasSubstitutedMsg'

const AttendedHeaderModal = (props) => {
  const {dataLesson, toggle, lessonStatus} = props
  const {translate} = useTranslation()
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }
  const attended =
    lessonStatus.split('-').length > 1 ? lessonStatus.split('-')[1] : 'missed'

  const makeup = dataLesson?.isMakeUp || false
  const madeup = dataLesson?.makeUpInfo !== null || false

  const topic =
    dataLesson.groupTopic ||
    dataLesson.individualTopic ||
    dataLesson.planTopic ||
    {}
  const service = dataLesson.service

  const penalizationInfo = dataLesson?.penalizationInfo

  const haveHomeWork = dataLesson?.notes?.educatorNotes?.newHw || false

  let newHomeWork = null
  if (haveHomeWork) {
    /**
      hadHw: Boolean
      didHw: Boolean
      score: Float!
      studentEffort: String
      acqKnowledge: String!
      newHw: Boolean!
      descriptionHw: String!
      dueDateHw: DateTime!
      others: String!
     **/
    newHomeWork = (
      <div className='d-flex flex-column my-2 ml-3' style={{width: '200px'}}>
        <div className='h4 text-gray'>
          {translate('modal.infolesson.hwassigned')}
        </div>
        <div className='h4 text-title my-2'>
          {dataLesson.notes.educatorNotes.descriptionHw}
        </div>
        <div className='h4 text-gray'>
          {translate('modal.sendnotes.duedate')}
        </div>
        <div className='h4 text-title my-2'>
          {moment(dataLesson.notes.educatorNotes.dueDateHw).format(
            'YYYY/MM/DD',
          )}
        </div>
      </div>
    )
  }

  const noshow = dataLesson?.educator?.attendanceStatus === 'missed' // falto el educador

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus className='ml-auto text-cancel' />
        <IconClose className='ml-auto text-cancel' onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 pt-3 pb-1'>
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <span className='my-1 h2 font-weight-bold text-title'>
            {dataLesson && `${dataLesson.title}`}
          </span>
          <span className='my-1 h4 text-title'>
            {dataLesson &&
              dataLesson.startDate &&
              dataLesson.endDate &&
              `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(
                dataLesson.endDate,
              ).format('hh:mm a')} | ${separateCamelCase(
                moment(dataLesson.startDate).format('MMM'),
              )} ${moment(dataLesson.startDate).format('DD, YYYY')}`}
          </span>
          <span className='h45 my-1 pr-2 text-gray'>
            {dataLesson &&
              dataLesson.__typename &&
              service &&
              `${separateCamelCase(service?.name)} | ${separateCamelCase(
                topic?.name,
              )} | ${separateCamelCase(
                dataLesson?.location?.type || 'No defined',
              )} | ${separateCamelCase(
                dataLesson.__typename?.replace('Lesson', ''),
              )}`}
          </span>
          <div className='d-flex flex-row m-0 p-0'>
            {attended !== 'group' && (
              <Badge
                className={`mx-0 mt-3 px-3 mr-1 badge badge-pill badge-${
                  attended === 'attended' && !noshow ? 'success' : 'danger'
                } p-0`}
                pill
              >
                {noshow
                  ? translate('modal.infolesson.noshow')
                  : translate(`modal.infolesson.${attended}`)}
              </Badge>
            )}
            {makeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.makeup')}
              </Badge>
            )}
            {madeup && (
              <Badge
                className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                {translate('modal.infolesson.madeup')}
              </Badge>
            )}
            {haveHomeWork && (
              <Badge
                className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                  <DropDown
                    className='d-flex flex-row align-items-center'
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component: newHomeWork,
                      },
                    ]}
                  >
                    <IconTask size={15} />
                  </DropDown>
                </div>
              </Badge>
            )}
            {penalizationInfo && (
              <Badge
                className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                pill
              >
                <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                  <DropDown
                    className='d-flex flex-row align-items-center'
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component: (
                          <div className='d-flex flex-row h45 px-2 py-1'>
                            <div
                              title={translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                              className='text-title mr-3 ellipsis-text'
                              style={{width: '124px'}}
                            >
                              {translate(
                                `modal.penalizelesson.${penalizationInfo?.specificIssueId}`,
                              )}
                            </div>
                            <div className='text-title'>{`-$${parseFloat(
                              penalizationInfo?.amount,
                            ).toFixed(2)}`}</div>
                          </div>
                        ),
                      },
                    ]}
                  >
                    <IconPenalize color='red' size={15} />
                  </DropDown>
                </div>
              </Badge>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const AttendedBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    // createdDate,
    // updateDate,
    RenderStudents,
    // lessonStatus,
    refetchCalendarQuery,
    wasSubstituted,
  } = props

  const {translate} = useTranslation()

  // const attended =
  //  lessonStatus.split('-').length > 1 ? lessonStatus.split('-')[1] : 'missed'

  // const rating = Array.isArray(student)
  //  ? student.reduce((totalRating, stu) => {
  //      return totalRating + (stu.rating === null ? 0 : stu.rating)
  //    }, 0) / (student.length > 0 ? student.length : 1)
  //  : student?.rating === null
  //  ? 0
  //  : student.rating

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }

  const notes = dataLesson?.notes?.educatorNotes
  const notesSentDate = dataLesson?.notes?.sentTime

  const allStudentMissed = Array.isArray(student)
    ? student.reduce((allMissed, stu) => {
        if (stu?.attendanceStatus !== 'missed') return false
        return allMissed
      }, true)
    : student?.attendanceStatus === 'missed'

  const penalizationInfo = dataLesson?.penalizationInfo
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'

  return (
    <div className='d-flex flex-column m-0 p-0 justify-content-between h-100'>
      <div className='m-0 p-0 d-flex flex-column'>
        <div
          className={`d-flex flex-row justify-content-between my-2 ${
            !penalizationInfo ? 'mb-6' : ''
          }`}
        >
          <span className='font-weight-bold'>
            {translate('modal.newlesson.educatorwage')}
          </span>
          <span
            className={`font-weight-bold ${
              educator?.ignorePaymentItem ? 'text-gray' : ''
            }`}
            style={
              educator?.ignorePaymentItem
                ? {textDecoration: 'line-through'}
                : {}
            }
          >{`$${educator && educator.rate.toFixed(2)}`}</span>
        </div>
        {penalizationInfo && (
          <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
            <span className='text-error'>
              {translate('modal.penalizelesson.penalty')}
            </span>
            <span className='text-error'>{`-$${
              penalizationInfo?.amount && penalizationInfo.amount.toFixed(2)
            }`}</span>
          </div>
        )}
        <ListItem
          showTopLine={false}
          showBottomLine
          Left={<RenderStudents student={student} />}
          Right={
            <div className='d-flex flex-row align-items-center px-1'>
              {Array.isArray(student) ? (
                <div
                  className='hover-icon py-1 px-2'
                  onClick={toggleCollapseGroupStudent}
                >
                  {collapseGroupStudent ? <IconArrowUp /> : <IconArrowDown />}
                </div>
              ) : student?.attendanceStatus === 'attended' ? (
                <IconSignCheck color='#8FC813' />
              ) : student?.attendanceStatus === 'missed' ? (
                <IconSignPlus color='red' />
              ) : (
                <IconSignMinus color='#72809D' />
              )}
            </div>
          }
        />
        <Collapse isOpen={collapseGroupStudent}>
          {Array.isArray(student) &&
            student.map((stu) => {
              return (
                <div>
                  <ListItem
                    className='py-2'
                    Left={
                      <div className='d-flex flex-row my-0 py-0 mr-7'>
                        <Avatar
                          name={stu && `${stu.firstName} ${stu.lastName}`}
                          named={false}
                          uri={null}
                          innerDiv
                          size='avatar-small mr-1'
                        />
                        <div
                          className='h45 align-self-center text-dark'
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {stu && `${stu.firstName} ${stu.lastName} `}
                        </div>
                      </div>
                    }
                    Right={
                      <div className='d-flex flex-row align-items-center px-1'>
                        {stu?.attendanceStatus === 'attended' ? (
                          <IconSignCheck color='#8FC813' />
                        ) : stu?.attendanceStatus === 'missed' ? (
                          <IconSignPlus color='red' />
                        ) : (
                          <IconSignMinus color='#72809D' />
                        )}
                      </div>
                    }
                  />
                </div>
              )
            })}
          <div className='borderTop' />
        </Collapse>
        <WasSubstitutedMsg wasSubstituted={wasSubstituted} />
        {dataLesson?.notes === null ? (
          <>
            <div className='d-flex flex-row my-4'>
              <span className='font-weight-bold'>
                {translate('modal.infolesson.waitingeducatornotes')}
              </span>
            </div>
            {!wasSubstituted && (
              <SendLessonNotes
                dataLesson={dataLesson}
                refetchCalendarQuery={refetchCalendarQuery}
              >
                <Button
                  style={{backgroundColor: '#E7AD00'}}
                  className='w-100 my-3'
                  size='large'
                  label={
                    <div className='d-flex flex-row justify-content-between'>
                      <span>
                        {translate('modal.infolesson.completelessonnotes')}
                      </span>
                      <IconArrowRight />
                    </div>
                  }
                />
              </SendLessonNotes>
            )}
          </>
        ) : (
          !allStudentMissed && (
            <div className='d-flex flex-column my-6'>
              <span className='text-title h4'>
                {translate('modal.sendnotes.title')}
              </span>
              <span className='text-title h45'>{`${translate(
                'modal.sendnotes.sentOn',
              )} ${moment(notesSentDate).format('DD MMMM, YYYY')}`}</span>
              {notes?.hadHw && (
                <div className='d-flex flex-row my-3 h45'>
                  <div
                    className='d-flex flex-column mr-2'
                    style={{width: '60%'}}
                    flex={1}
                  >
                    <span className='text-gray' style={{whiteSpace: 'nowrap'}}>
                      {translate('modal.sendnotes.hwCompleted')}
                    </span>
                    <span>
                      {notes?.didHw ? translate('modal.sendnotes.yes') : 'No'}
                    </span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='text-gray'>
                      {translate('modal.sendnotes.score')}
                    </span>
                    <span>{notes?.scoreAlphanumeric !== null ? notes?.scoreAlphanumeric : notes?.score || '-'}</span>
                  </div>
                </div>
              )}
              {notes?.newHw && (
                <div className='d-flex flex-row my-3 h45'>
                  <div
                    className='d-flex flex-column mr-2'
                    style={{width: '60%'}}
                    flex={1}
                  >
                    <span className='text-gray' style={{whiteSpace: 'nowrap'}}>
                      {translate('modal.sendnotes.hwAssigned')}
                    </span>
                    <span>{notes?.descriptionHw}</span>
                  </div>
                  <div className='d-flex flex-column'>
                    <span className='text-gray'>
                      {translate('modal.sendnotes.duedate')}
                    </span>
                    <span>{moment(notes?.dueDateHw).format('DD-MM-YYYY')}</span>
                  </div>
                </div>
              )}
              <div className='d-flex flex-column my-3 h45'>
                <span className='text-gray'>
                  {translate('modal.sendnotes.acqknowledge')}
                </span>
                <span>{notes?.acqKnowledge}</span>
              </div>
              {!isGroupLesson && (
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>
                    {translate('modal.sendnotes.studenteffort')}
                  </span>
                  <span>{notes?.studentEffort}</span>
                </div>
              )}
              {notes?.others && (
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>
                    {translate('modal.sendnotes.othercomments')}
                  </span>
                  <span>{notes?.others}</span>
                </div>
              )}
            </div>
          )
        )}
        {dataLesson.recordingUrl && (
          <ListItem
            showTopLine
            className='py-4'
            onClick={() => {
              window.open(hasHttp(dataLesson.recordingUrl), '_blank')
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconVideo color='#72809D' className='align-self-center mr-2' />
                <span className='align-self-center text-title'>
                  {translate('modal.infolesson.viewlessonrecording')}
                </span>
              </div>
            }
            Right={<IconArrowRight />}
          />
        )}
      </div>
      <div className='mb-6' />
      {/** <div
        className='pb-4 mt-5'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.cancelLesson',
            data: {
              isOpen: true,
              lessonId: dataLesson?.id || '',
            },
          })
        }}
      >
        <span className='h5 text-gray'>
          {translate('modal.infolesson.youneedremove')}
        </span>
      </div>**/}
    </div>
  )
}

export {AttendedHeaderModal, AttendedBodyModal}
