import {nanoid} from 'nanoid'

export const PhasesKeys = {
  'Pre-Application': 'preApplication',
  Application: 'application',
  'Post-Application': 'postApplication',
}
export const PhasesKeys2 = {
  'pre-application': 'preApplication',
  application: 'application',
  'post-application': 'postApplication',
}

export const PhasesTitles = {
  preApplication: 'Pre-Application',
  application: 'Application',
  postApplication: 'Post-Application',
}

export const OtherPhasesTitles = {
  'pre-application': 'Pre-Application',
  application: 'Application',
  'post-application': 'Post-Application',
}

export const initialState = {
  isEdit: false,
  templateId: nanoid(),
  templateName: 'Template name',
  phases: [
    {
      phaseId: 'pre',
      phaseName: PhasesTitles.preApplication,
      title: 'Pre-Application',
      steps: [],
    },
    {
      phaseId: 'app',
      phaseName: PhasesTitles.application,
      title: 'Application',
      steps: [],
    },
    {
      phaseId: 'post',
      phaseName: PhasesTitles.postApplication,
      title: 'Post-Application',
      steps: [],
    },
    // {
    //    phaseId: "!",
    //    phaseName: "",
    //    steps: [
    //        {
    //            stepId: "!",
    //            stepName: "",
    //            tasks: [
    //                {
    //                    taskId: "!",
    //                    taskName: "",
    //                    type: "exam"
    //                }
    //            ]
    //        }
    //    ]
    // }
  ],
  isInitialState: true,
}
export default {initialState, PhasesTitles, OtherPhasesTitles}
