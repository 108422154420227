import React, {useMemo} from 'react'
import {TemplateTimeline} from '@Knowledge-OTP/znk-ui-components'
import useTemplateReducer from '../../../../../reducers/template'
import useShowTask from '../../../../Workspace/Student/ViewTask/useShowTask'
import useShowScheduleMeeting from '../../../../Workspace/Student/ShowScheduledMeeting/useShowScheduledMeeting'
import taskTypes from '../../../../Workspace/Utils/taskTypes'

const Timeline = (props) => {
  const {plan} = props

  const {filterDelete} = useTemplateReducer()
  const phases = useMemo(() => {
    return plan?.tasks ? filterDelete({phases: plan?.tasks}).phases : []
  }, [plan, filterDelete])

  const {open: openTask} = useShowTask()
  const {open: opentScheduledMeeting} = useShowScheduleMeeting()

  const showTask = (task) => {
    task?.type === taskTypes.scheduledMeetting
      ? opentScheduledMeeting(() => {}, task)
      : openTask(() => {}, task)
  }
  return (
    <div className='my-5'>
      <TemplateTimeline
        className='w-100 p-0'
        isEdit={false}
        stages={phases}
        disabledDragAndDrop
        onClickTask={(task, {phaseId, stepId}) => {
          showTask(task)
        }}
      />
    </div>
  )
}

export default Timeline
