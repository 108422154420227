import React, {useState} from 'react'
import moment from 'moment-timezone'
import {useMutation, gql} from '@apollo/client'
import MobileView from './Mobile'
import DesktopView from './Desktop'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'

const ChangeTimezone = (props) => {
  const {timezone, id, from} = props

  const zones = moment.tz.names()
  const refetch = useSelector((state) => state.user.refetch)
  const [form, setForm] = useState({
    timezone: timezone || '',
    ...props,
  })
  const {breakWidth} = useWindowDimensions()
  const [updInfo, {loading}] = useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          id
          paymentMethod {
            ... on CardPaymentMethod {
              last4
              brand
              expYear
              expMonth
            }
          }
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            aditionalInfo
            parents {
              firstName
              lastName
              managePayments
              manageApprovals
              email
              parentUserId
              phone
            }
          }
          roles
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetch()
        // cache.writeQuery({
        //  query: meQuery,
        //  data: {me: data.updateUser},
        // })
      },
    },
  )

  return (
    <>
      {breakWidth === 'SM' && false ? (
        <MobileView
          {...{
            loading,
            updInfo,
            form,
            setForm,
            timezone,
            zones,
            id,
            from,
          }}
        />
      ) : (
        <DesktopView
          {...{
            loading,
            form,
            setForm,
            timezone,
            zones,
            updInfo,
            id,
            from,
            noText: breakWidth === 'SM',
            noArrow: breakWidth === 'SM',
          }}
        />
      )}
    </>
  )
}

export default ChangeTimezone
