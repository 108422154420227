import {db} from '../../Pages/VirtualChat/fireBase'

export const setStatusOnFirebase = async (
  userId,
  key = 'liveprepLessonsMsg',
  snapshotState,
) => {
  // marca la alerta como vista en firebase
  if (snapshotState?.exists) {
    const data = snapshotState?.data()
    await db
      .collection('alerts')
      .doc(`${userId}`)
      .set({...data, [key]: true})
  } else {
    await db
      .collection('alerts')
      .doc(`${userId}`)
      .set({[key]: true})
  }
}

export const getStatus = (
  userId,
  key = 'liveprepLessonsMsg',
  setSnapshotState = () => {},
  setShowMsg = () => {},
) => {
  db.collection('alerts')
    .doc(userId)
    .onSnapshot(
      (snapshot) => {
        setSnapshotState(snapshot)
        if (snapshot.exists) {
          // el usuario tiene registro
          // true: ya vio el mensaje, false: no lo ha visto o no lo ha cerrado
          const data = snapshot.data()
          setShowMsg(!data[key])
        } else {
          // el usuario no tiene registro
          setShowMsg(true)
        }
      },
      (error) => {
        console.error(error)
      },
    )
}
export default {setStatusOnFirebase, getStatus}
