// import scoreCollectionQueryResult from './useScoresCollectionResult'
import useUserTaskQueryResult from './useUserTaskQueryResult'
// String con modelo de objeto que será utilizado para
// obtener una Task de un estudiante.
const taskQueryResult = `
    ${useUserTaskQueryResult}
    phase
    stepId
    meetingStartDate
    serviceDate
    planId
`

export default taskQueryResult
