import React, {useContext} from 'react'
import {
  Button,
  useWindowDimensions,
  IconHomework,
  IconCancelation,
  IconAttendance,
  IconSupport,
  IconBrand,
  IconLogo,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {getReduxInitialState} from '../utils'
import {useHistory} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import UserContext from '../../../contexts/UserContext'
import useSocketEntities from '../../../common/SocketGateway/useSocketEntities'

const End = () => {
  const {refetch} = useContext(UserContext)
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {socket} = useSocketEntities()
  const {dataPlan} = useSelector((state) => state?.modal?.onboarding?.data)
  return (
    <>
      <div
        className='d-flex'
        style={{
          position: 'absolute',
          left: '15px',
          top: '10px',
          cursor: 'pointer',
        }}
        onClick={() => {
          history.push('/home')
        }}
      >
        <IconBrand size={50} color='#35425B' />
        {breakWidth !== 'SM' && (
          <IconLogo className='ml-4' size={50} color='#35425B' />
        )}
      </div>
      <div className='m-0 p-0 d-flex flex-column w-100 h-100 align-items-center justify-content-center text-title'>
        <div
          className='m-0 p-0 d-flex flex-column'
          style={breakWidth !== 'SM' ? {width: '32%'} : {width: '90%'}}
        >
          <div className='d-flex mt-5'>
            <h2 className='htext mt-7'>
              {`Hey ${dataPlan?.studentName},`}
              <br />
              {translate('home.onboard.approve.success.title')}
            </h2>
          </div>
          <span>{translate('home.onboard.approve.success.subtitle')}</span>
          <div className='mt-3'>
            <div className='d-flex my-2'>
              <IconAttendance
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>{translate('home.onboard.approve.success.effortTitle')}</b>
                </span>
                <br />
                <span>
                  {translate('home.onboard.approve.success.effortText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconCancelation
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>
                    {translate('home.onboard.approve.success.cancelationTitle')}
                  </b>
                </span>
                <br />
                <span>
                  {translate('home.onboard.approve.success.cancelationText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconHomework
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>
                    {translate('home.onboard.approve.success.homeworkTitle')}
                  </b>
                </span>
                <br />
                <span>
                  {translate('home.onboard.approve.success.homeworkText')}
                </span>
              </div>
            </div>
            <div className='d-flex my-2'>
              <IconSupport
                style={{maxWidth: '30%'}}
                size={breakWidth === 'SM' ? 48 : 48}
                color='#696CFF'
              />
              <div className='ml-3 mb-3' style={{maxWidth: '80%'}}>
                <span>
                  <b>
                    {translate('home.onboard.approve.success.supportTitle')}
                  </b>
                </span>
                <br />
                <span>
                  {translate('home.onboard.approve.success.supportText')}
                </span>
              </div>
            </div>
          </div>
          <Button
            className='mb-6 mt-4'
            label={translate('home.onboard.approve.success.goto')}
            check
            style={{backgroundColor: '#696CFF', border: 'none', width: '70%'}}
            onClick={() => {
              refetch()
              socket.emit('leave', dataPlan?.idPlan || dataPlan?.id)
              history.push(`/calendar/lessons`)
              dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'onboarding',
                data: getReduxInitialState(),
              })
            }}
          />
        </div>
      </div>
    </>
  )
}

export default End
