import React from 'react'
import {Notification} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import {useDispatch, useSelector} from 'react-redux'
import {useQuery, gql} from '@apollo/client'

const PendingLessonNotification = (props) => {
  const {
    refetch, // se ejecuta luego de agendar lección pendiente
    className,
  } = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const {data: pendingLessons, refetch: refetchPendingLessons} = useQuery(
    gql`
      query($status: [LessonStatus!]!, $serviceIds: [ID!]) {
        myLessons(status: $status, serviceIds: $serviceIds, limit: 0) {
          docs {
            id
            status
            service {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        status: ['pending'],
      },
    },
  )

  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find(
    ({isOrientation}) => isOrientation,
  )

  return pendingLessons?.myLessons?.docs?.filter((lesson) => {
    return lesson?.service?.id !== orientation?.id
  }).length > 0 ? (
    <Notification
      label={translate('lessons.youhavepending')}
      number={1}
      className={className}
      onClick={() => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'program.rescheduledModal',
          data: {
            isOpen: true,
            stage: 'showPendingLesson',
            refetchLessons: (lessonId) => {
              refetchPendingLessons()
              if (refetch) refetch(lessonId)
            },
          },
        })
      }}
    />
  ) : null
}

export default PendingLessonNotification
