import React from 'react'
import OpenInvoiceModalFromUrl from '../../common/OpenInvoiceModalFromUrl'

const OfflineModule = (props) => {
  return (
    <>
      <OpenInvoiceModalFromUrl />
    </>
  )
}

export default OfflineModule
