export default {
  totalLessons: (num) => {
    return `${num} lessons`
  },
  lessonRate: (num) => {
    return `$${num} per lesson`
  },
  registrationFee: 'Registration fee',
  toeflUpgrade: 'TOEFL Pro Account Upgrade',
  approvalTitle: 'We are excited to begin working with you!',
  breakdown: 'Plan breakdown',
  paymentSchedule: 'Payment schedule',
}
