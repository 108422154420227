import React, {useState, useMemo, useEffect, useCallback} from 'react'
import CalendarSection from './Sections/Calendar'
import {
  Avatar,
  ListItem,
  InputSelect,
  Calendar,
  Notification,
  LayoutTwoSections,
  IconArrowLeft,
  IconArrowRight,
  IconProgram,
  IconUser,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {useLazyQuery, gql} from '@apollo/client'

import InfoLesson from './modal/InfoLesson'
import SendEmailReminder from './modal/SendEmailReminder'
import PostponeLesson from './modal/PostponeLesson'
import IncompleteLessons from './modal/IncompleteLessons'
import ChangeTimezoneModal from './Components/ChangeTimezoneModal'
import OrientationModal from './modal/OrientationModal'
// import OrientationModal from '../Calendar/Lessons/Modal/OrientationModal'
// import OrientationLessonModal from '../Workspace/Student/ViewTask/Components/Modals/OrientationLessonModal'
import ResumeOrientation from './modal/ResumeOrientation'
import ConfirmSetBraincertToLesson from './modal/ConfirmSetBraincertToLesson'

import moment from 'moment-timezone'
import {useSelector, useDispatch} from 'react-redux'
import useTranslation from '../../i18n/useTranslation'
// import StudentProfile from '../Student/modal/InfoStudent/index'
import escapeRegExp from 'lodash/escapeRegExp'

import UseCalendarView from './Model/useCalendarView'
import UseCalendarViewEducatorQuery from './Model/useCalendarViewEducatorQuery'
import UsePendingAction from './Model/usePendingActionLessonsForEducator'

import RedirectToLessonModal from './Components/RedirectToLessonModal'

const CalendarView = () => {
  const info = useSelector((state) => state.user.info)

  const {breakWidth} = useWindowDimensions()

  const [week, setWeek] = useState(0)
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const zone = useSelector((state) => state.user.info.timezone)
  const datesTuple = useMemo(() => {
    if (zone && week) {
      return [
        moment().tz(zone).startOf('isoweek').add(week, 'week').toISOString(),
        moment()
          .tz(zone)
          .endOf('isoweek')
          .add(week, 'week')
          .add(1, 'millisecond')
          .toISOString(),
        // start of month
        moment()
          .tz(zone)
          .add(week, 'week')
          .startOf('month')
          .startOf('isoWeek')
          .toISOString(),
        // end of month
        moment()
          .tz(zone)
          .add(week, 'week')
          .endOf('month')
          .endOf('isoWeek')
          .add(1, 'milisecond')
          .toISOString(),
      ]
    }
    return [
      moment().startOf('isoweek').toISOString(),
      moment().endOf('isoweek').add(1, 'millisecond').toISOString(),
      // start of month
      moment().tz(zone).startOf('month').startOf('isoWeek').toISOString(),
      // end of month
      moment()
        .tz(zone)
        .endOf('month')
        .endOf('isoWeek')
        .add(1, 'milisecond')
        .toISOString(),
    ]
  }, [week, zone])
  const [
    from /** start of week**/,
    to /** end of week**/,
    startMonth,
    endMonth,
  ] = datesTuple

  const {upcomming, selectedUserData} = useSelector(
    (state) => state.modal.calendar.selectUser,
  )

  const [selectUser, setSelectUser] = useState('')

  const [getMe, {data: dataMe, loading: loadUser}] = useLazyQuery(
    gql`
      query {
        getTeachEducator {
          id
          firstName
          lastName
        }
      }
    `,
    {fetchPolicy: 'no-cache'},
  )
  const dataUsers = dataMe?.getTeachEducator || []

  // educator unavailable
  const [
    getEducatorUnavailable,
    {
      data: dataEducatorUnavailable,
      refetch: refetchCalendarQuery,
      loading: loadCalendar,
    },
  ] = UseCalendarView()
  useEffect(() => {
    if (info?.id && startMonth && endMonth) {
      getEducatorUnavailable({
        variables: {
          kinds: ['educator-unavailability'],
          userId: info?.id || '',
          from: startMonth,
          to: endMonth,
        },
      })
    }
  }, [
    info,
    getEducatorUnavailable,
    // from, to,
    startMonth,
    endMonth,
  ])

  // todas las lecciones
  const {
    data: dataEducatorLesson,
    loading: loadLesson,
    refetch,
  } = UseCalendarViewEducatorQuery({
    kinds: ['plan-lesson', 'individual-lesson', 'group-lesson', 'counselor'],
    studentIds: selectUser ? [selectUser] : [],
    from: startMonth,
    to: endMonth,
  })

  const infoModalData = useSelector(
    (state) => state.modal.calendar.infoLessonModal,
  )
  // const {isOpen, dataLesson} = reduxData
  const updateDataLessonModal = useCallback(
    (newDataLesson) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.infoLessonModal',
        data: {
          ...infoModalData,
          dataLesson: {
            ...(infoModalData?.dataLesson || {}),
            ...newDataLesson,
          },
        },
      })
      // eslint-disable-next-line
    }, [infoModalData]
  )

  useEffect(() => {
    if (infoModalData?.isOpen && infoModalData?.dataLesson) {
      // si el modal de lección esta abierto debe actualizar la info
      const allLessons = dataEducatorLesson?.calendarViewEducator
      const currentDataLesson = allLessons.find(
        ({id}) => id === infoModalData?.dataLesson.id,
      )
      updateDataLessonModal(currentDataLesson)
    }
    // eslint-disable-next-line
    }, [dataEducatorLesson]
  )

  // lecciones incompletas
  const [
    getIncompleteLessons,
    {data: incompleteLessonsFromQuery},
  ] = UsePendingAction()
  useEffect(() => {
    getMe()
    getIncompleteLessons()
  }, [getIncompleteLessons, getMe])
  const incompleteLessons = useMemo(() => {
    return incompleteLessonsFromQuery?.pendingActionLessonsForEducator || []
  }, [incompleteLessonsFromQuery])

  const [now, currentNow] = useMemo(() => {
    return [moment(), moment().add(week, 'weeks')]
  }, [week])

  useEffect(() => {
    const student = dataUsers?.find((stu) => stu.id === selectUser)
    if (student) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'calendar.selectUser',
        data: {
          upcomming: student?.studentInfo?.upcomingLesson?.id,
          selectedUserData: student,
        },
      })
    }
    // eslint-disable-next-line
  }, [selectUser, dataUsers])

  const ordenateUserArray = (array) => {
    return array
      .map((stu) => {
        return {
          name: `${stu.firstName} ${stu.lastName}`,
          id: stu.id,
          avatar: stu.avatar || null,
        }
      })
      .sort((a, b) => {
        if (a.name > b.name) return 1
        if (a.name < b.name) return -1
        return 0
      })
  }

  // const incompleteLessons = scheduledLessons ? scheduledLessons.filter((lesson) => moment(lesson.endDate) < moment()) : []

  const [openSideLeft, setOpenSideLeft] = useState(false)
  const toggleSideLeft = () => {
    setOpenSideLeft(!openSideLeft)
  }

  // const listLessons =
  const {listLessons, allLessons} = useMemo(() => {
    let lessons = []
    if (dataEducatorUnavailable?.calendarView?.length > 0)
      lessons = lessons.concat(dataEducatorUnavailable?.calendarView)
    if (dataEducatorLesson?.calendarViewEducator?.length > 0)
      lessons = lessons.concat(dataEducatorLesson.calendarViewEducator)

    const allLessons = lessons.filter(
      (lesson) =>
        lesson.status !== 'canceled' &&
        lesson.status !== 'pending' &&
        lesson.status !== 'draftPending' &&
        lesson.status !== 'occurrence' &&
        lesson.status !== 'paused' &&
        lesson.status !== 'draft',
    )
    const listLessons = allLessons.filter(
      ({startDate}) =>
        moment(startDate) >= moment(from) && moment(to) > moment(startDate),
    )
    return {
      listLessons,
      allLessons,
    }
  }, [dataEducatorUnavailable, dataEducatorLesson, from, to])

  return (
    <>
      <LayoutTwoSections
        isOpen={openSideLeft}
        toggle={toggleSideLeft}
        disabledClasses={breakWidth === 'SM'}
        IconHide={IconArrowLeft}
        IconShow={IconArrowRight}
        LeftSideMax={
          <>
            <span className='h3 font-weight-bold'>
              {translate('lessons.calendar.title')}
            </span>
            <Calendar
              theme='red'
              className='mt-5'
              data={
                allLessons?.filter(
                  (les) => les?.__typename !== 'EducatorUnavailability',
                ) || []
              }
              today={now}
              onToday={() => {
                setWeek(0)
              }}
              moment={currentNow}
              setMoment={(selectWeek) => {
                const addWeeks = moment(selectWeek)
                if (addWeeks.isoWeekday() > 1) {
                  // returns 1-7 where 1 is Monday and 7 is Sunday
                  addWeeks.add(1, 'week')
                }
                setWeek(
                  week +
                    moment
                      .duration(
                        addWeeks
                          .startOf('isoWeek')
                          .set({
                            hour: 0,
                            minutes: 0,
                            seconds: 0,
                            milliseconds: 0,
                          })
                          .diff(from),
                      )
                      .asWeeks(),
                )
              }}
              viewMode='month'
              focusWeek={null}
              isOpen
              ShowBPLeft
              ShowBPRight
            />
            <div className='m-0 p-0 d-flex flex-column w-100'>
              <div className='m-0 p-0 d-flex flex-row justify-content-between'>
                {/* <span className='h45 text-gray'>Student (i18n)</span> */}
                {selectUser &&
                  dataUsers?.filter((stu) => stu.id === selectUser).length && (
                    <span
                      className='h45 btn-link'
                      onClick={() => {
                        const student = dataUsers?.filter(
                          (stu) => stu.id === selectUser,
                        )
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'student.infoStudentModal',
                          data: {
                            isOpen: true,
                            dataStudent: student[0],
                            initPage: 'summary',
                          },
                        })
                      }}
                    >
                      {translate('lessons.calendar.seeProfile')}
                    </span>
                  )}
              </div>
              <InputSelect
                isLoading={loadUser}
                InputClassName='my-3'
                value={selectUser}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.data.name))
                    return true
                  return false
                }}
                options={
                  dataUsers
                    ? ordenateUserArray(dataUsers).map((stu) => {
                        return {
                          value: stu.id,
                          name: stu.name,
                          label: (
                            <ListItem
                              id='x-0'
                              className='py-0'
                              Left={
                                <div className='d-flex flex-row'>
                                  <Avatar
                                    name={stu.name}
                                    named={false}
                                    uri={null}
                                    size='avatar-small mr-1'
                                  />
                                  <span className='align-self-center'>
                                    {stu.name}
                                  </span>
                                </div>
                              }
                            />
                          ),
                        }
                      })
                    : []
                }
                onChange={(e) => {
                  setSelectUser(e)
                }}
              />
            </div>
            {incompleteLessons?.length > 0 && (
              <Notification
                label={`${incompleteLessons?.length} incomplete lessons`}
                number={1}
                className='mb-3'
                onClick={() => {
                  // lecciones incompletas
                  dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'calendar.incompleteLessonModal',
                    data: {
                      isOpen: true,
                    },
                  })
                }}
              />
            )}
          </>
        }
        LeftSideMaxClass='d-flex flex-column m-5 p-0'
        LeftSideMin={
          <>
            <div className='znk-option-item mb-3'>
              <IconProgram size={15} />
            </div>
            <div className='znk-option-item'>
              {selectedUserData && selectUser ? (
                <Avatar
                  name={`${selectedUserData?.firstName} ${selectedUserData?.lastName}`}
                  named={false}
                  uri={null}
                  size='avatar-small m-0'
                />
              ) : (
                <IconUser size={15} />
              )}
            </div>
          </>
        }
        LeftSideMinClass='m-0 p-0 d-flex flex-column justify-content-start align-items-center'
        RightSide={
          <CalendarSection
            openSideLeft={openSideLeft}
            week={week}
            setWeek={setWeek}
            from={from}
            refetchCalendarQuery={() => {
              refetchCalendarQuery()
              refetch()
            }}
            upcomming={upcomming}
            loading={loadCalendar || loadLesson}
            lessons={listLessons}
          />
        }
        RightSideClass='m-0 p-0 h-100'
        RightSideStyle={{backgroundColor: 'white'}}
      />
      <InfoLesson
        refetchCalendarQuery={() => {
          // actualiza info de la lección en modal ***
          refetchCalendarQuery()
          refetch()
        }}
      />
      <SendEmailReminder />
      <PostponeLesson />
      {
        // <StudentProfile />
      }
      <IncompleteLessons lessons={incompleteLessons} />
      <ChangeTimezoneModal />
      <OrientationModal refetchCalendarQuery={refetchCalendarQuery} />
      {/** <OrientationLessonModal
        role='student'
        dataLesson={
          listLessons?.filter((lesson) => lesson.id === reduxData?.idLesson)[0]
        }
        onCancel={() => {}}
        isOpen={reduxData?.isOpen}
        toggle={toggle}
      />**/}
      <ResumeOrientation />
      <ConfirmSetBraincertToLesson />
      <RedirectToLessonModal
        callBackFunction={(lessonId, dataLesson, isOrientation) => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: `calendar.${
              isOrientation ? 'orientationLessonModal' : 'infoLessonModal'
            }`,
            data: {
              isOpen: true,
              dataLesson,
              isOrientation: false,
            },
          })
        }}
      />
    </>
  )
}

export default CalendarView
