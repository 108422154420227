import React, {useMemo, useState, useEffect, Fragment} from 'react'
import {Collapse, Badge} from 'reactstrap'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import {
  Activity,
  Avatar,
  Button,
  DropDown,
  ListItem,
  useWindowDimensions,
  ModalScroll,
  IconClose,
  IconSignMinus,
  IconArrowRight,
  IconProgram,
  // IconIllustratedMessages,
  IconIllustratedLesson,
  IconSignPlus,
  IconSignCheck,
  IconOptions,
  Countdown,
  IconRepeat,
} from '@Knowledge-OTP/znk-ui-components'
// import SendLessonNotesOrientation from '../../SendLessonNotes/notesOrientation'
// import useTranslation from '../../../../../i18n/useTranslation'
import useTranslation from '../../../../../../../i18n/useTranslation'
import RescheduledOrientation from './RescheduledLesson'
import {useDispatch} from 'react-redux'
import ConfirmSetBraincertToLesson from '../../../../../../CalendarLessons/modal/ConfirmSetBraincertToLesson'
import AllowPermissions from './AllowPermissions'

// roles
const STUDENT = 'student'
const ADMIN = 'admin'
const EDUCATOR = 'educator'

// status
const PENDING = 'pending'
const DRAFT = 'draft'
const INCOMPLETE = 'incomplete'
const SCHEDULED = 'scheduled'
const FINISHED = 'finished'
const FINISHEDGROUP = 'finished-group'
const FINISHEDATTENDED = 'finished-attended'
const FINISHEDMISSED = 'finished-missed'
// const UPCOMING = 'upcoming'
const INPROGRESS = 'inprogress'

// attendance
const ATTENDED = 'attended'
const MISSED = 'missed'

const propTypes = {
  role: PropTypes.oneOf([STUDENT, EDUCATOR, ADMIN]),
  dataLesson: PropTypes.object,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onRescheduled: PropTypes.func,
  rescheduledText: PropTypes.string,
  onCheckIn: PropTypes.func,
  onCheckInLoad: PropTypes.bool,
  checkinText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  CancelComponent: PropTypes.node,
  onGoToClass: PropTypes.func,
  onGoToClassLoad: PropTypes.bool,
  gotoclassText: PropTypes.string,
  onBackUp: PropTypes.func,
  onBackUpLoad: PropTypes.bool,
  backupText: PropTypes.string,
  showCreatedDate: PropTypes.func,
  showUpdatedDate: PropTypes.func,
  studentText: PropTypes.string,
  educatorText: PropTypes.string,
  onSaveAttendance: PropTypes.func,
  saveAttendanceLoad: PropTypes.bool,
  onSendResume: PropTypes.func,
  sendResumeText: PropTypes.string,
  studentOptions: PropTypes.func,
  educatorOptions: PropTypes.func,
}
const defaultProps = {
  role: STUDENT,
  dataLesson: {},
  isOpen: false,
  toggle: () => {},
  onRescheduled: () => {},
  rescheduledText: 'Reschedule',

  onCheckIn: () => {},
  onCheckInLoad: false,
  checkinText: 'Check In',

  onCancel: () => {},
  cancelText: 'Cancel lesson',
  CancelComponent: null,

  onGoToClass: () => {},
  onGoToClassLoad: false,
  gotoclassText: 'Go to class room',

  onBackUp: () => {},
  onBackUpLoad: false,
  backupText: 'Backup call',

  showCreatedDate: (createdDate) => {
    return (
      <>
        <b>Created</b> at{' '}
        {`
                    ${
                      createdDate &&
                      moment(createdDate).format('MMM DD, YYYY hh:mma')
                    }
                `}
      </>
    )
  },
  showUpdatedDate: (updateDate) => {
    return (
      <>
        <b>Update</b> at{' '}
        {`
                    ${
                      updateDate &&
                      moment(updateDate).format('MMM DD, YYYY hh:mma')
                    }
                `}
      </>
    )
  },
  studentText: 'Student',
  educatorText: 'Educator',
  onSaveAttendance: () => {},
  saveAttendanceLoad: false,
  onSendResume: () => {},
  sendResumeText: 'Send resume',

  studentOptions: () => {},
  educatorOptions: () => {},
}
const OrientationLessonModal = (props) => {
  const {
    role,
    dataLesson,
    isOpen,
    toggle,

    // onRescheduled,
    rescheduledText,

    onCheckIn,
    onCheckInLoad,
    checkinText,

    // onCancel,
    // cancelText,
    // CancelComponent,

    onGoToClass,
    onGoToClassLoad,
    gotoclassText,

    onBackUp,
    onBackUpLoad,
    backupText,

    showCreatedDate,
    showUpdatedDate,

    studentText,
    // educatorText,

    onSaveAttendance,
    saveAttendanceLoad,

    onSendResume,
    sendResumeText,

    studentOptions,
    educatorOptions,

    refetchLesson,
  } = props
  const notes = dataLesson?.notes?.educatorNotes
  const notesSentDate = dataLesson?.notes?.sentTime
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }
  const service = dataLesson?.service
  // const topic = dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic || {}

  const [
    student,
    educator,
    createdDate,
    updateDate,
    statusOfInfoLesson,
  ] = useMemo(() => {
    /**
         *->Incomplete (SCHEDULED y posterior al enddate): solo cuando la lección ya terminó => incomplete
        ->Current/inprogress (SCHEDULED y entre startdate y enddate): => inprogress
        ---> para el estudiante es la siguiente
        ---> Cuando quedan menos de 10 minuts para el start time
        ---> Está en progreso
        ->scheduled (SCHEDULED)(azul oscuro o gris):  Aun no comienza / es futura => sheduled
        ->Attended/missed (FINISHED): Cuando status es igual a finished (para que pase a finished tiene que haber completado la asistencia) => finished
        * **/
    let studentAux =
      dataLesson?.student ||
      dataLesson?.studentPlan ||
      dataLesson?.studentGroup ||
      {}
    if (Array.isArray(studentAux))
      studentAux = studentAux.length > 0 ? studentAux[0] : {} // si la clase es grupal utiliza el primer estudiante

    let statusOfInfoLesson = SCHEDULED

    if (dataLesson?.status === FINISHED) {
      if (dataLesson?.notes?.status === DRAFT) {
        statusOfInfoLesson = INCOMPLETE
      } else if (Array.isArray(studentAux)) {
        statusOfInfoLesson = FINISHEDGROUP
      } else {
        statusOfInfoLesson = `FINISHED-${
          String(studentAux?.attendanceStatus).toUpperCase() || MISSED
        }`
      }
    } else if (dataLesson?.status === PENDING) {
      statusOfInfoLesson = PENDING
    } else {
      if (
        moment(dataLesson?.startDate) < moment() &&
        moment() < moment(dataLesson?.endDate)
      ) {
        // lección en curso
        statusOfInfoLesson = INPROGRESS
      } else if (moment() > moment(dataLesson?.endDate)) {
        // lección ya pasó y no está finished
        statusOfInfoLesson = INCOMPLETE
      } else if (moment() < moment(dataLesson?.startDate)) {
        // lección aún no sucede
        statusOfInfoLesson = SCHEDULED
      }
    }

    // ***********************
    // Commented for implements changes from model lesson in adin platform

    // let statusOfInfoLesson = SCHEDULED
    // if (dataLesson?.status === FINISHED) {
    //   if (Array.isArray(studentAux)) {
    //     statusOfInfoLesson = FINISHEDGROUP
    //   } else {
    //     statusOfInfoLesson =
    //       studentAux?.attendanceStatus === 'attended'
    //         ? FINISHEDATTENDED
    //         : FINISHEDMISSED
    //   }
    // } else if (dataLesson?.status === PENDING) {
    //   statusOfInfoLesson = PENDING
    // } else {
    //   if (
    //     moment(dataLesson?.startDate) < moment() &&
    //     moment() < moment(dataLesson?.endDate)
    //   ) {
    //     // lección en curso
    //     statusOfInfoLesson = INPROGRESS
    //   } else if (moment() > moment(dataLesson?.endDate)) {
    //     // lección ya pasó y no está finished
    //     statusOfInfoLesson = INCOMPLETE
    //   } else if (moment() < moment(dataLesson?.startDate)) {
    //     // lección aún no sucede
    //     statusOfInfoLesson = SCHEDULED
    //   }
    // }
    // ***********************

    return [
      studentAux,
      dataLesson?.educator || dataLesson?.substitute || {},
      dataLesson &&
        (dataLesson?.IndividualCreated ||
          dataLesson?.PlanCreated ||
          dataLesson?.GroupCreated ||
          dataLesson?.CounselorCreated),
      dataLesson &&
        (dataLesson?.IndividualUpdate ||
          dataLesson?.PlanUpdate ||
          dataLesson?.GroupUpdate ||
          dataLesson?.CounselorUpdate),
      statusOfInfoLesson,
      dataLesson,
    ]
  }, [dataLesson])

  const [statusOfInfoLessonState, setStatusOfInfoLessonState] = useState(
    statusOfInfoLesson,
  )

  useEffect(() => {
    setStatusOfInfoLessonState(statusOfInfoLesson)
  }, [statusOfInfoLesson])
  const isCheckIn =
    role === EDUCATOR
      ? educator?.attendanceStatus === ATTENDED
      : role === STUDENT
      ? student?.attendanceStatus === ATTENDED
      : false // estudiante / educador ya hizo checkIn

  const [timeToStart, setTimeToStart] = useState(
    moment.duration(moment(dataLesson?.startDate).diff(moment())).asMinutes(),
  )
  useEffect(() => {
    // inicia cuenta para habilitar el botón checkIn
    const initTime = moment
      .duration(
        moment(dataLesson?.startDate).subtract(10, 'minutes').diff(moment()),
      )
      .asMilliseconds()
    setTimeToStart(
      moment.duration(moment(dataLesson?.startDate).diff(moment())).asMinutes(),
    )
    const timer = setTimeout(() => {
      setTimeToStart(
        moment
          .duration(moment(dataLesson?.startDate).diff(moment()))
          .asMinutes(),
      )
    }, initTime)
    return () => clearTimeout(timer)
  }, [dataLesson])

  // student y educator attendance
  const [educatorAttendance, setEducatorAttendance] = useState(
    educator?.attendanceStatus,
  )
  const [studentAttendance, setStudentAttendace] = useState({})
  useEffect(() => {
    if (student && dataLesson && educator) {
      setStudentAttendace(
        Array.isArray(student)
          ? student.reduce((objStu, stu) => {
              return {...objStu, [stu.studentUserId]: stu.attendanceStatus}
            }, {})
          : {[student.studentUserId]: student.attendanceStatus},
      )
      setEducatorAttendance(educator.attendanceStatus)
    }
  }, [student, dataLesson, educator])

  // verifica si cambio el attendaceStatus original de la lección
  const checkAttendanceChanges = useMemo(() => {
    let val = false // ¿hay cambios?
    val = educatorAttendance !== educator?.attendanceStatus
    if (!val) {
      val =
        studentAttendance &&
        Object.entries(studentAttendance).reduce(
          (validationAccu, [idStudent, attendance]) => {
            const stu = Array.isArray(student)
              ? student.filter((stu) => stu.studentUserId === idStudent)[0]
              : student
            return validationAccu || stu?.attendanceStatus !== attendance
          },
          false,
        )
    }
    return val
  }, [studentAttendance, student, educatorAttendance, educator])
  const resetAttendance = () => {
    setStudentAttendace(
      Array.isArray(student)
        ? student.reduce((objStu, stu) => {
            return {...objStu, [stu.studentUserId]: stu.attendanceStatus}
          }, {})
        : {[student.studentUserId]: student.attendanceStatus},
    )
    // setEducatorAttendance(educator.attendanceStatus)
  }
  // const allStudentMissed =
  //   Array.isArray(student)
  //     ? student.reduce((allMissed, stu) => {
  //       if (stu?.attendanceStatus !== 'missed') return false
  //       return allMissed
  //     }, true)
  //     : student?.attendanceStatus === 'missed'

  const noshow = educator.attendanceStatus === 'missed'
  const attended = student.attendanceStatus === 'attended'
  const timeToComplete = moment(dataLesson?.endDate).clone().add(24, 'hours')
  const [isBlurActive, setBlurActive] = useState(false)

  return (
    <>
      <ModalScroll
        buttonLabel={null}
        modalScrollClassName='h-100'
        className={`h-100 modal-colored modal-${
          breakWidth === 'SM'
            ? 'botbar'
            : statusOfInfoLessonState === PENDING
            ? 'sidebar modal-centered'
            : 'sidebar modal-desktop-half'
        }  fixed-head-modal ${
          statusOfInfoLessonState === SCHEDULED
            ? 'scheduled'
            : [
                FINISHEDATTENDED,
                FINISHEDMISSED,
                FINISHEDGROUP,
                INCOMPLETE,
              ].includes(statusOfInfoLessonState)
            ? 'white'
            : [PENDING].includes(statusOfInfoLessonState)
            ? 'whiteblue'
            : 'upcoming' // inprogress
        }-modal`}
        isOpen={isOpen}
        toggle={toggle}
        MinHead={
          <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
            <div className='d-flex flex-row align-items-center'>
              <span>{`${dataLesson?.title}`}</span>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <IconClose className='text-cancel' onClick={toggle} />
            </div>
          </div>
        }
        Head={
          <div
            className={`modal-header px-6 px-sm-7 d-flex flex-column ${
              statusOfInfoLessonState === 'pending' ? 'pb-0 pt-5' : ''
            }`}
            style={{zIndex: 10}}
          >
            <div className='w-100 d-flex justify-content-center mt-1'>
              <IconSignMinus size={20} className='ml-auto text-cancel' />
              <IconClose
                className='ml-auto text-cancel'
                size={20}
                onClick={toggle}
              />
            </div>
            <div className='w-100 pr-3 pl-0 py-3'>
              <IconIllustratedLesson size={80} />
              <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
                <h2 className='my-1'>
                  <b>{dataLesson && `${dataLesson?.title}`}</b>
                </h2>
                {statusOfInfoLessonState !== PENDING && (
                  <h4 className='my-1'>
                    {dataLesson &&
                      dataLesson?.startDate &&
                      dataLesson?.endDate &&
                      `${moment(dataLesson?.startDate).format(
                        'hh:mm a',
                      )} to ${moment(dataLesson?.endDate).format(
                        'hh:mm a',
                      )} | ${separateCamelCase(
                        moment(dataLesson?.startDate).format('MMM'),
                      )} ${moment(dataLesson?.startDate).format('DD, YYYY')}`}
                  </h4>
                )}
                <h5 className='h45 my-1 pr-2'>
                  {dataLesson &&
                    dataLesson?.__typename &&
                    `${
                      dataLesson?.isMeeting === true
                        ? 'Counseling | Meeting'
                        : separateCamelCase(service?.name)
                    } | ${separateCamelCase(
                      dataLesson?.location?.type || 'No defined',
                    )}`}{' '}
                  {dataLesson?.isMeeting}
                </h5>
                <div className='d-flex flex-row m-0 p-0'>
                  {[
                    FINISHEDATTENDED,
                    FINISHEDMISSED,
                    FINISHEDGROUP,
                    INCOMPLETE,
                  ].includes(statusOfInfoLessonState) && (
                    <Badge
                      className={`mx-0 mt-3 px-3 mr-1 badge badge-pill badge-${
                        attended && !noshow ? 'success' : 'danger'
                      } p-0`}
                      pill
                    >
                      {noshow ? 'No show' : attended ? 'Attended' : 'Missed'}
                    </Badge>
                  )}
                </div>
              </div>
            </div>
            {[ADMIN, EDUCATOR].includes(role) && (
              <h5 className='my-0'>{`ID: ${dataLesson?.id || 'No Id'}`}</h5>
            )}
          </div>
        }
        isBlurActive={isBlurActive}
        BlurSection={
          <AllowPermissions
            onContinue={() => {
              onCheckIn(dataLesson)
            }}
            setBlur={setBlurActive}
          />
        }
      >
        <div className='px-6 px-sm-7 m-0 pt-6 d-flex flex-column justify-content-between h-100'>
          <div className='m-0 p-0'>
            <Collapse isOpen={checkAttendanceChanges}>
              <ListItem
                showTopLine
                showBottomLine={false}
                Left={
                  <span
                    className='btn-link text-error h45'
                    onClick={resetAttendance}
                  >
                    {saveAttendanceLoad ? <Activity /> : 'Reset'}
                  </span>
                }
                Right={
                  <span
                    className='btn-link h45'
                    onClick={() => {
                      onSaveAttendance(
                        dataLesson,
                        studentAttendance,
                        educatorAttendance,
                      )
                    }}
                  >
                    {saveAttendanceLoad ? <Activity /> : 'Save'}
                  </span>
                }
              />
            </Collapse>
            {[ADMIN, EDUCATOR].includes(role) && (
              <ListItem
                showTopLine={false}
                showBottomLine
                Left={
                  <div className='m-0 p-0 d-flex flex-row'>
                    <Avatar
                      name={
                        student && `${student.firstName} ${student.lastName}`
                      }
                      named={false}
                      uri={null}
                      size='avatar-medium mr-1'
                    />
                    <span className='align-self-center'>
                      {student && `${student.firstName} ${student.lastName} `}
                      <small className='text-muted'>{`(${studentText})`}</small>
                    </span>
                  </div>
                }
                Right={
                  [EDUCATOR, ADMIN].includes(role) &&
                  [INPROGRESS, SCHEDULED, PENDING].includes(
                    statusOfInfoLessonState,
                  ) ? (
                    <div className='d-flex flex-row align-items-center'>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={[
                            {
                              component: (
                                <div className='d-flex flex-row m-0 py-0 px-1'>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: MISSED,
                                      })
                                    }}
                                  >
                                    <IconSignPlus color='red' />
                                  </div>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: PENDING,
                                      })
                                    }}
                                  >
                                    <IconSignMinus color='#72809D' />
                                  </div>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setStudentAttendace({
                                        ...studentAttendance,
                                        [student.studentUserId]: ATTENDED,
                                      })
                                    }}
                                  >
                                    <IconSignCheck color='#8FC813' />
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        >
                          <div className='hover-icon py-1 px-2'>
                            {studentAttendance[student.studentUserId] ===
                            MISSED ? (
                              <IconSignPlus color='red' />
                            ) : studentAttendance[student.studentUserId] ===
                              ATTENDED ? (
                              <IconSignCheck color='#8FC813' />
                            ) : (
                              <IconSignMinus color='#72809D' />
                            )}
                          </div>
                        </DropDown>
                      </div>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={studentOptions(student)}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{transform: 'rotate(90deg)'}} />
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex flex-row m-0 p-0'>
                      <div className='align-self-center'>
                        {student.attendanceStatus === MISSED ? (
                          <IconSignPlus color='red' />
                        ) : student.attendanceStatus === ATTENDED ? (
                          <IconSignCheck color='#8FC813' />
                        ) : (
                          <IconSignMinus color='#72809D' />
                        )}
                      </div>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={studentOptions(student)}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{transform: 'rotate(90deg)'}} />
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  )
                }
              />
            )}
            {[ADMIN, STUDENT].includes(role) && (
              <ListItem
                showTopLine={false}
                showBottomLine
                Left={
                  <div className='m-0 p-0 d-flex flex-row'>
                    <Avatar
                      name={
                        educator && `${educator.firstName} ${educator.lastName}`
                      }
                      named={false}
                      uri={dataLesson?.aditionalInfo?.educatorAvatar || null}
                      size='avatar-medium mr-1'
                    />
                    <span className='align-self-center'>
                      {educator &&
                        `${educator.firstName} ${educator.lastName} `}
                      <small className='text-muted'>(Counselor)</small>
                    </span>
                  </div>
                }
                Right={
                  [ADMIN].includes(role) &&
                  [INPROGRESS, SCHEDULED, PENDING].includes(
                    statusOfInfoLessonState,
                  ) ? (
                    <div className='d-flex flex-row align-items-center'>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={[
                            {
                              component: (
                                <div className='d-flex flex-row m-0 py-0 px-1'>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setEducatorAttendance('missed')
                                    }}
                                  >
                                    <IconSignPlus color='red' />
                                  </div>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setEducatorAttendance('pending')
                                    }}
                                  >
                                    <IconSignMinus color='#72809D' />
                                  </div>
                                  <div
                                    className='hover-icon'
                                    onClick={() => {
                                      setEducatorAttendance('attended')
                                    }}
                                  >
                                    <IconSignCheck color='#8FC813' />
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        >
                          <div className='hover-icon py-1 px-2'>
                            {educatorAttendance === MISSED ? (
                              <IconSignPlus color='red' />
                            ) : educatorAttendance === ATTENDED ? (
                              <IconSignCheck color='#8FC813' />
                            ) : (
                              <IconSignMinus color='#72809D' />
                            )}
                          </div>
                        </DropDown>
                      </div>

                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={educatorOptions(educator)}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{transform: 'rotate(90deg)'}} />
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex flex-row m-0 p-0'>
                      <div className='align-self-center'>
                        {educator.attendanceStatus === MISSED ? (
                          <IconSignPlus color='red' />
                        ) : educator.attendanceStatus === ATTENDED ? (
                          <IconSignCheck color='#8FC813' />
                        ) : (
                          <IconSignMinus color='#72809D' />
                        )}
                      </div>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={educatorOptions(educator)}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{transform: 'rotate(90deg)'}} />
                          </div>
                        </DropDown>
                      </div>
                    </div>
                  )
                }
              />
            )}
            {[INPROGRESS, SCHEDULED].includes(statusOfInfoLessonState) &&
              (isCheckIn || [ADMIN].includes(role) ? (
                <div className='d-flex flex-row my-6'>
                  <Button
                    style={{backgroundColor: '#72809d'}}
                    className='w-50 mr-1'
                    size='large'
                    onClick={() => {
                      onBackUp(dataLesson)
                    }}
                    label={
                      onBackUpLoad ? (
                        <div className='w-100 d-flex flex-row justify-content-center'>
                          <Activity inverse />
                        </div>
                      ) : (
                        <span className='text-center'>{backupText}</span>
                      )
                    }
                  />
                  <Button
                    style={
                      dataLesson?.location?.type === 'virtualClassroom'
                        ? {
                            borderRadius: '16px 0px 0px 16px',
                            backgroundColor: '#696CFF',
                            width: '40%',
                          }
                        : {backgroundColor: '#696CFF', width: '50%'}
                    }
                    className='w-50 ml-1'
                    size='large'
                    onClick={() => {
                      onGoToClass(dataLesson)
                    }}
                    label={
                      onGoToClassLoad ? (
                        <div className='w-100 d-flex flex-row justify-content-center'>
                          <Activity inverse />
                        </div>
                      ) : (
                        <span className='text-center'>{gotoclassText}</span>
                      )
                    }
                  />
                  {dataLesson?.location?.type === 'virtualClassroom' && (
                    <Button
                      style={{
                        backgroundColor: '#696CFF',
                        borderRadius: '0px 16px 16px 0px',
                        marginLeft: '1px',
                        width: '10%',
                      }}
                      size='large'
                      className='d-flex flex-row justify-content-center align-items-center'
                      onClick={() => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'calendar.setBraincertToLesson',
                          data: {
                            isOpen: true,
                            lessonId: dataLesson.id,
                            isOrientation: true,
                          },
                        })
                      }}
                      label={<IconRepeat size={15} />}
                    />
                  )}
                </div>
              ) : (
                <Button
                  style={{backgroundColor: '#72809d'}}
                  className='w-100 my-6'
                  size='large'
                  disabled={timeToStart > 10} // habilitar checkin solo 10 minutos antes del inicio de la lección
                  onClick={() => {
                    // poner modal en blur
                    setBlurActive(true)
                  }}
                  label={
                    onCheckInLoad ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <div className='d-flex flex-row justify-content-between'>
                        <span>{checkinText}</span>
                        <IconArrowRight />
                      </div>
                    )
                  }
                />
              ))}
            {statusOfInfoLesson === INCOMPLETE && (
              <Fragment>
                <div className='borderTop' />
                {/* <SendLessonNotesOrientation
                    setStatusOfInfoLessonState={setStatusOfInfoLessonState}
                    dataLesson={dataLesson}
                    //refetchCalendarQuery={refetchCalendarQuery}
                    refetchCalendarQuery={() => {}}
                  >
                    <Button
                      style={{ backgroundColor: '#E7AD00' }}
                      className='w-100 mt-6 mb-3'
                      size='large'
                      label={
                        <div className='d-flex flex-row justify-content-between'>
                          <span>{translate('modal.infolesson.completelessonnotes')}</span>
                          <IconArrowRight />
                        </div>
                      }
                    />
                  </SendLessonNotesOrientation> */}
                <div
                  className='zkn-btn znk-size-small w-100 mb-6 btn mt-6'
                  style={{backgroundColor: '#FAEECD'}}
                >
                  <div className='d-flex flex-row justify-content-between'>
                    <span className='h45 text-incomplete'>
                      {translate('modal.infolesson.timetocomplete')}
                    </span>
                    <Countdown
                      endTime={timeToComplete}
                      iconColor='#E7AD00'
                      timeOverLabel={translate('modal.infolesson.timeisover')}
                      spanTimeClassName='h45 text-incomplete'
                    />
                  </div>
                </div>
              </Fragment>
            )}
            {[INPROGRESS, SCHEDULED, PENDING].includes(
              statusOfInfoLessonState,
            ) ? (
              [STUDENT, ADMIN].includes(role) ? (
                <ListItem
                  showTopLine={statusOfInfoLessonState !== PENDING}
                  showBottomLine
                  onClick={() => {
                    dispatch({
                      type: 'SET_DATA_MODAL_FORM_LIST',
                      payload: 'calendar.rescheduledOrientation',
                      isOpen: true,
                      data: {
                        isOpen: true,
                        dataLesson,
                      },
                    })
                  }}
                  className='py-4'
                  Left={
                    <div className='d-flex flex-row'>
                      <IconProgram
                        color='#696CFF'
                        className='align-self-center mr-2'
                      />
                      <span className='align-self-center text-primary'>
                        {rescheduledText}
                      </span>
                    </div>
                  }
                  Right={<IconArrowRight />}
                />
              ) : (
                <div className='borderTop w-100' />
              )
            ) : (
              [INCOMPLETE].includes(statusOfInfoLessonState) &&
              ([EDUCATOR, ADMIN].includes(role) ? (
                <ListItem
                  showTopLine={false}
                  showBottomLine
                  onClick={() => {
                    onSendResume(dataLesson)
                  }}
                  className='py-4'
                  Left={
                    <div className='d-flex flex-row'>
                      <IconProgram
                        color='#696CFF'
                        className='align-self-center mr-2'
                      />
                      <span className='align-self-center text-primary'>
                        {sendResumeText}
                      </span>
                    </div>
                  }
                  Right={<IconArrowRight />}
                />
              ) : (
                <div className='borderTop w-100' />
              ))
            )}
            {dataLesson?.notes?.status === 'sent' && (
              <div className='d-flex flex-column mt-5'>
                <span className='text-title h4'>Meeting notes</span>
                <span className='text-title h45'>{`${translate(
                  'modal.sendnotes.sentOn',
                )} ${moment(notesSentDate).format('DD MMMM, YYYY')}`}</span>
                {notes?.hadHw && (
                  <div className='d-flex flex-row my-3 h45'>
                    <div
                      className='d-flex flex-column mr-2'
                      style={{width: '60%'}}
                      flex={1}
                    >
                      <span
                        className='text-gray'
                        style={{whiteSpace: 'nowrap'}}
                      >
                        {translate('modal.sendnotes.hwCompleted')}
                      </span>
                      <span>
                        {notes?.didHw ? translate('modal.sendnotes.yes') : 'No'}
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <span className='text-gray'>
                        {translate('modal.sendnotes.score')}
                      </span>
                      <span>{notes?.score || '-'}</span>
                    </div>
                  </div>
                )}
                {notes?.newHw && (
                  <div className='d-flex flex-row my-3 h45'>
                    <div
                      className='d-flex flex-column mr-2'
                      style={{width: '60%'}}
                      flex={1}
                    >
                      <span
                        className='text-gray'
                        style={{whiteSpace: 'nowrap'}}
                      >
                        {translate('modal.sendnotes.hwAssigned')}
                      </span>
                      <span>{notes?.descriptionHw}</span>
                    </div>
                    <div className='d-flex flex-column'>
                      <span className='text-gray'>
                        {translate('modal.sendnotes.duedate')}
                      </span>
                      <span>
                        {moment(notes?.dueDateHw).format('DD-MM-YYYY')}
                      </span>
                    </div>
                  </div>
                )}
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>
                    What was discussed during the meeting?
                  </span>
                  <span>{notes?.acqKnowledge}</span>
                </div>
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>
                    What should the student be focusing on currently?
                  </span>
                  <span>{notes?.studentEffort}</span>
                </div>
                {notes?.others && (
                  <div className='d-flex flex-column my-3 h45'>
                    <span className='text-gray'>
                      {translate('modal.sendnotes.othercomments')}
                    </span>
                    <span>{notes?.others}</span>
                  </div>
                )}
              </div>
            )}
            {[ADMIN].includes(role) && (
              <ListItem
                showBottomLine={false}
                showTopLine={false}
                className='py-4'
                Left={
                  <div className='d-flex flex-column'>
                    <span className='h5 align-self-left text-gray my-2'>
                      {showCreatedDate(createdDate)}
                    </span>
                    <span className='h5 align-self-left text-gray my-2'>
                      {showUpdatedDate(updateDate)}
                    </span>
                  </div>
                }
                Right={<IconArrowRight />}
              />
            )}
          </div>
          {/* Estudent can`t a meeting or orietation
            {[INPROGRESS, SCHEDULED].includes(statusOfInfoLessonState) &&
            (CancelComponent || (
              <div
                className='pb-4'
                onClick={() => {
                  onCancel(dataLesson)
                }}
              >
                <span className='h5 text-gray'>Can {dataLesson?.isMeeting ? 'meeting' : 'lesson'}</span>
              </div>
            ))} */}
        </div>
      </ModalScroll>
      <RescheduledOrientation />
      <ConfirmSetBraincertToLesson
        isOrientation
        refetchLesson={refetchLesson}
      />
    </>
  )
}

OrientationLessonModal.propTypes = propTypes
OrientationLessonModal.defaultProps = defaultProps
export default OrientationLessonModal
