import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import showFrontappChat from '../../../../../../../helpers/showFrontappChat'

const MessageModal = (props) => {
  const {
    isOpen,
    toggle,
    message = '',
    messageBody = '',
    addUniversityWithCharge = false,
  } = props

  return (
    <ConfirmModal
      classNameActionDiv='d-flex flex-column'
      classNameConfirm='text-primary white-space-nowrap'
      isOpen={isOpen}
      toggle={toggle}
      title={message}
      body={messageBody}
      confirmAction={() => {
        addUniversityWithCharge ? showFrontappChat() : toggle()
      }}
      confirmText={addUniversityWithCharge ? 'Contact Support' : 'Ok'}
      cancelAction={() => {
        addUniversityWithCharge && toggle()
      }}
      cancelText={addUniversityWithCharge && 'Cancel'}
    />
  )
}

export default MessageModal
