import React from 'react'
import useGetPaymentMethod from '../../../../../NewPayments/Components/GetPaymentMethod/useGetPaymentMethod'
import Skeleton from 'react-loading-skeleton'

const ShowPaymentMethod = (props) => {
  const {userId = null} = props
  const {label, loading: loadPayer} = useGetPaymentMethod(userId)
  return loadPayer ? (
    <Skeleton count={1} height={10} width={70} />
  ) : (
    <span className='text-gray h45'>{label}</span>
  )
}

export default ShowPaymentMethod
