import {initialState} from './initialState'
import {ACTIVE_PLATFORM, DESACTIVE_PLATFORM} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_PLATFORM:
      return {
        ...state,
        platform: state.platform.concat([action.platform]),
      }
    case DESACTIVE_PLATFORM:
      return {
        ...state,
        platform: state.platform.filter((plat) => plat !== action.platform),
      }
    default:
      return {...state}
  }
}
