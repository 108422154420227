import React from 'react'
import {IconBrand, IconLogo} from '@Knowledge-OTP/znk-ui-components'
import Signin from './Signin'
import ArrayAlert from '../../common/Alert/index'

const LoginPage = () => {
  return (
    <div className='w-100 h-100 d-flex bg-white'>
      <div className='col-12 h-100 d-flex flex-column col-md-6'>
        <div className='p-4'>
          <IconBrand className='text-logo' />
          <IconLogo className='ml-2 text-logo' />
        </div>
        <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
          <Signin />
        </div>
      </div>
      <div
        className='col-6 h-100 d-none d-md-flex bg-light bodyPublic'
        style={{
          transform: 'scaleX(-1)',
          backgroundImage:
            'url("https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/web/background.png")',
          backgroundPosition: 'top',
        }}
      />
      <ArrayAlert />
    </div>
  )
}

export default LoginPage
