import React, {useMemo} from 'react'
import {
  ListItem,
  IconStudent,
  IconLesson,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import GroupLessonScheduled from './GroupLessonSchedule'

const WeeklySchedule = (props) => {
  const {
    hideTitle,
    hideSubTitle,
    className,
    data,
    idTopic,
    dataPlan,
    indexPart,
    showTotalLessons,
    useTotalLessonText,
    hasGroupLesson,
    groupPartData,
    showCollapse = false,
  } = props
  const {translate} = useTranslation()
  const [now] = useMemo(() => {
    return [moment()]
  }, [])

  const RenderLabel = (props) => {
    const {item} = props
    let lessons
    if (item.topic.id === 'Orientation') {
      lessons = item?.totalLessons || item?.lpw
    } else {
      lessons = showTotalLessons ? item?.totalLessons || item?.lpw : item?.lpw
    }

    return (
      <CarouselFrame type='animation'>
        <ListItem
          showTopLine={false}
          className='pl-0 py-1'
          Left={
            <div
              className='d-flex align-items-center text-muted'
              title={
                item.type === 'l'
                  ? useTotalLessonText
                    ? translate(`home.onboard.approve.weeklyItemLessonFixed`, {
                        lpw: lessons,
                        name: `${item.topic.name}`,
                      })
                    : translate(`home.onboard.approve.weeklyItemLesson`, {
                        lpw: lessons,
                        name: `${item.topic.name}`,
                      })
                  : translate(`home.onboard.approve.weeklyItemOrientation`, {
                      lpw: lessons,
                      name: `${item.topic.name}`,
                    })
              }
            >
              {item.topic.id === 'Orientation' ? (
                <IconStudent />
              ) : (
                <IconLesson />
              )}
              <span className='ml-4' style={{whiteSpace: 'nowrap'}}>
                {item.topic.id !== 'Orientation'
                  ? useTotalLessonText
                    ? translate(`home.onboard.approve.weeklyItemLessonFixed`, {
                        lpw: lessons,
                        name: `${item.topic.name}`,
                      })
                    : translate(`home.onboard.approve.weeklyItemLesson`, {
                        lpw: lessons,
                        name: `${item.topic.name}`,
                      })
                  : translate(`home.onboard.approve.weeklyItemOrientation`, {
                      lpw: lessons,
                      name: `${item.topic.name}`,
                    })}
              </span>
            </div>
          }
        />
      </CarouselFrame>
    )
  }

  const timeLabel = useTotalLessonText
    ? moment(dataPlan.ranges[indexPart][0]) <= now
      ? translate('home.onboard.approve.before.startwhen')
      : `${translate('home.onboard.approve.before.begin')} ${moment(
          dataPlan?.ranges[indexPart][0]?.split('T')[0],
        ).format('MMMM Do')}`
    : `${translate('home.onboard.approve.before.from')} 
        ${moment(dataPlan?.ranges[indexPart][0]?.split('T')[0]).format(
          'MMMM Do',
        )} 
        ${translate('home.onboard.approve.before.to')} 
        ${moment(dataPlan?.ranges[indexPart][1]?.split('T')[0]).format(
          'MMMM Do',
        )}`

  return (
    <div className={`m-0 ${className}`}>
      <>
        {!hideTitle && (
          <>
            <h3 className='mb-2'>
              <b>
                {useTotalLessonText
                  ? translate('home.onboard.approve.weeklyTitleFixed')
                  : translate('home.onboard.approve.weeklyTitle')}
              </b>
            </h3>
          </>
        )}
        {!hideSubTitle && (
          <small className='text-muted'>
            {dataPlan[indexPart]?.name && `${dataPlan[indexPart]?.name}:  `}
            {timeLabel}
          </small>
        )}
      </>
      <div className={`m-0 p-0 ${!hideTitle && 'py-3'}`}>
        {data.length > 0 || hasGroupLesson ? (
          <>
            {data.length > 0 &&
              data
                .filter((item) =>
                  idTopic !== '' ? item.topic.id === idTopic : true,
                )
                .map((item, index) => {
                  return <RenderLabel key={index} item={item} />
                })}
            {hasGroupLesson && (
              <GroupLessonScheduled
                showCollapse={showCollapse}
                groupPartData={groupPartData}
              />
            )}
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
    </div>
  )
}

WeeklySchedule.propTypes = {
  hideTitle: PropTypes.bool,
  hideSubTitle: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array,
  idTopic: PropTypes.string,
  showTotalLessons: PropTypes.bool,
}
WeeklySchedule.defaultProps = {
  hideTitle: false,
  hideSubTitle: false,
  className: '',
  data: [],
  idTopic: '',
  showTotalLessons: false,
}

export default WeeklySchedule
