export default {
  title: 'To approve your plan start scheduling your lessons',
  from: 'Desde',
  to: 'hasta',
  monthlyFees: (num) => {
    if (num > 1) {
      return `${num} cuotas mensuales`
    } else {
      return `${num} cuota mensual`
    }
  },
  monthlyGroupFees: (num) => {
    if (num > 1) {
      return `${num} cuotas grupales mensuales`
    } else {
      return `${num} cuota grupal mensual`
    }
  },
  rateLesson: ({num, rate}) => {
    if (num > 1) {
      return `${num} lecciones a $${rate} c/u`
    } else {
      return `${num} lección a $${rate}`
    }
  },
  rateLessonGroup: ({num, rate}) => {
    if (num > 1) {
      return `${num} lecciones grupales a $${rate} c/u`
    } else {
      return `${num} lección grupal a $${rate}`
    }
  },
}
