import React, {useEffect, useState, useMemo} from 'react'
import {Brand, useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {useLocation} from 'react-router-dom'
import InvoiceModal from '../../Pages/Profile/Pyments/InvoiceDetails/Modal'
import useModalState from '../../hooks/useModalState'
import {decryptToken} from '../../helpers/decrypt'
import './index.css'

const OpenInvoiceyModalFromUrl = () => {
  const now = new Date().toISOString()
  const location = useLocation()
  const {breakWidth} = useWindowDimensions()
  const {onChange} = useModalState('payments.viewInvoice')
  const searchParams = new URLSearchParams(location.search)
  const invoiceId = searchParams.get('invoiceId')
  const tokenParam = searchParams.get('token')
  const token = tokenParam ? decryptToken(tokenParam, 'zinkerz key') : ''
  const tokenDecrypted = useMemo(() => token.split('id:') || [null, null], [
    token,
  ])
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false)

  useEffect(() => {
    if (invoiceId) {
      if (now < tokenDecrypted[0] && tokenDecrypted[1] === invoiceId) {
        onChange(() => ({invoiceId}))
        setInvoiceModalOpen(true)
      }
    }
    /* eslint-disable-next-line */
  }, [invoiceId, tokenDecrypted])

  // useEffect(() => {
  //   if (now < tokenDecrypted[0] && tokenDecrypted[1] === invoiceId) {
  //   setShowInvoice(true)
  //   }
  //   /* eslint-disable-next-line */
  // }, [token, invoiceId])

  return (
    <>
      {invoiceModalOpen && (
        <div className='background-offline-modal'>
          {breakWidth !== 'SM' && (
            <Brand sizeClass='medium' className='position-relative mt-0 pt-4' />
          )}
          <InvoiceModal
            offline
            toggle={() => setInvoiceModalOpen(!invoiceModalOpen)}
          />
        </div>
      )}
    </>
  )
}

export default OpenInvoiceyModalFromUrl
