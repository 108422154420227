import {gql, useQuery} from '@apollo/client'
import taskQueryResult from './queryResults/task'

export default (vars) => {
  // Sirve para traer una task en particular.
  const {data, loading, refetch} = useQuery(
    gql`query(
            $taskId: ID!
            $stepId: ID!
            $phase: PhaseEnum!
            $planId: ID!
                  )
                  {
            getUserTask(
                taskId: $taskId
                stepId: $stepId
                phase: $phase
                planId: $planId
        ) {
            ${taskQueryResult}
        }
    }`,
    {
      variables: vars,
    },
  )
  return {
    loading,
    data: data ? data.getUserTask : {},
    refetch,
  }
}
