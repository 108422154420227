import {useLazyQuery, gql} from '@apollo/client'

const useUsers = (vars = {}) => {
  return useLazyQuery(
    gql`
      query($userIds: [ID!]) {
        users(userIds: $userIds) {
          id
          timezone
        }
      }
    `,
    {
      variables: vars,
    },
  )
}

export default useUsers
