import React from 'react'
import {ConfirmModal, Activity} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {gql, useMutation} from '@apollo/client'
import {useDispatch, useSelector} from 'react-redux'
import {
  TOGGLE_MODAL_ACTION,
  SET_DATA_MODAL_FORM_LIST,
} from '../../../../../store/modal/const'
import {showAlert} from '../../../../../common/Alert/util'

const SetPendingLesson = (props) => {
  const {lessonId, refetchLessons, callback} = props
  const isModalOpen = useSelector((state) => state.modal.program.setpending)
  const reduxDataInfoModal = useSelector(
    (state) => state.modal.student.infoLessonModal,
  )
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const toggle = () => {
    dispatch({type: TOGGLE_MODAL_ACTION, payload: 'program.setpending'})
  }
  const toggleRescheduledLesson = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'program.rescheduledModal',
      data: false,
    })
  }

  const [mutation, {loading}] = useMutation(
    gql`
      mutation create($lessonId: String!) {
        setLessonToPending(lessonId: $lessonId) {
          id
          status
        }
      }
    `,
    {
      update: (cache) => {
        refetchLessons()
        toggle()
        toggleRescheduledLesson()
        if (callback) callback()
        // cierra el modal de la lección
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'student.infoLessonModal',
          data: {
            ...reduxDataInfoModal,
            isOpen: false,
          },
        })
        showAlert(
          {
            text: translate(
              'lessons.modal.rescheduledpending.leavelessonpendingSuccess',
            ),
            status: 'success',
          },
          dispatch,
        )
      },
      onError: (error) => {
        showAlert(
          {
            text: translate(
              'lessons.modal.rescheduledpending.leavelessonpendingError',
            ),
            status: 'error',
          },
          dispatch,
        )
      },
    },
  )
  const setPendingLesson = (lessonId) => {
    mutation({
      variables: {
        lessonId: lessonId,
      },
    })
  }

  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <ConfirmModal
        className='m-4'
        isOpen={isModalOpen}
        toggle={toggle}
        title={translate('lessons.modal.rescheduledpending.leavelessonpending')}
        body={translate(
          'lessons.modal.rescheduledpending.leavelessonpendingBody',
        )}
        confirmAction={() => {
          setPendingLesson(lessonId)
        }}
        cancelAction={toggle}
        confirmText={
          loading ? (
            <Activity />
          ) : (
            translate('lessons.modal.rescheduledpending.confirm')
          )
        }
        cancelText={
          loading ? (
            <Activity />
          ) : (
            translate('lessons.modal.rescheduledpending.cancel')
          )
        }
      />
    </>
  )
}

export default SetPendingLesson
