import {gql} from '@apollo/client'

const invoiceQueryOffline = gql`
  query($invoiceId: String!) {
    getInvoiceOffline(invoiceId: $invoiceId) {
      id
      status
      category
      billedUserId
      processedTimes
      invoiceNumber
      from
      to
      paymentItemIds
      createdAt
      updatedAt
      lastProcessedAt
      amount: realAmount
      taxFee
      amounts: realAmounts {
        subTotal
        taxAmount
        amount
      }
      paymentItems {
        ... on PlanPaymentItem {
          generatedDescription
          id
          type
          amount: realAmount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on CustomPaymentItem {
          id
          type
          amount: realAmount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on LessonPaymentItem {
          generatedDescription
          id
          type
          amount: realAmount
          draft
          status
          lastProcessedAt
          description
          category
        }
      }
      issuedInvoices {
        stripeInvoiceId
        status
        paymentMethod
        amount
      }
    }
  }
`
export default invoiceQueryOffline
