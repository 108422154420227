import {useLazyQuery, gql} from '@apollo/client'
import universityCompleteQueryResult from './QueryResults/useUniversityCompleteResult'

export const useGetUniversity = () => {
  // Sirve para traer todas las tasks de un alumno.
  // Pero esta misma query la podemos usar para traer las tasks de una universidad,
  // solo las de un cierto estado, etc.
  return useLazyQuery(
    gql`query(
            $universityId: ID!
              )
              {
        getUniversity(universityId: $universityId
    ) {
        ${universityCompleteQueryResult}
    }
}`,
    {
      fetchPolicy: 'no-cache',
    },
  )
}
