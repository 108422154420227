import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import {FileItem, ExamCard, Countdown} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import useDocsAndExams from '../../DocsAndExams/useDocsAndExams'
import useTranslation from '../../../../../i18n/useTranslation'
import NoDocsComponent from '../../MyCollegeModal/Documents/NoDocs'
import taskTypes from '../../../Utils/taskTypes'
import useGetUserDocsAndExams from '../../../../Task/Model/useGetUserDocsAndExams'
import useRegisterScoresModal from '../../RegisterScoresModal/useRegisterScoresModal'
import {typesOnDocs} from '../../../Utils/taskTypesOnDocs'
import {useGetTestDatePlans} from './model/useMyTestDatePlans'
// import PopupSkipScores from './Components/popupSkipScores'
import useSetTimesSkippedMutation from './model/useSetTimesSkippedToPlan'
import {useGetExamScore} from './model/useGetExamScore'
import {SET_EXAMSCORES_ACTION} from '../../../../../store/examScores/const'
import {showAlert} from '../../../../../common/Alert/util'

const DocsSection = (props) => {
  //TODO SCORES: Popup Skip Scores comentado hasta que quede funcionando lo de SCORES
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const today = moment()
  const statusToFilter = ['scheduled', 'paid']
  const examScores = useSelector((state) => state?.examScore?.examScores)
    // eslint-disable-next-line
  const [canSkip, setCanSkip] = useState(true)
  // eslint-disable-next-line
  const [examStructure, setExamStructure] = useState({})
  const {open: openRegisterScores, setTaskData} = useRegisterScoresModal()
  // Mutación que obtiene los test dates
  const [
    getTestDatePlans,
    // eslint-disable-next-line
    { data: dataTestDatePlans, loading: loadingTestDatePlans, refetch: refetchTestDatePlans },
  ] = useGetTestDatePlans()
  // Mutación que obtiene los score collections
  // eslint-disable-next-line
  const [getExamScores, { data: dataExamScore, loading: loadingExamScore }] = useGetExamScore(
    (resp) => {
      dispatch({
        type: SET_EXAMSCORES_ACTION,
        payload: resp?.getExamScores?.docs,
      })
    },
    (err) => {
      console.error('Error on getting exam scores : ', err)
    },
  )
  // eslint-disable-next-line
  const [setTimesSkipped, { data: dataTimesSkipped, loading: loadingTimesSkipped }] = useSetTimesSkippedMutation()
  // Modal Docs & Exams
  // eslint-disable-next-line
  const { open: openDocsAndExamsModal, setData: setDataDocs } = useDocsAndExams()
  // eslint-disable-next-line
  const { loading: loadingDocs, data: dataDocs, refetch: refetchDocs } = useGetUserDocsAndExams({
  })
  const [scoresToSave, setScoresToSave] = useState([])
    // eslint-disable-next-line
  const [messageModalOpen, setMessageModalOpen] = useState(false)
    // eslint-disable-next-line
  const [messageModalText, setMessageModalText] = useState(
    translate('counseling.docsAndExams.skipScoresSubtitle'),
  )

  const [docsAndExamsData, setDocsAndExamsData] = useState([])
  const [examsToShow, setExamsToShow] = useState([])
  const docsToSee = 3
  const fakesDocsToRender = [
    {name: null},
    {name: null},
    {name: null},
    {name: null},
  ]
  // Le damos al array la longitud que debe tener, así no hay que hacer lógica de más en el return
  fakesDocsToRender.length =
    docsAndExamsData?.length > 0 && docsAndExamsData?.length < 3
      ? docsToSee - docsAndExamsData?.length
      : 0
  useEffect(() => {
    if (dataDocs && dataDocs.length > 0) {
      // Filtro los docs que tengan showInDocs en true.
      setDocsAndExamsData(
        dataDocs?.filter(
          (x) =>
            (typesOnDocs.includes(x.type) &&
              x.showInDocs &&
              x.resolveTask?.uploadFiles !== null) ||
            x.type === taskTypes?.scoreCollection,
        ),
      )
    }
  }, [dataDocs])

  const checkExamsToSaveScores = async (exams) => {
    await setScoresToSave([])
    // agregar validacion para ver si no estan cargadas las notas
    if (exams?.length > 0) {
      // eslint-disable-next-line
      exams.map((exam) => {
        // eslint-disable-next-line
        if (exam?.selectedExam?.scores) return
        if (exam.timesSkipped === 2 && !exam?.selectedExam?.scores) {
          setCanSkip(false)
          setMessageModalText(
            translate('counseling.docsAndExams.cantSkipAnymore'),
          )
          setScoresToSave((prevState) => [...prevState, exam])
        }
        if (
          moment(exam.activityTest?.scoreReleaseDate).add(2, 'days') < today
        ) {
          if (exam?.timesSkipped === 0) {
            setScoresToSave((prevState) => [...prevState, exam])
          } else {
            if (moment(exam?.dateSkipped).add(2, 'days') < today) {
              setScoresToSave((prevState) => [...prevState, exam])
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    getTestDatePlans()
    if (!examScores) {
      getExamScores()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      dataTestDatePlans?.myPlans &&
      dataTestDatePlans?.myPlans?.docs?.length > 0
    ) {
      // Filtro los exams que tengan test date.
      setExamsToShow(
        dataTestDatePlans?.myPlans?.docs?.filter(
          (exam) =>
            exam?.testDate !== null &&
            exam?.activityTest &&
            statusToFilter.includes(exam.status),
        ),
      )
    }
    // eslint-disable-next-line
  }, [dataTestDatePlans])

  useEffect(() => {
    checkExamsToSaveScores(examsToShow)
    // eslint-disable-next-line
  }, [examsToShow])

  useEffect(() => {
    if (scoresToSave.length > 0) {
      setExamStructure(
        examScores.filter(
          (exam) => exam.serviceId === scoresToSave[0].serviceId,
        )[0],
      )
      setMessageModalOpen(true)
    }
    // eslint-disable-next-line
  }, [scoresToSave])

  return (
    <TabContent
      activeTab={loadingDocs || loadingTestDatePlans ? `loading` : `show`}
    >
      <TabPane tabId='loading'>
        <div className='m-0 p-0 d-flex flex-column'>
          <div className='d-flex flex-column m-0 p-0'>
            <Skeleton count={1} height={20} width={300} />
            <div className='m-0 p-0 d-flex flex-row justify-content-center'>
              <Skeleton count={3} height={80} width={70} />
            </div>
          </div>
        </div>
      </TabPane>
      <TabPane tabId='show'>
        <div className='m-0 p-0 d-flex flex-column'>
          <div className='d-flex flex-column m-0 p-0'>
            <div className='d-flex flex-row justify-content-between'>
              <span className='h4 mr-6 mt-4' style={{lineHeight: '1.6'}}>
                {' '}
                {translate('counseling.docsAndExams')}
              </span>
              {docsAndExamsData?.length > 0 && (
                <span
                  className='h5 align-self-end mb-2'
                  style={{cursor: 'pointer', lineHeight: '1.6'}}
                  onClick={() => {
                    openDocsAndExamsModal()
                  }}
                >
                  See list
                </span>
              )}
            </div>
            <div
              className='m-0 p-0 d-flex flex-row justify-content-start znk-scroll-horizontal'
              style={{
                lineHeight: '2',
                fontSize: '10px',
              }}
            >
              {examsToShow?.length > 0 &&
                examsToShow?.map((exam, index) => {
                  const endTimeToShow =
                    moment(exam?.testDate) > today
                      ? moment(exam?.testDate)
                      : exam?.activityTest?.scoreReleaseDate
                      ? moment(exam?.activityTest?.scoreReleaseDate)
                      : 0
                  const titleToShow =
                    moment(exam?.testDate) > today
                      ? 'Test Date'
                      : exam?.activityTest?.scoreReleaseDate
                      ? 'Score Release Date'
                      : ''
                  const hoursUntilExam = moment
                    .duration(moment(endTimeToShow).diff(moment()))
                    .asHours()
                  const daysUntilExam = moment(endTimeToShow).fromNow()
                  // moment.duration(today.diff(endTimeToShow)).asDays()
                  return (
                    <div className='d-flex justify-content-between mb-7 mr-1'>
                      <ExamCard
                        className='mr-3'
                        spanTimeClassName='h5 text-white'
                        size='medium'
                        classColor='orange'
                        title={titleToShow}
                        // number={index + 1}
                        examTitle={exam?.name.split(' ')[0]}
                        endTime={endTimeToShow}
                        hiddenTime
                        showWithoutTime={
                          <div className='d-flex flex-column text-align-start'>
                            <span className='h5 text-white'>
                              {hoursUntilExam > 23 ? (
                                daysUntilExam?.charAt(0).toUpperCase() +
                                daysUntilExam?.slice(1)
                              ) : (
                                <Countdown
                                  spanTimeClassName='h5 text-white'
                                  endTime={endTimeToShow}
                                />
                              )}
                            </span>
                            <span className='h5 text-white'>
                              {moment(endTimeToShow).format('MMM DD')}
                            </span>
                          </div>
                        }
                        onClick={() => {
                          setTaskData(exam)
                          moment(exam?.testDate) > today
                            ? showAlert(
                                {
                                  text: translate(
                                    'counseling.docsAndExams.notAvailableYet',
                                  ),
                                  status: 'success',
                                },
                                dispatch,
                              )
                            : openRegisterScores(() => {
                                refetchTestDatePlans()
                              })
                        }}
                      />
                    </div>
                  )
                })}
              {/* Si hay Docs o Exams, renderiza lo que hay */}
              {docsAndExamsData?.length > 0
                ? // eslint-disable-next-line
                docsAndExamsData.map((x) => {
                    if (x.type === taskTypes.scoreCollection) {
                      return (
                        <div className='d-flex justify-content-between mb-7 mr-2'>
                          <div
                            style={{cursor: 'pointer'}}
                            className='d-flex flex-row'
                            onClick={() => {
                              setTaskData(x)
                              openRegisterScores(() => {})
                            }}
                          >
                            <ExamCard
                              className='mr-3'
                              spanTimeClassName='h5 text-white'
                              size='medium'
                              classColor='purple'
                              examTitle={x?.resolveTask?.score?.name}
                              onClick={() => {
                                // setTaskData(exam)
                                // openRegisterScores(() => {}, exam)
                              }}
                              hiddenTime
                              showWithoutTime={
                                <div className='d-flex flex-column text-align-start'>
                                  <span className='h5 text-white'>
                                    # {x?.resolveTask?.score?.overall}
                                  </span>
                                  <span className='h5 text-white'>
                                    {x?.resolveTask?.score?.examDate &&
                                      moment(
                                        x?.resolveTask?.score?.examDate,
                                      ).format('MMM YYYY')}
                                  </span>
                                </div>
                              }
                            />

                            {/* <FileItem
                            fileText={
                              <div className='d-flex flex-column text-align-start'>
                                <RedRibbon
                                  className='znk-ribbon-small ml-n4 mt-n2'
                                  left='znk-ribbon-small left'
                                  right=''
                                />
                                <span className='h5 text-white'>
                                  {x?.resolveTask?.score?.name}
                                </span>
                                <span className='h5 text-white'>
                                  # {x?.resolveTask?.score?.overall}
                                </span>
                                <span className='h5 text-white'>
                                  {x?.resolveTask?.score?.examDate &&
                                    moment(
                                      x?.resolveTask?.score?.examDate,
                                    ).format('MMM YYYY')}
                                </span>
                              </div>
                            }
                            className='align-items-start justify-content-end'
                            color='purple'
                            size='medium-plus'
                          /> */}
                          </div>
                        </div>
                      )
                    } else {
                      let nameToShow = x?.name.split(' ')
                      nameToShow.shift()
                      nameToShow = nameToShow.join(' ')
                      return (
                        <div className='d-flex justify-content-between mb-7'>
                          {/* eslint-disable-next-line */}
                        <a href={x?.resolveTask?.uploadFiles[0]?.url}
                            // eslint-disable-next-line
                          target="_blank">
                            <div className='d-flex flex-row'>
                              <FileItem
                                fileText={
                                  <div className='d-flex flex-column text-align-start'>
                                    <span className='h5 text-white'>
                                      {nameToShow}
                                    </span>
                                  </div>
                                }
                                className='align-items-start justify-content-end'
                                color='primary'
                                size='medium-plus'
                              />
                            </div>
                          </a>
                        </div>
                      )
                    }
                  })
                : null}
              {docsAndExamsData?.length === 0 && examsToShow?.length === 0 && (
                <div className='d-flex flex-row w-100'>
                  <NoDocsComponent small />
                </div>
              )}

              {fakesDocsToRender?.length > 0 &&
                fakesDocsToRender.map((x) => (
                  <div className='d-flex justify-content-between mb-5'>
                    <div className='d-flex flex-row'>
                      <FileItem
                        style={{backgroundColor: '#f0f4f8'}}
                        fileText={null}
                        className='align-items-center'
                        size='medium-plus'
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* <PopupSkipScores
          isOpen={messageModalOpen}
          toggle={() => setMessageModalOpen(!messageModalOpen)}
          message={messageModalText}
          plan={scoresToSave[0]}
          canSkip={canSkip}
          confirmActionByProps={() => {
            setTaskData(scoresToSave[0], scoresToSave[0]?.timesSkipped === 2)
            openRegisterScores(() => {
              refetchTestDatePlans()
            })
          }}
          skipAction={() => {
            setTimesSkipped({
              variables: {
                activityTest: {},
                planId: scoresToSave[0]?.id,
              },
            })
          }}
        /> */}
      </TabPane>
    </TabContent>
  )
}

export default DocsSection
