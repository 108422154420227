import {useQuery, gql} from '@apollo/client'

const useAllUsersCounseler = (variables = {}) => {
  return useQuery(
    gql`
      query($type: PaymentCategory!) {
        allUsersCounseler(type: $type) {
          id
          firstName
          lastName
          timezone
          emails {
            address
            verified
          }
          phone
        }
      }
    `,
    {
      variables,
    },
  )
}

export default useAllUsersCounseler
