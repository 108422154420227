import {useApolloClient, gql} from '@apollo/client'
import {useCallback, useState} from 'react'
import validate from 'validate.js'
import isEmpty from 'lodash/isEmpty'

export default (onSuccess, onError) => {
  const client = useApolloClient()
  const [validationErrors, setValidationErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const submit = useCallback(
    (email, whenSuccess, whenError) => {
      setValidationErrors({})
      const validation = validate({email}, {email: {email: true}})
      if (!isEmpty(validation)) {
        setValidationErrors(validation)
        return
      }
      setLoading(true)
      client
        .query({
          query: gql`
            query($email: String!) {
              getByEmail(email: $email) {
                hasFirebaseAuth
                hasLivePrepUser
                email
                firstName
                lastName
                providers
              }
            }
          `,
          variables: {
            email,
          },
        })
        .then(
          (result) => {
            whenSuccess(result.data.getByEmail)
            setLoading(false)
          },
          (error) => {
            whenError(error)
            setLoading(false)
          },
        )
    },
    [client, setLoading],
  )
  return {
    submit: (email) => submit(email, onSuccess, onError),
    loading,
    validationErrors,
  }
}
