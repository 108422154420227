import axios from 'axios'

// Función para completar la información necesaria del College para el modal
const completeUniversityInfo = async (collegeUnitId, infoToObtain = []) => {
  const CollegeURL =
    process.env.COLLEGE_URL || process.env.REACT_APP_COLLEGE_URL
  const CollegeAPI =
    process.env.COLLEGE_API || process.env.REACT_APP_COLLEGE_API
  if (!collegeUnitId) return
  // Convertir el arreglo a una string para pasar por params a la API
  const params = infoToObtain.join('%2C')
  const respuesta = await axios.get(
    `${CollegeURL}/college/info?api_key=${CollegeAPI}&college_unit_ids=${collegeUnitId}&info_ids=${params}`,
  )
  return respuesta?.data?.colleges[0]
}

export default completeUniversityInfo
