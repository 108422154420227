import React, {useMemo} from 'react'
import {Avatar, ListItem} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'

const Student = (props) => {
  const {className, plan} = props
  // nombre del estudiante seleccionado
  const {studentName, activeTab} = useMemo(() => {
    if (plan?.studentUser)
      return {
        studentName: `${plan.studentUser?.firstName} ${plan.studentUser?.lastName}`,
        activeTab: 'selected',
      }
    return {
      studentName: `No defined`,
      activeTab: 'no-selected',
    }
    // eslint-disable-next-line
    }, [plan?.studentUser])
  return (
    <div className={`m-0 p-0 d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title pb-3'>Student</span>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='selected'>
          <ListItem
            className='py-1 appears-animation'
            id='x-0'
            label={null}
            Left={
              <div className='d-flex align-items-center'>
                <Avatar
                  name={studentName}
                  named={false}
                  uri={null}
                  size='avatar-medium'
                />
                <label className='ml-2 h4 text-info'>{studentName}</label>
              </div>
            }
            Right={null}
          />
        </TabPane>
        <TabPane tabId='no-selected'>
          <span className='btn-link'>Select or create new student</span>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default Student
