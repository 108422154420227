import isEqual from 'lodash/isEqual'

/**
 * Receives a function and params
 * Returns a hooks which runs the function passing an object with
 * params and input
 *
 * (func, params) => (input, lifespan) => result of calling func({params, input})
 */
export default function (func, params) {
  const caches = []

  const setInvalidateCache = (cache, lifespan = 0) => {
    if (lifespan > 0) {
      setTimeout(() => {
        const index = caches.indexOf(cache)
        if (index !== -1) {
          caches.splice(index, 1)
        }
      }, lifespan)
    }
  }
  const getCache = (input) => caches.find((c) => isEqual(input, c.input))

  return (input, lifespan) => {
    let cache = getCache(input)

    if (cache) {
      if (Object.prototype.hasOwnProperty.call(cache, 'error')) {
        // eslint-disable-next-line
        console.error('And error has ocurred on a suspense hook')
        // eslint-disable-next-line
        console.error(cache.error)
        throw cache.error
      }

      // If a response was successful, return it.
      if (Object.prototype.hasOwnProperty.call(cache, 'response')) {
        return cache.response
      }

      throw cache.promise
    }

    cache = {
      promise: func({params, input})
        .then((response) => {
          cache.response = response
        })
        .catch((e) => {
          cache.error = e
        })
        .then(() => {
          setInvalidateCache(cache, lifespan)
        }),
      input,
    }

    caches.push(cache)

    throw cache.promise
  }
}
