import moment from 'moment-timezone'
export const parseDataToModalNewLesson = (dataLesson) => {
  /**
    dataLesson:
      PlanCreated: "2021-02-17T16:27:14.118Z"
      PlanUpdate: "2021-02-17T16:27:14.118Z"
      educator: {__typename: "LessonEducator", firstName: "Mercedes", lastName: "Hernandez", rate: 0}
      endDate: "2021-03-03T11:50:00.000Z"
      id: "602d43e1d90edb05d1b1c135"
      service: {__typename: "SimpleService", id: "5f88571de3cc10244852476c", name: "SAT"}
      startDate: "2021-03-03T11:00:00.000Z"
      status: "pending"
      studentPlan: {__typename: "LessonStudent", firstName: "Daniel", lastName: "Admin", rate: 10}
      title: "Geometría"
      __typename: "PlanLesson
    **/
  const timeEnd = dataLesson?.endDate
    ? moment(dataLesson.endDate).format('HH:mm')
    : ''
  const timeStart = dataLesson?.startDate
    ? moment(dataLesson.startDate).format('HH:mm')
    : ''

  const student = dataLesson?.studentPlan || dataLesson?.student || {}
  const educator = dataLesson?.substitute || dataLesson?.educator || {}

  const lessonConfig = dataLesson?.lessonConfig || {}
  return {
    typeLesson:
      dataLesson.__typename === 'GroupLesson' ? 'group' : 'individual',
    // isEducatorRate: false,
    educatorRate: educator?.rate,
    // isStudentRate: false,
    studentRate: student?.rate,
    lockRes: false,
    paymentDate:
      moment(lessonConfig?.paymentFor).format('YYYY-MM-DD') || '2021-03-04',
    repeat: true,
    repeatmonth: false,
    repeatweek: false,
    setPending: false,
    date: dataLesson?.startDate ? dataLesson?.startDate.split('T')[0] : '',
    timeStart,
    timeEnd,
    title: dataLesson?.title || '',
    waiveEducatorRate: educator?.ignorePaymentItem || false,
    waiveStudentRate: student?.ignorePaymentItem || false,
    educator: educator || {},
    student:
      dataLesson?.student ||
      dataLesson?.studentPlan ||
      dataLesson?.studentGroup ||
      {},
    service: dataLesson?.service || {},
    topic:
      dataLesson?.individualTopic ||
      dataLesson?.planTopic ||
      dataLesson?.groupTopic ||
      {},
    locationLesson: dataLesson?.location?.type || null,
    locationLessonLink:
      dataLesson?.location?.linkStudent ||
      dataLesson?.location?.linkEducator ||
      null,
  }
}

export const parseToMutation = (formData) => {
  const date =
    formData.date && formData?.timeStart
      ? moment(formData.date).set({
          hour: parseInt(formData?.timeStart.split(':')[0]),
          minute: parseInt(formData?.timeStart.split(':')[1]),
          second: 0,
          millisecond: 0,
        })
      : moment()

  const endDate =
    formData.date && formData?.timeEnd
      ? moment(formData?.date).set({
          hour: parseInt(formData?.timeEnd.split(':')[0]),
          minute: parseInt(formData?.timeEnd.split(':')[1]),
          second: 0,
          millisecond: 0,
        })
      : moment()

  const student = Array.isArray(formData?.student)
    ? formData?.student.length > 0
      ? formData.typeLesson === 'group'
        ? formData?.student.map((stu) => {
            return stu.id || stu.studentUserId
          })
        : [formData?.student[0].id || formData?.student[0].studentUserId]
      : []
    : formData?.student?.id || formData?.student?.studentUserId
    ? [formData.student.id || formData.student.studentUserId]
    : []

  // cantidad total de lecciones pendientes: formData.totalLessonPending
  return {
    serviceId: formData?.service?.id,
    topicId: formData?.topic?.id,
    students: student,
    educatorUserId:
      formData?.educator?.id || formData?.educator?.educatorUserId,
    title: formData?.title,
    date: date,
    startTime: formData?.timeStart,
    endTime: formData?.timeEnd,
    lessonDuration: moment.duration(moment(endDate).diff(date)).asMinutes(),
    commentForEducator: formData?.commentForEducator || '', // String!,
    location: {
      type: formData?.locationLesson || '', // TypeLink!
      linkStudent:
        formData?.locationLessonLink || formData?.locationPlanLink || null,
      linkEducator:
        formData?.locationLessonLink || formData?.locationPlanLink || null,
    }, // LocationLessonInput!,
    paymentFor: formData?.paymentDate
      ? moment(formData.paymentDate).toISOString()
      : moment().toISOString(), // DateTime!, para cuando quieres poner el payment de esa lesson especifica
    ignorePaymentToStudent: formData?.waiveStudentRate || false, // Boolean!,
    ignorePaymentToEducator: formData?.waiveEducatorRate || false, // Boolean!,educator.ignorePaymentItem

    // isEducatorRate: formData?.isEducatorRate || false, // Boolean!,educator.isManualRate
    educatorRate: formData?.educatorRate || 0,
    // isStudentRate: formData?.isStudentRate || false, // Boolean!,student.isManualRate
    studentRate: formData?.studentRate || 0,
    ignoreEducatorUnavailability: false, // Boolean!,educator.ignoreAvailabilty

    lockLesson: formData?.lockRes || false,
    setToPending: formData?.setPending || false,
    lessonsPending: formData?.totalLessonPending || 0,

    isRepeat: !formData?.repeat || false,
    isRepeatByWeek: formData?.repeatweek || false,
    isRepeatMonthly: formData?.repeatmonth || false,
    endDateRepeat: formData?.repeatuntil
      ? moment(formData.repeatuntil).toISOString()
      : moment().toISOString(),

    isCurrentAndFuture: formData?.future || false,
  }
}

export const parseOcurrencesToMutationMakeUp = (
  dataCalendar,
  originalDataMutation,
) => {
  /**
     *  collissionKinds: ["studentLesson"]
        dataEducator:
          id: "5f465f0165a1ce1146a70f7e"
          name: "Meneses Benjamín"
          __proto__: Object
        dataStudent: Array(1)
          0:
            id: "5f92deac586824186078a36c"
            name: "Daniel Admin"
            __proto__: Object
          length: 1
          __proto__: Array(0)
        indexOcurrence: 0
        isOpen: true
        ocurrences: Array(1)
          0:
            new: "2021-05-07T22:00:00.000Z"
            old: "2021-05-06T22:00:00.000Z"
            __proto__: Object
          length: 1
          __proto__: Array(0)
        topic:
          id: "608744ae3f496405ffea2266"
          name: "Spanish"
          __typename: "SimpleTopic"
        __proto__: Object
      __proto__: Object
   * **/
  const lesson = dataCalendar?.ocurrences[0]
  const date = moment(lesson.new).set({
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  originalDataMutation.makeUpInput.date = date
  originalDataMutation.makeUpInput.startTime = date.clone().format('HH:mm')
  originalDataMutation.makeUpInput.endTime = date
    .clone()
    .add(lesson.lessonDuration, 'minutes')
    .format('HH:mm')
  return originalDataMutation
}

export const parseOcurrencesToMutationRescheduled = (
  dataCalendar,
  originalDataMutation,
) => {
  return {
    ...originalDataMutation,
    startDate: dataCalendar.ocurrences[0].new,
  }
}

export const parseOcurrencesToMutationNewIndividualLesson = (
  dataCalendar,
  originalDataMutation,
) => {
  // parsea data enviada por el calendario de incidencias a la mutación scheduleNewLessonFromStudent
  // cuando solo se agenda UNA lección
  const lesson = dataCalendar.ocurrences[0]
  const lessonTime = moment(lesson.new)
  return {
    lessonInput: {
      ...originalDataMutation.lessonInput,
      date: lessonTime.clone(),
      startTime: lessonTime.clone().format('HH:mm'),
      endTime: lessonTime
        .clone()
        .add(parseInt(lesson.lessonDuration), 'minutes')
        .format('HH:mm'),
    },
  }
}

export const parseOcurrencesToMutationNewGroupLesson = (
  dataCalendar,
  originalDataMutation,
) => {
  return originalDataMutation
}

export const parseOcurrencesToCalendar = (ocurrences, callBack) => {
  return {
    ocurrences: ocurrences.map((ocu) => {
      return {
        old: ocu.from,
        new: null,
        resolve: null,
        lessonDuration: Math.abs(
          moment.duration(moment(ocu.from).diff(ocu.to)).asMinutes(),
        ),
        collissionKinds: ocu.collissionKinds,
        topic: ocu.lessonOriginal.topic,
        dataStudent: ocu.lessonOriginal.students.map((stu) => {
          return {
            id: stu.studentUserId,
            name: `${stu.firstName} ${stu.lastName}`,
          }
        }),
        dataEducator: {
          id: ocu.educatorUserId,
          name: `${ocu.educatorFirstName} ${ocu.educatorLastName}`,
        },
        recurringId: ocu.recurringId,
        ocurrenceId: ocu.ocurrenceId,
        isGroup: ocu.isGroup,
      }
    }),
    indexOcurrence: 0,
    callBack: callBack,
  }
}

export const parseOcurrencesToSolve = (dataCalendar, dataMutation) => {
  /**
   * recurringId: String!
   * solveResolution: {
        ocurrenceId: String!
        from: DateTime!
        to: DateTime!
        type: ResolutionType!
     }
   * **/
  const recurringId = dataCalendar.ocurrences[0].recurringId
  return {
    recurringId,
    solveResolution: dataCalendar.ocurrences.map((ocu) => {
      return {
        ocurrenceId: ocu.ocurrenceId,
        from: ocu.new,
        to: moment(ocu.new).add(ocu.lessonDuration, 'minutes').toISOString(),
        type: ocu.resolve,
        /**
         *  newBlock: nuevo horario
         *  ignore: ignorar
         *  force: Agendar de todos modos
         *  setPending: dejar en pending
         * **/
      }
    }),
  }
}

export const closeCalendarConflict = (dispatch) => {
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'lesson.scheduledConflict',
    data: {
      from: moment(),
      ocurrences: [],
      indexOcurrence: 0,
      callBack: () => {},
      isOpen: false,
      loading: false,
    },
  })
}
export const callBackForConflict = (
  callBack,
  dataCalendarConflict,
  dispatch,
) => {
  // CIERRA AGENDAMIENTO DE INCIDENCIAS
  dispatch({
    type: 'SET_DATA_MODAL_FORM_LIST',
    payload: 'lesson.scheduledConflict',
    data: {
      ...dataCalendarConflict,
      loading: true,
    },
  })
  callBack()
}

export const parseOcurrencesToMutationEditLesson = (
  dataCalendar,
  originalDataMutation,
) => {
  const lesson = dataCalendar.ocurrences[0]
  const lessonTime = moment(lesson.new)
  return {
    ...originalDataMutation,
    lessonInput: {
      ...originalDataMutation.lessonInput,
      date: lessonTime.clone(),
      startTime: lessonTime.clone().format('HH:mm'),
      endTime: lessonTime
        .clone()
        .add(parseInt(lesson.lessonDuration), 'minutes')
        .format('HH:mm'),
    },
  }
}

export const removeSpaces = (link = '') => {
  return link.split(' ').join('')
}
export const hasHttp = (parseLink) => {
  const link = removeSpaces(parseLink)
  // link incluye "http://" o "https://" al inicio
  if (
    link?.substring(0, 7) === 'http://' ||
    link?.substring(0, 8) === 'https://'
  )
    return link
  else return `https://${link}` // sino los tiene se concatena "https://" al inicio del link
}

export default {
  parseDataToModalNewLesson,
  parseToMutation,
  parseOcurrencesToCalendar,
  parseOcurrencesToMutationMakeUp,
  parseOcurrencesToMutationNewIndividualLesson,
  parseOcurrencesToMutationNewGroupLesson,
  parseOcurrencesToSolve,
  closeCalendarConflict,
  callBackForConflict,
  parseOcurrencesToMutationEditLesson,
  hasHttp,
}
