import React from 'react'
import {
  ListItem,
  IconProgram,
  IconTopic,
  Avatar,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import {gql, useMutation} from '@apollo/client'
import {showAlert} from '../../../../../../common/Alert/util'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'
import RescheduleCounselor from '../../../../../../Model/Lessons/RescheduleCounselor'

const ConfirmSelection = (props) => {
  const {selectData, onChange, modalData, refetchLessons, educator = {}} = props
  const {setOpenInfoModal} = modalData
  const isRecurring = !!modalData?.isRecurring
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const ocurrenceResultQuery = `
  educatorUserId
  educatorFirstName
  educatorLastName
  topicId
  serviceId
  from
  to
  lessonName
  recurringId
  ocurrenceId
  collissionKinds
  lessonOriginal {
    originalLessonId
    startDate
    endDate
    students {
      studentUserId
      planId
      firstName
      lastName
      rate
      attendanceStatus
      isManualRate
      ignoreAvailabilty
      whatsappAlertSent
      wasLate
      rating
      ignorePaymentItem
    }
    topic {
      id
      name
    }
    service {
      id
      name
    }
  }
`
  const [mutation, {loading}] = useMutation(
    gql`
      mutation update($currentAndFuture: Boolean = false, $startDate: DateTime!, $lessonId: String!) {
        reScheduleLessonFromStudent (
          startDate: $startDate
          lessonId: $lessonId
          currentAndFuture: $currentAndFuture
        ) {
          ... on OcurrenceResult {
            hasOccurrences
            occurrencesList {
              ${ocurrenceResultQuery}
            }
          }
          ... on ScheduleError {
            errorKey
            message
            errorLessons {
              ${ocurrenceResultQuery}
            }
          }
          ... on ScheduleSuccess {
            newLessons {
              ${queryLessonResult}
            }
          }
        }
      }
    `,
    {
      update: (cache, datos) => {
        refetchLessons(modalData?.lesson?.lessonId)
        onChange((val) => ({...val, isOpen: false}))
        // cierra modal de la lección reagendada
        if (setOpenInfoModal) setOpenInfoModal(false)
      },
    },
  )

  // verifica si el servicio de la lección es orientación
  const services = useSelector((state) => state.services.services)
  const orientation = Object.values(services).find((serv) => serv.isOrientation)
  const isOrientation = orientation?.id === modalData?.lesson?.service?.id

  const onSuccessMutation = (e) => {
    if (e.data.reScheduleLessonFromStudent.errorKey) {
      if (
        e.data.reScheduleLessonFromStudent?.errorKey ===
        'recurringRescheduledError'
      ) {
        const errorLessons = e?.data?.reScheduleLessonFromStudent?.errorLessons
        showAlert(
          {
            text:
              modalData?.lesson?.status === 'pending' ? (
                `${
                  errorLessons?.length || 0
                } lessons could not rescheduled and are still on pending status`
              ) : (
                <div className='d-flex flex-column'>
                  <span>
                    Successfully rescheduled, the following lessons could not be
                    rescheduled:
                  </span>
                  {(errorLessons || [])?.map((ocu) => {
                    const start = ocu?.lessonOriginal?.startDate
                    return (
                      <span className='ml-2'>
                        {start
                          ? moment(start).format(`dddd Do MMMM YYYY, h:mm a`)
                          : 'No defined'}
                      </span>
                    )
                  })}
                </div>
              ),
            status: 'error',
          },
          dispatch,
        )
        /**
         * 
         * <div className={'d-flex flex-column'}>
          <label>Reasgendado con exito, las siguientes lecciones no se lograron reagendar:</label>
          <label className={'ml-1'}>Lunes 23 noviembre, 2022</label>
          <label className={'ml-1'}>Lunes 23 noviembre, 2022</label>
          <label className={'ml-1'}>Lunes 23 noviembre, 2022</label>
        </div>
         * **/
      } else {
        showAlert(
          {
            text: translate(e.data.reScheduleLessonFromStudent.errorKey),
            status: 'error',
          },
          dispatch,
        )
      }
      return
    }
    refetchLessons()
    showAlert(
      {
        text: translate('lessons.modal.rescheduledpending.successAlert'),
        status: 'success',
      },
      dispatch,
    )
  }

  const onErrorMutation = () => {
    showAlert(
      {
        text: translate('lessons.modal.rescheduledpending.errorAlert'),
        status: 'error',
      },
      dispatch,
    )
  }

  const {
    loading: loadOrientationMutation,
    submit: rescheduledOrientation,
  } = RescheduleCounselor(
    () => {
      refetchLessons()
      onChange((val) => ({...val, isOpen: false}))
      // cierra modal de la lección reagendada
      if (setOpenInfoModal) setOpenInfoModal(false)
      // muestra la alerta
      onSuccessMutation()
    },
    () => {
      onErrorMutation()
    },
  )

  return (
    <div className='m-0 py-0 px-4'>
      {/* <span>{`ID: ${modalData?.lesson?.lessonId}`}</span> */}
      <ListItem
        className='pl-0'
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconProgram className='znk-icon-service' />
            <span className='ml-4'>
              {selectData.time.id !== 0 &&
                ` ${moment(selectData.time.time).format('h:mma')} - ${moment(
                  selectData.time.time,
                )
                  .add(isOrientation ? 15 : 50, 'm')
                  .format('h:mma')}
                               | ${moment(selectData.time.time).format(
                                 'MMM Do, YYYY',
                               )}`}
            </span>
          </div>
        }
      />
      <ListItem
        showTopLine
        showBottomLine
        className='pl-0'
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconTopic />
            <span className='ml-4'>{`${modalData?.lesson?.name}`}</span>
          </div>
        }
      />
      <ListItem
        showBottomLine
        className='pl-0'
        Left={
          <div className='d-flex align-items-center text-muted'>
            <Avatar
              name={`${educator?.firstName} ${educator?.lastName}`}
              named={false}
              uri={educator?.avatar || null}
              size='avatar-medium'
            />
            <span className='ml-2'>{`${educator?.firstName} ${educator?.lastName}`}</span>
          </div>
        }
      />
      <div className='m-0 p-0 mr-4 my-4'>
        <Button
          className='w-100 m-0'
          color='primary'
          label={translate('lessons.scheduledlesson')}
          activity={loading || loadOrientationMutation}
          check={!loading && !loadOrientationMutation}
          onClick={() => {
            // Ejecutar mutation
            if (isOrientation) {
              rescheduledOrientation({
                changeCouncelorInput: {
                  startDate: moment(selectData?.time?.time).toISOString(),
                  endDate: moment(selectData?.time?.time)
                    .add(15, 'minutes')
                    .toISOString(),
                  councelorId: modalData?.educator?.educatorUserId,
                  studentId: modalData?.plan?.studentUserId,
                  lessonId: modalData?.lesson?.lessonId,
                },
              })
            } else {
              mutation({
                variables: {
                  startDate: moment(selectData?.time?.time).toISOString(),
                  lessonId: modalData?.lesson?.lessonId,
                  currentAndFuture: isRecurring,
                },
              }).then(onSuccessMutation, onErrorMutation)
            }
          }}
        />
      </div>
    </div>
  )
}

export default ConfirmSelection
