import React, {Fragment} from 'react'
import Skeleton from 'react-loading-skeleton'
import * as moment from 'moment'
import PropTypes from 'prop-types'
import useTranslation from '../../../../i18n/useTranslation'
import InfoModal from '../Modal/InfoLesson'

const propTypes = {
  lessonsData: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  startWeek: PropTypes.bool,
}
const defaultProps = {
  lessonsData: [],
  loading: true,
  error: false,
  startWeek: false,
}
const LessonsWeek = (props) => {
  const {translate} = useTranslation()
  const {lessonsData: lessons, loading, refetchLessons} = props

  let momentAux = moment()
  return (
    <>
      {loading ? (
        <Skeleton count={4} height={40} />
      ) : lessons.length > 0 ? (
        lessons.map((item, idx) => {
          const lessonTime = moment(item.startDate)
          if (idx === 0) momentAux = lessonTime
          if (idx === 0 || lessonTime.day() !== momentAux.day()) {
            // nuevo día
            momentAux = lessonTime
            return (
              <Fragment key={`lessonsWeek${idx}`}>
                <div className='m-0 p-0 mt-1'>
                  {idx !== 0 ? <hr className='my-2' /> : null}
                  <span className='text-uppercase font-weight-light text-gray mt-2 mb-1 h5'>
                    {lessonTime.clone().dayOfYear() === moment().dayOfYear()
                      ? `${translate('lessons.today')}`
                      : null}
                    {`${lessonTime.format('dddd')} ${lessonTime.format('Do')}`}
                  </span>
                </div>
                <InfoModal
                  refetchLessons={refetchLessons}
                  disabledModal={false}
                  dataCard={
                    item.status === 'proposed'
                      ? {...item, status: 'attended'}
                      : item
                  }
                />
              </Fragment>
            )
          }
          return (
            <InfoModal
              refetchLessons={refetchLessons}
              dataCard={
                item.status === 'proposed'
                  ? {...item, status: 'attended'}
                  : item
              }
              key={`lessonsWeek${idx}`}
              disabledModal={false}
            />
          )
        })
      ) : (
        <div className='my-4 p-0 text-center'>
          <span className='w-100 text-center my-6 py-2'>
            {translate('program.calendar.noFound')}
          </span>
        </div>
      )}
    </>
  )
}
LessonsWeek.propTypes = propTypes
LessonsWeek.defaultProps = defaultProps
export default LessonsWeek
