import {useLazyQuery, gql} from '@apollo/client'
import taskQueryResult from '../../../QueryResult/useUserTaskQueryResult'
import queryUserHashResult from '../../../QueryResult/useUserHashResult'
import useMyCounselingPlan from '../../../QueryResult/useMyCounselingPlan'

const useLazyGetTask = (onCompleted = () => {}, onError = () => {}) => {
  return useLazyQuery(
    gql`query($taskId: ID!)
            {
            getTask(taskId: $taskId) {
                planId
                taskId
                phaseId
                stepId
                newStatus
                task {
                    ${taskQueryResult}
                }
                user {
                    ${queryUserHashResult}
                }
                dataPlan {
                    ${useMyCounselingPlan}
                }
            }
        }`,
    {
      onCompleted,
      onError,
    },
  )
}

export default useLazyGetTask
