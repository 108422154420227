import React, {useEffect} from 'react'
import {
  Button,
  Activity,
  IconBell,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import {useLazyQuery, gql} from '@apollo/client'
import {hasHttp} from '../../../utils'
import useTranslation from '../../../../../../i18n/useTranslation'

const BackupLinkButton = (props) => {
  const {
    className,
    educatorId,
    backupPopupImplementation = false,
    clickButton = () => {},
  } = props
  const {translate} = useTranslation()
  const [getBackUpLink, {data, loading, error}] = useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          educatorInfo {
            backupLink
          }
          staffInfo {
            backupLink
          }
        }
      }
    `,
    {fetchPolicy: 'no-cache'},
  )
  useEffect(() => {
    const backup = data?.user?.educatorInfo?.backupLink || data?.user?.staffInfo?.backupLink
    if (backup) {
      window.open(hasHttp(backup), '_blank')
    }
  }, [data])
  useEffect(() => {
    console.error(error)
  }, [error])
  return (
    <>
      <Button
        style={
          backupPopupImplementation
            ? {
                backgroundColor: '#72809d',
                borderRadius: '16px 0px 0px 16px',
                width: '40%',
              }
            : {backgroundColor: '#72809d'}
        }
        className={backupPopupImplementation ? 'w-40' : className}
        size='large'
        onClick={() => {
          getBackUpLink({variables: {id: educatorId}})
        }}
        label={
          loading ? (
            <div className='w-100 d-flex flex-row justify-content-center'>
              <Activity inverse />
            </div>
          ) : (
            <span className='text-center'>
              {translate('modal.infolesson.backupcall')}
            </span>
          )
        }
      />
      {backupPopupImplementation && (
        <ToolTipInfo
          tooltipId='universities-names'
          disable={false}
          innerElement={
            <div
            // className='d-flex flex-column m-0 pt-2 pl-1'
            >
              <span>{translate('modal.infolesson.backupcallExplanation')}</span>
            </div>
          }
          place='bottom'
        >
          <div
            className='h-100'
            // className='d-flex flex-row align-items-center m-0 p-0'
          >
            <Button
              style={{
                backgroundColor: '#72809d',
                borderRadius: '0px 16px 16px 0px',
                marginLeft: '1px',
                width: '80%',
              }}
              size='large'
              className='h-100 d-flex flex-row justify-content-center align-items-center'
              onClick={() => {
                clickButton()
              }}
              label={<IconBell color='white' size={30} />}
            />
            {/* {showLabel ? (
                universitiesToShow?.length === 1 ? (
                  <span className='h45 ml-2 text-info'>{`${universitiesList[0].name}`}</span>
                ) : universitiesToShow?.length === 0 ? (
                  <span className='h45 ml-2 text-info'>Universities</span>
                ) : (
                  <span className='h45 ml-2 text-info'>{`${universitiesList?.length} Universities`}</span>
                )
              ) : null} */}
          </div>
        </ToolTipInfo>
      )}
    </>
  )
}

export default BackupLinkButton
