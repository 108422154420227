import React from 'react'
import {
  IconProgram,
  IconProfile,
  IconLearn,
  IconStart,
} from '@Knowledge-OTP/znk-ui-components'
const iconSize = 20
const navItemClassName = 'm-2' // 'mr-1'
const navLinkClassName = 'p-0' // 'pt-4 pb-1'
export const items = [
  {
    href: '/home',
    Body: <IconStart size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/calendar/calendar-view',
    Body: <IconProgram size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/learn',
    Body: <IconLearn size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/profile',
    Body: <IconProfile size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
]

export const itemsMobile = [
  {
    href: '/home',
    Body: <IconStart size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/calendar',
    Body: <IconProgram size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/learn',
    Body: <IconLearn size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
  {
    href: '/profile',
    Body: <IconProfile size={iconSize} />,
    linkClass: navLinkClassName,
    itemClass: navItemClassName,
  },
]
