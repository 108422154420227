import {gql, useQuery} from '@apollo/client'
import queryLessonResult from '../../../QueryResult/useLessonResult'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return {loading: false, error: errors, data: []}
  }

  const {data, error, loading, refetch} = useQuery(
    gql`query(
            $kinds: [String!]
            $userId: ID
            $from: DateTime!
            $to: DateTime!
          ) {
      calendarView(
          kinds: $kinds
          userId: $userId,
          from: $from,
          to: $to
      ) {  
          ${queryLessonResult}
          ... on Counselor {
            id
            startDate
            endDate
            counselorId
            CounselorCreated : createdAt
            CounselorUpdate : updatedAt
          }
          ... on EducatorUnavailability {
            id
            kind
            startDate
            endDate
            busy
            educatorId
            userIdsInvolved
          }
      }
    }`,
    {
      variables: vars,
    },
  )
  return {loading, data: data ? data.calendarView : [], error, refetch}
}
