import React, {useEffect} from 'react'
import {
  Modal,
  useWindowDimensions,
  IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import ProgressBar from '../components/ProgressBar'
import {TabContent, TabPane} from 'reactstrap'
import {
  IntroductionPartHeader,
  IntroductionPartBody,
} from './inner/IntroductionPart'
import {
  IntroductionTopicHeader,
  IntroductionTopicBody,
} from './inner/IntroductionTopic'
import {SelectLpwHeader, SelectLpwBody} from './inner/SelectLpw'
import {ScheduledHeader, ScheduledBody} from './inner/Scheduled'
import {
  ScheduledOrientationHeader,
  ScheduledOrientationBody,
} from './inner/ScheduledOrientation'
import {ScheduledOrientationCalendarBody} from './inner/ScheduledOrientationCalendar'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'
import '../style.css'
import useUpdateOnboardingStatus from '../Model/useUpdateOnboardingStatus'

const SmallModal = (props) => {
  const {isAdmin} = props
  const {breakWidth} = useWindowDimensions()

  const dispatch = useDispatch()
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const {isOpen, page} = reduxSmall
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.smallModal',
      data: {...reduxSmall, isOpen: !isOpen},
    })
  }
  const setPage = (newPage) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.smallModal',
      data: {...reduxSmall, page: newPage},
    })
  }

  useEffect(() => {
    setPage('introductionPart')
    // eslint-disable-next-line
  }, [])

  const {scheduledTimes} = useSelector((state) => state.modal.onboarding.data)
  const arrayScheduled = scheduledTimes
    ? Object.entries(scheduledTimes).reduce(
        (arrayProgress, [__, objTopic]) => arrayProgress.concat(objTopic.times),
        [],
      )
    : []

  const [updateOnboarding] = useUpdateOnboardingStatus(
    () => {},
    () => {},
  )
  const updateOnboardingStatus = async (planId, newStatus = 'selecting') => {
    await updateOnboarding({
      variables: {planId, newStatus},
    })
  }
  useEffect(() => {
    if (isOpen) {
      let newStatus = 'selecting'
      if (
        [
          'introductionPart',
          'introductionTopic',
          'selectLpw',
          'scheduled',
        ].includes(page)
      )
        newStatus = 'selecting'
      else if (
        ['scheduledOrientation', 'scheduledOrientationCalendar'].includes(page)
      )
        newStatus = 'orientation'
      updateOnboardingStatus(
        dataPlan?.idPlan || dataPlan?._id || dataPlan?.id || dataPlan?.planId,
        newStatus,
      ).then(() => {}, console.error)
    }
    // eslint-disable-next-line
  }, [isOpen, page])  

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-colored modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored white-modal`}
        isOpen={isOpen}
        backdrop={[false, 'static']}
        toggle={toggle}
        Head={
          <div
            className={`${
              breakWidth === 'SM' ? 'm-0 pb-3 pt-0 pl-3' : 'm-0 pb-0 pt-0 px-0'
            } d-flex flex-column`}
            style={{
              borderRadius: '7px 7px 0 0',
            }}
          >
            <ProgressBar arrayScheduled={arrayScheduled} />
            <IconClose
              style={{zIndex: 10}}
              onClick={toggle}
              className='modal-close-absolute'
            />
            <TabContent activeTab={page} className='px-6 pt-3'>
              <TabPane tabId='introductionPart'>
                <IntroductionPartHeader />
              </TabPane>
              <TabPane tabId='introductionTopic'>
                <IntroductionTopicHeader />
              </TabPane>
              <TabPane tabId='selectLpw'>
                <SelectLpwHeader page={page} />
              </TabPane>
              <TabPane tabId='scheduled'>
                <ScheduledHeader />
              </TabPane>
              <TabPane tabId='scheduledOrientation'>
                <ScheduledOrientationHeader />
              </TabPane>
              <TabPane tabId='scheduledOrientationCalendar'>
                <ScheduledOrientationHeader />
              </TabPane>
            </TabContent>
          </div>
        }
      >
        <div
          className={`${
            breakWidth === 'SM' ? 'm-0 py-3 pl-3' : 'm-0 py-3 px-5'
          }`}
        >
          <TabContent activeTab={page} className='px-2'>
            <TabPane tabId='introductionPart'>
              <IntroductionPartBody setPage={setPage} />
            </TabPane>
            <TabPane tabId='introductionTopic'>
              <IntroductionTopicBody setPage={setPage} />
            </TabPane>
            <TabPane tabId='selectLpw'>
              <SelectLpwBody setPage={setPage} />
            </TabPane>
            <TabPane tabId='scheduled'>
              <ScheduledBody setPage={setPage} page={page} isAdmin={isAdmin} />
            </TabPane>
            <TabPane tabId='scheduledOrientation'>
              <ScheduledOrientationBody setPage={setPage} isAdmin={isAdmin} />
            </TabPane>
            <TabPane tabId='scheduledOrientationCalendar'>
              <ScheduledOrientationCalendarBody setPage={setPage} />
            </TabPane>
          </TabContent>
        </div>
      </Modal>
    </>
  )
}

export default SmallModal
