import educatorNotesResult from "./useEducatorNotes"
const queryLessonResult = `
    ... on IndividualLesson {
        id
        status
        title
        isMakeUp
        isMeeting
        recurringId
        recordingUrl
        backupLink
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        individualTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        topic {
          id
          name
        }
        student {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        startDate
        endDate
        IndividualCreated : createdAt
        IndividualUpdate : updatedAt
        commentForEducator
        isLocked
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }
        aditionalInfo {
          studentAvatar
          educatorAvatar
          substituteAvatar
        }
        invokeAdminInfo {
          invokeUserId
          invokeDate
        }
      }
    ... on PlanLesson {
        id
        status
        title
        startDate
        endDate
        isMakeUp
        recurringId
        recordingUrl
        backupLink
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        planTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        topic {
          id
          name
        }
        studentPlan : student {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        PlanCreated : createdAt
        PlanUpdate : updatedAt
        commentForEducator
        isLocked
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }
        aditionalInfo {
          studentAvatar
          educatorAvatar
          substituteAvatar
        }
        invokeAdminInfo {
          invokeUserId
          invokeDate
        }
    }
    ... on GroupLesson {
        id
        title
        status
        startDate
        endDate
        isMakeUp
        recordingUrl
        recurringId
        recordingUrl
        backupLink
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        groupTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        topic {
          id
          name
        }
        studentGroup : students {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        GroupCreated : createdAt
        GroupUpdate : updatedAt
        commentForEducator
        isLocked
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }
        aditionalInfo {
          studentsAvatar {
            studentUserId
            avatar
          }
          educatorAvatar
          substituteAvatar
        }
        invokeAdminInfo {
          invokeUserId
          invokeDate
        }
    }
    `

export default queryLessonResult
