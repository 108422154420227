import React from 'react'
import {
  StickyButton,
  LessonSelectCalendar,
  ListItem,
  CheckboxOption,
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'
import useFreeTimeFrames from '../../CopyLesson/TimeSelection/myFreeTimeFrames/index'
import SetPendingLesson from '../../SetPendingLesson'

const TimeSelectionCalendar = (props) => {
  const {
    lessonId,
    limitFrom,
    limitTo,
    // from,
    to,
    now,
    setNow,
    idUser,
    selectedBlock,
    setSelectedBlock,
    onChange,
    selectData,
    setSelectData,
    refetchLessons,
    toggle,
    modalData,
  } = props
  const {translate} = useTranslation()
  const {data, loading} = useFreeTimeFrames({
    educatorId: idUser,
    from: now.clone().toISOString(),
    to,
    minDurationInMinutes: 50,
    involveMyself: true,
  })
  const goToConfirm = () =>
    onChange((val) => ({...val, stage: 'confirmSelection'}))
  // calendario presenta bloques libres solo 24 horas despues de la hora original de la lección

  const setRescheduledRecurring = (bool) => {
    onChange((val) => ({
      ...val,
      isRecurring: bool,
      //, stage: 'timeSelectionCalendar'
    }))
  }

  const isRecurring = !!modalData?.isRecurring
  const isPending = modalData?.lesson?.status === 'pending'

  return (
    <>
      <LessonSelectCalendar
        className='mx-0'
        moment={now}
        setMoment={setNow}
        availabilityBlocks={data}
        loading={loading}
        selectedBlock={selectedBlock}
        setSelected={(newBlock) => {
          setSelectedBlock(newBlock)
          setSelectData({
            ...selectData,
            time: {
              time: newBlock.date,
              id: newBlock.id,
            },
          })
        }}
        limitFrom={limitFrom}
        limitTo={limitTo}
      />
      <div className='my-2 mx-5 d-flex flex-row align-items-center justify-content-start'>
        <CheckboxOption
          onChange={(e) => {
            setRescheduledRecurring(e)
          }}
          defaultState={isRecurring}
          value={isRecurring}
          className='mr-1'
        />
        <span
          className='h45 text-gray cursor-pointer'
          onClick={() => {
            setRescheduledRecurring(!isRecurring)
          }}
        >
          Apply to current & future lessons
        </span>
      </div>
      <Collapse isOpen={isRecurring && isPending}>
        <div className='d-flex flex-row m-0 p-0 justify-content-end'>
          <span className='h45 text-error cursor-pointer'>
            (Only pending lessons will be reschedule)
          </span>
        </div>
      </Collapse>
      <StickyButton
        color='#696CFF'
        condition
        disabled={!selectData?.time?.id}
        className='mx-4 mt-4 mb-0'
        onClickButtonFunction={goToConfirm}
      />
      <SetPendingLesson
        lessonId={lessonId}
        refetchLessons={refetchLessons}
        callback={() => {
          toggle()
        }}
      >
        <ListItem
          className='mx-4'
          Left={
            <div className='d-flex align-items-center text-muted small'>
              {translate(
                'lessons.modal.rescheduledpending.needthislessoninpending',
              )}
            </div>
          }
          Right={<></>}
        />
      </SetPendingLesson>
    </>
  )
}

export default TimeSelectionCalendar
