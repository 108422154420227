import es from './es'
import en from './en'
import approvalModal from './approvalModal'
import infoModal from './infoModal'
import statuses from './statuses'

export default {
  es,
  en,
  statuses,
  approvalModal,
  infoModal,
}
