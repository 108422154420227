import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import IconLoader from '../../../components/LoaderIcon'
import {gql, useMutation} from '@apollo/client'
import {
  parseDataOrToMutation,
  parseDataToMutation,
  makeObjectConflictOnboarding,
} from '../../../utils'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import {showAlert} from '../../../../../common/Alert/util'
import useUpdateOnboardingStatus from '../../../Model/useUpdateOnboardingStatus'

export const SchedulingRecurringHeader = (props) => {
  const {status = null} = props
  const {translate} = useTranslation()
  return (
    <div className='m-0 p-0 my-2'>
      <IconLoader />
      <h2 className='w-100 my-3'>
        <b>{translate('home.onboard.approve.scheduled.giveMe')}</b>
      </h2>
      <h4>{translate('home.onboard.approve.scheduled.someReason')}</h4>

      <div className='mt-5 w-100 d-flex flex-row justify-content-end'>
        <span className='text-info'>{status}</span>
      </div>
    </div>
  )
}
export const SchedulingRecurringBody = (props) => {
  const {
    setPage: setPageConflictModal = () => {},
    isOpen = false,
    toggleModal = () => {},
    setStatus = () => {},
    isAdmin,
    setError = () => {},
    error: errorProp = null,
  } = props
  const dispatch = useDispatch()
  const mediumModal = useSelector((state) => state.modal.onboarding.mediumModal)
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )

  const [updateOnboarding] = useUpdateOnboardingStatus(
    () => {},
    (err) => {
      console.error(err.toString())
    },
  )
  const updateOnboardingStatus = async (planId, newStatus = 'scheduling') => {
    await updateOnboarding({
      variables: {planId, newStatus},
    })
  }

  const {page} = conflictModal
  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {dataPlan, scheduledTimes, orientationKey} = reduxData
  const services = useSelector((state) => state.services.services)
  const reduxOnboarding = useSelector((state) => state.modal.onboarding)
  // listado de incidencias
  // const [notscheduled, setNotScheduled] = useState([])
  const {translate} = useTranslation()

  // const reduxOnboarding = useSelector((state) => state.modal.onboarding)
  // const reduxData = useSelector((state) => state.modal.onboarding.data)
  // const {page: mediumPage} = mediumModal
  const smallModal = useSelector((state) => state.modal.onboarding.smallModal)
  // const {scheduledTimes, dataPlan, scheduled, orientationKey} = reduxData
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'

  const rescheduledLesson = (topicKey, indexLesson) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.data',
      data: {...reduxData, keyLesson: topicKey, indexLesson},
      // data: {...reduxData, ...adjustTopicLesson(scheduledTimes, topicKey, indexLesson) },
    })
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.smallModal',
      data: {
        ...smallModal,
        isOpen: true,
        page:
          topicKey === ORIENTATION_KEY ? 'scheduledOrientation' : 'scheduled',
      },
    })
  }

  const actionAfterMutationScheduled = (notscheduled) => {
    if (notscheduled.length > 0) {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'onboarding.data',
        data: {
          ...reduxData,
          ...makeObjectConflictOnboarding(notscheduled, services),
        },
      })
      setPageConflictModal('listConflict')
      // dispatch({
      //   type: SET_DATA_MODAL_FORM_LIST,
      //   payload: 'onboarding.conflictModal',
      //   data: {...conflictModal, page: 'listConflict'},
      // })
    } else {
      const goToPayments = () => {
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'onboarding.mediumModal',
          data: {...mediumModal, page: 'confirmPayment'},
        })
        dispatch({
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'onboarding.conflictModal',
          data: {...conflictModal, isOpen: false},
        })
      }
      const planId = dataPlan?.idPlan || dataPlan?.id
      updateOnboardingStatus(planId, 'payments').then(
        () => {
          goToPayments()
        },
        () => {
          goToPayments()
        },
      )
    }
    // GUARDANDO EL ESTADO DEL MODAL
    mutationSetState({
      variables: {
        PlanInputStateOnboarding: {
          planId: dataPlan?.idPlan,
          stateOnboarding: JSON.stringify(reduxOnboarding),
        },
      },
    })
    // setNotScheduled([])
  }

  const actionIfScheduledFailed = (error) => {
    const json = JSON.stringify(error)
    console.error(json)
    showAlert(
      {
        text: translate('home.onboard.approve.scheduled.scheduledError'),
        status: 'error',
      },
      dispatch,
    )
    showAlert(
      {
        text: error.toString(),
        status: 'error',
      },
      dispatch,
    )
  }

  /**
   * Mutaciones:
   * setOnboardingState
   * scheduleCounselorFromStudent
   * scheduleMyLessonsPerWeekPlan
   * **/
  const [mutationSetState] = useMutation(
    gql`
      mutation update($PlanInputStateOnboarding: PlanInputStateOnboarding!) {
        setOnboardingState(input: $PlanInputStateOnboarding)
      }
    `,
    {
      update: () => {
        // console.log('¡Estado del onboarding guardado con éxito!')
      },
    },
  )

  const [mutationOr, {loading: loadOrientation}] = useMutation(
    gql`
      mutation update(
        $councelorInput: CouncelornputForStudent!
        $isAdmin: Boolean
        $defaultStatus: String = null
      ) {
        scheduleCounselorFromStudent(
          councelorInput: $councelorInput
          isAdmin: $isAdmin
          defaultStatus: $defaultStatus
        ) {
          id
          startDate
          endDate
        }
      }
    `,
  )
  // eslint-disable-next-line
  const [mutation, { loading: loadRecurring, error, data: datos }] = useMutation(
    gql`
      mutation update(
        $planId: ID!
        $lessonsPerWeekPlanPart: [LessonPerWeekPlanSchedulePart!]!
        $onboardingState: String
        $isAdmin: Boolean
      ) {
        scheduleMyLessonsPerWeekPlan(
          schedule: {
            planId: $planId
            lessonsPerWeekPlanPart: $lessonsPerWeekPlanPart
            onboardingState: $onboardingState
          }
          isAdmin: $isAdmin
        ) {
          ... on OcurrenceResult {
            hasOccurrences
            occurrencesList {
              topicId
              lessonName
              from
              to
              educatorUserId
              educatorFirstName
              educatorLastName
              serviceId
              recurringId
              lockLesson
            }
          }
        }
      }
    `,
    {
      update: (cache, datos) => {
        const notscheduled =
          datos.data.scheduleMyLessonsPerWeekPlan.occurrencesList
        setStatus(null)
        actionAfterMutationScheduled(notscheduled)
      },
      onError: (error) => {
        console.error('error', error)
        actionIfScheduledFailed(error)
        setError({
          msg:
            'Ups an error occurred!. Please try again, also you can select another time for your lessons',
          actionLabel: 'Try again',
          action: () => {
            // reejecuta mutación que agenda recurrencia
            executeRecurringMutation()
          },
        })
        setPageConflictModal('mutationError')
        setStatus(null)
      },
    },
  )

  // ejecuta mutaciones para agendamiento de lecciones
  const scheduledLessons = () => {
    if (dataPlan.isFirstPlan) {
      mutationOr({
        variables: parseDataOrToMutation(scheduledTimes, dataPlan, isAdmin),
      }).then(
        () => {
          mutation({
            variables: parseDataToMutation(
              scheduledTimes,
              dataPlan,
              JSON.stringify(reduxOnboarding),
              isAdmin,
            ),
          })
        },
        (err) => {
          actionIfScheduledFailed(err)
          setError({
            msg: err.toString(),
            actionLabel: 'Scheduled Orientation time',
            action: () => {
              // abre modal para reagendar orientación
              rescheduledLesson(ORIENTATION_KEY, 0)
              toggleModal()
            },
          })
          setPageConflictModal('mutationError')
        },
      )
    } else
      mutation({
        variables: parseDataToMutation(
          scheduledTimes,
          dataPlan,
          JSON.stringify(reduxOnboarding),
          isAdmin,
        ),
      })
  }
  const executeRecurringMutation = () => {
    setError(null)
    setPageConflictModal('schedulingRecurring')
  }

  const executeSchedulingProccess = () => {
    setError(null)
    updateOnboardingStatus(dataPlan?.idPlan || dataPlan?.id).then(
      () => {
        scheduledLessons()
      },
      () => {
        scheduledLessons()
      },
    )
  }

  useEffect(() => {
    if (isOpen && page === 'schedulingRecurring' && !errorProp) {
      executeSchedulingProccess()
    }
    // eslint-disable-next-line
  }, [page, errorProp, isOpen])

  useEffect(() => {
    if (loadOrientation) setStatus('Scheduling Orientation...')
    else if (loadRecurring) setStatus('Scheduling recurring lessons...')
    else setStatus(null)
    // eslint-disable-next-line
    }, [loadOrientation, loadRecurring]
  )

  return <div className='m-0 p-0' />
}

export default {SchedulingRecurringHeader, SchedulingRecurringBody}
