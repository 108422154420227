import React, {useState} from 'react'
import {LessonCard, useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import {useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'

const InfoLesson = (props) => {
  const {translate} = useTranslation()
  const {dataCard, className} = props
  const dispatch = useDispatch()

  const {breakWidth} = useWindowDimensions()
  const {status, notes: dataNotes} = dataCard

  let notes = dataNotes
  if (notes) if (notes.status !== 'sent') notes = null
  const student =
    dataCard?.student || dataCard?.studentPlan || dataCard?.studentGroup || {}
  const currentEducator = dataCard?.substitute || dataCard?.educator || {}
  const studentRating = student.rating || 0

  const [loadModal, setLoad] = useState(false)

  const RenderLesson = () => {
    return (
      <LessonCard
        className={`${className} w-100`}
        onClick={() => {
          // cargando
          setLoad(true)
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: `student.${
              dataCard?.isOrientation
                ? 'orientationLessonModal'
                : 'infoLessonModal'
            }`,
            data: {
              isOpen: true,
              idLesson: dataCard?.id,
            },
          })
          setTimeout(() => {
            setLoad(false)
          }, 1000)
        }}
        needsRating={status === 'attended'}
        currentRating={studentRating}
        strings={{
          endsIn: translate('misc.endsIn'),
          startsIn: translate('misc.startsIn'),
          yourRate: translate('lessons.rating.yourRating'),
          rateThisLesson: translate('lessons.rating.rateThisLesson'),
        }}
        activity={loadModal}
        title={dataCard.title}
        status={dataCard.status}
        lessonTime={
          dataCard?.startDate ? moment(dataCard?.startDate) : moment()
        }
        lessonEndTime={dataCard?.endDate ? moment(dataCard?.endDate) : moment()}
        attendants={[
          {
            name: `${currentEducator?.firstName} ${currentEducator?.lastName}`,
            avatar_url: null,
          },
        ]}
        showCountdown={null}
        preLabelTime={null}
        triggerFunction={() => {}}
      />
    )
  }

  return (
    <div className='w-100'>
      {breakWidth !== 'SM' && false ? (
        {
          /** <DropDown
          classNameMenu='border-0'
          className='w-100'
          direction='bottom'
          options={[
            {
              component: (
                <InfoDesktop status={status}>
                  <div className='pl-7 py-5 w-100 info-header'>
                    <div className='w-100 pr-3 pl-0 py-3'>
                      <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
                        <h2 className='my-1'>
                          <b>{title}</b>
                        </h2>
                        <h4 className='my-1'>
                          {lessonTime.format('LT')} to{' '}
                          {lessonEndTime.format('LT')} |{' '}
                          {lessonTime.format('ll')}
                        </h4>
                        <h5 className='my-1 pr-2'>
                          {service} | {topic} |{' '}
                          {translate(`lessons.modal.infolesson.${kind}`)}
                        </h5>
                      </div>
                    </div>
                    {edit && status === 'attended' ? (
                      <RatingComponent lessonId={id} />
                    ) : null}
                    {(status === 'attended' || status === 'missed') && (
                      <Badge
                        className='mx-1'
                        pill
                        color={status === 'attended' ? 'success' : 'danger'}
                      >
                        {status}
                      </Badge>
                    )}
                  </div>
                  <div className='w-100 p-0 m-0 pl-7 pb-4 info-body'>
                    <ListItem
                      className='pl-0 pr-5 '
                      Left={
                        <div className='d-flex align-items-center d-inline-block'>
                          <Avatar
                            name={attendants[0].name}
                            named={false}
                            uri={attendants[0].avatar_url}
                            size='avatar-medium'
                          />
                          <span className='ml-3 h4'>{attendants[0].name}</span>
                        </div>
                      }
                    />
                    {status !== 'attended' && status !== 'missed' ? (
                      <div className='p-0 pr-5 pt-3'>
                        <Action
                          className='m-0'
                          color='#F2F2FF'
                          textColor='#7079FF'
                          border=''
                          labelLeft={translate('lessons.reminder')}
                          labelRight={translate(
                            'lessons.modal.infolesson.minbefore',
                          )}
                          action='reminder'
                        />
                      </div>
                    ) : null}
                  </div>
                </InfoDesktop>
              ),
            },
          ]}
        >
          <RenderLesson />
        </DropDown>**/
        }
      ) : (
        <RenderLesson />
      )}
    </div>
  )
}

export default InfoLesson
