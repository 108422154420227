export const initialState = {
  refetch: {
    refetch: () => {},
  },
  info: {
    emails: [],
    firstName: '',
    lastName: '',
    phone: '',
    timezone: '',
    birthdate: '',
    studentInfo: {
      institution: '',
      graduationDate: '',
      gpa: '',
      universities: [],
      docsAndExamsFilter: '',
      filterByType: '',
      tasks: {},
    },
    educatorInfo: {},
    paymentMethod: null,
  },
  counseling: {
    allTasks: [],
    myTeam: [],
  },
  isSet: true,
}
