import React, {useMemo, useEffect} from 'react'
import {
  ExamCard,
  IconArrowDown,
  IconLesson,
  IconSignPlus,
  Action,
} from '@Knowledge-OTP/znk-ui-components'
import useActivities from '../useQueries/useActivities'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'

const Learn = (props) => {
  const {idStudent, page} = props
  const {translate} = useTranslation()
  const now = useMemo(() => moment(), [])
  const [
    activitiesQuery,
    {data: dataActivities, loading: loadActivities},
  ] = useActivities()
  useEffect(() => {
    if (page === 'learn' && idStudent && now) {
      activitiesQuery({
        variables: {
          kinds: ['test'],
          from: now.toISOString(),
          userIds: [idStudent],
        },
      })
    }
  }, [page, idStudent, now, activitiesQuery])
  return (
    <div className='d-flex flex-column m-0 p-0 pr-6'>
      <div className='p-0 mx-0 mt-5 mb-2'>
        <div className='mx-0 mt-0 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>
            {translate('student.modal.learn.exam')}
          </span>
          <span className='text-gray'>
            {translate('student.modal.learn.explore')}
          </span>
        </div>
        <div className='d-flex flex-row znk-scroll-horizontal-v2'>
          {loadActivities ? (
            new Array(5).fill(0).map(() => {
              return (
                <Skeleton className='mr-3' height={126} width={92} count={1} />
              )
            })
          ) : dataActivities?.allActivities.filter(
              (activity) => activity._typename === 'Test',
            ).length === 0 ? (
            <Action
              color='transparent'
              textColor='#7079FF'
              border='dashed 1px #7079FF'
              className='my-3'
              labelLeft={
                <span className='w-100 d-flex flex-column my-3'>
                  <label className='m-0'>
                    {translate('student.modal.learn.noactivities')}
                  </label>
                </span>
              }
              labelRight={null}
              action={null}
            />
          ) : (
            dataActivities?.allActivities
              .filter((activity) => activity._typename === 'Test')
              .map((activity, index) => {
                return (
                  <ExamCard
                    className='mr-3'
                    classColor='orange'
                    number={index + 1}
                    examTitle={activity?.name}
                    endTime={moment(activity?.endDate)}
                  />
                )
              })
          )}
        </div>
      </div>
      <div className='p-0 mx-0 mt-5 mb-2'>
        <div className='mx-0 mt-0 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>
            {translate('student.modal.learn.task')}
          </span>
          <span className='text-gray'>
            {translate('student.modal.learn.viewHistory')}
          </span>
        </div>
        <div className='d-flex flex-column'>
          {true ? (
            <Skeleton className='mb-2' count={4} height={40} />
          ) : (
            new Array(4).fill(0).map(() => {
              return (
                <div className='znk-card normal mb-2'>
                  <div className='left'>
                    <IconLesson size={15} color='#72809D' />
                    <div className='text normal-text'>
                      Test Prep Math and English
                    </div>
                  </div>
                  <div className='text text-gray'>Yesterday</div>
                </div>
              )
            })
          )}
          <div className='znk-card normal mb-2' style={{cursor: 'pointer'}}>
            <div className='left'>
              <IconSignPlus size={15} color='#72809D' />
              <div className='text text-gray'>
                {translate('student.modal.learn.createNewTask')}
              </div>
            </div>
          </div>
          <div className='znk-card empty mb-2' style={{cursor: 'pointer'}}>
            <div className='left'>
              <IconArrowDown size={15} color='#72809D' />
              <div className='text text-gray'>
                {translate('student.modal.learn.viewCompletedTask')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Learn
