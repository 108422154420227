export const firtsGradeIndex = 0
export const lastGradeIndex = 13
// ultimo grado (no el indice sino el grado)
// si en el futuro se agrega otro nivel despues de 12th debe cambiarse
export const lastGradeEnum = 12

export const gradesEnum = {
  0: {id: 'kinder', label: 'Kinder'},
  1: {id: '_1th', label: '1th'},
  2: {id: '_2th', label: '2th'},
  3: {id: '_3th', label: '3th'},
  4: {id: '_4th', label: '4th'},
  5: {id: '_5th', label: '5th'},
  6: {id: '_6th', label: '6th'},
  7: {id: '_7th', label: '7th'},
  8: {id: '_8th', label: '8th'},
  9: {id: '_9th', label: '9th'},
  10: {id: '_10th', label: '10th'},
  11: {id: '_11th', label: '11th'},
  12: {id: '_12th', label: '12th'},
  13: {id: '_13th', label: '12th+'},
  // '12last': { id: '_12thLastMinute', label: '12 last minutes' },
  transfer: {id: 'transfer', label: 'Transfer'},
  graduate: {id: 'graduate', label: 'Graduate'},
}

export const gradesOptions = Object.values(gradesEnum).map((grade) => {
  return {
    ...grade,
    value: grade?.id,
  }
})
