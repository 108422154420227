import {gql, useMutation} from '@apollo/client'

const useSendWspWhenFirstPlanIsApproved = (
  onCompleted = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation($planId: ID!) {
        sendWspWhenFirstPlanIsApproved(planId: $planId)
      }
    `,
    {onCompleted, onError},
  )
}

export default useSendWspWhenFirstPlanIsApproved
