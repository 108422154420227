export default {
  whateducatorwant: 'Qué educador deseas?',
  footermsg1: 'Es todo por ahora.',
  footermsg2: 'Obtén tu máximo potencial.',
  footermsg3: 'Fortalece y adquiere nuevos conocimientos.',
  footermsg4: 'Agendar nueva lección',
  newlessonnot: 'Tienes lecciones pendientes',
  nextweek: 'Siguiente semana',
  rescheduled: 'Reagendar lección',
  needcancellesson: '¿Necesitas cancelar esta lección?',
  checkin: 'Registrarse',
  reminder: 'Recordatorio',
  gotoclassroom: 'Ir a Class Room',
  startin: 'Comienza en',
  scheduledlesson: 'Agendar lección',
  selectdate: 'Seleccionar una fecha',
  whattopicwant: '¿Que tópico deseas?',
  addnewlesson: 'Añadir nueva lección',
  createtask: 'Crear tarea',
  needhelp: '¿Necesitas ayuda?',
  cancel: 'CANCELAR',
  today: 'Hoy, ',
  continue: 'Continuar',
  timezone: 'Zona horaria',
  lessonscheduled: 'Lección agendada',
  view: 'VER',
  fortimezone: ({lesson, zone}) => {
    return `Para lección ${lesson} | ${zone}`
  },
  needmoreop: '¿Necesitas más opciones para agendar?',
  errorscheduled: 'Al programar esta lección, acepto el cargo adicional de ',
  selectAnEducator: 'Selecciona un educador',
  copyLesson: 'Copiar lección',
  scheduleLesson: 'Agendar Lección',
}
