import {initialState} from './initialState'
import {SET_EVENT_LISTENER} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVENT_LISTENER:
      return {
        ...state,
        events: {
          ...state?.events,
          [action.payload.type]: {
            event: action.payload.event,
            otherProps: action.payload.otherProps,
          },
        },
      }
    default:
      return {...state}
  }
}
