import {initialState} from './initialState'
import {SET_EXAMSCORES_ACTION} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXAMSCORES_ACTION:
      return {
        ...state,
        examScores: action.payload,
      }
    default:
      return {...state}
  }
}
