import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {
  Task,
  IconIllustratedTask,
  Action,
  BlankSpace,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'
import {UseMyTeam} from '../../../../../Model/Users/MyTeam/useMyTeam'
import {TabContent, TabPane} from 'reactstrap'
import showAvatarAssigned from '../../../Utils/showAvatarAssigned'
import useShowTask from '../../ViewTask/useShowTask'

const TasksTab = (props) => {
  const {college /** , data, loading */} = props
  const myTeam = useSelector((state) => state.user.counseling?.myTeam)
  const studentInfo = useSelector((state) => state.user.info)
  // Set pending tasks
  const planId = useSelector(
    (state) =>
      state?.user?.info?.studentInfo?.homeNotification?.councelingPlanId,
  )
  const allTasksReduxData = useSelector(
    (state) => state.user.counseling?.allTasks,
  )
  const universityTask = allTasksReduxData?.filter((task) =>
    task.universities.includes(college?.id),
  )
  // eslint-disable-next-line
  const [getMyPlan, { data: dataTeam, loading: loadingTeam }] = UseMyTeam()
  const {open: openTask, setTaskData, isOpen} = useShowTask()
  const [pendingList, setPendingList] = useState([])
  // Set complete tasks
  const [completeList, setCompleteList] = useState([])
  // Set review tasks
  const [reviewList, setReviewList] = useState([])
  const {translate} = useTranslation()

  const tasksToShow = [
    {title: translate('counseling.pendingTask'), list: pendingList},
    {title: translate('counseling.reviewTask'), list: reviewList},
    {title: translate('counseling.completedTask'), list: completeList},
  ]

  const showTask = (task) => {
    setTaskData(task)
    openTask(() => {}, task)
  }

  useEffect(() => {
    // Cada vez que se terminen de actualizar las tasks (traemos todas las de la universidad), hacemos un filter para cada estado
    if (universityTask?.length > 0) {
      const pendingArray = universityTask.filter(
        (task) => task.status === 'pending',
      )
      setPendingList(pendingArray)
      const completedArray = universityTask.filter(
        (task) => task.status === 'completed',
      )
      setCompleteList(completedArray)
      const reviewArray = universityTask.filter(
        (task) => task.status === 'review',
      )
      setReviewList(reviewArray)
    } else {
      setPendingList([])
      setCompleteList([])
      setReviewList([])
    }
    // eslint-disable-next-line
  }, [isOpen && allTasksReduxData])

  useEffect(() => {
    if (planId && planId !== '') {
      getMyPlan({
        variables: {
          planId,
        },
      })
    }
    // eslint-disable-next-line
  }, [planId])

  return (
    <TabContent activeTab={universityTask?.length === 0 ? `blank` : 'show'}>
      <TabPane tabId='blank'>
        <BlankSpace
          mainText={translate('counseling.noTasksText')}
          linkProps={{
            text: translate('counseling.docsExamsText'),
            className: `text-gray h45`,
          }}
          icon={<IconIllustratedTask />}
          style={{height: '50vh'}}
          className=''
          border='border-dashed'
          textAlign='text-center'
        />
      </TabPane>
      <TabPane tabId='loading'>
        {new Array(3).fill(0).map(() => {
          return (
            <div className='d-flex flex-column mx-0 my-3 p-0'>
              <div className='d-flex flex-row justify-content-start'>
                <Skeleton count={1} height={10} width={70} className='my-1' />
              </div>
              <Skeleton count={5} height={40} className='my-1' />
            </div>
          )
        })}
      </TabPane>
      <TabPane tabId='show'>
        <div className='d-flex flex-column'>
          {tasksToShow?.map((category) => {
            return (
              <div className='my-3'>
                <div className='h4 font-weight-bold text-title my-3'>
                  <span>{category.title}</span>
                </div>
                {category.list.length > 0 ? (
                  category.list.map((task) => {
                    return (
                      <Task
                        onClick={() => {
                          showTask(task)
                        }}
                        taskClassName='mb-1 cursor-pointer'
                        labelLeft={
                          <span
                            title={task.name || 'No defined'}
                            className='text-truncate w-100'
                          >
                            {task.name}
                          </span>
                        }
                        labelRight={
                          task.userId === studentInfo.id
                            ? null
                            : showAvatarAssigned(task.userId, myTeam)
                        }
                        isCounseling
                      />
                    )
                  })
                ) : (
                  <Action
                    color='transparent'
                    textColor='#7079FF'
                    border='dashed 1px #7079FF'
                    className='my-2'
                    labelLeft={
                      <span className='w-100 d-flex flex-column my-3'>
                        <label className='m-0'>No task</label>
                      </span>
                    }
                    labelRight={null}
                    action={null}
                  />
                )}
              </div>
            )
          })}
        </div>
      </TabPane>
    </TabContent>
  )
}

export default TasksTab
