import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'

const useScheduledOrientationModal = () => {
  const dispatch = useDispatch()
  const scheduledOrientationRedux = useSelector(
    (state) => state.modal.scheduledOrientation,
  )
  const {isOpen} = scheduledOrientationRedux
  const setData = (data = {}) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'scheduledOrientation',
      data: {
        ...data,
      },
    })
  }

  const parseFrames = (
    frames,
    studentTimezone,
    orientatorTimezone,
    minutesDiff = 30,
  ) => {
    const zone = moment.tz(studentTimezone).format('z')
    // timezone del orientador
    const orientatorZone = orientatorTimezone || 'UTC'
    // setea el timezone
    moment().tz(zone) // Pasarle el zone del estudiante, para que muestre el horario en su zona horaria
    const array = []
    // frames.length
    for (let i = 0; i < frames.length; i++) {
      let [from, to] = frames[i]
      from = moment(from)
      to = moment(to)
      if (from.minutes() !== 0) {
        // SI NO PARTE DESDE UNA HORA EN PUNTO (minuto zero) => ajustar from a la siguiente hora
        const minutes = from.minutes()
        let addMin = 0
        if (minutes >= 1 && minutes < 15) {
          addMin = 15 - minutes
        } else if (minutes >= 15 && minutes < 30) {
          addMin = 30 - minutes
        } else if (minutes >= 30 && minutes < 45) {
          addMin = 45 - minutes
        } else if (minutes >= 45) {
          addMin = 60 - minutes
        }
        from = from.add(addMin, 'm')
        const seconds = from.seconds()
        if (seconds !== 0) {
          from = from.subtract(seconds, 's')
        }
      }
      if (moment(to).clone().minutes() !== 0) {
        const minutesTo = moment(to).clone().minutes()
        let subMin = 0
        if (minutesTo >= 1 && minutesTo < 15) {
          subMin = 15 - minutesTo
        } else if (minutesTo >= 15 && minutesTo < 30) {
          subMin = 30 - minutesTo
        } else if (minutesTo >= 30 && minutesTo < 45) {
          subMin = 45 - minutesTo
        } else if (minutesTo >= 45) {
          subMin = 45 - minutesTo
        }
        to = to.subtract(subMin, 'm')
      }
      while (from < to && array.length <= 100) {
        const dayOfWeek = moment(from).clone().tz(orientatorZone).isoWeekday()
        const hourOfDay = moment(from).clone().tz(orientatorZone).hour()
        const fromBool = moment(from) > moment().add(24, 'hours')
        const hourBool = hourOfDay >= 10 && hourOfDay < 17
        const dayBool = dayOfWeek >= 1 && dayOfWeek <= 5
        if (
          // de 10am a 17pm
          hourBool &&
          // lunes a viernes
          dayBool &&
          fromBool
        ) {
          array.push(moment(from).format())
        }
        from = from.add(minutesDiff, 'm') // probar con 30
      }
    }
    return array
  }

  return {
    ...scheduledOrientationRedux,
    setData,
    open: (onChange = () => {}, dataPlan = null) => {
      setData({
        ...scheduledOrientationRedux,
        isOpen: true,
        onChange,
        dataPlan,
      })
    },
    close: () => {
      setData({
        ...scheduledOrientationRedux,
        isOpen: false,
      })
    },
    toggle: () => {
      setData({
        ...scheduledOrientationRedux,
        isOpen: !isOpen,
      })
    },
    parseFrames,
  }
}

export default useScheduledOrientationModal
