import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {showAlert} from '../../../../../common/Alert/util'
import useLinkLesson from '../../../../CalendarLessons/modal/InfoLesson/useLinkLesson'
import {hasHttp} from '../../../../CalendarLessons/modal/utils'
import CheckInLesson from '../../../../../Model/Lessons/CheckInLesson'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import {UseGetLesson} from '../../Queries/useGetLesson'

const useActionsFields = (refetch = () => {}) => {
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  // redux modal sendLessonNotes
  const reduxLessonNotes = useSelector(
    (state) => state.modal.calendar.sendLessonNotes,
  )

  // go to classroom hook
  const getvclink = useLinkLesson()
  const showAlertNoLink = () => {
    // alerta "No hay vínculo"
    showAlert(
      {
        text: translate('workspace.teach.table.actions.noLink'),
        status: 'error',
      },
      dispatch,
    )
  }

  // checkin mutation
  const {submit: checkInLesson} = CheckInLesson(
    () => {
      showAlert(
        {
          text: translate('workspace.teach.table.actions.checkInSuccess'),
          status: 'success',
        },
        dispatch,
      )
      refetch()
    },
    (err) => {
      console.error(err)
      showAlert(
        {
          text: translate('workspace.teach.table.actions.checkInError'),
          status: 'error',
        },
        dispatch,
      )
      refetch()
    },
  )

  const gotoClassRoom = (dataLesson) => {
    const {student} = dataLesson
    getvclink(dataLesson?.id, student?.studentUserId || student?.id)
      .then(
        ({launchUrl, location}) => {
          if (
            location.type === 'virtualClassroom' ||
            location.type === 'zinkerzPlatform'
          ) {
            if (launchUrl?.length > 0) window.open(hasHttp(launchUrl), '_blank')
            else showAlertNoLink()
          } else {
            if (location?.linkStudent?.length > 0)
              window.open(hasHttp(location.linkStudent), '_blank')
            else showAlertNoLink()
          }
        },
        (error) => {
          console.error(error)
          if (dataLesson?.location?.linkStudent?.length > 0)
            window.open(hasHttp(dataLesson.location.linkStudent), '_blank')
          else showAlertNoLink()
        },
      )
      .catch((error) => {
        console.error(error)
        if (dataLesson?.location?.linkStudent?.length > 0)
          window.open(hasHttp(dataLesson.location.linkStudent), '_blank')
        else showAlertNoLink()
      })
  }

  const openModalSendNotes = (dataLesson) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'calendar.sendLessonNotes',
      data: {
        ...reduxLessonNotes,
        dataLesson,
        isOpen: true,
      },
    })
  }
  const onCompletedGetLessonData = ({getLesson: dataLesson}) => {
    // identifica la acción pendiente [checkin, gotoclassroom, completelesson]
    const {startDate, endDate, status, substitute, educator} = dataLesson
    const now = moment()
    const lessonInProgress =
      moment(startDate) < now && now < moment(endDate) && status === 'scheduled'
    const lessonIncomplete = moment(endDate) < now && status === 'scheduled'
    const lessonTenMinutesLeft =
      moment.duration(moment(now).diff(startDate)).asMinutes() < 10 &&
      !lessonInProgress

    let oficialEducator = null
    // si la lección tiene sustituto asignado el usuario será ese sustituto
    if (substitute?.educatorUserId === '') oficialEducator = substitute
    else oficialEducator = educator

    // abrir formulario "completar lesson notes"
    if (lessonIncomplete) openModalSendNotes(dataLesson)
    // redireccionar a classroom
    else if (lessonInProgress || lessonTenMinutesLeft) {
      // checkin
      if (oficialEducator?.attendanceStatus === 'pending')
        checkInLesson({lessonId: dataLesson.id})
      else gotoClassRoom(dataLesson)
    } else {
      // no hay acción disponible
      showAlert(
        {
          text: translate('workspace.teach.table.actions.noAction'),
          status: 'error',
        },
        dispatch,
      )
    }
  }

  const onErrorGetLessonData = (err) => {
    showAlert(
      {
        text: translate('workspace.teach.table.actions.noAction'),
        status: 'error',
      },
      dispatch,
    )
    console.error('error', err)
  }
  const [getLessonData] = UseGetLesson(
    () => {},
    () => {},
  )

  const onClickAction = (item, onCompleted = () => {}) => {
    // if (loading) return
    // setLoading(true)
    getLessonData({
      variables: {
        lessonId: item?.action?.idLesson || null,
      },
    }).then(
      (res) => {
        // setLoading(false)
        onCompleted()
        onCompletedGetLessonData(res.data)
      },
      (err) => {
        // setLoading(false)
        onCompleted()
        onErrorGetLessonData(err)
      },
    )
  }

  const getLabel = (item) => {
    const translateLabel =
      item?.action?.key === 'completelesson'
        ? translate('workspace.teach.table.actions.completeLesson')
        : item?.action?.key === 'checkin'
        ? translate('workspace.teach.table.actions.checkIn')
        : translate('workspace.teach.table.actions.gotoClassRoom')
    const hasAction = ['gotoclassroom', 'completelesson', 'checkin'].includes(
      item?.action?.key,
    )
    return {
      hasAction,
      label: hasAction
        ? translateLabel
        : item?.action?.key === 'noMoreLessons'
        ? `No future lessons`
        : moment().subtract(item?.action?.finishedAt, 'milliseconds').fromNow(),
    }
  }

  return {
    onClickAction,
    getLabel,
  }
}

export default useActionsFields
