import React, {useState, useEffect} from 'react'
import {Button, Input} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import useEmailVerifier from './useEmailVerifier'
import {useLocation} from 'react-router-dom'

const EmailSignin = (props) => {
  const {goSignin, goSignup} = props
  const {translate} = useTranslation()
  const [email, setEmail] = useState('')

  const {submit, loading, validationErrors} = useEmailVerifier(
    (user) => {
      if (user.hasFirebaseAuth) {
        // usuario existe en firebase
        global.localStorage.setItem('loggedUser', JSON.stringify(user))
        goSignin()
      } else {
        // usuario NO existe en firebase
        global.localStorage.setItem('signupEmail', email)
        if (user.hasLivePrepUser) {
          global.localStorage.setItem('foundUser', JSON.stringify(user))
        }
        goSignup(user)
      }
    },
    (e) => console.log(e),
  )

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isEducator = searchParams.get('isEducator') === 'true'
  const emailFromParams = searchParams.get('email')

  useEffect(() => {
    if (emailFromParams) {
      setEmail(emailFromParams)
    }
    // eslint-disable-next-line
  }, [searchParams])

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit(email)
        }}
      >
        <span className='text-secondary font-weight-light'>
          {translate('login.signin.email.label')}
        </span>
        <Input
          className='my-2'
          name='email'
          value={email}
          placeholder={translate(
            `login.signin.email.placeholder${isEducator ? 'Educator' : ''}`,
          )}
          onChange={({target: {value}}) => setEmail(value)}
        />
        {validationErrors.email ? (
          <div className='my-2 text-center text-error h3 w-100'>
            {translate('login.signin.email.invalid')}
          </div>
        ) : null}
        <Button
          className='w-100 mt-2'
          color='primary'
          activity={loading}
          type='submit'
          label={translate('login.signin.email.presubmit')}
        />
      </form>
    </div>
  )
}

export default EmailSignin
