import React, {useMemo} from 'react'
import WeeklySchedule from '../../components/WeeklySchedule'
import LessonsIcons from '../../components/LessonsIcons'
import {useSelector} from 'react-redux'
import {
  StickyButton,
  IconIllustratedLesson,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import {checkIfNeedSelectLpw} from '../../utils'
import useTranslation from '../../../../i18n/useTranslation'

export const IntroductionPartHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <div className='m-0 p-0 mt-3'>
      <IconIllustratedLesson size={80} />
      <h2 className='w-100 my-3'>
        <b>{translate('home.onboard.approve.before.title')}</b>
      </h2>
    </div>
  )
}
export const IntroductionPartBody = (props) => {
  const {setPage} = props
  const data = useSelector((state) => state.modal.onboarding.data)
  const {dataPlan, scheduledTimes, keyLesson} = data
  const needSelectLpw = useMemo(() => {
    return (
      dataPlan?.isTotalLesson &&
      checkIfNeedSelectLpw(scheduledTimes, keyLesson?.split('-')[1])
    )
  }, [dataPlan, scheduledTimes, keyLesson])
  const {translate} = useTranslation()

  const allSelected =
    scheduledTimes && keyLesson
      ? Object.entries(scheduledTimes)
          .filter(([key]) => key.split('-')[1] === keyLesson.split('-')[1])
          .reduce((allSel, [__, objTopic]) => {
            return allSel && objTopic.distributed
          }, false)
      : false

  const services = useSelector((state) => state.services.services)
  const groupLessons =
    dataPlan?.groupInfo?.PartsSchedule?.reduce(
      (arrayTimes, {lessonsPerWeekGroup}, indexPart) => {
        arrayTimes.push(
          Object.values(
            lessonsPerWeekGroup.reduce((obj, {topicId}) => {
              if (obj[topicId]) obj[topicId].lessons += 1
              else {
                obj = {
                  ...obj,
                  [topicId]: {
                    lessons: 1,
                    name: services[dataPlan?.serviceId].topics[topicId].name,
                  },
                }
              }
              return obj
            }, {}),
          ),
        )
        return arrayTimes
      },
      [],
    ) || []

  return (
    <div className='m-0 p-0'>
      <WeeklySchedule
        indexPart={parseInt(keyLesson.split('-')[1])}
        data={Object.entries(scheduledTimes)
          .filter(
            ([key]) =>
              parseInt(key.split('-')[1]) === parseInt(keyLesson.split('-')[1]),
          )
          .map(([__, value]) => value)}
        idTopic=''
        hideTitle
        hideSubTitle={false}
        dataPlan={dataPlan}
        className='p-0'
        showTotalLessons={dataPlan?.isTotalLesson}
        useTotalLessonText={dataPlan?.isTotalLesson}
        hasGroupLesson={dataPlan?.isGroup}
        groupPartData={groupLessons[parseInt(keyLesson?.split('-')[1])]}
      />
      {(!dataPlan?.isTotalLesson || allSelected) && (
        <CarouselFrame type='animation'>
          <LessonsIcons
            data={
              scheduledTimes
                ? Object.entries(scheduledTimes).reduce(
                    (arrayData, [key, objTopic]) => {
                      if (key.split('-')[1] === keyLesson.split('-')[1]) {
                        return arrayData.concat(
                          objTopic.times.map((item) => {
                            return {
                              scheduled: item !== null,
                              lesson: objTopic.topic.name,
                              idTopic: objTopic.topic.id.toString(),
                            }
                          }),
                        )
                      }
                      return arrayData
                    },
                    [],
                  )
                : []
            }
            idTopic={null}
            lesson={1}
            checked
            selected={false}
          />
        </CarouselFrame>
      )}
      <StickyButton
        className='mt-4'
        condition
        label={translate('home.onboard.approve.before.continue')}
        onClickButtonFunction={() => {
          if (needSelectLpw) {
            setPage('selectLpw')
          } else {
            setPage('introductionTopic')
          }
        }}
      />
    </div>
  )
}

export default {IntroductionPartHeader, IntroductionPartBody}
