import React, {useCallback, useEffect, useState} from 'react'
import {IconLoad, Switch} from '@Knowledge-OTP/znk-ui-components'
import useSwitchDisabledForStudent from './Model/useSwitchDisabledForStudent'

const DisabledForStudent = (props) => {
  const {
    disableForStudents = false,
    educatorId = '',
    refetch = () => {},
  } = props
  const [disabled, setDisabled] = useState(disableForStudents)
  useEffect(() => {
    setDisabled(disableForStudents)
  }, [disableForStudents])

  const [switchDisabled, {loading}] = useSwitchDisabledForStudent((res) => {
    refetch()
  }, console.error)

  const RenderSwitch = useCallback(
    (props) => {
      return (
        <Switch
          initialState={!disabled}
          onChange={() => {
            switchDisabled({variables: {educatorId}})
          }}
          // getState={() => { }}
          style={{}}
          {...props}
        />
      )
    },
    [disabled, switchDisabled, educatorId],
  )

  return (
    <>
      <div className='d-flex flex-row justify-content-between mt-3'>
        <span className='text-title'>Abled for student</span>
        <div className='d-flex flex-row align-items-center'>
          {loading && <IconLoad size={16} />}
          <RenderSwitch className='mb-0 ml-1' />
        </div>
      </div>
    </>
  )
}

export default DisabledForStudent
