import React, {useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  IconClose,
  IconDate,
  IconReminder,
  IconSignCheck,
  IconSignPlus,
  IconTime,
  Modal,
} from '@Knowledge-OTP/znk-ui-components'
import isEmpty from 'lodash/isEmpty'
import LessonFilters from './LessonFilters'
import useModalState from '../../../../../../hooks/useModalState'
import moment from 'moment-timezone'

const baseStatus = ['Finished', 'Scheduled', 'Pending']
const LessonsModal = (props) => {
  const {isOpen, toggle, modalData} = useModalState('program.topicLessons')
  const {topicId, name} = modalData
  const {lessons, lessonLoading} = props
  const [filterStatus, setFilterStatus] = useState(baseStatus)
  const styles = {
    scheduled: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
    },
    Pending: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      border: '1px solid #696CFF',
    },
    canceled: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      backgroundColor: '#ffe8e3',
    },
    pending: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      color: '#696CFF',
      backgroundColor: '#f3fcd5',
    },
  }
  const now = moment()
  const firstLesson = !isEmpty(lessons)
    ? lessons
        .filter(
          (lesson) => lesson.status === 'Scheduled' && lesson.lessonTime > now,
        )
        .sort((a, b) => a.lessonTime - b.lessonTime)
        .find((lesson) => lesson.status === 'Scheduled')
    : null
  return (
    <Modal
      isOpen={isOpen}
      className='modal-sidebar modal-desktop-half'
      backdropClassName='no-op'
      Head={
        <div className='modal-header d-flex'>
          <h2 className='ml-2 flex-grow-1 text-title font-weight-bold'>
            {name}
          </h2>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='d-flex flex-column px-5 pt-2 pb-4'>
        <LessonFilters
          {...{
            setFilterStatus,
            lessons: lessons.filter((l) =>
              topicId ? l.topic.id === topicId : true,
            ),
            lessonLoading,
            baseStatus,
            current: filterStatus,
          }}
        />
        {lessonLoading ? (
          <Skeleton count={4} height={26} />
        ) : lessons ? (
          lessons
            .filter((l) => (topicId ? l.topic.id === topicId : true))
            .filter((l) => {
              return ['attended', 'missed'].includes(filterStatus[0])
                ? (l.status === 'Finished' &&
                    l?.student?.attendanceStatus === filterStatus[0]) ||
                    (l.status === 'Scheduled' &&
                      filterStatus[0] === 'attended' &&
                      l?.student?.attendanceStatus === 'checkIn')
                : filterStatus[0] === 'Scheduled'
                ? filterStatus.includes(l.status) &&
                  l?.student?.attendanceStatus !== 'checkIn'
                : filterStatus.includes(l.status)
            })
            .map((item, idx) =>
              item.status === 'Scheduled' &&
              item?.student?.attendanceStatus !== 'checkIn' ? (
                <div
                  style={styles.scheduled}
                  className={`${
                    firstLesson && firstLesson.id === item.id
                      ? 'bg-primary'
                      : 'bg-secondary'
                  } text-white d-flex w-100 my-1 px-4 font-weight-light`}
                >
                  <span className='flex-grow-1'>
                    {item.lessonTime.format('dddd, Do MMM HHa')}
                  </span>
                  <span>
                    {firstLesson && firstLesson.id === item.id ? (
                      <IconReminder size={15} className='mx-3' />
                    ) : (
                      <IconDate size={15} className='mx-3' />
                    )}
                  </span>
                </div>
              ) : item.status === 'Pending' ? (
                <div
                  style={styles.Pending}
                  className='d-flex w-100 my-1 px-4 text-gray font-weight-light'
                >
                  <span className='flex-grow-1 text-primary'>
                    {item.lessonTime.format('dddd, Do MMM HHa')}
                  </span>
                  <span>
                    <IconTime
                      size={16}
                      className='mx-3'
                      style={{color: '#696CFF'}}
                    />
                  </span>
                </div>
              ) : ['pending', 'missed'].includes(
                  item.student.attendanceStatus,
                ) ? (
                <div
                  style={styles.canceled}
                  className='d-flex w-100 my-1 px-4 text-gray font-weight-light'
                >
                  <span className='flex-grow-1 text-error'>
                    {item.lessonTime.format('dddd, Do MMM HHa')}
                  </span>
                  <span>
                    <IconSignPlus
                      size={15}
                      className='mx-3'
                      style={{color: '#FF3E1D'}}
                    />
                  </span>
                </div>
              ) : (
                <div
                  style={styles.pending}
                  className='d-flex w-100 my-1 px-4 text-gray font-weight-light'
                >
                  <span className='flex-grow-1'>
                    {item.lessonTime.format('dddd, Do MMM HHa')}
                  </span>
                  <span>
                    <IconSignCheck
                      size={15}
                      className='mx-3'
                      style={{color: '#A7E521'}}
                    />
                  </span>
                </div>
              ),
            )
        ) : null}
      </div>
    </Modal>
  )
}

export default LessonsModal
