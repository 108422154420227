import es from './es'
import en from './en'
import before from './before'
import prescheduled from './prescheduled'
import scheduled from './scheduled'
import confirm from './confirm'
import profile from './profile'
import success from './success'

export default {
  es,
  en,
  before,
  prescheduled,
  scheduled,
  confirm,
  profile,
  success,
}
