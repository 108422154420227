import React, {useEffect, useState} from 'react'
import {Avatar /** , IconArrowRight**/} from '@Knowledge-OTP/znk-ui-components'
import {extractTeamFromPlan} from '../../../../Utils/extractTeamFromPlan'

const Team = (props) => {
  const {plan, className} = props
  const [team, setTeam] = useState('')

  useEffect(() => {
    setTeam(extractTeamFromPlan(plan))
  }, [plan])

  return (
    <div className={`d-flex flex-column ${className}`}>
      <span
        className='h4 font-weight-bold text-title'
        style={{lineHeight: '1.6'}}
      >
        Team
      </span>

      {team?.length > 0 &&
        team?.map((user) => (
          <div className='d-flex flex-row mb-2'>
            <div className='w-90 d-flex flex-row align-items-center'>
              <Avatar
                uri={user.avatar}
                name={user.firstName + ' ' + user.lastName}
                size='avatar-medium'
              />
              <label className='ml-2 text-info' style={{color: '#283d57'}}>
                {`${user.firstName} ${user.lastName}`}
                <span className='text-muted h45 ml-2'>({user.role})</span>
              </label>
            </div>
          </div>
        ))}
    </div>
  )
}

export default Team
