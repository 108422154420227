import {gql} from '@apollo/client'

export default gql`
  query(
    $status: [PaymentStatus!]!
    $category: [PaymentCategory!]!
    $dates: [DateTime!]
  ) {
    getInvoicePayments(category: $category, status: $status, dates: $dates) {
      id
      from
      to
      status
      category
      invoiceNumber
      createdAt
      updatedAt
      lastProcessedAt
      amount
      taxFee
      amounts {
        subTotal
        taxAmount
        amount
      }
      paymentItems {
        ... on PlanPaymentItem {
          generatedDescription
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on CustomPaymentItem {
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on LessonPaymentItem {
          generatedDescription
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on EducatorPaymentItem {
          generatedDescription
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
      }
      issuedInvoices {
        stripeInvoiceId
        status
        paymentMethod
        amount
        lastProcessedAt
        issuedCreditNotes {
          creditNoteId
          amount
          stripeInfo {
            created
            currency
            discount_amount
            number
            out_of_band_amount
            pdf
            subtotal
            total
          }
        }
      }
    }
  }
`
