import {gql} from '@apollo/client'
import queryStudentResult from '../../QueryResult/useStudentResult'

export default gql`
  query {
    me {
      id
      paymentMethod {
        ... on CardPaymentMethod {
          last4
          brand
          expYear
          expMonth
        }
      }
      emails {
        address
        verified
      }
      countryId
      gender
      spokenLanguages
      address
      city
      state
      zipCode
      firstName
      lastName
      phone
      timezone
      birthdate
      studentInfo {
        institution
        graduationDate
        gpa
        aditionalInfo
        parents {
          firstName
          lastName
          managePayments
        }
        notifications {
          missedLesson
          lessonsNotesReady
          lessonReminder {
            isAvailable
            duration
          }
        }
        linkedEducators {
          educatorUserId
          firstName
          lastName
        }
        upcomingLesson {
          id
        }
      }
      roles
      educatorInfo {
        id
        availabilityBlocks {
          day
          hour
        }
        myPayslips {
          id
          status
          category
          payslipDate
          to
          payslipNumber
          payslipProps {
            amount
            isPenalized
          }
        }
        getTeach {
          ${queryStudentResult}
        }
      }
    }
  }
`
