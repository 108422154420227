import validate from 'validate.js'

const constraints = {
  planId: {
    presence: true,
  },
  status: {
    presence: true,
  },
}

export default (data) => {
  return validate(data, constraints)
}
