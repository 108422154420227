import React, {useEffect, useState, useMemo} from 'react'
// import {OrientationLessonModal} from '@Knowledge-OTP/znk-ui-components'
import OrientationLessonModal from '../../../../Workspace/Student/ViewTask/Components/Modals/OrientationLessonModal'
import useTranslation from '../../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import {showAlert} from '../../../../../common/Alert/util'
import useCalendarEvent from '../InfoModal/useCalendarEvent'
import CheckInLesson from '../../../../../Model/Lessons/CheckInLesson'
import useLinkLesson from '../InfoModal/useLinkLesson'
import {hasHttp} from '../InfoModal/utils'
import CancelLesson from '../CancelLesson'
import moment from 'moment-timezone'
import { useLazyQuery, gql } from '@apollo/client'


const useLazyGetUser = () => {
  return useLazyQuery(
    gql`
      query (
        $id: String!
      ) {
        user (id: $id) {
          educatorInfo {backupLink}
          staffInfo {backupLink}
        }
      }
    `, {fetchPolicy: 'network-only'}
  )
}

const OrientationModal = (props) => {
  const {refetchLessons, typeUser = 'student'} = props
  // redux modal para lecciones de orientación
  const reduxOrientation = useSelector(
    (state) => state.modal.student.orientationLessonModal,
  )
  const {isOpen: isOpenOrientation, idLesson} = reduxOrientation
  const {translate} = useTranslation()
  const dispatch = useDispatch()

  const [
    getDataLesson,
    {data: dataLessonOrientation, loading: loadDataLesson},
  ] = useCalendarEvent(
    () => {},
    () => {
      showAlert(
        {
          text: translate('student.infolesson.getDataLessonError'),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  useEffect(() => {
    if (idLesson) {
      getDataLesson({
        variables: {
          calendarEventId: idLesson,
        },
      })
    }
    // eslint-disable-next-line
    }, [idLesson])
  const dataLesson = useMemo(() => {
    return dataLessonOrientation?.calendarEvent || {}
  }, [dataLessonOrientation])

  const {loading: loadCheckIn, submit: checkIn} = CheckInLesson(
    () => {
      // refetchOrientation()
      // alert("success")
      getDataLesson({
        variables: {
          calendarEventId: idLesson,
        },
      })
    },
    () => {
      // alert("error")
    },
  )

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const [backupLoad, setBackupLoad] = useState(false)

  const [timeToStart, setTimeToStart] = useState(
    moment.duration(moment(dataLesson?.startDate).diff(moment())).asMinutes(),
  )
  useEffect(() => {
    setTimeToStart(
      moment.duration(moment(dataLesson?.startDate).diff(moment())).asMinutes(),
    )
    // inicia cuenta para habilitar el botón checkIn
    const initTime = moment
      .duration(
        moment(dataLesson?.startDate).subtract(10, 'minutes').diff(moment()),
      )
      .asMilliseconds()
    const timer = setTimeout(() => {
      setTimeToStart(
        moment
          .duration(moment(dataLesson?.startDate).diff(moment()))
          .asMinutes(),
      )
    }, initTime)
    return () => clearTimeout(timer)
  }, [dataLesson])

  const isOnNext24 = useMemo(() => {
    return timeToStart / 60 < 24
  }, [timeToStart])

  const [getBackUpLink, {loading: loadGetBackup}] = useLazyGetUser()

  return (
    <>
      <OrientationLessonModal
        role={typeUser}
        isOpen={isOpenOrientation && !loadDataLesson}
        toggle={() => {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'student.orientationLessonModal',
            data: {
              ...reduxOrientation,
              isOpen: !isOpenOrientation,
            },
          })
        }}
        dataLesson={dataLesson}
        onRescheduled={(dataLesson) => {
          const isLocked = dataLesson?.isLocked
          const isGroupLesson = dataLesson?.__typename === 'GroupLesson'
          const currentStudent =
            dataLesson?.student ||
            dataLesson?.studentPlan ||
            dataLesson?.studentGroup ||
            {}
          isOnNext24 || isLocked || isGroupLesson
            ? dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'program.cantReschedule',
                data: {
                  isOpen: true,
                  isLocked,
                  isOnNext24,
                  isGroupLesson,
                },
              })
            : dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'program.rescheduledModal',
                data: {
                  isOpen: true,
                  stage: 'timeSelectionBlock',
                  educator: dataLesson?.educator,
                  selectedBlock: null,
                  refetchLessons,
                  lesson: {
                    lessonId: dataLesson?.id,
                    name: dataLesson?.title,
                    service: dataLesson?.service,
                    topic: dataLesson?.topic,
                  },
                  plan: currentStudent,
                  setOpenInfoModal: (bool) => {
                    dispatch({
                      type: 'SET_DATA_MODAL_FORM_LIST',
                      payload: 'student.infoLessonModal',
                      data: {
                        isOpen: bool,
                        dataLesson,
                      },
                    })
                  },
                },
              })
        }}
        rescheduledText={`${translate('modal.infolesson.rescheduled')}`}
        onCheckIn={(dataLesson) => {
          checkIn({lessonId: dataLesson?.id || ''})
        }}
        onCheckInLoad={loadCheckIn}
        onCancel={() => {}}
        CancelComponent={
          <CancelLesson
            startDate={dataLesson?.startDate}
            lessonId={dataLesson?.id}
            refetchLessons={() => {}}
            isLocked={dataLesson?.isLocked}
            isGroupLesson={dataLesson?.__typename === 'GroupLesson'}
            className='pb-4'
          >
            <span className='h5 text-gray m-0 p-0'>
              {translate('modal.infolesson.youneedremove')}
            </span>
          </CancelLesson>
        }
        cancelText={translate('modal.infolesson.youneedremove')}
        onGoToClass={(dataLesson) => {
          // si location es VCR es launchURL, sino es location.linkStudent!
          setGoToClassLoad(true)
          getvclink(dataLesson?.id, null)
            .then(
              ({launchUrl, location}) => {
                if (
                  location.type === 'virtualClassroom' ||
                  location.type === 'zinkerzPlatform'
                )
                  window.open(hasHttp(launchUrl), '_blank')
                else window.open(hasHttp(location.linkStudent), '_blank')
                setGoToClassLoad(false)
              },
              (error) => {
                console.error(error)
                // setVcLinkError(true)
                setGoToClassLoad(false)
              },
            )
            .catch((error) => {
              console.error(error)
              // setVcLinkError(true)
              setGoToClassLoad(false)
            })
        }}
        onGoToClassLoad={goToClassLoad}
        gotoclassText='Go to classroom'
        onBackUp={(dataLesson) => {
          setBackupLoad(true)
          getBackUpLink({variables: {id: dataLesson?.educator?.educatorUserId}}).then(
            (res) => {
              const link = res?.data?.user?.staffInfo?.backupLink || res?.data?.user?.educatorInfo?.backupLink
              if (link?.length > 0) {
                window.open(hasHttp(link), '_blank')
              }
              else showAlert({text: translate('modal.infolesson.errorToGetOrientatorBackUp'), status: 'error'}, dispatch)
            }
          ).catch(() => {}).finally(() => {setBackupLoad(false)})
        }}
        onBackUpLoad={backupLoad || loadGetBackup}
        backupText='Backup call'
        studentText={translate('modal.infolesson.student')}
        educatorText={translate('modal.infolesson.educator')}
        refetchLesson={() => {
          getDataLesson({
            variables: {
              calendarEventId: idLesson,
            },
          })
        }}
      />
    </>
  )
}

export default OrientationModal
