import React, {useState} from 'react'
import {IconProgram, ListItem} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import Unavailability from './SetAvailable'
import UnavailabilityRecurring from './SetAvailableRecurring'
import useTranslation from '../../../../../i18n/useTranslation'

const EducatorAvailability = () => {
  const [availableOpen, setAvailableOpen] = useState(false)
  const toggleCollapse = () => {
    setAvailableOpen(!availableOpen)
  }
  const {translate} = useTranslation()
  return (
    <>
      <ListItem
        className='w-100'
        showTopLine={false}
        showBottomLine
        hasHover
        hasColorHover
        onClick={toggleCollapse}
        Left={
          <span className='d-flex align-items-center'>
            <IconProgram size={16} className='mr-3' />
            <span className='text-black'>
              {translate('profile.actions.availability.name')}
            </span>
          </span>
        }
      />
      <Collapse isOpen={availableOpen}>
        <Unavailability />
        <UnavailabilityRecurring />
      </Collapse>
    </>
  )
}

export default EducatorAvailability
