import React from 'react'
import {IconIllustratedTopic} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const TopicSelectionHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <>
      <div className='w-100 p-0 mt-2'>
        <IconIllustratedTopic size={80} />
        <div className='mt-2 h2 text-body font-weight-bold'>
          {translate('lessons.whattopicwant')}
        </div>
      </div>
    </>
  )
}

export default TopicSelectionHeader
