import React, {useState, useEffect} from 'react'
import {
  Button,
  IconCircleBack,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {usePDF} from '@react-pdf/renderer'
import ScheduledOrientation from './Modal/ScheduledOrientation'
import useScheduledOrientation from './Modal/ScheduledOrientation/useScheduledOrientation'
import useBubblesModalHeader from '../../../../bubbles/useBubblesModalHeader'
import useApproveCounselingPlan from '../../Model/useApproveCounselingPlan'
import useUploadPdfBase64 from '../../Model/useUploadPdfBase64'
// import useUploadPdfBase64 from '../../Model/useUploadPdfBase64'
import moment from 'moment'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch, useSelector} from 'react-redux'
import axios from 'axios'
import useTranslation from '../../../../i18n/useTranslation'
import GetPdfProposalObject from '../Proposal/PdfViewer/GetPdfProposalObject'
import useElementsHandler from '../../Elements/useElementsHandler'
import CompleteProfile from '../../../Onboarding/components/CompleteProfile'
import useUpdateOnboardingStatus from '../../Model/useUpdateOnboardingStatus'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const Orientation = (props) => {
  const {setSection, counselingOnboarding, setData} = props
  const bubblesStyle = useBubblesModalHeader()
  const {open: openScheduledOrientationModal, close} = useScheduledOrientation()
  const {breakWidth} = useWindowDimensions()
  const isMobile = breakWidth === 'SM'
  const isTablet = breakWidth === 'MD'
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const refetchUser = useSelector((state) => state.user.refetch)

  const [updateOnboading] = useUpdateOnboardingStatus(
    () => {},
    () => {},
  )
  const setOnboardingStatusToApproving = async (planId) => {
    await updateOnboading({variables: {planId, newStatus: 'approving'}})
  }

  const [approvePlan, {loading: loadApprove}] = useApproveCounselingPlan(
    (res) => {
      const response = res?.approveCounselingPlan || {success: true}
      if (response?.success) {
        showAlert(
          {text: 'Plan has been approved successfully!', status: 'success'},
          dispatch,
        )
        // refetch del usuario en redux
        refetchUser()
        setSection('congratulations')
      } else {
        // mutación responde error
        showAlert(
          {
            text: response?.errorMsg?.toString() || 'Error, please try again!',
            status: 'error',
          },
          dispatch,
        )
      }

      setLoading(false)
    },
    (err) => {
      console.error(err)
      showAlert({text: err?.toString(), status: 'error'}, dispatch)
      setLoading(false)
    },
  )

  // sube documento pdf a s3
  const [uploadToS3] = useUploadPdfBase64(
    (res) => {
      approvePlan({
        variables: {
          parentSign: counselingOnboarding?.parentSignUrl,
          studentSign: counselingOnboarding?.studentSignUrl,
          approveInput: {
            planId: counselingOnboarding?.myPlan?.id,
            typePayment: counselingOnboarding?.periodicity,
            paymentItemsConfig: counselingOnboarding?.paymentItemsConfig.map(
              (paymentItem) => {
                return {
                  amount: paymentItem?.amount,
                  percentDiscount: paymentItem?.percentDiscount,
                  amountWithoutDiscount: paymentItem?.amountWithoutDiscount,
                  date: paymentItem?.date,
                  description: paymentItem?.description,
                  type:
                    paymentItem?.type === 'others-fixed'
                      ? 'otherFixed'
                      : paymentItem?.type === 'counseling-payment'
                      ? 'counselingPayment'
                      : paymentItem?.type,
                }
              },
            ),
            studentId: counselingOnboarding?.studentUser?.studentUserId,
            councelorInput: {
              startDate: moment(counselingOnboarding.orientation?.time)
                .utc()
                .toISOString(),
              endDate: moment(counselingOnboarding.orientation?.time)
                .utc()
                .add(15, 'minutes')
                .toISOString(),
              councelorId: counselingOnboarding.orientation?.educator?.id,
              planId: counselingOnboarding?.myPlan?.id,
              // phase: 'ID',
              // stepId: 'ID',
              // taskId: 'ID',
            },
            proposal: res?.uploadPdfBase64,
          },
        },
      })
    },
    (err) => {
      console.error(err)
      showAlert({text: err?.toString(), status: 'error'}, dispatch)
      setLoading(false)
    },
  )

  const completeOnboarding = (url) => {
    // obtiene pdf en base 64 para subir a s3
    axios.get(url, {responseType: 'blob'}).then(
      (result) => {
        getBase64(result?.data).then((data) => {
          // subir pdf a S3
          uploadToS3({
            variables: {
              file: data,
              bucket: `media.zinkerz.com/counseling/${counselingOnboarding?.studentUser?.studentUserId}/${counselingOnboarding?.myPlan?.id}`,
              fileName: `proposal-sign`,
              type: 'pdf',
            },
          })
        })
      },
      (err) => {
        console.error(err)
        showAlert({text: err?.toString(), status: 'error'}, dispatch)
        setLoading(false)
      },
    )
  }

  const {translate} = useTranslation()

  const [generateDocument, setGenerateDocument] = useState(false)

  const {RenderHtmlDocument} = useElementsHandler()

  const [documentArray, setDocumentArray] = useState([])
  const [finalDocumentArray, setFinalDocumentArray] = useState(null)
  const [documentInstance, setDocumentInstance] = usePDF({
    document: RenderHtmlDocument(documentArray),
  })

  // renderiza las firmas de estudiante y padre
  useEffect(() => {
    if (
      // (counselingOnboarding?.studentSignUrl ||
      //   counselingOnboarding?.parentSignUrl) &&
      documentArray?.length > 0
    ) {
      // return
      const documentObj = documentArray
      // renderiza las firmas del estudiante y del padre si lo tiene

      setFinalDocumentArray(documentObj)
      // return RenderHtmlDocument(documentObj)
      // setDocumentInstance({document: <>{RenderHtmlDocument(documentObj)}</>})
      // }
    }
    // eslint-disable-next-line
    }, [
    // counselingOnboarding.studentSignUrl,
    // counselingOnboarding.parentSignUrl,
    documentArray,
  ])

  useEffect(() => {
    if (finalDocumentArray) {
      // setDocument({Html, jsonDocument})
      const document = RenderHtmlDocument(finalDocumentArray)
      setDocumentInstance({document: document})
    }
    // eslint-disable-next-line
  }, [finalDocumentArray])
  useEffect(() => {
    if (documentInstance?.url && finalDocumentArray) {
      completeOnboarding(documentInstance.url)
    }
    // eslint-disable-next-line
  }, [documentInstance?.url])

  const [isOpenProfile, setOpenProfile] = useState(false)
  const completeProfile = false

  const beginOperation = () => {
    setLoading(true)
    setTimeout(() => {
      setGenerateDocument(true)
      // actualiza estado del onboarding a "aprobando"
      const planId =
        counselingOnboarding?.myPlan?.id || counselingOnboarding?.myPlan?.planId
      setOnboardingStatusToApproving(planId)
    }, 2000)
  }
  return (
    <>
      <div className='d-flex flex-row justify-content-center my-6'>
        <div
          className={`${
            isMobile ? 'w-100' : isTablet ? 'w-70' : 'w-50'
          } py-7 d-flex flex-row justify-content-center`}
          style={{
            boxShadow: '1px 5px 15px 5px rgba(0,0,0,0.27)',
            backgroundColor: 'rgb(50,64,91)',
            borderRadius: '20px',
            ...bubblesStyle,
          }}
        >
          <div
            className={`${
              isMobile ? 'w-90' : isTablet ? 'w-80' : 'w-60'
            } d-flex flex-column pt-4`}
            style={{lineHeight: '1.5'}}
          >
            <div className='w-100 d-flex flex-column justify-content-start mb-4'>
              <div
                onClick={() => {
                  setSection('payments')
                }}
                className='hover-icon dark'
                style={{
                  width: 'fit-content',
                  left: '-10px',
                  position: 'relative',
                }}
              >
                <IconCircleBack color='white' size={20} />
              </div>
            </div>
            <span className='h1 text-white mb-5'>
              {translate('counselingOnboarding.orientation.header.title')}
            </span>
            <span className='h4 text-white mb-5'>
              {translate('counselingOnboarding.orientation.header.info')}
            </span>
            <Button
              check
              className='w-70'
              activity={loadApprove || loading}
              label={
                <span className='white-space-nowrap mr-5'>
                  {translate(
                    'counselingOnboarding.orientation.header.scheduledOrientation',
                  )}
                </span>
              }
              onClick={() => {
                openScheduledOrientationModal((selectedTime, educator) => {
                  setData({
                    ...counselingOnboarding,
                    orientation: {
                      time: selectedTime,
                      educator,
                    },
                  })
                  close()
                  if (completeProfile) {
                    // abrir complete profile
                    setOpenProfile(true)
                  } else {
                    beginOperation()
                  }

                  // setOnboardingCompleted(true)
                  // ejecutar mutación approveCounselingPlan
                }, counselingOnboarding?.myPlan)
              }}
              color='primary'
              size='large'
            />
          </div>
        </div>
        <ScheduledOrientation />
      </div>
      <CompleteProfile
        isOpen={isOpenProfile}
        toggle={() => {
          setOpenProfile(!isOpenProfile)
        }}
        isCounselingOnboarding
        onChange={() => {
          setOpenProfile(false)
          beginOperation()
        }}
      />
      <GetPdfProposalObject // obtiene ARREGLO que confecciona propuesta pdf
        counselingPlan={{
          ...counselingOnboarding?.myPlan,
          // incluye las firmas
          studentSignUrl: counselingOnboarding?.studentSignUrl,
          parentSignUrl: counselingOnboarding?.parentSignUrl,
          paymentItemsConfig: counselingOnboarding?.paymentItemsConfig,
        }}
        start={generateDocument}
        setDocumentArray={(res) => {
          if (res.length > 0) {
            setDocumentArray(res)
            setGenerateDocument(false)
          }
        }}
      />
    </>
  )
}

export default Orientation
