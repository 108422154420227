export const extractTeamFromPlan = (plan) => {
  if (!plan) return []
  const dataTeam = []
  if (plan?.advisor)
    dataTeam.push({
      ...plan?.advisor,
      id: plan?.advisor?.teamUserId,
      avatar: plan?.aditionalInfo?.avatarAdvisor,
      role: 'Advisor',
    })
  if (plan?.counselorData)
    dataTeam.push({
      ...plan?.counselorData,
      id: plan?.counselorData?.teamUserId,
      avatar: plan?.aditionalInfo?.avatarCounselor,
      role: 'Counselor',
    })
  if (plan?.head)
    dataTeam.push({
      ...plan?.head,
      id: plan?.head?.teamUserId,
      avatar: plan?.aditionalInfo?.avatarHead,
      role: 'Head',
    })

  return dataTeam
}
