import {useSelector, useDispatch} from 'react-redux'

const useSetStudentPhoneModal = () => {
  const dispatch = useDispatch()
  // Para que hook funciones debe haber solo UN componente NewTaskModal
  // renderizado en el dominio
  const reduxData = useSelector(
    (state) => state.modal.onboarding.setStudentPhone,
  )
  const {isOpen} = reduxData
  const setOpen = (open, onChange = () => {}, studentId = null) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'onboarding.setStudentPhone',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        studentId,
      },
    })
  }

  return {
    ...reduxData,
    open: (onChange = () => {}, studentId = null) => {
      setOpen(true, onChange, studentId)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
  }
}

export default useSetStudentPhoneModal
