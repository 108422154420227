import React, {useMemo} from 'react'
import {TimelinePlan} from '@Knowledge-OTP/znk-ui-components'
import useTimelineReducer from '../../../../../reducers/timeline'

const LivePrepTimeline = (props) => {
  const {plan} = props

  // timeline reducer for parse method
  const {parseTimelineFromAPI, parseToTimeline} = useTimelineReducer()
  const timeline = useMemo(() => {
    if (plan?.timelines) {
      return parseTimelineFromAPI({
        plans: plan?.timelines?.plans || [],
        events:
          (plan?.timelines?.events || []).concat(plan?.timelines?.test || []) ||
          [],
      })
    }
    // events: []
    // plans: [{…}]
    // tests: []
  }, [plan, parseTimelineFromAPI])

  return (
    <div className='mt-5'>
      {timeline && (
        <TimelinePlan
          events={parseToTimeline(
            timeline.filter(({statusBuild}) => statusBuild !== 'delete'),
            () => {},
            () => {},
          )}
        />
      )}
    </div>
  )
}

export default LivePrepTimeline
