import React, {useMemo} from 'react'
import {
  StatsCustomized,
  IconSignCheck,
  IconProgram,
  IconTime,
  IconBank,
} from '@Knowledge-OTP/znk-ui-components'

const PlanProgress = (props) => {
  const {className, plan} = props
  const {
    completed,
    programed,
    pendings,
    applications,
    totalValue,
  } = useMemo(() => {
    const tasks = plan?.tasks?.reduce((array1, {steps}) => {
      return array1.concat(
        steps.reduce((array2, {tasks}) => {
          return array2.concat(tasks)
        }, []),
      )
    }, [])
    return {
      completed: tasks.filter(({status}) => status === 'completed')?.length,
      programed: tasks.filter(({status}) => status === 'review')?.length,
      pendings: tasks.filter(({status}) => status === 'pending')?.length,
      applications: 0,
      totalValue: tasks?.length,
    }
  }, [plan])
  return (
    <div className={`d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Progress</span>
      <div className='p-2'>
        <StatsCustomized
          textColor='counseling'
          statsClassName='text-info'
          totalValue={totalValue}
          items={[
            {
              Icon: IconSignCheck,
              label: 'Completed',
              value: completed,
              isProgress: true,
            },
            {
              Icon: IconProgram,
              label: 'Programed',
              value: programed,
              isProgress: false,
            },
            {
              Icon: IconTime,
              label: 'Pendings',
              value: pendings,
              isProgress: false,
            },
            {
              Icon: IconBank,
              label: 'Applications',
              labelRight: '0/4',
              value: applications,
              isProgress: false,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default PlanProgress
