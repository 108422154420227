import React, {useState} from 'react'
import {gql, useMutation} from '@apollo/client'
import useTranslation from '../../../../../i18n/useTranslation'
import {
  Modal,
  Rating,
  Toast,
  Activity,
  IconSignCheck,
} from '@Knowledge-OTP/znk-ui-components'

const RateLesson = (props) => {
  const {open, roomId} = props
  const [toastOpen, setToastOpen] = useState(false)
  const {translate} = useTranslation()
  const [isOpen, setOpen] = useState(open)
  const [rating, setRating] = useState(0)
  const toggle = () => {
    setOpen((open) => !open)
  }
  const [mutation, {loading}] = useMutation(
    gql`
      mutation($ratingInput: Float!, $roomId: String) {
        ratingLessonForStudent(rating: $ratingInput, braincertId: $roomId) {
          id
          student {
            rating
          }
        }
      }
    `,
  )

  const updateRating = () => {
    mutation({variables: {ratingInput: rating, roomId}}).then(() => {
      setToastOpen(true)
      toggle()
      setTimeout(function () {
        setToastOpen(false)
      }, 3000)
    })
  }

  return (
    <>
      <Toast
        className='secondary-toast'
        open={toastOpen}
        Left={translate('lessons.modal.rate.rating')}
        Right={<IconSignCheck size={16} />}
      />
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='row m-0 px-4 py-3'>
          <span className='h2 m-0 pr-5'>
            <b>{translate('lessons.modal.rate.rate')}</b>
          </span>
          <span className='text-muted h4 m-0 pr-5 py-4'>
            {translate('lessons.modal.rate.description')}
          </span>
          <div className='m-0 p-0 d-flex flex-column w-100'>
            <Rating onChange={setRating} className='w-100' mode='edit' />
            <div className='d-flex align-self-end mt-3'>
              {loading ? (
                <Activity />
              ) : (
                <div>
                  <button
                    className='btn btn-link p-0 text-muted mr-3'
                    onClick={toggle}
                  >
                    {translate('lessons.modal.rate.cancel')}
                  </button>
                  <button
                    onClick={() => {
                      updateRating()
                    }}
                    className='btn btn-link p-0'
                  >
                    {translate('lessons.modal.rate.cont')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RateLesson
