import React, {useMemo, useState} from 'react'
import {
  TimelinePlan,
  TemplateTimeline,
  TemplateTimelineSkeleton,
  IconOptions,
  IconList,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import useTimelineReducer from '../../../reducers/timeline'
import useTemplateReducer from '../../../reducers/template'
import useCounselingPlanReducer from '../../../reducers/counseling-plan'
import MyCounselingPlan from '../../../Model/Users/Plans/MyCounselingPlan'
import useViewPlanModal from '../../MyPlans/viewCounselingPlanModal/useViewPlanModal'
import useShowTask from '../../Workspace/Student/ViewTask/useShowTask'
import {TabContent, TabPane} from 'reactstrap'

const TimelineView = (props) => {
  const {className, showTitle = false, showLink = false} = props

  // reducers
  const {filterDelete} = useTemplateReducer()
  const {parsePlanFromAPI} = useCounselingPlanReducer()
  const {parseTimelineFromAPI, parseToTimeline} = useTimelineReducer()

  const {open: openTask} = useShowTask()
  const user = useSelector((state) => state.user?.info)
  const counselingPlanId = user?.studentInfo?.homeNotification?.councelingPlanId
  // obtiene el cp actual del usuario
  const {data, loading} = MyCounselingPlan({planId: counselingPlanId})

  const plan = useMemo(() => {
    return parsePlanFromAPI(data)
  }, [data, parsePlanFromAPI])
  const timeline = useMemo(() => {
    if (plan?.timelines) {
      return parseTimelineFromAPI({
        plans: plan?.timelines?.plans || [],
        events:
          (plan?.timelines?.events || []).concat(plan?.timelines?.test || []) ||
          [],
      })
    }
    // events: []
    // plans: [{…}]
    // tests: []
  }, [plan, parseTimelineFromAPI])

  const {open: openViewPlan} = useViewPlanModal()
  const [activeTab, setActiveTab] = useState('cp-timeline')

  return (
    <>
      <div
        className={`d-flex flex-row ${
          showTitle && showLink
            ? 'justify-content-between'
            : showLink
            ? 'justify-content-end'
            : 'justify-content-start'
        } align-items-center m-0 p-0 w-100`}
      >
        <div className='d-flex flex-row align-items-center mb-3'>
          {showTitle && (
            <h3 className='font-weight-bold mr-2'>
              {activeTab === 'cp-timeline'
                ? 'Counseling Timeline'
                : 'LivePrep Timeline'}
            </h3>
          )}
          <span>
            <TabContent activeTab={activeTab}>
              <TabPane tabId='cp-timeline'>
                <div
                  className='hover-icon'
                  onClick={() => {
                    setActiveTab('lp-timeline')
                  }}
                >
                  <IconList size={16} />
                </div>
              </TabPane>
              <TabPane tabId='lp-timeline'>
                <div
                  className='hover-icon'
                  onClick={() => {
                    setActiveTab('cp-timeline')
                  }}
                >
                  <IconOptions size={16} className='rotate-90' />
                </div>
              </TabPane>
            </TabContent>
          </span>
        </div>
        {showLink && (
          <span
            className='btn-link h45 mr-2 mb-3'
            onClick={() => {
              openViewPlan(() => {}, plan /** , 'live-prep-timeline' */)
            }}
          >
            View plan
          </span>
        )}
      </div>
      <div className={`px-4 ${className}`}>
        <TabContent activeTab={activeTab} className='w-100'>
          <TabPane tabId='lp-timeline'>
            {timeline && timeline?.length > 0 ? (
              <TimelinePlan
                events={parseToTimeline(
                  timeline.filter(({statusBuild}) => statusBuild !== 'delete'),
                  () => {},
                  () => {},
                )}
              />
            ) : (
              <div className='m-0 p-0 w-100 d-flex flex-row justify-content-center'>
                <span className='h45 text-gray'>No timeline found</span>
              </div>
            )}
          </TabPane>
          <TabPane tabId='cp-timeline' className='w-100'>
            <TabContent
              activeTab={loading ? 'loading' : plan?.tasks ? 'show' : 'blank'}
            >
              <TabPane tabId='loading'>
                <TemplateTimelineSkeleton />
              </TabPane>
              <TabPane tabId='show'>
                <TemplateTimeline
                  className='w-100 p-0'
                  isEdit={false}
                  stages={
                    plan?.tasks ? filterDelete({phases: plan.tasks}).phases : []
                  }
                  onClickTask={(task) => {
                    // abre modal para ver tarea
                    openTask(() => {}, {...task, isEdit: false})
                  }}
                />
              </TabPane>
              <TabPane tabId='blank'>
                <div className='m-0 p-0 w-100 d-flex flex-row justify-content-center'>
                  <span className='h45 text-gray'>No timeline found</span>
                </div>
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </div>
    </>
  )
}

export default TimelineView
