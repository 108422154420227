import React, {useState} from 'react'
import {
  SignInput,
  SignGroup,
  ScoreItem,
  ScoreListItem,
  ModalScroll,
  IconClose,
  RedRibbon,
  LogoItem,
  TemplateTimeline,
  XlsxItem,
  PasswordField,
  BlankSpace,
  ProcessItem,
  IconIllustratedReminder,
  IconMessages,
  FileItem,
  DocumentInfoSection,
  DocumentInfoGroup,
  ListOption,
  IconLearn,
  ListOptionSelect,
  ModalLayout,
  IconIllustratedCalendar,
  // CarouselList,
  Modal,
} from '@Knowledge-OTP/znk-ui-components'
import Layout from './common/Layout'
import {Switch, Route, Redirect} from 'react-router-dom'
import CalendarView from './Pages/Calendar'
// import LearnView from './Pages/Learn'
import WorkspaceView from './Pages/Workspace'
import ProfileView from './Pages/Profile'
import OnBoardingView from './Pages/Onboarding'
import CalendarLessonsView from './Pages/CalendarLessons'
import CalendarViewStudent from './Pages/CalendarStudent'
import HomeView from './Pages/Home'
import 'moment/locale/es'
import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'
import './App.css'
// import {useSelector} from 'react-redux'
import useSocketEntities from './common/SocketGateway/useSocketEntities'
import StudentView from './Pages/Workspace/Student/index'
import CounselingOnboardingView from './Pages/CounselingOnboarding'
import CounselingProposalView from './Pages/CounselingProposal'
import logo from './assets/Avatar.png'
// import MyPlans from './Pages/MyPlans'
import ViewPlanModal from './Pages/MyPlans/viewCounselingPlanModal'
import RenderModals from './Pages/RenderModals'
import Hotjar from '@hotjar/browser'
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY)

function App(props) {
  const {empty, socket} = useSocketEntities()
  const siteId = 3607664
  const hotjarVersion = 6
  Hotjar.init(siteId, hotjarVersion)
  // const socket = useSelector((state) => state.socket.socket)
  const [isOpen, setOpen] = useState(false)
  const [isOpenSection, setIsOpenSection] = useState(false)
  const preSteps = new Array(5).fill(0).map((_, index) => {
    return {
      id: `pre-${index}`,
      stageId: 'pre',
      title: 'Title',
      subtitle: 'Subtitle',
    }
  })
  const appSteps = Array(2)
    .fill(0)
    .map((_, index) => {
      return {
        id: `app-${index}`,
        stageId: 'app',
        title: 'Title 2',
        subtitle: 'Subtitle 2',
      }
    })
  const postSteps = new Array(7).fill(0).map((_, index) => {
    return {
      id: `pre-${index}`,
      stageId: 'post',
      title: 'Title 3',
      subtitle: 'Subtitle 3',
    }
  })
  const [stages, setStages] = useState([
    {
      id: 'pre',
      title: 'Pre Application',
      steps: preSteps,
    },
    {
      id: 'app',
      title: 'Application',
      steps: appSteps,
    },
    {
      id: 'post',
      title: 'Post Application',
      steps: postSteps,
    },
  ])
  const onCreate = (props) => {
    setStages(
      stages.map((stage) => {
        const {id} = stage
        if (id === props.id)
          return {
            ...props,
            steps: props.steps.concat([
              {
                id: `${id}-${props.steps.length}`,
                stageId: id,
                title: 'New title',
                subtitle: 'New subtitle',
              },
            ]),
          }
        return stage
      }),
    )
  }
  const onEdit = (step) => {
    setStages(
      stages.map((stage) => {
        const {id} = stage
        if (id === step.stageId)
          return {
            ...stage,
            steps: stage.steps.map((stepIt) => {
              if (stepIt.id === step.id)
                return {
                  ...stepIt,
                  title: 'Edit',
                  subtitle: 'Edit',
                }
              return stepIt
            }),
          }
        return stage
      }),
    )
  }
  const [selectedOption, setSelectedOption] = useState(null)

  return (
    <Elements stripe={stripePromise}>
      <Layout>
        <Switch>
          <Route exact path='/home' component={HomeView} />
          <Route
            exact
            path='/calendar/calendar-view'
            component={CalendarViewStudent}
          />
          <Route path='/calendar' component={CalendarView} />
          <Route path='/workspace' component={WorkspaceView} />
          <Route path='/profile' component={ProfileView} />
          <Route path='/learn' component={StudentView} />
          <Route path='/onboarding/:idPlan' component={OnBoardingView} />
          <Route path='/lessons' component={CalendarLessonsView} />
          <Route
            path='/counseling-onboarding/:counselingPlanId'
            component={CounselingOnboardingView}
          />
          <Route
            path='/counseling-proposal/:counselingPlanId'
            component={CounselingProposalView}
          />

          {/* <Route path='/myCouncelingPlan' component={ViewCouncelingPlanModal} /> */}
          <Route path='/myCouncelingPlan' component={ViewPlanModal} />
          <Route
            path='/focus'
            render={() => {
              const text = [
                'Verde',
                'Rojo',
                'Violeta',
                'Celeste',
                'Negro',
                'Amarillo',
                'Gris',
              ]
              const classes = [
                'red',
                'blue',
                'green',
                'cyan',
                'black',
                'yellow',
              ]
              const getRandomIndex = (len) => Math.floor(Math.random() * len)
              return (
                <div className='row m-7'>
                  {new Array(20).fill(0).map((_, idx) => {
                    return (
                      <div
                        key={idx}
                        className='col-4 my-2 font-weight-bold h1 text-center'
                        style={{
                          color: classes[getRandomIndex(classes.length)],
                        }}
                      >
                        {text[getRandomIndex(text.length)]}
                      </div>
                    )
                  })}
                </div>
              )
            }}
          />

          <Route
            path='/components'
            render={() => {
              return (
                <div
                  className='m-0 p-6 d-flex flex-column h-100'
                  style={{overflowY: 'scroll'}}
                >
                  <div>
                    {/* <CarouselList /> */}
                    <SignGroup>
                      <SignInput />
                      <SignInput closed />
                    </SignGroup>

                    <SignInput closed showing />

                    <button
                      onClick={() => {
                        setOpen(!isOpen)
                      }}
                    >
                      ABRIR
                    </button>
                    <ModalScroll
                      isOpen={isOpen && false}
                      toggle={() => {
                        setOpen(!isOpen)
                      }}
                      className='modal-colored modal-sidebar modal-desktop-half exam-modal'
                      MinHead={
                        <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
                          <div className='d-flex flex-column align-items-center'>
                            <span className='h3 text-white font-weight-bold'>
                              SAT, Jun 2020
                            </span>
                            <span className='h4 text-white'>
                              June 15, 2020 - 3:00p
                            </span>
                          </div>
                          <div className='d-flex flex-row align-items-center'>
                            <IconClose
                              className='text-cancel'
                              onClick={() => {
                                setOpen(!isOpen)
                              }}
                            />
                          </div>
                        </div>
                      }
                      Head={
                        <div className='modal-header px-6 px-sm-7 mt-0 pt-8 d-flex flex-column'>
                          <RedRibbon className='ml-3' />
                          <div className='m-0 p-0 d-flex flex-column my-2'>
                            <span className='h3 text-white font-weight-bold'>
                              SAT, Jun 2020
                            </span>
                            <span className='h4 text-white'>
                              June 15, 2020 - 3:00p
                            </span>
                          </div>
                          <div className='d-flex flex-row w-100 justify-content-between'>
                            <ScoreItem
                              className='m-0 mr-1'
                              serviceLabel='SAT'
                              scoreLabel='100,0'
                            />
                            <ScoreItem
                              className='m-0 mr-1'
                              serviceLabel='SAT'
                              scoreLabel='100,0'
                            />
                          </div>
                        </div>
                      }
                    >
                      <div className='m-0 p-0 d-flex flex-column mx-2'>
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                        <ScoreListItem className='m-1' />
                      </div>
                    </ModalScroll>

                    <div className='d-flex flex-row'>
                      {new Array(10).fill(0).map((_, index) => {
                        return (
                          <LogoItem
                            active={index % 2 === 0}
                            className='mr-1'
                            image={logo}
                          />
                        )
                      })}
                    </div>
                    <DocumentInfoSection>
                      <span>oaickaimca</span>
                    </DocumentInfoSection>

                    <DocumentInfoGroup className='my-2'>
                      <DocumentInfoSection>
                        <span>oaickaimca</span>
                      </DocumentInfoSection>
                      <DocumentInfoSection>
                        <span>oaickaimca</span>
                      </DocumentInfoSection>
                      <DocumentInfoSection>
                        <span>oaickaimca</span>
                      </DocumentInfoSection>
                      <DocumentInfoSection>
                        <span>oaickaimca</span>
                      </DocumentInfoSection>
                    </DocumentInfoGroup>

                    <ListOptionSelect
                      onChange={(data, key) => {
                        setSelectedOption(data)
                      }}
                    >
                      <ListOption
                        selected={selectedOption?.key === 'option 1'}
                        data-selected-key='option 1'
                        data-selected={JSON.stringify({
                          key: 'option 1',
                        })}
                        childClassName='d-flex flex-row'
                        label={
                          <div className='d-flex flex-column pl-1'>
                            <span className='h4 text-title'>Live Prep</span>
                            <span className='h45 text-disabled'>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </span>
                          </div>
                        }
                        icon={
                          <div className='h-inherit d-flex flex-column justify-content-start py-1'>
                            <IconLearn size={15} />
                          </div>
                        }
                      />
                      <ListOption
                        selected={selectedOption?.key === 'option 2'}
                        data-selected-key='option 2'
                        data-selected={JSON.stringify({
                          key: 'option 2',
                        })}
                        childClassName='d-flex flex-row'
                        label={
                          <div className='d-flex flex-column pl-1'>
                            <span className='h4 text-title'>Live Prep</span>
                            <span className='h45 text-disabled'>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </span>
                          </div>
                        }
                        icon={
                          <div className='h-inherit d-flex flex-column justify-content-start py-1'>
                            <IconLearn size={15} />
                          </div>
                        }
                      />
                    </ListOptionSelect>

                    <div className='d-flex flex-row justify-content-center'>
                      <TemplateTimeline
                        className='w-50'
                        isEdit
                        onEdit={onEdit}
                        onCreate={onCreate}
                        stages={stages}
                      />
                    </div>
                    <XlsxItem />
                    <PasswordField
                      placeholder='Enter password'
                      style={{width: '26%'}}
                      onChange={(value) => {
                        console.log('value', value)
                      }}
                    />
                    <BlankSpace
                      icon={<IconIllustratedReminder size={80} />}
                      linkProps={{
                        text: 'Learn more about college counseling',
                        link: '',
                      }}
                      mainText='Aquí podrás ver los documentos en los que tengas que trabajar y los examenes para que ingreses tu puntaje.'
                    />
                    <ProcessItem
                      icon={<IconMessages />}
                      title='Preapplication'
                      content='Getting know to you'
                      status='upcoming'
                    />
                    <FileItem />
                  </div>

                  <ModalLayout
                    isOpen={isOpen}
                    toggle={() => {
                      setOpen(!isOpen)
                    }}
                    className='modal-colored modal-sidebar modal-desktop-half scheduled-modal'
                    minHead={<span>Título</span>}
                    topHead={<span className='btn-link'>VOLVER</span>}
                    underHead={
                      <div className='m-0 d-flex flex-column'>
                        <IconIllustratedCalendar size={80} />
                        <span
                          onClick={() => {
                            setIsOpenSection(!isOpenSection)
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley
                        </span>
                      </div>
                    }
                    useRightSection
                    options={[
                      {
                        component: (
                          <span className='h45 text-title'>Cancelar</span>
                        ),
                      },
                      {
                        component: (
                          <span className='h45 text-title'>Cancelar</span>
                        ),
                      },
                      {
                        component: (
                          <span className='h45 text-title'>Cancelar</span>
                        ),
                      },
                    ]}
                    disabledScrollAnimation={false}
                    isOpenRight={isOpenSection}
                    toggleRight={() => {
                      setIsOpenSection(!isOpenSection)
                    }}
                    sideRight={<div>DERECHA</div>}
                  >
                    <div className='h-100 d-flex flex-column'>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                      <span>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley
                      </span>
                    </div>
                  </ModalLayout>
                  <button
                    onClick={() => {
                      socket.emit('update', {entity: 'task'})
                    }}
                  >
                    Task
                  </button>
                  <button
                    onClick={() => {
                      socket.emit('update', {entity: 'university'})
                    }}
                  >
                    University
                  </button>
                  <button
                    onClick={() => {
                      socket.emit('update', {entity: 'docs'})
                    }}
                  >
                    Docs & Exams
                  </button>
                  <button
                    onClick={() => {
                      empty()
                    }}
                  >
                    Empty
                  </button>
                  <Modal />
                </div>
              )
            }}
          />

          <Route render={() => <Redirect to='/home' />} />
        </Switch>
        <RenderModals />
      </Layout>
    </Elements>
  )
}

export default App
