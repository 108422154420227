import moment from 'moment-timezone'
export const initialState = {
  scheduledMeeting: {
    isOpen: false,
    onChange: () => {},
    studentData: {},
    counselorData: {},
  },
  currentOpened: '',
  loading: false,
  payslips: {
    viewPayslip: {
      isOpen: false,
      payslipId: '',
    },
  },
  payments: {
    viewInvoice: {
      isOpen: false,
      invoice: {
        status: 'pendingPayment',
        from: new Date().toISOString(),
        to: new Date().toISOString(),
        amount: 0,
        paymentItems: [],
        amounts: {},
      },
    },
  },
  profile: {
    password: false,
    studentInfo: false,
    familyInfo: false,
    personal: false,
    personalInfo: false,
    notification: false,
    payment: false,
    available: false,
    availableRecurring: false,
    notificationToggle: {
      isOpen: false,
    },
    notificationChange: {
      isOpen: false,
    },
    pendingPayments: {
      isOpen: false,
    },
    accounts: false,
    accountInfo: {},
    services: [],
  },
  program: {
    newManualLesson: {
      isOpen: false,
      stage: 0,
      stageProps: {},
    },
    rescheduled: false,
    cancel: false,
    cantReschedule: {
      isOpen: false,
    },
    topicLessons: {
      isOpen: false,
    },
    rescheduledModal: {},
    rescheduleddate: false,
    rescheduledform: false,
    rescheduledformCalendar: false,
    copyLesson: {
      isOpen: false,
    },
    setpending: false,
  },
  pending: {
    lesson: {
      id: 0,
      name: '',
      att: {
        id: 0,
        name: '',
      },
      modal: false,
    },
    time: {
      id: 0,
      time: '',
      modal: false,
    },
    confirminfo: {
      modal: false,
    },
  },
  approveplan: {
    modal: false,
    isOpen: false,
    openEducatorModal: false,
    educatorData: {id: null, name: null, idTopic: null, lesson: null},
    preScheduled: true,
    isTimeless: false,
    reScheduled: false,
    indexModal: 0,
    parts: [],
    selectday: [],
    currentTopicLesson: {idTopic: null, indexLesson: null},
    selectedFrames: [],
    selectedManualFrames: [],
    currentMainModal: {head: null, body: null},
    currentMainMenu: {html: null},
    dataPlan: {idPlan: null},
    plan: [],
    adjust: {isOpen: false},
    currentManualLesson: {idTopic: null, from: null, to: null},
    payments: [],
    profile: {isOpen: false},
    currentData: {},
    timezone: null,
    myLessons: [],
    groupLessons: [],
    selectedFramesCheckpoint: [],
    rescheduledTimeless: false,
  },
  onboarding: {
    mainPage: {
      page: 'welcome',
    },
    smallModal: {
      isOpen: false,
      page: 'introductionPart',
    },
    mediumModal: {
      isOpen: false,
      page: 'information',
    },
    conflictModal: {
      isOpen: false,
      page: 'listConflict',
    },
    data: {},
    countdown: null,
    setStudentPhone: {
      isOpen: false,
      onChange: () => {},
      studentId: null,
    },
  },
  counselingOnboarding: {
    paymentItemsConfig: [],
    periodicity: 'monthly',
    signed: null,
    orientation: {
      time: null,
      educator: null,
    },
  },
  scheduledOrientation: {
    isOpen: false,
    onChange: () => {},
    dataPlan: null,
  },
  calendar: {
    selectUser: {
      upcomming: '',
      selectedUserData: {},
    },
    infoLessonModal: {
      isOpen: false,
      dataLesson: {},
    },
    orientationLessonModal: {
      isOpen: false,
      dataLesson: {},
    },
    resumeOrientationModal: {
      isOpen: false,
      dataLesson: {},
    },
    incompleteLessonModal: {
      isOpen: false,
    },
    rescheduledLesson: {
      isOpen: false,
      dataLesson: {},
    },
    changeTimezoneModal: {
      isOpen: false,
    },
    editLessonModal: {
      isOpen: false,
      dataLesson: {},
    },
    wspAlert: {
      isOpen: false,
      dataLesson: {},
      studentsId: [],
      educatorId: null,
    },
    rescheduledpending: {
      isOpen: false,
      lessons: [],
    },
    postponeLesson: {
      isOpen: false,
      lessonId: null,
      isOrientation: false,
    },
    cancelMultiple: {
      isOpen: false,
      lessonsId: [],
    },
    setPendingMultiple: {
      isOpen: false,
      lessonsId: [],
    },
    sendEmailReminder: {
      isOpen: false,
      dataLesson: {},
      studentsId: [],
      educatorId: null,
    },
    scheduledConflict: {
      isOpen: false,
      from: moment(),
      ocurrences: [],
      indexOcurrence: 0,
      callBack: () => {},
    },
    confirmDropRescheduled: {
      isOpen: false,
      collissionKinds: [],
      confirmAction: () => {},
      cancelAction: () => {},
    },
    sendLessonNotes: {
      isOpen: false,
      dataLesson: {},
    },
    setBraincertToLesson: {
      isOpen: false,
      lessonId: null,
      isOrientation: false,
    },
    rescheduledOrientation: {
      isOpen: false,
      dataLesson: {},
    },
    popupBackupLink: {
      isOpen: false,
      link: '',
    },
  },
  student: {
    infoStudentModal: {
      isOpen: false,
      dataStudent: {},
      initPage: 'summary',
    },
    infoLessonModal: {
      isOpen: false,
      idLesson: null,
    },
    orientationLessonModal: {
      isOpen: false,
      idLesson: null,
      dataLesson: {},
    },
  },
  counseling: {
    collegeModal: {
      isOpen: false,
    },
    univeristyDirectory: {
      isOpen: false,
    },
    viewTaskModal: {
      isOpen: false,
      notAllowClose: false,
    },
    registerScoresModal: {
      isOpen: false,
      data: {},
      scores: {},
    },
    docsAndExams: {
      isOpen: false,
    },
    docModal: {
      isOpen: false,
    },
    examModal: {
      isOpen: false,
    },
    loginInfoModal: {
      isOpen: false,
      data: {},
    },
    scheduleMeetingModal: {
      isOpen: false,
      data: {},
      lessonInfo: {
        isOpen: false,
      },
    },
    myPlansModal: {
      isOpen: false,
      plan: {},
    },
    messageModal: {
      isOpen: false,
    },
    popupSkipScores: {
      isOpen: false,
      plans: [],
    },
    viewPlanModal: {
      isOpen: false,
      plan: {},
      tab: null,
    },
    confirmTaskModal: {
      isOpen: false,
      onChange: () => {},
      taskData: {},
      useMutation: false,
      loading: false,
    },
    invoiceModal: {
      isOpen: false,
    },
    planStatus: '',
    submitScoreModal: {
      isOpen: false,
      exam: {},
      onChange: () => {}
    },
    submitScoreModalLoading: {
      loading: false,
    },
  },
}
