import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import myUniversity from './queryResults/myUniversity'

export default (onSuccess, onError = () => {}) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
        mutation(
            $universityId: ID!
            $action: UniversityAction!
            $mark: UniversityEarly!
            $makeValidation: Boolean = false
    
            ){
                markMyUniversity (
                    universityId: $universityId
                    action: $action
                    mark: $mark
                    makeValidation: $makeValidation
    
                ){
                  ${myUniversity} 
                }
            }
    `,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {loading, data: data ? data.markMyUniversity : null, error, submit}
}
