import {gql, useMutation} from '@apollo/client'

const useEmitInvokeLessonForAdmin = (
  onCompleted = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation($userId: ID!) {
        emitInvokeLessonForAdmin(userId: $userId)
      }
    `,
    {onCompleted, onError},
  )
}

export default useEmitInvokeLessonForAdmin
