import {useEffect} from 'react'
import {useLazyQuery, gql} from '@apollo/client'

const useGetPaymentMethodFromUser = (userId) => {
  const [getPaymentMethod, {data, refetch}] = useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          paymentMethod {
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              kind
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on BankCreditPaymentMethod {
              kind
              status
              sourceInfo {
                accountNumber
                routingNumber
                bankName
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              kind
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
      }
    `,
  )
  useEffect(() => {
    if (userId) getPaymentMethod({variables: {id: userId}})
  }, [userId, getPaymentMethod])
  return {data: data?.user?.paymentMethod, refetch}
}

export default useGetPaymentMethodFromUser
