import React, {useState, useMemo, useEffect} from 'react'
import {
  ListItem,
  IconCountry,
  IconArrowRight,
  IconArrowLeft,
  IconDate,
  ScheduledCalendar,
  StickyButton,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {setTimeOnTopicPart, nextPage} from '../../utils'
import useTranslation from '../../../../i18n/useTranslation'
import {gql, useLazyQuery} from '@apollo/client'
import * as moment from 'moment-timezone'
import ChangeTimezone from '../../components/ChangeTimezone'
import LessonsIcons from '../../components/LessonsIcons'
import UpdateUser from '../../../../Model/UpdateUser'

export const ScheduledOrientationCalendarHeader = (props) => {
  return (
    <div className='m-0 p-0'>
      <div className='d-flex my-2'>
        <LessonsIcons
          data={[]}
          idTopic=''
          lesson={1}
          checked
          selected={false}
          selectedLesson={[]}
        />
      </div>
    </div>
  )
}
export const ScheduledOrientationCalendarBody = (props) => {
  const {selectedDays, setPage} = props
  const {translate} = useTranslation()
  const [selectedTime, setSelectedTime] = useState({})
  const dispatch = useDispatch()

  const zone = useSelector((state) => state.user.info.timezone)

  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const reduxMedium = useSelector((state) => state.modal.onboarding.mediumModal)

  const dataRedux = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimes, keyLesson, dataPlan} = dataRedux
  const orientationData =
    scheduledTimes && keyLesson === 'Orientator-0'
      ? scheduledTimes[keyLesson]
      : {}

  // fecha límite del calendario
  const [fromLimit, toLimit] = dataPlan?.ranges[
    parseInt(keyLesson?.split('-')[1])
  ]
  const limitDate =
    moment() < moment(fromLimit) ? moment(fromLimit) : moment(toLimit)

  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO
  const {submit: changeTimezone} = UpdateUser(
    ({
      data: {
        updateUser: {timezone},
      },
    }) => {
      setSelectedTime({})
    },
    (error) => {
      console.error(error)
    },
  )

  // MUTACIÓN Y FUNCIÓN PARA MODIFICAR EL TIMEZONE DEL USUARIO - FIN
  const from = useMemo(() => {
    return moment()
  }, [])
  // fromDate siempre marca el inicio de la semana o el momento actual
  const [fromDate, setFromDate] = useState(from)
  const startOfWeek = moment(fromDate).clone().startOf('isoWeek')
  const endOfWeek = moment(fromDate).clone().endOf('isoWeek')
  const previusWeek = () => {
    setFromDate(moment(startOfWeek).clone().subtract(7, 'd'))
  }
  const nextWeek = () => {
    setFromDate(moment(startOfWeek).clone().add(7, 'd'))
  }
  const resetFromDate = () => {
    setFromDate(from)
  }

  const [getOrientationHours, {data, loading}] = useLazyQuery(
    gql`
      query($educatorId: ID!, $from: DateTime!, $to: DateTime!) {
        getOrientationHours(from: $from, to: $to, educatorId: $educatorId)
      }
    `,
  )
  useEffect(() => {
    if (
      reduxSmall?.page === 'scheduledOrientationCalendar' &&
      orientationData?.educator[0]?.id &&
      fromDate
    ) {
      getOrientationHours({
        variables: {
          educatorId: orientationData?.educator[0]?.id,
          from:
            fromDate.clone() > moment().add(24, 'hours')
              ? fromDate.clone().toISOString()
              : moment().toISOString(),
          to:
            endOfWeek >= limitDate // llego al límite
              ? limitDate.toISOString()
              : endOfWeek.clone().toISOString(),
        },
      })
    }
    // eslint-disable-next-line
  }, [reduxSmall, orientationData, fromDate, selectedDays])

  const setZone = (time) => {
    // ejecutar mutación updateUser
    changeTimezone({
      input: {
        timezone: time,
      },
    })
  }

  return (
    <div className='m-0 p-0'>
      <span className='text-title'>
        <b>{translate('home.onboard.approve.scheduled.chooseOr')}</b>
      </span>{' '}
      <br />
      <span className='text-title'>
        {translate('home.onboard.approve.scheduled.orSubtitle')}
      </span>
      <ChangeTimezone setTime={setZone} time={zone}>
        <ListItem
          showBottomLine
          showTopLine
          Left={
            <div className='d-flex align-items-center text-muted'>
              <IconCountry className='mx-3' size={16} />
              <span className='ml-2'>{zone}</span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      </ChangeTimezone>
      <ListItem
        showBottomLine
        Left={
          <CarouselFrame type='animation'>
            <div
              className='d-flex align-items-center text-muted'
              style={{whiteSpace: 'nowrap'}}
            >
              <IconDate className='mx-3' size={16} />
              <span className='ml-2'>
                {`${startOfWeek.format('D MMM YY').toUpperCase()}
                - ${endOfWeek.format('D MMM YY').toUpperCase()}`}
              </span>
            </div>
          </CarouselFrame>
        }
        Right={
          <div className='m-0 p-0' style={{whiteSpace: 'nowrap'}}>
            {fromDate !== from && (
              <>
                <span
                  onClick={() => {
                    resetFromDate()
                  }}
                  className='btn-link ml-1'
                >
                  Reset
                </span>
                <IconArrowLeft
                  className='mr-3'
                  onClick={() => {
                    previusWeek()
                  }}
                  style={{cursor: 'pointer'}}
                />
              </>
            )}
            {!(endOfWeek >= limitDate) && (
              <IconArrowRight
                onClick={() => {
                  nextWeek()
                }}
                style={{cursor: 'pointer'}}
              />
            )}
          </div>
        }
      />
      <div className='m-0 py-6 px-0'>
        <div className='m-0 p-0'>
          <ScheduledCalendar
            loading={loading}
            setSelected={(time) => {
              setSelectedTime(time)
              dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'onboarding.data',
                data: {
                  ...dataRedux,
                  scheduledTimes: setTimeOnTopicPart(
                    scheduledTimes,
                    keyLesson,
                    moment(time?.time),
                  ),
                },
              })
            }}
            selectedDay={selectedTime}
            educator={orientationData?.educator}
            checkedDays={[]}
            myFreeTimeFrames={data?.getOrientationHours}
            fromPart={fromDate.clone().toISOString()}
            toPart={endOfWeek.clone().toISOString()}
            zone={zone}
            className='p-0 m-0'
            scheduleOr
            lang='en'
          />
        </div>
      </div>
      <StickyButton
        condition={JSON.stringify(selectedTime) !== '{}'}
        onClickButtonFunction={() => {
          const {page: next, key: nextKey} = nextPage(scheduledTimes, keyLesson)
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.data',
            data: {...dataRedux, keyLesson: nextKey},
          })
          if (next === 'end') {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.smallModal',
              data: {...reduxSmall, isOpen: false},
            })
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: 'onboarding.mediumModal',
              data: {...reduxMedium, page: 'confirmScheduled'},
            })
          } else setPage(next)
        }}
      />
    </div>
  )
}

export default {
  ScheduledOrientationCalendarHeader,
  ScheduledOrientationCalendarBody,
}
