import React from 'react'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'

const LayoutViewRightSection = (props) => {
  const {leftSide, rightSide, isOpen, styleLeftSection} = props
  const {breakWidth} = useWindowDimensions()
  return (
    <div className='m-0 p-0 w-100 h-100'>
      <div
        className={`m-0 p-0 w-100 h-100 d-flex flex-${
          breakWidth === 'SM' ? 'column' : 'row'
        } layout-container ${isOpen ? 'open-section' : 'close-section'}`}
      >
        <div
          className={`left-section left-section-${breakWidth} h-100`}
          style={styleLeftSection || {}}
        >
          {leftSide}
        </div>
        <div className={`right-section right-section-${breakWidth} h-100`}>
          <div
            className={`m-0 p-0 h-100 right-section-content right-section-content-${breakWidth}`}
          >
            {rightSide}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutViewRightSection
