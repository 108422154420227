import React from 'react'
import {IconIllustratedCalendar} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const ConfirmLessonHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <>
      <div className='w-100 pl-0'>
        <IconIllustratedCalendar size={80} />
        <div className='mt-2 h2 text-body font-weight-bold'>
          {translate('lessons.scheduledlesson')}
        </div>
      </div>
    </>
  )
}

export default ConfirmLessonHeader
