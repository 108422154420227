import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'

export default (onSuccess, onError) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
      mutation($taskId: ID!, $stepId: ID!, $phase: PhaseEnum!, $planId: ID!) {
        setClickOnLink(
          taskId: $taskId
          stepId: $stepId
          phase: $phase
          planId: $planId
        )
      }
    `,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {loading, data: data ? data.setClickOnLink : null, error, submit}
}
