import {gql} from '@apollo/client'

const invoiceQuery = gql`
  query($invoiceId: String!) {
    invoice(invoiceId: $invoiceId) {
      id
      status
      category
      billedUserId
      processedTimes
      invoiceNumber
      from
      to
      paymentItemIds
      createdAt
      updatedAt
      lastProcessedAt
      amount
      amountPaid
      realAmount
      manualPayments {
        status
        paymentMethod
        amount
        stripeInvoiceId
        lastProcessedAt
      }
      taxFee
      amounts {
        subTotal
        taxAmount
        amount
      }
      paymentItems {
        ... on PlanPaymentItem {
          generatedDescription
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on CustomPaymentItem {
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
        ... on LessonPaymentItem {
          generatedDescription
          id
          type
          amount
          draft
          status
          lastProcessedAt
          description
          category
        }
      }
      issuedInvoices {
        stripeInvoiceId
        status
        paymentMethod
        amount
        lastProcessedAt
        issuedCreditNotes {
          creditNoteId
          amount
          stripeInfo {
            created
            currency
            discount_amount
            number
            out_of_band_amount
            pdf
            subtotal
            total
          }
        }
      }
    }
  }
`
export default invoiceQuery
