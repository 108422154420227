export const educatorNotesResult = `
hadHw
didHw
score
scoreAlphanumeric
studentEffort
acqKnowledge
newHw
descriptionHw
dueDateHw
others
`

export default educatorNotesResult