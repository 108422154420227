import {initialState} from './initialState'
import {SET_COUNTRIES_ACTION} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES_ACTION:
      return {
        ...state,
        countries: action.payload.reduce((obj, country) => {
          return {
            ...obj,
            [country.id]: {
              ...country,
            },
          }
        }, {}),
      }
    default:
      return {...state}
  }
}
