import React, {useState, useEffect} from 'react'
import {
  Modal,
  PlanCard,
  IconClose,
  useWindowDimensions,
  IconOptions,
  IconLesson,
} from '@Knowledge-OTP/znk-ui-components'
import ApprovalModal from './pendingApproval'
import InfoModal from './scheduled'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router'
import useTranslation from '../../../../i18n/useTranslation'

const PlanModal = (props) => {
  const services = useSelector((state) => state.services.services)
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }
  const {status, info} = props
  const history = useHistory()
  const groupInfo = info?.groupPerWeekInfo
  const partArray = info?.lessonPerTopic || info?.parts || []
  const lpwTotal = (partArray || []).reduce((totalLpw, part) => {
    return (
      totalLpw +
      (info.kind !== 'lessonsFixed'
        ? part.lessonsPerWeek
        : part.TotalPerTopic.reduce(
            (totalLessons, part) => totalLessons + (part?.lessonsPerWeek || 0),
            0,
          ))
    )
  }, 0)
  const totalPlan =
    info?.kind === 'lessonsFixed' &&
    (partArray || []).reduce(
      (isTotal, part) => isTotal || part?.type === 'totalLesson',
      false,
    )

  const location = useLocation()
  const searchParams = new URLSearchParams(location?.search)
  const planId = searchParams.get('planId')
  useEffect(() => {
    if (planId === info?.id && status !== 'sent') toggle()
    // abrir modal del plan
    // eslint-disable-next-line
  }, [planId])

  return (
    <>
      <PlanCard
        {...{...props, ...info}}
        translate={translate}
        lessonsTotal={lpwTotal}
        isTimelessPlan={info?.kind === 'lessonsFixed'}
        isLessonPerWeek={!totalPlan}
        onClick={
          status === 'sent'
            ? () => history.push(`/onboarding/${info.id}`)
            : toggle
        }
      />
      <Modal
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
        } modal-colored upcoming-modal`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header flex-column d-flex mx-6 mx-sm-7 mt-4 p-0 mb-2'>
            <div className='w-100 d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                <IconLesson size={16} className='text-white mr-2' />
                <span>{translate('Live Prep')}</span>
              </div>
              <div>
                <IconOptions
                  size={20}
                  className='m-0 mr-2 text-transparent p-0'
                  style={{transform: 'rotate(90deg)'}}
                />
                <IconClose
                  size={20}
                  className='text-transparent'
                  onClick={toggle}
                />
              </div>
            </div>
            <div
              className='align-self-start d-flex flex-column'
              style={{flex: 1}}
            >
              <span className='h2 text-light font-weight-normal mt-5'>
                {info.name ||
                  `${services[info.serviceId].name} ${info.startTime.format(
                    'MMMM D, YYYY',
                  )}`}
              </span>
              <span className='h4 text-light small font-weight-light'>
                {info.startTime.format('MMM D, YYYY')} to{' '}
                {info.endTime.format('MMM D, YYYY')}
              </span>
              <span className='h4 text-transparent small font-weight-light'>
                {separateCamelCase(info?.location?.type ?? 'Not Defined')}
              </span>
            </div>
          </div>
        }
      >
        {status === 'sent' ? (
          <ApprovalModal />
        ) : (
          <InfoModal {...props} groupInfo={groupInfo} />
        )}
      </Modal>
    </>
  )
}
export default PlanModal
