import {useReducer} from 'react'
import {
  IconPerLesson,
  IconPerMonth,
  IconLessonPerWeek,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import reducer from './reducer'
import {initialState} from './initialState'
import moment from 'moment'
import {totalLesson, totalLpw as totalLpwFunction} from './util'

const useTimelineReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const servicesObj = useSelector((state) => state.services.services)
  const services = servicesObj ? Object.values(servicesObj) : []
  const topics = services.reduce(
    (array, {topics}) => array.concat(Object.values(topics)),
    [],
  )
  const actionData = (
    data,
    key, // [phase, step, task]
    action, // [add, remove]
  ) => {
    dispatch({
      type: `${action}-${key}`,
      data: data,
    })
  }

  // Recibe indice de la parte y formulario de plan y retorna cantidad total de lecciones
  const TotalLessonPart = (lpwInd, form) => {
    let count = 0
    if (form.lpw) {
      if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
        Object.keys(form.lpw[lpwInd])
          .filter(
            (key) =>
              key !== 'startdate' && key !== 'enddate' && key !== 'partname',
          )
          .forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value
          })
        return count
      } else {
        let weeks = 1
        if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
          const partFrom = moment(form.lpw[lpwInd].startdate)
          const partTo = moment(form.lpw[lpwInd].enddate)
          // si una semana tiene menos de tres dias no contarla como una semana valida
          weeks = partFrom.diff(partTo, 'week') * -1 + 1
          if (
            partTo.day() < partFrom.day() &&
            partTo.day() !== 0 &&
            weeks >= 2
          ) {
            // en este caso diff cuenta una semana menos
            weeks += 1
          }
          if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
          if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
        }
        Object.keys(form.lpw[lpwInd])
          .filter(
            (key) =>
              key !== 'startdate' && key !== 'enddate' && key !== 'partname',
          )
          .forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value * weeks
          })
        return count
      }
    }
    return count
  }

  // const parseToTimelineOld = (
  //   events = [],
  //   onClickPlan = () => {},
  //   onClickEvent = () => {},
  // ) => {
  //   return events.map((event) => {
  //     const {type} = event
  //     if (type === 'plan') {
  //       const from = event?.lpw[0]?.startdate
  //       const largo = Object.keys(event?.lpw)?.length
  //       const to = event?.lpw[largo - 1]?.enddate
  //       // lpw:
  //       //     0:
  //       //     enddate: "2022-02-27"
  //       //     partname: "Part 1"
  //       //     startdate: "2022-01-17"
  //       // console.log("event", event)
  //       return {
  //         ...event,
  //         spans: [
  //           {text: `10 lpw`, description: 'lessons per week'},
  //           {
  //             text: `$${event?.pricingRate}`,
  //             description: `rate ${
  //               event?.typePlanPricing === 'perlesson'
  //                 ? 'per lesson'
  //                 : 'per month'
  //             }`,
  //           },
  //           {text: '$100', description: 'total'},
  //         ], // max 3 spans
  //         id: event?.planId || event?.id,
  //         from,
  //         to,
  //         onClick: () => {
  //           onClickPlan(event)
  //         },
  //       }
  //     }
  //     return {
  //       ...event,
  //       spans: [{text: event?.name, description: 'name'}], // max 3 spans
  //       id: event?.eventId || event?.id,
  //       from: event?.date,
  //       to: undefined,
  //       onClick: () => {
  //         onClickEvent(event)
  //       },
  //     }
  //   })
  // }

  const parseToTimeline = (
    events = [],
    onClickPlan = () => {},
    onClickEvent = () => {},
  ) => {
    return events.reduce((array, event) => {
      const {type} = event
      if (type === 'plan') {
        const totalMonth = Math.ceil(
          moment
            .duration(
              Math.abs(
                moment(event?.lpw[0].startdate).diff(
                  moment(
                    event?.lpw[Object.values(event?.lpw).length - 1].enddate,
                  ),
                ),
              ),
            )
            .asMonths(),
        )
        const moreOnePart = Object.values(event?.lpw)?.length > 1
        for (let i = 0; i < Object.values(event?.lpw)?.length; i++) {
          const thisPart = event?.lpw[i]
          const {
            // eslint-disable-next-line
                    enddate, partname, startdate,
            ...part
          } = thisPart

          const from = thisPart?.startdate
          // const largo = Object.keys(event?.lpw)?.length
          const to = thisPart?.enddate

          // total de lecciones a lo largo del plan
          const total = totalLesson({...event, lpw: [thisPart]})
          const totalLpw = totalLpwFunction({...event, lpw: [thisPart]})
          const labelLpw = Object.entries(part).reduce(
            (label, [topicId, {value}], index) => {
              const topicName = (topics || []).find(({id}) => id === topicId)
                ?.name
              const isLast = index === Object.entries(part).length - 1
              if (index === 0)
                return `${value} ${topicName}${isLast ? ' lesson' : ''}`
              else {
                return `${label}${isLast ? ' and' : ','} ${value} ${topicName}${
                  isLast ? ' lesson(s)' : ''
                }`
              }
            },
            ``,
          )

          const spans = [
            {
              text: totalLpw,
              description: labelLpw,
              place: 'right',
              Icon: IconLessonPerWeek,
              iconSize: 25,
            },
          ]
          if (event?.typePlanPricing === 'perlesson') {
            spans.push({
              text: `$${event?.pricingRate}`,
              description: `rate per lesson`,
              Icon: IconPerLesson,
              iconSize: 25,
            })
            spans.push({
              text: `$${parseInt(event?.pricingRate) * parseInt(total)}`,
              description: 'total',
            })
          }
          // per month
          else if (i === 0) {
            spans.push({
              text: `~$${parseInt(event?.pricingRate) / parseInt(totalMonth)}`,
              description: `rate per month`,
              Icon: IconPerMonth,
              iconSize: 25,
            })
            spans.push({
              text: `$${parseInt(event?.pricingRate)}`,
              description: 'total',
            })
          } else {
            spans.push({text: `-`})
            spans.push({text: `-`})
          }

          const totalWeek = moment
            .duration(Math.abs(moment(from).diff(moment(to))))
            .asWeeks()

          array.push({
            ...event,
            spans, // max 3 spans
            small: `${Math.ceil(totalWeek)} week(s)`,
            id: `part${i}-${event?.planId || event?.id}`,
            subtitle: `From ${moment(from).format(`MMM Do`)} to ${moment(
              to,
            ).format(`MMM Do`)}`,
            label:
              `${event?.planname}${moreOnePart ? ` (part ${i + 1})` : ''}` ||
              event?.name,
            from,
            to,
            onClick: () => {
              onClickPlan(event)
            },
          })
        }
        return array
      }
      array.push({
        ...event,
        spans: [
          // {text: event?.name, description: 'name'},
        ], // max 3 spans
        id: event?.eventId || event?.id,
        from: event?.date,
        label: event?.name,
        subtitle: moment(event?.date).format(`MMM Do`),
        to: undefined,
        onClick: () => {
          onClickEvent(event)
        },
      })
      return array
    }, [])
  }

  return {
    state,
    dispatch,
    initialState,

    setData: () => {
      actionData({}, 'data', 'set')
    },

    parseTimelineFromAPI: (timeline = null) => {
      return timeline
        ? Object.entries(timeline).reduce((array, [key, events]) => {
            if (key === 'plans') {
              return array.concat(
                events
                  .filter(({deleteAt}) => deleteAt === null)
                  .map((event) => {
                    const thisService = services.find(
                      ({id}) => id === event?.serviceId,
                    )
                    return {
                      cuotas: [],
                      idservice: event?.serviceId,
                      pricingRate: event?.pricingRate,
                      typePlanPricing: event?.typePlanPricing,
                      planId: event?.id,
                      lpw: event?.parts?.reduce((parseParts, part, index) => {
                        // lessonsPerWeek
                        // totalLessons
                        // lessonPerWeekPerTopic {
                        // topicId
                        // lessonsPerWeek
                        // totalLessons
                        // lockLessons
                        // }
                        // from
                        // to
                        // name
                        const parsePart = {
                          partname: part?.name,
                          startdate: moment(part?.from)
                            .utc()
                            .format('YYYY-MM-DD'),
                          enddate: moment(part?.to).utc().format('YYYY-MM-DD'),
                        }
                        // puebla la parte con la cantidad de lecciones por semana (según tópico)
                        for (
                          let i = 0;
                          i < part.lessonPerWeekPerTopic?.length;
                          i++
                        ) {
                          const topicPerWeek = part.lessonPerWeekPerTopic[i]
                          parsePart[topicPerWeek?.topicId] = {
                            value: topicPerWeek?.lessonsPerWeek || 0,
                          }
                        }
                        // part.lessonPerWeekPerTopic.forEach((topicPerWeek) => {
                        //     parsePart[topicPerWeek?.topicId] = {
                        //         value: topicPerWeek?.lessonsPerWeek || 0
                        //     }
                        // })
                        return {
                          ...parseParts,
                          [index]: parsePart,
                        }
                      }, {}),
                      statusPlan: 'draft',
                      planname: event?.name,
                      payments: {
                        registration: false,
                        exam: false,
                        pro: false,
                        lessons: false,
                        discount: {
                          registration: 0,
                          exam: 0,
                          pro: 0,
                          lessons: 0,
                        },
                        values: {
                          registration: 200,
                          exam: 50,
                          pro: 50,
                        },
                      },
                      type: 'plan',
                      statusBuild: 'normal',
                      // testdate
                      testdate: {
                        name: `${thisService?.name} - ${moment(
                          event?.testDate,
                        ).format('MMM Do, YYYY')}`,
                        date: event?.testDate,
                      },
                      // testDateId: ''
                    }
                  }),
              )
            } else if (key === 'events') {
              return array.concat(
                events.map((event) => {
                  return {
                    eventId: event?.id,
                    date: moment(event.startDate).utc().format(`YYYY-MM-DD`),
                    toDefine: event.tbd,
                    name: event.name,
                    description: event?.description,
                    isEdit: false,
                    type: 'event',
                    statusBuild: 'normal',
                  }
                }),
              )
            }
            return array
          }, [])
        : null
    },

    parseTimelineToMutation: (timeline, studentUser) => {
      // parse to TimelineInput
      const timelineInput = {
        timelinePlans: timeline
          ?.filter(({type}) => type === 'plan')
          ?.map((plan) => {
            // name: String!
            // serviceId: ID!
            // typePlanPricing: TypePlanPricing!
            // parts: [LessonsPerWeekPlanPartInput!]!
            // statusBuild: StatusBuild
            return {
              statusBuild: plan?.statusBuild || 'new',
              name: plan?.planname,
              serviceId: plan.idservice,
              typePlanPricing: plan.typePlanPricing,
              studentUser: {
                studentUserId: studentUser?.id || studentUser?.studentUserId,
                rate: studentUser?.rate,
              },
              parts: Object.values(plan?.lpw || {}).map((part, index) => {
                const {startdate, enddate, partname, ...partTopic} = part
                return {
                  lessonPerWeekPerTopic: Object.entries(partTopic).map(
                    ([topicId, {value, lock}]) => {
                      return {
                        topicId,
                        lessonsPerWeek: value,
                        lockLessons: lock || false,
                      }
                    },
                  ),
                  lessonsPerWeek: Object.values(partTopic).reduce(
                    (sum, {value}) => {
                      return parseInt(value) + sum
                    },
                    0,
                  ),
                  totalLessons: TotalLessonPart(index, plan) || 0,
                  from: startdate && new Date(startdate),
                  to: enddate && new Date(enddate),
                  name: partname,
                }
              }),
              // testdate
              testDate: plan?.testdate?.date
                ? new Date(plan?.testdate?.date)
                : null,
              planId: plan?.planId,
              statusPlan: plan?.statusPlan || 'draft',
              // testDateId: ''

              // [
              // lessonPerWeekPerTopic: [
              // topicId: ID!
              // lessonsPerWeek: Int!
              // lockLessons: Boolean!
              // ]
              // lessonsPerWeek: Int!
              // totalLessons: Int!
              // from: DateTime!
              // to: DateTime!
              // name: String!
              // ]
            }
          }),
        activities: timeline
          ?.filter(({type}) => type === 'event')
          ?.map((event) => {
            return {
              // activityId: ID!
              // type: EventEnum!
              // statusBuild: StatusBuild!
              activityId: event?.eventId,
              statusBuild: event?.statusBuild || 'new',
              type: 'event',
            }
          }),
      }
      return timelineInput
    },

    parseToTimeline,
  }
}

export default useTimelineReducer
