export default {
  footermsg1: 'This is all, for now',
  footermsg2: 'Get Your maximun potential.',
  footermsg3: 'Strengthens and acquires new knowledge.',
  footermsg4: 'Schedule new Lesson',
  newlessonnot: 'You have pending lessons',
  nextweek: 'Go to next week',
  rescheduled: 'Reschedule lesson',
  needcancellesson: 'Need to cancel this lesson?',
  checkin: 'Check In',
  reminder: 'Reminder',
  gotoclassroom: 'Go to class room',
  startin: 'Start in',
  scheduledlesson: 'Scheduled lesson',
  selectdate: 'Select a date',
  whattopicwant: 'What topic you want?',
  whateducatorwant: 'Which Educator you want?',
  addnewlesson: 'Add new lesson',
  createtask: 'Create task',
  needhelp: 'Need help?',
  cancel: 'CANCEL',
  today: 'Today, ',
  continue: 'Continue',
  timezone: 'Timezone',
  lessonscheduled: 'Lesson scheduled',
  view: 'VIEW',
  fortimezone: ({lesson, zone}) => {
    return `For ${lesson} lesson | ${zone}`
  },
  needmoreop: 'Need more option to scheduled?',
  errorscheduled:
    'By scheduling this lesson I accept the additional charge of ',
  selectAnEducator: 'Select and educator',
  copyLesson: 'Copy lesson',
  scheduleLesson: 'Schedule Lesson',
}
