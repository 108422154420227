import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const ChangeEducatorConfirm = (props) => {
  const {isOpen, toggle, confirmAction} = props
  const {translate} = useTranslation()
  return (
    <ConfirmModal
      backdrop={[false, 'static']}
      className=''
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('home.onboard.approve.before.confirmEducatorTitle')}
      body={translate('home.onboard.approve.before.confirmEducatorBody')}
      confirmAction={confirmAction}
      cancelAction={toggle}
      confirmText={translate('home.onboard.approve.before.okdelete')}
      cancelText={translate('home.onboard.approve.before.cancel')}
    />
  )
}

export default ChangeEducatorConfirm
