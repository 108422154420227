import React, {Fragment} from 'react'
import {
  IconLesson,
  IconStarFilled,
  ListItem,
  ToolTipInfo,
  IconInfo,
  IconCircleDolar,
  IconOptions,
  DropDown
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import formatNumber from '../../../../../helpers/formatNumber'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'

const Details = (props) => {
  const {invoice = {}, loading, offline = false} = props
  const {translate} = useTranslation()

  const paymentNameManual = {
    cash: {
      name: translate('invoices.payments.cash')
    },
    check: {
      name: translate('invoices.payments.check')
    },
    creditCard: {
      name: translate('invoices.payments.creditcard')
    },
    debitCard: {
      name: translate('invoices.payments.debitcard')
    },
    bankTransfer: {
      name: translate('invoices.payments.banktransfer')
    },
    payPal: {
      name: translate('invoices.payments.paypal')
    },
    zelle: {
      name: translate('invoices.payments.zelle')
    },
    other: {
      name: translate('invoices.payments.other')
    }
  }

  return (
    <>
      <div className='d-flex flex-row align-items-center mb-4'>
        {invoice.category === 'livePrep' ? (
          <IconLesson size='20' />
        ) : (
          <IconStarFilled size='20' />
        )}
        <span className='ml-2'>
          {translate(`profile.payments.${invoice.category}Desc`)}
        </span>
      </div>
      <ListItem
        className='px-0 py-3'
        Left={
          <span className='text-bold'>
            {translate(`profile.payments.item`)}
          </span>
        }
        showBottomLine
        showTopLine
        Right={
          <span className='text-bold'>
            {translate(`profile.payments.amount`)}
          </span>
        }
      />
      <span className='w-100 p-0 pb-4'>
        {loading ? (
          <Skeleton height={400} />
        ) : (
          (invoice?.paymentItems || []).map((item, key) => {
            return (
              <ListItem
                key={item.id}
                className='px-0 py-3'
                classNameLeft='text-truncate'
                Left={
                  <div className='d-flex flex-column text-truncate'>
                    <div className='d-flex align-items-center'>
                      <span className='text-bold'>
                        {translate(`profile.payments.types.${item.type}`)}
                      </span>
                      {translate(`profile.payments.types.${item.type}`) ===
                        'Registration' && (
                        <ToolTipInfo
                          tooltipId={'registration-fee-tooltip-invoice'}
                          type='dark'
                          place='right'
                          innerElement={
                            <p className='p-0 m-0' style={{color: 'white'}}>
                              {translate(
                                'home.onboard.approve.registrationFeeInfo',
                              )}
                            </p>
                          }
                        >
                          <div className='ml-2'>
                            <IconInfo className='text-gray' size={16} />
                          </div>
                        </ToolTipInfo>
                      )}

                      <span className='text-gray'>
                        {item.percentDiscount > 0
                          ? ` (${item.percentDiscount.toFixed(2)}% Discount)`
                          : ''}
                      </span>
                    </div>
                    <div>
                      <span className='text-gray'>
                        {item.generatedDescription ?? item.description}
                      </span>
                    </div>
                  </div>
                }
                showBottomLine
                classNameRight='align-self-start'
                Right={
                  <span className='text-gray'>
                    ${formatNumber(item.amount)}
                  </span>
                }
              />
            )
          })
        )}
        <div className='w-100'>
          <div className='d-flex flex-row justify-content-between mt-3'>
            <div>
              <span>Sub Total</span>
            </div>
            <span>$ {formatNumber(invoice?.amounts?.subTotal)}</span>
          </div>
          <div className='borderTop mt-3' />
        </div>
        <div className='w-100'>
          <div className='d-flex flex-row justify-content-between mt-3'>
            <div>
              <span>
                {invoice.taxFee === 0
                  ? 'Tax 0%'
                  : `Credit Card Processing Fee at ${invoice.taxFee}%`}
              </span>
            </div>
            <span>${formatNumber(invoice?.amounts?.taxAmount)}</span>
          </div>
          <div className='borderTop mt-3' />
        </div>
        <div className='w-100'>
          <div className='d-flex flex-row justify-content-between mt-3'>
            <div>
              <span className='font-weight-bold'>
                {translate('profile.payments.total')}
              </span>
            </div>
            <span className='font-weight-bold'>
              ${formatNumber(invoice?.amounts?.amount)}
            </span>
          </div>
          <div className='borderTop mt-3' />
        </div>
        {
                invoice && invoice?.manualPayments?.length > 0 && (
                  <div className='my-5 px-1'>
                    {
                      invoice && invoice?.manualPayments?.map(
                        (payment, index) => {
                          return (
                            <Fragment>
                              <div
                                key={`manual-${index}`}
                                className={`znk-invoice-row paid`}
                              >
                                <span>
                                  <IconCircleDolar />
                                  <div className='ml-3'>{moment(String(payment.lastProcessedAt).split('T')[0]).format('MM/DD/YYYY')}</div>
                                </span>
                                <span className='text-color'><div className='w-100'>(M) {paymentNameManual[payment.paymentMethod]?.name}</div></span>
                                <span className='font-weight-bold text-color justify-content-end' style={{ marginRight: 10 }}>${formatNumber(payment.amount)}</span>
                                <span className='flex-grow-0'>
                                  <div className='znk-table-dropdown-custom dropdown-menu-width-content pr-0'>
                                    <DropDown
                                      direction='down'
                                      fixedWidth
                                      hoverEnabled={false}
                                      options={[
                                        {
                                          component: null
                                        }
                                      ]}
                                    >
                                      <IconOptions style={{ transform: 'rotate(90deg)', marginRight: 10 }} color='rgba(37, 64, 80, 0.6)' />
                                    </DropDown>
                                  </div>
                                </span>
                              </div>
                            </Fragment>
                          )
                        }
                      )
                    }
                  </div>
                )
              }


        {!offline && (
          <div className='mt-3 w-100'>
            <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row'>
                  <span className='font-weight-bold text-gray'>Created</span>
                  <span className='ml-2 text-gray'>
                    {'at ' +
                      moment(invoice.createdAt).format('MMM DD, YYYY hh:mm a')}
                  </span>
                </div>
                <div className='d-flex flex-row'>
                  <span className='font-weight-bold text-gray'>Updated</span>
                  <span className='ml-2 text-gray'>
                    {'at ' +
                      moment(invoice.updateAt).format('MMM DD, YYYY hh:mm a')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </span>
    </>
  )
}

export default Details
