import React from 'react'
import {IconCreditCard, ListItem} from '@Knowledge-OTP/znk-ui-components'
import iconVisa from '../../../../../../assets/card-visa.png'
import iconMaster from '../../../../../../assets/card-mastercard.png'
import iconDiscover from '../../../../../../assets/card-discover.png'
import iconDiners from '../../../../../../assets/card-diners.png'
import iconAmericanExpress from '../../../../../../assets/card-amex.png'
import IconCardUndefined from '../../../../../../assets/card-undefined.png'

const CreditCardMethod = (props) => {
  const {cardInfo, isClickable} = props
  console.log('card brand', cardInfo.brand)
  return (
    <ListItem
      className='px-4'
      showTopLine
      showBottomLine
      hasColorHover={isClickable}
      hasHover={isClickable}
      Left={
        <span className='d-flex align-items-center text-gray'>
          <IconCreditCard className='mr-2' />
          Credit Card
        </span>
      }
      Right={
        <span className='d-flex align-items-center'>
          <span className='mr-2 font-weight-light text-gray'>
            **** **** **** {cardInfo.last4}
            <span className='ml-1 small'>
              {cardInfo.expMonth}/{cardInfo.expYear}
            </span>
          </span>
          {cardInfo.brand === 'visa' ? (
            <img src={iconVisa} size={24} alt='visa card' />
          ) : cardInfo.brand === 'mastercard' ? (
            <img src={iconMaster} size={24} alt='master card' />
          ) : cardInfo.brand === 'diners' ? (
            <img src={iconDiners} size={24} alt='dinners card' />
          ) : cardInfo.brand === 'discover' ? (
            <img src={iconDiscover} size={24} alt='discover card' />
          ) : cardInfo.brand === 'amex' ? (
            <img src={iconAmericanExpress} size={24} alt='amex card' />
          ) : (
            <>
              <img src={IconCardUndefined} size={24} alt='undefined card' />
              {/* {cardInfo.brand} */}
            </>
          )}
        </span>
      }
    />
  )
}

export default CreditCardMethod
