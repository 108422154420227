import React from 'react'
import {IconCheckFilled} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const SendToReviewButtonOff = () => {
  const {translate} = useTranslation()

  return (
    <>
      <div
        className='d-flex justify-content-center py-1 mt-4'
        style={{
          width: '100%',
          border: '1px solid',
          borderRadius: '10px',
          borderColor: '#999bf7',
        }}
      >
        <div className='d-flex justify-content-between my-auto w-100'>
          <div className='col-10'>
            <span
              className='d-flex justify-content-start align-middle py-2'
              style={{
                color: '#999bf7',
              }}
            >
              {translate('counseling.sendReview')}
            </span>
          </div>
          <div className='col-2 d-flex justify-content-end align-items-center'>
            <IconCheckFilled color='#666fff' size={16} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SendToReviewButtonOff
