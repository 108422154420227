import React, {useState, useEffect} from 'react'
import {
  Modal,
  Button,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
// Components
import StudentModalForm from './Student/StudentInfo'
import PersonalModalForm from './Personal/PersonalModalForm'
import useTranslation from '../../i18n/useTranslation'

const personalObligatoryFields = [
  'firstName',
  'lastName',
  'phone',
  'address',
  'city',
  'state',
  'zipCode',
  'countryId',
  'spokenLanguages',
  'birthdate',
]
const studentObligatoryFields = ['institution', 'graduationDate']

const CompleteProfile = () => {
  const userData = useSelector((state) => state.user.info)
  const {translate} = useTranslation()
  const isStudent = userData.hasOwnProperty('roles')
    ? userData.roles.find((role) => role === 'STUDENT')
    : false
  const [errorValues, setErrorValues] = useState({
    personalInfoErrors: undefined,
    studentInfoErrors: undefined,
    academicInfoErrors: undefined,
  })
  const [personalInfoCallBack, setPersonalInfoCallBack] = useState()
  const [studentInfoCallBack, setStudentInfoCallBack] = useState()
  const [formState, setFormState] = useState()
  const [isOpen, setOpen] = useState(false)
  const handleSubmit = () => {
    if (formState) {
      personalInfoCallBack()
      if (isStudent) {
        studentInfoCallBack()
      }
      setOpen(false)
    }
  }

  useEffect(() => {
    const {personalInfoErrors, studentInfoErrors} = errorValues
    let state = personalInfoErrors === undefined
    if (isStudent) {
      state =
        personalInfoErrors === undefined && studentInfoErrors === undefined
    }
    setFormState(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorValues])

  useEffect(() => {
    const personalBooleans = personalObligatoryFields.map(
      (field) => !!userData[field],
    )
    const studentBooleans = studentObligatoryFields.map(
      (field) => !!userData.studentInfo[field],
    )
    const check = (booleans) => booleans.indexOf(false) !== -1
    let finalCondition = check(personalBooleans)
    if (isStudent) {
      finalCondition = check(personalBooleans) || check(studentBooleans)
    }

    // completar perfil solo cuando el
    // usuario tiene algun plan o lección activos
    setOpen(finalCondition && userData?.checkIfUserHasActiveEvents)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {breakWidth} = useWindowDimensions()
  return (
    <Modal
      isOpen={isOpen}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
      backdrop='static'
    >
      <h2
        className='htext mt-7 mx-4 text-center'
        style={{fontSize: '25px', color: '#2C3F78'}}
      >
        {translate('common.completeProfile.requiredInformation')}
      </h2>
      <div className='mx-4'>
        <div className='mb-5 mt-7'>
          <h3 className='modal-title'>
            <span className='font-weight-bold mr-4'>
              {translate('profile.personalInfo')}
            </span>
            <hr />
          </h3>
        </div>
        <PersonalModalForm
          {...userData}
          formState={formState}
          setPersonalInfoCallBack={setPersonalInfoCallBack}
          setErrorValues={setErrorValues}
        />
        {isStudent && (
          <>
            <div className='mb-5 mt-7'>
              <h3 className='modal-title'>
                <span className='font-weight-bold mr-4'>
                  {translate('profile.studentInfo')}
                </span>
                <hr />
              </h3>
            </div>
            <StudentModalForm
              {...userData}
              formState={formState}
              setStudentInfoCallBack={setStudentInfoCallBack}
              setErrorValues={setErrorValues}
            />
          </>
        )}
        <Button
          disabled={!formState}
          onClick={handleSubmit}
          label={translate('misc.saveChanges')}
          color='primary'
          check
          size='medium'
          className='w-100'
        />
      </div>
    </Modal>
  )
}

export default CompleteProfile
