import React, {useMemo, useState} from 'react'
import {Button} from '@Knowledge-OTP/znk-ui-components'
import useActionsFields from './useActionsFields'
const ActionField = (props) => {
  const {item, refetch} = props
  const {action} = item
  const {key} = action
  const {onClickAction, getLabel} = useActionsFields(refetch)
  const {label, hasAction} = useMemo(
    () => {
      return getLabel(item)
    },
    // eslint-disable-next-line
        [item]
  )
  const [loading, setLoading] = useState(false)

  return hasAction ? (
    <Button
      className='py-0 px-1 h45 btn-width-auto'
      color={
        key === 'completelesson'
          ? 'warning'
          : key === 'checkin'
          ? 'secondary'
          : 'primary'
      }
      size='small'
      activity={loading}
      onClick={() => {
        setLoading(true)
        onClickAction(item, () => {
          setLoading(false)
        })
      }}
      label={label}
      styleText={{padding: '0px'}}
    />
  ) : (
    <span className='m-0 p-0 text-gray' title={label}>
      {label}
    </span>
  )
}

export default ActionField
