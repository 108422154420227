export default {
  edit: 'Editar',
  delete: 'Borrar',
  notFound: 'Sin Resultados!',
  modalError: 'No se pudo completar la acción',
  saveChanges: 'Guardar Cambios',
  timezone: 'Huso horario',
  noLessonFound: 'Sin lecciones',
  nothingFound: 'Sin resultados',
  paymentMethod: 'Método',
  clearFilters: 'Limpiar filtros',
  coolThanks: 'Genial, gracias.',
  results: (num) => `${num} resultado${num === 1 ? '' : 's'}`,
  rangeDate: ({from, to}) => `${from} a ${to}`,
}
