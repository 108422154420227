import React from 'react'
import './index.css'
import {CompleteScoreButton, ScoreCompletedButton} from './Buttons'
import ShowButtonByStatus from './showButtonsByStatus'

const CompleteScore = (props) => {
  const {
    task,
    closeModal = () => {},
    uploadStatusOk,
    filesUploadedByUser,
  } = props

  return (
    <>
      <div>
        {task?.status !== 'pending' ? (
          <ScoreCompletedButton task={task} />
        ) : (
          <CompleteScoreButton
            task={task}
            closeModal={closeModal}
            uploadStatusOk={uploadStatusOk}
            filesUploadedByUser={filesUploadedByUser}
          />
        )}
      </div>
      <div>
        {task?.status !== 'pending' && <ShowButtonByStatus taskData={task} />}
      </div>
    </>
  )
}

export default CompleteScore
