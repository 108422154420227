export default {
  topTitle: 'Selecciona un pago',
  botTitle: 'para ver los detalles',
  noPaymentsDue: 'Sin deuda',
  item: 'Detalle',
  amount: 'Monto',
  total: 'Total',
  endOf: (str) => `Fines de ${str}`,
  paymentDetails:
    'Este invoice se procesara automaticamente a final de cada mes o basado en el método seleccionado en tu perfil',
  pendingInvoices: 'Tienes pagos pendiente',
  pendingInvoicesDesc: 'Estos pagos están pendientes',
  payInvoice: 'Pagar Factura',
  date: 'Fecha',
  creditCard: 'Credit Card',
}
