import {initialState} from './initialState'
import {
  TRANSLATION_DICT_GET_ACTION_ERROR,
  TRANSLATION_DICT_GET_ACTION_INIT,
  TRANSLATION_DICT_GET_ACTION_RESET,
  TRANSLATION_DICT_GET_ACTION_SUCCESS,
  TRANSLATION_LANG_SET_ACTION,
} from './const'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TRANSLATION_DICT_GET_ACTION_INIT:
      return {
        ...state,
        translationDictError: false,
        translationDictSuccess: false,
        translationDictLoading: true,
      }
    case TRANSLATION_DICT_GET_ACTION_ERROR:
      return {
        ...state,
        translationDictError: true,
        translationDictSuccess: false,
        translationDictLoading: false,
        translationDictResponse: action.payload,
      }
    case TRANSLATION_DICT_GET_ACTION_SUCCESS:
      return {
        ...state,
        strings: {...state.strings, ...action.payload},
        translationDictError: false,
        translationDictSuccess: true,
        translationDictLoading: false,
      }
    case TRANSLATION_DICT_GET_ACTION_RESET:
      return {
        ...state,
        translationDictError: false,
        translationDictSuccess: false,
        translationDictLoading: false,
      }
    case TRANSLATION_LANG_SET_ACTION:
      return {
        ...state,
        selectedLang: action.payload,
      }
    default:
      return {...state}
  }
}
