import React from 'react'
import * as moment from 'moment'
import {IconTask} from '@Knowledge-OTP/znk-ui-components'

const InReviewButton = (props) => {
  const {task} = props

  return (
    <>
      <div
        className='d-flex justify-content-center py-2'
        style={{
          width: '100%',
          border: '1px solid',
          borderRadius: '10px',
          borderColor: '#fff7d7',
          backgroundColor: '#fff7d7',
          color: '#e4c01e',
        }}
      >
        <div className='d-flex justify-content-between py-2 my-auto w-100'>
          <div className='col-10'>
            <span className='d-flex justify-content-start align-middle'>
              In review
            </span>
          </div>
          <div className='col-2 d-flex justify-content-end align-items-center'>
            <IconTask
              color='#ffd61f'
              fill='#ffd61f'
              stroke='transparent'
              strokeWidth='2'
              size={16}
            />
          </div>
        </div>
      </div>
      <div className='d-flex flex-row mt-2 ml-1'>
        {/* <span className='h5' style={{color: '#4b4f54'}}>Submitted </span> */}
        <h5 className='font-weight-bold h14 text-gray'>Submitted</h5>
        <h5 className='ml-2 font-weight-light h14 text-gray'>
          on {moment(task?.reviewDate).format('MMM Do, YY h:mm a')}
        </h5>
        {/* <span className='h5' style={{color: '#959fac'}}>
          on {moment(task?.reviewDate).format('MMM Do, YY h:mm a')}
        </span> */}
      </div>
    </>
  )
}

export default InReviewButton
