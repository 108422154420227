import React, {useEffect, useState} from 'react'
import {gql, useMutation, useQuery} from '@apollo/client'
import {useDispatch, useSelector} from 'react-redux'
import {ModalHeader, TabContent, TabPane} from 'reactstrap'
import {
  Button,
  IconCheckmark,
  IconClose,
  Modal,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import PaymentMethod from './PaymentMethod'
import AddCard from './AddCard'
import Skeleton from 'react-loading-skeleton'
import AddBankDebit from './AddBankDebit'
import AddManualPaymentMethod from './AddManualPaymentMethod'
import {SET_USER_INFO_ACTION} from '../../../../store/user/const'
import AddZellePaymentMethod from './AddZelle'

const ModalCard = (props) => {
  const {breakWidth, isOpen, onClick} = props
  const {translate} = useTranslation()
  const [page, setPage] = useState('methods')
  const GET_USER_LIST = gql`
    query {
      getPlaidLink
      me {
        id
        paymentMethod {
          kind
          ... on CardPaymentMethod {
            cardInfo {
              last4
              brand
              expYear
              expMonth
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
        registeredPaymentMethods {
          id
          kind
          ... on CardPaymentMethod {
            cardInfo {
              last4
              brand
              expYear
              expMonth
            }
          }
          ... on BankDebitPaymentMethod {
            validationStatus
            accountInfo {
              bankName
              last4
            }
          }
          ... on BankCreditPaymentMethod {
            status
            sourceInfo {
              bankName
              accountNumber
              swiftCode
              routingNumber
            }
          }
          ... on ManualPaymentMethod {
            manualPaymentInfo {
              bankName
              accountNumber
              routingNumber
              beneficiary
              address
              swiftCode
            }
          }
          ... on ZellePaymentMethod {
            zellePaymentInfo {
              emailToSend
            }
          }
        }
      }
    }
  `
  const {data, methodLoading, refetch} = useQuery(GET_USER_LIST)
  const [setDefaultMethod, {loading: loadingDefaultMethod}] = useMutation(
    gql`
      mutation setMyDefault($paymentMethodId: String!) {
        setMyDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
          id
          paymentMethod {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
          registeredPaymentMethods {
            id
            kind
            ... on CardPaymentMethod {
              cardInfo {
                last4
                brand
                expYear
                expMonth
              }
            }
            ... on BankDebitPaymentMethod {
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on BankCreditPaymentMethod {
              status
              sourceInfo {
                bankName
                accountNumber
                swiftCode
                routingNumber
              }
            }
            ... on ManualPaymentMethod {
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on ZellePaymentMethod {
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
      }
    `,
    {
      update: (cache, mutationResult) => {
        cache.writeQuery({
          query: GET_USER_LIST,
          data: {
            getPlaidLink: data.getPlaidLink,
            me: mutationResult.data.setMyDefaultPaymentMethod,
          },
        })
      },
    },
  )
  const setMethodAsDefault = (paymentMethodId) => {
    setDefaultMethod({
      variables: {
        paymentMethodId,
      },
    })
  }
  const currentMethod = data && !methodLoading ? data.me?.paymentMethod : null
  // actualiza método de pago en redux
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.user.info)
  useEffect(() => {
    // actualiza método de pago en redux
    if (currentMethod) {
      dispatch({
        type: SET_USER_INFO_ACTION,
        payload: {
          ...userData,
          paymentMethod: currentMethod,
        },
      })
    }
    // eslint-disable-next-line
  }, [currentMethod, dispatch])
  const registeredMethods =
    data && !methodLoading ? data.me?.registeredPaymentMethods : []
  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'} pl-0`}
      backdropClassName='no-op'
      isOpen={isOpen}
      toggle={onClick}
      Head={
        <ModalHeader
          toggle={onClick}
          close={
            <IconClose className='text-cancel' size={20} onClick={onClick} />
          }
          className='px-0'
        >
          <h2 className='htext' style={{fontSize: '24px'}}>
            {(() => {
              switch (page) {
                case 'methods':
                  return methodLoading
                    ? '...'
                    : currentMethod
                    ? translate('profile.actions.changeMethod.change')
                    : translate('profile.actions.changeMethod.add')
                case 'addCard':
                  return translate('profile.actions.changeMethod.add')
                default:
                  return null
              }
            })()}
          </h2>
        </ModalHeader>
      }
    >
      <TabContent className='pl-0 ml-0' activeTab={page}>
        <TabPane tabId='methods'>
          <div className='container-fluid pl-0'>
            <div className='col-12 col-lg-10 pl-0'>
              <div className='w-100 mb-4 pl-0'>
                {!methodLoading && !loadingDefaultMethod ? (
                  <span className='text-title h3 pl-0'>
                    {translate('profile.actions.changeMethod.current')}:
                  </span>
                ) : (
                  <Skeleton height={60} />
                )}
              </div>
              {!methodLoading && !loadingDefaultMethod ? (
                currentMethod !== null ? (
                  <PaymentMethod {...currentMethod} />
                ) : (
                  <div className='flex-grow-1 p-auto'>
                    {translate('misc.notFound')}
                  </div>
                )
              ) : (
                <Skeleton height={60} />
              )}
            </div>
          </div>
          <div className='container-fluid mt-6 pl-0'>
            <div className='col-12 col-lg-10 pl-0'>
              <div className='w-100 mb-4 d-flex flex-column justify-content-start pl-0'>
                <span className='text-title h3 pl-0'>
                  {translate('profile.actions.changeMethod.registered')}:
                </span>
                <span className='m-0 p-0 d-flex flex-row w-100 justify-content-end'>
                  <span
                    className='btn-link m-0 p-0'
                    onClick={() => setPage('addCard')}
                  >
                    {translate('profile.actions.changeMethod.addCard')}
                  </span>
                  <span className='mx-2 text-title'>/</span>
                  <AddManualPaymentMethod refetch={refetch} />
                  {data && !methodLoading ? (
                    <>
                      <span className='mx-2 text-title'>/</span>
                      <AddBankDebit
                        refetch={refetch}
                        keyPlaid={data.getPlaidLink}
                      />
                    </>
                  ) : null}
                  <span className='mx-2 text-title'>/</span>
                  <AddZellePaymentMethod refetch={refetch} />
                </span>
              </div>
              {loadingDefaultMethod || methodLoading ? (
                <Skeleton height={60} count={3} />
              ) : registeredMethods.length ? (
                registeredMethods.map((method, idx) => {
                  return method.id !== currentMethod?.id ? (
                    <PaymentMethod
                      key={method.id || idx}
                      {...method}
                      clickable
                      mutation={setMethodAsDefault}
                    />
                  ) : null
                })
              ) : (
                translate('profile.actions.changeMethod.noRegisteredMethods')
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tabId='addCard'>
          <AddCard
            onSuccess={() => {
              refetch()
              setPage('success')
            }}
            goBack={() => setPage('methods')}
            currenMethod={currentMethod}
          />
        </TabPane>
        <TabPane tabId='success'>
          <IconCheckmark size={breakWidth === 'SM' ? 80 : 120} />
          <h2 className='htext mb-8' style={{marginTop: 48}}>
            {translate('profile.actions.changeMethod.method')}{' '}
            {currentMethod === null
              ? translate('profile.actions.changeMethod.added')
              : translate('profile.actions.changeMethod.changed')}
          </h2>
          <p className='text-p mb-8'>
            {translate('profile.actions.changeMethod.success')}
          </p>
          <Button
            label={translate('misc.coolThanks')}
            color='primary'
            check
            size='medium'
            onClick={() => setPage('methods')}
            style={{width: '100%'}}
          />
        </TabPane>
      </TabContent>
    </Modal>
  )
}

export default ModalCard
