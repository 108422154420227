import queryLessonResult from './useLessonResult'

const queryUserHashResult = `
    id
    isActive
    avatar
    checkIfUserHasActiveEvents
    paymentMethod {
        ... on CardPaymentMethod {
            cardInfo {
                last4
                brand
                expYear
                expMonth
            }
        }
        ... on BankDebitPaymentMethod {
            kind
            validationStatus
            accountInfo {
                bankName
                last4
            }
        }
        ... on ManualPaymentMethod {
            manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
            }
        }
        ... on BankCreditPaymentMethod {
            kind
            status
            sourceInfo {
                accountNumber
                routingNumber
                bankName
                swiftCode
            }
        }
        ... on ZellePaymentMethod {
            kind
            zellePaymentInfo {
                emailToSend
            }
        }
    }
    emails {
    address
    verified
    }
    countryId
    gender
    spokenLanguages
    address
    city
    state
    zipCode
    firstName
    lastName
    phone
    timezone
    birthdate
    studentInfo {
        institution
        grade
        graduationDate
        materials
        gpa
        aditionalInfo
        defaultRate
        studentType
        universities {
            id
            status
            early
            name
            collegeId
            logo
            isPrincipal
        }
        servicesRate {
            serviceId
            name
            defaultRate
        }
        parents {
            firstName
            lastName
            managePayments
            email
            phone
            parentUserId
        }
        notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
            isAvailable
            duration
            }
        }
        upcomingLesson {
            ${queryLessonResult}
        }
        nextLesson {
            id
            startDate
        }
        homeNotification {
            councelingPlanId
            councelingPlanType
            countryName
            tasksPending {
                id
                status
                title
                startDate
                dueDate
                task
            }
            lessonsPending {
                id
                startDate
                endDate
                title
                service {
                    id
                    name
                }
                topic {
                    id
                    name
                }
                educator {
                    firstName
                    lastName
                }
                substitute {
                    firstName
                    lastName
                }
            }
            invoicesPending {
                id
                invoiceNumber
                amount
                to
            }
            approvalPlans {
                _id
                from
                to
                name
                isFixed
                isTotalLesson
                totalLessons
                kind
            }
            upcomingCounselingPlan {
                _id
                from
                to
                name
            }
        }
    }
    parentInfo {
        children {
            studentUserId
            managePayments
            manageApprovals
            email
            firstName
            lastName
        }
        homeChildrensNotifications {
            councelingPlanId
            councelingPlanType
            countryName
            tasksPending {
                id
                status
                title
                startDate
                dueDate
                task
            }
            lessonsPending {
                id
                startDate
                endDate
                title
                service {
                    id
                    name
                }
                topic {
                    id
                    name
                }
                educator {
                    firstName
                    lastName
                }
                substitute {
                    firstName
                    lastName
                }
            }
            invoicesPending {
                id
                invoiceNumber
                amount
                to
            }
            approvalPlans {
                _id
                from
                to
                name
                isFixed
                isTotalLesson
                totalLessons
                kind
                assignedTo
            }
            upcomingCounselingPlan {
                _id
                from
                to
                name
            }
        }
    }
    educatorInfo {
        id
        isWaivePayments
        defaultRate
        disableForStudents
        rating
        services {
            serviceId
            name
            defaultRate
            topics {
            topicId
            teachingTypes
            name
            individualRate
            groupRate
            isWaiveIndividual
            isWaiveGroup
            }
        }
        myPayslips {
            id
            status
            category
            payslipDate
            to
            payslipNumber
            payslipProps {
            amount
            isPenalized
            }
        }
        backupLink
        weeklyCapHours
        canTeach
        isActive
        bankNumber
        bankRouting
        googleCalendar
        backupLink
        handbook
        upcomingLesson {
            ${queryLessonResult}
        }
        pendingLessons {
            ${queryLessonResult}
        }
        nextLesson {
            ${queryLessonResult}
        }
        pendingTasks {
            id
            status
            title
            startDate
            dueDate
            task
        }
    }
  
    roles
    adminSignupToken
    `

export default queryUserHashResult
