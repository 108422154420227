import React from 'react'
import {ModalLayout} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import DocsAndExamsFilter from './Filter'
import useTranslation from '../../../../i18n/useTranslation'
import useDocsAndExams from './useDocsAndExams'
import DocsAndExamsComponent from './DocComponent'

const DocsAndExamsModal = () => {
  const {isOpen, toggle} = useDocsAndExams()
  const {translate} = useTranslation()

  return (
    <>
      <ModalLayout
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <span className='h3 text-title'>
            {translate('counseling.docsExams')}
          </span>
        }
        minHead={<span className='h3 text-title' />}
      >
        <DocsAndExamsFilter />
        <DocsAndExamsComponent />
      </ModalLayout>
    </>
  )
}

DocsAndExamsModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
}

DocsAndExamsModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default DocsAndExamsModal
