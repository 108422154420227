// Recibe el array que se pasa en el VirtualChat, que es un array
// de objetos con los integrantes del team y un objeto con los avatares
import rolesNames from './rolesNames'

export const setAvatarToUsers = (usersList) => {
  if (!Array.isArray(usersList)) return []
  const onlyUsersList = usersList.filter((user) => user.id)
  const usersWithAvatar = []
  if (onlyUsersList?.length < 1) return
  onlyUsersList.map((user) =>
    usersWithAvatar.push({
      ...user,
      avatar:
        usersList[3][
          `avatar${
            rolesNames[user.role][0].toUpperCase() +
            rolesNames[user.role].substring(1)
          }`
        ],
    }),
  )

  return usersWithAvatar
}
