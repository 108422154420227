import React, {useState} from 'react'
import {
  Modal,
  useWindowDimensions,
  ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import {adjustAll, adjustTopicPart} from '../utils'
import useTranslation from '../../../i18n/useTranslation'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const AdjustPlan = (props) => {
  const {className, disabled} = props
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!isOpen)
  }

  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const {translate} = useTranslation()

  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimes, dataPlan, orientationKey} = reduxData
  const ORIENTATION_KEY = orientationKey || 'Orientation-0'
  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const topicList = scheduledTimes
    ? Object.entries(scheduledTimes).reduce((topicArray, [key, value]) => {
        topicArray.push({
          key,
          topic: value.topic,
        })
        return topicArray
      }, [])
    : []

  return (
    <>
      <div
        className={className}
        onClick={() => {
          if (!disabled) {
            toggle()
          }
        }}
      >
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-float modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-hauto`}
        StyleModalBody={{backgroundColor: 'white'}}
        isOpen={isOpen}
        toggle={toggle}
        Foot={
          <ListItem
            className='listItem-white px-4 d-flex justify-content-center w-100'
            style={{borderRadius: '12px'}}
            Left={
              <div className='d-flex align-items-center' onClick={toggle}>
                <h3>{translate('home.onboard.adjust.cancel')}</h3>
              </div>
            }
          />
        }
      >
        <div className='row m-0 p-0'>
          <div
            className='m-0 p-0 w-100'
            style={{
              listStyleType: 'none',
            }}
          >
            <ListItem
              className='listItem-white px-4 d-flex justify-content-center'
              Left={
                <div className='d-flex align-items-center'>
                  <h3>{translate('home.onboard.adjust.title')}</h3>
                </div>
              }
            />
            {topicList.map((obj) => {
              if (obj.key !== ORIENTATION_KEY) {
                return (
                  <ListItem
                    showBottomLine
                    showTopLine
                    borderTopClassName='borderTop-white'
                    className='listItem-white px-4 d-flex justify-content-center'
                    Left={
                      <div className='d-flex align-items-center text-muted'>
                        <span>
                          {translate('home.onboard.adjust.rescheduled', {
                            name: obj.topic.name,
                            indexPart: parseInt(obj.key.split('-')[1]) + 1,
                          })}
                        </span>
                      </div>
                    }
                    onClick={() => {
                      dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'onboarding.data',
                        data: {
                          ...reduxData,
                          ...adjustTopicPart(
                            scheduledTimes,
                            obj.key,
                            dataPlan.isTotalLesson,
                          ),
                        },
                      })
                      toggle()
                      dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'onboarding.smallModal',
                        data: {
                          ...reduxSmall,
                          isOpen: true,
                          page: dataPlan.isTotalLesson
                            ? 'selectLpw'
                            : 'introductionTopic',
                        },
                      })
                    }}
                  />
                )
              } else {
                return null
              }
            })}
            <ListItem
              showBottomLine={false}
              showTopLine={false}
              onClick={() => {
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'onboarding.data',
                  data: {
                    ...reduxData,
                    ...adjustAll(scheduledTimes, dataPlan.isTotalLesson),
                  },
                })
                toggle()
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'onboarding.smallModal',
                  data: {...reduxSmall, isOpen: true, page: 'introductionPart'},
                })
              }}
              className='listItem-white px-4 d-flex justify-content-center'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <span>{translate('home.onboard.adjust.rescheduledAll')}</span>
                </div>
              }
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AdjustPlan
