import React, {useEffect, useState} from 'react'
import {
  Activity,
  Countdown,
  IconArrowRight,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {useSelector} from 'react-redux'
import moment from 'moment-timezone'
import CheckInLesson from '../../../../Model/Lessons/CheckInLesson'
import useLinkLesson from '../../../Calendar/Lessons/Modal/InfoModal/useLinkLesson'
import BackupLinkButton from './BackUpLinkButton'
import {hasHttp} from '../../../Calendar/Lessons/Modal/InfoModal/utils'
import './index.css'

const CheckInStudent = (props) => {
  const {dataLesson, currentStudent, setDataLessonOnRedux} = props
  const {translate} = useTranslation()
  // eslint-disable-next-line
  const [lessonOnGoing, setLessonOnGoing] = useState(
    moment(dataLesson?.startDate) <= moment() &&
      moment() < moment(dataLesson?.endDate),
  )
  const lessonFinished = moment() >= moment(dataLesson?.endDate)
  const {timezone} = useSelector((state) => state.user.info)

  const [timeToStart, setTimeToStart] = useState(
    moment.duration(moment(dataLesson?.startDate).diff(moment())).asMinutes(),
  )
  useEffect(() => {
    // inicia cuenta para habilitar el botón checkIn
    const initTime = moment
      .duration(
        moment(dataLesson?.startDate).subtract(10, 'minutes').diff(moment()),
      )
      .asMilliseconds()
    const initLesson = moment
      .duration(moment(dataLesson?.startDate).diff(moment()))
      .asMilliseconds()
    const timer = setTimeout(() => {
      setTimeToStart(
        moment
          .duration(moment(dataLesson?.startDate).diff(moment()))
          .asMinutes(),
      )
    }, initTime)
    const updateOnGoing = setTimeout(() => {
      setLessonOnGoing(
        moment(dataLesson?.startDate) <= moment() &&
          moment() < moment(dataLesson?.endDate),
      )
    }, initLesson)
    return () => {
      clearTimeout(timer)
      clearTimeout(updateOnGoing)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLesson])
  const {submit: checkIn, loading: loadCheckIn} = CheckInLesson(
    ({data}) => {
      // marca como attended el attendenStatus del educador
      // alert("success")
      setDataLessonOnRedux(data?.checkInLesson || dataLesson)
    },
    () => {
      // alert('error')
    },
  )

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const isCheckIn = currentStudent?.attendanceStatus === 'attended'
  return (
    <div
      className='m-0 p-0 d-flex flex-column mt-2'
      style={{position: 'sticky', bottom: '0px'}}
    >
      <div className='m-0 p-0 d-flex flex-column'>
        <div className='m-0 p-0 d-flex flex-column'>
          {lessonFinished ? (
            <></>
          ) : /** (timeToStart < 10 && timeToStart >= 0) ||**/
          isCheckIn ? (
            <>
              <div className='d-flex flex-row mb-3'>
                <BackupLinkButton
                  className='w-50 mr-1'
                  educatorId={
                    dataLesson?.substitute?.educatorUserId ||
                    dataLesson?.educator?.educatorUserId
                  }
                />
                <Button
                  style={
                    dataLesson.location.type === 'virtualClassroom'
                      ? {
                          borderRadius: '16px 16px 16px 16px',
                          backgroundColor: '#696CFF',
                          width: '50%',
                        }
                      : {backgroundColor: '#696CFF', width: '50%'}
                  }
                  className='ml-1'
                  size='large'
                  onClick={() => {
                    // si location es VCR es launchURL, sino es location.linkStudent!
                    setGoToClassLoad(true)
                    getvclink(dataLesson?.id, null)
                      .then(
                        ({launchUrl, location}) => {
                          if (
                            location.type === 'virtualClassroom' ||
                            location.type === 'zinkerzPlatform'
                          )
                            window.open(hasHttp(launchUrl), '_blank')
                          else
                            window.open(hasHttp(location.linkStudent), '_blank')
                          setGoToClassLoad(false)
                        },
                        (error) => {
                          console.error(error)
                          // setVcLinkError(true)
                          setGoToClassLoad(false)
                        },
                      )
                      .catch((error) => {
                        console.error(error)
                        // setVcLinkError(true)
                        setGoToClassLoad(false)
                      })
                  }}
                  label={
                    goToClassLoad ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <span className='text-center'>
                        {translate('modal.infolesson.gotoclassroom')}
                      </span>
                    )
                  }
                />
              </div>
              <div
                className='zkn-btn znk-size-small w-100 mb-3 btn'
                style={{backgroundColor: '#F0F1FF'}}
              >
                <div className='d-flex flex-row justify-content-between'>
                  <span className='h45 text-primary'>
                    {translate('student.infolesson.startat')}
                  </span>
                  <Countdown
                    endTime={
                      dataLesson?.startDate
                        ? moment(dataLesson?.startDate).tz(timezone)
                        : moment().tz(timezone)
                    }
                    iconColor='#696CFF'
                    timeOverLabel={translate('student.infolesson.timeisover')}
                    spanTimeClassName='h45 text-primary'
                    spanSecondsClassName='h45 text-primary'
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='d-flex flex-row mb-3 mt-6'>
                <Button
                  style={{backgroundColor: '#696CFF'}}
                  className='w-100'
                  size='large'
                  onClick={() => {
                    if (timeToStart < 10) {
                      checkIn({lessonId: dataLesson?.id || ''})
                    }
                  }}
                  disabled={timeToStart >= 10}
                  label={
                    loadCheckIn ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <div className='d-flex flex-row justify-content-between'>
                        <span>
                          {translate('virtualChat.checkInStudent.checkInChat')}
                        </span>
                        <IconArrowRight color='white' />
                      </div>
                    )
                  }
                />
              </div>
              <div
                className='zkn-btn znk-size-small w-100 mb-3 btn'
                style={{backgroundColor: 'white', border: '1px solid #5C5FF2'}}
              >
                <div className='d-flex flex-row justify-content-between'>
                  <span className='h45 text-primary'>
                    {translate('student.infolesson.startat')}
                  </span>
                  <Countdown
                    endTime={
                      dataLesson?.startDate
                        ? moment(dataLesson?.startDate).tz(timezone)
                        : moment().tz(timezone)
                    }
                    iconColor='#696CFF'
                    timeOverLabel={translate('student.infolesson.timeisover')}
                    spanTimeClassName='h45 text-primary'
                    spanSecondsClassName='h45 text-primary'
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CheckInStudent
