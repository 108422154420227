export default {
  topTitle: 'Select an Invoice',
  botTitle: 'to see details',
  noPaymentsDue: 'No due',
  item: 'Item',
  amount: 'Amount',
  total: 'Total price',
  endOf: (str) => `End of ${str}`,
  paymentDetails:
    'This invoice will be processed automatically at the end of the month or based by your current selected method',
  pendingInvoices: 'You have pending payments',
  pendingInvoicesDesc: 'This Invoices are due payment',
  date: 'Date',
  payInvoice: 'Pay Invoice',
  creditCard: 'Credit Card',
}
