import React, {useEffect, useState, useMemo} from 'react'
import {
  IconIllustratedLesson,
  IconClose,
  IconSignMinus,
  ListItem,
  IconArrowRight,
  Countdown,
  Activity,
  Button,
  IconVideo,
  IconComment,
  IconRepeat,
  Rating,
  IconProgram,
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'
import ratingLessonForStudent from '../../../../../../Model/Lessons/RatingLessonForStudent'
import CheckInLesson from '../../../../../../Model/Lessons/CheckInLesson'
import {hasHttp} from '../utils'
import {showAlert} from '../../../../../../common/Alert/util'
import useLinkLesson from '../useLinkLesson'
import moment from 'moment-timezone'
import {useDispatch, useSelector} from 'react-redux'
import CancelLesson from '../../CancelLesson'
import useModalState from '../../../../../../hooks/useModalState'
import BackupLinkButton from '../../../../../CalendarLessons/modal/InfoLesson/Components/BackupLinkButton'
import PopupBackupLink from '../../../../../CalendarLessons/modal/InfoLesson/Components/PopUpBackUpLink'
import useSendWspWhenStudentsCheckinFirst from '../../../Model/useSendWspWhenStudentCheckinFirst'
const optionsKeys = {
  viewRecording: 'viewRecording',
  viewMessages: 'viewMessages',
  rescheduled: 'rescheduled',
  repeat: 'repeat',
}

const UpcommingHeaderModal = (props) => {
  const {
    RenderTitleLesson,
    dataLesson,
    toggle,
    currentStudent,
    setDataLessonOnRedux,
  } = props
  const {rating} = currentStudent
  const {id: idLesson} = dataLesson
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const lessonFinished = moment() >= moment(dataLesson?.endDate)
  const {submit: rateLesson, loading: loadRate} = ratingLessonForStudent(
    ({data}) => {
      setDataLessonOnRedux(data.ratingLessonForStudent)
      showAlert(
        {
          text: translate('student.infolesson.rating.successAlert'),
          status: 'success',
        },
        dispatch,
      )
    },
    () => {
      showAlert(
        {
          text: translate('student.infolesson.rating.errorAlert'),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  const [ratingStudent, setRatingStudent] = useState(null)

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus size={20} className='ml-auto' />
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        {!(lessonFinished && rating === null) && (
          <IconIllustratedLesson size={80} />
        )}
        <RenderTitleLesson />
        {lessonFinished && rating === null && (
          <div className='d-flex flex-column m-0 p-0'>
            <div
              className='borderTop my-3'
              style={{color: 'white', backgroundColor: 'white', opacity: '0.5'}}
            />
            <span className='h4 my-1'>
              {translate('student.infolesson.rating.rateLesson')}
            </span>
            <span className='h45 my-1 pr-2' style={{opacity: '0.5'}}>
              {translate('student.infolesson.rating.body')}
            </span>
            <Rating
              mode='edit'
              initialRating={ratingStudent}
              onChange={(rate) => {
                setRatingStudent(rate)
              }}
            />
            <Collapse isOpen={ratingStudent !== null}>
              <div className='w-100 m-0 p-0 d-flex flex-row justify-content-end'>
                {loadRate ? (
                  <>
                    <Activity inverse />
                  </>
                ) : (
                  <>
                    <span
                      className='m-0 p-0 h45'
                      style={{opacity: '0.5', cursor: 'pointer'}}
                      onClick={() => {
                        setRatingStudent(null)
                      }}
                    >
                      {translate('student.infolesson.rating.cancel')}
                    </span>
                    <span
                      className='my-0 mr-0 ml-2 p-0 h45'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        rateLesson({
                          rating: parseFloat(ratingStudent),
                          lessonId: idLesson || '',
                        })
                      }}
                    >
                      {translate('student.infolesson.rating.confirm')}
                    </span>
                  </>
                )}
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </>
  )
}

const UpcommingBodyModal = (props) => {
  const {
    RenderEducator,
    dataLesson,
    RenderLessonNotes,
    currentStudent,
    currentEducator,
    refetchLessons,
    setDataLessonOnRedux,
    setTabValue,
  } = props
  const {title, startDate, service, topic, id, isLocked} = dataLesson
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {onChange} = useModalState('program.newManualLesson')
  const openCopyLesson = () => {
    const {educatorUserId, firstName, lastName} = currentEducator
    onChange((s) => ({
      stage: 2,
      serviceId: service.id,
      topicId: topic.id,
      educator: {
        id: educatorUserId,
        firstName,
        lastName,
      },
      dateTime: null,
    }))
  }
  //eslint-disable-next-line
  const [sendWsp, {loading: loadWsp}] = useSendWspWhenStudentsCheckinFirst(
    () => {
      //On success
    },
    (err) => {
      //On error
    },
  )
  const [lessonOnGoing, setLessonOnGoing] = useState(
    moment(dataLesson?.startDate) <= moment() &&
      moment() < moment(dataLesson?.endDate),
  )
  const [backupLinkOpen, setBackupLinkOpen] = useState(false)
  const lessonFinished = moment() >= moment(dataLesson?.endDate)
  const {timezone} = useSelector((state) => state.user.info)

  const [timeToStart, setTimeToStart] = useState(
    moment.duration(moment(dataLesson.startDate).diff(moment())).asMinutes(),
  )
  useEffect(() => {
    // inicia cuenta para habilitar el botón checkIn
    const initTime = moment
      .duration(
        moment(dataLesson?.startDate).subtract(10, 'minutes').diff(moment()),
      )
      .asMilliseconds()
    const initLesson = moment
      .duration(moment(dataLesson?.startDate).diff(moment()))
      .asMilliseconds()
    const timer = setTimeout(() => {
      setTimeToStart(
        moment
          .duration(moment(dataLesson?.startDate).diff(moment()))
          .asMinutes(),
      )
    }, initTime)
    const updateOnGoing = setTimeout(() => {
      setLessonOnGoing(
        moment(dataLesson?.startDate) <= moment() &&
          moment() < moment(dataLesson?.endDate),
      )
    }, initLesson)
    return () => {
      clearTimeout(timer)
      clearTimeout(updateOnGoing)
    }
  }, [dataLesson])
  const {submit: checkIn, loading: loadCheckIn} = CheckInLesson(
    ({data}) => {
      // marca como attended el attendenStatus del educador
      setDataLessonOnRedux(data?.checkInLesson || dataLesson)
      const educatorAttendance = data?.checkInLesson?.substitute?.attendanceStatus ? data?.checkInLesson?.substitute?.attendanceStatus : data?.checkInLesson?.educator?.attendanceStatus
      if (educatorAttendance !== 'attended') {
        sendWsp({variables: {lessonId: data?.checkInLesson?.id}})
    }
    },
    () => {
      alert('error')
    },
  )

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const isOnNext24 = useMemo(() => {
    return timeToStart / 60 < 24
  }, [timeToStart])
  const isGroupLesson = dataLesson?.__typename === 'GroupLesson'
  const isCheckIn = currentStudent?.attendanceStatus === 'attended'

  const RenderOption = ({optionKey}) => {
    switch (optionKey) {
      case optionsKeys.viewRecording:
        return (
          <ListItem
            showTopLine
            showBottomLine={false}
            className='py-4'
            Left={
              <div className='d-flex flex-row'>
                <IconVideo className='align-self-center mr-2' />
                <span className='align-self-center'>
                  {translate(`student.infolesson.options.viewRecording`)}
                </span>
              </div>
            }
            Right={<IconArrowRight />}
          />
        )
      case optionsKeys.viewMessages:
        return (
          <ListItem
            onClick={() => {
              setTabValue('chat')
            }}
            showTopLine
            showBottomLine
            className='py-4'
            Left={
              <div className='d-flex flex-row'>
                <IconComment
                  color='#696CFF'
                  className='align-self-center mr-2'
                />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.viewMessages')}
                </span>
              </div>
            }
            Right={<IconArrowRight color='#696CFF' />}
          />
          // </ToolTipInfo>
        )
      case optionsKeys.rescheduled:
        return (
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-4'
            onClick={() => {
              isOnNext24 || isLocked || isGroupLesson
                ? dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'program.cantReschedule',
                    data: {
                      isOpen: true,
                      isLocked,
                      isOnNext24,
                      isGroupLesson,
                    },
                  })
                : dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'program.rescheduledModal',
                    data: {
                      isOpen: true,
                      stage: 'timeSelectionBlock',
                      educator: currentEducator,
                      selectedBlock: null,
                      refetchLessons: refetchLessons,
                      lesson: {
                        lessonId: id,
                        name: title,
                        service: service,
                        topic: topic
                      },
                      plan: currentStudent,
                      setOpenInfoModal: (bool) => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'student.infoLessonModal',
                          data: {
                            isOpen: bool,
                            dataLesson,
                          },
                        })
                      },
                    },
                  })
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconProgram
                  color='#696CFF'
                  className='align-self-center mr-2'
                />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.rescheduled')}
                </span>
              </div>
            }
            Right={<IconArrowRight color='#696CFF' />}
          />
        )
      case optionsKeys.repeat:
        return (
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-4'
            onClick={() => {
              openCopyLesson()
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconRepeat className='align-self-center mr-2 text-primary' />
                <span className='align-self-center text-primary'>
                  {translate('student.infolesson.options.repeat')}
                </span>
              </div>
            }
            Right={<IconArrowRight className='text-primary' />}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-between h-100'>
      <div className='m-0 p-0 d-flex flex-column'>
        <RenderEducator />
        <div className='m-0 p-0 d-flex flex-column'>
          {lessonFinished ? (
            <>
              <RenderLessonNotes />
            </>
          ) : /** (timeToStart < 10 && timeToStart >= 0) ||**/
          isCheckIn ? (
            <>
              <div className='d-flex flex-row mb-3 mt-6'>
                <BackupLinkButton
                  className='w-50 mr-1'
                  educatorId={
                    dataLesson?.substitute?.educatorUserId ||
                    dataLesson?.educator?.educatorUserId
                  }
                  backupPopupImplementation
                  clickButton={() => setBackupLinkOpen(true)}
                />
                <Button
                  style={{backgroundColor: '#696CFF'}}
                  className='w-50 ml-1'
                  size='large'
                  onClick={() => {
                    // si location es VCR es launchURL, sino es location.linkStudent!
                    setGoToClassLoad(true)
                    getvclink(dataLesson?.id, null)
                      .then(
                        ({launchUrl, location}) => {
                          if (
                            location.type === 'virtualClassroom' ||
                            location.type === 'zinkerzPlatform'
                          )
                            window.open(hasHttp(launchUrl), '_blank')
                          else
                            window.open(hasHttp(location.linkStudent), '_blank')
                          setGoToClassLoad(false)
                        },
                        (error) => {
                          showAlert(
                            {
                              text: translate('student.infolesson.studentErrorClassroom'),
                              status: 'error',
                            },
                            dispatch,
                          )
                          console.error(error)
                          // setVcLinkError(true)
                          setGoToClassLoad(false)
                        },
                      )
                      .catch((error) => {
                        console.error(error)
                        // setVcLinkError(true)
                        setGoToClassLoad(false)
                      })
                  }}
                  label={
                    goToClassLoad ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <span className='text-center'>
                        {translate('student.infolesson.options.gotoclassroom')}
                      </span>
                    )
                  }
                />
              </div>
              <div
                className='zkn-btn znk-size-small w-100 mb-6 btn'
                style={{backgroundColor: '#F0F1FF'}}
              >
                <div className='d-flex flex-row justify-content-between'>
                  <span className='h45 text-primary'>
                    {translate('student.infolesson.startat')}
                  </span>
                  <Countdown
                    endTime={
                      dataLesson?.startDate
                        ? moment(dataLesson?.startDate).tz(timezone)
                        : moment().tz(timezone)
                    }
                    iconColor='#696CFF'
                    timeOverLabel={translate('student.infolesson.timeisover')}
                    spanTimeClassName='h45 text-primary'
                    spanSecondsClassName='h45 text-primary'
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='d-flex flex-row mb-3 mt-6'>
                <Button
                  style={{backgroundColor: '#696CFF'}}
                  className='w-100'
                  size='large'
                  onClick={() => {
                    if (timeToStart < 10) {
                      checkIn({lessonId: dataLesson?.id || ''})
                    }
                  }}
                  disabled={timeToStart >= 10}
                  label={
                    loadCheckIn ? (
                      <div className='w-100 d-flex flex-row justify-content-center'>
                        <Activity inverse />
                      </div>
                    ) : (
                      <div className='d-flex flex-row justify-content-between'>
                        <span>
                          {translate('student.infolesson.options.checkIn')}
                        </span>
                        <IconArrowRight color='white' />
                      </div>
                    )
                  }
                />
              </div>
              <div
                className='zkn-btn znk-size-small w-100 mb-6 btn'
                style={{backgroundColor: 'white', border: '1px solid #5C5FF2'}}
              >
                <div className='d-flex flex-row justify-content-between'>
                  <span className='h45 text-primary'>
                    {translate('student.infolesson.startat')}
                  </span>
                  <Countdown
                    endTime={
                      dataLesson?.startDate
                        ? moment(dataLesson?.startDate).tz(timezone)
                        : moment().tz(timezone)
                    }
                    iconColor='#696CFF'
                    timeOverLabel={translate('student.infolesson.timeisover')}
                    spanTimeClassName='h45 text-primary'
                    spanSecondsClassName='h45 text-primary'
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className='m-0 p-0 d-flex flex-column'>
          {lessonFinished ? (
            <>
              <RenderOption optionKey={optionsKeys.viewRecording} />
              <RenderOption optionKey={optionsKeys.viewMessages} />
            </>
          ) : (
            <>
              <RenderOption optionKey={optionsKeys.viewMessages} />
              <RenderOption optionKey={optionsKeys.rescheduled} />
            </>
          )}
          <RenderOption optionKey={optionsKeys.repeat} />
        </div>
      </div>
      <div className='mx-0 my-3 p-0'>
        {lessonOnGoing ? (
          <span className='h5 text-gray m-0 p-0'>
            {translate('student.infolesson.options.needHelp')}
          </span>
        ) : (
          !lessonFinished && (
            <CancelLesson
              startDate={startDate}
              lessonId={id}
              refetchLessons={refetchLessons}
              isLocked={isLocked}
              isGroupLesson={isGroupLesson}
            >
              <span className='h5 text-gray m-0 p-0'>
                {translate('student.infolesson.options.needCancel')}
              </span>
            </CancelLesson>
          )
        )}
      </div>
      <PopupBackupLink
        isOpen={backupLinkOpen}
        toggle={() => setBackupLinkOpen(!backupLinkOpen)}
        educatorId={
          dataLesson?.substitute?.educatorUserId ||
          dataLesson?.educator?.educatorUserId
        }
        collectionId={dataLesson?.id}
        link={dataLesson.backupLink}
        userInfo={currentStudent}
      />
    </div>
  )
}

export {UpcommingHeaderModal, UpcommingBodyModal}
