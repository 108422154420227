import React from 'react'
import WeeklySchedule from '../../components/WeeklySchedule'
import LessonsIcons from '../../components/LessonsIcons'
import {useSelector} from 'react-redux'
import {
  CarouselFrame,
  StickyButton,
  IconIllustratedTopic,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'

export const IntroductionTopicHeader = (props) => {
  const {translate} = useTranslation()
  const data = useSelector((state) => state.modal.onboarding.data)
  const {scheduledTimes, keyLesson} = data
  return (
    <div className='m-0 p-0'>
      <IconIllustratedTopic size={80} />
      <h2 className='w-100 my-3'>
        <b>
          {translate('home.onboard.approve.prescheduled.title', {
            lpw: scheduledTimes[keyLesson]?.lpw,
            name: scheduledTimes[keyLesson]?.topic?.name,
          })}
        </b>
      </h2>
      <h5>{translate('home.onboard.approve.prescheduled.subtitle')}</h5>
    </div>
  )
}
export const IntroductionTopicBody = (props) => {
  const {setPage} = props
  const data = useSelector((state) => state.modal.onboarding.data)
  const {dataPlan, scheduledTimes, keyLesson} = data
  const {translate} = useTranslation()

  const services = useSelector((state) => state.services.services)
  const groupLessons =
    dataPlan?.groupInfo?.PartsSchedule?.reduce(
      (arrayTimes, {lessonsPerWeekGroup}, indexPart) => {
        arrayTimes.push(
          Object.values(
            lessonsPerWeekGroup.reduce((obj, {topicId}) => {
              if (obj[topicId]) obj[topicId].lessons += 1
              else {
                obj = {
                  ...obj,
                  [topicId]: {
                    lessons: 1,
                    name: services[dataPlan?.serviceId].topics[topicId].name,
                  },
                }
              }
              return obj
            }, {}),
          ),
        )
        return arrayTimes
      },
      [],
    ) || []

  return (
    <div className='m-0 p-0'>
      <WeeklySchedule
        indexPart={parseInt(keyLesson.split('-')[1])}
        data={Object.entries(scheduledTimes)
          .filter(
            ([key]) =>
              parseInt(key.split('-')[1]) === parseInt(keyLesson.split('-')[1]),
          )
          .map(([__, value]) => value)}
        idTopic={keyLesson?.split('-')[0]}
        hideTitle
        hideSubTitle={false}
        dataPlan={dataPlan}
        className='p-0'
        showTotalLessons={dataPlan?.isTotalLesson && false}
        useTotalLessonText={dataPlan?.isTotalLesson && false}
        hasGroupLesson={dataPlan?.isGroup && false}
        groupPartData={groupLessons[parseInt(keyLesson?.split('-')[1])]}
      />
      <CarouselFrame type='animation'>
        <LessonsIcons
          data={
            scheduledTimes
              ? Object.entries(scheduledTimes).reduce(
                  (arrayData, [key, objTopic]) => {
                    if (key.split('-')[1] === keyLesson.split('-')[1]) {
                      return arrayData.concat(
                        objTopic.times.map((item) => {
                          return {
                            scheduled: item !== null,
                            lesson: objTopic.topic.name,
                            idTopic: objTopic.topic.id.toString(),
                          }
                        }),
                      )
                    }
                    return arrayData
                  },
                  [],
                )
              : []
          }
          idTopic={keyLesson?.split('-')[0]}
          lesson={1}
          checked
          selected={false}
        />
      </CarouselFrame>
      <StickyButton
        className='mt-4'
        label={translate('home.onboard.approve.before.continue')}
        condition
        onClickButtonFunction={() => {
          if (keyLesson?.split('-')?.includes('Orientation'))
            setPage('scheduledOrientation')
          else setPage('scheduled')
        }}
      />
    </div>
  )
}

export default {IntroductionTopicHeader, IntroductionTopicBody}
