const phaseNames = {
  'pre-application': 'preApplication',
  preApplication: 'preApplication',
  application: 'application',
  Application: 'application',
  'post-application': 'postApplication',
  postApplication: 'postApplication',
  sent: 'sent',
  scheduled: 'scheduled',
}

export default phaseNames
