import {
  IconArrowDown,
  InputSearchSelector,
} from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import {Badge} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'

export const baseStatuses = ['pendingPayment', 'paid', 'canceled', 'unPaid']

const StatusesFilter = (props) => {
  const {statuses, setStatuses} = props
  const {translate} = useTranslation()
  return (
    <InputSearchSelector
      setDataFilters={setStatuses}
      dataFilters={statuses}
      direction='down'
      data={baseStatuses.map((st) => ({
        name: translate(`profile.payments.statuses.${st}`),
        id: st,
      }))}
      hoverEnabled
      typeFilter='paymentStatuses'
      strings={{
        viewAll: translate('misc.filters.viewAll'),
        buttonCancel: translate('misc.filters.buttonCancel'),
        buttonApply: translate('misc.filters.buttonApply'),
        search: translate('misc.filters.search'),
      }}
    >
      <Badge className='mr-1 filters' pill color='option'>
        <label style={{cursor: 'pointer'}} className='m-0 p-0'>
          {translate('profile.payments.status.label')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </InputSearchSelector>
  )
}
export default StatusesFilter
