export default {
  title: 'Confirmar tus lecciones agendadas',
  educators: 'Tus educadores',
  adjust: 'Ajustar',
  confirm: 'Confirmar horario',
  notif: 'Tienes lecciones que coinciden',
  subnotif: (names) => {
    return `Para los tópicos ${names}`
  },
  subnotifOcurrences: (names) => {
    return `Para los tópicos ${names}`
  },
  confirmPayment: 'Confirme su programa de pagos',
  confirmPaymentsubtitle:
    'A continuación verá su programa semanal de acuerdo con su disponibilidad.\nSi quieres, puedes cambiarlo.',
  orientation: 'Orientación',
}
