import React, {useState, useMemo, useEffect} from 'react'
import {
  ModalLayout,
  ListItem,
  IconCountry,
  IconArrowRight,
  Button,
  ListOption,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import {TabContent, TabPane, Collapse} from 'reactstrap'
import ChangeTimezone from '../../../Onboarding/components/ChangeTimezone'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import {showAlert} from '../../../../common/Alert/util'
import useScheduledMeetingModal from './useScheduledMeetingModal'
import useUpdateTimezone from '../../../Onboarding/Model/useUpdateTimezone'
import useScheduledOrientation from '../../../CounselingOnboarding/Sections/Orientation/Modal/ScheduledOrientation/useScheduledOrientation'
import {CHANGE_TIMEZONE} from '../../../../store/user/const'
import useGetOrientationHours from '../../../CounselingOnboarding/Model/useGetOrientationHours'
import useUsers from './Model/useUsers'
import useScheduledCounselorFromStudent from './Model/useScheduledCounselorFromStudent'

const MIN_OPTIONS = 5

/**
 * Recibe id de estudiante e id de counselor y presenta horarios disponibles
 * en comun para ambos usuarios, luego agenda una meeting si useMutation es true
 * **/
const ScheduledMeetingModal = () => {
  const {parseFrames} = useScheduledOrientation()
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(null)
  const {
    isOpen,
    toggle,
    studentData,
    counselorData,
    useMutation,
    onChange,
    // si es true agenda meeting para studentId, si es false agenda
    // para el usuario actual
    scheduledToStudent = false,
  } = useScheduledMeetingModal()
  const studentId = studentData?.id || null
  const counselorId = counselorData?.id || null

  const RenderOption = ({frame}) => {
    const from = frame
    const timeLabel = `${moment(from).format('ddd DD, MMM hh:mma')} to ${moment(
      from,
    )
      .add(15, 'minutes')
      .format('hh:mma')}`
    return (
      <ListOption
        onClick={() => {
          setSelected(from)
        }}
        className={`mb-2 ${from === selected ? 'selected' : ''} `}
        spanClass='text-truncate'
        title={timeLabel}
        label={timeLabel}
      />
    )
  }

  // for update the user timezone
  const userInfo = useSelector((state) => state.user.info)

  // buscar información de usuarios
  const [getUsersData, {data: usersData}] = useUsers()
  useEffect(() => {
    const ids = []
    if (studentId) ids.push(studentId)
    if (userInfo?.id) ids.push(userInfo.id)
    if (counselorId) ids.push(counselorId)
    getUsersData({variables: {userIds: ids}})
    // eslint-disable-next-line
    }, [studentId, counselorId, userInfo?.id])
  const {counselorTimezone, studentTimezone} = useMemo(() => {
    if (!(usersData?.users?.length > 0)) return {}
    const users = usersData.users
    const counselor = users.find(({id}) => id === counselorId)
    const student = users.find(({id}) => id === studentId)
    return {
      counselorTimezone: counselor?.timezone || null,
      studentTimezone: student?.timezone || null,
    }
  }, [usersData, counselorId, studentId])

  const {timezone: userTimezone, utcOffSet} = useMemo(() => {
    if (userInfo?.timezone) {
      return {
        timezone: userInfo.timezone,
        utcOffSet: Math.ceil(moment().tz(userInfo.timezone).utcOffset() / 60),
      }
    }
    const myTimezone = moment.tz.guess()
    return {
      timezone: myTimezone,
      utcOffSet: Math.ceil(moment().tz(myTimezone).utcOffset() / 60),
    }
  }, [userInfo])
  // actualiza timezone en perfil del usuario
  const [updateTimezone, {loading: loadTimezone}] = useUpdateTimezone(
    (result) => {
      showAlert(
        {
          text: 'Success',
          status: 'success',
        },
        dispatch,
      )
      // actualiza timezone en redux
      dispatch({
        type: CHANGE_TIMEZONE,
        data: result?.updateTimezone,
      })
    },
    (err) => {
      console.error(err)
      showAlert(
        {
          text: err.toString(),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  // for update the user timezone [END]

  // obtiene disponibilidad
  const [
    getOrientationHours,
    {data: orientationHours, loading: loadOrientationHours},
  ] = useGetOrientationHours()
  useEffect(() => {
    if (counselorId && (studentId || userInfo?.id)) {
      console.log(
        `dates between ${counselorId} and ${
          scheduledToStudent ? studentId : userInfo.id
        }`,
      )
      getOrientationHours({
        variables: {
          educatorId: counselorId,
          studentId: scheduledToStudent ? studentId : userInfo.id,
          from: moment().toISOString(),
          to: moment().add(1, 'week').toISOString(),
        },
      })
    }
    // eslint-disable-next-line
    }, [userInfo, counselorId, studentId, scheduledToStudent])

  const parsedFramesArray = useMemo(
    () => {
      if (
        orientationHours?.getOrientationHours?.length > 0 &&
        ((scheduledToStudent && studentTimezone) ||
          (!scheduledToStudent && userTimezone)) &&
        counselorTimezone
      ) {
        return parseFrames(
          orientationHours.getOrientationHours || [],
          scheduledToStudent ? studentTimezone : userTimezone,
          counselorTimezone,
          30,
        )
      }
      // setSelected(null)
      return []
    },
    // eslint-disable-next-line
        [orientationHours, studentTimezone, counselorTimezone, scheduledToStudent, userTimezone]
  )

  const [isOpenCollapse, setOpenCollapse] = useState(false)

  const [
    scheduledMeeting,
    {loading: loadScheduledMutation},
  ] = useScheduledCounselorFromStudent(
    (res) => {
      showAlert(
        {
          status: 'success',
          text: 'Success!',
        },
        dispatch,
      )
      if (onChange) onChange()
    },
    (err) => {
      showAlert(
        {
          status: 'error',
          text: err.toString(),
        },
        dispatch,
      )
      console.error(err)
    },
  )

  return (
    <ModalLayout
      className='modal-colored modal-centered white-modal'
      topHead={
        <span className='h2 font-weight-bold text-title'>Schedule meeting</span>
      }
      isOpen={isOpen}
      toggle={toggle}
      // contentClassName={`h-auto`}
      disabledScrollAnimation
    >
      <div className='d-flex flex-column m-0 p-0'>
        <span className='mb-5'>
          Please select a day and time to meet for your orientation.
        </span>
        {/** CAMBIA LA ZONA HORARIO DEL USUARIO LOGEADO, NO DEL ESTUDIANTE**/}
        <ChangeTimezone
          setTime={(newTimezone) => {
            updateTimezone({
              variables: {
                userId: userInfo.id,
                newTimezone,
              },
            })
          }}
          time={userTimezone}
        >
          <ListItem
            hasHover
            className='hover-no-border'
            Left={
              <div className='d-flex flex-row align-items-center'>
                <IconCountry size={12} className='mx-2' />
                {loadTimezone ? (
                  <Skeleton height={20} width={100} count={1} className='' />
                ) : (
                  <span>
                    {userTimezone}
                    <span className='text-gray ml-1'>
                      (GMT{utcOffSet > 0 ? '+' : ''}
                      {utcOffSet})
                    </span>
                  </span>
                )}
              </div>
            }
            Right={
              <div className='hover-icon'>
                <IconArrowRight />
              </div>
            }
            showBottomLine
            showTopLine
          />
        </ChangeTimezone>
        <div className='d-flex flex-column p-0 m-0 my-4'>
          <TabContent activeTab={!loadOrientationHours ? 'show' : 'loading'}>
            <TabPane tabId='show'>
              {parsedFramesArray
                .filter((_, index) => index < MIN_OPTIONS)
                .map((frame, index) => {
                  return <RenderOption index={index} frame={frame} />
                })}
              {parsedFramesArray?.length > MIN_OPTIONS && (
                <>
                  <Collapse isOpen={isOpenCollapse}>
                    {parsedFramesArray
                      .filter((_, index) => index >= MIN_OPTIONS)
                      .map((frame, index) => {
                        return <RenderOption index={index} frame={frame} />
                      })}
                  </Collapse>
                  <div className='d-flex flex-row justify-content-center'>
                    <span
                      className='btn-link'
                      onClick={() => {
                        setOpenCollapse(!isOpenCollapse)
                      }}
                    >
                      {isOpenCollapse ? 'Show less' : 'Show more'}
                    </span>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane tabId='loading'>
              <Skeleton
                className='d-flex flex-column w-100 mb-2'
                count={5}
                height={40}
              />
            </TabPane>
          </TabContent>
          <Button
            disabled={!selected}
            className='w-100 my-2'
            label='Scheduled meeting'
            color='primary'
            size='large'
            check
            activity={loadScheduledMutation}
            onClick={() => {
              if (useMutation) {
                const startDate = moment(selected).toISOString()
                const endDate = moment(selected)
                  .add(30, 'minutes')
                  .toISOString()
                scheduledMeeting({
                  variables: {
                    isMeeting: true,
                    councelorInput: {
                      startDate: new Date(startDate),
                      endDate: new Date(endDate),
                      councelorId: counselorId,
                    },
                  },
                })
              } else {
                if (onChange) onChange()
              }
            }}
          />
        </div>
      </div>
    </ModalLayout>
  )
}

export default ScheduledMeetingModal
