import React from 'react'

import ArrayAlert from '../../common/Alert/index'
import CompleteProfile from '../../common/CompleteProfile'
import ChatNotification from '../../common/ChatNotification'
import RedirectToPaymentMethods from '../../common/RedirectToLocalUrl/RedirectToPaymentMethods'
import SocketGateway from '../../common/SocketGateway'
import OpenUniversityModalFromUrl from '../../common/OpenUniversityModalFromUrl'
import OpenInvoiceModalFromUrl from '../../common/OpenInvoiceModalFromUrl'
import RedirectCounselingPlanModal from '../MyPlans/viewCounselingPlanModal/RedirectFromUrl'

import NewLesson from '../Calendar/Lessons/Modal/NewLesson'
import useModalState from '../../hooks/useModalState'

import MyCollegeModal from '../Workspace/Student/MyCollegeModal'
import useMyCollegeModal from '../Workspace/Student/MyCollegeModal/useMyCollegeModal'

import ShowTask from '../Workspace/Student/ViewTask'
import useShowTask from '../Workspace/Student/ViewTask/useShowTask'

import ViewPlanModal from '../MyPlans/viewCounselingPlanModal'
import useViewPlanModal from '../MyPlans/viewCounselingPlanModal/useViewPlanModal'

import UniversityDirectory from '../University/UniversityDirectory'
import useUniversityDirectory from '../University/UniversityDirectory/useUniversityDirectory'

import ShowUniversity from '../University/ShowUniversity'
import useShowUniversity from '../University/ShowUniversity/useShowUniversity'

import DocsAndExamsModal from '../Workspace/Student/DocsAndExams'
import useDocsAndExams from '../Workspace/Student/DocsAndExams/useDocsAndExams'

import RegisterScoresModal from '../Workspace/Student/RegisterScoresModal'
import useRegisterScoresModal from '../Workspace/Student/RegisterScoresModal/useRegisterScoresModal'

import InvoiceModal from '../MyPlans/viewCounselingPlanModal/InvoiceModal'
import useInvoiceModal from '../MyPlans/viewCounselingPlanModal/InvoiceModal/useInvoiceModal'

import RedirectToTaskModal from '../Workspace/Student/RedirectToTaskModal'

import ScheduledMeetingModal from '../Workspace/Student/ScheduledMeeting'
import useScheduledMeetingModal from '../Workspace/Student/ScheduledMeeting/useScheduledMeetingModal'

import RedirectToStudentModal from '../Student/modal/InfoStudent/RedirectFromUrl'

import PopupBackupLink from '../CalendarLessons/modal/InfoLesson/Components/PopUpBackUpLink'
import usePopupBuLink from '../CalendarLessons/modal/InfoLesson/Components/PopUpBackUpLink/usePopupBackupLink'

import WspAlert from '../CalendarLessons/modal/WspAlert'

import useSubmitScoreModal from '../Workspace/Student/DocsAndExams/SubmitScore/useSubmitScoreModal'
import SubmitScoreModal from '../Workspace/Student/DocsAndExams/SubmitScore'

const RenderModals = () => {
  // Newlesson
  const {isOpen} = useModalState('program.newManualLesson')
  const {isOpen: isOpenMyCollege} = useMyCollegeModal()
  const {isOpen: isOpenShowTask} = useShowTask()
  const {isOpen: isOpenViewPlan} = useViewPlanModal()
  const {isOpen: isOpenUniversityDirectory} = useUniversityDirectory()
  const {isOpen: isOpenShowUniversity} = useShowUniversity()
  const {isOpen: isOpenDocsAndExams} = useDocsAndExams()
  const {isOpen: isOpenRegisterScores} = useRegisterScoresModal()
  const {isOpen: isOpenInvoice} = useInvoiceModal()
  const {isOpen: isOpenScheduledMeeting} = useScheduledMeetingModal()
  const {isOpen: isOpenPopupBackupLink} = usePopupBuLink()
  const {isOpen: isOpenSubmitScore} = useSubmitScoreModal()
  const isOnboardingPage = window.location.pathname.includes('/onboarding')
  return (
    <>
      <ArrayAlert />
      {!isOnboardingPage && <CompleteProfile loading={false} />}
      <ChatNotification />
      <RedirectToPaymentMethods />
      <SocketGateway />
      <OpenUniversityModalFromUrl />
      <OpenInvoiceModalFromUrl />
      <RedirectCounselingPlanModal />
      <WspAlert />

      {isOpen && <NewLesson />}

      {isOpenMyCollege && <MyCollegeModal />}
      {(isOpenShowTask || true) && <ShowTask />}
      {isOpenViewPlan && <ViewPlanModal />}
      {isOpenUniversityDirectory && <UniversityDirectory />}
      {isOpenShowUniversity && <ShowUniversity />}
      {isOpenDocsAndExams && <DocsAndExamsModal />}
      {isOpenRegisterScores && <RegisterScoresModal />}
      {isOpenInvoice && <InvoiceModal />}
      {isOpenPopupBackupLink && <PopupBackupLink />}

      <RedirectToTaskModal />

      {isOpenScheduledMeeting && <ScheduledMeetingModal />}

      <RedirectToStudentModal />

      {isOpenSubmitScore && <SubmitScoreModal />}
    </>
  )
}

export default RenderModals
