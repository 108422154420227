// String con modelo de objeto que será utilizado para
// obtener UNA meeting.
const meetingQueryResult = `
id
kind
createdAt
updatedAt
googleCalendarEventId
startDate
endDate
busy
userIdsInvolved
studentId
counselorId
`

export default meetingQueryResult
