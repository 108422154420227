import React, {useState, useMemo} from 'react'
import {
  ModalLayout,
  useWindowDimensions,
  TimezoneSelector,
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment-timezone'
const ChangeTimezone = (props) => {
  const {setTime, time, geoMode = false} = props
  const detectTimezone = moment.tz.guess()
  const [isOpen, setOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()

  // const [auxTimezone, setAuxTimezone] = useState(null)
  const [scrollTo, setScrollTo] = useState(null)
  const selectedTimezoneOption = useMemo(() => {
    return geoMode ? detectTimezone : scrollTo || time
    // eslint-disable-next-line
  }, [scrollTo, time])
  return (
    <>
      <div
        className='m-0 p-0 w-100'
        onClick={() => {
          if (!geoMode && time) {
            // scrollea hasta el item seleccionado
            setScrollTo(time)
          }
          toggle()
        }}
      >
        {props.children}
      </div>
      <ModalLayout
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored h-auto`}
        isOpen={isOpen}
        toggle={toggle}
        modalHeaderClassName='pb-0'
        topHead={
          <div className='d-flex flex-column justify-content-start mr-4'>
            <span className='text-title font-weight-bold h2'>
              {translate('home.onboard.approve.selecttime')}
            </span>
          </div>
        }
        underHead={
          geoMode ? (
            <div className='d-flex flex-column m-0 p-0'>
              <label className='mt-2 w-100 h4 text-error'>
                {translate('home.onboard.approve.timezone.alertLocationDetect')}
                {/* We have detected a time zone that does not match your location, you can select a new one or skip */}
                {/* <span onClick={toggle} className={`btn-link text-error`}>do nothing</span> */}
              </label>
              <label className='mt-2 w-100 text-info mb-0'>
                <span className='font-weight-bold mb-0'>
                  {translate('home.onboard.approve.timezone.currentLabel')}:{' '}
                </span>{' '}
                {time}
              </label>
              <label className='mt-2 w-100 d-flex flex-row justify-content-between align-items-center text-info mb-0'>
                <span>
                  <span className='font-weight-bold mb-0'>
                    {translate(
                      'home.onboard.approve.timezone.recommendedLabel',
                    )}
                    :{' '}
                  </span>
                  {detectTimezone}
                </span>
                <span
                  onClick={async () => {
                    await setCollapseOpen(true)
                    await setScrollTo(null)
                    await setScrollTo(detectTimezone)
                  }}
                  className='h45 btn-link text-primary'
                >
                  {translate('home.onboard.approve.timezone.changeLabel')}
                </span>
              </label>
            </div>
          ) : null
        }
      >
        <Collapse isOpen>
          <TimezoneSelector
            selected={selectedTimezoneOption}
            scrollTo={scrollTo}
            mutation={(time) => {
              if (setTime) setTime(time)
              toggle()
            }}
            onSkip={() => {
              // solo es clickeable cuando showCurrent es true
              toggle()
            }}
            geoMode={geoMode}
            isOpenCollapse={collapseOpen || !geoMode}
            recommendedTimezone={detectTimezone}
            submitLabelNormal={translate(
              'home.onboard.approve.timezone.submitLabel',
            )} // {'Submit'}
            submitLabelRecommended={translate(
              'home.onboard.approve.timezone.setRecommendedLabel',
            )} // {`Set recommended`}
            currentLabel={translate(
              'home.onboard.approve.timezone.currentLabel',
            )} // {'Current'}
            skipLabel={translate('home.onboard.approve.timezone.skipLabel')} // {'Skip'}
          />
        </Collapse>
      </ModalLayout>
    </>
  )
}

ChangeTimezone.propTypes = {}

export default ChangeTimezone
