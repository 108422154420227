import React from 'react'
import {IconPayments} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const PaymentSelectionHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <>
      <div className='w-100 p-0 mt-2'>
        <IconPayments size={80} />
        <div className='mt-2 h2 text-body font-weight-bold'>
          <span>{translate('payments.notmethoddefined.title')}</span>
        </div>
        <span>{translate('payments.notmethoddefined.text')}</span>
      </div>
    </>
  )
}

export default PaymentSelectionHeader
