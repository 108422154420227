// eslint-disable-next-line
import  React, { useMemo } from 'react'
import {useSelector} from 'react-redux'

const UsersListDataLesson = (dataLesson) => {
  const {roles, id: userID} = useSelector((state) => state.user.info)
  const isEducator = roles?.includes('EDUCATOR')
  const usersListData = useMemo(() => {
    if (dataLesson) {
      if (dataLesson?.__typename === 'GroupLesson') {
        if (
          dataLesson.hasOwnProperty('educator') &&
          dataLesson.hasOwnProperty('studentGroup')
        ) {
          if (isEducator) {
            return [
              ...dataLesson?.studentGroup.filter(
                (user) =>
                  user[
                    user.__typename === 'LessonEducator'
                      ? 'educatorUserId'
                      : 'studentUserId'
                  ] !== userID,
              ),
            ]
          } else {
            return [
              dataLesson?.substitute || dataLesson.educator,
              ...dataLesson?.studentGroup.filter(
                (user) =>
                  user[
                    user.__typename === 'LessonEducator'
                      ? 'educatorUserId'
                      : 'studentUserId'
                  ] !== userID,
              ),
            ]
          }
        }
        return []
      }
      if (
        dataLesson?.__typename === 'IndividualLesson' ||
        dataLesson?.__typename === 'PlanLesson'
      ) {
        if (
          dataLesson.hasOwnProperty('educator') &&
          (dataLesson.hasOwnProperty('student') ||
            dataLesson.hasOwnProperty('studentPlan'))
        ) {
          return [
            dataLesson?.substitute || dataLesson?.educator,
            dataLesson?.student || dataLesson?.studentPlan,
          ].filter(
            (user) =>
              user[
                user.__typename === 'LessonEducator'
                  ? 'educatorUserId'
                  : 'studentUserId'
              ] !== userID,
          )
        }
        return []
      }
    }
    return []
    // eslint-disable-next-line
    }, [dataLesson])

  return usersListData
}

export default UsersListDataLesson
