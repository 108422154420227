export default {
  beginwork: 'We are excited to begin working with you!',
  paymentTitle: 'Payment schedule',
  paymentTotal: 'Total price',
  paymentPlanap: 'When plan is approved',
  paymentEndof: 'End of',
  paymentTotalCost: 'Plan total cost',
  weeklyTitle: 'Weekly schedule',
  weeklyItemLesson: ({lpw, name}) => {
    return `${lpw} individual ${name} lessons per week`
  },
  weeklyItemLessonFixed: ({lpw, name}) => {
    return `${lpw} ${name} lessons`
  },
  weeklyItemLessonGroup: ({lpw, name}) => {
    return `${lpw} group ${name} lessons per week`
  },
  weeklyItemOrientation: ({lpw, name}) => {
    return `${lpw} ${name} session before start`
  },
  breakdownTitle: 'Plan breakdown',
  breakdownRegistration: 'Registration free',
  breakdownLessons: 'lessons',
  breakdownPerlesson: 'per lesson',
  approvePlan: 'Approve plan',
  selecttime: 'Select Timezone',
  selectedu: ({topic}) => `Select your ${topic} educator`,
}
