import React from 'react'
import {IconEducator} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'

export const parseLesson = (
  lessonsArray,
  upcomming,
  dispatch,
  translate,
  zone,
  orientationId,
  meId,
  translateFunction = (str) => str,
) => {
  // obtiene el indice de la proxima lección
  // const indexUpcomming =
  //   week === 0
  //     ? lessonsArray.reduce(
  //       (indexUpcommingAccu, lesson, index) => {
  //         if ((lesson.__typename === 'Counselor' || lesson.status === 'scheduled') && moment() < moment(lesson.endDate)) {
  //           if (indexUpcommingAccu === -1 || (moment(lesson.startDate) < moment(lessonsArray[indexUpcommingAccu].startDate))) return index
  //         }
  //         return indexUpcommingAccu
  //       }, -1) : -1

  return lessonsArray.map((lesson) => {
    const start = moment(lesson.startDate).tz(zone)
    let duration = moment
      .duration(moment(lesson.endDate).tz(zone).diff(start))
      .asMinutes()
    duration = duration < 20 ? 20 : duration // si la duration es menor que 20 la altura del bloque es demasiado pequeña
    const day = start.clone().day()
    // estados en calendario: attended - missed - scheduled - upcoming - incomplete
    /** estados en backend :
       *  draft
          finished
          scheduled**/
    let status =
      lesson.__typename === 'Counselor'
        ? 'scheduled' // orientación
        : lesson.status === 'scheduled'
        ? moment(lesson.endDate) < moment() // lección esta scheduled (no finished) y ya terminó
          ? 'incomplete'
          : 'scheduled'
        : lesson.status === 'finished'
        ? 'attended'
        : 'upcoming'
    // setea status a lección upcoming
    if (
      (upcomming && lesson?.id === upcomming) ||
      (!upcomming &&
        moment(lesson.startDate) <= moment() &&
        moment() < moment(lesson.endDate) &&
        status === 'scheduled')
    )
      status = 'upcoming' // upcoming se define desde query lo usa
    // else if(index === indexUpcomming && status === 'scheduled') status = 'upcoming' //sino verifica el indice
    let hasMissed = false
    let student =
      lesson?.student || lesson?.studentPlan || lesson?.studentGroup || []
    let missed = false
    if (status === 'attended') {
      if (Array.isArray(student)) {
        missed = student.reduce((missedBool, studentAux) => {
          if (studentAux.attendanceStatus === 'attended') return false
          else if (studentAux.attendanceStatus === 'missed') hasMissed = true
          return missedBool
        }, true)
      } else {
        missed = student.attendanceStatus === 'missed'
      }
      if (lesson?.educator?.attendanceStatus === 'missed' || missed)
        status = 'missed'
    }
    const noshow = lesson?.educator?.attendanceStatus === 'missed' // falto el educador
    if (!Array.isArray(student)) student = [student]

    // if(lesson?.__typename === 'EducatorUnavailability' && moment(lesson.startDate).day() === 0){
    //  console.log("lesson", lesson)
    //  console.log("day:", day === 0 ? 6 : day - 1)
    //  console.log("hour:", start.clone().hour())
    //  console.log("minutes:", start.clone().minutes())
    // }

    // el usuario era el educador original de la lección pero fue sustituido
    const substituted =
      lesson?.substitute && lesson?.educator?.educatorUserId === meId

    return {
      idLesson: lesson.id,
      day: day === 0 ? 6 : day - 1,
      hour: start.clone().hour(),
      minutes: start.clone().minutes(),
      durationMin: duration,
      title: lesson.title || (lesson.counselorId ? 'Counselor' : 'No defined'),
      subtitle:
        student.length > 0
          ? noshow && ['attended', 'missed'].includes(status)
            ? translate('modal.infolesson.noshow')
            : student.length === 1
            ? `${student[0].firstName} ${student[0].lastName}`
            : `Group`
          : lesson.counselorId
          ? 'Counselor'
          : 'No defined',
      type:
        duration <= 20
          ? 'skinny'
          : duration <= 30
          ? 'medium'
          : duration <= 45
          ? 'normal'
          : 'big',
      status: status,
      semiAttended: status === 'attended' && hasMissed,
      onClickLesson: () => {
        if (
          !lesson.counselorId &&
          lesson?.__typename !== 'EducatorUnavailability'
        ) {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: `calendar.${
              lesson?.service?.id === orientationId
                ? 'orientationLessonModal'
                : 'infoLessonModal'
            }`,
            data: {
              isOpen: true,
              dataLesson: lesson,
            },
          })
        }
      },
      canChecked: false,
      isGroup: lesson.__typename === 'GroupLesson',
      student,
      topic:
        lesson?.individualTopic ||
        lesson?.planTopic ||
        lesson?.groupTopic ||
        {},
      disabled: lesson?.__typename === 'EducatorUnavailability',
      idEducator:
        lesson?.substituted?.educatorUserId || lesson?.educator?.educatorUserId,
      icon: substituted ? <IconEducator size={17} /> : null, // sobreescribe icono derecho
      tooltipText: substituted
        ? `${translateFunction('lessons.calendar.substitutedBy')} ${
            lesson?.substitute?.firstName
          } ${lesson?.substitute?.lastName}`
        : null, // necesita que icon esté definido
    }
  })
}

export default {
  parseLesson,
}
