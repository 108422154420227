import {useLazyQuery, gql} from '@apollo/client'

export const useGetOrientationHours = () => {
  return useLazyQuery(
    gql`
      query($educatorId: ID!, $from: DateTime!, $to: DateTime!) {
        getOrientationHours(from: $from, to: $to, educatorId: $educatorId)
      }
    `,
  )
}
