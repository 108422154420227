import React from 'react'
import {ListItem} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import {useSelector} from 'react-redux'

const Profile = (props) => {
  const {userData, style} = props
  const loggedUser = useSelector((state) => state.user.info)
  const {translate} = useTranslation()
  const genders = {
    male: {
      name: 'Male',
    },
    female: {
      name: 'female',
    },
  }

  return (
    <div
      className='m-0 p-0 d-flex flex-column justify-content-start'
      style={style}
    >
      <ListItem
        showTopLine={false}
        showBottomLine
        className='py-3'
        Left={
          <span className='font-weigth-bold'>
            {translate('student.modal.profile.gender')}
          </span>
        }
        Right={
          <span className='h45 text-gray'>
            {genders[userData?.gender]?.name}
          </span>
        }
      />
      <ListItem
        showTopLine={false}
        showBottomLine
        className='py-3'
        Left={
          <span className='font-weigth-bold'>
            {translate('student.modal.profile.birthdate')}
          </span>
        }
        Right={
          <span className='h45 text-gray'>
            {userData?.birthdate
              ? moment(userData.birthdate).format('DD-MM-YYYY')
              : null}
          </span>
        }
      />
      <ListItem
        showTopLine={false}
        showBottomLine
        className='py-3'
        Left={
          <span className='font-weigth-bold'>
            {translate('student.modal.profile.spoken')}
          </span>
        }
        Right={
          <span className='h45 text-gray'>{userData?.spokenLanguages}</span>
        }
      />
      {loggedUser?.id === userData?.id && (
        <div>
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-3'
            Left={
              <span className='font-weigth-bold'>
                {translate('student.modal.profile.address')}
              </span>
            }
            Right={<span className='h45 text-gray'>{userData?.address}</span>}
          />
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-3'
            Left={
              <span className='font-weigth-bold'>
                {translate('student.modal.profile.city')}
              </span>
            }
            Right={<span className='h45 text-gray'>{userData?.city}</span>}
          />
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-3'
            Left={
              <span className='font-weigth-bold'>
                {translate('student.modal.profile.state')}
              </span>
            }
            Right={<span className='h45 text-gray'>{userData?.state}</span>}
          />
          <ListItem
            showTopLine={false}
            showBottomLine
            className='py-3'
            Left={
              <span className='font-weigth-bold'>
                {translate('student.modal.profile.zip')}
              </span>
            }
            Right={<span className='h45 text-gray'>{userData?.zipCode}</span>}
          />
        </div>
      )}

      <ListItem
        showTopLine={false}
        showBottomLine
        className='py-3'
        Left={
          <span className='font-weigth-bold'>
            {translate('student.modal.profile.country')}
          </span>
        }
        Right={<span className='h45 text-gray'>{userData?.country?.name}</span>}
      />
    </div>
  )
}

export default Profile
