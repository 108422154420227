import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import removeAccountFromUser from '../../../../../../Model/Users/Accounts/DeleteAccount/removeAccountFromUser'
import {useSelector} from 'react-redux'

const AccountRemove = (props) => {
  const {isOpen, toggle, refetch = () => {}} = props
  const {translate} = useTranslation()
  const account = useSelector(
    (state) => state.modal?.profile?.account?.info?.account,
  )
  const {
    submit: removeAccountMutation,
    loading: removeAccountLoading,
    // eslint-disable-next-line
        //     data: removeAccountData,
    // error: removeAccountErrors,
  } = removeAccountFromUser(
    () => {
      toggle()
      refetch()
    },
    (err) => {
      //   setErrorMessage(err.toString())
      //   setErrorMessageModalOpen(true)
      console.error('Error on delete account : ', err)
    },
  )

  // const confirmFunction = async () => {
  //     try {
  //       await removeAccountMutation({
  //         platformName: account?.platform,
  //       })
  //     } catch (error) {
  //         console.error(error.toString())
  //     //   setErrorMessage(error.toString())
  //     //   setErrorMessageModalOpen(true)
  //     }
  // }

  return (
    <ConfirmModal
      className='m-4'
      isOpen={isOpen}
      toggle={toggle}
      loading={removeAccountLoading}
      title={translate('profile.actions.accounts.deleteAccountTitle')}
      body={translate('profile.actions.accounts.deleteAccountText')}
      classNameActionDiv='d-flex flex-column justify-content-end w-80'
      confirmAction={() => {
        removeAccountMutation({
          platformName: account?.platform,
        })
      }}
      cancelAction={toggle}
      confirmText={translate('profile.actions.accounts.confirmButton')}
      cancelText='Cancel'
    />
  )
}

export default AccountRemove
