import React, {useContext, useState} from 'react'
import {
  Button,
  CheckboxOption,
  IconLearn,
  Input,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import FirebaseContext from '../../../../contexts/FirebaseContext'
const PasswordSignin = (props) => {
  const {email, onForgot} = props
  const firebase = useContext(FirebaseContext)
  const {translate} = useTranslation()
  const [password, setPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const submit = () => {
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        (result) => {
          if (!remember) {
            console.log('should Remind')
          }
          setLoading(false)
          setError(null)
        },
        (error) => {
          switch (error.code) {
            case 'auth/wrong-password':
              setError('login.signin.email.errors.wrongPassword')
              break
            case 'auth/user-disabled':
            case 'auth/user-not-found':
              setError('login.signin.email.errors.userNotFound')
              break
            case 'auth/too-many-requests':
              setError('login.signin.email.errors.tooManyAttempts')
              break
            case 'auth/invalid-email':
            default:
              console.log(error)
              setError('login.signin.email.errors.unknown')
              break
          }
          setLoading(false)
        },
      )
  }
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
      >
        <span className='text-secondary font-weight-light'>
          {translate('login.signin.email.passLabel')}
        </span>
        <Input
          className='my-2'
          name='email'
          value={password}
          type='password'
          placeholder={translate('login.signin.email.passPlaceholder')}
          onChange={({target: {value}}) => setPassword(value)}
          addOnComponent={<IconLearn />}
        />
        <div className='d-flex justify-content-between align-items-center my-4'>
          <div className='d-flex flex-grow-1 text-truncate'>
            <CheckboxOption
              defaultState
              onChange={(bool) => setRemember(bool)}
              className='ml-2 text-gray'
            />
            <span className='text-body'>
              {translate('login.signin.email.remember')}
            </span>
          </div>
          <span
            className='btn-link font-weight-light text-gray'
            onClick={onForgot}
          >
            {translate('login.signin.email.forgot')}
          </span>
        </div>
        {error ? (
          <div className='my-2 text-center text-error h3 w-100'>
            {translate(error)}
          </div>
        ) : null}
        <Button
          className='w-100 mt-2'
          color='primary'
          check
          activity={loading}
          type='submit'
          label={translate('login.signin.email.submit')}
        />
      </form>
    </div>
  )
}

export default PasswordSignin
