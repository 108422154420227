import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import useLazyGetTask from '../../../../Model/Users/Student/useLazyGetTask'
import useViewTaskModal from '../ViewTask/useShowTask'

const RedirectToTaskModal = () => {
  const {open: openViewTaskModal} = useViewTaskModal()
  const [getTask, {refetch}] = useLazyGetTask(
    (res) => {
      // abre el modal
      if (res?.getTask) {
        const {task, ...other} = res?.getTask
        openViewTaskModal(
          () => {
            refetch()
          },
          {
            ...task,
            ...other,
            isEdit: true,
            taskName: task?.taskName || task?.name,
          },
        )
      }
    },
    (err) => {
      console.error(err)
    },
  )

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const taskId = searchParams.get('taskId')

  useEffect(() => {
    if (taskId) getTask({variables: {taskId}})
    // eslint-disable-next-line
        }, [taskId]
    )
  return <></>
}

export default RedirectToTaskModal
