import React from 'react'
import Skeleton from 'react-loading-skeleton'
import RenderLabelGroupLessons from './RenderLabelGroupLessons'

const GroupLessonSchedule = (props) => {
  const {groupPartData, showCollapse = false} = props

  return (
    <>
      {groupPartData ? (
        groupPartData.map(({lessons, name, days}, key) => {
          return (
            <RenderLabelGroupLessons
              showCollapse={showCollapse}
              key={key}
              lessons={lessons}
              name={name}
              days={days}
            />
          )
        })
      ) : (
        <Skeleton count={4} />
      )}
    </>
  )
}

GroupLessonSchedule.propTypes = {}
GroupLessonSchedule.defaultProps = {}

export default GroupLessonSchedule
