import {useMutation, gql} from '@apollo/client'

const useAddExamScoresToPlan = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($examScoreId: ID!, $planId: ID!) {
        addExamScoresToPlan(examScoreId: $examScoreId, planId: $planId) {
          id
          kind
          name
          serviceId
          testDate
          status
          timesSkipped
          dateSkipped
          activityTest {
            scoreReleaseDate
          }
          selectedExam {
            examId {
              _id
            }
            scores {
              name
              results {
                name
                scale
                value
                values {
                  name
                  scale
                  value
                }
              }
              examDate
              overall
            }
          }
        }
      }
    `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useAddExamScoresToPlan
