import {combineReducers} from 'redux'
import translation from './translation/reducer'
import user from './user/reducer'
import modal from './modal/reducer'
import services from './services/reducer'
import countries from './countries/reducer'
import alert from './alert/reducer'
import platform from './platform/reducer'
import socket from './socket/reducer'
import examScore from './examScores/reducer'
import events from './events/reducer'

export default combineReducers({
  translation,
  user,
  modal,
  services,
  countries,
  alert,
  platform,
  socket,
  examScore,
  events,
})
