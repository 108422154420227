import {useMutation, gql} from '@apollo/client'

const useSetBankDebitPaymentMethod = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation(
        $accountId: String!
        $plaidPublicToken: String!
        $userId: String
        $institutionId: String
      ) {
        setBankDebitPaymentMethod(
          accountId: $accountId
          plaidPublicToken: $plaidPublicToken
          userId: $userId
          institutionId: $institutionId
        ) {
          kind
          validationStatus
          accountInfo {
            bankName
            last4
          }
        }
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default useSetBankDebitPaymentMethod
