import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const useMyCollegeModal = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.counseling.collegeModal)
  const {isOpen, onChange, college} = reduxData
  const setUpdateUniversity = (college) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        college,
      },
    })
  }

  const setOpen = (open, onChange = () => {}, college = {}, plan = {}) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        college,
        plan,
      },
    })
  }
  return {
    ...reduxData,
    isOpen,
    onChange,
    open: (onChange = () => {}, college = {}, plan = {}) => {
      setOpen(true, onChange, college, plan)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    college,
    setUpdateUniversity,
  }
}
export default useMyCollegeModal
