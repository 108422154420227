import React, {useMemo, useState} from 'react'
import {
  Action,
  IconClose,
  IconNotificationOff,
  ListItem,
  Modal,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {ModalHeader} from 'reactstrap'
import styles from './styles.module.css'
import {useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import ToggleReminder from './ToggleReminder'
import omit from 'lodash/omit'
import ToggleTimedReminder from './ChangeReminder'
const ChangeNotifications = (props) => {
  const {studentInfo} = props
  const notificationsSettings = useMemo(() => {
    return studentInfo && studentInfo.notifications
      ? omit(studentInfo.notifications, [
          '__typename',
          'lessonReminder.__typename',
        ])
      : {
          missedLesson: true,
          lessonsNotesReady: true,
          lessonReminder: {isAvailable: true, duration: 30},
        }
  }, [studentInfo])
  const {translate} = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const editReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'profile.notificationToggle',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings,
      },
    })
  }
  const editTimedReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'profile.notificationChange',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings,
      },
    })
  }
  return (
    <>
      <ListItem
        showBottomLine
        hasHover
        hasColorHover
        onClick={toggle}
        Left={
          <span className='d-flex align-items-center'>
            <IconNotificationOff size={16} className='mr-3' />
            <span className='text-black'>
              {translate('profile.actions.notifications')}
            </span>
          </span>
        }
      />
      <Modal
        buttonLabel={null}
        isOpen={isOpen}
        toggle={toggle}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
          >
            <span className='h2 font-weight-bold'>
              {translate('profile.actions.changeNotifications.modalTitle')}
            </span>
          </ModalHeader>
        }
      >
        <div className='container-fluid flex-column my-2'>
          <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
            {translate('profile.actions.changeNotifications.lessonReminder')}
          </p>
          <p
            className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
          >
            {translate(
              'profile.actions.changeNotifications.lessonReminderDesc',
            )}
          </p>
          <Action
            action='reminder'
            status={
              notificationsSettings
                ? notificationsSettings.lessonReminder.isAvailable
                  ? 'reminder-active'
                  : 'reminder'
                : 'reminder-active'
            }
            className='my-1'
            onClick={() =>
              editTimedReminder(
                'LessonReminder',
                'lessonReminder',
                notificationsSettings.lessonReminder.isAvailable,
              )
            }
            labelLeft={
              notificationsSettings
                ? notificationsSettings.lessonReminder.isAvailable
                  ? translate('profile.actions.changeNotifications.reminderSet')
                  : translate(
                      'profile.actions.changeNotifications.reminderDisabled',
                    )
                : translate('profile.actions.changeNotifications.reminderSet')
            }
            labelRight={
              notificationsSettings &&
              notificationsSettings.lessonReminder.isAvailable
                ? translate(
                    'profile.actions.changeNotifications.reminderMinutes',
                    notificationsSettings.lessonReminder.duration,
                  )
                : 'none'
            }
          />
        </div>
        <div className='container-fluid flex-column my-2'>
          <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
            {translate('profile.actions.changeNotifications.lessonNotesReady')}
          </p>
          <p
            className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
          >
            {translate(
              'profile.actions.changeNotifications.lessonNotesReadyDesc',
            )}
          </p>
          <Action
            action='reminder'
            status={
              notificationsSettings
                ? notificationsSettings.lessonsNotesReady
                  ? 'reminder-active'
                  : 'reminder'
                : 'reminder-active'
            }
            onClick={() =>
              editReminder(
                'LessonNotes',
                'lessonsNotesReady',
                notificationsSettings.lessonsNotesReady,
              )
            }
            className='my-1'
            labelLeft={
              notificationsSettings
                ? notificationsSettings.lessonsNotesReady
                  ? translate('profile.actions.changeNotifications.reminderSet')
                  : translate(
                      'profile.actions.changeNotifications.reminderDisabled',
                    )
                : translate('profile.actions.changeNotifications.reminderSet')
            }
            labelRight={
              notificationsSettings && notificationsSettings.lessonsNotesReady
                ? translate(
                    'profile.actions.changeNotifications.reminderLessonNotesSet',
                  )
                : ''
            }
          />
        </div>
        <div className='container-fluid flex-column my-2'>
          <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
            {translate('profile.actions.changeNotifications.missedLessons')}
          </p>
          <p
            className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
          >
            {translate('profile.actions.changeNotifications.missedLessonsDesc')}
          </p>
          <Action
            action='reminder'
            status={
              notificationsSettings
                ? notificationsSettings.missedLesson
                  ? 'reminder-active'
                  : 'reminder'
                : 'reminder-active'
            }
            onClick={() =>
              editReminder(
                'MissedLessons',
                'missedLesson',
                notificationsSettings.missedLesson,
              )
            }
            className='my-1'
            labelLeft={
              notificationsSettings
                ? notificationsSettings.missedLesson
                  ? translate('profile.actions.changeNotifications.reminderSet')
                  : translate(
                      'profile.actions.changeNotifications.reminderDisabled',
                    )
                : translate('profile.actions.changeNotifications.reminderSet')
            }
          />
        </div>
      </Modal>
      <ToggleReminder />
      <ToggleTimedReminder />
    </>
  )
}

export default ChangeNotifications
