import {useMutation, gql} from '@apollo/client'

const useUpdateUserPhone = (onCompleted = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          phone
        }
      }
    `,
    {onCompleted, onError},
  )
}

export default useUpdateUserPhone
