import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const useUniveristyDirectory = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.univeristyDirectory,
  )
  const {isOpen, onChange} = reduxData
  const setOpen = (open, onChange = () => {}, plan = {}) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.univeristyDirectory',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        plan,
      },
    })
  }
  return {
    ...reduxData,
    isOpen,
    onChange,
    open: (onChange = () => {}, plan = {}) => {
      setOpen(true, onChange, plan)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
  }
}
export default useUniveristyDirectory
