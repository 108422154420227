import React from 'react'
import showFrontappChat from '../../../../../helpers/showFrontappChat'
import './index.css'

const OptionsButton = () => {
  const callHelp = () => {
    showFrontappChat()
  }
  return (
    <>
      <div onClick={callHelp}></div>
    </>
  )
}

export default OptionsButton
