import {useMutation, gql} from '@apollo/client'

const useUpdateOnboardingStatus = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
      mutation($newStatus: OnboardingStatuses!, $planId: ID!) {
        updateOnboardingStatus(newStatus: $newStatus, planId: $planId)
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default useUpdateOnboardingStatus
