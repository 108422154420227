import React, {useState, useEffect} from 'react'
import {IconTask, Button} from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import useOperationTaskMutation from '../../../../../Task/Model/useOperationTask'
import useAllUserTasks from '../../../../../Task/Model/useAllUserTasks'
import useUploadFileToTask from '../../../../../Task/Model/useUploadFileToTask'
import {UseGetUserTask} from '../../../../../../Model/Users/Student/getUserTask/useGetUserTask'
import {UPDATE_TASK} from '../../../../../../store/user/const'
import useShowTask from '../../useShowTask'
import {showAlert} from '../../../../../../common/Alert/util'
import useTranslation from '../../../../../../i18n/useTranslation'
import phaseNames from '../../../../Utils/phaseNames'

const SendToReviewButton = (props) => {
  const planId = useSelector(
    (state) => state.user.info.studentInfo.homeNotification.councelingPlanId,
  )
  const refetchUserData = useSelector((state) => state.user.refetch)
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const {task, completionLoading, filesUploadedByUser} = props
  // eslint-disable-next-line
  const [taskData, setTaskState] = useState(task)
  const {setTaskData} = useShowTask()
  const [
    operationMutation,
    {loading: loadMutation, data: dataMutation},
  ] = useOperationTaskMutation(
    () => {
      //   callBack()
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )
  // Query para traer una sola task
  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask }] = UseGetUserTask()
  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })
  // Mutation para subir archivos a la task
  const {
    submit: submitUpload,
    loading: uploadLoading,
    // data: uploadData,
  } = useUploadFileToTask(
    () => {
      sendToReviewFunction()
    },
    () => {},
  )

  const sendToReviewFunction = () => {
    operationMutation({
      variables: {
        universities: taskData?.universities,
        operation: 'review',
        userId: taskData?.userId,
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId,
      },
    })
  }

  // Función que es llamada para subir archivos a una task
  const uploadFileFunction = () => {
    if (filesUploadedByUser?.length === 0) return
    try {
      submitUpload({
        files: filesUploadedByUser,
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId,
      })
    } catch (error) {
      showAlert(
        {
          text: translate('modal.newlesson.errorAlert'),
          status: 'error',
        },
        dispatch,
      )
    }
  }
  /* eslint-disable */
  useEffect(() => {

    if (dataMutation) {
      refetchUserData()
      refetchTasks()
      getOneTask({
        variables: {
          taskId: taskData?.id,
          stepId: taskData?.stepId,
          phase: taskData?.phase?.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase()
          }) || task?.phaseName,
          planId
        }
      })
    }
  }, [dataMutation])

  useEffect(() => {
    if (dataOneTask) {
      setTaskData(dataOneTask?.getUserTask)
      dispatch({
        type: UPDATE_TASK,
        data: dataOneTask?.getUserTask,
      })
      refetchUserData()
      refetchTasks()
    }
  }, [dataOneTask])

  /* eslint-enable */
  return (
    <>
      <Button
        onClick={() => {
          filesUploadedByUser?.length > 0
            ? uploadFileFunction()
            : sendToReviewFunction()
        }}
        className='d-flex justify-content-center align-items-center py-2'
        activity={loadMutation || completionLoading || uploadLoading}
        label={
          <div className='d-flex justify-content-between align-items-center my-auto w-100'>
            <div className='col-10 mx-n2'>
              <span className='d-flex justify-content-start align-middle'>
                Send to review
              </span>
            </div>
            <div className='col-2 py-2 mx-n2 d-flex justify-content-end align-items-center'>
              <IconTask
                color='#ffffff'
                fill='#b0b2f9'
                stroke='#767aff'
                strokeWidth='1'
                size={16}
              />
            </div>
          </div>
        }
        style={{
          width: '100%',
          border: '1px solid',
          borderRadius: '10px',
          borderColor: '#696bff',
          backgroundColor: '#696bff',
          color: '#ffffff',
          cursor: 'pointer',
        }}
        size='medium'
      />
    </>
  )
}

export default SendToReviewButton
