import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import IconLoader from '../../../components/LoaderIcon'
import {gql, useMutation} from '@apollo/client'
import {
  parseDataToMutationManual,
  makeObjectConflictOnboarding,
} from '../../../utils'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'

export const SchedulingConflictHeader = (props) => {
  const {status} = props
  const {translate} = useTranslation()
  return (
    <div className='m-0 p-0 my-2'>
      <IconLoader />
      <h2 className='w-100 my-3'>
        <b>{translate('home.onboard.approve.scheduled.giveMe')}</b>
      </h2>
      <h4>{translate('home.onboard.approve.scheduled.someReason')}</h4>

      <div className='mt-5 w-100 d-flex flex-row justify-content-end'>
        <span className='text-info'>{status}</span>
      </div>
    </div>
  )
}
export const SchedulingConflictBody = (props) => {
  const {
    isAdmin,
    setStatus = () => {},
    errorProps = null,
    isOpen,
    setError = () => {},
    setPage = () => {},
  } = props
  const dispatch = useDispatch()
  const reduxOnboarding = useSelector((state) => state.modal.onboarding)
  const mediumModal = useSelector((state) => state.modal.onboarding.mediumModal)
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )
  const {page} = conflictModal
  const reduxData = useSelector((state) => state.modal.onboarding.data)
  const {dataPlan, scheduledTimesOcurrences} = reduxData
  const services = useSelector((state) => state.services.services)

  const [mutationSetState] = useMutation(
    gql`
      mutation update($PlanInputStateOnboarding: PlanInputStateOnboarding!) {
        setOnboardingState(input: $PlanInputStateOnboarding)
      }
    `,
    {
      update: () => {
        // console.log('¡Estado del onboarding guardado con éxito!')
      },
    },
  )

  const [
    mutationManual,
    // eslint-disable-next-line
    {loading: loadManual},
  ] = useMutation(
    gql`
      mutation update(
        $lessons: LessonsPerWeekPlanManualyInput!
        $isAdmin: Boolean
      ) {
        scheduleMyLessonsPerWeekPlanManualy(
          lessons: $lessons
          isAdmin: $isAdmin
        ) {
          ... on OcurrenceResult {
            hasOccurrences
            occurrencesList {
              topicId
              lessonName
              from
              to
              educatorUserId
              educatorFirstName
              educatorLastName
              serviceId
              recurringId
              lockLesson
            }
          }
        }
      }
    `,
    {
      update: (cache, datosManual) => {
        setError(null)
        const notscheduledManual =
          datosManual.data.scheduleMyLessonsPerWeekPlanManualy.occurrencesList
        if (notscheduledManual.length > 0) {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.data',
            data: {
              ...reduxData,
              ...makeObjectConflictOnboarding(notscheduledManual, services),
            },
          })
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.conflictModal',
            data: {...conflictModal, page: 'listConflict'},
          })
        } else {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.mediumModal',
            data: {...mediumModal, page: 'confirmPayment'},
          })
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.conflictModal',
            data: {...conflictModal, isOpen: false},
          })
        }
        // GUARDANDO EL ESTADO DEL MODAL
        mutationSetState({
          variables: {
            PlanInputStateOnboarding: {
              planId: dataPlan?.idPlan,
              stateOnboarding: JSON.stringify(reduxOnboarding),
            },
          },
        })
      },
      onError: (err) => {
        setPage('mutationError')
        setError({
          msg:
            'Ups an error occurred!. Please try again, also you can select another time for your lessons',
          actionLabel: 'Try again',
          action: () => {
            // reejecuta la mutación
            rexecuteSchedulingProccess()
          },
        })
      },
    },
  )

  const rexecuteSchedulingProccess = () => {
    // vuelve a ejecutar la mutación
    setError(null)
    setPage('schedulingConflict')
  }

  useEffect(() => {
    if (page === 'schedulingConflict' && !errorProps && isOpen) {
      mutationManual({
        variables: parseDataToMutationManual(
          scheduledTimesOcurrences,
          dataPlan,
          JSON.stringify(reduxOnboarding),
          isAdmin,
        ),
      })
    }
    // eslint-disable-next-line
  }, [page, isAdmin, errorProps, isOpen])

  useEffect(() => {
    if (loadManual) setStatus('Scheduling conflict...')
    else setStatus(null)
    // eslint-disable-next-line
    }, [loadManual]
  )

  return <div className='m-0 p-0' />
}

export default {SchedulingConflictHeader, SchedulingConflictBody}
