import {useQuery, gql} from '@apollo/client'
import useMyCounselingPlanResult from '../../../Model/QueryResult/useMyCounselingPlan'

const useMyPlan = (vars = {}) => {
  return useQuery(
    gql`
      query($isAdmin: Boolean, $planId: ID!) {
        myPlan(isAdmin: $isAdmin, planId: $planId) {
          ${useMyCounselingPlanResult}
        }
      }
    `,
    {variables: vars},
  )
}

export default useMyPlan
