import approve from './approve'
import adjust from './adjust'
import change from './change'
import payment from './payment'

export default {
  approve,
  adjust,
  change,
  payment,
}
