import {initialState} from './initialState'
import {
  SET_SOCKET_CONNECTION,
  ADD_ENTITY_UPDATED,
  EMPTY_ENTITY_UPDATED,
  ADD_ENTITY_IDS,
} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SOCKET_CONNECTION:
      return {
        ...state,
        socket: action.socket,
      }
    case ADD_ENTITY_UPDATED:
      const newObj = Object.entries(action.entities).reduce(
        (obj, [entity, ids]) => {
          // fusiona ambos arreglos
          if (obj[entity]?.length > 0) {
            obj[entity] = obj[entity].concat(ids).reduce((newArray, id) => {
              if (!newArray.includes(id)) newArray.push(id)
              return newArray
            }, [])
          }
          // crea la llave con el arreglo ids
          else obj[entity] = ids
          return obj
        },
        state.entities,
      )
      return {
        // si action.entities tiene alguna llave que ya esta en entities
        // entonces ambos arreglos se fusionan
        ...state,
        entities: newObj,
        nModified: state.nModified + 1,
      }
    case EMPTY_ENTITY_UPDATED:
      return {
        ...state,
        entities: {},
        nModified: state.nModified + 1,
      }
    case ADD_ENTITY_IDS:
      return {
        ...state,
        entitiesIds: action.ids,
      }
    default:
      return {...state}
  }
}
