export default {
  welcome: 'Welcome to Zinkerz',
  follow: 'Please follow the steps below',
  followSub: ' to set up your firts study plan.',
  step: 'Step',
  approve: 'Approve your study plan',
  confirm: 'Confirm your schedule',
  complete: 'Complete your profile',
  goto: 'Go to approve plan',
  continue: 'Continue',
}
