import React, {useState, useEffect} from 'react'
import {Task, IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import './index.css'
import {SendToReviewButtonOff} from './Buttons'
import ShowButtonByStatus from './showButtonsByStatus'
import useShowScheduleMeeting from '../../ShowScheduledMeeting/useShowScheduledMeeting'
import InfoTaskButton from './Buttons/infoTaskButton'
import useCalendarEvent from '../../../../Calendar/Lessons/Modal/InfoModal/useCalendarEvent'
import OrientationLessonModal from './Modals/OrientationLessonModal'

const ScheduledMeetingComponent = (props) => {
  const {task, team} = props
  const {open: openScheduledMeeting} = useShowScheduleMeeting()
  const [taskData, setTaskData] = useState(task)

  const [idMeeting, setIdMeeting] = useState({})
  const [meetingInfo, setMeetingInfo] = useState({})
  const statusToShowInfo = ['review', 'completed']
  const [isOpenLessonModal, setIsOpenLessonModal] = useState(false)
  const toggle = () => {
    setIsOpenLessonModal(!isOpenLessonModal)
  }

  // eslint-disable-next-line
  const [getDataLesson, {data: dataLessonQuery}] = useCalendarEvent(
    (resp) => {
      setMeetingInfo(resp?.calendarEvent)
    },
    () => {},
  )

  useEffect(() => {
    if (openScheduledMeeting && idMeeting !== {}) {
      getDataLesson({
        variables: {
          calendarEventId: idMeeting,
        },
      })
    }
    // eslint-disable-next-line
  }, [idMeeting])

  useEffect(() => {
    if (task) {
      setTaskData(task)
      setIdMeeting(task?.resolveTask?.meeting)
    }
  }, [task])

  return (
    <>
      {statusToShowInfo.includes(taskData?.status) && (
        <InfoTaskButton
          task={taskData}
          onClick={() => {
            // openInfoLesson(() => {}, task)
            setIsOpenLessonModal(true)
          }}
          icon={
            <IconArrowRight
              size={12}
              style={{verticalAlign: 'inherit'}}
              className='text-gray'
            />
          }
          textLabel={`${moment(taskData?.meetingStartDate).format(
            'dddd MMMM Do, h:mma',
          )} - ${moment(taskData?.meetingStartDate)
            .add(30, 'minutes')
            .format('h:mma')}`}
        />
      )}

      {taskData?.status === 'pending' && (
        <Task
          noIcon
          taskClassName='my-6'
          className='d-flex flex-row justify-content-end' // rightSection className
          labelLeft={
            <span className='text-primary w-100'>Schedule meeting</span>
          }
          labelRight={
            <IconArrowRight
              size={16}
              color='#7D80FF'
              style={{
                verticalAlign: 'inherit',
              }}
            />
          }
          onClick={() => {
            openScheduledMeeting(() => {}, task, team)
          }}
          style={{cursor: 'pointer'}}
        />
      )}
      {taskData?.status === 'pending' && !taskData?.resolveTask?.meeting ? (
        <SendToReviewButtonOff />
      ) : (
        <ShowButtonByStatus taskData={taskData} />
      )}

      <OrientationLessonModal
        role='student'
        dataLesson={meetingInfo}
        onCancel={() => {}}
        isOpen={isOpenLessonModal}
        toggle={toggle}
      />
    </>
  )
}

export default ScheduledMeetingComponent
