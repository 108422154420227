import React, {useEffect} from 'react'
import {IconRight, ListItem} from '@Knowledge-OTP/znk-ui-components'

import {gql, useLazyQuery} from '@apollo/client'
import moment from 'moment-timezone'
import omit from 'lodash/omit'
// import formatNumber from '../../../../../utils/formatNumber'
import usePaymentScheduledReducer from '../../../../../reducers/payment-scheduled'
import useGetPaymentMethod from './Components/GetPaymentMethod/useGetPaymentMethod'

const indexFee = ({num1, num2}) => {
  const string =
    num1 +
    (['th', 'st', 'nd', 'rd'][((num1 % 100) - 20) % 10] ||
      ['th', 'st', 'nd', 'rd'][num1 % 100] ||
      ['th', 'st', 'nd', 'rd'][0])
  return `${string} out of ${num2}`
}
const Payments = (props) => {
  const {plan, isCounseling = true} = props
  const info = plan
  const {formatNumber} = usePaymentScheduledReducer()
  const {label: paymentMethodLabel} = useGetPaymentMethod(
    info?.studentUser?.studentUserId,
  )

  const [getPaymentItems, {data: paymentsQuery}] = useLazyQuery(
    gql`
      query($planId: String!) {
        myPaymentsItems(planId: $planId) {
          docs {
            amount
            status
            description
            category
            type
            date
          }
        }
      }
    `,
  )
  useEffect(() => {
    if (info.id) {
      getPaymentItems({
        variables: {planId: info.id},
      })
    }
  }, [info, getPaymentItems])
  let pendingTotal = 0
  let paidTotal = 0
  let total = 0
  if (
    paymentsQuery &&
    paymentsQuery.myPaymentsItems &&
    paymentsQuery.myPaymentsItems.docs
  ) {
    paymentsQuery.myPaymentsItems.docs.forEach((data) => {
      if (data.status === 'pendingPayment' || data.status === 'unPaid') {
        pendingTotal += data.amount
      }
      if (data.status === 'paid') {
        paidTotal += data.amount
      }
    })
    total = pendingTotal + paidTotal
  }

  const summary = {
    borderRadius: 12,
    fontSize: 14,
    backgroundColor: '#17A1E6',
    minHeight: 121,
    width: '100%',
    color: '#fff',
  }
  let numPayments = 0
  const paymentItems =
    paymentsQuery &&
    paymentsQuery.myPaymentsItems &&
    paymentsQuery.myPaymentsItems.docs
      ? paymentsQuery.myPaymentsItems.docs
          .map((p) => omit(p, ['__typename']))
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
          )
          .map((p) => {
            const newP = {...p}
            if (p.type !== 'otherFixed') {
              newP.num = numPayments + 1
              numPayments += 1
            }
            return newP
          })
      : []

  let approvalFeeStatus = 'paid'
  const approvalFee = paymentItems.reduce((amount, payment) => {
    if (payment.type === 'otherFixed') {
      if (payment.status !== 'paid') {
        approvalFeeStatus = 'pendingPayment'
      }
      return amount + payment.amount
    }
    return amount
  }, 0)

  const counselingStyle = isCounseling
    ? {
        borderRadius: '16px',
        backgroundColor: '#35425b',
        color: '#ffffff',
      }
    : {}
  return (
    <>
      <div className='d-flex flex-column my-5'>
        <div
          style={{...summary, ...counselingStyle}}
          className='px-4 py-4 pb-3 mt-4'
        >
          <div className='d-flex justify-content-center font-weight-bold'>
            <div className='w-50'>
              Total price <br />
              <span className='font-weight-light'>USD</span>
            </div>
            <div className='h1 w-50 text-white font-weight-bold text-right'>
              ${formatNumber(total)}
            </div>
          </div>
          <div className='border-top w-100 border-transparent mt-3 d-flex justify-content-center font-weight-normal text-transparent'>
            <div className='w-50 pt-4'>Payment Method</div>
            <div className='w-50 pt-4 text-right text-capitalize'>
              {paymentMethodLabel}
            </div>
          </div>
        </div>
      </div>
      <div className='px-0 pt-4 pb-3'>
        <span className='h4 font-weight-bold text-title'>Payment History</span>
      </div>
      <div className='borderTop forceOverflow' />
      {approvalFee ? (
        <ListItem
          className='py-3 font-weight-light'
          style={{fontSize: 14}}
          showBottomLine
          forceBorderOverflow
          Left={
            <div className='d-flex align-items-center text-body'>
              Approval Fee
            </div>
          }
          Right={
            <span className='text-gray'>
              ${formatNumber(approvalFee)}
              <IconRight
                className={`ml-2 ${
                  approvalFeeStatus !== 'paid' && 'text-error'
                }`}
                size={16}
              />
            </span>
          }
        />
      ) : null}
      {paymentItems
        .filter(
          (item) =>
            item.type !== 'otherFixed' &&
            item.type !== 'other' &&
            ['paid', 'unPaid'].includes(item.status),
        )
        .map((data) => {
          return (
            <ListItem
              className='py-3 font-weight-light'
              style={{fontSize: 14}}
              showBottomLine
              forceBorderOverflow
              Left={
                <div className='d-flex align-items-center'>
                  <span className='h45 text-dark font-weight-light mr-1'>
                    {`End of ${moment(data.date).utc().format('MMMM')}`}
                  </span>
                  <span className='text-gray'>
                    {indexFee({num1: data.num, num2: numPayments})}
                  </span>
                </div>
              }
              Right={
                <span className='text-gray'>
                  ${formatNumber(data.amount)}
                  <IconRight
                    className={`ml-2 text-${
                      data.status === 'paid' ? 'success' : 'error'
                    }`}
                  />
                </span>
              }
            />
          )
        })}
      <div className='px-0 pt-4 pb-3'>
        <span className='h4 font-weight-bold text-title'>
          Upcoming Payments
        </span>
      </div>
      <div className='borderTop forceOverflow' />
      {paymentItems
        .filter(
          (item) =>
            item.type !== 'otherFixed' &&
            item.type !== 'other' &&
            item.status === 'pendingPayment',
        )
        .map((data) => {
          const unPaid =
            moment(data.date).utc().endOf('month') < moment() && false
          return (
            <ListItem
              className='py-3 px-0 font-weight-light'
              style={{fontSize: 14}}
              showBottomLine
              forceBorderOverflow
              Left={
                <div
                  title={unPaid ? 'unpaid' : 'pending'}
                  className={`${
                    unPaid ? 'text-error' : 'text-body'
                  } d-flex align-items-center`}
                >
                  <span
                    className={`${
                      unPaid ? 'text-error' : 'text-dark'
                    } font-weight-light h45 mr-1`}
                  >
                    {`End of ${moment(data.date).utc().format('MMMM')}`}
                  </span>
                  <span className={`${unPaid ? 'text-error' : 'text-gray'}`}>
                    {indexFee({num1: data.num, num2: numPayments})}
                  </span>
                </div>
              }
              Right={
                <span
                  title={unPaid ? 'unpaid' : 'pending'}
                  className={`${unPaid ? 'text-error' : 'text-gray'}`}
                >
                  ${parseFloat(data.amount).toFixed(2)}
                  <IconRight
                    className={`${
                      unPaid ? 'text-error' : 'text-secondary'
                    } ml-2`}
                  />
                </span>
              }
            />
          )
        })}
    </>
  )
}

export default Payments
