import React, {useEffect} from 'react'
import {
  Modal,
  useWindowDimensions,
  IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import {InformationHeader, InformationBody} from './inner/Information'
import {
  ConfirmScheduledHeader,
  ConfirmScheduledBody,
} from './inner/ConfirmScheduled'
import {ConfirmPaymentHeader, ConfirmPaymentBody} from './inner/ConfirmPayment'
import {TabContent, TabPane, ModalHeader} from 'reactstrap'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

import beanWar from '../../../assets/beanWar.svg'
import beanWar2 from '../../../assets/beanWar.svg'
import '../style.css'
import useUpdateOnboardingStatus from '../Model/useUpdateOnboardingStatus'
// import useTranslation from '../../../i18n/useTranslation'

const MediumModal = (props) => {
  const {isAdmin} = props
  // const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const dispatch = useDispatch()
  const reduxMedium = useSelector((state) => state.modal.onboarding.mediumModal)
  const {isOpen, page} = reduxMedium
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.mediumModal',
      data: {...reduxMedium, isOpen: !isOpen},
    })
  }

  const setPage = (newPage) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'onboarding.mediumModal',
      data: {...reduxMedium, page: newPage},
    })
  }

  useEffect(() => {
    // setPage('confirmPayment')
    setPage('information')
    // eslint-disable-next-line
  }, [])

  const style = {
    backgroundImage: `url(${beanWar}), url(${beanWar2})`,
    backgroundPosition: '98% -105%, 9% 137%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '200px, 120px',
  }

  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const {isOpen: isOpenSmall} = useSelector(
    (state) => state.modal.onboarding.smallModal,
  )
  const [updateOnboarding] = useUpdateOnboardingStatus(
    () => {},
    () => {},
  )
  const updateOnboardingStatus = async (planId, newStatus = 'summary') => {
    await updateOnboarding({
      variables: {planId, newStatus},
    })
  }
  useEffect(() => {
    if (isOpen && !isOpenSmall) {
      updateOnboardingStatus(
        dataPlan?.idPlan || dataPlan?._id || dataPlan?.id || dataPlan?.planId,
        page === 'confirmScheduled'
          ? 'confirming'
          : page === 'confirmPayment'
          ? 'payments'
          : 'summary',
      ).then(() => {}, console.error)
    }
    // eslint-disable-next-line
  }, [page, isOpen, dataPlan, isOpenSmall])

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-colored modal-botbar modal-colored warning-modal modal-border-radius ${
          breakWidth !== 'SM' ? 'px-7 mx-10' : ''
        }`}
        backdropClassName='no-op'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader
            toggle={toggle}
            close={
              <div className='w-100 d-flex flex-row my-2 mt-6 pl-7 pr-1 justify-content-end'>
                {/* <IconMessages
                  size={30}
                  className='mr-4'
                  onClick={() => {
                    showFrontappChat()
                  }}
                /> */}
                <IconClose size={30} className='mr-4' onClick={toggle} />
              </div>
            }
            className='mt-0'
            style={style}
          >
            <div className='w-100 p-0 m-0 px-2 px-md-3 px-xl-9 mx-xl-5'>
              <TabContent activeTab={page} className='px-2'>
                <TabPane tabId='information'>
                  <InformationHeader/>
                </TabPane>
                <TabPane tabId='confirmScheduled'>
                  <ConfirmScheduledHeader />
                </TabPane>
                <TabPane tabId='confirmPayment'>
                  <ConfirmPaymentHeader />
                </TabPane>
              </TabContent>
            </div>
          </ModalHeader>
        }
      >
        <div className='w-100 p-0 m-0'>
          <TabContent activeTab={page} className='px-0'>
            <TabPane tabId='information'>
              <InformationBody />
            </TabPane>
            <TabPane tabId='confirmScheduled'>
              <ConfirmScheduledBody />
            </TabPane>
            <TabPane tabId='confirmPayment'>
              <ConfirmPaymentBody isAdmin={isAdmin} />
            </TabPane>
          </TabContent>
        </div>
      </Modal>
    </>
  )
}

export default MediumModal
