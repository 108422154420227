import React from 'react'
import noDocsImg from '../../../../../../assets/noDocs.svg'
import useTranslation from '../../../../../../i18n/useTranslation'

const NoDocsComponent = (props) => {
  const {small} = props
  const {translate} = useTranslation()

  return (
    <>
      <div
        className={`d-flex flex-column justify-content-center w-100 mt-4 ${
          small ? 'py-2' : 'py-8 mb-2'
        } `}
        style={{
          borderRadius: '10px',
          border: '2px dashed',
          borderColor: '#f0f2f5',
        }}
      >
        <div className='d-flex justify-content-center w-100 mb-3'>
          <img
            src={noDocsImg}
            alt='No Docs'
            color='#696bff'
            width={`${small ? '60' : '70'} `}
            height={`${small ? '77' : '90'}`}
          />
        </div>
        <div className='d-flex flex-column justify-content-center w-100 mt-2'>
          <span className='text-muted text-center h45 mb-4'>
            {translate('counseling.noDocsMainText')}
          </span>
          {!small && (
            <span className='text-muted text-center h45'>
              {translate('counseling.docsExamsText')}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default NoDocsComponent
