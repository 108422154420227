import React, {useMemo} from 'react'
import {LessonCard, PlanCard} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import Skeleton from 'react-loading-skeleton'

const Summary = (props) => {
  const {studentResume, loading} = props
  const {translate} = useTranslation()
  const upcomingLesson = studentResume?.user?.studentInfo?.upcomingLesson
  // const currentPlans = []
  const currentPlans = useMemo(() => {
    if (studentResume?.plans?.docs) {
      const parsedPlans = studentResume?.plans?.docs?.map((plan) => {
        let startTime
        let endTime
        let partArray
        let isFixedPlan = false
        let isTotalLesson = false
        if (plan.kind === 'lessonsFixed') {
          partArray = plan.lessonPerTopic || plan.fixedParts
          isFixedPlan = true
          isTotalLesson = (partArray || []).reduce(
            (isTotal, lessonPart) =>
              isTotal || lessonPart.type === 'totalLesson',
            false,
          )
          // isTotalLessonsPlan = plan.lessonPerTopic.reduce((isTotal, lessonPart) => isTotal || (lessonPart.type === 'totalLesson'), false)
        } else partArray = plan.parts

        startTime = (partArray || []).reduce(
          (num, item) =>
            moment(item.from.split('T')[0]).valueOf() < num
              ? moment(item.from.split('T')[0]).valueOf()
              : num,
          Number.POSITIVE_INFINITY,
        )
        endTime = (partArray || []).reduce(
          (num, item) =>
            moment(item.to.split('T')[0]).valueOf() > num
              ? moment(item.to.split('T')[0]).valueOf()
              : num,
          Number.NEGATIVE_INFINITY,
        )

        const lpwTotal = (partArray || []).reduce((totalLpw, part) => {
          return (
            totalLpw +
            (part?.lessonsPerWeek ||
              part?.TotalPerTopic?.reduce(
                (totalLessons, part) =>
                  totalLessons + (part?.lessonsPerWeek || 0),
                0,
              ))
          )
        }, 0)

        return {
          ...plan,
          startTime: moment(startTime),
          endTime: moment(endTime),
          isFixedPlan,
          isTotalLesson,
          lessonsTotal: lpwTotal,
          status:
            plan.status === 'scheduled' || plan.status === 'paid'
              ? startTime > new Date().getTime()
                ? 'upcoming'
                : 'inprogress'
              : plan.status,
        }
      })
      return parsedPlans?.filter(
        (item) => item.status === 'inprogress' || item.status === 'upcoming',
      )
    }
    return []
  }, [studentResume])

  return (
    <div className='d-flex flex-column'>
      <div className='pr-6'>
        <label className='h4 font-weight-bold mt-4 mb-2'>
          {translate('student.modal.summary.upcommingLesson')}
        </label>
        {loading ? (
          <Skeleton count={1} height={112} className='w-100' />
        ) : (
          upcomingLesson && (
            <LessonCard
              title={`${upcomingLesson?.service?.name} ${upcomingLesson?.topic?.name}`}
              status='upcoming'
              lessonTime={moment(upcomingLesson?.startDate)}
              lessonEndTime={moment(upcomingLesson?.endDate)}
              attendants={
                upcomingLesson?.substitute === null
                  ? [
                      {
                        name: `${upcomingLesson?.educator?.firstName} ${upcomingLesson?.educator?.lastName}`,
                        avatar: null,
                      },
                    ]
                  : [
                      {
                        name: `${upcomingLesson?.substitute?.firstName} ${upcomingLesson?.substitute?.lastName}`,
                        avatar: null,
                      },
                    ]
              }
              onClick={() => {}}
              showCountdown
              preLabelTime={null}
            />
          )
        )}
      </div>
      <div>
        <label className='h4 font-weight-bold mt-4 mb-2'>
          {translate('student.modal.summary.currentPlan')}
        </label>
        <div className='d-flex flex-row znk-scroll-horizontal-v2 znk-scroll-horizontal-planCard'>
          {loading ? (
            <Skeleton count={1} height={112} className='w-100' />
          ) : (
            currentPlans?.map((plan) => {
              return (
                <PlanCard
                  className='mx-2'
                  styleCard={{minWidth: '90%'}}
                  onClick={() => {}}
                  translate={translate}
                  {...plan}
                />
              )
            })
          )}
        </div>
      </div>
    </div>
  )
}

export default Summary
