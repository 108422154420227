import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'

const useRegisterScoresModal = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.registerScoresModal,
  )
  const refetch = useSelector(
    (state) => state.modal.counseling.viewTaskModal.refetch,
  )
  const {isOpen, onChange, task, notAllowClose} = reduxData

  const setTaskData = (task, notAllowClose = false, refetch = () => {}) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.viewTaskModal',
      data: {
        ...reduxData,
        task,
        notAllowClose,
        refetch,
      },
    })
  }
  const setOpen = (open, onChange = () => {}, task) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        task,
      },
    })
  }

  const setScoresData = (scores) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.registerScoresModal',
      data: {
        ...reduxData,
        scores,
      },
    })
  }

  return {
    isOpen,
    onChange,
    open: (onChange = () => {}, college = {}) => {
      setOpen(true, onChange, college)
    },
    close: () => {
      setScoresData({})
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    task,
    setTaskData,
    notAllowClose,
    refetch,
  }
}
export default useRegisterScoresModal
