import React from 'react'
import {
  IconIllustratedPending,
  LessonCard,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import {useSelector, useDispatch} from 'react-redux'

export const ListConflictHeader = (props) => {
  const {translate} = useTranslation()
  return (
    <div className='m-0 p-0'>
      <IconIllustratedPending size={80} />
      <h2 className='w-100 my-3'>
        <b>{translate('home.onboard.approve.scheduled.notScheduled')}</b>
      </h2>
      <h4>
        {translate('home.onboard.approve.scheduled.notScheduledsubtitle')}
      </h4>
    </div>
  )
}
export const ListConflictBody = (props) => {
  const {setPage} = props
  const {scheduledTimesOcurrences} = useSelector(
    (state) => state.modal.onboarding.data,
  )
  const mediumModal = useSelector((state) => state.modal.onboarding.mediumModal)
  const conflictModal = useSelector(
    (state) => state.modal.onboarding.conflictModal,
  )
  const dispatch = useDispatch()
  const redirectToConfirmPayment = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'onboarding.mediumModal',
      data: {...mediumModal, page: 'confirmPayment'},
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'onboarding.conflictModal',
      data: {
        ...conflictModal,
        isOpen: false,
      },
    })
  }
  return (
    <div className='m-0 p-0'>
      <div className='m-0 p-0'>
        {scheduledTimesOcurrences &&
          scheduledTimesOcurrences.map((ocu) => {
            /**
             *  educator: {id: "5fe2504237141078e0d4527f", name: "Jimmy Page"}
                isostring: "2021-05-04T18:00:00.000Z"
                newTime: null
                recurringId: "97RZt1Tn4jmK5ETYNKWYZ"
                topic:
                id: "5f58e6cf1b8c4862420e394d"
                name: "Geometría"
             * **/
            return (
              <LessonCard
                title={ocu?.topic?.name}
                status='pending'
                preLabelTime={moment(ocu?.isostring).format('MMM Do')}
                lessonTime={moment(ocu?.isostring)}
                lessonEndTime={moment(ocu?.isostring).add(50, 'minutes')}
                attendants={[{name: ocu?.educator?.name, avatar_url: null}]}
                key={0}
                showCountdown={false}
                className='w-100 m-1 mb-1'
              />
            )
          })}
      </div>
      <StickyButton
        condition
        color='#696CFF'
        onClickButtonFunction={() => {
          // si no hay incidencias por agendar redirige a confirmarPayment
          if (
            !scheduledTimesOcurrences || // no está definido
            scheduledTimesOcurrences?.length === 0 // el listado de incidencias está vacio
          )
            redirectToConfirmPayment()
          else setPage('scheduledConflict')
        }}
      />
    </div>
  )
}

export default {ListConflictHeader, ListConflictBody}
