import {useMutation, gql} from '@apollo/client'
import planQueryResult from '../../../../../Task/Model/queryResults/plan'

const useSetTimesSkippedMutation = (
  onSuccess = () => {},
  onError = () => {},
) => {
  return useMutation(
    gql`
            mutation (
                $activityTest: ActivityTestInput!
                $planId: ID!
                ) {
            setTimesSkippedToPlan (
                    activityTest: $activityTest
                    planId: $planId
                )  {
                    ${planQueryResult}
                }
            }
        `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useSetTimesSkippedMutation
