import React, {useState, useMemo, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
  ModalLayout,
  useWindowDimensions,
  IconIllustratedCalendar,
  Toast,
  IconSignCheck,
  IconArrowLeft,
} from '@Knowledge-OTP/znk-ui-components'

import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import {TabContent, TabPane} from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import TimeSelectionBlock from './TimeSelectionBlock'
import TimeSelectionBlockHeader from './TimeSelectionBlock/header'
import TimeSelectionCalendar from './TimeSelectionCalendar'
import ShowPendingLesson from './ShowPendingLesson'
import ShowPendingLessonHeader from './ShowPendingLesson/header'
import ConfirmSelection from './ConfirmSelection'
import ConfirmSelectionHeader from './ConfirmSelection/header'
import useModalState from './../../../../../hooks/useModalState'
import moment from 'moment'

const PendingLesson = () => {
  const {translate} = useTranslation()
  const [toastOpen] = useState(false)
  const dispatch = useDispatch()
  const {isOpen, modalData, onChange, toggle} = useModalState(
    'program.rescheduledModal',
  )

  const [selectData, setSelectData] = useState({
    time: {
      id: 0,
      time: null,
    },
    lesson: {
      id: 0,
      name: null,
      att: null,
    },
  })
  useEffect(() => {
    if (!isOpen) {
      // blanquea el bloque seleccionado en el calendario
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'program.rescheduledModal',
        data: {
          ...modalData,
          selectedBlock: null,
        },
      })
      setSelectedBlock(null)
      setSelectData({
        time: {
          id: 0,
          time: null,
        },
        lesson: {
          id: 0,
          name: null,
          att: null,
        },
      })
    }
    // eslint-disable-next-line
  }, [isOpen])
  const {
    stage,
    educator,
    selectedBlock: selectedBlockModal,
    refetchLessons,
    setOpenInfoModal,
  } = modalData
  const [now, setNow] = useState(() =>
    moment().startOf('isoWeek').hours(7).minutes(0).seconds(0).milliseconds(0),
  )

  const [selectedBlock, setSelectedBlock] = useState(selectedBlockModal)

  const userInfo = useSelector((state) => state.user.info)
  const {breakWidth} = useWindowDimensions()
  const [from, to] = useMemo(() => {
    const from = now.toISOString()
    const to = now.clone().add(7, 'd').toISOString()
    return [from, to]
  }, [now])

  const [limitFrom, limitTo] = useMemo(() => {
    const from = moment().add(24, 'hours')
    const to = moment().add(5, 'month')
    return [from, to]
  }, [])

  const topicHeaderName = `${
    modalData?.lesson?.service &&
    modalData?.lesson?.service?.name
  } ${
    modalData?.lesson?.topic && 
    modalData?.lesson?.topic?.name
  }`

  return (
    <span>
      <Toast
        className='secondary-toast'
        open={toastOpen}
        Left={
          <>
            <IconSignCheck size={16} />
            {translate('lessons.lessonscheduled')}
          </>
        }
        Right={<>{translate('lessons.view')}</>}
      />
      <ModalLayout
        buttonLabel={null}
        className={`modal-colored modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } white-modal`}
        isOpen={isOpen}
        toggle={toggle}
        useModalHeader={false}
        disabledScrollAnimation
        dimensionClassName={
          stage === 'timeSelectionCalendar' ? `m-0 px-5 py-3` : null
        }
        topHead={
          <div className='d-flex flex-column m-0 p-0'>
            {['timeSelectionCalendar', 'confirmSelection'].includes(stage) && (
              <div
                style={{width: 'fit-content'}}
                onClick={() => {
                  onChange((val) => ({
                    ...val,
                    stage:
                      stage === 'timeSelectionCalendar'
                        ? 'timeSelectionBlock'
                        : stage === 'confirmSelection'
                        ? 'timeSelectionBlock'
                        : stage,
                  }))
                }}
                className='hover-icon'
              >
                <IconArrowLeft size={20} className='text-disabled' />
              </div>
            )}
            {['showPendingLesson'].includes(stage) && (
              <ShowPendingLessonHeader />
            )}
            {[
              'timeSelectionCalendar',
              'confirmSelection',
              'timeSelectionBlock',
            ].includes(stage) && (
              <IconIllustratedCalendar className='ml-2 mt-2' size={80} />
            )}
          </div>
        }
        underHead={
          <div className='pl-0'>
            <div className='w-100 pl-3'>
              <TabContent activeTab={stage}>
                <TabPane tabId='timeSelectionBlock'>
                  <TimeSelectionBlockHeader
                    topic={topicHeaderName}
                    time={userInfo?.timezone}
                  />
                </TabPane>
                <TabPane tabId='timeSelectionCalendar'>
                  <TimeSelectionBlockHeader
                    topic={topicHeaderName}
                    time={userInfo?.timezone}
                  />
                </TabPane>
                <TabPane tabId='confirmSelection'>
                  <ConfirmSelectionHeader
                    topic={topicHeaderName}
                    time={userInfo?.timezone}
                  />
                </TabPane>
                <TabPane tabId='showPendingLesson' />
              </TabContent>
            </div>
          </div>
        }
      >
        <div className='p-0 m-0'>
          <TabContent activeTab={stage}>
            <TabPane tabId='timeSelectionBlock'>
              <TimeSelectionBlock
                modalData={modalData}
                from={limitFrom}
                to={limitTo}
                now={now}
                educator={educator}
                idUser={educator?.educatorUserId}
                selectData={selectData}
                setSelectData={setSelectData}
                onChange={onChange}
                isOpen={isOpen}
              />
            </TabPane>
            <TabPane tabId='timeSelectionCalendar'>
              <TimeSelectionCalendar
                from={from}
                to={to}
                limitFrom={limitFrom}
                limitTo={limitTo}
                now={now}
                setNow={setNow}
                educator={educator}
                idUser={educator?.educatorUserId}
                lessonId={modalData?.lesson?.lessonId}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
                onChange={onChange}
                selectData={selectData}
                setSelectData={setSelectData}
                isOpen={isOpen}
                refetchLessons={refetchLessons}
                modalData={modalData}
                toggle={() => {
                  setOpenInfoModal(false)
                }}
              />
            </TabPane>
            <TabPane tabId='confirmSelection'>
              <ConfirmSelection
                selectData={selectData}
                setSelectData={setSelectData}
                modalData={modalData}
                onChange={onChange}
                refetchLessons={refetchLessons}
                educator={educator}
              />
            </TabPane>
            <TabPane tabId='showPendingLesson'>
              <ShowPendingLesson
                selectData={selectData}
                setSelectData={setSelectData}
              />
            </TabPane>
          </TabContent>
        </div>
      </ModalLayout>
    </span>
  )
}

export default PendingLesson
