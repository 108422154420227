export default {
  title: 'Solo un paso más.',
  complete: 'Completa tu perfil.',
  subtitle:
    'In order to improve your experience Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero.',
  student: 'Nombre del estudiante',
  gender: 'Género',
  male: 'Masculino',
  female: 'Femenino',
  other: 'No se, no contesto',
  birth: 'Fecha de nacimiento',
  day: 'Día',
  month: 'Mes',
  year: 'Año',
  language: 'Lenguaje',
  dialcode: 'Prefijo',
  mobile: 'Teléfono',
  spoken: 'Idiomas',
  address: 'Dirección',
  city: 'Ciudad',
  state: 'Estado',
  zip: 'Código zip',
  country: 'País',
  confirm: 'Completar perfil',
  selectcountry: 'Seleccionar país',
}
