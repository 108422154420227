import {gql, useMutation} from '@apollo/client'

const UseUpdateTimezone = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($userId: String, $newTimezone: String!) {
        updateTimezone(userId: $userId, newTimezone: $newTimezone)
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default UseUpdateTimezone
