import {gql, useLazyQuery} from '@apollo/client'

const GetInvoicePayUrl = (
  onCompleted = () => {},
  onError = () => {},
  options = {fetchPolicy: 'no-cache'},
) => {
  return useLazyQuery(
    gql`
      query($invoiceId: String!) {
        getInvoicePayUrl(invoiceId: $invoiceId)
      }
    `,
    {
      onCompleted,
      onError,
      ...options,
    },
  )
}

export default GetInvoicePayUrl
