import React from 'react'
import {IconArrowRight, IconBank} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'

const ListItem = (props) => {
  const {item, onClick} = props
  return (
    <div
      className='d-flex flex-row align-items-center list-item-university py-2'
      onClick={onClick}
    >
      <div className='w-25'>
        {item?.logo ? (
          <img
            src={item?.logo}
            alt={`${item?.name}`}
            style={{width: '50px', height: '50px'}}
          />
        ) : (
          <IconBank />
        )}
      </div>
      <div className='w-100'>{item?.name}</div>
      <div>
        <IconArrowRight color='#E3E3E3' size={16} />
      </div>
    </div>
  )
}

ListItem.defaultProps = {
  item: {},
  onClick: () => {},
}

ListItem.propTypes = {
  item: PropTypes.array,
  onClick: PropTypes.func,
}

export default ListItem
