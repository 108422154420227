import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {IconRemove, Task} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import {showAlert} from '../../../../../../common/Alert/util'

const UploadFileButton = (props) => {
  // eslint-disable-next-line
    const {filesUploadedByUser, setFilesUploadedByUser, ...otherProps} = props
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const maxNumOfFiles =
    process.env.MAX_QUANTITY_FILES ||
    process.env.REACT_APP_MAX_QUANTITY_FILES ||
    10 // Queda 10 por defecto, para evitar errores si no están las variables de entorno
  const maxSize = process.env.MAX_SIZE || process.env.REACT_APP_MAX_SIZE || 300 // Poner la cantidad máxima de mb
  const byteInMb = 1048576 // cuantos bytes son 1 mb
  const [showFilesToUpload, setShowFilesToUpload] = useState(true)
  const [loadingFiles, setLoadingFiles] = useState(false)
  // Función que maneja los cambios en el Drag and Drop de los files
  const handleFileChange = (e) => {
    let fullSize = 0
    if (!e.target.files) return
    if (e.target.files?.length > maxNumOfFiles) {
      showAlert(
        {
          text: translate('counseling.maxFilesError') + maxNumOfFiles,
          status: 'error',
        },
        dispatch,
      )
    }

    if (e?.target?.files) {
      fullSize = Array.from(e?.target?.files).reduce((fullSize, file) => {
        return fullSize + file.size
      }, 0)
    }

    if (fullSize > maxSize * byteInMb) {
      showAlert(
        {
          text: translate('counseling.maxSizeError') + `${maxSize} mb`,
          status: 'error',
        },
        dispatch,
      )
    }

    setFilesUploadedByUser(
      filesUploadedByUser.concat(Array.from(e?.target?.files)),
    )
    if (showFilesToUpload === false) setShowFilesToUpload(true)

    setLoadingFiles(!loadingFiles)
  }

  // Función para borrar un archivo del array de archivos que se va a subir
  const removeFromListFunction = (itemRemove) => {
    const removed = filesUploadedByUser.filter(
      (f) => f.name !== itemRemove.name,
    )
    setFilesUploadedByUser(removed)
  }

  return (
    <div className='mt-3 mb-1'>
      <div className='d-flex flex-column align-items-start'>
        <span className='h4'>
          {' '}
          {translate('modal.task.uploadfilebuton.uploadprove')}
        </span>
      </div>
      <div
        id='drop-zone'
        className='file-select mb-4 d-flex flex-column align-items-center justify-content-center'
        style={{borderColor: '#DCE1E7'}}
      >
        <span
          className='d-flex flex-column align-items-center justify-content-center'
          style={{
            visibility: 'visible',
            textAlign: 'center',
          }}
          id='dragSpan'
          color='#DCE1E7'
          width='100'
          height='100'
        >
          <span className='cursor-pointer'>
            {' '}
            <u>{translate('modal.task.uploadfilebuton.browse')}</u>{' '}
          </span>
        </span>
        <input
          className='d-flex justify-content-center py-0 mb-4 input-drop'
          type='file'
          style={{backgroundColor: 'transparent'}}
          id='input'
          name='input'
          onChange={handleFileChange}
          multiple
        />
      </div>

      <div
        id='files-info'
        className='d-flex flex-column mb-4'
        style={{
          display: showFilesToUpload ? 'block' : 'none',
        }}
      >
        {filesUploadedByUser?.length > 0 && (
          <span className='h4 mb-1'> {translate('counseling.title2')} </span>
        )}
        {filesUploadedByUser?.length > 0 &&
          filesUploadedByUser?.map((x, index) => (
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <Task
                noIcon
                taskClassName='my-1'
                labelLeft={
                  <div>
                    <span style={{lineHeight: '1.6', color: '#4a6270'}}>
                      {x.name}
                    </span>
                  </div>
                }
                labelRight={
                  <IconRemove
                    style={{cursor: 'pointer'}}
                    onClick={() => removeFromListFunction(x)}
                  />
                }
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default UploadFileButton
