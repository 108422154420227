export default {
  studentName: 'Nombre',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo',
  birthday: 'Cumpleaños',
  phone: 'Teléfono celular',
  address: 'Dirección',
  country: 'País',
  city: 'Ciudad',
  state: 'Estado/Región',
  zipCode: 'Código postal',
  spokenLanguages: 'Idiomas hablados',
  gender: 'Género',
}
