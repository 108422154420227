import React, {useEffect, useRef} from 'react'
import ChangeTimezone from '../ChangeTimezone/index.js'
import moment from 'moment-timezone'
import {useSelector} from 'react-redux'
import useChangeTimezone from '../ChangeTimezone/useChangeTimezone'

const AdjustTimezone = () => {
  const myComputerTimezone = moment.tz.guess()
  const {setZone} = useChangeTimezone()
  const {timezone: zone} = useSelector((state) => state.user.info)
  const clickElement = useRef(null)
  useEffect(() => {
    if (clickElement?.current && zone !== myComputerTimezone)
      clickElement.current.click()
    // eslint-disable-next-line
    }, [clickElement])
  return (
    <ChangeTimezone
      time={zone}
      setTime={(e) => {
        setZone(e)
      }}
      geoMode
    >
      <div ref={clickElement} style={{visibility: 'hidden'}}>
        Click
      </div>
    </ChangeTimezone>
  )
}

export default AdjustTimezone
