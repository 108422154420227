import React from 'react'
import {EventItem, IconTime} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const CheckInTypeInt = {
    regular: 'home.onboard.approve.types.regular',
    scoreExpectation: 'home.onboard.approve.types.scoreExpectation'
}

const CheckInsSection = (props) => {
    const { checkIns = [] } = props
    const {translate} = useTranslation()
    return (
        checkIns.map(
            (checkin) => {
                const { date, type, id } = checkin
                return (
                  <>
                    <EventItem
                      // pending scheduling
                      className='my-1 py-1'
                      key={id}
                      status='secondary'
                      noRightSection={true}
                      Icon={() => (<></>)}
                      titleForTitle={`${moment(date)
                        .utc()
                        .format('dddd, Do MMM')} (${translate(CheckInTypeInt[type])})`}
                      title={
                        <>
                          <IconTime className={'mr-2'} style={{marginBottom: '2px'}} size={16}/>
                          {moment(date).utc().format('dddd, Do MMM')}{' '}
                          <small>{`(${translate(CheckInTypeInt[type])})`}</small>
                        </>
                      }
                      info=''
                    />
                  </>
                )
            }
        )
    )
}

export default CheckInsSection
