import {useMutation, gql} from '@apollo/client'

const useSetExamScore = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation(
        $examInput: ExamInput!
        $taskId: ID!
        $stepId: ID!
        $phase: PhaseEnum!
        $planId: ID!
      ) {
        setExamScore(
          examInput: $examInput
          taskId: $taskId
          stepId: $stepId
          phase: $phase
          planId: $planId
        )
      }
    `,
    {
      onCompleted: (result) => {
        onSuccess(result)
      },
      onError,
    },
  )
}

export default useSetExamScore
