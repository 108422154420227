import React, {useState, useEffect} from 'react'
import {
  Modal,
  useWindowDimensions,
  ListItem,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
import AccountEdit from '../accountEdit'
import removeAccountFromUser from '../../../../../../Model/Users/Accounts/DeleteAccount/removeAccountFromUser'
import ErrorMessageModal from '../messageModal'

const AccountsOptionsMenu = (props) => {
  const {className, disabled, isOpen, toggle} = props

  const account = useSelector(
    (state) => state.modal?.profile?.account?.info?.account,
  )
  const {breakWidth} = useWindowDimensions()

  const {translate} = useTranslation()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [accountEditModalOpen, setAccountEditModalOpen] = useState(false)

  const [errorMessageModalOpen, setErrorMessageModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const {
    submit: removeAccountMutation,
    loading: removeAccountLoading,
    // eslint-disable-next-line
        data: removeAccountData,
    error: removeAccountErrors,
  } = removeAccountFromUser(
    () => {},
    (err) => {
      setErrorMessage(err.toString())
      setErrorMessageModalOpen(true)
      console.error('Error on delete account : ', err)
    },
  )

  const confirmFunction = async () => {
    try {
      await removeAccountMutation({
        platformName: account?.platform,
      })
    } catch (error) {
      setErrorMessage(error.toString())
      setErrorMessageModalOpen(true)
    }
  }

  useEffect(() => {
    if (removeAccountErrors) {
      setErrorMessage(removeAccountErrors.toString())
      setErrorMessageModalOpen(true)
      setConfirmModalOpen(false)
      toggle()
    }
    // eslint-disable-next-line
    }, [removeAccountErrors])

  return (
    <>
      <div
        className={className}
        onClick={() => {
          if (!disabled) {
            toggle()
          }
        }}
      />
      <Modal
        buttonLabel={null}
        className={`modal-float modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-hauto`}
        StyleModalBody={{backgroundColor: 'white'}}
        isOpen={isOpen}
        toggle={toggle}
        Foot={
          <ListItem
            className='listItem-white px-4 d-flex justify-content-center w-100'
            style={{borderRadius: '12px'}}
            Left={
              <div className='d-flex align-items-center' onClick={toggle}>
                <h3>{translate('profile.actions.accounts.cancel')}</h3>
              </div>
            }
          />
        }
      >
        <div className='row m-0 p-0'>
          <div
            className='m-0 p-0 w-100'
            style={{
              listStyleType: 'none',
            }}
          >
            <ListItem
              className='listItem-white px-4 d-flex justify-content-center'
              Left={
                <div className='d-flex align-items-center'>
                  <h3
                    style={{
                      color: '#293e58',
                      textAlign: 'center',
                    }}
                  >
                    {account?.platform}
                  </h3>
                </div>
              }
            />

            <ListItem
              showBottomLine={false}
              showTopLine
              borderTopClassName='borderTop-white'
              className='listItem-white px-4 d-flex justify-content-center'
              Left={
                <div className='d-flex align-items-center'>
                  <span className='text-primary'>
                    {translate('profile.actions.accounts.editKey')}
                  </span>
                </div>
              }
              onClick={() => {
                setAccountEditModalOpen(true)
              }}
            />

            <ListItem
              showBottomLine={false}
              showTopLine
              borderTopClassName='borderTop-white'
              className='listItem-white px-4 d-flex justify-content-center'
              Left={
                <div className='d-flex align-items-center'>
                  <span style={{color: '#ff431e'}}>
                    {translate('profile.actions.accounts.removeKey')}
                  </span>
                </div>
              }
              onClick={() => {
                setConfirmModalOpen(true)
              }}
            />
          </div>
        </div>
      </Modal>
      <AccountEdit
        isOpen={accountEditModalOpen}
        toggle={() => setAccountEditModalOpen(!accountEditModalOpen)}
        account={account}
      />
      <ConfirmModal
        className='m-4'
        isOpen={confirmModalOpen}
        toggle={!confirmModalOpen}
        loading={removeAccountLoading}
        title={translate('profile.actions.accounts.deleteAccountTitle')}
        body={translate('profile.actions.accounts.deleteAccountText')}
        classNameActionDiv='d-flex flex-column justify-content-end w-80'
        confirmAction={() => {
          confirmFunction()
        }}
        cancelAction={() => setConfirmModalOpen(false)}
        confirmText={translate('profile.actions.accounts.confirmButton')}
        cancelText='Cancel'
      />
      <ErrorMessageModal
        isOpen={errorMessageModalOpen}
        toggle={() => setErrorMessageModalOpen(!errorMessageModalOpen)}
        message={errorMessage}
      />
    </>
  )
}

export default AccountsOptionsMenu
