import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import myUniversity from './queryResults/myUniversity'

export default (onSuccess, onError = () => {}) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
        mutation(
          $universityId: ID!
          ){
            removeMyUniversity (
                universityId: $universityId
                ){
                    ${myUniversity} 
                }
          }
    `,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {loading, data: data ? data.removeMyUniversity : null, error, submit}
}
