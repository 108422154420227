import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../store/modal/const'
import get from 'lodash/get'
const useModalState = (stateKey) => {
  const {isOpen, ...modalData} = useSelector((state) =>
    get(state.modal, stateKey),
  )
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: stateKey,
      data: {isOpen: !isOpen, ...modalData},
    })
  }
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(modalData)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: stateKey,
      data: {isOpen: true, ...newVal},
    })
  }
  return {
    isOpen,
    modalData,
    toggle,
    onChange,
  }
}

export default useModalState
