import moment from 'moment'
import {nanoid} from 'nanoid'

export default function reducer(state, action) {
  switch (action.type) {
    case 'update-data':
      return {
        ...state,
        ...action.data,
      }

    case 'add-payment':
      return {
        ...state,
        payments: state.payments.concat([action.data]),
      }

    case 'edit-payment':
      return {
        ...state,
        payments: state.payments.map((payment) => {
          if (payment.paymentId === action.data.paymentId)
            return {
              ...payment,
              ...action.data,
              name: `End of ${moment(action.data.date || payment.date).format(
                'MMM',
              )}`,
            }
          return payment
        }),
      }
    case 'remove-payment':
      return {
        ...state,
        payments: state.payments.filter(
          (payment) => payment.paymentId !== action.data.paymentId,
        ),
      }

    case 'distribute-payment':
      // cuotas en las que el remanente será distribuido
      const paymentsToDist = state.payments.filter(({isEdit}) => !isEdit)
      // console.log("paymentsToDist", paymentsToDist)

      // monto que será distribuido entre las cuotas no editadas
      // monto total - valor de las cuotas editadas
      // console.log("state.totalAmount", state.totalAmount)
      const amountToDist =
        state.totalAmount -
        state.payments
          .filter(({isEdit}) => isEdit)
          .reduce((total, {amount}) => total + amount, 0)
      // console.log("amountToDist", amountToDist)
      // monto de cada cuota
      const amount = parseFloat(amountToDist / (paymentsToDist?.length || 0))
      // console.log("amount", amount)
      return {
        ...state,
        payments: state.payments.map((payment) => {
          if (!payment.isEdit)
            return {
              ...payment,
              amount,
            }
          return payment
        }),
      }
    case 'set-total':
      return {
        ...state,
        totalAmount: action.data.total,
      }
    case 'set-default':
      return {
        ...state,
        defaultPayments: action.data,
      }
    case 'reset-payment':
      const totalMonth = Math.ceil(
        Math.abs(
          moment.duration(moment(state.from).diff(moment(state.to))).asMonths(),
        ),
      )
      const newPayments = new Array(totalMonth).fill(0)
      return {
        ...state,
        payments:
          state.defaultPayments ||
          newPayments.map((_, index) => {
            const paymentDate = moment(state.from).add(index, 'month')
            return {
              amount: 0,
              date: paymentDate.toISOString(),
              name: `End of ${paymentDate.format('MMM')}`,
              paymentId: nanoid(),
            }
          }),
      }
    case 'set-range':
      return {
        ...state,
        from: action.data.from,
        to: action.data.to,
      }
    default:
      throw new Error()
  }
}
