export default {
  edit: 'Edit',
  delete: 'Delete',
  notFound: 'Not found!',
  modalError: "Couldn't complete the given action",
  saveChanges: 'Save Changes',
  timezone: 'Timezone',
  noLessonFound: 'No lessons found',
  nothingFound: 'Nothing found',
  paymentMethod: 'Method',
  clearFilters: 'Clear filters',
  coolThanks: 'Cool, thanks.',
  cancel: 'CANCEL',
  continue: 'continue',
  results: (num) => `${num} result${num === 1 ? '' : 's'}`,
  rangeDate: ({from, to}) => `${from} to ${to}`,
}
