import React from 'react'
import {
  Modal,
  IconClose,
  Button,
  useWindowDimensions,
  IconPayments,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'

const BankCreditModal = (props) => {
  const {isOpen, toggle, bankData} = props
  const {breakWidth} = useWindowDimensions()

  return (
    <span>
      <div onClick={toggle}>{props.children}</div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader close={<IconClose size={20} onClick={toggle} />}>
            <IconPayments size={breakWidth === 'SM' ? 48 : 70} color />
          </ModalHeader>
        }
      >
        <div className='d-flex flex-column m-3'>
          <h2 className='htext mt-0'>Bank Transfer Details</h2>
          <div
            className='d-flex flex-column h45 p-3 mb-5 text-gray'
            style={{
              backgroundColor: '#F0F4F8',
              borderRadius: '10px',
            }}
          >
            <div className='d-flex flex-row'>
              ​<b className='w-50'>Bank Name:</b>
              <span className='w-50 d-flex flex-row justify-content-end'>
                {bankData?.bankName}
              </span>
            </div>
            <div className='d-flex flex-row'>
              ​<b className='w-50'>Routing Number:</b>
              <span className='w-50 d-flex flex-row justify-content-end'>
                {bankData?.routingNumber}
              </span>
            </div>
            <div className='d-flex flex-row'>
              ​<b className='w-50'>Account Number:</b>
              <span className='w-50 d-flex flex-row justify-content-end'>
                {bankData?.accountNumber}
              </span>
            </div>
            <div className='d-flex flex-row'>
              ​<b className='w-50'>SWIFT Code:</b>
              <span className='w-50 d-flex flex-row justify-content-end'>
                {bankData?.swiftCode}
              </span>
            </div>
          </div>
          <Button
            label='Confirm'
            style={{backgroundColor: '#FFB519', border: 'none', width: '100%'}}
            onClick={toggle}
            activity={false}
          />
        </div>
      </Modal>
    </span>
  )
}

BankCreditModal.propTypes = {}

export default BankCreditModal
