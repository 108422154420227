import React, {useMemo, useEffect, useState} from 'react'
import useElementsHandler from '../../../Elements/useElementsHandler'
import useMyPlan from '../../../Model/useMyPlan'
import {usePDF} from '@react-pdf/renderer'
import EmbedPdf from './EmbedPdf'
import GetProposalObject from './GetPdfProposalObject'

const PdfViewer = (props) => {
  const {
    counselingPlanId,
    isAdmin = false,
    currentPage = 1,
    ...otherProps
  } = props
  // obtiene info de plan
  const {data: dataPlan} = useMyPlan({
    planId: counselingPlanId,
    isAdmin,
  })

  const {RenderHtmlDocument} = useElementsHandler()

  const [documentArray, setDocumentArray] = useState([])
  const [documentInstance, setDocumentInstance] = usePDF({
    document: RenderHtmlDocument(documentArray),
  })
  useEffect(() => {
    if (documentArray) {
      // setDocument({Html, jsonDocument})
      const document = RenderHtmlDocument(documentArray)
      setDocumentInstance({document})
    }
    // eslint-disable-next-line
  }, [documentArray])

  const URL = useMemo(() => {
    if (documentInstance?.url) {
      return `${documentInstance?.url}#page=${currentPage}`
    }
    return ''
    // eslint-disable-next-line
    }, [documentInstance?.url, currentPage]
  ) 

  return (
    <>
      <GetProposalObject
        setDocumentArray={(newArray) => {
          setDocumentArray(newArray)
        }}
        counselingPlan={dataPlan?.myPlan}
      />
      <EmbedPdf url={URL} type='application/pdf' {...otherProps} />
    </>
  )
}

export default PdfViewer
