import React, {useEffect, useRef, useState} from 'react'
import cursor from './assets/cursor.png'
import './index.css'

const SwitchAnimationIcon = () => {
  const switchRef = useRef(null)

  const [clickCount, setClickCount] = useState(0)

  useEffect(() => {
    if (switchRef?.current?.children?.length > 0) {
      const sw = switchRef?.current?.children[0]
      const timer = setTimeout(() => {
        sw.click()
        setClickCount(clickCount + 1)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [switchRef, clickCount])
  return (
    <span className='allow-gift' ref={switchRef}>
      <span className='allow-link'>Allow</span>
      <div className='allow-cursor'>
        <span />
        <img className='w-100' src={cursor} alt='' />
      </div>
      {/* <Switch style={{'transform': 'scale(1.2)'}} className={'h-auto'} /> */}
    </span>
  )
}

export default SwitchAnimationIcon
