import React from 'react'
import {Badge} from 'reactstrap'
import useTranslation from '../../../../../../../i18n/useTranslation'

const LessonFilters = (props) => {
  const {lessons, setFilterStatus, baseStatus, lessonLoading, current} = props
  let attended = 0
  let missed = 0
  let pending = 0
  let scheduled = 0
  if (!lessonLoading && lessons.length) {
    lessons.forEach((item) => {
      switch (item.status) {
        case 'Pending':
          pending++
          break
        case 'Finished':
          if (item.student.attendanceStatus === 'missed') {
            missed++
          } else {
            attended++
          }
          break
        case 'Scheduled':
          if (item.student.attendanceStatus === 'checkIn') {
            attended++
          } else {
            scheduled++
          }
          break
        default:
          break
      }
    })
  }
  const filterByStatus = (status) => {
    if (status === 'all') {
      return setFilterStatus(baseStatus)
    }
    return setFilterStatus([status])
  }
  const {translate} = useTranslation()
  return (
    <div className='my-3 znk-scroll-horizontal scrollToo'>
      <Badge
        className={`mr-2 ${current.length === 3 && 'active'}`}
        pill
        onClick={() => filterByStatus('all')}
      >
        {translate('program.plan.statuses.all')} ({lessons.length})
      </Badge>
      <Badge
        className={`mr-2 ${
          current.length === 1 && current[0] === 'Scheduled' && 'active'
        }`}
        pill
        onClick={() => filterByStatus('Scheduled')}
      >
        {translate('program.plan.statuses.upcoming')} ({scheduled})
      </Badge>
      <Badge
        className={`mr-2 ${current[0] === 'attended' && 'active'}`}
        pill
        onClick={() => filterByStatus('attended')}
      >
        {translate('program.plan.statuses.attended')} ({attended})
      </Badge>
      <Badge
        className={`mr-2 ${current[0] === 'missed' && 'active'}`}
        pill
        onClick={() => filterByStatus('missed')}
      >
        {translate('program.plan.statuses.missed')} ({missed})
      </Badge>
      <Badge
        className={`mr-2 ${current[0] === 'Pending' && 'active'}`}
        pill
        onClick={() => filterByStatus('Pending')}
      >
        {translate('program.plan.statuses.pending')} ({pending})
      </Badge>
    </div>
  )
}

export default LessonFilters
