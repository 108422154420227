const set = require('lodash/set')
const get = require('lodash/get')
const cloneDeep = require('lodash/cloneDeep')

const useTemplatePdf = () => {
  const replaceText = (documentString, variables = {}) => {
    return Object.entries(variables).reduce((document, [varName, varValue]) => {
      // reemplaza todas las ocurrencias
      return varValue?.length > 0
        ? document.split(`/*[${varName}]*/`).join(varValue)
        : document
    }, documentString)
  }

  const pushChildren = (documentPage /* obj */, newChildren, key = '') => {
    // section id : SUrXf4pLgScAJAjQ3VYWC , body[0]%childrens[4]
    const newDocumentPage = cloneDeep(documentPage)
    const element = cloneDeep(get(newDocumentPage, key))
    if (element?.childrens) element.childrens.push(newChildren)
    set(newDocumentPage, key, element)
    return newDocumentPage
  }

  const replaceChildren = (documentPage /* obj */, newChildren, key = '') => {
    // section id : SUrXf4pLgScAJAjQ3VYWC , body[0]%childrens[4]
    const newDocumentPage = cloneDeep(documentPage)
    const element = cloneDeep(get(newDocumentPage, key))
    // if (element?.childrens)
    element.childrens = [newChildren]
    set(newDocumentPage, key, element)
    return newDocumentPage
  }

  const pushOnPath = (
    document /* array */,
    newChildren,
    currentPage = 0,
    key = '',
  ) => {
    // section id : SUrXf4pLgScAJAjQ3VYWC , body[0]%childrens[4]
    const newDocument = cloneDeep(document)
    newDocument[currentPage] = pushChildren(
      newDocument[currentPage],
      newChildren,
      key,
    )
    return newDocument
  }

  const replaceOnPath = (
    document /* array */,
    newChildren,
    currentPage = 0,
    key = '',
  ) => {
    // section id : SUrXf4pLgScAJAjQ3VYWC , body[0]%childrens[4]
    const newDocument = cloneDeep(document)
    newDocument[currentPage] = replaceChildren(
      newDocument[currentPage],
      newChildren,
      key,
    )
    return newDocument
  }

  return {
    replaceText,
    pushChildren,
    pushOnPath,
    replaceOnPath,
  }
}

export default useTemplatePdf
