import validate from 'validate.js'

const constraints = {
  userId: {
    presence: {
      allowEmpty: false,
      message: '^Plase select o create a student',
    },
  },
  updatedUser: {
    presence: true,
  },
  'updatedUser.firstName': {
    presence: false,
  },
  'updatedUser.lastName': {
    presence: false,
  },
  'updatedUser.address': {
    presence: false,
  },
  'updatedUser.phoneDialCode': {
    presence: false,
  },
  'updatedUser.phoneNumber': {
    presence: false,
  },
  'updatedUser.spokenLanguages': {
    presence: false,
  },
  'updatedUser.zipCode': {
    presence: false,
  },
  'updatedUser.state': {
    presence: false,
  },
  'updatedUser.city': {
    presence: false,
  },
  'updatedUser.birthDay': {
    presence: false,
  },
  'updatedUser.birthMonth': {
    presence: false,
  },
  'updatedUser.birthYear': {
    presence: false,
  },
  'updatedUser.timezone': {
    presence: false,
  },
}

export default (data) => {
  const errs = validate(data, constraints)
  return errs
}
