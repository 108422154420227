import React from 'react'
import {
  Modal,
  IconClose,
  Button,
  useWindowDimensions,
  IconPayments,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import {ModalHeader} from 'reactstrap'

const ZelleModal = (props) => {
  const {isOpen, toggle, recipient, primary = false} = props
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()
  return (
    <span>
      <div onClick={toggle}>{props.children}</div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader close={<IconClose size={20} onClick={toggle} />}>
            <IconPayments size={breakWidth === 'SM' ? 48 : 70} color />
          </ModalHeader>
        }
      >
        <div className='d-flex flex-column m-3'>
          <h2 className='htext mt-0 mb-3'>Zelle</h2>
          <span className='w-100 h4 mb-5 mt-0 text-muted'> Submit your payment to the info below: </span>
          <div
            className='d-flex flex-column h45 p-3 mb-5 text-gray'
            style={{
              backgroundColor: '#F0F4F8',
              borderRadius: '10px',
            }}
          >
            <div className='d-flex flex-row'>
              ​
              <b className='w-50'>
                {`${translate(
                  `profile.actions.addManualPaymentMethod.data.recipient`,
                )}`}
                :
              </b>
              <span className='w-50 d-flex flex-row justify-content-end'>
                {recipient}
              </span>
            </div>
          </div>
          <Button
            label='Confirm'
            style={{
              backgroundColor: primary ? '' : '#FFB519',
              border: 'none',
              width: '100%',
            }}
            color={primary ? 'primary' : null}
            onClick={toggle}
            activity={false}
          />
        </div>
      </Modal>
    </span>
  )
}

ZelleModal.propTypes = {}

export default ZelleModal
