import React, {useEffect, useState} from 'react'
import {
  ListItem,
  IconClose,
  useWindowDimensions,
  Modal,
  AvailabilityCalendar,
  IconCountry,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'
import BulkUpdateMyAvailability from '../../../../../../Model/CalendarEvent/BulkUpdateMyAvailability'
import ChangeTimezoneModal from '../../ChangeTimezoneModal'
import useTranslation from '../../../../../../i18n/useTranslation'
import {useLazyQuery, gql} from '@apollo/client'
import {showAlert} from '../../../../../../common/Alert/util'

const SetAvailable = () => {
  const isOpen = useSelector((state) => state.modal.profile.available)
  const {timezone, id} = useSelector((state) => state.user.info)
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    // reinicio
    setSelectedBlocks([])
    setUnavailableBlocks([])
    refetch(now, id)
    setNow(moment().toISOString())
    dispatch({type: 'TOGGLE_MODAL_ACTION', payload: 'profile.available'})
  }
  const {breakWidth} = useWindowDimensions()
  const [now, setNow] = useState(moment().toISOString())
  // cambiar query por alguna que obtenga las lecciones de mi usuario como educador

  const [calendarViewQuery, {data, loading: loadCalendarView}] = useLazyQuery(
    gql`query(
            $kinds: [String!]
            $userId: ID
            $from: DateTime!
            $to: DateTime!
          ) {
      calendarView(
          kinds: $kinds
          userId: $userId,
          from: $from,
          to: $to
      ) {  
          ${queryLessonResult}
          ... on Counselor {
            id
            startDate
            endDate
            counselorId
            CounselorCreated : createdAt
            CounselorUpdate : updatedAt
          }
          ... on EducatorUnavailability {
            id
            kind
            startDate
            endDate
            busy
            educatorId
            userIdsInvolved
          }
      }
    }`,
    {
      fetchPolicy: 'no-cache',
    }
  )
  const refetch = (now, id) => {
    if (now && id) {
      calendarViewQuery({
        variables: {
          kinds: [
            'educator-unavailability',
            'individual-lesson',
            'plan-lesson',
            'group-lesson',
            'counselor',
          ],
          userId: id,
          from: moment(now).startOf('isoWeek').toISOString(),
          to: moment(now).endOf('isoWeek').toISOString(),
        },
      })
    }
  }
  useEffect(() => {
    if (isOpen && now && id) refetch(now, id)
    // eslint-disable-next-line
  }, [now, id, isOpen])
  const parseAvailability = (items) => {
            return items?.filter(
                (lesson) =>
                  ![
                    'canceled',
                    'draft',
                    'finished',
                    'pending',
                    'draftPending',
                    'occurrence',
                    'paused',
                  ].includes(lesson.status),
              ) || []

  }

  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [unavailableBlocks, setUnavailableBlocks] = useState([])
  const {submit: bulkUpdate, loading: loadSave} = BulkUpdateMyAvailability(
    () => {
      showAlert(
        {
          text: translate('profile.actions.availability.temporalSuccessAlert'),
          status: 'success',
        },
        dispatch,
      )
      setSelectedBlocks([])
      setUnavailableBlocks([])
      refetch(now, id)
    },
    () => {
      showAlert(
        {
          text: translate('profile.actions.availability.temporalErrorAlert'),
          status: 'error',
        },
        dispatch,
      )
    },
  )

  const parseToMutation = () => {
    return {
      blocks: selectedBlocks // nuevos bloques disponibles
        .map((block) => {
          return {
            time: block,
            isAdding: false,
          }
        })
        .concat(
          unavailableBlocks.map((block) => {
            // nuevos bloques no disponibles
            return {
              time: block,
              isAdding: true,
            }
          }),
        ),
    }
  }
  return (
    <>
      <ListItem
        id='available'
        className='m-0 py-3 px-6 w-100 font-weight-light'
        showTopLine={false}
        showBottomLine
        hasHover
        hasColorHover
        onClick={toggle}
        Left={translate('profile.actions.availability.temporal')}
      />
      <Modal
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
        backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader
            toggle={toggle}
            close={<IconClose size={20} onClick={toggle} />}
          >
            <span className='h2 font-weight-bold'>
              {translate('profile.actions.availability.temporalTitle')}
            </span>
          </ModalHeader>
        }
      >
        <div className='p-0 my-0 mx-7'>
          <AvailabilityCalendar
            loading={loadCalendarView}
            setMoment={(newMoment) => {
              setNow(newMoment.toISOString())
            }}
            moment={moment(now)}
            lessons={
              parseAvailability(data?.calendarView)
            }
            setSelectedBlocks={setSelectedBlocks}
            selectedBlocks={selectedBlocks}
            setUnavailableBlocks={setUnavailableBlocks}
            unavailableBlocks={unavailableBlocks}
            loadSave={loadSave}
            saveLabel={translate('profile.actions.save')}
            onSave={() => {
              bulkUpdate({
                recurrentAvailability: parseToMutation(),
              })
            }}
            zone={timezone}
            changeTimezone={
              <ChangeTimezoneModal timezone={timezone}>
                <ListItem
                  showTopLine
                  showBottomLine
                  Left={
                    <div className='m-0 p-0 d-flex flex-row align-items-center'>
                      <IconCountry className='mr-2' />
                      <span>{timezone}</span>
                    </div>
                  }
                  Right={
                    <div className='m-0 p-0 d-flex flex-row align-items-center'>
                      <div className='hover-icon px-2 py-1'>
                        <IconArrowRight />
                      </div>
                    </div>
                  }
                />
              </ChangeTimezoneModal>
            }
          />
        </div>
      </Modal>
    </>
  )
}
export default SetAvailable
