import scoreCollectionQueryResult from './useScoresCollectionResult'
// String con modelo de objeto que será utilizado para
// obtener una Task de un estudiante.
const userTaskQueryResult = `
id
idReference
name
position
instructions
type
dueTime
buttonLabel
isEspecific
startDate
dueDate
reviewDate
completedDate
canceledDate
linkToShow
selectedExam {
    _id
    name
    serviceId
    results {
        name
        scale
        values {
            name
            scale
        }
    }
}
accountsPlatform
universities
status
userId
resolveTask {
    link {
        lastProcessedAt
        accessedCount
    }
    loginInfo {
        platform{
            name
            username
            password
        }
    }
    ${scoreCollectionQueryResult}
    meeting
    uploadFiles {
        url
        uploadDate
    }
    completion
}
isReviewable
showInDocs
uploadDocs
deleteAt
userMeetingId
`

export default userTaskQueryResult
