import clone from 'lodash/clone'
import getLocale from './getLocale'
import defaultStrings from './strings'
import {useDispatch, useSelector} from 'react-redux'
import {useCallback} from 'react'
import {TRANSLATION_LANG_SET_ACTION} from '../store/translation/const'

const getString = function (key, lang, strings) {
  const parts = key.split('.')
  let data = clone(strings) || clone(defaultStrings)
  for (let i = 0; i < parts.length; i++) {
    const currentKey = parts[i]
    if (i === parts.length - 1) {
      const final = data[lang]
      if (!final) return null
      return final[currentKey]
    }
    data = data[currentKey]
    if (!data) return null
  }
  return null
}
const generateStringTemplate = function (string) {
  const sanitized = string
    .replace(
      /\$\{([\s]*[^\s]+[\s]*)\}/g,
      (_, match) => `\${map.${match.trim()}}`,
    )
    .replace(/(\$\{(?!map\.)[^}]+\})/g, '')

  // eslint-disable-next-line
  const fn = Function('map', `return \`${sanitized}\``)
  return fn
}
const evaluateString = function (string, params) {
  if (typeof string === 'function') {
    return string(params)
  }
  const template = generateStringTemplate(string)
  return template(params)
}

export default () => {
  // Use redux state values
  const strings = useSelector((state) => state.translation.strings)
  const selectedLang = useSelector((state) => state.translation.selectedLang)
  // set default languages
  const dispatch = useDispatch()
  const setDefaultLang = useCallback(
    (lang) => dispatch({type: TRANSLATION_LANG_SET_ACTION, payload: lang}),
    [dispatch],
  )
  // define translation function
  const translate = useCallback(
    (key, params, lang) => {
      if (!key) return ''
      lang = lang || selectedLang
      if (lang === 'keys') {
        return key
      }
      let string = getString(key, lang, strings)
      if (!string) string = getString(key, getLocale())
      if (!string) {
        // eslint-disable-next-line
        console.warn(`No translation for "${key}" in lang "${lang}"`)
        return key
      }
      try {
        return evaluateString(string, params)
      } catch (e) {
        if (params) {
          // eslint-disable-next-line
          console.log(
            `Error translating "${key}" in lang "${lang}" with params`,
            params,
            e,
          )
        } else {
          // eslint-disable-next-line
          console.log(`Error translating "${key}" in lang "${lang}"`, e)
        }

        return key
      }
    },
    [strings, selectedLang],
  )

  const setVariables = (str, vars = {}) => {
    let finalString = str
    const arrayObj = Object.entries(vars)
    for (let i = 0; i < arrayObj.length; i++) {
      const [key, value] = arrayObj[i]
      finalString = finalString.split(`[${key}]`).join(value)
    }
    return finalString
  }
  return {translate, setDefaultLang, selectedLang, setVariables}
}
