import React, {Fragment, useMemo} from 'react'
import {
  useWindowDimensions,
  PlanCard,
  LessonCard,
  InvoiceCard,
  Notification,
  LessonShortCard,
  Action,
  ScheduledMeet,
  IconProgram,
  IconEmail,
  PlanCounselingCard,
  Avatar,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import './index.css'
import {Col, Container, Row, TabContent, TabPane} from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../i18n/useTranslation'
import ReschedulePendingLesson from '../Calendar/Lessons/Modal/ReschedulePendingLesson'
import moment from 'moment'
import {SET_DATA_MODAL_FORM_LIST} from '../../store/modal/const'
import {useHistory} from 'react-router'
import InfoModalLesson from '../Calendar/Lessons/Modal/InfoModal/index.js'
// import OptionsButton from '../Calendar/Lessons/Modal/Options'
import useModalState from '../../hooks/useModalState'
import PostponeLesson from '../CalendarLessons/modal/PostponeLesson'
import InfoLesson from '../CalendarLessons/modal/InfoLesson'
import InvoiceModal from '../Profile/Pyments/InvoiceDetails/Modal'
import PendingPaymentsModal from '../Profile/Pyments/PendingPayments'
import planKind from '../../common/Enums/planKind'
import MyCounselingPlan from '../../Model/Users/Plans/MyCounselingPlan'
import useViewPlanModal from '../MyPlans/viewCounselingPlanModal/useViewPlanModal'
import useCounselingPlanReducer from '../../reducers/counseling-plan'
import useShowTask from '../Workspace/Student/ViewTask/useShowTask'
import InfoModal from '../Calendar/Lessons/Modal/InfoModal/index.js'
import YoutubeGidget from './Components/YoutubeGidget'
import InstagramWidget from './Components/InstagramWidget'
import OrientationModal from '../Calendar/Lessons/Modal/OrientationModal'
const HomeView = () => {
  const {breakWidth} = useWindowDimensions()
  const info = useSelector((state) => state.user.info)
  const refetch = useSelector((state) => state.user.refetch)
  const {onChange} = useModalState('program.newManualLesson')
  const {onChange: onChangeInvoice} = useModalState('payments.viewInvoice')
  const isOpenOrientationModal = useSelector(
    (state) => state.modal?.student?.orientationLessonModal?.isOpen,
  )
  const openNewLessonModal = () => {
    onChange(() => ({
      serviceId: null,
      topicId: null,
      educator: null,
      dateTime: null,
      stage: 0,
    }))
  }
  const history = useHistory()
  const Roles = [].concat(info.roles)
  const {open: openTask} = useShowTask()
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {parsePlanFromAPI} = useCounselingPlanReducer()
  const {open: openViewPlanModal} = useViewPlanModal()
  const homeNotification =
    info?.studentInfo?.homeNotification ||
    info?.parentInfo?.homeChildrensNotifications ||
    undefined

  const alerts = useMemo(() => {
    if (Roles.filter((e) => e === 'STUDENT').length > 0) {
      if (homeNotification) {
        return {
          witouth: !(
            homeNotification.lessonsPending.length ||
            homeNotification.invoicesPending.length ||
            homeNotification.approvalPlans.length ||
            moment(homeNotification.upcomingCounselingPlan?.from).format() >
              moment().format()
          ),
          multiple:
            (homeNotification.lessonsPending.length ? 1 : 0) +
            (homeNotification.invoicesPending.length ? 1 : 0) +
            (homeNotification.approvalPlans.length ? 1 : 0) +
            (moment(homeNotification.upcomingCounselingPlan?.from).format() >
            moment().format()
              ? 1
              : 0),
          howMuch:
            (homeNotification.lessonsPending.length
              ? homeNotification.lessonsPending.length
              : 0) +
            (homeNotification.invoicesPending.length
              ? homeNotification.invoicesPending.length
              : 0) +
            (homeNotification.approvalPlans.length
              ? homeNotification.approvalPlans.length
              : 0) +
            (moment(homeNotification.upcomingCounselingPlan?.from).format() >
            moment().format()
              ? 1
              : 0),
          lessons: homeNotification.lessonsPending.length,
          payments: homeNotification.invoicesPending.length,
          plans: homeNotification.approvalPlans.length,
          isStudent: true,
          upcomingCounselingPlan:
            moment(homeNotification.upcomingCounselingPlan?.from).format() >
              moment().format() &&
            moment(homeNotification.upcomingCounselingPlan?.from).fromNow() < 15
              ? homeNotification.upcomingCounselingPlan
              : null,
        }
      }
      return {
        witouth: false,
        multiple: 0,
        howMuch: 0,
        lessons: 0,
        payments: 0,
        plans: 0,
        isStudent: true,
      }
    } else if (Roles.filter((e) => e === 'EDUCATOR').length > 0) {
      return {
        witouth: info.educatorInfo.pendingLessons.length === 0,
        multiple: 0,
        howMuch: 0,
        lessonspending: info.educatorInfo.pendingLessons.length,
        payments: 0,
        plans: 0,
        isStudent: false,
      }
    } else if (Roles.filter((e) => e === 'PARENT').length > 0) {
      if (
        Array.isArray(homeNotification) &&
        homeNotification.some(
          (notifications) => notifications.approvalPlans.length > 0,
        )
      ) {
        return homeNotification.map((notifications) => {
          return {
            witouth: !(
              notifications.lessonsPending.length ||
              notifications.invoicesPending.length ||
              notifications.approvalPlans.length ||
              moment(notifications.upcomingCounselingPlan?.from).format() >
                moment().format()
            ),
            multiple:
              (notifications.lessonsPending.length ? 1 : 0) +
              (notifications.invoicesPending.length ? 1 : 0) +
              (notifications.approvalPlans.length ? 1 : 0) +
              (moment(notifications.upcomingCounselingPlan?.from).format() >
              moment().format()
                ? 1
                : 0),
            howMuch:
              (notifications.lessonsPending.length
                ? notifications.lessonsPending.length
                : 0) +
              (notifications.invoicesPending.length
                ? notifications.invoicesPending.length
                : 0) +
              (notifications.approvalPlans.length
                ? notifications.approvalPlans.length
                : 0) +
              (moment(notifications.upcomingCounselingPlan?.from).format() >
              moment().format()
                ? 1
                : 0),
            lessons: notifications.lessonsPending.length,
            payments: notifications.invoicesPending.length,
            plans: notifications.approvalPlans.length,
            isParent: true,
            upcomingCounselingPlan:
              moment(notifications.upcomingCounselingPlan?.from).format() >
                moment().format() &&
              moment(notifications.upcomingCounselingPlan?.from).fromNow() < 15
                ? notifications.upcomingCounselingPlan
                : null,
          }
        })
      }
      return {
        witouth: false,
        multiple: 0,
        howMuch: 0,
        lessons: 0,
        payments: 0,
        plans: 0,
        isParent: true,
      }
    }
    return {
      witouth: false,
      multiple: 0,
      howMuch: 0,
      lessons: 0,
      payments: 0,
      plans: 0,
      isStudent: false,
    }
    // eslint-disable-next-line
  }, [homeNotification, Roles])
  const dataCard = useMemo(() => {
    if (info && info?.studentInfo?.upcomingLesson) {
      const {
        status,
        startDate,
        endDate,
        id,
        student,
        studentPlan,
        studentGroup,
      } = info.studentInfo.upcomingLesson
      const currentStudent = student || studentPlan || studentGroup || {}
      return {
        ...info.studentInfo.upcomingLesson,
        status:
          status === 'finished'
            ? currentStudent?.attendanceStatus === 'attended'
              ? 'attended'
              : 'missed'
            : status === 'scheduled'
            ? moment(startDate) < moment()
              ? moment(endDate) > moment()
                ? 'upcoming' // 'inprogress'
                : 'incomplete'
              : info.studentInfo.upcomingLesson.id === id
              ? 'upcoming'
              : 'scheduled'
            : status,
      }
    }
    return {}
  }, [info])
  const mailto = translate('home.emilto')
  const planId = info?.studentInfo?.homeNotification?.councelingPlanId
  const {
    loading: loadingCounselingPlan,
    data: dataCounselingPlan,
  } = MyCounselingPlan({planId})

  const openPlanOrOnboarding = () => {
    const onboarding = homeNotification?.approvalPlans.filter(
      (plan) => plan._id === alerts.upcomingCounselingPlan._id,
    )
    if (onboarding[0]) {
      history.push(
        `/counseling-onboarding/${alerts.upcomingCounselingPlan?._id}`,
      )
    } else {
      openViewPlanModal(() => {}, parsePlanFromAPI(dataCounselingPlan))
    }
  }
  const NotificationZone = () => {
    return (
      <div
        className={`${
          breakWidth === 'XL'
            ? 'w-35 sideBarHome pt-5 px-4'
            : 'w-100 sideBarHomeXs pt-5 pb-4'
        } hidde-scroll-vertical`}
        style={
          breakWidth === 'XL'
            ? {
                backgroundColor: 'white',
                WebkitBoxShadow: 'inset 0px -3px 32px 2px rgba(67,86,100,0.1)',
                MozBoxShadow: 'inset 0px -3px 32px 2px rgba(67,86,100,0.1)',
                boxShadow: 'inset 0px -3px 32px 2px rgba(67,86,100,0.1)',
              }
            : {}
        }
      >
        {/* Student Notifications */}
        {info && alerts.witouth && alerts.isStudent && (
          <Notification
            label={translate('home.withoutnotifications')}
            number={0}
            className='mb-3'
          />
        )}
        {/* {info && alerts.multiple > 1 && alerts.isStudent && (
          <Notification
            label={translate('home.newnotifications')}
            number={alerts.howMuch}
            className='mb-3'
          />
        )} */}
        {info && alerts.isStudent && (
          <Fragment>
            {alerts.plans > 0 ? (
              homeNotification?.approvalPlans.map((plan, index) => {
                return (
                  <Notification
                    label={translate('home.planapprove')}
                    number={index + 1}
                    className='mb-3'
                    onClick={() => {
                      history.push(`/onboarding/${plan._id}`)
                    }}
                  />
                )
              })
            ) : alerts.payments > 0 ? (
              <Notification
                label={translate('home.pedingpayments')}
                number={-1}
                className='mb-3'
                onClick={() => {
                  dispatch({
                    type: SET_DATA_MODAL_FORM_LIST,
                    payload: 'profile.pendingPayments',
                    data: {
                      isOpen: true,
                      invoices:
                        info.studentInfo.homeNotification.invoicesPending,
                    },
                  })
                }}
              />
            ) : alerts.lessons > 0 ? (
              <Notification
                label={translate('home.pedinglessons')}
                number={1}
                className='mb-3'
                onClick={() => {
                  dispatch({
                    type: SET_DATA_MODAL_FORM_LIST,
                    payload: 'program.rescheduledModal',
                    data: {
                      isOpen: true,
                      stage: 'showPendingLesson',
                      refetchLessons: refetch,
                    },
                  })
                }}
              />
            ) : alerts.upcomingCounselingPlan ? (
              <Notification
                label={`Your Counseling Plan starts ${moment(
                  alerts.upcomingCounselingPlan?.from,
                ).fromNow()}`}
                number={1}
                className='mb-3'
                onClick={() => {
                  openPlanOrOnboarding()
                }}
              />
            ) : null}
          </Fragment>
        )}
        {info &&
          Array.isArray(alerts) &&
          alerts.length > 0 &&
          alerts.some((alert) => alert.plans > 0 && alert.isParent) && (
            <Notification
              label={translate('home.planapprove')}
              number={alerts.reduce((total, alert) => total + alert.plans, 0)}
              className='mb-3'
              onClick={() => {
                const planIds = homeNotification.flatMap(({approvalPlans}) => {
                  return approvalPlans.map(({_id, kind}) => {
                    return {
                      _id,
                      kind,
                    }
                  })
                })
                const idPlanToSelect = Math.floor(
                  Math.random() * planIds.length,
                )
                history.push(`/onboarding/${planIds[idPlanToSelect]._id}`)
                if (planIds[idPlanToSelect].kind === 'counceling') {
                  history.push(
                    `/counseling-onboarding/${planIds[idPlanToSelect]._id}`,
                  )
                }
              }}
            />
          )}
        <TabContent activeTab={loadingCounselingPlan ? `loading` : `show`}>
          <TabPane tabId='loading'>
            <Skeleton count={1} height={100} />
          </TabPane>
          <TabPane tabId='show'>
            {info &&
              alerts.isStudent &&
              homeNotification?.councelingPlanId &&
              dataCounselingPlan && (
                <div className='mt-5'>
                  <span className='h5 text-muted mb-2'>
                    {translate('home.inProgres').toUpperCase()}
                  </span>
                  <div
                    onClick={() => {
                      openViewPlanModal(() => {},
                      parsePlanFromAPI(dataCounselingPlan))
                    }}
                  >
                    <PlanCounselingCard
                      // className='border-0'
                      styleCard={{cursor: 'pointer'}}
                      // styleCard={{
                      //   background: '#32405b',
                      //   color: 'white',
                      // }}
                      title={dataCounselingPlan?.name}
                      subtitle={`${
                        dataCounselingPlan?.type
                          ? dataCounselingPlan.type
                              .substring(0, 1)
                              .toUpperCase() +
                            dataCounselingPlan.type.substring(
                              1,
                              dataCounselingPlan.type.length,
                            )
                          : 'Type'
                      } | ${
                        dataCounselingPlan?.aditionalInfo?.studentCountryName ||
                        'Country'
                      } | Until ${moment(dataCounselingPlan?.to).format(
                        'MMM DD, YYYY',
                      )}`}
                      text=''
                      status='scheduled'
                      // onClick={() => {
                      //   alert('hice click')
                      //   openViewPlanModal(() => { }, dataCounselingPlan)
                      //   // history.push(
                      //   //   `/counselingPlan/${dataCounselingPlan?._id}`,
                      //   // )
                      // }}
                    />
                  </div>
                </div>
              )}
          </TabPane>
        </TabContent>
        {/* <div className='d-flex flex-column'>
          {livePrepScheduledPlans?.length > 0 &&
            livePrepScheduledPlans.map(({_id}) => {
              return <LivePrepPlanModal planId={_id} />
            })}
        </div> */}
        {info && alerts.plans > 0 && alerts.isStudent && (
          <div className='mt-5'>
            <span className='h5 text-muted my-2'>
              {translate('home.newapproval').toUpperCase()}
            </span>
            {homeNotification?.approvalPlans.map((plan) => {
              if (plan?.kind === planKind.counseling) {
                return (
                  <PlanCounselingCard
                    styleCard={{cursor: 'pointer'}}
                    // styleCard={{
                    //   backgroundColor: 'white',
                    //   color: '#8c8eff',
                    // }}
                    title={plan?.name}
                    subtitle={`${
                      homeNotification?.councelingPlanType
                        ? homeNotification.councelingPlanType
                            .substring(0, 1)
                            .toUpperCase() +
                          homeNotification.councelingPlanType.substring(
                            1,
                            homeNotification.councelingPlanType.length,
                          )
                        : 'Type'
                    } | ${
                      homeNotification?.countryName || 'Country'
                    } | Until ${moment(plan?.to).format('MMM DD, YYYY')}`}
                    // subtitle={`${moment(plan?.from).format(
                    //   'MMM DD, YYYY',
                    // )} to ${moment(plan?.to).format('MMM DD, YYYY')}`}
                    text=''
                    status='blank'
                    onClick={() => {
                      history.push(`/counseling-onboarding/${plan?._id}`)
                    }}
                  />
                )
              } else {
                return (
                  <PlanCard
                    translate={translate}
                    name={plan?.name}
                    style={{cursor: 'pointer'}}
                    status='sent'
                    className='mt-2'
                    styleCard={{minWidth: '90%'}}
                    startTime={moment(plan?.from)}
                    endTime={moment(plan?.to)}
                    lessonsTotal={plan?.totalLessons}
                    isTimelessPlan={plan?.isFixed}
                    isLessonPerWeek={!plan?.isTotalLesson}
                    onClick={() => {
                      history.push(`/onboarding/${plan?._id}`)
                    }}
                  />
                )
              }
            })}
          </div>
        )}
        {info &&
          Array.isArray(alerts) &&
          alerts.length > 0 &&
          alerts.some((alerts) => alerts.plans > 0) && (
            <div className='mt-5'>
              <span className='h5 text-muted my-2'>
                {translate('home.newapproval').toUpperCase()}
              </span>
              {(() => {
                const plansToApprove = []
                const uniquePlansToApprove = new Set()
                homeNotification.map(
                  ({approvalPlans, councelingPlanType, countryName}) => {
                    approvalPlans.forEach((plan) => {
                      if (plan.kind === planKind.counseling) {
                        if (!uniquePlansToApprove.has(plan._id)) {
                          uniquePlansToApprove.add(plan._id)
                          plansToApprove.push({
                            _id: plan._id,
                            name: plan?.name,
                            from: plan?.from,
                            to: plan?.to,
                            totalLessons: plan?.totalLessons,
                            isFixed: plan?.isFixed,
                            isTotalLesson: plan?.isTotalLesson,
                            countryName: countryName,
                            councelingPlanType: councelingPlanType,
                            isCouseling: true,
                            assignedTo: plan?.assignedTo,
                          })
                        }
                      }
                      if (!uniquePlansToApprove.has(plan._id)) {
                        uniquePlansToApprove.add(plan._id)
                        plansToApprove.push({
                          _id: plan._id,
                          name: plan?.name,
                          from: plan?.from,
                          to: plan?.to,
                          totalLessons: plan?.totalLessons,
                          isFixed: plan?.isFixed,
                          isTotalLesson: plan?.isTotalLesson,
                          assignedTo: plan?.assignedTo,
                        })
                      }
                    })
                    return plansToApprove
                  },
                )
                if (plansToApprove.length === 0) return null
                return plansToApprove.map((plan) => {
                  if (plan?.isCouseling) {
                    return (
                      <PlanCounselingCard
                        styleCard={{cursor: 'pointer'}}
                        // styleCard={{
                        //   backgroundColor: 'white',
                        //   color: '#8c8eff',
                        // }}
                        // title={`${plan?.name} | ${plan?.assignedTo}`}
                        title={
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '10px',
                            }}
                          >
                            {plan?.name} | &nbsp;
                            <Avatar
                              name={plan?.assignedTo}
                              named={false}
                              uri={null}
                              size='avatar-small mr-1'
                            />
                          </div>
                        }
                        subtitle={`${
                          plan?.councelingPlanType
                            ? plan.councelingPlanType
                                .substring(0, 1)
                                .toUpperCase() +
                              plan.councelingPlanType.substring(
                                1,
                                plan.councelingPlanType.length,
                              )
                            : 'Type'
                        } | ${plan?.countryName || 'Country'} | Until ${moment(
                          plan?.to,
                        ).format('MMM DD, YYYY')}`}
                        text=''
                        status='blank'
                        onClick={() => {
                          history.push(`/counseling-onboarding/${plan?._id}`)
                        }}
                      />
                    )
                  } else {
                    return (
                      <PlanCard
                        translate={translate}
                        name={
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginRight: '10px',
                            }}
                          >
                            {plan?.name} | &nbsp;
                            <Avatar
                              name={plan?.assignedTo}
                              named={false}
                              uri={null}
                              size='avatar-small mr-1'
                            />
                          </div>
                        }
                        style={{cursor: 'pointer'}}
                        status='sent'
                        className='mt-2'
                        styleCard={{minWidth: '90%'}}
                        startTime={moment(plan?.from)}
                        endTime={moment(plan?.to)}
                        lessonsTotal={plan?.totalLessons}
                        isTimelessPlan={plan?.isFixed}
                        isLessonPerWeek={!plan?.isTotalLesson}
                        assignedTo={plan?.assignedTo}
                        onClick={() => {
                          history.push(`/onboarding/${plan?._id}`)
                        }}
                      />
                    )
                  }
                })
              })()}
            </div>
          )}
        {info && alerts.lessons > 0 && alerts.isStudent && (
          <div className='mt-5'>
            <span className='h5 text-muted mb-2'>
              {translate('home.lessonstoschedule').toUpperCase()}
            </span>
            {homeNotification.lessonsPending.map((lesson, index) =>
              index <= 1 ? (
                <LessonCard
                  key={lesson.id}
                  title={lesson.title}
                  status='pending'
                  preLabelTime={null}
                  lessonTime={moment(lesson.startDate)}
                  lessonEndTime={moment(lesson.endDate)}
                  attendants={[
                    {
                      name: `${lesson?.educator?.firstName} ${lesson?.educator?.lastName}`,
                      avatar_url: null,
                    },
                  ]}
                  showCountdown={false}
                  className='w-100 m-1 mt-2'
                  onClick={() => {
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'program.rescheduledModal',
                      data: {
                        isOpen: true,
                        stage: 'showPendingLesson',
                        refetchLessons: refetch,
                      },
                    })
                  }}
                />
              ) : null,
            )}
          </div>
        )}
        {info && alerts.payments > 0 && alerts.isStudent && (
          <div className='my-5'>
            <span className='h5 text-muted mb-2'>
              {translate('home.pedingpayments').toUpperCase()}
            </span>
            {homeNotification.invoicesPending
              .slice(0, 1)
              .map((payment, index) => (
                <div
                  onClick={() =>
                    onChangeInvoice(() => ({invoiceId: payment.id}))
                  }
                >
                  <InvoiceCard
                    className='mt-3'
                    key={payment.id}
                    type='warn'
                    text={`${translate(
                      'student.modal.payments.endOf',
                    )} ${moment(payment.to).format('MMMM')}`}
                    textTop='Next pay:'
                    typePayment={
                      paymentName[info.paymentMethod?.__typename]?.name
                    }
                    amount={payment.amount}
                    invoice={`INV-${payment.invoiceNumber}`}
                    empty={false}
                    emptyText=''
                  />
                </div>
              ))}
          </div>
        )}
        {info && alerts.isStudent && (
          <Notification
            label={translate('home.gocoursematerials')}
            number={0}
            className='znk-go mb-3'
            type='go'
            onClick={() => {
              window.open(info.studentInfo.materials)
            }}
          />
        )}

        {/* Educator Notifications */}
        {info && !alerts.isStudent && alerts.witouth && (
          <Notification
            label={translate('home.withoutnotifications')}
            number={0}
            className='mb-3'
          />
        )}
        {info && !alerts.isStudent && alerts.lessonspending > 0 && (
          <Notification
            label={translate('home.incompletelessons')}
            number={1}
            className='mb-3'
          />
        )}
        {info &&
          !alerts.isStudent &&
          (info?.educatorInfo?.pendingLessons || []).map((lesson, index) =>
            index <= 1 ? (
              <LessonCard
                key={lesson.id}
                title={lesson.title}
                status='incompleteLesson'
                preLabelTime={null}
                lessonTime={moment(lesson.startDate)}
                lessonEndTime={moment(lesson.endDate)}
                attendants={
                  lesson.kind !== 'group-lesson'
                    ? [
                        {
                          name: `${lesson?.student?.firstName} ${lesson?.student?.lastName}`,
                          avatar_url: null,
                        },
                      ]
                    : lesson?.students.map((item) => ({
                        name: `${item?.firstName} ${item?.lastName}`,
                        avatar_url: null,
                      }))
                }
                showCountdown={false}
                className='w-100 m-1 mt-2'
                onClick={() => {
                  dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'calendar.infoLessonModal',
                    data: {
                      isOpen: true,
                      dataLesson: {
                        ...lesson,
                        showInCenter: false,
                        // redirectToListAfter: true,
                      },
                    },
                  })
                }}
              />
            ) : null,
          )}
        <Container className='p-0' fluid>
          {alerts.isStudent === true && (
            <Row>
              <Col className='pr-1'>
                {info && info.studentInfo.upcomingLesson ? (
                  <LessonShortCard
                    status='upcoming'
                    className='mt-3'
                    iconSide='left'
                    type='lesson'
                    style={{}}
                    pending
                    name={info.studentInfo.upcomingLesson.title}
                    lessonTime={moment(
                      info.studentInfo.upcomingLesson.startDate,
                    ).clone()}
                    title={translate('home.upcominglesson')}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: `student.${
                          info?.studentInfo?.upcomingLesson?.service?.name ===
                          'Orientation'
                            ? 'orientationLessonModal'
                            : 'infoLessonModal'
                        }`,
                        data: {
                          isOpen: true,
                          idLesson: dataCard.id,
                          // isOrientation: info?.studentInfo?.upcomingLesson?.service?.name === "Orientation" ? true : false,
                        },
                      })
                    }}
                  />
                ) : (
                  <LessonShortCard
                    status='completed'
                    className='mt-3'
                    iconSide='left'
                    type='lesson'
                    style={{}}
                    pending={false}
                    lessonTime={moment(
                      info.studentInfo?.nextLesson?.startDate,
                    ).clone()}
                    title={translate('home.nonextcominglesson')}
                  />
                )}
              </Col>
              <Col className='pl-1'>
                {info.studentInfo?.homeNotification?.tasksPending?.length >
                0 ? (
                  <LessonShortCard
                    status={
                      moment(
                        info.studentInfo?.homeNotification?.tasksPending[0]
                          .dueDate,
                      )
                        .clone()
                        .valueOf() > moment().clone().valueOf()
                        ? 'upcoming'
                        : 'due'
                    }
                    className='mt-3'
                    iconSide='left'
                    type='task'
                    name={
                      info.studentInfo.homeNotification.tasksPending[0].title
                    }
                    pending={
                      info.studentInfo?.homeNotification?.tasksPending?.length >
                      0
                    }
                    style={{}}
                    lessonTime={moment(
                      info.studentInfo?.homeNotification?.tasksPending[0]
                        .dueDate,
                    ).clone()}
                    title={translate(
                      moment(
                        info.studentInfo?.homeNotification?.tasksPending[0]
                          .dueDate,
                      )
                        .clone()
                        .valueOf() > moment().clone().valueOf()
                        ? 'home.nextcomingtask'
                        : 'home.duecomingtask',
                    )}
                    onClick={() => {
                      const task = JSON.parse(
                        info.studentInfo?.homeNotification?.tasksPending[0]
                          .task,
                      )
                      openTask(() => {}, {...task, __typename: 'UserTasksOut'})
                    }}
                  />
                ) : (
                  <LessonShortCard
                    status='completed'
                    className='mt-3'
                    type='task'
                    pending={false}
                    style={{}}
                    title={translate('home.nonextcomingtask')}
                  />
                )}
              </Col>
            </Row>
          )}
          {alerts.isStudent === false && (
            <Row>
              <Col className='pr-1'>
                {info && info.educatorInfo.upcomingLesson ? (
                  <LessonShortCard
                    status='upcoming'
                    className='mt-3'
                    iconSide='left'
                    type='lesson'
                    style={{}}
                    name={info.educatorInfo.upcomingLesson.title}
                    lessonTime={moment(
                      info.educatorInfo.upcomingLesson.startDate,
                    ).clone()}
                    title={translate('home.upcominglesson')}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'calendar.infoLessonModal',
                        data: {
                          isOpen: true,
                          dataLesson: {
                            ...info.educatorInfo.upcomingLesson,
                            showInCenter: false,
                          },
                        },
                      })
                    }}
                  />
                ) : (
                  <LessonShortCard
                    status='completed'
                    className='mt-3'
                    iconSide='left'
                    type='lesson'
                    pending={false}
                    style={{}}
                    lessonTime={moment(
                      info.educatorInfo?.nextLesson?.startDate,
                    ).clone()}
                    title={translate('home.nonextcominglesson')}
                  />
                )}
              </Col>
              <Col className='pl-1'>
                <LessonShortCard
                  status='completed'
                  className='mt-3'
                  type='lessonEdu'
                  style={{}}
                  title={
                    alerts.witouth
                      ? translate('home.alllessoncomplete')
                      : info?.educatorInfo?.pendingLessons?.length ||
                        0 + ' ' + translate('home.somelessonincomplete')
                  }
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ScheduledMeet
                icon={
                  alerts.isStudent === true ? (
                    <IconProgram size={16} className='mr-2' />
                  ) : (
                    <IconEmail size={16} className='mr-2' />
                  )
                }
                color='#F2F2FF'
                textColor='#7079FF'
                border=''
                title={
                  alerts.isStudent === true
                    ? translate('home.iamheare')
                    : translate('home.iamheareeducator')
                }
                name={
                  alerts.isStudent === true
                    ? translate('home.nameavatar')
                    : translate('home.nameavatareducator')
                }
                uri={
                  alerts.isStudent === true
                    ? translate('home.uriavatar') === ''
                      ? undefined
                      : translate('home.uriavatar')
                    : translate('home.uriavatareducator') === ''
                    ? undefined
                    : translate('home.uriavatareducator')
                }
                position={
                  alerts.isStudent === true
                    ? translate('home.position')
                    : translate('home.positioneducator')
                }
                textscheduled={
                  alerts.isStudent === true
                    ? translate('home.textscheduled')
                    : translate('home.textschedulededucator')
                }
                onClick={() => {
                  if (alerts.isStudent) {
                    window.open(translate('home.urlschedulemeet'), '_blank')
                  } else {
                    window.location.href = `mailto:${mailto}`
                  }
                }}
              />
            </Col>
          </Row>
          {alerts.isStudent === true && (
            <Row>
              <Col className='pb-5'>
                <Action
                  color='#F2F2FF'
                  textColor='#7079FF'
                  border=''
                  style={{marginTop: 10}}
                  labelLeft={
                    <div className='d-flex align-items-center'>
                      <IconProgram size={16} className='mr-2' />
                      {translate('home.newschedule')}
                    </div>
                  }
                  labelRight=''
                  action='primary'
                  onClick={() => openNewLessonModal()}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    )
  }
  const paymentName = {
    BankCreditPaymentMethod: {
      name: translate('student.modal.payments.credit'),
    },
    BankDebitPaymentMethod: {
      name: translate('student.modal.payments.debit'),
    },
    ManualPaymentMethod: {
      name: translate('student.modal.payments.manual'),
    },
    CardPaymentMethod: {
      name: translate('student.modal.payments.card'),
    },
    ZellePaymentMethod: {
      name: translate('student.modal.payments.zelle'),
    },
  }

  return (
    <>
      <div className='d-flex flex-row h-100'>
        <div
          className={`${
            breakWidth === 'XL' ? 'w-65' : 'w-100 p-4'
          } d-flex flex-column h-100 hidde-scroll-vertical`}
          style={{borderRadius: '16px'}}
        >
          {/* <div className='d-flex flex-row justify-content-between align-items-center px-4 pb-5 pt-0'>
            <span>Start</span>
            <Input className='w-25' placeholder='Buscar' />
          </div> */}
          <div className={`w-100 mt-5 ${breakWidth === 'XL' ? 'px-8' : ''}`}>
            {breakWidth !== 'XL' && <NotificationZone />}
            <Container
              className={`px-0 ${breakWidth === 'XL' ? '' : 'mt-3'}`}
              fluid
            >
              <Row>
                <Col>
                  {/* <a
                    href={translate('home.linkimgprincipal')}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={translate('home.imgprincipal')}
                      alt='test'
                      className='img-fluid'
                      style={{borderRadius: 16}}
                    />
                  </a> */}
                  {/* <div
                    className='tagembed-container'
                    style={{width: '100%', height: '100%', overflow: 'auto'}}
                  >
                    <iframe
                      title='home banner'
                      className='height-banner snapwidget-widget'
                      src='https://snapwidget.com/embed/1008801'
                      allowTransparency='true'
                      frameBorder='0'
                      scrolling='no'
                      style={{
                        border: 'none',
                        overflow: 'hidden',
                        width: '100%',
                        height: breakWidth === 'XL' ? '350px' : '120px',
                      }}
                    />
                    <script
                      src='//widget.tagembed.com/embed.min.js'
                      type='text/javascript'
                    />
                  </div> */}
                  <InstagramWidget />
                </Col>
              </Row>
              <Row>
                <Col>
                  <YoutubeGidget />
                </Col>
              </Row>
              <Row className='my-3'>
                <Col className='mt-4' xs='12' md='6'>
                  <a
                    href={translate('home.linktopleft')}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={translate('home.topleft')}
                      alt='test'
                      className='img-fluid'
                      style={{borderRadius: 16, maxHeight: 400}}
                    />
                  </a>
                </Col>
                <Col className='mt-4' xs='12' md='6'>
                  <a
                    href={translate('home.linktopright')}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={translate('home.topright')}
                      alt='test'
                      className='img-fluid'
                      style={{borderRadius: 16, maxHeight: 400}}
                    />
                  </a>
                </Col>
                <Col className='mt-4' xs='12' md='6'>
                  <a
                    href={translate('home.linkbottomleft')}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={translate('home.bottomleft')}
                      alt='test'
                      className='img-fluid'
                      style={{borderRadius: 16, maxHeight: 400}}
                    />
                  </a>
                </Col>
                <Col
                  className='mt-4 pb-5'
                  xs='12'
                  md='6'
                  style={{marginBottom: 100}}
                >
                  <a
                    href={translate('home.linkbottomright')}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src={translate('home.bottomright')}
                      alt='test'
                      className='img-fluid'
                      style={{borderRadius: 16, maxHeight: 300}}
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {breakWidth === 'XL' && <NotificationZone />}
      </div>
      <ReschedulePendingLesson />
      <InfoModalLesson refetchLessons={() => refetch} />
      {/* <OptionsButton /> */}
      <PostponeLesson />
      <InfoLesson refetchCalendarQuery={refetch} />
      <InvoiceModal refetch={refetch} />
      <PendingPaymentsModal />
      <OrientationModal
        role='student'
        dataLesson={info?.studentInfo?.upcomingLesson}
        onCancel={() => {}}
        isOpen={isOpenOrientationModal}
        toggle={() =>
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'student.orientationLessonModal',
            data: {
              isOpen: !isOpenOrientationModal,
              idLesson: info?.studentInfo?.upcomingLesson?.id,
            },
          })
        }
      />
      <InfoModal />
    </>
  )
}

HomeView.propTypes = {}
export default HomeView
