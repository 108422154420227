import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import * as moment from 'moment'
import {IconArrowRight, IconSignCheck} from '@Knowledge-OTP/znk-ui-components'
import useSetClickOnLink from '../../../../Task/Model/useSetClickOnLink'
import useAllUserTasks from '../../../../Task/Model/useAllUserTasks'
import ShowButtonByStatus from './showButtonsByStatus'
import SendToReviewButtonOff from './Buttons/sendToReviewOff'
import MessageModal from './Modals/messageModal'
import useGetUserTask from '../../../../Task/Model/useGetUserTask'
import InfoTaskButton from './Buttons/infoTaskButton'
import {TabContent, TabPane} from 'reactstrap'

const LinkToShow = (props) => {
  const {task, uploadStatusOk, filesUploadedByUser} = props
  const [taskData, setTaskData] = useState(task)
  const planId = useSelector(
    (state) => state.user.info.studentInfo.homeNotification.councelingPlanId,
  )
  /* eslint-disable-next-line */
  const refetchUserData = useSelector((state) => state.user.refetch)
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)

  const infoByStatus = {
    pending: {
      text: task?.buttonLabel,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconArrowRight
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    review: {
      text: `Submitted on: ${moment(task?.reviewDate).format(
        'MMM Do, YY h:mm a',
      )}`,
      colorText: '#696CFF',
      background: '#F2F2FF',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#696CFF'
        />
      ),
    },
    completed: {
      text: `Completed on: ${moment(task?.completedDate).format(
        'MMM Do, YY h:mm a',
      )}`,
      colorText: '#72809D',
      background: '#F0F4F8',
      icon: (
        <IconSignCheck
          size={16}
          style={{verticalAlign: 'inherit'}}
          color='#72809D'
        />
      ),
    },
  }

  /* eslint-disable-next-line */
  const { refetch: refetchTasks } = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })
  const [render, setRender] = useState(false)
  const {
    submit: linkToshowSubmit,
    /* eslint-disable-next-line */
    loading: linkLoading,
    data: linkData,
  } = useSetClickOnLink(() => {})
  // Query para traer una sola task
  const {
    data: dataOneTask,
    loading: loadingOneTask,
    refetch: refetchOneTask,
  } = useGetUserTask({
    taskId: taskData?.id,
    stepId: taskData?.stepId,
    phase:
      taskData?.phase?.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      }) || task?.phaseName,
    planId,
  })

  const linkToShowFunction = () => {
    try {
      linkToshowSubmit({
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase:
          taskData?.phase?.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase()
          }) || task?.phaseName,
        planId,
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
  }

  useEffect(() => {
    if (dataOneTask) {
      refetchOneTask().then((resp) => {
        setTaskData(resp?.data?.getUserTask)
      })
    }
    // eslint-disable-next-line
  }, [dataOneTask])

  useEffect(() => {
    if (linkData) {
      refetchOneTask().then((resp) => {
        setTaskData(resp?.data?.getUserTask)
      })
      setRender(!render)
    }
    // eslint-disable-next-line
  }, [linkData])

  return (
    <>
      <TabContent activeTab={loadingOneTask ? 'loading' : 'show'}>
        <TabPane tabId='show'>
          <div className='d-flex flex-column justify-content-center py-2'>
            {/* eslint-disable-next-line */}
          <a href={taskData?.linkToShow}
              // eslint-disable-next-line
            target="_blank">
              <InfoTaskButton
                background={
                  infoByStatus[taskData?.status]?.background || 'white'
                }
                colorText={infoByStatus[taskData?.status]?.colorText || 'black'}
                task={taskData}
                onClick={() => linkToShowFunction()}
                icon={infoByStatus[taskData?.status]?.icon}
                textLabel={infoByStatus[taskData?.status]?.text}
              />
            </a>
            {task?.status !== 'pending' &&
              task?.resolveTask?.uploadFiles?.length > 0 &&
              task?.resolveTask?.uploadFiles?.map((file) => {
                return (
                  <InfoTaskButton
                    background={infoByStatus[task?.status]?.background}
                    colorText={infoByStatus[task?.status]?.colorText}
                    onClick={() => {
                      window.open(file.url)
                    }}
                    icon={infoByStatus[task?.status].icon}
                    textLabel={file.url.split('/').at(-1)}
                  />
                )
              })}
          </div>
          {taskData?.status === 'pending' && !uploadStatusOk ? (
            <SendToReviewButtonOff />
          ) : (
            <ShowButtonByStatus
              taskData={taskData}
              filesUploadedByUser={filesUploadedByUser}
            />
          )}
          <MessageModal
            isOpen={messageModalOpen}
            toggle={() => setMessageModalOpen(!messageModalOpen)}
            message={message}
          />
        </TabPane>
        <TabPane tabId='loading'>
          <div className='d-flex flex-column justify-content-center py-2'>
            <Skeleton height={40} />
          </div>
          <Skeleton height={50} />
        </TabPane>
      </TabContent>
    </>
  )
}

export default LinkToShow
