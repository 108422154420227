import queryLessonResult from './useLessonResult'
import queryPartialUniversityResult from './usePartialUniversity'

const queryUserAccount = `
    id
    gender
    countryId
    paymentMethod {
        ... on CardPaymentMethod {
            cardInfo {
                last4
                brand
                expYear
                expMonth
            }
        }
        ... on BankDebitPaymentMethod {
            kind
            validationStatus
            accountInfo {
                bankName
                last4
            }
        }
        ... on ManualPaymentMethod {
            manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
            }
        }
        ... on BankCreditPaymentMethod {
            kind
            status
            sourceInfo {
                accountNumber
                routingNumber
                bankName
                swiftCode
            }
        }
        ... on ZellePaymentMethod {
            kind
            zellePaymentInfo {
                emailToSend
            }
        }
    }
    createdAt
    updatedAt
    emails {
        address
        verified
        }
    timezone
    firstName
    lastName
    phone
    spokenLanguages
    address
    city
    state
    zipCode
    birthdate   
    studentInfo {
        id
        studentType
        isWaivePayments
        materials
        materialsTopic {
            topicId
            serviceId
            link
          }
        universities {
           ${queryPartialUniversityResult}
        }
        accounts {
            username
            password
            platform
            originAccount
            taskInfo {
                planId
                phase
                stepId
                taskId
            }
        }
        grade
        institution
        graduationDate
        gpa
        defaultRate
        parents {
            firstName
            lastName
            managePayments
            email
            phone
            parentUserId
        }
        servicesRate {
            serviceId
            name
            defaultRate
        }
        aditionalInfo
        notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
            isAvailable
            duration
            }
        }
        linkedEducators{
            educatorUserId
            firstName
            lastName
         
        }
        upcomingLesson {
            ${queryLessonResult}
        }
        nextLesson {
            id
            startDate
        }
        homeNotification {
            councelingPlanId
            tasksPending {
                id
                status
                title
                startDate
                dueDate
                task
            }
            lessonsPending {
            id
            startDate
            endDate
            title
            service {
                id
                name
            }
            topic {
                id
                name
            }
            educator {
                firstName
                lastName
            }
            substitute {
                firstName
                lastName
            }
            }
            invoicesPending {
            id
            invoiceNumber
            amount
            to
            }
            approvalPlans {
            _id
            from
            to
            name
            isFixed
            isTotalLesson
            totalLessons
            kind
            }
        }
        }
        

`

export default queryUserAccount
