import React from 'react'
import {
    Button,
    IconRepeat,
    ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const VirtualClassroomButton = (props) => {
    const {
        clickButton = () => { },
        label,
        locationType,
        onClickResetVC = () => { },
    } = props
    const { translate } = useTranslation()

    return (
        <>
            <Button
                style={
                    locationType === 'virtualClassroom'
                      ? {
                        borderRadius: '16px 0px 0px 16px',
                        backgroundColor: '#696CFF',
                        width: '40%',
                      }
                      : { backgroundColor: '#696CFF', width: '50%' }
                  }
                className='ml-1'
                size='large'
                onClick={() => {
                    clickButton()
                }}
                label={label}
            />
            {locationType === 'virtualClassroom' && (
                <ToolTipInfo
                    tooltipId='reset-vc'
                    disable={false}
                    innerElement={
                        <div>
                            <span>{translate('modal.infolesson.resetvctooltip')}</span>
                        </div>
                    }
                    place='bottom'
                >
                    <div className='h-100'>
                        <Button
                            style={{
                                backgroundColor: '#696CFF',
                                borderRadius: '0px 16px 16px 0px',
                                marginLeft: '1px',
                                width: '100%',
                            }}
                            size='large'
                            className='d-flex flex-row justify-content-center align-items-center h-100'
                            onClick={() => {
                                onClickResetVC()
                            }}
                            label={<IconRepeat size={15} />}
                        />
                    </div>
                </ToolTipInfo>
            )}
        </>
    )
}

export default VirtualClassroomButton
