import React from 'react'
import PdfViewer from '../CounselingOnboarding/Sections/Proposal/PdfViewer'
import {useParams, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'

const CounselingProposal = () => {
  const {counselingPlanId} = useParams()

  const {/** timezone: zone,**/ roles} = useSelector((state) => state.user.info)

  // isAdmin from url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  // si isAdmin es true verifica si los datos del usuario entrante realmente son de administrador
  const isAdmin =
    searchParams.get('isAdmin') === 'true' && roles?.includes('SUPER_ADMIN')

  return (
    <div className='h-100 w-100'>
      <PdfViewer
        className='w-100 h-100'
        counselingPlanId={counselingPlanId}
        isAdmin={isAdmin}
      />
    </div>
  )
}

export default CounselingProposal
