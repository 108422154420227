import React from 'react'
import {IconLoad, IconRepeat, ListItem} from '@Knowledge-OTP/znk-ui-components'
import useGenerateGoogleCalendarDocument from './Model/useGenerateGoogleCalendarDocument'
import useGetGoogleCalendarDocument from './Model/useGetGoogleCalendarDocument'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../../i18n/useTranslation'
import { showAlert } from '../../../../../../../common/Alert/util'

const EducatorCalendarLink = (props) => {
    const { user = {}, refetch = () => {}, role = 'educator', classNameText = '' } = props
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    const {data, loading, refetch: refetchQuery} = useGetGoogleCalendarDocument({userId: user.id, role})
    const {getGoogleCalendarDocument} = data || {}
    const googleCalendarString = getGoogleCalendarDocument || translate('profile.actions.educatorInfo.calendarLinkNoDefined')
    const [generateLink, {loading: loadMutation}] = useGenerateGoogleCalendarDocument(
        () => {
            refetchQuery()
            refetch()
            showAlert({text: translate('profile.actions.educatorInfo.onSuccess'), status: 'success'}, dispatch)
        }, 
        (err) => {
            console.error(err)
            showAlert({text: err.toString(), status: 'error'}, dispatch)
        }
    )

    
    return (
        <ListItem
            classNameLeft={'w-50'}
            classNameRight={'w-50'}
            Left={translate('profile.actions.educatorInfo.calendarLinkTitle')}
            Right={
                <div className='d-flex flex-row justify-content-end align-items-center'>
                    {loading ? <IconLoad size={16} /> : <span onClick={() => {
                        if(getGoogleCalendarDocument) {
                            navigator.clipboard.writeText(getGoogleCalendarDocument).then(
                                () => {showAlert({text: "Copy!", status: 'success'}, dispatch)}
                            )
                            showAlert({text: getGoogleCalendarDocument, status: 'success'}, dispatch)
                        }
                    }} className={`text-truncate cursor-pointer ${classNameText}`} title={googleCalendarString}>{googleCalendarString}</span>}
                    <div
                        onClick={() => {if(!loadMutation)generateLink({variables: {userId: user.id, role}})}} 
                        className={'hover-icon ml-1'}>
                        {loadMutation ? <IconLoad size={16}/> : <IconRepeat size={16}/>}
                    </div>
                </div>
            }

        />
    )
}

export default EducatorCalendarLink