import React from 'react'
import {ListItem} from '@Knowledge-OTP/znk-ui-components'
import ShowPaymentMethod from '../../../Resume/Sections/PaymentSchedule/Components/ShowPaymentMethod'
// import RenderInputOption from '../../../../../newPlanModal/Components/RenderInputOption'
import moment from 'moment'

// NO MUESTRA PAYMENT ITEM CONFIG, SINO QUE LOS ITEMS DE PAGO CREADOS EN API
const PaymentScheduled = (props) => {
  const {plan, className} = props
  return (
    <div className={`d-flex flex-column ${className}`}>
      <span className='h4 font-weight-bold text-title'>Payment Status</span>
      <ListItem
        className='w-100 mt-2'
        showBottomLine={false}
        showTopLine={false}
        Left={<span className='text-gray h45'>Payment method</span>}
        Right={
          <ShowPaymentMethod userId={plan?.studentUser?.studentUserId || ''} />
        }
      />
      <div
        className={`d-flex flex-column pr-3 ${
          !(plan?.payments?.length > 0) ? 'justify-content-center' : ''
        }`}
      >
        {
          plan?.payments?.length > 0
            ? plan.payments.map((payment, index) => {
                return (
                  <ListItem
                    className='w-100 appears-animation py-2'
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <span className='text-title align-self-baseline mr-1'>
                          {`End of ${moment(payment?.date).format('MMM')}`}
                        </span>
                        <span className='h45 text-gray align-self-baseline'>
                          {`(${index + 1}th of ${plan?.payments?.length})`}
                        </span>
                      </div>
                    }
                    Right={
                      <div className='d-flex flex-row align-items-center'>
                        <span className='text-title mr-1'>{`$${parseFloat(
                          payment?.amount,
                        ).toFixed(2)}`}</span>
                      </div>
                    }
                  />
                )
              })
            : null
          // <RenderInputOption className={`mx-6 text-align-center`} key={'noDefine'} label={`Define an amount on plan breakdown to see the payment schedule.`}/>
        }
      </div>
    </div>
  )
}

export default PaymentScheduled
