import React, {useEffect, useState, useMemo} from 'react'
import {IconLoad, IconBook} from '@Knowledge-OTP/znk-ui-components'
import {useQuery, gql} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../../../../common/Alert/util'
import {hasHttp} from '../../../utils'

const GetCourseMAterials = (props) => {
  const {
    serviceId: serviceIdProp = null,
    topicId: topicIdProp = null,
    studentId = null,
  } = props
  const dispatch = useDispatch()
  const [query, setQuery] = useState({id: studentId})
  useEffect(() => {
    setQuery({id: studentId})
  }, [studentId])
  const // [getUserInfo,
    {data, loading} = useQuery(
      gql`
        query($id: String!) {
          user(id: $id) {
            studentInfo {
              materials
              materialsTopic {
                topicId
                serviceId
                link
              }
            }
          }
        }
      `,
      {
        variables: query,
      },
    )
  const url = useMemo(() => {
    // const generalMaterials = data?.user?.studentInfo?.materials
    const materials = data?.user?.studentInfo?.materialsTopic
    const url =
      (materials || []).find(
        ({serviceId, topicId}) =>
          serviceId === serviceIdProp && topicId === topicIdProp,
      )?.link ||
      // generalMaterials ||
      null
    return url
  }, [data, serviceIdProp, topicIdProp])

  return (
    <span
      className='cursor-pointer'
      onClick={() => {
        if (url) window.open(hasHttp(url), '_blank')
        else {
          showAlert(
            {
              status: 'warning',
              text: 'No course material for this topic.',
            },
            dispatch,
          )
        }
      }}
    >
      {loading ? <IconLoad className='mr-2' /> : <IconBook className='mr-2' />}
      Course Materials
    </span>
  )
}

export default GetCourseMAterials
