import React, {useState, useMemo} from 'react'
import {Input, IconLearn, Button} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {useLocation} from 'react-router-dom'
import firebase from 'firebase/app'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import {Collapse} from 'reactstrap'

const ResetPassword = (props) => {
  const {back} = props

  const dispatch = useDispatch()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  // FIREBASE CODE
  const code = searchParams.get('code')

  const {translate} = useTranslation()
  const [form, setForm] = useState({
    password: null,
    passwordCopy: null,
  })
  const samePass = useMemo(() => {
    return form.password === form.passwordCopy
  }, [form])
  const [load, setLoad] = useState(false)

  return (
    <div>
      <form onSubmit={(e) => {}}>
        <div className='h1 font-weight-light text-logo'>
          {translate('login.signin.email.confirmPass.bigTitle')}
        </div>
        <div className='h4 my-2 font-weight-light text-secondary'>
          {translate('login.signin.email.confirmPass.description')}
        </div>
        <Input
          className='my-2'
          name='password'
          value={null}
          type='password'
          placeholder={translate(
            'login.signin.email.confirmPass.placeholderEmail',
          )}
          onChange={({target: {value}}) => {
            setForm({...form, password: value})
          }}
          addOnComponent={<IconLearn />}
        />
        <Input
          className='my-2'
          name='passwordCopy'
          value={null}
          type='password'
          placeholder={translate(
            'login.signin.email.confirmPass.placeholderEmailRepeat',
          )}
          onChange={({target: {value}}) => {
            setForm({...form, passwordCopy: value})
          }}
          addOnComponent={<IconLearn />}
        />
        <Collapse isOpen={!samePass}>
          <div className='my-2 text-center text-error h3 w-100'>
            {translate('login.signin.email.confirmPass.error')}
          </div>
        </Collapse>
        <Button
          className='w-100 mt-2'
          color='primary'
          check
          activity={load}
          disabled={
            !samePass ||
            !(form.password?.length > 0) ||
            !(form.passwordCopy?.length > 0)
          }
          type='button'
          onClick={() => {
            setLoad(true)
            firebase
              .auth()
              .confirmPasswordReset(code, form.password)
              .then(() => {
                showAlert(
                  {
                    text: translate('login.signin.email.passUpdated'),
                    status: 'success',
                  },
                  dispatch,
                )
                setLoad(false)
                if (back) back()
              })
              .catch((error) => {
                console.error(error)
                showAlert(
                  {
                    text: translate('login.signin.email.passUpdatedError'),
                    status: 'error',
                  },
                  dispatch,
                )
                setLoad(false)
              })
          }}
          label={translate('login.signin.email.confirmPass.resetPass')}
        />
      </form>
    </div>
  )
}

export default ResetPassword
