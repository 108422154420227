import React from 'react'
import {
  Avatar,
  Button,
  IconProgram,
  IconTopic,
  ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
import useManualLesson from './ManualLessonFromStudent'
import useTranslation from '../../../../../../i18n/useTranslation'

const ConfirmLesson = (props) => {
  const {educator, service, topic, selectedBlock, goBack, toggle} = props
  const startTime = moment(selectedBlock ? selectedBlock.date : null)
  const {submit, loading} = useManualLesson(() => toggle(), console.log)
  const onSubmit = () => {
    submit({
      input: {
        topicId: topic.topicId,
        serviceId: service.serviceId,
        educatorUserId: educator.educatorUserId,
        title: `${service && service.name} ${topic && topic.name}`,
        startDate: new Date(selectedBlock.date),
      },
    })
  }
  const {translate} = useTranslation()
  return (
    <>
      <ListItem
        showTopLine
        hasHover
        onClick={goBack}
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconProgram fill='logo' className='znk-icon-service' />
            {startTime && (
              <span className='ml-2'>{`${startTime.format(
                'h:mma',
              )} to ${startTime
                .clone()
                .add(50, 'm')
                .format('h:mma')} | ${startTime.format('MMM D, YYYY')}`}</span>
            )}
          </div>
        }
      />
      <ListItem
        showTopLine
        hasHover
        Left={
          <div className='d-flex align-items-center text-muted'>
            <IconTopic fill='logo' className='znk-icon-service' />
            <span className='ml-2'>{`${service && service.name} ${
              topic && topic.name
            }`}</span>
          </div>
        }
      />
      <ListItem
        showBottomLine
        showTopLine
        hasHover
        Left={
          <div className='d-flex align-items-center text-muted'>
            <>
              <Avatar
                name={`${educator.firstName} ${educator.lastName}`}
                named={false}
                uri={educator?.avatar ? educator?.avatar : null}
                size='avatar-medium'
              />
              <span className='ml-2'>
                {educator.firstName} {educator.lastName}
              </span>
            </>
          </div>
        }
      />
      <Button
        color='primary'
        label={translate('lessons.scheduleLesson')}
        activity={loading}
        onClick={onSubmit}
        className='w-100 my-2'
      />
    </>
  )
}

export default ConfirmLesson
