import {gql, useMutation} from '@apollo/client'

const useSetExamScore = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $examInput: ExamInput!
                $taskId: ID!
                $stepId: ID!
                $phase: PhaseEnum!
                $planId: ID!
            ) {
                setExamScore (
                    examInput: $examInput
                    taskId: $taskId
                    stepId: $stepId
                    phase: $phase
                    planId: $planId
                )
            }
        `, {onCompleted, onError}
    )
}

export default useSetExamScore