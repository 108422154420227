export default {
  notes: 'Notas de la lección',
  rating: 'Calificación de la lección',
  duedate: 'Fecha de vencimiento',
  score: 'Puntuación',
  qone: '¿Qué aprendió hoy el/la estudiante?',
  qtwo: '¿Como fue el esfuerzo y la participación del alumno?',
  ocomment: 'Otros comentarios',
  minbefore: '30 min antes',
  waiting: 'Esperando que el educador complete las notas de la lección.',
  description: 'Cras mattis consectetur purus sit amet fermentum donec.',
  recording: 'Ver grabación de la lección',
  norecording: 'Lección sin grabación',
  charsLeft: ({num}) => `Faltan ${num} caracteres`,
}
