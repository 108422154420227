import {useDispatch, useSelector} from 'react-redux'

const usePopupBuLink = (props) => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.calendar.popupBackupLink)
  const {isOpen, link} = reduxData
  const setOpen = (open, link) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.popupBackupLink',
      data: {
        ...reduxData,
        isOpen: open,
        link,
      },
    })
  }
  return {
    ...reduxData,
    link,
    isOpen,
    open: (link) => {
      setOpen(true, link)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
  }
}

export default usePopupBuLink
