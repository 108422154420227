import React, {useEffect, useState} from 'react'
import {SubscribeButton} from '@Knowledge-OTP/znk-ui-components'
import {gapi} from 'gapi-script'
import {showAlert} from '../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
const YOUTUBE_URL_SUBSCRIPTION =
  'https://www.googleapis.com/youtube/v3/subscriptions'

const OAUTH2_CLIENT_ID =
  '572073252041-kucrgs78h9bimvv4fhfj54qq0qkfilq8.apps.googleusercontent.com' // process.env.REACT_APP_YOUTUBE_API_KEY;
// const OAUTH2_SCOPES = [
//     "https://www.googleapis.com/auth/youtubepartner",
//     'https://www.googleapis.com/auth/youtube',
//     "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
//     "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
// ];
const isDev = false
let done = false
const initClient = (
  options = {
    updateLoggedInStatus: (status) => {},
  },
  setApiInstance = () => {},
) => {
  if (done) {
    return
  }
  const KEY = process.env.REACT_APP_YOUTUBE_API_KEY || 'x'
  // const CHANNEL_ID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID
  done = true
  gapi.client
    .init({
      apiKey: KEY,
      client_id: OAUTH2_CLIENT_ID,
      // permite administrar tu perfil de youtube
      scope: 'https://www.googleapis.com/auth/youtubepartner',
      // discoveryDocs: OAUTH2_SCOPES
      // scope: OAUTH2_SCOPES,
    })
    .then((res) => {
      // Listen for sign-in state changes.
      const api = gapi.auth2.getAuthInstance()
      api.isSignedIn.listen(options.updateLoggedInStatus)

      // Handle the initial sign-in state.
      options.updateLoggedInStatus(api.isSignedIn.get())

      setApiInstance(api)
    })
    .catch((err) => {
      console.error('Caught error', err)
    })

  setApiInstance(gapi.auth2.getAuthInstance())
}

async function AddSubscription(onSucccess = () => {}, onError = () => {}) {
  // Replace this channel ID with the channel ID you want to subscribe to
  const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID

  try {
    gapi.client
      .request(
        {
          method: 'POST',
          path: `${YOUTUBE_URL_SUBSCRIPTION}?part=snippet`,
          headers: {
            Accept: 'application.json',
            'Content-Type': 'application/json',
          },
          body: {
            snippet: {
              resourceId: {
                kind: 'youtube#channel',
                channelId: channelId,
              },
            },
          },
        },
        // , 'snippet'
      )
      .then(
        (res) => {
          console.log('request post success', res)
          onSucccess()
        },
        (err) => {
          console.log('request post error', err)

          onError(err.result?.error)
        },
      )
    // .youtube.subscriptions.insert
  } catch (e) {
    console.log('ERROR => ', e)
    onError({message: e.toString()})
  }
}

const Subscriber = (props) => {
  const {onSubscriber = () => {}, isSubscriber = false} = props
  const dispatch = useDispatch()
  const [googleApiInstance, setGoogleApiInstance] = useState(null)

  const [loggedInStatus, setLoggedInStatus] = useState(false)
  const [initiatedClient, setInitiatedClient] = useState(false)

  useEffect(() => {
    if (!isDev) {
      gapi.load('client:auth2', () => {
        return initClient(
          {
            updateLoggedInStatus: (status) => {
              // ejecuta cada vez que el usuario cambia
              // su estado (logeado o desloageado)
              console.log('Login status', status)
              setLoggedInStatus(status)
            },
          },
          setGoogleApiInstance,
        )
      })

      setInitiatedClient(true)
    }
  }, [initiatedClient])

  const onSubscriptionError = (errorBody = 'Error') => {
    if (
      errorBody.errors.find(({reason}) => reason === 'subscriptionDuplicate')
    ) {
      onSubscriber(true, 0 /** usuario ya estaba subscrito**/)
    }
    showAlert(
      {
        text: errorBody?.message || errorBody,
        status: 'error',
      },
      dispatch,
    )
  }

  const onSubscriptionSuccess = (res) => {
    onSubscriber(true, 1)
    showAlert(
      {
        text: 'Subscription successfully!',
        status: 'success',
      },
      dispatch,
    )
  }

  return (
    <>
      {/* <div>You are {loggedInStatus ? "" : "not"} signed in</div> */}
      <SubscribeButton
        isSubscriber={isSubscriber}
        subscribeLabel='SUBSCRIBE'
        subscribedLabel='SUBSCRIBED'
        onClick={() => {
          if (isDev) return
          if (isSubscriber) return
          if (loggedInStatus) {
            AddSubscription(onSubscriptionSuccess, onSubscriptionError)
          } else {
            googleApiInstance
              .signIn()
              .then((res) => {
                AddSubscription(onSubscriptionSuccess, onSubscriptionError)
              })
              .catch((err) => {
                console.error(err.toString())
              })
          }
        }}
      />
    </>
  )
}

export default Subscriber
