import {gql, useLazyQuery} from '@apollo/client'

const useUser = () => {
  return useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          id
          avatar
          firstName
          lastName
        }
      }
    `,
  )
}
export default useUser
