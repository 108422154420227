import {useCallback, useState} from 'react'
import {gql, useMutation} from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
      mutation($invoiceId: String!) {
        processInvoice(invoiceId: $invoiceId) {
          id
          userId {
            id
          }
          billedUserId
          processedTimes
          createdAt
          updatedAt
          lastProcessedAt
          from
          to
          status
          category
          paymentItems {
            ... on PlanPaymentItem {
              id
              amount
              draft
              status
              lastProcessedAt
              description
              category
            }
            ... on CustomPaymentItem {
              id
              amount
              draft
              status
              lastProcessedAt
              description
              category
            }
            ... on LessonPaymentItem {
              id
              amount
              draft
              status
              lastProcessedAt
              description
              category
            }
            ... on EducatorPaymentItem {
              id
              amount
              draft
              status
              lastProcessedAt
              description
              category
            }
          }
        }
      }
    `,
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback(
    (obj) => {
      const errors = validate(obj)
      if (errors && Object.keys(errors).length) {
        setValidationErrors(errors)
        onError('validation Error')
        return
      }
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          setValidationErrors({general: 'misc.apiError'})
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {
    loading,
    data: data ? data.processInvoice : null,
    error,
    validationErrors,
    submit,
  }
}
